import React from 'react';
import cv1 from '../../Images/commercial-page/cv-01.svg';
import cv2 from '../../Images/commercial-page/cv-02.svg';
import ProposalDetails from '../../Components/Proposal/ProposalDetails';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router';

const CommercialVehicleProposalPage = () => {
  const naviagte = useNavigate();

  const handlebackBtn = () => {
    naviagte('/motor/commercial/quotation');
  };
  return (
    <div>
      <div>
        <div className="kychedding">
          Commercial Vehicle Details
        </div>
        <div className="kyclogo">
          <div>
            <div className="infologo">
              <div className="back_icon">
                <KeyboardBackspaceIcon onClick={handlebackBtn} />
              </div>
              <h6>
                Step 3 (<span className="inprogress">In Progress</span>)
              </h6>
            </div>

            <div>
              <img src={cv2} alt="car and umberela" className="carnumberela" />
            </div>
          </div>
          <div>
            <img src={cv1} alt="vector object" className="vectorobject" />
          </div>
        </div>
      </div>
      <ProposalDetails />
      <div className="bottomdiv mx-5 mb-5">
        <div>***</div>
      </div>
    </div>
  );
};

export default CommercialVehicleProposalPage;
