const option = [

  { title: 'Zero Depreciation', value: 'partsDepreciation' },
  { title: 'Road Side Assistance', value: 'roadSideAssistance' },
  { title: 'Engine And Gearbox Protection', value: 'engineProtection' },
  { title: 'Tyre Protection', value: 'tyreProtection' },
  { title: 'Rim Damage', value: 'rimProtection' },
  { title: 'Return To Invoice', value: 'returnToInvoice' },
  { title: 'Consumable', value: 'consumables' },
  { title: 'key And Lock Protect', value:'keyAndLockProtect'},
  { title:'Loss Of Personal Belongings', value:'lossOfPersonalBelongings'}
  
];

export default option;