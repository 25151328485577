import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
 
const Brouchure = ({quote}) => {
  console.log(quote)
  const [documents,setDocuments] = useState([])
  const CareDocuments = [
    {
      title: 'Policy Wording',
      link:
        'https://frontend-pdf.s3.ap-south-1.amazonaws.com/care-supreme---policy-terms-and-conditions+(2).pdf',
    },
    {
      title: 'Proposal',
      link:
        'https://frontend-pdf.s3.ap-south-1.amazonaws.com/care1649917866Proposal.pdf',
    },
    {
      title: 'Claim Form',
      link:
        'https://frontend-pdf.s3.ap-south-1.amazonaws.com/care1649917866Claim.pdf',
    },
    {
      title: 'Brochure',
      link:'https://frontend-pdf.s3.ap-south-1.amazonaws.com/CARE+SUPREME+-+BROCHURE+(1).pdf',
    },
  ];
  const hdfcDocuments = [
    {
      title:"Policy Wording",
      link:"https://frontend-pdf.s3.ap-south-1.amazonaws.com/optima_secure_policywordings.pdf",
    },
    {
      title:"Brouchure",
      link:"https://frontend-pdf.s3.ap-south-1.amazonaws.com/optima-secure-brochure.pdf"
    },
    {
      title:"Prospectus",
      link:"https://frontend-pdf.s3.ap-south-1.amazonaws.com/my-optima-secure-prospectus.pdf"
    }
  ]
  useEffect(()=>{
    if(quote?.provider === "HDFC"){
      setDocuments(hdfcDocuments)
    }else if(quote?.provider === "CARE"){
      setDocuments(CareDocuments)
    }
  },[quote])
  const gradientColors = [
    'linear-gradient(to right, #ff9a9e, #fad0c4)',
    'linear-gradient(to right, #a18cd1, #fbc2eb)',
    'linear-gradient(to right, #8ee4af, #ffdde1)',
    'linear-gradient(to right, #e0c3fc, #8ec5fc)',
  ];
 
  return (
     <Grid container spacing={2}>
      {documents?.map((document, index) => (
        <Grid key={index} item xs={12} sm={6}>
          <Card
            sx={{
              background: gradientColors[index],
              boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.08)',
              transition: '0.3s',
              '&:hover': {
                boxShadow: '0px 16px 30px rgba(0, 0, 0, 0.1), 0px 8px 12px rgba(0, 0, 0, 0.08)',
              },
            }}
          >
            <CardContent>
              <a href={document.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <PictureAsPdfIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" style={{ color: '#253858', fontSize: '16px', fontWeight: 600 }}>
                      {document.title}
                    </Typography>
                  </Grid>
                </Grid>
              </a>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
 
export default Brouchure;