import React, {useEffect, useState} from "react";
import {
  Button,
  Grid,
} from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DownloadIcon from '@mui/icons-material/Download';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import {useDispatch, useSelector} from "react-redux";
import {DenseTable} from "./DenseTable";
import {clearError, setError} from "../../../../Redux/action-creators/errorAction";
import {clearSuccessMessage} from "../../../../Redux/action-creators/successAction";
import {useTranslation} from "react-i18next";
import {getClaimsByCustomerId} from "../../api/customerService";

const CustPolicyClaim = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customerData = useSelector(state => state.customer.data);
  const [claims, setClaims] = useState();


  useEffect(() => {
    if (customerData) {
      getClaims();
    }
  }, [customerData])


  const getClaims = () => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    getClaimsByCustomerId(customerData.customer_id).then(response => {
      if (response.status > 400) {
        dispatch(setError(t('lmv.serverError')))
      } else {
        setClaims(response.data);
      }
    }).catch((error) => {
      dispatch(setError("Internal Server Error: " + error.toString()));
    }).catch((error) => {
      dispatch(setError("Internal Server Error: " + error.toString()));
    })
  }


  return (
      <Grid container>
        <Grid item xs={10} sm={10} md={12} lg={12}>
          <div className="container admin-container-title px-3 mt-3 text-primary border rounded-4">
            <div className="d-flex justify-content-between align-items-center mt-3">
              <Grid item xs={12} lg={6} md={12}>
                <div className="admin-container-title px-3 mt-3 text-primary">
                  <h3>{t('lmv.customer.policyClaimsHeading')}</h3>
                </div>
              </Grid>
              <Grid item xs={12} lg={6} md={12}>
                <div style={{ display: 'flex', gap: '10px',justifyContent:"end" }}>
                  <Button
                      onClick={getClaims}
                    variant="outlined"
                    color="primary"
                    endIcon={<AutorenewIcon />}
                  >
                    {t('lmv.customer.refresh')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<DownloadIcon />}
                  >
                    {t('lmv.customer.bulk')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<DownloadIcon />}
                  >
                    {t('lmv.customer.csv')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<DownloadIcon />}
                  >
                    {t('lmv.customer.life')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<ToggleOffIcon fontSize="large" />}
                  >
                    {t('lmv.customer.nonLife')}
                  </Button>
                </div>
              </Grid>
            </div>
            { claims &&
              <DenseTable claimDetails={claims} />
            }
          </div>
        </Grid>
      </Grid>
  );
};

export default CustPolicyClaim;
