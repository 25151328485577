import {FETCH_MASTER_DATA_FAILURE, FETCH_MASTER_DATA_SUCCESS} from "../action-type/actionType";
import {getMasterData} from "../../crm/customer/api/customerService";

export const fetchMasterData = () => async dispatch => {
    try {
        const response = await getMasterData();
        const data = await response.data;
        dispatch({type: FETCH_MASTER_DATA_SUCCESS, payload: data});
    } catch (error) {
        dispatch({type: FETCH_MASTER_DATA_FAILURE, error});
    }
};