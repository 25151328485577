import axios from "axios";

export const validatePospUserByPhone = (payload) => {
    return axios.post('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/user/validateuser', payload);
};  


export const getStudyMaterial = () => {
    return axios.get('https://lmv-web-staging.s3.ap-south-1.amazonaws.com/training_modules.json')
}


export const generateOtp = (payload) => {
    return axios.post('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/user/generateOTP', payload);
};


export const otpValidation = (payload) => {
    return axios.post('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/user/validateOTP', payload);
};

export const fetchPospData = (phone) => {
    return axios.get(`https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/profileDetails/${phone}`)
}


export const updatePospProfileData = (phone, payload) => {
    return axios.put(`https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/profileDetails/${phone}`, payload);
};

export const updatePospProfileSetting = (agent_id, payload) => {
    return axios.put(`https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/profilesettings/${agent_id}`, payload);
};

export const addCustomer = (playload) =>{
    return axios.post('https://75e8n7v4uj.execute-api.ap-south-1.amazonaws.com/Prod/insurance/crm/v1/org/agent/customer', playload)
}

export const addOfflinePolicy = (payload) => {
    return axios.post('https://75e8n7v4uj.execute-api.ap-south-1.amazonaws.com/Prod/insurance/policy', payload);
}


export const getPospBankAccount = (agent_id) => {
    return axios.get(`https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/bankAccountInfo/${agent_id}`);
};

export const postPospBankDetails = (agent_id, payload) => {
    return axios.put(`https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/bankAccountInfo/${agent_id}`, payload);
};

export const getPospSalesData = (agent_id) => {
    return axios.get(`https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/salesinfo/${agent_id}`);
};
export const getPospSalesBarchartData = (playload) =>{
    return axios.post('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/salesinfo', playload)

}

export const getPaymentLogData = (agent_id) => {
    return axios.get(`https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/Posp/v1/payemntlogs/${agent_id}`);
};


export const getPoliciesDataById = (agent_id) => {
    return axios.get(`https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/policy/${agent_id}`);
};

export const getPospDocuments = (agent_id) => {
    // return axios.get(`https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/customer/v1/document/upload/${agent_id}`);
};

export const uploadPospDocument = (customerId,documentType,payload,headers) => {
    // return axios.post(`https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/customer/v1/document/upload/${customerId}/${documentType}`,payload,headers);
};

export const fetchQuestions = async () => {
    return axios.get(`https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/examination`);
  };

  export const fetchQuestionsAPI = async () => {
    const response = await axios.get(`https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/examination`);
    return response?.data;
  };
  
  export const submitAnswersAPI = async (responses, agentId) => {
    
    const payload = { questions: responses, agent_id: agentId };
    const response = await axios.post('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/examination', payload);
    return response?.data;
  };

  export const fetchEmployees = async() =>{
      return await axios.get('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/employeedata')
    // return await axios.get('https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/admin/usermanagement/0')
}

export const fetchLastAgentId = async() =>{
    return await axios.get('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/agentidgenerator')
}

export const fetchAllAgentsIdAndName = async() =>{
    return await axios.get('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/poplist')
}

export const fetchCustomerData = async(phone)=>{
    return await axios.get('https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/customer/v1/profileDetails/' + phone)
}