// Actions.js
import axios from "axios";
import { common_api, loan_api } from "../../Config";
export const PROPOSAL_NUMBER ="PROPOSAL_NUMBER"
export const PROPOSAL_RESPONSE = "PROPOSAL_RESPONSE";
export const PERSONAL_DETAILS = "PERSONAL_DETAILS";
export const ADDRESS_DETAILS = "ADDRESS_DETAILS";
export const LOAN_DETAILS = "LOAN_DETAILS";
export const NOMINEE_DETAILS = "NOMINEE_DETAILS";
export const Get_Data_Request = "Get_Data_Request";
export const Add_Customer_Data_Success = "Add_Customer_Data_Success";
export const Mobile_OTP_Verification_Request = 'Mobile_OTP_Verification_Request';
export const Mobile_OTP_Request = 'Mobile_OTP_Request';
export const proposalNumber = (payload) => {
  return {
    type:PROPOSAL_NUMBER,
    payload
  }
}

export const proposalResponse = (payload) => {
  return {
    type: PROPOSAL_RESPONSE,
    payload
  }
}

export const setPersonalDetails = (payload) => {
  return {
    type: PERSONAL_DETAILS,
    payload,
  };
};

export const setAddressDetails = (payload) => {
  return {
    type: ADDRESS_DETAILS,
    payload,
  };
};

export const setLoanDetails = (payload) => {
  console.log(payload,"resssss")
  return {
    type: LOAN_DETAILS,
    payload,
  };
};

export const setNomineeDetails = (payload) => {
  return {
    type: NOMINEE_DETAILS,
    payload,
  };
};

const loadingRequest = () => {
  return {
    type: Get_Data_Request
  };
}

export const postTotalCustomData = (payload) => {
  return {
    type: Add_Customer_Data_Success,
    payload
  };
};

export const getSuccessSmsResponse = (payload) => {
  return {
    type: Mobile_OTP_Verification_Request,
    payload
  };
};
export const getSuccessSmsRequest = (payload) => {
  return {
    type: Mobile_OTP_Request,
    payload
  };
};


export const addCustomerData = (requestBody) => (dispatch) => {
  dispatch(loadingRequest());
  axios.post(`${common_api}/crm/v1/org/agent/customer`, requestBody)
    .then((response) => {
      dispatch(postTotalCustomData(response.data));
    })
    .catch((error) => console.log(error, 'err'));
};