import React, {useState } from 'react'
import { Box, Grid,Checkbox, Typography, Select, MenuItem, Divider, FormControlLabel } from '@mui/material';
 
const ProfileSettings = () => {
 
  const [selectedOption, setSelectedOption] = useState('');
  const [communicationPreferences, setCommunicationPreferences] = useState({
    'Policy Update': [],
    'Pre Purchase Updates': [],
    'News & Offers': []
  });
 
 
  const handleCheckboxToggle = (preference, option) => {
    setCommunicationPreferences(prevState => {
      const updatedPreferences = { ...prevState };
      const index = updatedPreferences[preference].indexOf(option);
     
      if (index === -1) {
        // If the option is not already selected, add it to the array
        updatedPreferences[preference].push(option);
      } else {
        // If the option is already selected, remove it from the array
        updatedPreferences[preference].splice(index, 1);
      }
 
      return updatedPreferences;
    });
  };
 
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
 
  // Options for selecting mobile number or email address
  const selectOptions = [
    { value: '9568326578', type: 'Mobile Number' },
    { value: 'Rohan@gmail.com', type: 'Email Address' },
  ];
 
  return (
 
      <Grid container spacing={2} sx={{minHeight:"90vh" ,px:2}}>
        <Grid item xs={10} sm={10} md={11} lg={12}>
          <Typography variant="h5" gutterBottom>
            Profile Settings
          </Typography>
 
          <Grid container className="prof-img">
            <Grid item xs={12} >
              <Typography variant="subtitle1" gutterBottom>
                Select a mobile number/email address to set communication preferences
              </Typography>
 
            </Grid>
            <Grid item xs={12} >
              {/* Select input for choosing mobile number or email address */}
              <Select value={selectedOption} onChange={handleOptionChange} sx={{ width: '300px', marginBottom: '20px' }}>
                {selectOptions.map((option) => (
                  <MenuItem key={option.type} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={7} >
 
              <Typography variant="subtitle2" gutterBottom sx={{ color: "red" }}>
                Manage contact details
              </Typography>
            </Grid>
          </Grid>
          {/* <ProfileSettingTable /> */}
          <Typography variant="subtitle1" gutterBottom>
            Set communication preferences
          </Typography>
 
        </Grid>
        <Grid item xs={10} sm={10} md={11} lg={12}>
        <Box
        sx={{ py: 2,px:1, backgroundColor: '#f4f5f7',borderRadius:4 }}
      >
        <Grid container spacing={1}>
              <Grid item xs={8}>
              <Typography variant="body1" color='#6b778c'>Label</Typography>
              </Grid>
              <Grid item xs={1}>
              <Typography variant="body1" color='#6b778c'>SMS</Typography>
              </Grid>
              <Grid item xs={1}>
              <Typography variant="body1" color='#6b778c'>Email</Typography>
              </Grid>
              <Grid item xs={1}>
              <Typography variant="body1" color='#6b778c'>Call</Typography>
              </Grid>
              <Grid item xs={1}>
              <Typography variant="body1" color='#6b778c'>Whatsapp</Typography>
              </Grid>
        </Grid>
              </Box>
        </Grid>
        {Object.entries(communicationPreferences).map(([preference, options]) => (
          <Grid item key={preference} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h6">{preference}</Typography>
              </Grid>
              <Grid item xs={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={options.includes('SMS')}
                      onChange={() => handleCheckboxToggle(preference, 'SMS')}
                    />
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={options.includes('Email')}
                      onChange={() => handleCheckboxToggle(preference, 'Email')}
                    />
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={options.includes('Call')}
                      onChange={() => handleCheckboxToggle(preference, 'Call')}
                    />
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={options.includes('WhatsApp')}
                      onChange={() => handleCheckboxToggle(preference, 'WhatsApp')}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
  )
}
 
export default ProfileSettings