import './Payment.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddToLocalStorage, GetDataLocalStorage } from '../../../Framework/GlobalObj';
import { getKycStatus } from '../../../Redux/Action';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import axios from 'axios';
import { vehicle_api } from '../../../Config';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PaymentSuccessTick from '../../Images/Car-Page/Payment-Page/paymentSuccess.svg';
import supportHelpCard from '../../Images/Car-Page/Payment-Page/HelpCenterSupport.svg';
import getReceipt from '../../Images/Car-Page/Payment-Page/getReceipt.svg';
import kycFailedLogo from '../../Images/Car-Page/Payment-Page/kyc-logo.svg';
import downloadPolicyLogo from '../../Images/Car-Page/Payment-Page/downloadPolicyLogo.svg';
import { useNavigate, useSearchParams} from 'react-router-dom';

const PaymentSuccess = () => {
  const steps = ['Payment', 'KYC', 'Policy'];
  const steps1 = ['Payment', 'Policy'];
  const dispatch = useDispatch();
  const kycData = useSelector((store) => store.motorReducer.kycData);
  const [showKycStatus, setShowKycStatus] = useState(false);
  const [download, setDownload] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [req, setReq] = useState({});
  const [PolicyNumber, setPolicyNumber] = useState('');
  const [number, setNumber] = useState('');
  const navigate = useNavigate();
  const [parms] = useSearchParams();

  useEffect(() => {
    let obj = GetDataLocalStorage('globalObj');
    let proposalNumber = obj?.profile?.customer?.proposalNumber;
    dispatch(getKycStatus(proposalNumber));
    setReq(obj);
  }, []);

  useEffect(() =>{
    let obj = GetDataLocalStorage('globalObj');
    const bajajPolicyNumber = parms.get('p_policy_ref');
    setNumber(bajajPolicyNumber)
    obj.profile.customer.policyNumber = parms.get('p_policy_ref')
  },[])

  useEffect(() => {
    const paymentData = async () => {
      let obj = GetDataLocalStorage('globalObj');
      if (obj.insurance.providerName === 'ICICI') {
        try {
          const paymentSuccess = await axios.post(`${vehicle_api}/icici/v1/paymentStatus`, obj)
          console.log(paymentSuccess)
          obj.insurance.authCode = paymentSuccess?.data?.AuthCode;
          obj.insurance.MerchantId = paymentSuccess?.data?.MerchantId;
          obj.insurance.PGtransactionId = paymentSuccess?.data?.PGtransactionId;
          AddToLocalStorage('globalObj', obj);
          if (paymentSuccess.status === 200) {
            const submit_payment_details = await axios.post(`${vehicle_api}/insurance/v1/motor/policy-number/geneartion`, obj)
            console.log(submit_payment_details, "submit payment details")
            if (submit_payment_details.data.statusMessage === 'Success') {
              let policyNumber = submit_payment_details.data.paymentMappingResponse.paymentMapResponseList[0].policyNo;
              console.log(policyNumber, 'policyNumber')
              setPolicyNumber(policyNumber)
              console.log(policyNumber, 'policyNumber');
              obj.profile.customer.policyNumber = policyNumber;
              AddToLocalStorage('globalObj', obj);
            }
          }
        } catch (error) {
          console.log(error, "error in ICICI Payment Success")
        }
      }
    }
    paymentData();
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      let obj = GetDataLocalStorage('globalObj');
      let proposalNumber = obj?.profile?.customer?.proposalNumber;
      dispatch(getKycStatus(proposalNumber));
      setReq(obj);
      const trnsno = obj?.insurance?.TransactionID;
      if (trnsno) {
        try {
          const submitResponseCall = await axios.post(`${vehicle_api}/insurance/v1/motor/policy/payment`, obj);
          console.log(submitResponseCall, 'payment Responseee');
          const policyNumber = submitResponseCall?.data?.body?.Policy_Details?.PolicyNumber;
          setPolicyNumber(policyNumber);
          console.log(policyNumber, 'policyNumber');
          obj.profile.customer.policyNumber = policyNumber;
          AddToLocalStorage('globalObj', obj);
        } catch (error) {
          console.error('Error making payment API call:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.error('TransactionID is not available');
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (kycData && (kycData?.kycVerificationStatus == 'FAILED' || kycData?.kycVerificationStatus == 'NOT_DONE')) {
      setShowKycStatus(false);
      let obj = Object.keys(req).length > 0 ? { ...req } : GetDataLocalStorage('globalObj');
      obj.profile.customer.kycLink = kycData?.link;
      AddToLocalStorage('globalObj', obj);
      setReq(obj);
      setIsLoading(false);
    } else if (kycData && Object.keys(kycData).length > 0) {
      setShowKycStatus(true);
      setIsLoading(false);
    }
    setRefresh(false);
  }, [kycData]);

  const handleClose = (e, reason) => {
    if (reason && reason == 'backdropClick') {
      return;
    } else {
      setRefresh(false);
    }
  };

  const handleRefresh = () => {
    setRefresh(false);
    window.location.reload();
  };
  const getObj = GetDataLocalStorage('globalObj');

  const handleDownload = async () => {
    let policyId = req?.profile?.customer?.applicationId;
    try {
      let res;
      if (download === '') {
        if (download === '' && req?.insurance?.providerName === 'HDFC') {
          res = await axios.post(`${vehicle_api}/insurance/v1/motor/policy/pdf`, getObj);
          let obj = GetDataLocalStorage('globalObj');
          const fileName = `${obj?.profile?.customer?.policyNumber}.pdf`; // Set the desired filename
          const base64String = res.data.filePath;
          const data = base64String.replace(/^data:application\/pdf;base64,/, '');
          const byteCharacters = atob(data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = fileName;
          anchor.style.display = 'none';
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          // Update policy status
          req.profile.customer.policyStatus = 'COM';
        } else if(req?.insurance?.providerName === 'BAJAJ'){
          const request = {
            userid: "webservice@lmv.com",
            password: "newpas12",
            pdfmode: "WS_POLICY_PDF",
            policynum: number,
            insurance: {
                providerName: "BAJAJ"
            }
        }
          res = await axios.post(`${vehicle_api}/insurance/v1/motor/policy/pdf`, request);
          const fileName = `lmv-policy.pdf`;
          console.log(res, "pdf response")
          const base64String = res?.data?.filePath;
          const data = base64String.replace(/^data:application\/pdf;base64,/, '');
          const byteCharacters = atob(data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = fileName;
          anchor.style.display = 'none';
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          req.profile.customer.policyStatus = 'COM';
        } else if (req?.insurance?.providerName === "ICICI") {
          res = await axios.post(
            `${vehicle_api}/insurance/v1/motor/policy/pdf`,
            getObj,
            // { responseType: "arraybuffer" } 
          );
          console.log(res.data, "res of pdf");
          const byteArray = new Uint8Array(res.data.data);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "lmv_customerId.pdf";
          link.click();

          URL.revokeObjectURL(url);
          // Update policy status
          req.profile.customer.policyStatus = "COM";
        }

        //  else {
        //   res = await axios.post(`${vehicle_api}/insurance/v1/car/policy/pdf`, { policyId });
        // }
     
        // Show success message
      } else {
        window.location.href = download;
      }
    } catch (err) {
      console.error('Error:', err); // Log the error message
      alert('Error downloading PDF');
    }
  };

  const handleKycVerification = () => {
    if (req?.profile?.customer?.kycLink) {
      req.profile.customer.policyStatus = 'KYC';
      setRefresh(true);
    } else {
      alert('Internal server Error');
    }
  };

  let inputString = req?.profile?.customer?.applicationId || '-';
  let trimmedString = inputString.slice(0, 13);

  if (isLoading) {
    return (
      <Box sx={{ height: '700px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  const currentDate = new Date();
  // Format the date and time as desired
  const formattedDateTime = currentDate.toLocaleString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  });
  return (
    <div className="payment-container">
      <div className="payment-status-logo-container">
        <div className="payment-status-logo">
          <img src={PaymentSuccessTick} alt="Payment Success" />
        </div>
        <span className="payment-status-title">{showKycStatus ? 'Policy Generated Successfully!' : 'Payment Successful!'}</span>
        {req?.insurance?.providerName === 'BAJAJ' ? <div className="payment-status-stepper">
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={showKycStatus ? 3 : 1} alternativeLabel>
              {steps1.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div> : <div className="payment-status-stepper">
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={showKycStatus ? 3 : 1} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>}
      </div>
      <div className="payment-success-table-container">
        <div className="payment-success-table">
          <div className="payment-success-table-header">
            Amount
            <span style={{ fontWeight: '500', fontSize: '18px' }}>₹ {req?.insurance?.grossPremium}</span>
          </div>
          <div className="payment-success-table-divider">
            <ul className="payment-success-list">
              <li>
                <span>Application ID</span>
                <span className="application-id">{trimmedString || req.CustomerResponse.customer_id || req.insurance.bajajTransactionId}</span>
              </li>
              <li>
                <span>Policy Number</span>
                <span>{PolicyNumber || number}</span>
              </li>
              <li>
                <span>Merchant Name</span>
                <span>{req?.rc?.name || '-'}</span>
              </li>
              <li>
                <span>Payment Method</span>
                <span>Bank Transfer</span>
              </li>
              <li>
                <span>Payment Time</span>
                <span>{formattedDateTime}</span>
              </li>
              <li>
                <span>Policy Provider</span>
                <span>{req?.insurance?.providerName || '-'}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="kyc-failed-container">
          <div className="kyc-failed-content">
            <p className="kyc-failed-content-title" style={{ fontFamily: 'Poppins' }}>
              Download Policy
            </p>
            <p style={{ margin: 'none' }}>
              {showKycStatus ? (
                <>
                  Download Your <span style={{ color: '#23A8FA' }}> Policy</span> Here.
                </>
              ) : (
                <>
                  To view or download your policy <br />
                  details, please update your <span style={{ color: '#23A8FA' }}>KYC.</span>
                </>
              )}
            </p>

            {req?.insurance?.providerName === 'HDFC' || req?.insurance?.providerName === 'ICICI' || req?.insurance?.providerName === 'BAJAJ' ? ( // Check the provider name
              <Button className="kyc-failed-btn">
                <span className="kyc-failed-btn" style={{ fontWeight: '700', color: 'white' }} onClick={handleDownload}>
                  Download
                </span>
              </Button>
            ) : showKycStatus ? (
              <Button className="kyc-failed-btn">
                <span className="kyc-failed-btn" style={{ fontWeight: '700', color: 'white' }} onClick={handleDownload}>
                  Download
                </span>
              </Button>
            ) : ''}

          </div>
          <div style={{ marginLeft: '22px' }}>
            <img src={showKycStatus ? `${downloadPolicyLogo}` : `${kycFailedLogo}`} alt="KYC failed" />
          </div>
        </div>
      </div>
      <div className="payment-success-view-policy">
        {showKycStatus ? (
          <>
            To View Your Policy Details, Please
            <span style={{ color: '#23A8FA' }}> Click Here.</span>
          </>
        ) : (
          <></>
        )}
      </div>
      <div>
        <img src={supportHelpCard} alt="Click For Support" />
      </div>
      <div className="payment-success-button-section">
        {showKycStatus ? (
          <>
            <Button style={{ width: '100%', borderRadius: '10px' }} variant="outlined" onClick={handleDownload}>
              <img src={getReceipt} alt="Get Receipt" />
            </Button>
            <Button
              style={{
                padding: '0.8rem',
                borderRadius: '10px',
                backgroundColor: '#23a8fa',
                color: 'white'
              }}
              onClick={() => navigate('/')}
            >
              Done
            </Button>
          </>
        ) : (
          <Button
            style={{
              padding: '0.8rem',
              borderRadius: '10px',
              backgroundColor: '#23a8fa',
              color: 'white'
            }}
            onClick={handleKycVerification}
          >
            Proceed To KYC
          </Button>
        )}
      </div>
      <Dialog open={refresh} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Please Refresh The Page After KYC Verification !</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRefresh}>Refresh</Button>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentSuccess;
