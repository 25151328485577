import {CLEAR_SUCCESS, SET_SUCCESS} from "../action-type/actionType";

const initialState = {
    success: null,
};

const successReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUCCESS:
            return { ...state, success: action.payload };
        case CLEAR_SUCCESS:
            return { ...state, success: null };
        default:
            return state;
    }
};

export default successReducer;
