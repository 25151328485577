import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  TextField,
  MenuItem,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import { salesSummaryData, salesChannelsData } from "../api/adminService";

import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import { getProductStats } from "../api/adminService";
import { useSelector } from "react-redux";

const BusinessManagement = () => {
  const pagePermissions = useSelector((state) => state.adminReducer.loginData.pages)
  const permissions = pagePermissions.filter((page) => page.pageName === 'Home')[0].permissions;
  const StyledIcon = styled("img")({
    fontSize: "48px",
    color: (theme) => theme.palette.primary.main,
  });

  const [selectedButton, setSelectedButton] = useState("ALL");
  const [period, setPeriod] = useState({
    period: "Week",
    data: [6, 7, 20, 50],
    range: ["Week1 ", "week 2", "week 3", "week 4"],
  });

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Sales Data",
    },
    plotOptions: {
      column: {
        pointWidth: 50,
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    xAxis: {
      categories: period.range,
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      title: {
        text: "Sales",
      },
    },
    series: [
      {
        name: period.period,
        data: period.data,
      },
    ],
  };

  useEffect(() => {
    // getSummaryData();
    // getChannelsData();
  }, []);
  const [summaryData, setSummaryData] = useState([]);
  const [channelsData, setChannelsData] = useState([]);

  const today = new Date();
  const year = today.getFullYear();

  const summaryImages = [
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/Admin/Images/Total.svg",
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/Admin/Images/Non-Term.svg",
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/Admin/Images/Commercial.svg",
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/Admin/Images/Car.svg",
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/Admin/Images/Health.svg",
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/Admin/Images/Travel.svg",
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/Admin/Images/Term.svg",
    "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/Admin/Images/Bike.svg",
  ];

  const cardData = [
    {
      lines: [
        {
          text: "₹ 4546464",
          color: "blue",
          fontSize: "30px",
          fontWeight: "600",
        },
        {
          text: "AGGREGATION",
          color: "blue",
          fontSize: "20PX",
          fontWeight: "500",
        },
        { text: "52.2%", color: "green", fontSize: "18px" },
        { text: "total premium", color: "GREEN", fontSize: "16px" },
        { text: "LIFE 52.2%", color: "BLUE", fontSize: "16px" },
        { text: "CAR 52.2%", color: "GOLD", fontSize: "16px" },
        { text: "BIKE 52.2%", color: "PURPLE", fontSize: "16px" },
        { text: "TERM 52.2%", color: "BROWN", fontSize: "16px" },
        { text: "CV 52.2%", color: "BLUE", fontSize: "16px" },
        { text: "TRAVEL 52.2%", color: "PURPLE", fontSize: "16px" },
        { text: "RENEWALS 52.2%", color: "BROWN", fontSize: "16px" },
      ],
    },
    {
      lines: [
        {
          text: "₹ 4546464",
          color: "brown",
          fontSize: "30px",
          fontWeight: "600",
        },
        { text: "DIY", color: "brown", fontSize: "20PX", fontWeight: "500" },
        { text: "52.2%", color: "green", fontSize: "18px" },
        { text: "total premium", color: "GREEN", fontSize: "16px" },
        { text: "LIFE 52.2%", color: "BLUE", fontSize: "16px" },
        { text: "CAR 52.2%", color: "GOLD", fontSize: "16px" },
        { text: "BIKE 52.2%", color: "PURPLE", fontSize: "16px" },
        { text: "TERM 52.2%", color: "BROWN", fontSize: "16px" },
        { text: "CV 52.2%", color: "BLUE", fontSize: "16px" },
        { text: "TRAVEL 52.2%", color: "PURPLE", fontSize: "16px" },
        { text: "RENEWALS 52.2%", color: "BROWN", fontSize: "16px" },
      ],
    },
    {
      lines: [
        {
          text: "₹ 4546464",
          color: "GOLD",
          fontSize: "30px",
          fontWeight: "600",
        },
        { text: "TELE", color: "GOLD", fontSize: "20PX", fontWeight: "500" },
        { text: "52.2%", color: "green", fontSize: "18px" },
        { text: "total premium", color: "GREEN", fontSize: "16px" },
        { text: "LIFE 52.2%", color: "BLUE", fontSize: "16px" },
        { text: "CAR 52.2%", color: "GOLD", fontSize: "16px" },
        { text: "BIKE 52.2%", color: "PURPLE", fontSize: "16px" },
        { text: "TERM 52.2%", color: "BROWN", fontSize: "16px" },
        { text: "CV 52.2%", color: "BLUE", fontSize: "16px" },
        { text: "TRAVEL 52.2%", color: "PURPLE", fontSize: "16px" },
        { text: "RENEWALS 52.2%", color: "BROWN", fontSize: "16px" },
      ],
    },
    {
      lines: [
        {
          text: "₹ 4546464",
          color: "GREEN",
          fontSize: "30px",
          fontWeight: "600",
        },
        { text: "BRANCH", color: "GREEN", fontSize: "20PX", fontWeight: "500" },
        { text: "52.2%", color: "green", fontSize: "18px" },
        { text: "total premium", color: "GREEN", fontSize: "16px" },
        { text: "LIFE 52.2%", color: "BLUE", fontSize: "16px" },
        { text: "CAR 52.2%", color: "GOLD", fontSize: "16px" },
        { text: "BIKE 52.2%", color: "PURPLE", fontSize: "16px" },
        { text: "TERM 52.2%", color: "BROWN", fontSize: "16px" },
        { text: "CV 52.2%", color: "BLUE", fontSize: "16px" },
        { text: "TRAVEL 52.2%", color: "PURPLE", fontSize: "16px" },
        { text: "RENEWALS 52.2%", color: "BROWN", fontSize: "16px" },
      ],
    },
    {
      lines: [
        {
          text: "₹ 4546464",
          color: "red",
          fontSize: "30px",
          fontWeight: "600",
        },
        {
          text: "FRANCHISE",
          color: "RED",
          fontSize: "20PX",
          fontWeight: "500",
        },
        { text: "52.2%", color: "green", fontSize: "18px" },
        { text: "total premium", color: "GREEN", fontSize: "16px" },
        { text: "LIFE 52.2%", color: "BLUE", fontSize: "16px" },
        { text: "CAR 52.2%", color: "GOLD", fontSize: "16px" },
        { text: "BIKE 52.2%", color: "PURPLE", fontSize: "16px" },
        { text: "TERM 52.2%", color: "BROWN", fontSize: "16px" },
        { text: "CV 52.2%", color: "BLUE", fontSize: "16px" },
        { text: "TRAVEL 52.2%", color: "PURPLE", fontSize: "16px" },
        { text: "RENEWALS 52.2%", color: "BROWN", fontSize: "16px" },
      ],
    },
    {
      lines: [
        {
          text: "₹ 4546464",
          color: "PURPLE",
          fontSize: "30px",
          fontWeight: "600",
        },
        {
          text: "OFFLINE",
          color: "PURPLE",
          fontSize: "20PX",
          fontWeight: "500",
        },
        { text: "52.2%", color: "green", fontSize: "18px" },
        { text: "total premium", color: "GREEN", fontSize: "16px" },
        { text: "LIFE 52.2%", color: "BLUE", fontSize: "16px" },
        { text: "CAR 52.2%", color: "GOLD", fontSize: "16px" },
        { text: "BIKE 52.2%", color: "PURPLE", fontSize: "16px" },
        { text: "TERM 52.2%", color: "BROWN", fontSize: "16px" },
        { text: "CV 52.2%", color: "BLUE", fontSize: "16px" },
        { text: "TRAVEL 52.2%", color: "PURPLE", fontSize: "16px" },
        { text: "RENEWALS 52.2%", color: "BROWN", fontSize: "16px" },
      ],
    },
  ];

  const data = [
    "#4F45B6",
    "#F8C140",
    "#CE25D1",
    "#006EBE",
    "#37C14D",
    "#FF6B00",
    "#64C4F7",
    "#FF9900",
  ];

  const handlePeriodChange = (e) => {
    const { value } = e.target;
    if (value === "Week") {
      setPeriod({
        period: "Week",
        data: [6, 7, 20, 50],
        range: ["Week1 ", "week 2", "week 3", "week 4"],
      });
    }

    if (value === "Month") {
      setPeriod({
        period: "Month",
        data: [6, 7, 20, 50, 15, 38, 30, 15, 10, 14, 11, 13],
        range: [
          "Jan ",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      });
    }
    if (value === "Year") {
      setPeriod({
        period: "Year",
        data: [500, 705, 970, 830, 653],
        range: ["2020", "2021", "2022", "2023", "2024"],
      });
    }
  };

  const [stats, setStats] = useState({
    totalsales: "0",
    totalsalesbyproduct: [],
  });

  const getStats = async () => {
    const res = await getProductStats();
    console.log(res.data, "response");
    const productTypeMap = {
      TVL: "Travel",
      HLT: "Health",
      MOT: "Motor",
      LON: "Loan",
    };
    const updatedTotalsalesbyproduct = res.data.totalsalesbyproduct.map(
      (item) => {
        return {
          ...item,
          product_type: productTypeMap[item.product_type] || item.product_type,
        };
      }
    );
    const updatedResponse = {
      ...res.data,
      totalsalesbyproduct: updatedTotalsalesbyproduct,
    };

    console.log(updatedResponse, "updated response");
    setStats(updatedResponse);
  };

  useEffect(() => {
    // getStats();
  }, []);



  return (
    <Grid container sx={{ backgroundColor: "#f0f0f0", padding: "20px" }}>

      <Grid item xs={12} md={12}>
        <Grid container direction={"row"} spacing={3} justifyContent={"end"}>
          <Grid item xs={6}>
            <Typography variant="h4">Business/Sales</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              onChange={(e) => setSelectedButton(e.target.value)}
              value={selectedButton}
              sx={{ minWidth: "120px" }}
              select
              label="Product Type"
              size="small"
            >
              <MenuItem value="ALL">All</MenuItem>
              <MenuItem value="HEALTH">Health</MenuItem>
              <MenuItem value="MOTOR">Motor</MenuItem>
              <MenuItem value="TRAVEL">Travel</MenuItem>
              <MenuItem value="LOAN">Loan</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Card sx={{ boxShadow: 3, position: "relative", borderRadius: 4 }}>
          <CardContent>
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={8}>
                <Typography variant="h4" gutterBottom>
                  Bussiness Data
                </Typography>
              </Grid>
              <Box>
                <Grid sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    sx={{ minWidth: "120px" }}
                    value={period.period}
                    label="Sort By"
                    size="small"
                    select
                    onChange={handlePeriodChange}
                  >
                    <MenuItem value="Week">Week</MenuItem>
                    <MenuItem value="Month">Month</MenuItem>
                    <MenuItem value="Year">Year</MenuItem>
                  </TextField>
                </Grid>
              </Box>
              <Grid item xs={11} justifyContent="center">
                <HighchartsReact highcharts={Highcharts} options={options} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* channel data */}
      <Grid item xs={12}>
        <Grid container>
          {stats.totalsalesbyproduct.map((item, index) => (
            <Grid sx={{ p: 1 }} key={index} item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ boxShadow: 3, position: "relative", borderRadius: 4 }}
              >
                <CardContent>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={9} justifyContent="center">
                      <Typography
                        variant="h4"
                        color={data[index]}
                        gutterBottom
                      >
                        {item.count}
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom>
                        {item.product_type}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="#808080"
                        fontSize={15}
                      >
                        <ArrowCircleUpOutlinedIcon />
                        {"+15%"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} justifyContent="center">
                      {summaryImages && (
                        <StyledIcon src={summaryImages[index]} />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          Channels
        </Typography>
        <Grid container>
          {cardData.map((card, cardIndex) => (
            <Grid key={cardIndex} item xs={12} sm={6} md={3} sx={{ p: 1 }}>
              <Card sx={{ boxShadow: 3, borderRadius: 4 }}>
                <CardContent>
                  {card.lines.slice(0, 4).map((line, lineIndex) => (
                    <Typography
                      key={lineIndex}
                      variant="body1"
                      style={{
                        color: line.color,
                        fontSize: line.fontSize,
                        fontWeight: line.fontWeight,
                        marginTop: "5px",
                      }}
                    >
                      {line.text}
                    </Typography>
                  ))}

                  <Divider
                    variant="middle"
                    sx={{ backgroundColor: "gray", mt: 1, mb: 2 }}
                  />

                  {card.lines.slice(4).map((line, lineIndex) => (
                    <Typography
                      key={lineIndex}
                      variant="body1"
                      style={{
                        color: line.color,
                        fontSize: line.fontSize,
                        fontWeight: line.fontWeight,
                        marginTop: "5px",
                      }}
                    >
                      {line.text}
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BusinessManagement;
