import React, { useState, useEffect } from 'react';
import '../../Pages/Car Pages/CarRegNumberFetching.css';
// import bump from "../../../Images/Car-Page/bump.png";
// import fenderbender from "../../../Images/Car-Page/fender-bender.png";
// import carwash from "../../../Images/Car-Page/car-wash.png";
// import carmbg from "../../../Images/Car-Page/Web-Page-For-Car-Main-2.jpeg";
import RegistrationNumberFetching from '../../Components/RegistrationNumberFetching/RegistrationNumberFetching';
import { GetDataLocalStorage } from '../../../Framework/GlobalObj';
import cv1 from '../../Images/commercial-page/cv-02.svg';
import img1 from '../../Images/commercial-page/Layer 9.png';
import img2 from '../../Images/commercial-page/Layer 8.png';
import img3 from '../../Images/commercial-page/Layer 10.png';
import RoadImg from '../../Images/commercial-page/bluetruck.gif';
import { useNavigate } from 'react-router';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const CommercialVehicleRegNumberFetching = () => {
  const [req, setreq] = useState({});
  const naviagte = useNavigate();

  useEffect(() => {
    let obj = GetDataLocalStorage('globalObj');
    setreq(obj);
  }, []);

  const handlebackBtn = () => {
    naviagte('/');
  };
  return (
    <div>
      {/* <div className="mx-4 row justify-content-between">
        Left Image 
        <div className="col-lg-6 col-md-12 rounded-5">
          <img
            src={cv1}
            alt=""
            style={{
              width: '100%',
              height: '100%',
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
            }}
            className="rounded-5 border mt-3"
          />
        </div>

        Fetch Vehicle Number Section

        <RegistrationNumberFetching req={req} setreq={setreq} />
      </div> */}

      <div className="registration-number-top-section">
        {/* <div className="bg-road-car">

        </div> */}
        <div className="back_icon">
          <KeyboardBackspaceIcon onClick={handlebackBtn} />
        </div>
        <img src={RoadImg} alt="" className="roadimg" />
        <RegistrationNumberFetching req={req} setreq={setreq} />
      </div>

      <div className="mt-5 text-center justify-content-center">
        {/* Car Insurance & Its Types (Description) */}
        <h1 className="fw-bold">Commercial Vehicle Insurance & Its Types</h1>
        <p>Commercial is one of the most important and beloved item of possession for any individual.In simple language, Car Insurance is a contract between the Customer and the Insurance Company where in you pay a premium for a policy that will provide protection against every comprehensible risk related to your vehicle, damage, theft, death and injury in an accident, plus liability protection in case you are sued as a result of an accident (Third Party Liability).</p>
        <div className="row mt-5">
          <div className="col-lg-4 col-md-12">
            <img src={img1} alt="" style={{ width: '20%' }} />
            <p style={{ fontSize: '22px', fontWeight: '500' }}>Liability Coverage</p>
            <p>Covers vehicles used for business, providing liability and property damage coverage.</p>
          </div>
          <div className="col-lg-4 col-md-12">
            <img src={img2} alt="" style={{ width: '60%' }} />
            <p style={{ fontSize: '22px', fontWeight: '500' }}>Collision Coverage</p>
            <p>Specifically for trucks used in commercial operations, offering specialized coverage for liability, cargo, and physical damage.</p>
          </div>
          <div className="col-lg-4 col-md-12">
            <img src={img3} alt="" className="my-2" style={{ width: '20%' }} />
            <p style={{ fontSize: '22px', fontWeight: '500' }}>Comprehensive Coverage</p>
            <p>Covers multiple vehicles under a single policy, providing comprehensive coverage for businesses with a fleet of vehicles.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommercialVehicleRegNumberFetching;
