import React from "react";

const TravelOfflineForm = React.lazy(() => import("./Travel/TravelOfflineForm"));
const LoanOfflineForm = React.lazy(() => import("./Loan/LoanOfflineForm"));
const HealthOfflineForm = React.lazy(() => import("./Health/HealthOfflineForm"));
const VehicleCarOfflineForm = React.lazy(() => import("./Vehicle/VehicleCarOfflineForm"));
const VehicleBikeOfflineForm = React.lazy(() => import("./Vehicle/VehicleBikeOfflineForm"));
const VehicleCommercialOfflineForm = React.lazy(() => import("./Vehicle/VehicleCommercialOfflineForm"));
const LifeOfflineForm = React.lazy(()=> import("./Life/LifeOfflineForm")); 
export {
    TravelOfflineForm, LoanOfflineForm, HealthOfflineForm,
    VehicleCarOfflineForm, VehicleBikeOfflineForm,
    VehicleCommercialOfflineForm,
    LifeOfflineForm
}