import { useRoutes } from 'react-router-dom';

// routes
import PospRoutes from './PospRoutes';
import ExaminationRoutes from './ExaminationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {
  return useRoutes([PospRoutes,ExaminationRoutes]);
}
