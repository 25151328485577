import React from "react";
import paymentFailedCross from "../../Images/Car-Page/Payment-Page/paymentfailedcross.svg";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import supportHelpCard from "../../Images/Car-Page/Payment-Page/HelpCenterSupport.svg";
import Button from "@mui/material/Button";

const PaymentFailed = () => {
  const steps = ["Payment", "KYC", "Policy"];
  return (
    <div className="payment-container">
      <div className="payment-status-logo-container">
        <div className="payment-status-logo">
          <img src={paymentFailedCross} alt="Payment Success" />
        </div>
        <span className="payment-status-title">Payment Failed!</span>
        <div className="payment-status-stepper">
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={0} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
      </div>
      <div className="payment-success-table">
        <div className="payment-success-table-header">
          Amount
          <span style={{ fontWeight: "500", fontSize: "18px" }}>
            1,000,000 ₹
          </span>
        </div>
        <div className="payment-success-table-divider">
          <div className="payment-success-table-body">
            <div className="payment-success-table-body-title">
              <div style={{ marginBottom: "7px" }}>Application ID</div>
              <div style={{ marginBottom: "7px" }}>Policy Number</div>
              <div style={{ marginBottom: "7px" }}>Merchant Name</div>
              <div style={{ marginBottom: "7px" }}>Payment Method</div>
              <div style={{ marginBottom: "7px" }}>Payment Time</div>
              <div style={{ marginBottom: "7px" }}>Policy Provider</div>
            </div>
            <div className="payment-success-table-body-status">
              <div style={{ marginBottom: "7px", textAlign: "right" }}>
                <span style={{ fontWeight: "400" }}>000085752257</span>
              </div>
              <div style={{ marginBottom: "7px", textAlign: "right" }}>
                13135626566
              </div>
              <div style={{ marginBottom: "7px", textAlign: "right" }}>
                Otomoto Car
              </div>
              <div style={{ marginBottom: "7px", textAlign: "right" }}>
                Bank Transfer
              </div>
              <div style={{ marginBottom: "7px", textAlign: "right" }}>
                Mar 22, 2023, 13:22:16
              </div>
              <div style={{ marginBottom: "7px", textAlign: "right" }}>
                Jason Momoa
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="payment-success-view-policy">
        To Try Again, Please
        <span style={{ color: "#23A8FA" }}> Click Here.</span>
      </div>
      <div style={{ marginTop: "30px" }}>
        <img src={supportHelpCard} alt="Click For Support" />
      </div>
      <div className="payment-success-button-section">
        <Button
          style={{ width: "100%", borderRadius: "10px", marginTop: "12px" }}
          variant="contained"
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default PaymentFailed;
