import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  MenuItem,
  Box,
  Paper,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { RecentPolicyCard } from "./RecentPolicyCard";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { clearSuccessMessage } from "../../../../Redux/action-creators/successAction";
import {
  clearError,
  setError,
} from "../../../../Redux/action-creators/errorAction";

import {
  getPoliciesDataById,
  getProviderLogo,
} from "../../api/customerService";

import PerfectScrollbar from "react-perfect-scrollbar";
import FilterBar from "../../../posp/ui-component/extended/FilterBar";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router";

// import { getProviderLogo } from '../../customer/api/customerService';

const Policies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const [logos, setLogos] = useState();
  const [selectedStatusFilter, setSelectedStatusFilter] = useState("ALL");
  const [selectedProductFilter, setSelectedProductFilter] = useState("All");
  const [policies, setPolicies] = useState([]);
  const [policiesCopy, setPoliciesCopy] = useState([]);
  const [isLoaderTrue, setIsLoaderTrue] = useState(false);
  const [initialLoaderTrue, setInitialLoaderTrue] = useState(true);

  const customer_id = useSelector(
    (state) => state?.customer?.data?.customer_id
  );

  useEffect(() => {
    getProvidersLogo();
    if (customer_id) {
      getPolicies();
    }
  }, [customer_id]);

  useEffect(() => {
    filterPolicies();
  }, [selectedStatusFilter, selectedProductFilter]);

  const getProvidersLogo = async () => {
    try {
      const response = await getProviderLogo();
      if (response.status > 400) {
      } else {
        setLogos(response?.data);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  const getLogoUrl = (provider) => {
    const logoUrl = logos.filter((data) => data.provider === provider);
    return logoUrl[0]?.logo;
  };

  const getPolicies = async () => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    setInitialLoaderTrue(true);
    try {
      const response = await getPoliciesDataById(customer_id);
      if (response?.status > 400) {
        dispatch(setError(t("lmv.serverError")));
      } else {
        setPolicies(response?.data);
        setPoliciesCopy(response?.data);
      }
    } catch (error) {
      dispatch(setError(t("lmv.serverError") + error.toString()));
    }
    setInitialLoaderTrue(false);
  };

  const handleFilterChange = (event) => {
    setSelectedStatusFilter(event.target.value);
  };

  const handleProductFilterChange = (filter) => {
    setSelectedProductFilter(filter);
  };

  const productTypeMap = {
    Health: "HEALTH",
    Motor: "MOTOR",
    Travel: "TRAVEL",
    Loan: "LOAN",
  };

  const filterPolicies = () => {
    let filteredPolicies = policies;
    setIsLoaderTrue(true);
    // Filter by status
    if (selectedStatusFilter !== "ALL") {
      filteredPolicies = filteredPolicies?.filter((policy) => {
        if (selectedStatusFilter === "APPROVED") {
          return policy.policy_status === "APPROVED";
        }
        if (selectedStatusFilter === "PENDING") {
          return policy.policy_status === "PENDING";
        }
        if (selectedStatusFilter === "RELOOK") {
          return policy.policy_status === "RELOOK";
        }
        return false;
      });
    }

    // Filter by product type
    if (selectedProductFilter !== "All") {
      const productTypeAbbreviation = productTypeMap[selectedProductFilter];
      filteredPolicies = filteredPolicies.filter(
        (policy) => policy?.product_type === productTypeAbbreviation
      );
    }
    setIsLoaderTrue(false);
    setPoliciesCopy(filteredPolicies);
  };

  const statusOptions = [
    { value: "ALL", label: t("lmv.customer.all") },
    { value: "APPROVED", label: t("lmv.customer.approved") },
    { value: "PENDING", label: t("lmv.customer.pending") },
    { value: "RELOOK", label: t("lmv.customer.relook") },
  ];

  const getEmptyText = () => {
    return (
      <Box
        sx={{
          bgcolor: "#f5f8fd",
          minHeight: "calc(100vh - 130px)",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={12} sx={{ padding: "20px" }}>
            <Paper sx={{ padding: "20px", borderRadius: "8px" }}>
              <Typography variant="h2" sx={{ color: "#00a3ff" }}>
                Your policies
              </Typography>
              <Typography variant="body" sx={{ mt: 2 }}>
                Manage your insurance policies & renewals
              </Typography>
              <Box
                sx={{
                  mt: 4,
                  textAlign: "center",
                  padding: "40px",
                  border: "2px dashed #d9d9d9",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="h5">
                  You don’t have any policies yet!
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Buy an insurance policy to protect your family & assets now
                </Typography>
                <Button
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={() => navigate("/customer/dashboard")}
                >
                  Explore
                </Button>
              </Box>
            </Paper>

            {/* <Box sx={{ mt: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Unlock special offers just for you
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </Box> */}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const Loader = () => {
    return (
      <Box
        sx={{
          height: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  };

  return (
    <>
      {initialLoaderTrue ? (
        Loader()
      ) : policies.length !== 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <FilterBar
              selectedFilter={selectedProductFilter}
              onFilterClick={handleProductFilterChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography variant="h3">Policies</Typography>
              </Grid>

              <Grid item>
                <TextField
                  id="standard-select-currency"
                  select
                  value={selectedStatusFilter}
                  onChange={handleFilterChange}
                  variant="outlined"
                  label=""
                  style={{ width: "120px" }}
                >
                  {statusOptions?.map((option, id) => (
                    <MenuItem key={id} value={option?.value}>
                      {option?.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {isLoaderTrue ? (
              <Box
                sx={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            ) : policiesCopy?.length !== 0 ? (
              <PerfectScrollbar
                component="div"
                style={{
                  height: "calc(100vh - 240px)",
                  paddingRight: "12px",
                }}
              >
                <Grid container spacing={3}>
                  {policiesCopy?.map((policy, index) => (
                    <RecentPolicyCard
                      key={uuidv4()} // temporary used uuid but in future use application id
                      policy={policy}
                      logo={getLogoUrl(policy?.provider)}
                    />
                  ))}
                </Grid>
              </PerfectScrollbar>
            ) : (
              <Box
                sx={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h3">Empty</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      ) : (
        getEmptyText()
      )}
    </>
  );
};

export default Policies;

