import axios from 'axios';
import { FETCH_POSP_FAILURE, FETCH_POSP_SUCCESS , COMPLETE_STEP, RESET_STEPPER } from '../../action-type/posp/actionType';

export const fetchPospData = (phone) => {

    return async (dispatch) => {
        try {
            const response = await axios.get(`https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/profileDetails/${phone}`);
            dispatch({ type: FETCH_POSP_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: FETCH_POSP_FAILURE, payload: error.message });
        }
    };
};
            