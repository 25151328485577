import React, {  useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { fetchRenewalData, updatePolicyStatus } from "../api/adminService";
import {
  Divider,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { handleRenewalNotification } from "../../../Redux/admin/action";
import { useDispatch, useSelector } from "react-redux";

const dateFormat = (date) => {
  const dateObj = new Date(date);
  const [y, m, d] = dateObj.toISOString().split("T")[0].split("-");
  return `${d}-${m}-${y}`;
};

export default function DataGridDemo() {
  const renewalData = useSelector(
    (state) => state.adminReducer.RenewalNotificationData
  );
  const dispatch = useDispatch();
  const rows = renewalData;

  const getRenewalData = async () => {
    try {
      let response = await fetchRenewalData();
      const addID = (data) => data.map((row, id) => ({ ...row, id }));
      dispatch(handleRenewalNotification(addID(response.data)));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRenewalData();
  }, [dispatch]);

  const columns = [
    { field: "org_id", headerName: "Organization ID", width: 150 },
    { field: "agent_id", headerName: "Agent ID", width: 150 },
    { field: "customer_id", headerName: "Customer ID", width: 150 },
    {
      field: "product_type",
      headerName: "Product Type",
      width: 150,
      valueGetter: (params) =>
        params === "TVL"
          ? "TRAVEL"
          : params === "HLT"
          ? "HEALTH"
          : params === "LON"
          ? "LOAN"
          : params === "MOT"
          ? "MOTOR"
          : null,
    },
    { field: "rc_number", headerName: "RC Number", width: 150 },
    { field: "policy_type", headerName: "Policy Type", width: 150 },
    { field: "policy_sub_type", headerName: "Policy Sub Type", width: 150 },
    { field: "provider", headerName: "Provider", width: 150 },
    { field: "policy_number", headerName: "Policy Number", width: 200 },
    { field: "application_id", headerName: "Application ID", width: 150 },
    {
      field: "created_date",
      headerName: "Created Date",
      width: 150,
      valueGetter: (params) => dateFormat(params),
    },
    {
      field: "policy_startdate",
      headerName: "Policy Start Date",
      width: 150,
      valueGetter: (params) => dateFormat(params),
    },
    {
      field: "policy_enddate",
      headerName: "Policy End Date",
      width: 150,
      valueGetter: (params) => dateFormat(params),
    },
    {
      field: "policy_status",
      headerName: "Policy Status",
      width: 150,
      renderCell: (params) => {
        const { customer_id, policy_status } = params.row;
        const handleStatusChange = async (event) => {
          const newStatus = event.target.value;
          try {
            await updatePolicyStatus(customer_id, { status: newStatus });
            getRenewalData();
          } catch (error) {
            console.error("Error updating policy status:", error);
          }
        };

        return (
          <FormControl fullWidth sx={{ m: 1 }}>
            <Select
              labelId={`status-select-${customer_id}`}
              value={policy_status}
              onChange={handleStatusChange}
              size="small"
            >
              <MenuItem value="PENDING">Pending</MenuItem>
              <MenuItem value="RENEWED">Renewed</MenuItem>
              <MenuItem value="LOST">Lost</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
  ];

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }} p={2}>
          Renewal Notification Panel
        </Typography>
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid item xs={12}>
        <Box
          sx={{
            height: "calc(100vh - 180px)",
            width: "100%",
            "& .actions": { color: "text.secondary" },
            "& .textPrimary": { color: "text.primary" },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 12,
                },
              },
            }}
            pageSizeOptions={[5, 10, 12]}
            disableRowSelectionOnClick
          />
        </Box>
      </Grid>
    </Grid>
  );
}
