import { Children, lazy } from 'react';

// project imports
import Loadable from '../ui-component/Loadable';


// dashboard routing
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const PersonalDetails = Loadable(lazy(() => import('../pages/personaldetails/PersonalDetails')));
const Bookings = Loadable(lazy(() => import('../pages/Bookings')));
const PolicyClaim = Loadable(lazy(() => import('../pages/policyclaims/PolicyClaim')));
const Settings = Loadable(lazy(() => import('../../posp/pages/Settings')));
const Support = Loadable(lazy(() => import('../../posp/pages/Support')));
const Main = Loadable(lazy(() => import('../pages/Main')));
const MotorPolicy = Loadable(lazy(() => import('../../posp/pages/offlinePolicy/MotorPolicy/index')));
const PospLayout = Loadable(lazy(() => import('../../posp/layout/MainLayout')));
const HealthPolicy = Loadable(lazy(() => import('../pages/offlinePolicy/HealthPolicy')));
const LoanPolicy = Loadable(lazy(() => import('../pages/offlinePolicy/LoanPolicy')));
const TravelPolicy = Loadable(lazy(() => import('../pages/offlinePolicy/TravelPolicy')));
const Payouts = Loadable(lazy(() => import('../pages/payouts')))
const Invoice = Loadable(lazy(()=> import('../pages/invoice')))


// ==============================|| MAIN ROUTING ||============================== //

const PospRoutes = {
  path: '/',
  element: <PospLayout />,
  children: [
    {
      path: '/home',
      element: <Main />
    },
    {
      path: '/dashboard',
      element: <Dashboard />
    },
    {
      path: '/personaldetails',
      element: <PersonalDetails />
    },
    {
      path: '/bookings',
      element: <Bookings />
    },
    {
      path: '/health',
      element: <HealthPolicy />
    },
    {
      path: '/motor',
      element: <MotorPolicy />
    },
    {
      path: '/loan',
      element: <LoanPolicy />
    },
    {
      path: '/travel',
      element: <TravelPolicy />
    },
    {
      path: '/policyclaims',
      element: <PolicyClaim />
    },
    {
      path: '/payouts',
      element: <Payouts />
    },
    {
      path: '/invoice',
      element: <Invoice />
    },
    {
      path: '/settings',
      element: <Settings />
    },
    {
      path: '/helpline',
      element: <Support />
    },
    
  ]
};

export default PospRoutes;  
