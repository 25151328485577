export const SalesData = "SalesData";
export const SelfInspecData = 'SelfInspecData';
export const profileDetails = "profileDetails";
export const pendingCasesData = "pendingCases";
export const UserIdManagement = "UserIdManagement";
export const LOGINDATA = 'LOGINDATA';
export const BRANCHDATA = 'BRANCHDATA';
export const SingleRow = "SingleRow";
export const NEWEMPLOYEE = "NEWEMPLOYEE";
export const RenewalNotification = "RenewalNotification";
export const RENEWALS = "RENEWALS";
export const Other_Payouts = "Other_Payouts";
export const TOGGLE_FORM = 'TOGGLE_FORM'
