import './VehicleDetails.css';
import { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, MenuItem, Select, Modal, Box, FormControl, FormControlLabel, RadioGroup, Radio, Autocomplete, TextField, Paper } from '@mui/material';
import { getNCBValue, getInsurerName, getSmsResponse, getSmsVerificationResponse, getVehicleData, getCityData, getVehicleMaster, NCBSuccessRequest, InsurerNamesSuccessRequest, getSuccessVehicleMaster, citySuccessRequest, getQuotationData, getSuccessPincode, getCityStateWithPincode, getUsageType, addingCustomerData } from '../../../Redux/Action';
import moment from 'moment';
import { AddToLocalStorage, GetDataLocalStorage } from '../../../Framework/GlobalObj';
import { mobileValidate, otpValidation } from '../../validation/ValidationSchema';
import { AlertError } from '../../../Framework/Toaster/AlertError';
import MakeModalVarient from '../Models/MakeModalVarient/MakeModalVarient';
import AddressRtoModal from '../Models/StateCityCode/AddressRtoModal';
import { VehicleMainCode } from '../../../Framework/Utils/VehicleMainCode';
import CommercialMakeModelVarient from '../Models/MakeModalVarient/CommercialMakeModelVarient';
import { sendOtp } from '../../../Framework/Add&SendOTP';
 
const VehicleDetails = () => {
  const [city, setCity] = useState('Eg: AN01-PORT BLAIR(Andaman & Nicobar Islands)');
  const [isNext, setIsNext] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [showNCBValue, setShowNCBValue] = useState(true);
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const navigate = useNavigate();
  const [manufactureDate, setmanufactureDate] = useState('');
  const [formData, setFormData] = useState({});
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [isVehicleNew, setIsVehicleNew] = useState(false);
  const [isVehicleWithoutNumber, setIsVehicleWithoutNumber] = useState(false);
  const [openMakeModel, setOpenMakeModel] = useState(false);
  const [openAddressModel, setOpenAddressModel] = useState(false);
  const [isEditButtonClick, setIsEditButtonClick] = useState(false);
  const [ispreviousinsurer, setIsPreviousInsurer] = useState('true');
  const [vehiclemaincodearr, setVehicleMainCodeArr] = useState([]);
  const [subCategory, setSubcategory] = useState('private');
  const [isVerificationError, setIsVerificationError] = useState(false);
  const [otpSentSuccess, setOtpSentSuccess] = useState(false);
  const [transactionID, setTransactionID] = useState('');
  const inputRef = useRef(null);
 
  // Fetching Data From Redux Store
 
  const vehicleData = useSelector((store) => store.motorReducer.vehicleData);
  const cityData = useSelector((store) => store.motorReducer.cityData);
  const insuranceData = useSelector((store) => store.motorReducer.insurerNames);
  const ncbData = useSelector((store) => store.motorReducer.ncbData);
  const vehicleMaster = useSelector((store) => store.motorReducer.vehicleMaster);
  const pincodeData = useSelector((store) => store.motorReducer.pincodeData);
  const smsResponse = useSelector((store) => store.motorReducer.smsResponse);
  const smsVerificationResponse = useSelector((store) => store.smsVerificationResponse);
  const loginData = useSelector(state => state.adminReducer.loginData)
  console.log(loginData, 'useSelector(state => state)');

  const dispatch = useDispatch();
 
  //***/ useEffect for fetching data when page refresh if it is not saved in local storage/***/
  useEffect(() => {
    let ncbData = GetDataLocalStorage('NcbData');
    let insurenceName = GetDataLocalStorage('InsurenceCompanyName');
    let vehicleMaster = GetDataLocalStorage('vehicleMasterData');
    let cityData = GetDataLocalStorage('cityData');
    let obj = GetDataLocalStorage('globalObj');
    let pincode = GetDataLocalStorage('pincodeData');
    ncbData ? dispatch(NCBSuccessRequest(ncbData)) : dispatch(getNCBValue());
    insurenceName ? dispatch(InsurerNamesSuccessRequest(insurenceName)) : dispatch(getInsurerName());
    vehicleMaster ? dispatch(getSuccessVehicleMaster(vehicleMaster)) : dispatch(getVehicleMaster(obj?.profile?.customer?.policyType));
    cityData ? dispatch(citySuccessRequest(cityData)) : dispatch(getCityData());
    pincode ? dispatch(getSuccessPincode(pincode)) : dispatch(getCityStateWithPincode());
 
    dispatch(getUsageType());
    if (obj?.vehicle?.isVehicleNew) {
      setIsVehicleNew(true);
    } else if (obj?.rc?.number !== '') {
      dispatch(getVehicleData(obj?.rc?.number));
    } else {
      setIsVehicleWithoutNumber(true);
    }
    setFormData(obj);
    setmanufactureDate(obj?.vehicle?.manufactureDate);
  }, []);
 
  // Filtering City API
 
  useEffect(() => {
    if (cityData.length > 0) {
      let obj = Object.keys(formData).length > 1 ? { ...formData } : GetDataLocalStorage('globalObj');
 
      const userCity = cityData?.filter((ele) => {
        return ele['RTO Code'] == (obj?.rc?.number).slice(0, 4);
      });
      if (userCity.length > 0) {
        let arr = userCity[0];
        obj.vehicle.vehicleRegisteredState = userCity[0].registered_state_name;
        obj.vehicle.vehicleRegisteredCity = arr.REGISTERED_CITY_NAME;
        setFormData(obj);
      }
      setCity(userCity);
    }
  }, [cityData, vehicleData]);
 
  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);

 let formattedDate
 
  useEffect(() => {
    if (Object.keys(vehicleData).length > 0 && vehicleData?.regDate != null) {
      let obj = Object.keys(formData).length > 1 ? { ...formData } : GetDataLocalStorage('globalObj');
      obj.rc.number = vehicleData?.regNo;
      let formattedDate = vehicleData?.regDate?.replace(/\//g, '-');
      obj.rc.registrationDate = formattedDate;
      obj.rc.name = vehicleData?.owner.toUpperCase();
      obj.rc.rtoCode = vehicleData?.rtoCode;
      obj.rc.status = vehicleData?.status;
      obj.vehicle.category = vehicleData?.vehicleCategory;
      obj.vehicle.engineNumber = vehicleData?.engine;
      obj.vehicle.chasisNumber = vehicleData?.chassis;
      obj.vehicle.fuelType = vehicleData?.type;
      obj.vehicle.manufactureDate = vehicleData?.vehicleManufacturingMonthYear;
      obj.vehicle.vehicleCc = vehicleData?.vehicleCubicCapacity

      if (vehicleData?.regDate) {
        const formattedRegDate = vehicleData?.regDate?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1'); // Converts dd/mm/yyyy to yyyy-mm-dd
        obj.rc.registrationDate = formattedRegDate;
        obj.vehicle.registrationDate = formattedRegDate;
      }
      if (vehicleData?.vehicleManufacturingMonthYear) {
        const [month, year] = vehicleData?.vehicleManufacturingMonthYear?.split('/');
        setmanufactureDate(`${year}-${month.padStart(2, '0')}`)
      }
      if (vehicleData?.vehicleInsuranceUpto) {
        const formattedRegDate = vehicleData?.vehicleInsuranceUpto?.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1'); // Converts dd/mm/yyyy to yyyy-mm-dd
        obj.previousPolicyDetails.policyEndDate = formattedRegDate;
      }

      let vehiclemaincode = VehicleMainCode(vehicleData?.model, vehicleData?.vehicleManufacturerName, vehicleData?.type);
 
      console.log(vehiclemaincode[0], "vehiclemaincode")
      if (vehiclemaincode.length > 0) {
        obj.vehicle.vehicleMaincode = vehiclemaincode[0]['Vehicle Code'];
        obj.vehicle.makerModel = vehiclemaincode[0]?.Make;
        obj.vehicle.model = vehiclemaincode[0]?.Model
        obj.vehicle.variant = vehiclemaincode[0]?.Variant
        setVehicleMainCodeArr(vehiclemaincode);
      } else {
        setIsVehicleWithoutNumber(true);
        setOpenMakeModel(true);
      }
      insuranceData.forEach((el) => {
        if (el.Name == vehicleData?.vehicleInsuranceCompanyName) {
          obj.previousPolicyDetails.policyCompany = vehicleData?.vehicleInsuranceCompanyName;
        }
      });
      setFormData(obj);
    }
  }, [vehicleData]);
 
  const handleChangeispreviousinsurer = (e) => {
    setIsPreviousInsurer(e.target.value);
    if (e.target.value === 'true') {
      const updatedFormData = { ...formData };
      updatedFormData.motorBreakIn.isBreakin = true;
      setFormData(updatedFormData);
    }
  };
 
  const handleNextModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') {
      return;
    } else {
      setOpenOtp(false);
      setOpenMakeModel(false);
      setOpenAddressModel(false);
    }
  };
 
  const handleChangeOtp = ({ target }, index) => {
    const { value } = target;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    if (!value) {
      setActiveOtpIndex(index - 1);
    } else {
      setActiveOtpIndex(index + 1);
    }
    setOtp(newOtp);
  };
 
  const handleChangePreviousInsurerAutocomplete = (e, name) => {
    let objInsurer = { ...formData.previousPolicyDetails }; // Create a copy of previousPolicyDetails
    let objMotorBreakIn = { ...formData.motorBreakIn }; // Create a copy of motorBreakIn
    if (name == 'policyEndDate') {
      // Update policyEndDate
      objInsurer.policyEndDate = e.target.value;
      // Set isBreakin based on condition
      const isBreakin = new Date(e.target.value) < new Date();
      objMotorBreakIn.isBreakin = isBreakin;
      // objInsurer.isBreakin = formData?.previousPolicyDetails?.isPreviousInsurerKnown === true && formData?.previousPolicyDetails?.isPreviousInsurerKnown === false
    } else if (name == 'policyCompany') {
      objInsurer = {
        ...formData.previousPolicyDetails,
        [name]: e.target.innerHTML
      };
      // objInsurer[name] = e.target.value;
    }
    // Update the formData object with the modified values
    setFormData({
      ...formData,
      previousPolicyDetails: objInsurer,
      motorBreakIn: objMotorBreakIn
    });
  };
 
  // Handling All The Input Fields
  const handleChangeRc = (e) => {
    let uppercaseValue = e.target.value.toUpperCase();
    if (e.target.name === 'name') {
      let objrc = { ...formData.rc, [e.target.name]: uppercaseValue };
      setFormData({ ...formData, rc: objrc });
    } else {
      let mobileObj = { ...formData.rc, [e.target.name]: uppercaseValue };
      setFormData({ ...formData, rc: mobileObj });
    }
  };
 
  const handleChangeInsurence = (e) => {
    console.log(e.target.value);
    let ncbObj = { ...formData.insurance, [e.target.name]: e.target.value };
    setFormData({ ...formData, insurance: ncbObj });
  };
 
  const handleChangePincode = (e) => {
    let addressobj = { ...formData.insurance.kyc.addresses, ['pincode']: e.target.value };
    let objAddressParent = { ...formData.insurance.kyc, ['addresses']: addressobj };
    let objAddressContainer = { ...formData.insurance, ['kyc']: objAddressParent };
    setFormData({ ...formData, ['insurance']: objAddressContainer });
  };
 
  const handleDatePicker = (e, name) => {
    let objVehicle = { ...formData.vehicle, [name]: e.target.value };
    setFormData({ ...formData, ['vehicle']: objVehicle });
  };
  const handleClaimsMade = (e) => {
    let claimsMadeObj = {
      ...formData.insurance,
      [e.target.name]: JSON.parse(e.target.value)
    };
    setFormData({ ...formData, insurance: claimsMadeObj });
  };
 

console.log(useSelector((state) => state.adminReducer), 'use selector in vehicle detail page');


  const handleNextModalOpen = async (e) => {
    let addCustomerData = {
      customer_name: formData.rc.name,
      phone: formData.rc.phone,
      org_id: formData.profile.org.orgID ,
      agent_id: formData.profile.agent.agentId,
      method: "POST",
      type: "C",
    };
    let otpData = {
      phone: formData.rc.phone,
      org_id: formData.profile.org.orgID,
      agent_id: formData.profile.agent.agentId,
      method: "SEND",
      type: "C",
    };
    // let otpResponse = await sendOtp(addCustomerData, otpData);
    // console.log("otpresponse in vehicle details page", otpResponse);
    dispatch(addingCustomerData(addCustomerData))
    dispatch(getSmsResponse(otpData))
    e.preventDefault();
    setIsNext(true);
    let obj = {
      manufacture: formData?.vehicle?.manufactureDate || manufactureDate,
      name: formData?.rc?.name,
      make_model: formData?.vehicle?.makerModel,
      registrationType: formData?.insurance?.policyHolderType
    };
    if (mobileValidate(formData?.rc?.phone) === '' && Object.values(obj).every((key) => key != '')) {
      let objprev = {
        registrationDate: formData?.vehicle?.registrationDate,
        rtoCode: formData?.rc?.rtoCode
      };
      if (ispreviousinsurer == 'true') {
        objprev.policyCompany = formData?.previousPolicyDetails?.policyCompany;
        objprev.policyEndDate = formData?.previousPolicyDetails?.policyEndDate;
      }
      if (showNCBValue) {
        objprev.ncbData = formData?.insurance?.ncbValue;
      }
      if (isVehicleNew && formData?.insurance?.kyc?.addresses?.pincode !== '') {
        dispatch(getSmsResponse({ phone: formData?.rc?.phone }));
 
        setOpenOtp(true);
      } else if (Object.values(objprev).every((key) => key != '')) {
        dispatch(getSmsResponse({ phone: formData?.rc?.phone }));
        setOpenOtp(true);
      } else {
        setOpenAlert(true);
      }
    } else {
      setOpenAlert(true);
    }
  };
  const otpToString = () => Number(otp.join(''));
 
  const handleGetQuote = async (e) => {
    e.preventDefault();
    setIsVerified(true);
    const method = 'VERIFY';
    const type = 'C';
    const verification_code = otpToString();
    const phone = formData?.rc?.phone;
    console.log(smsResponse, "smsResponse.body.otp")
    dispatch(getSmsVerificationResponse(phone, verification_code, method, type));
    if (smsResponse.otp === verification_code) {
      let objFormData = { ...formData };
      objFormData.vehicle.manufactureDate = objFormData.vehicle.manufactureDate ? objFormData.vehicle.manufactureDate : `${manufactureDate}-01`;
 
      if (ispreviousinsurer == 'true') {
        objFormData.previousPolicyDetails.isPreviousInsurerKnown = true;
        let insurerCode = insuranceData?.filter((el) => {
          if (el.Name == objFormData.previousPolicyDetails.policyCompany) {
            return true;
          } else if (el.Name == objFormData.previousPolicyDetails.policyCompany) {
            return true;
          } else {
            return false;
          }
        });
 
        objFormData.previousPolicyDetails.policyCompany = `${insurerCode[0]?.Name}`;
      } else {
        objFormData.previousPolicyDetails.isPreviousInsurerKnown = false;
      }
 
      for (let i = 0; i < pincodeData.length - 1; i++) {
        if (pincodeData[i].City.toLowerCase() == formData.vehicle.vehicleRegisteredCity.toLowerCase()) {
          objFormData.insurance.kyc.addresses.pincode = pincodeData[i]['Pin Code'];
          break;
        }
      }
 
      AddToLocalStorage('globalObj', objFormData);
      dispatch(getQuotationData(objFormData))
      // const postEncBody = btoa(JSON.stringify(objFormData));
 
      if (formData.vehicle.vehicleType == 'Car') {
        navigate(`/motor/car/quotation`);
      } else if (formData.vehicle.vehicleType == 'Bike') {
        navigate('/motor/bike/quotation');
      } else if (formData.vehicle.vehicleType == 'Commercial') {
        navigate('/motor/commercial/quotation');
      }
    } else {
      setIsVerificationError(true);
    }
  };
 
 
  const handleResendOtp = () => {
    const method = 'RESEND';
    const org_id = formData.profile.org.orgID;
    const agent_id = formData.profile.agent.agentId;
    const phone = formData.rc.phone;
    const type = 'C';
    dispatch(getSmsResponse(phone, method, org_id, agent_id, type));
  };
 
  const handleClickEditButton = () => {
    setOpenMakeModel(true);
    setIsEditButtonClick(true);
  };
 
  const generateTransactionId = () => {
    const staticTransactionId = 'LMV00000';
    let dynamicSequence = Array.from({ length: 8 }, () => Math.floor(Math.random() * 10)).join('');
    let transactionId = staticTransactionId + dynamicSequence;
 
    return transactionId;
  };
 
  // Example usage
 
  useEffect(() => {
    let obj = GetDataLocalStorage('globalObj');
    const transId = generateTransactionId();
    console.log(transId, 'transId');
    setTransactionID(transId);
    if (transId && transId !== undefined) {
      obj.insurance.TransactionID = transId;
      AddToLocalStorage('globalObj', obj);
    }
  }, []);
 
  return (
    <div>
      <div className="vehical_detail_container">
        <div className="vehical_detail_content">
          <label htmlFor="" className="vehical_detail_content_labels">
            Owner Name {isVehicleWithoutNumber || isVehicleNew ? <span style={{ color: 'red' }}>*</span> : ''}
          </label>
          <div action="">{!isVehicleNew ? <input type="text" placeholder="Enter Name" className="vehical_detail_white_box" name="name" value={formData?.rc?.name} onChange={(e) => handleChangeRc(e)} /> : <input type="text" className="vehical_detail_white_box" value={formData?.rc?.name} name="name" onChange={(e) => handleChangeRc(e)} />}</div>
          {/* <br /> */}
          {isNext && formData?.rc?.name == '' ? <span className="error-validation">Please Enter Full Name</span> : <span />}
        </div>
        <div className="vehical_detail_content">
          <label className="vehical_detail_content_labels">
            Owner's Mobile Number<span style={{ color: 'red' }}>*</span>
          </label>
          <div action="">
            <input type="number" className="vehical_detail_white_box" placeholder="Eg: 984562XXXX" name="phone" onChange={(e) => handleChangeRc(e)} value={formData?.rc?.phone || ''} />
          </div>
          {isNext && mobileValidate(formData?.rc?.phone) != '' ? <div className="error-validation">{mobileValidate(formData?.rc?.phone)}</div> : ''}
        </div>
        <div className="vehical_detail_content  vehical_detail_model_search_box sb">
          <label className="vehical_detail_content_labels">
            Manufacturer - Model - Fuel Type
            {isVehicleWithoutNumber || isVehicleNew ? <span style={{ color: 'red' }}>*</span> : ''}
          </label>
          {!isVehicleNew ? (
            vehiclemaincodearr.length > 0 ? (
              <Grid item sx={{ display: 'flex' }}>
                <Select className="make-model-search-bar-2" value={formData?.vehicle?.vehicleMaincode} onChange={(e) => handleDatePicker(e, 'vehicleMaincode')}>
                  {formData?.vehicle?.vehicleMaincode ? <MenuItem value={formData?.vehicle?.vehicleMaincode}>{`${formData?.vehicle?.makerModel}`}</MenuItem> : ''}
                  {vehiclemaincodearr.map((el) => (
                    <MenuItem value={el['Vehicle Code']}>{`${el.Model.toString().trim()} ${el.Variant.toString().trim()}`}</MenuItem>
                  ))}
                </Select>
                <button onClick={handleClickEditButton} className="edit_button">
                  Edit
                </button>
              </Grid>
            ) : (
              <button className="make-model-search-bar" placeholder="Eg: Mahindra XUV 5OO FWD W8 Petrol" onClick={() => setOpenMakeModel(true)} name="modelFuelType" disabled={!isVehicleWithoutNumber}>
                {formData?.vehicle?.makerModel !== '' && formData?.vehicle?.vehicleMaincode !== '' ? `${formData?.vehicle?.makerModel}, ${formData?.vehicle?.fuelType}` : 'Click Here'}
              </button>
            )
          ) : (
            <button className="make-model-search-bar" onClick={() => setOpenMakeModel(true)}>
              {formData?.vehicle?.makerModel !== '' && formData?.vehicle?.vehicleMaincode !== '' ? `${formData?.vehicle?.makerModel}, ${formData?.vehicle?.fuelType}` : 'Click Here'}
            </button>
          )}
          {formData?.vehicle?.vehicleType !== 'Commercial' && openMakeModel ? <MakeModalVarient open={openMakeModel} setOpen={setOpenMakeModel} handleClose={handleNextModalClose} vehicleMaster={vehicleMaster} formData={formData} setFormData={setFormData} /> : <CommercialMakeModelVarient open={openMakeModel} setOpen={setOpenMakeModel} handleClose={handleNextModalClose} vehicleMaster={vehicleMaster} formData={formData} setFormData={setFormData} />}
          {/* <br /> */}
          {isNext && formData?.vehicle?.makerModel == '' ? <span className="error-validation">Please Select Make Model Varient</span> : ''}
        </div>
 
        {/* Customer Type */}
 
        <div className="vehical_detail_content registration_type">
          <label className="vehical_detail_content_labels">
            Customer Type<span style={{ color: 'red' }}>*</span>
          </label>
          <FormControl sx={{ width: '100%' }}>
            <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="policyHolderType" className="previous_policy_radio_button" value={formData?.insurance?.policyHolderType || ''} onChange={(e) => handleChangeInsurence(e)}>
              <FormControlLabel sx={{ margin: '0px' }} value="INDIVIDUAL" control={<Radio sx={{ display: 'none' }} />} label={<span className={`custom-radio-button ${formData?.insurance?.policyHolderType === 'INDIVIDUAL' ? 'active' : ''}  `}>INDIVIDUAL</span>} />
              <FormControlLabel sx={{ margin: '0px' }} value="COMPANY" control={<Radio sx={{ display: 'none' }} />} label={<span className={`custom-radio-button  ${formData?.insurance?.policyHolderType === 'COMPANY' ? 'active' : ''}`}>COMPANY</span>} />
            </RadioGroup>
          </FormControl>
        </div>
 
        <div className="vehical_detail_content">
          <label className="vehical_detail_content_labels">Month & Year Of Manufacturer{isVehicleWithoutNumber || isVehicleNew ? <span style={{ color: 'red' }}>*</span> : ''}</label>
          <input type="month" className="vehical_detail_white_box" name="dob" value={manufactureDate} onChange={(e) => setmanufactureDate(e.target.value)} />
          {isNext && formData?.vehicle?.manufactureDate == '' && manufactureDate == '' ? <span className="error-validation">Please Enter Manufacture Year</span> : <span />}
        </div>
 
        {/* Registration Date  */}
 
        {!isVehicleNew ? (
          <div className="vehical_detail_content">
            <label className="vehical_detail_content_labels">
              Registration Date
              {isVehicleWithoutNumber || isVehicleNew ? <span style={{ color: 'red' }}>*</span> : ''}
            </label>
            <input type="date" className="vehical_detail_white_box" name="registrationDate" value={formData?.vehicle?.registrationDate || ''} onChange={(e) => handleDatePicker(e, 'registrationDate')} />
            {isNext && formData?.vehicle?.registrationDate == '' ? <span className="error-validation">Please Enter Registration Date</span> : <span />}
          </div>
        ) : (
          ''
        )}
 
        {/* Registration Area  */}
        <div className="vehical_detail_content registration_area_search_box">
          <label className="vehical_detail_content_labels">
            {!isVehicleNew ? 'Registration Area  (RTO)' : 'Enter Your Pincode'}
            {isVehicleWithoutNumber || isVehicleNew ? <span style={{ color: 'red' }}>*</span> : ''}
          </label>
          {!isVehicleNew ? (
            <button
              className="vehical_detail_white_box"
              value={city[0]?.registered_city_name || ''}
              // value={city[0]?.Name || ""}
              onClick={() => setOpenAddressModel(true)}
              disabled={!isVehicleWithoutNumber}
            >
              {formData?.rc?.rtoCode !== '' ? `${formData?.rc?.rtoCode} - ${formData?.vehicle?.vehicleRegisteredCity} (${formData?.vehicle?.vehicleRegisteredState})` : 'Click Here'}
            </button>
          ) : (
            <input type="number" className="vehical_detail_white_box" name="pincode" value={formData?.insurance?.kyc?.addresses?.pincode || ''} onChange={(e) => handleChangePincode(e)} />
          )}
          {openAddressModel ? <AddressRtoModal open={openAddressModel} setOpen={setOpenAddressModel} handleClose={handleNextModalClose} rto={cityData} formData={formData} setFormData={setFormData} pincodeData={pincodeData} /> : ''}
          {!isVehicleNew ? isNext && formData?.rc?.rtoCode == '' ? <span className="error-validation">Please Select City State and RTO Code</span> : '' : isNext && formData?.insurance?.kyc?.addresses?.pincode == '' ? <span className="error-validation">Please Enter Pincode</span> : ''}
        </div>
 
        {/* Sub Category  */}
        {formData?.vehicle?.vehicleType == 'Commercial' ? (
          <div className="vehical_detail_content">
            <label className="vehical_detail_content_labels">
              Sub Category<span style={{ color: 'red' }}>*</span>
            </label>
            <div className="row_buttons">
              <button className={`custom-radio-small-button  ${subCategory === 'public' ? 'active' : ''}`} onClick={() => setSubcategory('public')}>
                Public
              </button>
              <button className={`custom-radio-small-button ${subCategory === 'private' ? 'active' : ''}`} onClick={() => setSubcategory('private')}>
                Private
              </button>
              <input type="hidden" name="subcatogery" value={subCategory} />
            </div>
          </div>
        ) : (
          ' '
        )}
 
        {/* Is Previous Insurer Known * */}
        {!isVehicleNew ? (
          <div className="vehical_detail_content">
            <label className="vehical_detail_content_labels">
              Is Previous Insurer Known <span style={{ color: 'red' }}>*</span>
            </label>
            {/* <br /> */}
            <FormControl>
              <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="policyHolderType" className="previous_insurer_radio_button" value={ispreviousinsurer} onChange={(e) => handleChangeispreviousinsurer(e)}>
                <FormControlLabel sx={{ margin: '0px' }} value={true} control={<Radio sx={{ display: 'none' }} />} label={<span className={`previous_insurer_custom_radio_button ${ispreviousinsurer === 'true' ? 'active' : ''}`}>Yes</span>} />
                <FormControlLabel sx={{ margin: '0px' }} value={false} control={<Radio sx={{ display: 'none' }} />} label={<span className={`previous_insurer_custom_radio_button  ${ispreviousinsurer === 'false' ? 'active' : ''}`}>No</span>} />
              </RadioGroup>
            </FormControl>
          </div>
        ) : (
          ''
        )}
 
        {ispreviousinsurer == 'true' && !isVehicleNew ? (
          <>
            <div className="previous_policy_type ppt">
              <label className="vehical_detail_content_labels">
                Previous Policy Type <span style={{ color: 'red' }}>*</span>
              </label>
              <FormControl sx={{ width: '100%' }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="policyType"
                  className="previous_policy_radio_button"
                  value={formData?.previousPolicyDetails?.policyType}
                  onChange={(e) => {
                    handleChangePreviousInsurerAutocomplete(e, 'policyType');
                  }}
                >
                  <FormControlLabel sx={{ margin: '0px' }} value="1OD_3TP" control={<Radio sx={{ display: 'none' }} />} label={<span className={`custom-radio-button ${formData?.previousPolicyDetails?.policyType === '1OD_3TP' ? 'active' : ''}`}>Comprehensive</span>} />
                  <FormControlLabel sx={{ margin: '0px' }} value="0OD_1TP" control={<Radio sx={{ display: 'none' }} />} label={<span className={`custom-radio-button ${formData?.previousPolicyDetails?.policyType === '0OD_1TP' ? 'active' : ''}`}>Third Party</span>} />
                </RadioGroup>
              </FormControl>
              {isNext && formData?.previousPolicyDetails?.policyType == '' ? <span className="error-validation">Please Select Previous Policy Type</span> : <span />}
            </div>
 
            <div className="btn-insurance-modal-parent">
              {!isVehicleNew ? (
                <div className="kyc-proposal-autocomplete-parent">
                  <label className="vehical_detail_content_labels">
                    Previous Insurer Name<span style={{ color: 'red' }}>*</span>
                  </label>
                  <br />
                  <Autocomplete
                    id="free-solo-2-demo"
                    disableClearable
                    options={insuranceData?.length > 0 && insuranceData?.map((option) => (option ? option.Name : ''))}
                    value={formData?.previousPolicyDetails?.policyCompany || ''}
                    onChange={(e) => {
                      handleChangePreviousInsurerAutocomplete(e, 'policyCompany');
                    }}
                    PaperComponent={({ children }) => <Paper style={{ width: '100%', borderRadius: '10px' }}>{children}</Paper>}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          height: '50px', // Background color
                          borderRadius: '0.9em', // Border radius
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#23A8FA',
                              height: '100%',
                              borderRadius: '0.9em'
                              // Border color
                            }
                          }
                        }}
                        {...params}
                        InputProps={{ ...params.InputProps, type: 'search' }}
                        name="policyCompany"
                      />
                    )}
                  />
                  {/* <br /> */}
                  {isNext && formData?.previousPolicyDetails?.policyCompany == '' ? <span className="error-validation">Please Select Previous Insurer Name</span> : <span />}
                </div>
              ) : (
                ''
              )}
            </div>
            {!isVehicleNew ? (
              <div className="datePicker">
                <label className="vehical_detail_content_labels">
                  Policy Expiry Date <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input type="date" className="vehical_detail_white_box" name="policyEndDate" value={formData?.previousPolicyDetails?.policyEndDate} onChange={(e) => handleChangePreviousInsurerAutocomplete(e, 'policyEndDate')} />
                {isNext && formData?.previousPolicyDetails?.policyEndDate == '' ? <span className="error-validation">Please Select Previous Policy Expiry Date</span> : <span />}
              </div>
            ) : (
              ''
            )}
 
            {!isVehicleNew && formData?.previousPolicyDetails?.policyType === '1OD_3TP' ? (
              <div className="vehical_detail_content">
                <label className="vehical_detail_content_labels">
                  Claims Made In Previous Policy<span style={{ color: 'red' }}>*</span>
                </label>
 
                <FormControl>
                  <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="claimsMade" className="previous_insurer_radio_button" value={showNCBValue} onChange={(e) => handleClaimsMade(e)}>
                    <FormControlLabel sx={{ margin: '0px' }} value={true} control={<Radio sx={{ display: 'none' }} />} label={<span className={`previous_insurer_custom_radio_button ${showNCBValue === false ? 'active' : ''}`}>Yes</span>} onClick={() => setShowNCBValue(false)} />
                    <FormControlLabel sx={{ margin: '0px' }} value={false} control={<Radio sx={{ display: 'none' }} />} label={<span className={`previous_insurer_custom_radio_button ${showNCBValue === true ? 'active' : ''}`}>No</span>} onClick={() => setShowNCBValue(true)} />
                  </RadioGroup>
                </FormControl>
              </div>
            ) : (
              ''
            )}
            {showNCBValue && !isVehicleNew && formData?.previousPolicyDetails?.policyType === '1OD_3TP' && (
              <div className="vehical_detail_content">
                <Box sx={{ minWidth: 120 }}>
                  <label className="vehical_detail_content_labels">
                    Previous NCB Value<span style={{ color: 'red' }}>*</span>
                  </label>
                  <br />
                  <FormControl style={{ width: '50%' }}>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" name="ncbValue" value={formData?.insurance?.ncbValue} onChange={(e) => handleChangeInsurence(e)} className="make-model-search-bar-2">
                      {ncbData &&
                        ncbData.length > 0 &&
                        ncbData?.map((data) => {
                          return (
                            <MenuItem value={data.value} key={data.value}>
                              {data.valuePercentage}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {isNext && formData?.insurance?.ncbValue == '' ? <div className="error-validation">Please Select NCB Value</div> : ''}
                  </FormControl>
                </Box>
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>
      <hr />
 
      <div className="vehical_details_submit_btn">
        <button onClick={handleNextModalOpen}>
          Continue
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none+" d="M0 0h24v24H0z"></path>
              <path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
            </svg>
          </div>
        </button>
      </div>
 
      <Modal open={openOtp} onClose={handleNextModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div className="car-details-otp-modal">
          <span class="mainHeading">Enter OTP</span>
          <p class="otpSubheading">We have sent a verification code to your mobile number</p>
          <div className="car-details-otp-box-container">
            {otp.map((_, index) => {
              return <input ref={index === activeOtpIndex ? inputRef : null} key={index} type="number" className="car-details-otp-box" value={otp[index]} onChange={(e) => handleChangeOtp(e, index)} />;
            })}
          </div>
          {isVerified && otpValidation(otp) !== '' ? <div className="text-danger">{otpValidation(otp)}</div> : ''}
          <button onClick={handleGetQuote} class="car-details-otp-submit-button" type="submit">
            Verify & Submit
          </button>
          <p class="resendNote">
            Didn't receive the code?{' '}
            <button onClick={() => dispatch(() => handleResendOtp())} class="resendBtn">
              Resend Code
            </button>
          </p>
          <CloseIcon sx={{ cursor: 'pointer', position: 'absolute', right: '20px', top: '20px' }} onClick={() => setOpenOtp(false)} />
        </div>
      </Modal>
      <AlertError open={otpSentSuccess} setOpen={setOtpSentSuccess} message="OTP sent successfully" severity="success"></AlertError>
    </div>
  );
};
 
export default VehicleDetails;
