import { lazy } from 'react';
import MinimalLayout from '../layout/MinimalLayout';
import Loadable from '../ui-component/Loadable';
import ResultDisplay from '../pages/examination/ResultDisplay';


// project imports
const ExamContainer = Loadable(lazy(() => import('../pages/examination/ExamContainer')));


// ==============================|| Examination ROUTING ||============================== //

const ExaminationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/examination',
      element: <ExamContainer  />
    },
    {
      path: '/examination/result',
      element: <ResultDisplay />
    }
  ]
};

export default ExaminationRoutes;
