export const mappedAddObj = {
    "otherColumns":
    {
        "product_type": "",
        "plan_name": "",
        "company_name": "",
        "business_type": "",
        "commission_rate": "",
        "reward_rate": "",
        "add_reward": "",
        "month_year": "",
    },
    "carColumns": {
        "rto_category": "",
        "rto_cluster": "",
        "new_diesel_comp": "",
        "new_petrol_comp": "",
        "old_diesel_ncb": "",
        "old_diesel_zeroncb": "",
        "old_petrol_ncb": "",
        "old_petrol_zeroncb": "",
        "stp_1000_diesel": "",
        "stp_1000_petrol": "",
        "stp_1000_1500_diesel": "",
        "stp_1000_1500_petrol": "",
        "stp_1500_diesel": "",
        "stp_1500_petrol": "",
        "company": "",
        "month_year": "",
    },
    "newBikeColumns": {
        "rto_category": "",
        "rto_cluster": "",
        "hmc_bike": "",
        "hmc_scooter": "",
        "honda_bike": "",
        "honda_scooter": "",
        "royal_enfield_bike": "",
        "suzuki_bike": "",
        "suzuki_scooter": "",
        "tvs_bike": "",
        "tvs_scooter": "",
        "yamaha_scooter": "",
        "yamaha_bike": "",
        "bike_ev_all_oem": "",
        "scooter_ev_all_oem": "",
        "company_name": "",
        "month_year": "",
    },
    "oldBikeColumns": {
        "rto_category": "",
        "rto_cluster": "",
        "bike_comp": "",
        "bike_saod": "",
        "bikeaotp": "",
        "scootercomp": "",
        "scootercompsaod": "",
        "scooteraotp": "",
        "company_name": "",
        "month_year": "",
    }
}