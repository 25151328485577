/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./ProductDetailsPage.css";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { premiumCalculation } from "../../api/service.js";
import { useNavigate } from "react-router-dom";
import { fieldObject } from "../../api/service.js";
import { TextLoader } from "./TextLoader.jsx";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomCheckboxTravel from "../../Components/CustomCheckboxTravel.jsx";

export const ProductDetailsPage = () => {
  const { t } = useTranslation();
  const travelContent =  (t("travel"));
  let [loading, setLoading] = useState(false);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let ProductData = useSelector((e) => e.travelReducer.selectedPlan.plan); // product details
  let sumInsuredValue = fieldObject(ProductData, "field_2").selectedValue; // selected sum insured value
  let [addOn, setAddOn] = useState({ rvf: false, sumInsured: sumInsuredValue });
  let data = useSelector((e) => e.travelReducer.formData);
  let ProductName = fieldObject(ProductData, "field_16").selectedValue; // product name
  let provided_values = fieldObject(ProductData, "field_2").dataValues;
  let {
    selectedCountries,
    proposerName,
    proposerMobileNo,
    planType,
    ...postdata
  } = data;
  let newPostBody = { ...postdata, planName: ProductName };

  async function getAddon(addOn) {
    setLoading(true);
    let newPostData = {
      ...newPostBody,
      rvf_addOn: addOn.rvf ? 1 : "",
      sumInsured: addOn.sumInsured,
    };

    let postBody = {
      partnerId: "303",
      abacusId: "5216",
      postedField: { ...newPostData },
    };
    let res1 = await premiumCalculation(postBody);
    dispatch({ type: "HANDLE_SELECTED_PLAN", payload: res1 });
    setLoading(false);
  }

  useEffect(() => {
    getAddon(addOn);
  }, [addOn]);

  const AddOn = ({ addOnName, value }) => {
    return (
      <div className="addon-row">
        <span>{addOnName}</span>
        <span>
          <b>₹ {value}</b>
        </span>
      </div>
    );
  };

  return (
    <div style={{ minHeight: "calc(100vh - 75px)", maxHeight: "fit-content" }}>
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <div className="inner-div centeralign flex-wrap align-items-center">
                  <div className="logo-details view-details-logo col-4">
                    <img
                      src="https://static.pbcdn.in/health-cdn/images/insurer-logo/Care_Health@2x.png"
                      alt="Care Health"
                      width="100"
                    />
                  </div>
                  <div className="plan-name col-5">
                    <h2>Explore {ProductName}</h2>
                  </div>
                  <div className="col-12 col-md-5 position-relative mt-3 mt-xl-0">
                    <div className="m-0 addons_wrapper">
                      <div className="addons_details">
                        <h4>{travelContent.quotes.sum_insured}</h4>
                      </div>
                      <div className="addons_type_wrapper">
                        <div className="addons_add_buttons">
                          <div className="input_group">
                            <label htmlFor="selftitle">
                              $ USD
                              <span className="star"></span>
                            </label>
                            <select
                              value={addOn.sumInsured}
                              style={{ textTransform: "capitalize" }}
                              className="form_control"
                              onChange={(e) => {
                                setAddOn((prev) => {
                                  return {
                                    ...prev,
                                    sumInsured: e.target.value,
                                  };
                                });
                              }}
                            >
                              {provided_values.map((e, _) => {
                                return (
                                  <option value={e} key={_}>
                                    {" "}
                                    {e < 100 ? e + "k" : e / 100 + "Lac"}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="inner-div">
                  <div className="heading_section">
                    <h3>
                     {travelContent.products_page.add_ons}
                      <span>
                       {travelContent.products_page.add_ons_description}
                      </span>
                    </h3>
                    <div className="multi-addons-wrapper">
                      <h4 className="addons-importance">{travelContent.products_page.mandatory_addon}</h4>
                      {ProductData.extraFields.map(
                        (e, _) =>
                          e.selectedValue === "checked" && (
                            <div className="addons_wrapper" key={_}>
                              <div className="addons_details">
                                <h4>{e.label}</h4>
                                <div className="addons-info"></div>
                              </div>
                              <div className="addons_type_wrapper">
                                <div className="addons_inner_box">
                                  <p>
                                    <span>{travelContent.products_page.premium}</span>
                                    {loading ? "₹ 0" : `₹ ${e.dataValues}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                    <div className="multi-addons-wrapper">
                      <h4 className="addons-importance">{travelContent.products_page.recommended_addon}</h4>

                      {ProductData.extraFields.map(
                        (e, _) =>
                          e.selectedValue !== "checked" && (
                            <div className="addons_wrapper" key={_}>
                              <div className="addons_details">
                                <h4>{e.label}</h4>
                                <div className="addons-info"></div>
                              </div>
                              <div className="addons_type_wrapper">
                                <div className="addons_inner_box">
                                  <p>
                                    <span>{travelContent.products_page.premium}</span>₹{" "}
                                    {loading
                                      ? "0"
                                      : `${
                                          e.dataValues !== ""
                                            ? e.dataValues
                                            : "0"
                                        }`}
                                  </p>
                                </div>
                                <div className="addons_add_buttons">
                                  <CustomCheckboxTravel
                                    state={addOn}
                                    toggle={setAddOn}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 right_product ">
            <div className="inner_right_section">
              <h3>{travelContent.products_page.summary}</h3>
              <div className="scroll_space">
                <div className="flexRow section_right">
                  <div>{travelContent.products_page.base_premium}</div>
                  <div>
                    <div>
                      <span>₹ {ProductData.outputFields[0].premium}</span>
                    </div>
                  </div>
                </div>
                <div className="rider_head">
                  <h4>{travelContent.products_page.selected_addon}</h4>
                  {ProductData.extraFields[1].selectedValue !== "" ? (
                    <div className="flexRow select_benefits">
                      <AddOn
                        addOnName={travelContent.products_page.visa}
                        value={ProductData.extraFields[1].dataValues}
                      />
                    </div>
                  ) : (
                    <div className="flexRow select_benefits">
                      <div className="addon-row">
                        <span>{travelContent.products_page.no_visa}</span>
                      </div>
                    </div>
                  )}
                  <div className="premium_right">
                    <div className="flexRow section_premium discountSectionPremium">
                      <div>{travelContent.products_page.total_premium}</div>
                      <span>
                        {loading
                          ? "₹ 0"
                          : `₹ ${ProductData.grandTotal.selectedValue}`}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="primaryMainCta"
                      onClick={() => {
                        navigate("/travel/proposals");
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <Stack
                          sx={{ color: "white" }}
                          direction="row"
                          justifyContent={"center"}
                          textTransform={"capitalize"}
                        >
                          <TextLoader></TextLoader>
                        </Stack>
                      ) : (
                        <span style={{textTransform:"capitalize"}}>Proceed to Proposal</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
