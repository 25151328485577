import React from "react";
import "./QuotationCard.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fieldObject } from "../../api/service";
import {sumInsured} from '../../api/service.js';
import {
  Modal,
  Box,
  Stack,
  IconButton,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";

export const QuotationCard = ({ data }) => {
  const { t } = useTranslation();
  const travelContent =  (t("travel"));
  let sumInsure = fieldObject(data, "field_2").selectedValue;
  const [open, setOpen] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    pt: 2,
    px: 4,
    pb: 3,
  };

  function modalToggle() {
    setOpen((prev) => !prev);
  }

  let dispatch = useDispatch();
  let navigate = useNavigate();



  let tripType = useSelector((e) => e.travelReducer.formData.tripType);

  function getTravellGeographyId() {
    let sumInsuredFromJson;
    if (tripType === "Single") {
      sumInsuredFromJson = sumInsured().trip_type.Single.product_details;
    } else {
      sumInsuredFromJson = sumInsured().trip_type.Multi_Trip.product_details;
    }

    let travelCountry = fieldObject(data, "field_16").selectedValue; // selected plan name
    let cDetails = sumInsuredFromJson.find(
      (element) => element.product_name === travelCountry
    );
    return cDetails.travellgeographyCd;
  }

  function handleBuyNow() {
    dispatch({ type: "HANDLE_SELECTED_PLAN", payload: data });
    dispatch({
      type: "HANDLE_TRAVELLGEOGRAPHY_NO",
      payload: getTravellGeographyId(),
    });
    dispatch({
      type: "HANDLE_PLAN_NAME",
      payload: fieldObject(data, "field_16").selectedValue,
    });
    navigate("/travel/products");
  }

  return (
    <div>
      <div className="quotation_main_card  mb_25"> 
        <div className="d_flex quotation_upr_div">
          <div className="logo_container">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/9/90/Care_health_insurance_logo.png"
              alt="CARE HEALTH"
              className="logo_box"
            />
          </div>
          <div className="quotation_content">
            <div className="content_box_cs">
              <div className="plane_name plan_details">
                <div className="title_pa">
                  Explore {fieldObject(data, "field_16").selectedValue}
                </div>
                <div className="suminsured_text">
                 {travelContent.quotes.sum_insured} : ₹{sumInsure}000
                </div>
              </div>
              <div className="plan_feature plan_details">
                <div
                  className="health_check_compare"
                  style={{ marginRight: "30px" }}
                >
                  <ul
                    className="features_list"
                    style={{ listStyleType: "none" }}
                  >
                    <li className="font-12 pb-3">
                      <VerifiedUserIcon
                        fontSize="small"
                        style={{ marginRight: "5px", color: "#03a9f4" }}
                      />
                      <span
                        data-toggle="tooltip"
                        title=""
                        className=" font-blue"
                        aria-describedby="ui-tooltip-3"
                      >
                        {
                          travelContent.quotes.key_1
                        }
                      </span>
                    </li>
                    <li className="font-12">
                      <VerifiedUserIcon
                        fontSize="small"
                        style={{ marginRight: "5px", color: "#03a9f4" }}
                      />
                      <span
                        data-toggle="tooltip"
                        title=""
                        className=" font-blue"
                        aria-describedby="ui-tooltip-3"
                      >
                       {travelContent.quotes.key_3}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="health_check_compare">
                  <ul
                    className="features_list"
                    style={{ listStyleType: "none" }}
                  >
                    <li className="font-12 pb-3">
                      <VerifiedUserIcon
                        fontSize="small"
                        style={{ marginRight: "5px", color: "#03a9f4" }}
                      />
                      <span
                        data-toggle="tooltip"
                        title=""
                        className=" font-blue"
                        aria-describedby="ui-tooltip-3"
                      >
                       {travelContent.quotes.key_2}
                      </span>
                    </li>
                    <li className="font-12">
                      <VerifiedUserIcon
                        fontSize="small"
                        style={{ marginRight: "5px", color: "#03a9f4" }}
                      />
                      <span
                        data-toggle="tooltip"
                        title=""
                        className=" font-blue"
                        aria-describedby="ui-tooltip-3"
                      >
                        {
                          travelContent.quotes.key_1
                        }
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="text_right hal_buy_btn_btn plan_details">
                <button className="buy_btn" onClick={handleBuyNow}>
                  <Tooltip title="Buy Plan" placement="bottom">
                    <span>
                      <i className="fa-solid fa-indian-rupee-sign mr_5"></i>₹
                      {data.grandTotal.selectedValue}
                    </span>
                  </Tooltip>
                  <p>{travelContent.quotes.gst}</p>
                  <small>{travelContent.quotes.buy_now}</small>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="d_flex quotation_bottom_div">
          <button className="features_btn" onClick={modalToggle}>
           {travelContent.quotes.view_features} <ChevronRightIcon />
          </button>
        </div>
      </div>
      <Modal
        open={open}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: {
              xs: "90%",
              sm: "70%",
              md: "60%",
            },
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent={"flex-end"}>
                <IconButton onClick={modalToggle}>
                  <CloseIcon></CloseIcon>
                </IconButton>
              </Stack>
            </Grid>
          </Grid>

          <Grid container columnGap={3} pb={1}>
            <Grid item display={"flex"} xs={12} md={"auto"}>
              <Box width={"120px"} borderRadius={2} overflow={"hidden"}>
                <img
                  width={"100%"}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Care_health_insurance_logo.png/220px-Care_health_insurance_logo.png"
                  alt=""
                />
              </Box>
            </Grid>
            <Grid
              item
              display={"flex"}
              alignItems={"center"}
              xs={12}
              md={"auto"}
            >
              <Typography variant="h5" id="child-modal-title">
                Explore {fieldObject(data, "field_16").selectedValue}
              </Typography>
            </Grid>
          </Grid>
          <Grid container rowGap={1} pt={2}>
            <Grid item xs={12} pt={1}>
              <Typography variant="h5" color={"#23a8fa"} fontWeight={600}>
              {travelContent.quotes.key_highlight}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              gap={1}
              alignItems={"center"}
            >
              <TaskAltIcon fontSize="14px" htmlColor="#23a8fa" />
              <Typography fontFamily={"Poppins"}>
                {travelContent.quotes.key_2}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              gap={1}
              alignItems={"center"}
            >
              <TaskAltIcon fontSize="14px" htmlColor="#23a8fa" />
              <Typography fontFamily={"Poppins"}>{travelContent.quotes.key_3}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              gap={1}
              alignItems={"center"}
            >
              <TaskAltIcon fontSize="14px" htmlColor="#23a8fa" />
              <Typography fontFamily={"Poppins"} fontSize={{ sx: 1, md: 2 }}>
                No age bar.
              </Typography>
            </Grid>
          </Grid>

          <Grid container rowGap={1} pt={2}>
            <Grid item xs={12} pt={1}>
              <Typography variant="h5" color={"#23a8fa"} fontWeight={600}>
              {travelContent.quotes.key_coverage}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              gap={1}
              alignItems={"center"}
            >
              <TaskAltIcon fontSize="14px" htmlColor="#23a8fa" />
              <Typography fontFamily={"Poppins"}>
                {travelContent.quotes.keys_1}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              gap={1}
              alignItems={"center"}
            >
              <TaskAltIcon fontSize="14px" htmlColor="#23a8fa" />
              <Typography fontFamily={"Poppins"}>
              {travelContent.quotes.keys_2}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              gap={1}
              alignItems={"center"}
            >
              <TaskAltIcon fontSize="14px" htmlColor="#23a8fa" />
              <Typography fontFamily={"Poppins"}>
              {travelContent.quotes.keys_3}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
