import React, { useEffect, useState } from "react";
import careLogo from "../../Images/CHIL LOGO TYPE 1.jpg";
import { useSelector } from "react-redux";
import HDFCLogo from "../../Images/HDFCLOGO 1 copy.jpg";
import bajajLogo from "../../Images/Bajaj_Allianz_Insurance.jpg";
import { Grid, Typography, Avatar } from "@mui/material";
import { formatNumberWithCommas } from "../../../Framework/Utils/pancardValidation";

const PolicyDetails = () => {
  const [sum, setSum] = useState("");
  const [totalSum, setTotalSum] = useState("");
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");

  let provider = useSelector((e) => e?.healthReducer?.selectedProvider);
  let quotationData = useSelector((e) => e?.healthReducer?.quotationData);

  useEffect(() => {
    switch (provider) {
      case "CARE" || "Care":
        if (quotationData) {
          setTotalSum(quotationData.premiumWithGST);
          setSum(quotationData.sumInsured.toLocaleString());
          setImage(careLogo);
          setTitle(quotationData?.title);
        }
        break;

      case "HDFC":
        if (quotationData) {
          setSum(
            `${formatNumberWithCommas(
              Number(
                quotationData?.sumInsured
              )
            )}`
          );
          setTotalSum(
            `${formatNumberWithCommas(
              Number(
                quotationData?.premiumWithGST
              )
            )}`
          );
          setImage(HDFCLogo);
          setTitle("Optima Secure");
        }
        break;

      case "bajaj":
        if (quotationData) {
          setTotalSum(quotationData.premiumWithGST);
          setSum(quotationData.sumInsured);
          setImage(bajajLogo);
          setTitle(quotationData?.productName);
        }
        break;
      default:
    }
  }, [provider, quotationData]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {/* <Paper elevation={0}>
          </Paper> */}
          <Avatar
            src={image}
            alt="Company Logo"
            variant="rounded"
            style={{ width: 120, height: 80, margin: "20px auto" }}
          />

          <Typography
            variant="h6"
            style={{ textAlign: "center", marginTop: 10 }}
          >
            Plan
          </Typography>

          <Typography
            variant="body1"
            style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.54)" }}
          >
            {title}
          </Typography>

          <Typography
            variant="h6"
            style={{ textAlign: "center", marginTop: 10 }}
          >
            Sum Insured
          </Typography>

          <Typography
            variant="body1"
            style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.54)" }}
          >
            ₹ {sum}
          </Typography>

          <Typography
            variant="h6"
            style={{ textAlign: "center", marginTop: 10 }}
          >
            Goods & Service Tax
          </Typography>

          <Typography
            variant="body1"
            style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.54)" }}
          >
            18%
          </Typography>

          <Typography
            variant="h6"
            style={{ textAlign: "center", marginTop: 10 }}
          >
            Total Premium
          </Typography>

          <Typography
            variant="body1"
            style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.54)" }}
          >
            ₹ {totalSum}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PolicyDetails;
