import React, { useState } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  IconButton,
  InputLabel,
  FormControl,
  Typography,
  CircularProgress,
  Stack,
  Modal,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import PolicyIcon from "@mui/icons-material/Policy";
import { useForm } from "react-hook-form";
import {v4 as uuidv4} from 'uuid'



export default function AddExistingPolicy({existingPolicy,PreviousBtn,saveBtn,isSaveLoaderTrue,}) {
   const {
    formState: { errors, isSubmitted },
    register,
    setValue,
    handleSubmit,
    trigger,
    reset
  } = useForm();
  const [policies, setPolicies] = useState(existingPolicy);
  const [isModalTrue, setIsModalTrue] = useState(false);
 

  const addNewRecord = (data) =>{
    setPolicies((prv)=>([...prv , {id : uuidv4() , ...data}]))
    reset()
    setIsModalTrue(false)
  }

  const deletePolicy = (id) =>{
    const updatePolicy = policies.filter((eachValue)=> eachValue?.id !== id)
    setPolicies(updatePolicy)
  }

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"100%"}
        padding={3}
      >
        <Box sx={{ display: "flex", alignItems: "center", m: 2 , alignSelf : 'flex-start'}}>
          <PolicyIcon sx={{ fontSize: "2rem", color: "#0000ff", mr: 1 }} />
          <Typography variant="h4">Exit Policy Details</Typography>
        </Box>

        {policies?.length === 0 ? (
          <Typography variant="h3" marginTop={5} marginBottom={5}>
            Empty
          </Typography>
        ) : (
          // table
          <TableContainer
            component={Paper}
            sx={{
              width: "70%",
              minHeight: "150px",
              maxHeight: "300px",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: 400 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    POLICY NUMBER
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    COMPANY
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    START DATE
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    END DATE
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    DELETE
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={{ overflow: "auto" }}>
                {policies.map((row) => (
                  <TableRow
                    key={row?.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row?.policy_number}
                    </TableCell>
                    <TableCell align="center">{row?.company}</TableCell>
                    <TableCell align="center">{row.start_date}</TableCell>
                    <TableCell align="center">{row.end_date}</TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "red", cursor: "pointer" }}
                      onClick={() => deletePolicy(row?.id)}
                    >
                      {<DeleteIcon />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* // buttons */}
        <Stack
          spacing={2}
          direction={"row"}
          sx={{ alignSelf: "center", marginTop: "30px" }}
        >
          <Button variant="contained" onClick={() => PreviousBtn(policies)}>
            Back
          </Button>

          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsModalTrue(true)}
          >
            ADD POLICY
          </Button>

          <Button
            variant="contained"
            disabled={isSaveLoaderTrue}
            onClick={() => saveBtn(policies)}
          >
            {isSaveLoaderTrue ? <CircularProgress size={20} /> : "Save All Changes"}
          </Button>
        </Stack>
      </Box>

      {/* modal */}
      <Modal
        open={isModalTrue}
        onClose={() => setIsModalTrue(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          width={500}
          height={400}
          bgcolor={"white"}
          padding={5}
          borderRadius={5}
        >
          <form onSubmit={handleSubmit(addNewRecord)}>
            <Grid container columnSpacing={2} rowGap={3}>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"right"}
                marginBottom={2}
              >
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {setIsModalTrue(false); reset();}}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Policy Number"
                  name="policyNumber"
                  {...register("policy_number", {
                    required: "Policy Number is required...",
                  })}
                  variant="outlined"
                  error={!!errors.policy_number}
                  helperText={
                    errors.policy_number ? errors.policy_number.message : ""
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Company"
                  name="Company"
                  {...register("company", {
                    required: "Company name is required...",
                  })}
                  variant="outlined"
                  error={!!errors.company}
                  helperText={errors.company ? errors.company.message : ""}
                  onInput={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  type="date"
                  fullWidth
                  label="Start Date"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="Start_date"
                  {...register("start_date", {
                    required: "Start Date is required..",
                  })}
                  error={!!errors.start_date}
                  helperText={
                    errors.start_date ? errors.start_date.message : ""
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  type="date"
                  fullWidth
                  label="End Date"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="end_date"
                  {...register("end_date", {
                    required: "End Date is required..",
                  })}
                  error={!!errors.end_date}
                  helperText={errors.end_date ? errors.end_date.message : ""}
                />
              </Grid>

              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <Button variant="contained" type="submit">
                  ADD
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
}

// import React, { useState } from "react";
// import {
//   Box,
//   TextField,
//   Select,
//   MenuItem,
//   Button,
//   Grid,
//   IconButton,
//   InputLabel,
//   FormControl,
//   Typography,
//   CircularProgress,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";

// const AddExistingPolicy = ({existingPolicy , PreviousBtn , saveBtn , isSaveLoaderTrue}) => {
//   const [policies, setPolicies] = useState([{ policyNumber: "", relationship: "Self" }]);

//   const handleAddPolicy = () => {
//     setPolicies([...policies, { policyNumber: "", relationship: "Self" }]);
//   };

//   const handlePolicyChange = (index, event) => {
//     const newPolicies = policies.slice();
//     newPolicies[index][event.target.name] = event.target.value;
//     setPolicies(newPolicies);
//   };

//   const handleDeletePolicy = (index) => {
//     const newPolicies = policies.filter((_, i) => i !== index);
//     setPolicies(newPolicies);
//   };

//   return (
//     <Box sx={{ p: 3, backgroundColor: "#f9f9f9", borderRadius: 1 }}>
//       {policies.length !== 0 ? (
//         policies?.map((policy, index) => (
//           <Grid
//             container
//             spacing={2}
//             alignItems="center"
//             key={index}
//             sx={{ mb: 2 }}
//           >
//             <Grid item xs={12} sm={4}>
//               <TextField
//                 fullWidth
//                 label="Policy Number"
//                 name="policyNumber"
//                 value={policy.policyNumber}
//                 onChange={(e) => handlePolicyChange(index, e)}
//                 variant="outlined"
//               />
//             </Grid>

//             <Grid item xs={12} sm={4}>
//               <FormControl fullWidth variant="outlined">
//                 <InputLabel>Relationship</InputLabel>
//                 <Select
//                   label="Relationship"
//                   name="relationship"
//                   value={policy.relationship}
//                   onChange={(e) => handlePolicyChange(index, e)}
//                 >
//                   <MenuItem value="Self">Self</MenuItem>
//                   <MenuItem value="Spouse">Spouse</MenuItem>
//                   <MenuItem value="Child">Child</MenuItem>
//                   <MenuItem value="Parent">Parent</MenuItem>
//                 </Select>
//               </FormControl>
//             </Grid>

//             <Grid item xs={12} sm={1}>
//               <IconButton
//                 aria-label="delete"
//                 onClick={() => handleDeletePolicy(index)}
//                 sx={{ color: "#d32f2f" }}
//               >
//                 <DeleteIcon />
//               </IconButton>
//             </Grid>
//           </Grid>
//         ))
//       ) : (
//         <Typography variant="h3" textAlign={"center"} mt={2} mb={2}>
//           Empty
//         </Typography>
//       )}

//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Button
//           onClick={handleAddPolicy}
//           sx={{ textTransform: "none", color: "#1976d2" }}
//         >
//           + Add More Policies
//         </Button>

//         <Box>
//           <Button variant="outlined" sx={{ mr: 2 }} onClick={() => PreviousBtn(policies)}>
//             Back
//           </Button>
//           <Button variant="contained" disabled={isSaveLoaderTrue} onClick={() => saveBtn(policies)}>{isSaveLoaderTrue ? <CircularProgress size={20}/> : 'Save'}</Button>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default AddExistingPolicy;
