const initialState = {
    apiData: null,
};

const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return {
                ...state,
                apiData: action.payload,
            };
        default:
            return state;
    }
};

export default apiReducer;
