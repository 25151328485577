import React, { useEffect } from 'react';
import vectorobject from '../../Images/Bike-Page/Bike Insurance 01-01.png';
import bikereverse from '../../Images/Bike-Page/Bike Insurance 02-01.png';
import ProposalDetails from '../../Components/Proposal/ProposalDetails';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router';

const BikeProposal = () => {
  const naviagte = useNavigate();

  const handlebackBtn = () => {
    naviagte('/motor/bike/quotation');
  };
  return (
    <div>
      <div className="kychedding">Bike Proposal</div>
      <div className="kyclogo">
        <div>
          <div className="infologo">
            <div className="back_icon">
              <KeyboardBackspaceIcon onClick={handlebackBtn} />
            </div>

            <h6>
              Step 3 (<span className="inprogress">In Progress</span>)
            </h6>
          </div>

          <div>
            <img src={bikereverse} alt="car and umberela" className="carnumberela" />
          </div>
        </div>

        <div>
          <img src={vectorobject} alt="vector object" className="vectorobject" />
        </div>
      </div>

      <ProposalDetails />

      <div className="bottomdiv mx-5 mb-5">
        <div>
          <img src={bikereverse} alt="carnumberela2" className="carnumberela2" />
        </div>
        <div>***</div>
        <div>
          <img src={bikereverse} alt="carnumberela3" className="carnumberela3" />
        </div>
      </div>
    </div>
  );
};

export default BikeProposal;
