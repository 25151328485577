/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./FamilyDetails.css";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDaughterCount,
  updateFamilyData,
  updateProposerGender,
  updateSonCount,
} from "../../Redux/Health/action";
import { Link, useNavigate } from "react-router-dom";
import { AlertError } from "../../Health/Pages/Tosters/AlertError";
import { Grid, Typography, Container, Box, useMediaQuery } from "@mui/material";
import NextButton from "../../Framework/Components/NextButton";
import { useTranslation } from "react-i18next";

const FamilyDetails = () => {
  const [familyData, setFamilyData] = useState({
    self: false,
    spouse: false,
    son: false,
    daughter: false,
    father: false,
    mother: false,
    fatherInLaw: false,
    motherInLaw: false,
  });
  const [alertMessage, setAllertMesssage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [sonCount, setSonCount] = useState(0);
  const [gender, setGender] = useState({
    selfGender: "male",
    spouseGender: "female",
  });
  const [daughterCount, setDaughterCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("male");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleCheckboxChange = (value) => {
    setSelectedOption(value);
    if (value === "male") {
      setGender({ selfGender: "male", spouseGender: "female" });
    } else {
      setGender({ selfGender: "female", spouseGender: "male" });
    }
  };

  const handleClick = () => {
    const isAnyAdultSelected =
      familyData.self ||
      familyData.spouse ||
      familyData.mother ||
      familyData.father ||
      familyData.motherInLaw ||
      familyData.fatherInLaw;

    const isAnyNonAdultSelected = familyData.son || familyData.daughter;

    const isFatherOrMotherSelected = familyData.father || familyData.mother;

    const isAnyFamilyMemberSelected =
      familyData.self ||
      familyData.spouse ||
      familyData.son ||
      familyData.daughter;

    const totalSelectedMembers =
      (familyData.self ? 1 : 0) +
      (familyData.spouse ? 1 : 0) +
      (familyData.mother ? 1 : 0) +
      (familyData.father ? 1 : 0) +
      (familyData.motherInLaw ? 1 : 0) +
      (familyData.fatherInLaw ? 1 : 0) +
      (familyData.son ? 1 : 0) +
      (familyData.daughter ? 1 : 0);

    if (!isAnyAdultSelected) {
      setAllertMesssage(
        "Select at least one adult member for family type policy"
      );
      setOpenAlert(true);
    }
    // else if (!isAnyAdultSelected && !isAnyNonAdultSelected) {
    //   setAllertMesssage("Select at least one adult member for family type policy");
    //   setOpenAlert(true);
    // }
    //  else if (isAnyFamilyMemberSelected && isFatherOrMotherSelected) {
    //   setAllertMesssage(
    //     "Father and Mother are not allowed with other members in the floater plan"
    //   );
    //   setOpenAlert(true);
    // }
    else {
      navigate("/health/Familyagefield");
    }
  };

  const handleCount = (name) => {
    if (name === "son") {
      if (!familyData.son) {
        if (sonCount + daughterCount < 2) {
          setSonCount((prevCount) => prevCount + 1);
        }
      } else {
        setSonCount(0);
      }
    }

    if (name === "daughter") {
      if (!familyData.daughter) {
        if (daughterCount + sonCount < 2) {
          setDaughterCount((prevCount) => prevCount + 1);
        }
      } else {
        setDaughterCount(0);
      }
    }

    setFamilyData((prevData) => ({
      ...prevData,
      [name]: !familyData[name],
    }));
  };

  useEffect(() => {
    // localStorage.clear();
    dispatch(updateFamilyData(familyData));
    dispatch(updateSonCount(sonCount));
    dispatch(updateDaughterCount(daughterCount));
    dispatch(updateProposerGender(gender.selfGender));
  }, [dispatch, familyData, sonCount, daughterCount, gender]);

  const handleSonCount = (action) => {
    if (action === "+") {
      if (sonCount + daughterCount < 2 && familyData.son === true) {
        setSonCount((prevCount) => prevCount + 1);
      }
    } else if (action === "-" && sonCount > 1) {
      setSonCount((prevCount) => prevCount - 1);
    }
  };

  // daughter counter
  const handleDaughterCount = (action) => {
    if (action === "+") {
      if (daughterCount + sonCount < 2 && familyData.daughter === true) {
        setDaughterCount((prevCount) => prevCount + 1);
      }
    } else if (action === "-" && daughterCount > 1) {
      setDaughterCount((prevCount) => prevCount - 1);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  // Destructuring the translation data

  const { welcomeMessage, subMessage } = t(
    "health.familydetailspage.leftColumn"
  );

  //  const { self,spouse,son,daughter,father,mother,fatherInLaw,motherInLaw } = t('health.familydetailspage.rightColumn.checkboxLabels')
  return (
    <>
      <Container>
        <Grid container spacing={1}>
          {/* Left Column - Banner */}
          <Grid item sm={6} display={{ xs: "none", md: "block" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              borderRadius="8px"
              padding="20px"
            >
              <img
                src="/Health/Images/Frame (14).svg"
                alt="Banner"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </Grid>

          {/* Right Column - Form */}
          <Grid item xs={12} sm={12} md={6}>
            <Box
              // bgcolor="#f5f5f5"
              borderRadius="8px"
              textAlign="center"
              padding="20px"
            >
              <Typography variant="h6" gutterBottom style={{ color: "#333" }}>
                {welcomeMessage}
              </Typography>

              <Typography
                variant="h6"
                gutterBottom
                style={{
                  color: "#0d398f ",
                  marginBottom: "10px",
                  fontWeight: "600",
                }}
              >
                {subMessage}
              </Typography>

              <div className="male_female_box text_center d-none d-lg-block">
                <input
                  type="radio"
                  id="male"
                  value="male"
                  checked={selectedOption === "male"}
                  onChange={() => handleCheckboxChange("male")}
                />
                <label
                  htmlFor="male"
                  className={`genderLabel ${
                    selectedOption === "male" ? "active" : ""
                  }`}
                >
                  <span className="gender_text">Male</span>
                </label>

                <input
                  value="female"
                  type="radio"
                  id="female"
                  checked={selectedOption === "female"}
                  onChange={() => handleCheckboxChange("female")}
                />
                <label
                  htmlFor="female"
                  className={`genderLabel ${
                    selectedOption === "female" ? "active" : ""
                  }`}
                >
                  <span className="gender_text">Female</span>
                </label>
              </div>

              <Grid container spacing={2} justifyContent="center">
                {/* Two Columns of Inputs */}

                {/* self */}
                <Grid item xs={6}>
                  {/* <TextField label="First Name" variant="outlined" fullWidth /> */}
                  <div className="travel_readio_btn">
                    <input
                      type="checkbox"
                      value="self"
                      name="member_checkbox[]"
                      id="self"
                      checked={familyData?.self}
                      onChange={() => handleCount("self")}
                    />
                    <label for="self">
                      <div className="icon_tab">
                        <img
                          src={
                            selectedOption === "male"
                              ? "/Health/Images/self1.svg"
                              : "/Health/Images/self.svg"
                          }
                          id="selfimg"
                        />
                        <span>Self</span>
                        <div className="error_message_cst">
                          <span className="genders"></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </Grid>

                {/* spouse */}
                <Grid item xs={6}>
                  <div className="travel_readio_btn">
                    <input
                      type="checkbox"
                      name="member_checkbox[]"
                      value="Spouse"
                      id="spouse"
                      checked={familyData?.spouse}
                      onChange={() => handleCount("spouse")}
                    />
                    <label for="spouse">
                      <div className="icon_tab">
                        <img
                          src={
                            selectedOption === "female"
                              ? "/Health/Images/self1.svg"
                              : "/Health/Images/self.svg"
                          }
                          title=""
                          // alt="best health insurance plan"
                          id="wife"
                        />
                        <span id="wifeText">Spouse</span>
                        <div className="error_message_cst">
                          <span className="genders"></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </Grid>

                {/* son */}
                <Grid item xs={6}>
                  {/* <TextField label="First Name" variant="outlined" fullWidth /> */}
                  <div className="travel_readio_btn">
                    <input
                      name="member_checkbox[]"
                      type="checkbox"
                      id="son"
                      checked={familyData?.son}
                      disabled={familyData?.daughter && daughterCount === 2}
                      onChange={() => handleCount("son")}
                    />
                    <label
                      for="son"
                      disabled={
                        familyData?.daughter && daughterCount === 2
                          ? "blur"
                          : ""
                      }
                    >
                      <div
                        className={`icon_tab ${
                          daughterCount === 2 ? "blur" : ""
                        }`}
                      >
                        <img src="/Health/Images/son.svg" title="" />
                        <span>son</span>
                        {familyData?.son && (
                          <div className="counter_cs add_son_cs">
                            <button
                              type="button"
                              className="add_member_minus"
                              onClick={() => handleSonCount("-")}
                            >
                              <i className="bi bi-dash"></i>
                            </button>
                            <p>{sonCount}</p>
                            <button
                              type="button"
                              className="add_member_plus"
                              onClick={() => handleSonCount("+")}
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                </Grid>

                {/* daughter */}
                <Grid item xs={6}>
                  {/* <TextField label="Last Name" variant="outlined" fullWidth /> */}
                  <div className="travel_readio_btn">
                    <input
                      type="checkbox"
                      value="Daughter1"
                      name="member_checkbox[]"
                      id="daughter"
                      checked={familyData?.daughter}
                      disabled={familyData?.son && sonCount === 2}
                      onChange={() => handleCount("daughter")}
                    />
                    <label
                      htmlFor="daughter"
                      disabled={familyData?.son && sonCount === 2 ? "blur" : ""}
                    >
                      <div
                        className={`icon_tab ${sonCount === 2 ? "blur" : ""}`}
                      >
                        <img
                          src="/Health/Images/daughter.svg"
                          title=""
                          // alt="best health insurance plan"
                        />
                        <span>Daughter</span>
                        <div className="error_message_cst">
                          <span className="genders"></span>
                        </div>
                        {familyData?.daughter && (
                          <div className="counter_cs add_daughter_cs">
                            <button
                              type="button"
                              className="add_member_minus"
                              onClick={() => handleDaughterCount("-")}
                            >
                              <i className="bi bi-dash"></i>
                            </button>
                            <p>{daughterCount}</p>
                            <button
                              type="button"
                              className="add_member_plus"
                              onClick={() => handleDaughterCount("+")}
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                </Grid>

                {/* father */}
                <Grid item xs={6}>
                  {/* <TextField label="First Name" variant="outlined" fullWidth /> */}
                  <div className="travel_readio_btn">
                    <input
                      type="checkbox"
                      value="Father"
                      name="member_checkbox[]"
                      id="father"
                      disabled={
                        familyData?.fatherInLaw || familyData?.motherInLaw
                      }
                      checked={familyData?.father}
                      onChange={() => handleCount("father")}
                    />
                    <label
                      htmlFor="father"
                      disabled={
                        familyData?.fatherInLaw || familyData?.motherInLaw
                          ? "blur"
                          : ""
                      }
                    >
                      <div
                        className={`icon_tab ${
                          familyData?.fatherInLaw || familyData?.motherInLaw
                            ? "blur"
                            : ""
                        }`}
                      >
                        <img src="/Health/Images/father.svg" title="" />
                        <span>Father</span>
                        <div className="error_message_cst">
                          <span className="genders"></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </Grid>

                {/* mother */}
                <Grid item xs={6}>
                  {/* <TextField label="Last Name" variant="outlined" fullWidth /> */}
                  <div className="travel_readio_btn">
                    <input
                      type="checkbox"
                      value="Mother"
                      name="member_checkbox[]"
                      id="mother"
                      disabled={
                        familyData?.fatherInLaw || familyData?.motherInLaw
                      }
                      checked={familyData?.mother}
                      onChange={() => handleCount("mother")}
                    />
                    <label
                      htmlFor="mother"
                      disabled={
                        familyData?.fatherInLaw || familyData?.motherInLaw
                          ? "blur"
                          : ""
                      }
                    >
                      <div
                        className={`icon_tab ${
                          familyData?.fatherInLaw || familyData?.motherInLaw
                            ? "blur"
                            : ""
                        }`}
                      >
                        <img src="/Health/Images/mother.svg" title="" />
                        <span>Mother</span>
                        <div className="error_message_cst">
                          <span className="genders"></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </Grid>

                {/* father-in-law */}
                <Grid item xs={6}>
                  {/* <TextField label="First Name" variant="outlined" fullWidth /> */}
                  <div className="travel_readio_btn">
                    <input
                      value="Mother"
                      name="member_checkbox[]"
                      type="checkbox"
                      id="father-in-law"
                      disabled={familyData?.father || familyData?.mother}
                      checked={familyData.fatherInLaw}
                      onChange={() => handleCount("fatherInLaw")}
                    />
                    <label
                      htmlFor="father-in-law"
                      disabled={
                        familyData?.father || familyData?.mother ? "blur" : ""
                      }
                    >
                      <div
                        className={`icon_tab ${
                          familyData?.father || familyData?.mother ? "blur" : ""
                        }`}
                      >
                        <img src="/Health/Images/father.svg" title="" />
                        <span>FatherInLaw</span>
                        <div className="error_message_cst">
                          <span className="genders"></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </Grid>

                {/* mother-in-low */}
                <Grid item xs={6}>
                  {/* <TextField label="Last Name" variant="outlined" fullWidth /> */}
                  <div className="travel_readio_btn">
                    <input
                      value="Mother"
                      name="member_checkbox[]"
                      type="checkbox"
                      id="mother-in-law"
                      disabled={familyData?.father || familyData?.mother}
                      checked={familyData?.motherInLaw}
                      onChange={() => handleCount("motherInLaw")}
                    />
                    <label
                      htmlFor="mother-in-law"
                      disabled={familyData?.father || familyData?.mother}
                    >
                      <div
                        className={`icon_tab ${
                          familyData?.father || familyData?.mother ? "blur" : ""
                        }`}
                      >
                        <img src="/Health/Images/mother.svg" title="" />
                        <span>MotherInLaw</span>
                        <div className="error_message_cst">
                          <span className="genders"></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </Grid>

                <Grid item xs={12} container justifyContent="space-between">
                  <NextButton direction="back" onClick={handleBack} />
                  <NextButton onClick={handleClick} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <AlertError
        open={openAlert}
        setOpen={setOpenAlert}
        message={alertMessage}
      />
    </>
  );
};

export default FamilyDetails;
