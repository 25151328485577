import { fetchQuestionsAPI, submitAnswersAPI } from '../../../crm/posp/api/pospService';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_SUCCESS = 'SET_SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';

export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS'
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE'
export const FETCH_MASTER_DATA_SUCCESS = 'FETCH_MASTER_DATA_SUCCESS'
export const FETCH_MASTER_DATA_FAILURE = 'FETCH_MASTER_DATA_FAILURE'
export const FETCH_PIN_CODE_MASTER_DATA_SUCCESS = 'FETCH_PIN_CODE_MASTER_DATA_SUCCESS'
export const FETCH_PIN_CODE_MASTER_DATA_FAILURE = 'FETCH_PIN_CODE_MASTER_DATA_FAILURE'
export const FETCH_DOCUMENTS_DATA_SUCCESS = 'FETCH_DOCUMENTS_DATA_SUCCESS'
export const FETCH_DOCUMENTS_DATA_FAILURE = 'FETCH_DOCUMENTS_DATA_FAILURE'


export const FETCH_POSP_SUCCESS = 'FETCH_POSP_SUCCESS';
export const FETCH_POSP_FAILURE = 'FETCH_POSP_FAILURE'

export const FETCH_POSP_DOCUMENTS_DATA_SUCCESS = 'FETCH_DOCUMENTS_DATA_SUCCESS'
export const FETCH_POSP_DOCUMENTS_DATA_FAILURE = 'FETCH_DOCUMENTS_DATA_FAILURE'



// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const SUBMIT_ANSWERS_SUCCESS = 'SUBMIT_ANSWERS_SUCCESS';
export const SET_TIMER = 'SET_TIMER';
export const SET_USER_RESPONSE = 'SET_USER_RESPONSE';
export const CLEAR_EXAM_STATE = 'CLEAR_EXAM_STATE'


export const fetchQuestions = () => async (dispatch) => {
    try {
      const questions = await fetchQuestionsAPI();
      dispatch({ type: FETCH_QUESTIONS_SUCCESS, payload: questions });
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: error.message });
    }
  };
  
  export const setCurrentQuestion = (index) => ({
    type: SET_CURRENT_QUESTION,
    payload: index,
  });
  
  export const submitAnswers = (responses, agentId) => async (dispatch) => {
    try {
      const result = await submitAnswersAPI(responses, agentId);
      dispatch({ type: SUBMIT_ANSWERS_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: error.message });
    }
  };
  
  export const setTimer = (time) => ({
    type: SET_TIMER,
    payload: time,
  });
  
  export const setUserResponse = (questionId, option) => ({
    type: SET_USER_RESPONSE,
    payload: { questionId, option },
  });