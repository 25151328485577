import { Grid, Modal, Tabs } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import Tab from '@mui/material/Tab';
import './MakeModalVarient.css';
import { AlertError } from '../../../../Framework/Toaster/AlertError';
import CloseIcon from '@mui/icons-material/Close';
import { useThrottle } from 'use-throttle';
import { SearchNotFoundErrorMessage, MandatoryFieldsSelectingErrorMessage, VehicleMainCodeErrorMessage } from '../../../../Framework/Utils/ErrorMessage';

const MakeModalVarient = ({ open, setOpen, handleClose, vehicleMaster, formData, setFormData }) => {
  const [make, setMake] = useState([]);
  const [modal, setModal] = useState([]);
  const [Varient, setVarient] = useState([]);
  const [makeModalVariant, setMakeModalVariant] = useState({
    make: formData?.vehicle?.makerModel || '',
    model: formData?.vehicle?.model || '',
    varient: formData?.vehicle?.variant || '',
    fuel: formData?.vehicle?.fuelType || ''
  });
  const [fuel, setFuel] = useState([]);
  const [suggestionMake, setSuggestionMake] = useState([]);
  const [suggestionModal, setSuggestionModal] = useState([]);
  const [suggestionVarient, setSuggestionVarient] = useState([]);
  const [searchTextMake, setSearchTextMake] = useState('');
  const [searchTextModel, setSearchTextModel] = useState('');
  const [searchTextVarient, setSearchTextVarient] = useState('');
  const [value, setValue] = useState('make');
  const [quaery, setQuaery] = useState('');
  const [isSubmited, setIsSubmited] = useState(false);
  const [count, setCount] = useState(0);
  const [errorr, setErrorr] = useState(false);
  const [vehicleMainCodeError, setVehicleMainCodeError] = useState(false);

  //********/ useEffect for separating make model and varient from api/********/
  useEffect(() => {
    let makearr = [];
    let modalarr = [];
    let varientarr = [];
    vehicleMaster.length > 0 &&
      vehicleMaster?.forEach((el) => {
        makearr.push(el.Make.toString().trim());
        modalarr.push(el.Model.toString().trim());
        varientarr.push(el.Variant.toString().trim());
      });
    setMake([...new Set(makearr)]);
    setSuggestionMake([...new Set(makearr)]);
    if (formData?.vehicle?.makerModel && formData?.vehicle?.model && formData?.vehicle?.variant) {
      let modelArr = [];
      let varientArr = [];
      let fuelType = [];
      vehicleMaster?.forEach((el) => {
        if (formData?.vehicle?.makerModel == el.Make.toString().trim()) {
          modelArr.push(el.Model.toString().trim());
          varientArr.push(el.Variant.toString().trim());
          fuelType.push(el['Fuel Type'].toUpperCase().toString().trim());
        }
      });
      modelArr.sort();
      varientArr.sort();
      fuelType.sort();
      setFuel([...new Set(fuelType)]);
      setModal([...new Set(modelArr)]);
      setSuggestionModal([...new Set(modelArr)]);
      setVarient([...new Set(varientArr)]);
      setSuggestionVarient([...new Set(varientArr)]);
    } else {
      setModal([...new Set(modalarr)]);
      setSuggestionModal([...new Set(modalarr)]);
      setVarient([...new Set(varientarr)]);
      setSuggestionVarient([...new Set(varientarr)]);
    }
    setCount(count + 1);
  }, []);

  //********/ useEffect for searching based on make model and varient/********/

  useEffect(() => {
    if (value == 'make' && count > 0) {
      let suggestionArr = make
        ?.filter((item) => {
          return item.toLowerCase().indexOf(quaery.toLowerCase()) !== -1 ? true : false;
        })
        .map((item) => item);
      console.log('suggestionmake', suggestionArr);
      if (suggestionArr.length > 0) {
        setErrorr(false);
        setSuggestionMake(suggestionArr);
      } else {
        if (quaery.length == 0) {
          setErrorr(false);
          setSuggestionMake(make);
        } else {
          setErrorr(true);
        }
      }
    } else if (value == 'model' && count > 0) {
      let suggestionArr = modal
        ?.filter((item) => {
          return item.toLowerCase().indexOf(quaery.toLowerCase()) !== -1 ? true : false;
        })
        .map((item) => item);
      if (suggestionArr.length > 0) {
        setErrorr(false);
        setSuggestionModal(suggestionArr);
      } else {
        if (quaery.length == 0) {
          setErrorr(false);
          setSuggestionModal(modal);
        } else {
          setErrorr(true);
        }
      }
    } else if (value == 'varient' && count > 0) {
      let suggestionArr = Varient?.filter((item) => {
        return item.toLowerCase().indexOf(quaery.toLowerCase()) !== -1 ? true : false;
      }).map((item) => item);
      if (suggestionArr.length > 0) {
        setErrorr(false);
        setSuggestionVarient(suggestionArr);
      } else {
        if (quaery.length == 0) {
          setErrorr(false);
          setSuggestionVarient(Varient);
        } else {
          setErrorr(true);
        }
      }
    }
  }, [quaery]);

  const throttleTextMake = useThrottle(searchTextMake, 500);
  const throttleTextModel = useThrottle(searchTextModel, 500);
  const throttleTextVarient = useThrottle(searchTextVarient, 500);

  const quaeryHandler = useCallback((val) => {
    setQuaery(val);
  }, []);

  useEffect(() => {
    if (value == 'make') {
      quaeryHandler(throttleTextMake);
    } else if (value == 'model') {
      quaeryHandler(throttleTextModel);
    } else if (value == 'varient') {
      quaeryHandler(throttleTextVarient);
    }
  }, [quaeryHandler, throttleTextMake, throttleTextModel, throttleTextVarient]);

  const handleChangeMakeModalVarient = (el) => {
    let obj = { ...makeModalVariant, [value]: el };
    setMakeModalVariant(obj);
    setErrorr(false);
    if (value == 'make') {
      setValue('model');
      let modelArr = [];
      let varientArr = [];
      vehicleMaster?.forEach((el) => {
        if (obj.make == el.Make.toString().trim()) {
          modelArr.push(el.Model.toString().trim());
          varientArr.push(el.Variant.toString().trim());
        }
      });
      modelArr.sort();
      varientArr.sort();
      setModal([...new Set(modelArr)]);
      setSuggestionModal([...new Set(modelArr)]);
      setVarient([...new Set(varientArr)]);
      setSuggestionVarient([...new Set(varientArr)]);
    } else if (value == 'model') {
      setValue('varient');
      let varientArr = [];
      vehicleMaster?.forEach((el) => {
        if (obj.make == el.Make.toString().trim() && obj.model == el.Model.toString().trim()) {
          varientArr.push(el.Variant.toString().trim());
        }
      });
      varientArr.sort();
      setVarient([...new Set(varientArr)]);
      setSuggestionVarient([...new Set(varientArr)]);
    } else if (value == 'varient') {
      setValue('fuel');
      let fuelType = [];
      vehicleMaster?.forEach((el) => {
        if (obj.make == el.Make.toString().trim() && obj.model == el.Model.toString().trim() && obj.varient == el.Variant.toString().trim()) {
          fuelType.push(el['Fuel Type'].toUpperCase().toString().trim());
        }
      });
      fuelType.sort();
      setFuel([...new Set(fuelType)]);
    }
  };

  const handleChange = (event, newValue) => {
    setErrorr(false);
    setValue(newValue);
  };

  const handleApply = () => {
    if (makeModalVariant.make && makeModalVariant.varient && makeModalVariant.model && makeModalVariant.fuel) {
      let applyObj = { ...formData };
      applyObj.vehicle.makerModel = makeModalVariant.make
      applyObj.vehicle.model = makeModalVariant.model;
      applyObj.vehicle.variant = makeModalVariant.varient;
      applyObj.vehicle.fuelType = makeModalVariant.fuel;
      vehicleMaster.forEach((el) => {
        if (el.Make.toString().trim() == makeModalVariant.make && el.Model.toString().trim() == makeModalVariant.model && el.Variant.toString().trim() == makeModalVariant.varient && el['Fuel Type']?.toUpperCase().toString().trim() == makeModalVariant.fuel) {
          applyObj.vehicle.vehicleMaincode = el['Vehicle Code'].toString().trim();
        }
      });
      if (applyObj.vehicle.vehicleMaincode != '') {
        setFormData(applyObj);
        // AddToLocalStorage('globalObj', applyObj);
        setVehicleMainCodeError(false);
        setOpen(false);
      } else {
        setVehicleMainCodeError(true);
      }
    } else {
      setIsSubmited(true);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="modal-container">
      <Grid container className="make-modal-varient-main-container">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/*******Tabs for Switching Modal content**********/}
          <Grid item xs={12} sm={6} md={12}>
            <Tabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary" aria-label="secondary tabs example" className="make-modal-varient-tab-parent">
              <Tab value="make" label="Make" className="make-modal-varient-tab-label" />
              <Tab value="model" label="Model" className="make-modal-varient-tab-label" />
              <Tab value="varient" label="Variant" className="make-modal-varient-tab-label" />
              <Tab value="fuel" label="Fuel" className="make-modal-varient-tab-label" />
            </Tabs>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} className="make-modal-varient-suggestion-div">
            {/****** Based On Tab Value Model will Render********/}
            {value == 'make' ? (
              <Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="srach-Box-parent-div">
                    <svg class="icon" aria-hidden="true" viewBox="0 0 24 24">
                      <g>
                        <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                      </g>
                    </svg>
                    <input type="text" value={searchTextMake} className="address-search-box" onChange={(e) => setSearchTextMake(e.target.value)} placeholder="Search By Make" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container gap={5} className="state-city-modal-parent" justifyContent="center" alignItems="center">
                    {errorr ? (
                      <div style={{ color: 'red', marginTop: '120px', fontWeight: '600' }}>{SearchNotFoundErrorMessage}</div>
                    ) : (
                      suggestionMake?.map((el) => (
                        <Grid
                          item
                          xs={5}
                          md={3}
                          className="make-modal-varient-eachelement"
                          style={makeModalVariant.make == el ? { backgroundColor: '#31a0d8', color: 'white', textAlign: 'center' } : { textAlign: 'center' }}
                          onClick={() => {
                            handleChangeMakeModalVarient(el);
                          }}
                        >
                          {el}
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : value == 'model' ? (
              <Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="srach-Box-parent-div">
                    <svg class="icon" aria-hidden="true" viewBox="0 0 24 24">
                      <g>
                        <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                      </g>
                    </svg>
                    <input type="text" value={searchTextModel} className="address-search-box" onChange={(e) => setSearchTextModel(e.target.value)} placeholder="Search By Model" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container gap={4} className="state-city-modal-parent" justifyContent="center" alignItems="center">
                    {errorr ? (
                      <div style={{ color: 'red', marginTop: '120px', fontWeight: '600' }}>{SearchNotFoundErrorMessage}</div>
                    ) : (
                      suggestionModal?.map((el) => (
                        <Grid
                          item
                          xs={5}
                          md={3}
                          className="make-modal-varient-eachelement"
                          style={makeModalVariant.model == el ? { backgroundColor: '#31a0d8', color: 'white', textAlign: 'center' } : { textAlign: 'center' }}
                          onClick={() => {
                            handleChangeMakeModalVarient(el);
                          }}
                        >
                          {el}
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : value == 'varient' ? (
              <Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="srach-Box-parent-div">
                    <svg class="icon" aria-hidden="true" viewBox="0 0 24 24">
                      <g>
                        <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                      </g>
                    </svg>
                    <input type="text" value={searchTextVarient} className="address-search-box" onChange={(e) => setSearchTextVarient(e.target.value)} placeholder="Search By Varient" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container gap={4} className="state-city-modal-parent" justifyContent="center" alignItems="center">
                    {errorr ? (
                      <div style={{ color: 'red', marginTop: '120px', fontWeight: '600' }}>{SearchNotFoundErrorMessage}</div>
                    ) : (
                      suggestionVarient?.map((el) => (
                        <Grid
                          item
                          xs={5}
                          md={3}
                          className="make-modal-varient-eachelement"
                          style={makeModalVariant.varient == el ? { backgroundColor: '#31a0d8', color: 'white', textAlign: 'center' } : { textAlign: 'center' }}
                          onClick={() => {
                            handleChangeMakeModalVarient(el);
                          }}
                        >
                          {el}
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container gap={4} className="state-city-modal-parent" justifyContent="start" alignItems="center">
                {fuel?.map((el) => (
                  <Grid
                    item
                    xs={5}
                    md={3}
                    style={makeModalVariant.fuel == el ? { backgroundColor: '#31a0d8', color: 'white', textAlign: 'center' } : { textAlign: 'center' }}
                    className="make-modal-varient-eachelement"
                    onClick={() => {
                      handleChangeMakeModalVarient(el);
                    }}
                  >
                    {el}
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
          <CloseIcon sx={{ cursor: 'pointer', position: 'absolute', right: '12px', top: '10px' }} md={{ right: '20px', top: '20px' }} onClick={() => setOpen(false)} />
        </Grid>

        {/****** Apply All Make Modal Varient user Selected********/}

        {value == 'fuel' ? (
          <button className="make-modal-varient-apply-button" onClick={handleApply}>
            Apply
          </button>
        ) : (
          <div />
        )}

        {/****** Alert showing if any feild missed for selecting********/}

        {isSubmited ? <AlertError open={isSubmited} setOpen={setIsSubmited} message={MandatoryFieldsSelectingErrorMessage} /> : ''}
        {vehicleMainCodeError ? <AlertError open={vehicleMainCodeError} setOpen={setVehicleMainCodeError} message={VehicleMainCodeErrorMessage} /> : ''}
      </Grid>
    </Modal>
  );
};

export default MakeModalVarient;
