// Local URL's
// export const vehicle_api=`http://localhost:8081`;
// export const travel_api=`http://localhost:8080`;
// export const loan_api = `https://localhost/8082`
// export const health_api = `https://localhost/8083`
// export const common_api = `http://localhost:8084`;

//Newly Prod Deployed URL's
export const vehicle_api =`https://szh7xnve3j.execute-api.ap-south-1.amazonaws.com/prod`; 
export const health_api = ` https://c4vygufp78.execute-api.ap-south-1.amazonaws.com/Prod`;
export const travel_api = `https://nfo35x58dk.execute-api.ap-south-1.amazonaws.com/Prod`
export const loan_api = `https://2fsu6kdjfj.execute-api.ap-south-1.amazonaws.com/Prod`
export const common_api = `https://75e8n7v4uj.execute-api.ap-south-1.amazonaws.com/Prod`       
export const crm_api = `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod`
// export const crm_api = ' https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage'

// Stage Deployed urls
// export const travel_api = `https://cuuziphbdc.execute-api.ap-south-1.amazonaws.com/Stage`
// export const loan_api = `https://tld032tp79.execute-api.ap-south-1.amazonaws.com/Stage`
// export const common_api = `https://t2ry125acg.execute-api.ap-south-1.amazonaws.com/Stage`
// export const crm_api = `https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage`

// Old Deployed URL's
// export const api = `https://apw5dz3t41.execute-api.ap-south-1.amazonaws.com/live`; // dev_deploy //Previous
// export const api = `https://hhke4ii62e.execute-api.ap-south-1.amazonaws.com/Prod`; //prod_deploy
// export const api = `https://bh761fnvbl.execute-api.ap-south-1.amazonaws.com/live`; //dev_deploy //Latest
