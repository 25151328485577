import React, { useEffect } from 'react';
import vectorobject from '../../Images/Bike-Page/Bike Insurance 01-01.png';
import bikereverse from '../../Images/Bike-Page/Bike Insurance 02-01.png';
import QuotationDetails from '../../Components/Quotation/QuotationDetails';
import '../../../Framework/Pages/Main.css';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router';

const BikeQuotations = () => {
  const naviagte = useNavigate();
  
  const handlebackBtn = () => {
    naviagte('/motor/bike/details');
  };

  return (
    <div>
      <QuotationDetails />
    </div>
  );
};

export default BikeQuotations;
