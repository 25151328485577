import React, { useEffect, useState } from "react";
import { Grid, Typography, Card, CardContent } from "@mui/material";
import { productDetails } from "../../Data/Benifits";
 
const PlanBenefits = ({ quote }) => {
  const [data, setdata] = useState([]);
 
  useEffect(() => {
    let benefits = productDetails.find(
      (el) => el.provider.toUpperCase() === quote?.provider
    );
    setdata(benefits?.features);
  }, [quote]);
 
  console.log(quote,'quote');
  console.log(data,'data for benefits');
 
  const cardStyle = {
    margin: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "85%",
    padding: "12px",
  };
 
  return (
    <div style={{ padding: "16px" }}>
      <Grid container spacing={2}>
        {data?.map((benefit, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              style={cardStyle}
              sx={{
                boxShadow:
                  "0px 8px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.08)",
                transition: "0.3s",
                "&:hover": {
                  boxShadow:
                    "0px 16px 30px rgba(0, 0, 0, 0.1), 0px 8px 12px rgba(0, 0, 0, 0.08)",
                },
                backgroundColor: "#f7faff",
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  style={{
                    color: "#253858",
                    fontSize: "19px",
                    fontWeight: 600,
                  }}
                >
                  {benefit?.feature}
                </Typography>
                <Typography variant="body2">{benefit?.benefit}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
 
export default PlanBenefits;