import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectResult } from "../../../../Redux/reducers/posp/examinationreducer/examSelectors";
import {
  Typography,
  Container,
  Box,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { fetchPospData } from "../../../../Redux/action-creators/posp/pospAction";

const displayAttemptsAndResultCard = (result) => {
  const { attempt, status, marks } = result?.res[0];
  //const { attempt } = result?.res[0];

  const totalQuestions = 30; // Adjust this value based on your total questions or marks logic

  // Calculate percentage score
  const percentageScore = (marks / totalQuestions) * 100;
  const isPass = status === "Pass";

  return (
    <>
      <Box mb={2}>
        {isPass ? (
          <CheckCircleIcon color="success" style={{ fontSize: 40 }} />
        ) : (
          <CancelIcon color="error" style={{ fontSize: 40 }} />
        )}
      </Box>
      <Typography variant="h5" gutterBottom>
        {isPass ? "Congratulations!" : "Better Luck Next Time!"}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {isPass
          ? "You have passed the examination. You are eligible to become a certified POS agent."
          : "Unfortunately, you did not pass the examination. Please try again."}
      </Typography>
      <Box my={4}>
        <CircularProgress
          variant="determinate"
          value={percentageScore}
          size={80}
          thickness={5}
        />
        <Box mt={2}>
          <Typography variant="h6">Your Score</Typography>
          <Typography variant="h5" color="primary">
            {percentageScore.toFixed(2)}%
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-around" mb={2}>
        <Box>
          <Typography variant="body2">Passing marks</Typography>
          <Typography variant="body1">60%</Typography>
        </Box>
        <Box>
          <Typography variant="body2">Total questions</Typography>
          <Typography variant="body1">30</Typography>
        </Box>
        <Box>
          <Typography variant="body2">Attempted</Typography>
          <Typography variant="body1">{attempt}</Typography>
        </Box>
      </Box>
    </>
  );
};

const displayMaximumAttemptsOver = (result) => {
  return (
    <Typography variant="h5" sx={{ marginBottom: "15px" }}>
      {result}
    </Typography>
  );
};

// Main Component
const ResultDisplay = () => {
  // Destructure the needed information from the result prop
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const result = useSelector(selectResult);

  // const {  marks, status, attempt } = result?.res[0];

  useEffect(() => {
    const phone = localStorage.getItem("phone");
    dispatch(fetchPospData(phone));
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      dispatch({ type: "CLEAR_EXAM_STATE" });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      // dispatch({ type: "CLEAR_EXAM_STATE" });
    };
  }, [dispatch]);

  const clickDashboard = () => {
    dispatch({ type: "CLEAR_EXAM_STATE" });
    navigate("/posp/personaldetails");
  };

  return (
    <Container maxWidth="xs">
      <Paper
        elevation={3}
        style={{ padding: "20px", textAlign: "center", marginTop: "20px" }}
      >
        {typeof result === "string"
          ? displayMaximumAttemptsOver(result)
          : displayAttemptsAndResultCard(result)}
        <Button variant="contained" color="primary" onClick={clickDashboard}>
          Go to Dashboard
        </Button>
      </Paper>
    </Container>
  );
};

export default ResultDisplay;
