import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { IconButton, TableCell } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PrintIcon from "@mui/icons-material/Print";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

export const DenseTable = (props) => {
  const { t } = useTranslation();

  return (
    <div className="py-3">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead sx={{ backgroundColor: "lightgray" }}>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                {t("lmv.customer.userId")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                {t("lmv.customer.date")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                {t("lmv.customer.amountToBeClaimed")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                {t("lmv.customer.claimedAmount")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                {t("lmv.customer.type")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                {t("lmv.customer.paymentStatus")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                {t("lmv.customer.claimStatus")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                {t("lmv.customer.amountClaimed")} <br />
                {t("lmv.customer.inNumberOfDays")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                {t("lmv.customer.filter")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {props.claimDetails && props.claimDetails.length>0 ? props.claimDetails.map((row) => (
                  <TableRow
                      key={row.id}
                      sx={{"&:last-child td, &:last-child th": {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell sx={{textAlign: "center"}}>{row.claim_date}</TableCell>
                    <TableCell sx={{textAlign: "center"}}>{row.amount_claimed}</TableCell>
                    <TableCell sx={{textAlign: "center"}}>{row.amount_claimed}</TableCell>
                    <TableCell sx={{textAlign: "center"}}>{row.claim_type}</TableCell>
                    <TableCell sx={{textAlign: "center"}}>{row.payment_status}</TableCell>
                    <TableCell sx={{textAlign: "center"}}>{row.claim_status}</TableCell>
                    <TableCell sx={{textAlign: "center"}}/>
                    <TableCell>
                      <IconButton aria-label="download">
                        <CloudDownloadIcon/>
                      </IconButton>
                      <IconButton aria-label="print">
                        <PrintIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
              )):
              <Typography sx={{textAlign: 'center'}}>{t('lmv.customer.noData')}</Typography>} */}
            <TableRow>
              <TableCell sx={{ textAlign: "center" }}>{1214}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>{"23-09-2023"}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>{50000}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>{50000}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>{"Medical"}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>{"Pending"}</TableCell>
              <TableCell>{"Pending"}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>{15}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                <IconButton aria-label="download">
                  <CloudDownloadIcon />
                </IconButton>
                <IconButton aria-label="print">
                  <PrintIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
