import * as types from "./actionType";
import axios from "axios";
import { common_api, travel_api } from "../../Config";

function handleForm(formData) {
  return { type: "HANDLE_FORM_DATA", payload: formData };
}

function handleResponse(plans) {
  return { type: "HANDLE_RESPONSE", payload: plans };
}

function handleSelectedPlan(selectedPlan) {
  return { type: "HANDLE_SELECTED_PLAN", payload: selectedPlan };
}

const loadingRequest = () => {
  return {
    type: types.Get_Dashboard_Data_Request,
  };
};
export const postTotalCustomData = (payload) => {
  return {
    type: types.Add_Customer_Data_Success,
    payload,
  };
};

export const getSuccessSmsRequest = (payload) => {
  return {
    type: types.Mobile_OTP_Request,
    payload,
  };
};

export const getSuccessSmsResponse = (payload) => {
  return {
    type: types.Mobile_OTP_Verification_Request,
    payload,
  };
};

const addTravelCustomerData = (requestBody) => (dispatch) => {
  console.log(requestBody, "helo");
  dispatch(loadingRequest());
  axios
    .post(`${common_api}/insurance/customer`, requestBody)
    .then((response) => {
      dispatch(postTotalCustomData(response.data));
    })
    .catch((error) => console.log(error, "err"));
};

// const getSmsResponse =
//   (phone, method, org_id, agent_id, type) => (dispatch) => {
//     dispatch(loadingRequest());
//     axios
//       .post(`${travel_api}/messages/v1/sms`, {
//         phone,
//         method,
//         org_id,
//         agent_id,
//         type,
//       })
//       .then((response) => {
//         dispatch(getSuccessSmsRequest(response.data));
//         console.log(response, "response");
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

// const getSmsVerificationResponse =
//   (phone, verification_code, method, type) => (dispatch) => {
//     dispatch(loadingRequest());
//     axios
//       .post(`${travel_api}/messages/v1/sms`, {
//         phone,
//         verification_code,
//         method,
//         type,
//       })
//       .then((response) => {
//         dispatch(getSuccessSmsResponse(response.data.statusCode));
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

const ProposalDetails = (payload) => {
  return {
    type: types.proposalDetails,
    payload,
  };
};

const MemberDetails = (payload) => {
  return {
    type: types.MemberDetails,
    payload,
  };
};

const NomineeDetails = (payload) => {
  return {
    type: types.NomineeDetails,
    payload,
  };
};

const MedcalData = (payload)=>{
  return{
    type:types.MedcalData,
    payload
  };
};

export {
  handleForm,
  handleResponse,
  handleSelectedPlan,
  addTravelCustomerData,
  ProposalDetails,
  MemberDetails,
  NomineeDetails,
  MedcalData
};
