import React from 'react'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';


const PaymentPage = () => {
  let [loading, setLoading] = useState(true);
  let proposalNum = useSelector((e) => e.loanReducer?.ProposalData?.intPolicyDataIO?.policy?.proposalNum);


  const handleSubmit = async () => {
    try {
      const form = document.getElementById("form");
      let data = await form.submit();
      return data
    } catch (error) {
      console.error("Error during payment:", error);
    }
    finally {
      setLoading(false)
    }
  };


  useEffect(() => {
    setTimeout(() => {
      handleSubmit();
    }, 1000);
  }, [proposalNum]);









  return (
    <div>
      {loading &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,flexDirection:'column',gap:5 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
          <Typography>Redirecting to care payment page</Typography>
        </Backdrop>
      }
      <form
        action="https://api.religarehealthinsurance.com/portalui/PortalPayment.run"
        name="PAYMENTFORM"
        method="post"
        id="form"
      >
        <input type="hidden" name="proposalNum" value={proposalNum} />
        <input type="hidden" name="returnURL" value="https://main.d315p1596xx1q3.amplifyapp.com/loan/thankyou" />
      </form>
    </div>


  )
}

export default PaymentPage
