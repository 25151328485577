// src/components/ModalComponent.jsx

import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import HealthBreakUp from "../../Health/Pages/Health info/HealthBreakUp";
import HospitalList from "../../Health/Pages/Health info/HospitalList";
import Brouchure from "../../Health/Pages/Health info/Brouchure";
import PlanBenefits from "../../Health/Pages/Health info/PlanBenefits";
import {Paper, Typography} from "@mui/material"

const ModalComponent = ({ open, handleClose, quote, features }) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  console.log(quote)
  const tabComponents = [
    <HealthBreakUp quote={quote} />,
    <HospitalList quote = {quote}/>,
    <Brouchure quote={quote}/>,
    <PlanBenefits quote={quote} />,
  ];

  const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
 
  const closeButtonStyle = {
    position: 'absolute',
    top: '8px',
    right: '8px',
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={modalStyle}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Paper sx={{
          position: 'absolute',
          borderRadius: "2px",
          width: { xs: "90%", sm: "80%" }, // Adjust width for small devices
          height: "90%",
          backgroundColor: '#fff',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          padding: '14px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'hidden',
        }}>
        <IconButton
          aria-label="close"
          style={closeButtonStyle}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" id="modal-title" align="center">
          Features
        </Typography>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons={false}
          allowScrollButtonsMobile
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Break Up" />
          <Tab label="Hospital List" />
          <Tab label="Brouchure" />
          <Tab label="Plan Benefit" />
        </Tabs>
        <Box mt={2} sx={{height:"82%",overflow:"scroll"}}>{tabComponents[tabValue]}</Box>
      </Paper>
    </Modal>
  );
};

export default ModalComponent;
