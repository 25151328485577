export const styles = {
  typography: {
    padding: { xs: "0.5em", md: "1em" },
    paddingBottom: { xs: "0.5em", md: "1em" },
  },
  capitalizeTextField: {
    backgroundColor: "white",
    "& input:-webkit-autofill": {
      transition: "background-color 5000s ease-in-out 0s",
      backgroundColor: "white !important",
    },
  },
  paddingLeft: {
    paddingLeft: "1em",
  },
  marginBottom: {
    marginBottom: "2em",
  },
  containerBorder: {
    border: "2px solid #c8c8c8",
    borderRadius: "8px",
    marginTop: "8px",
  },
  chip: {
    backgroundColor: "#ecf8ff",
    color: "#23a8f8",
    border: "1px solid #23a8f8",
    borderRadius: "5px",
    padding: "5px 10px",
    margin: "0 10px 10px 0",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "12px",
  },
  outlinedInput: {
    "& .MuiOutlinedInput-root": {
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0",
    },
    "& .MuiInputBase-input": {
      "&:focus": {
        boxShadow: "none",
      },
    },
  },
  scrollableBox: {
    maxHeight: "250px",
    overflowY: "scroll",
    border: "1px solid rgba(255,255,255,.1)",
    borderRadius: "8px",
    position: "absolute",
    zIndex: "3",
    top: "0",
    left: "0",
    width: "100%",
  },
  countryOption: {
    fontSize: "0.9em",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "12px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    transition: "background-color 0.1s linear",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#4eaaff",
      color: "white",
    },
  },
  columnFlexBox: {
    display: "flex",
    flexDirection: "column",
  },
  errorText: {
    marginTop: "-2.4em",
    paddingLeft: "1em",
  },
  centerText: {
    width: "100%",
    textAlign: "center",
  },
  boldText: {
    fontWeight: "bold",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexBoxStyles: {
    padding: "8px",
    display: "flex",
    gap: "12px",
    flexWrap: "wrap",
  },
  flexContainerStyles: {
    padding: "0px",
    display: "flex",
  },
  chipStyles: {
    backgroundColor: "#f2f2f2",
    borderRadius: "5px",
    fontWeight: "600",
    fontSize: "12px",
    margin: "0 10px 10px 0",
    cursor: "pointer",
    padding: "5px 10px",
    border: "1px solid white",
  },
  selectedChipStyles: {
    backgroundColor: "#ecf8ff",
    color: "#23a8f8",
    border: "1px solid #23a8f8",
    borderRadius: "5px",
    padding: "5px 10px",
    margin: "0 10px 10px 0",
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "12px",
  },
};
