/*
 * Copyright © 2023 LMV Insurance Private Limited India
 * Author @lmvArchitect March 2023
 */
const masterType = require('../../Vehicle/data/mastertype.json');
const pincodeData = require('../../Vehicle/data/pinCodeMaster.json');
const rtocityData = require('../../Vehicle/data/cityRtoMaster.json');
const companyData = require('../../Vehicle/data/companyInsuranceMaster.json');
const ncbData = require('../../Vehicle/data/ncbMaster.json');
const relationData = require('../../Vehicle/data/nomineeMaster.json');
const vehicalData = require('../../Vehicle/data/vehicleMaster.json');
const subInsuranceData = require('../../Vehicle/data/subInsuranceMaster.json');
const digiproductData = require('../../Vehicle/data/digiproductMaster.json');
const usageTypeData = require('../../Vehicle/data/usageType.json');

//This service return master type list
export const getMasterType = async () => {
  return masterType;
};

//This service return master data based on parameters
export const getMasterData = async (masterType, vehicalType) => {
  let data = [];
  switch (masterType) {
    case 'pincode':
      data = pincodeData;
      break;
    case 'rtocity':
      data = rtocityData;
      break;
    case 'company':
      data = companyData;
      break;
    case 'ncb':
      data = ncbData;
      break;
    case 'relation':
      data = relationData;
      break;
    case 'usageType':
      data = usageTypeData;
      break;
    case 'vehical':
      if (vehicalType) {
        if (vehicalType == 'MotorBike') {
          data = vehicalData.filter((item) => item.vehicleType === vehicalType || item.vehicleType === 'Scooter');
        } else if (vehicalType == 'Pvt Car') {
          data = vehicalData.filter((item) => item.vehicleType === vehicalType || (item.vehicleType === 'Passenger Carrying' && item['No Of Wheels'] == 4));
        } else if (vehicalType == 'Goods Carrying') {
          data = vehicalData.filter((item) => item.vehicleType === vehicalType || item.vehicleType === 'Passenger Carrying' || item.vehicleType === 'Miscellaneous');
        }
        if (data.length == 0) {
          data = vehicalData;
        }
      } else {
        data = vehicalData;
      }
      break;
    case 'subInsurance':
      data = subInsuranceData;
      break;
    case 'digiproduct':
      data = digiproductData;
      break;
    default:
      break;
  }
  return data;
};
