import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Phone, VpnKey } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { signupContainer, signupMain } from "./UserSignupStyle";
import {
  clearSuccessMessage,
  setSuccessMessage,
} from "../../../../Redux/action-creators/successAction";
import {
  clearError,
  setError,
} from "../../../../Redux/action-creators/errorAction";
import { formFieldError } from "../../../../Framework/common-css/style";
import { useTranslation } from "react-i18next";
import {
  generateOtp,
  otpValidation,
  validateUserByPhone,
} from "../../api/customerService";

const UserSignup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isResendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [step, setStep] = useState(1); // Step 1 for mobile number, Step 2 for OTP
  const [loading, setLoading] = useState(false);
  const [isResendOtpLoaderTrue, setResendOtpLoaderTrue] = useState(false);

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  let timer;
  useEffect(() => {
    if (isResendDisabled) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isResendDisabled]);

  useEffect(() => {
    if (countdown === 0) {
      setResendDisabled(false);
      clearInterval(timer);
      setCountdown(60); // Reset countdown
    }
  }, [countdown]);

  const handleResendClick = async () => {
    const mobileNumber = getValues("mobileNumber");
    await sendOtp(mobileNumber);
    setResendDisabled(true);
  };

  const handleMobileNumber = () => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const mobileNumber = getValues("mobileNumber");
    const username = getValues("full_name");
    const isNumber = () => !isNaN(Number(mobileNumber));
    if (isNumber && mobileNumber.toString().trim().length === 10) {
      if (/^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(username)) {
        setLoading(true);
        validateUser(mobileNumber.toString().trim());
      } else {
        dispatch(setError("Invaild Username"));
      }
    } else {
      dispatch(setError(t("lmv.customer.invalidMobileNumber")));
    }
  };

  const validateUser = async (phone) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());

    try {
      const response = await validateUserByPhone({
        enrollType: "SIGNUP",
        phone: phone,
      });
      if (response?.status > 400) {
        dispatch(setError(t("lmv.serverError")));
        setLoading(false);
      } else {
        if (response?.data?.signUpRequestFlag) {
          sendOtp(phone);
        } else {
          dispatch(setError(response?.data?.message));
          setLoading(false);
        }
      }
    } catch (e) {
      dispatch(setError(t("Internal Server Error")));
      setLoading(false);
    }
  };

  const sendOtp = async (mobileNumber) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    setResendOtpLoaderTrue(true);
    try {
      const response = await generateOtp({ phone: mobileNumber });
      if (response?.status > 400) {
        dispatch(setError(t("lmv.serverError")));
      } else {
        setStep(2);
        dispatch(setSuccessMessage("Otp sent successfully"));
      }
    } catch (e) {
      dispatch(setError(t("Internal Server Error")));
    } finally {
      setResendOtpLoaderTrue(false);
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());

    if (step === 1) {
      handleMobileNumber();
    } else {
      try {
        setLoading(true);
        const otpObject = {
          phone: data?.mobileNumber,
          customer_name: data?.full_name,
          otp: data?.otp,
          enrollType: "SIGNUP",
        };
        const response = await otpValidation(otpObject);
        if (response.status > 400) {
          dispatch(setError(t("lmv.serverError")));
        } else {
          if (response?.data?.isOtpVerified) {
            dispatch(
              setSuccessMessage("Otp verified successfully. Now You can Login")
            );
            navigate("/customer");
          } else {
            dispatch(setError(response?.data?.message));
          }
        }
      } catch (e) {
        dispatch(setError(t("Internal Server Error")));
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePhoneChange = () => {
    setStep(1);
  };

  return (
    <div>
      <Container style={signupContainer}>
        <Paper style={signupMain}>
          <form className="mx-auto py-2 px-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="text-center mb-4">
              <Typography variant="h4">
                {t("lmv.customer.createAccount")}
              </Typography>
            </div>

            {/* full name */}
            <Grid item xs={12}>
              <Controller
                name="full_name"
                control={control}
                defaultValue=""
                rules={{
                  required: "Name is required",
                  pattern: {
                    value: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,
                    message: "Invalid Name",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={"Full Name"}
                    variant="outlined"
                    margin="normal"
                    id="full_name"
                    fullWidth
                    onInput={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                    }}
                    error={!!errors?.full_name}
                    disabled={step !== 1}
                  />
                )}
              />
              {errors?.full_name?.message && (
                <Typography style={{ color: "red" }} variant="caption">
                  {errors?.full_name?.message}
                </Typography>
              )}
            </Grid>

            {/* mobile number */}
            <div className="mb-1">
              <Controller
                name="mobileNumber"
                control={control}
                defaultValue=""
                rules={{
                  required: t("lmv.requiredField"),
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: t("lmv.customer.invalidMobileNumber"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Mobile Number"
                    margin="normal"
                    id="mobileNumber"
                    fullWidth
                    disabled={step !== 1}
                    error={!!errors.mobileNumber}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone color="action" />
                        </InputAdornment>
                      ),
                    }}
                    onInput={(e) => {
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                    }}
                    // onBlur={(e) => handleMobileNumberBlur(e.target.value)}
                  />
                )}
              />
              {errors.mobileNumber?.message && (
                <div>
                  <Typography style={formFieldError} variant="caption">
                    {errors.mobileNumber?.message}
                  </Typography>
                </div>
              )}
            </div>

            {/* proceed button */}
            {step === 1 && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleMobileNumber}
                  disabled={loading} // Disable while loading
                  startIcon={loading && <CircularProgress size={20} />} // Show spinner while loading
                >
                  {loading ? `proceeding` : t("lmv.customer.proceed")}
                </Button>
              </Grid>
            )}

            {step === 2 && (
              <>
                {/* otp field */}
                <div className="mb-1">
                  <Controller
                    name="otp"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("lmv.requiredField"),
                      pattern: {
                        value: /^[0-9]{4}$/,
                        message: "Invalid Otp",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("lmv.customer.otp")}
                        margin="normal"
                        fullWidth
                        id="otp"
                        error={!!errors.otp}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <VpnKey color="action" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={handleResendClick}
                                disabled={isResendDisabled}
                              >
                                {isResendOtpLoaderTrue ? (
                                  <CircularProgress
                                    fullWidth
                                    sx={{ color: "#ffffff" }}
                                    size={25}
                                  />
                                ) : (
                                  t("lmv.customer.resend")
                                )}
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                        onInput={(e) => {
                          if (e.target.value.length > 4) {
                            e.target.value = e.target.value.slice(0, 4);
                          }
                        }}
                      />
                    )}
                  />
                  {errors.otp?.message && (
                    <div>
                      <Typography style={formFieldError} variant="caption">
                        {errors.otp?.message}
                      </Typography>
                    </div>
                  )}
                </div>

                {/* agree checkbox */}
                <div className="mb-1">
                  <FormControlLabel
                    control={
                      <Controller
                        name="agree"
                        control={control}
                        defaultValue={false}
                        rules={{ required: t("lmv.customer.signUpAgreeText") }}
                        render={({ field }) => (
                          <Checkbox {...field} color="primary" />
                        )}
                      />
                    }
                    label={t("lmv.customer.signUpAgreeText2")}
                  />
                  {errors.agree?.message && (
                    <div>
                      <Typography style={formFieldError} variant="caption">
                        {errors.agree?.message}
                      </Typography>
                    </div>
                  )}
                </div>

                {/* submit button */}
                <div className="mb-1">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading} // Disable while loading
                    startIcon={loading && <CircularProgress size={20} />} // Show spinner while loading
                  >
                    {loading ? `loading` : t("lmv.customer.signUp")}
                  </Button>
                </div>

                {/* resend otp */}
                {isResendDisabled && (
                  <div className="mb-1">
                    <Button variant="text" fullWidth disabled>
                      Resend Otp in {countdown} seconds
                    </Button>
                  </div>
                )}
              </>
            )}

            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                {/* navigate signin */}
                <Grid item>
                  <Typography variant="body2" align="center">
                    {t("lmv.customer.alreadyHaveAccount")}{" "}
                    <Link to="/customer">{t("lmv.customer.signIn")}</Link>
                  </Typography>
                </Grid>

                {/* change number */}
                {step === 2 && (
                  <Grid item>
                    <Link onClick={handlePhoneChange}>
                      {t("lmv.customer.changeMobileNumber")}
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default UserSignup;
