import React, { useState } from 'react'
import { Modal, Box, Typography, Stack, IconButton, Grid } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ClearIcon from '@mui/icons-material/Clear';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { currencyFormat } from '../../../Framework/Utils/CurrencyFormat';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const AddToCompareModal = ({ arr, open, handleClose }) => {
    console.log(arr,"arrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
    const [expanded, setExpanded] = useState(false);
    const [ownDamageExpand, setOwnDamageExpand] = useState(false);
    const [addonsExpand, setAddonsExpand] = useState(false);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: '100%',
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 2,
        borderRadius: 3,
        maxWidth: '950px',
        height: '500px',
        overflowY: 'scroll'
    };

    const toggleExpansion = () => {
        setExpanded(!expanded);
        setAddonsExpand(!addonsExpand);
        setOwnDamageExpand(!ownDamageExpand)
    };
    const toggleExpansion1 = () => {
        setAddonsExpand(!addonsExpand);
    };
    const toggleExpansion2 = () => {
        setOwnDamageExpand(!ownDamageExpand)
    };
    // const handleDownloadPDF = () => {
    //     html2canvas(document.getElementById('comparison-container')).then(canvas => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF();
    //         pdf.addImage(imgData, 'PNG', 0, 0);
    //         pdf.save('comparison.pdf');
    //     });
    // };

    const handleDownloadPDF = () => {
        // Get the comparison container element
        const comparisonContainer = document?.getElementById('comparison-container');

        // Set options for html2canvas
        const options = {
            logging: true, // Enable logging
            allowTaint: true, // Allow cross-origin images to taint the canvas
            useCORS: true, // Use CORS to fetch image data
            scale: 2, // Increase scale for better resolution (optional)
            backgroundColor: '#ffffff' // Set background color of the PDF
        };

        // Use html2canvas to capture the comparison container
        html2canvas(comparisonContainer, options).then(canvas => {
            // Convert canvas to base64 image data URL
            const imgData = canvas.toDataURL('image/png');

            // Create new jsPDF instance
            const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait orientation, millimeters, A4 size

            // Add custom content to PDF
            pdf.setFontSize(16);
            pdf.text('Comparison Details', 20, 20);

            // Add image from canvas to PDF
            pdf.addImage(imgData, 'PNG', 10, 30, 190, 0); // 190 is the width, height is automatically calculated to maintain aspect ratio
            // Save PDF file
            pdf.save('comparison.pdf');
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    };

    const CustomPriceBtn = ({ price }) => {
        return <Button variant='contained' sx={{ padding: '10px 18px', borderRadius: '10px', backgroundColor: '#23a8fa', width: '140px' }}>
            <Typography variant='body1'><CurrencyRupeeIcon fontSize='12px' /> {price}</Typography>
        </Button>
    }

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ top: "10%" }}
        >
            <Box sx={style}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={"space-between"} pl={2}>
                    <Box>
                        <Typography id="modal-modal-title" variant="h5" fontWeight={500}>
                            Comparison
                        </Typography>
                    </Box>
                    <Stack direction={'row'} alignItems={'center'} sx={{
                        padding: '1px',
                        border: '1px solid #e3e3e3',
                        borderRadius: "9999px",
                        boxShadow: "10px 9px 10px #e3e3e3"
                    }}>
                        <IconButton color='primary'>
                            <DownloadIcon onClick={handleDownloadPDF}></DownloadIcon>
                        </IconButton>
                        <IconButton color='error'>
                            <MailOutlineIcon>
                            </MailOutlineIcon>
                        </IconButton>
                        <IconButton>
                            <WhatsAppIcon color="success"></WhatsAppIcon>
                        </IconButton>
                        <IconButton color='error' >
                            <ClearIcon onClick={handleClose}></ClearIcon>
                        </IconButton>
                    </Stack>
                </Stack>
                <Box id="comparison-container">
                    <Stack>
                        <Grid container p={2}>
                            <Grid item xs={4} component={Stack} direction={'row'} alignItems={'center'}>
                                {/* <Stack direction={'row'} borderRadius={2} sx={{ width: '150px', height: '60px', boxShadow: '2px 4px 10px lightgray', padding: '5px 8px' }}>
                                    <img src="https://main.dy3gwnxriq0bt.amplifyapp.com/static/media/Logo.58fdf7e33453ed97e74a7ed2a1973e2a.svg" alt="" />
                                </Stack> */}
                            </Grid>
                            <Grid id="" item xs={8}>
                                <Stack direction={'row'}>
                                    {arr?.map((el, index) => {
                                        return (
                                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                                <Box sx={{ width: '100px', height: '56px', overflow: 'hidden', borderRadius: '8px' }}>
                                                    <img src={el?.imageUrl?.ImageUrlLink} alt="" width="300" height="100%" />
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container p={2}>
                            <Grid item xs={4} component={Stack} direction={'row'} alignItems={'center'}>
                                <Typography variant='h6'>Premium</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Stack direction={'row'}>
                                    {arr?.map((el) => {
                                        const amount = el?.grossPremium?.includes('$') ? el?.grossPremium?.split('$')[1] : el?.grossPremium;
                                        return (
                                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                                <CustomPriceBtn price={currencyFormat(amount)} />
                                            </Box>
                                        )
                                    })}
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container p={2}>
                            <Grid item xs={4}>IDV</Grid>
                            <Grid item xs={8}>
                                <Stack direction={'row'}>
                                    {
                                        arr?.map((el) => {
                                            return (
                                                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                                    ₹{currencyFormat(el?.vehicleIDV?.vehicleIDV?.idv)}
                                                </Box>
                                            )
                                        })
                                    }


                                </Stack>
                            </Grid>
                        </Grid>
                        <Box sx={{ borderBottom: '1px solid lightgray' }}>
                            <Accordion sx={{
                                border: 'none',
                                boxShadow: 'none',
                            }}
                                onClick={toggleExpansion}
                            >
                                <AccordionSummary
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Grid container>
                                        <Grid item xs={4}>Own Damage Premium (A) {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Grid>
                                        <Grid item xs={8}>
                                            <Stack direction={'row'}>
                                                {
                                                    arr?.map((el) => {
                                                        return (
                                                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>₹{currencyFormat(Math.round(el?.coverages?.ownDamage?.netPremium))}</Box>
                                                        )
                                                    })
                                                }
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                {/* <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={4}>type</Grid>
                                        <Grid item xs={8}>
                                            <Stack justifyContent={'center'} direction={'row'}>
                                                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>p1</Box>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails> */}
                            </Accordion>
                            <Accordion sx={{
                                border: 'none',
                                boxShadow: 'none',
                            }}
                                onClick={toggleExpansion2}
                            >
                                <AccordionSummary
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Grid container>
                                        <Grid item xs={4}>Third Party Premium (B) {ownDamageExpand ? <ExpandLessIcon onClick={toggleExpansion} /> : <ExpandMoreIcon onClick={toggleExpansion} />}</Grid>
                                        <Grid item xs={8}>
                                            <Stack direction={'row'}>
                                                {
                                                    arr?.map((el) => {
                                                        return (
                                                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>₹{currencyFormat(Math.round(el?.coverages?.thirdPartyLiability?.netPremium))}</Box>
                                                        )
                                                    })
                                                }

                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                {/* <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={4}>type</Grid>
                                        <Grid item xs={8}>
                                            <Stack justifyContent={'center'} direction={'row'}>
                                                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>p1</Box>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails> */}
                            </Accordion>
                            <Accordion sx={{
                                border: 'none',
                                boxShadow: 'none',
                            }}
                                onClick={toggleExpansion1}
                            >
                                <AccordionSummary
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Grid container>
                                        <Grid item xs={4}>Addon Details (C) {addonsExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Grid>
                                    </Grid>
                                    {/* <Grid item xs={4}>
                                            <Stack justifyContent={'center'} direction={'row'}>
                                                {arr.map((el, index) => {
                                                    let related_name = el?.coverages?.addons[index];
                                                    return(
                                                        <Box>₹ {currencyFormat(Math.floor(related_name?.netPremium))}</Box>
                                                )})}
                                            </Stack>
                                        </Grid> */}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                    <Grid item xs={8}>
                                            <Stack direction={'row'} sx={{display:'block'}}>
                                                {arr.map((el, index) => {
                                                    if (el.coverages && el.coverages.addons) {
                                                        const uniqueAddons = new Set();
                                                        return Object.keys(el.coverages.addons).map((addon_name) => {
                                                            const addon = el?.coverages?.addons[addon_name];
                                                            if (addon.selection && !uniqueAddons.has(addon_name)) {
                                                                uniqueAddons.add(addon_name)
                                                                return (
                                                                    <Box sx={{display:'block'}} key={addon_name}>
                                                                        <div>
                                                                            <ul>
                                                                                <li>
                                                                                    {addon_name?.charAt(0)?.toUpperCase() +
                                                                                        addon_name
                                                                                            .slice(1)
                                                                                            .replace(/([A-Z])/g, ` $1`)
                                                                                            .trim()}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </Box>
                                                                );
                                                            }
                                                        });
                                                    }
                                                })}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{
                                border: 'none',
                                boxShadow: 'none',
                            }}>
                                <AccordionSummary
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Grid container>
                                        <Grid item xs={4}>Service Tax/GST (D)</Grid>
                                        <Grid item xs={8}>
                                            <Stack direction={'row'}>
                                                {
                                                    arr.map((el, index) => {
                                                        return (
                                                            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>₹{currencyFormat(el?.serviceTax?.totalTax)}</Box>
                                                        )
                                                    })
                                                }
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={4}>type</Grid>
                                        <Grid item xs={8}>
                                            <Stack justifyContent={'center'} direction={'row'}>
                                                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>p1</Box>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    )
}

export default AddToCompareModal