import React, { Suspense, useEffect, useState } from 'react';
import Footer from '../src/Framework/Components/Footer';
import './Styles.css';
import { GetDataLocalStorage, carReqObj } from './Framework/GlobalObj';
import { CircularProgress, ThemeProvider, CssBaseline, StyledEngineProvider } from '@mui/material';
import { Box } from "@mui/material";
import theme from './Framework/Utils/theme';
import { Route, Routes, Outlet, useNavigate, useLocation } from "react-router-dom";
import Main from './Framework/Pages/Main';
import MotorRoutes from './Vehicle/Routes/MotorRoutes';
import HealthRoutes from './Health/Routes/HealthRoutes';
import TravelRoutes from './Travel/Routes/TravelRoutes';
import PospApp from './crm/posp/PospApp';
import PospLogin from './crm/posp/pages/sign-in/PospLogin';
import LoanRoutes from './Loan/Routes/LoanRoutes';
import Header from "./crm/common/NavBar/BasicHeader";
import UserLogin from "./crm/customer/pages/sign-in/UserLogin";
import UserSignup from "./crm/customer/pages/sign-up/UserSignup";
import { useDispatch, useSelector } from 'react-redux';
import { store } from "./Redux/Store"
import { fetchMasterData } from "./Redux/action-creators/masterDataAction";
import { setToken } from "./Redux/action-creators/authAction";
import ErrorMessage from "./Framework/error-handling/ErrorMessage";
import SuccessMessage from './Framework/error-handling/SuccessMessage';
import NavbarMain from "./Framework/Components/NavbarMain";
import NavBar from "./crm/common/NavBar/NavBar"
import SideDrawer from './Framework/Components/SideDrawer';

import themes from './crm/posp/themes';
import AdminNavbar from './crm/admin/common/Navbar/AdminNavbar';
import AdminLogin from './crm/admin/pages/sign-in/AdminLogin';
import SignUp from "./crm/posp/pages/SignUp/Main.js";
import CustomerRoutes from "./crm/customer/routes/customerRoutes";

function App() {
  const [reqs, setReqs] = useState({});
  const customization = useSelector((state) => state.customization);

  useEffect(() => {
    let obj = GetDataLocalStorage('globalObj');
    if (obj) {
      setReqs(obj);
    } else {
      setReqs(carReqObj);
    }
  }, []);

  const CustomLoading = () => {
    return (
      <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div>

          <CircularProgress color="primary" size={50} />
        </div>
      </div>
    );
  };


  return (
    <StyledEngineProvider injectFirst>
    <ThemeProvider theme={themes(customization)}>
      <CssBaseline />
      <Suspense fallback={<CustomLoading />}>
        <div className="App">
          <Routes>
            <Route path='/' element={<Home />}>
              <Route index element={<Main />} />
              <Route path="motor/*" element={<MotorRoutes />} />
              <Route path="health/*" element={<HealthRoutes />} />
              <Route path="travel/*" element={<TravelRoutes />} />
              <Route path="loan/*" element={<LoanRoutes />} />
            </Route>
            <Route path='customer/*' element={<Customer />} />
            <Route path='posp/*' element={<Posp />} />
            <Route path='employee/*' element={<Admin />} />
          </Routes>
        </div>
      </Suspense>
     </ThemeProvider>
     </StyledEngineProvider>
  );
}

export default App;

function Home() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  return (
    <>
      <NavbarMain toggleDrawer={toggleDrawer} />
      <SideDrawer open={open} toggleDrawer={toggleDrawer} />
      <Outlet />
      <Box sx={{ bgcolor: "black", color: 'white', position: "relative" }}>
        <Footer />
      </Box>
    </>
  )
}


function Customer() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.data && state.auth.data.token !== '');
 
  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    const phone = localStorage.getItem('phone');
    if (token && phone) {
      dispatch(setToken({ token: token, phone: phone }));
    }
    store.dispatch(fetchMasterData());
    // store.dispatch(fetchPincodeMasterData());
  }, []);
 
 
  return (
    <>
      <div>
        {
          !isAuthenticated ?
            <div>
              <Routes>
                <Route exact path={'/auth/user-signup'}
                  element={<UserSignup />}
                />
                <Route exact path={'/'}
                  element={<UserLogin />}
                />
              </Routes>
            </div>
            :
            <CustomerRoutes/>
        }
        <ErrorMessage />
        <SuccessMessage />
      </div>
    </>
  )
}

function Posp() {
  const isAuthenticated = useSelector((state) => state.auth.data && state.auth.data.token !== '');
  return (
    <>
      <div>
        {
          !isAuthenticated ?
            <div>
              <Routes>
                <Route path={'/'}
                  element={<PospLogin />}
                />
                <Route path={'/posp-signup'}
                element={<SignUp />}
                />
              </Routes>
            </div>
            :
            <PospApp />
        }
        <ErrorMessage />
        <SuccessMessage />
      </div>
    </>
  )
}

function Admin() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.data && state.auth.data.token !== '');

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    const phone = localStorage.getItem('phone');
    if (token && phone) {
      dispatch(setToken({ token: token, phone: phone }));
    }
    // store.dispatch(fetchMasterData());
    // store.dispatch(fetchPincodeMasterData());
  }, []);
 
  return (
    <>
      <div>
        {
          !isAuthenticated ?
            <div>
              <Routes>
                <Route path={'/'}
                  element={<AdminLogin />}
                />
              </Routes>
            </div>
            :
            <AdminNavbar />
     
        }
        <ErrorMessage />
        <SuccessMessage />
      </div>
    </>
  )
}