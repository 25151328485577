import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
import { FileDownload, SmsFailed } from '@mui/icons-material';
import axios from 'axios';
import { travel_api } from '../../../Config';
import Box from '@mui/material/Box';

export const TravelPaymentSuccess = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [downloading,setDownloading] = useState(false)
  const [policyNum,setPolicyNumber] = useState("")


const proposalNum = useSelector((state) =>state.travelReducer.proposalResponse.intPolicyDataIO.policy.proposalNum);
console.log(proposalNum,"roposa")

  useEffect(() => {
    let reqBody 
      reqBody={
        proposalNum:proposalNum,
      }
    const fetchData = async () => {
      try {
          const response = await axios.post(`${travel_api}/insurance/v1/travel/policy/proposal/submitpayment`, reqBody);
          console.log(response,"response")
          setPaymentDetails(response.data?.data?.intGetPolicyStatusIO);
          setPolicyNumber(response?.data?.data?.intGetPolicyStatusIO?.policyNum)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); 

  

  const handleDownload = async () => {
    let  req={
        policynum:policyNum
      }
   
    try {
      setDownloading(true)
      const res = await axios.post(
        `${travel_api}/insurance/v1/travel/policy/pdfgenerate`,
        req
      );
      let base64String = res?.data?.intFaveoGetPolicyPDFIO?.dataPDF;

      let fileName = "lmv_customerId.pdf";
      const data = base64String.replace(/^data:application\/pdf;base64,/, "");
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = fileName;
      anchor.style.display = "none";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setDownloading(false)
      localStorage.clear()
    } catch (error) {
      setDownloading(false)
    }
  };


  const showKycStatus = true;

  if (! paymentDetails) {
    return (
      <Box sx={{ height: '700px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="payment-container">
      <div className="payment-success-table-container">
      <div className="payment-success-table">
          <div className="payment-success-table-header">
            Amount
            <span style={{ fontWeight: '500', fontSize: '18px' }}>₹ {paymentDetails?.policyPremium}</span>
          </div>
          <div className="payment-success-table-divider">
            <ul className="payment-success-list">
              <li>
                <span>Proposal Number</span>
                <span className="application-id">
                {proposalNum}
                </span>
              </li>
              <li>
                <span>Policy Number</span>
                <span>
                    {paymentDetails?.policyNum}
                </span>
              </li>
              <li>
                <span>Policy Number</span>
                <span>
                    {paymentDetails?.policyStatus}
                </span>
              </li>
              <li>
                <span>Merchant Name</span>
                <span>LMVIB</span>
              </li>
              <li>
                <span>Payment Method</span>
                <span>Online</span>
              </li>
              <li>
                <span>Policy Start Date</span>
                {paymentDetails?.policyMaturityDt}
              </li>
              <li>
                <span>Policy Provider</span>
                <span>Care</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="kyc-failed-container">
          <div className="kyc-failed-content">
            <p className="kyc-failed-content-title">Download Policy</p>
            <p>
              Download your <span style={{ color: '#23A8FA' }}>Policy</span> here.
            </p>
            <Button className="kyc-failed-btn" onClick={handleDownload}>
              <span style={{ fontWeight: '700', color: 'white' }}>{downloading ? "Downloading...":"Download"}</span>
            </Button>
          </div>
          <div style={{ marginLeft: '22px' }}>
            {showKycStatus ? <FileDownload /> : <SmsFailed />}
          </div>
        </div>
      </div>
    </div>
  );
};
