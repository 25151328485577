/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getProposalNumber } from "../../api/service.js";
import {
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  CircularProgress,
  Box,
  Typography,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { fieldObject } from "../../api/service.js";
import { useTranslation } from "react-i18next";
import { MedcalData } from "../../../Redux/Travel/action.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { sendOtp } from "../../../Framework/Add&SendOTP.js";
function MedicalDetails() {
  const { t } = useTranslation();
  const travelContent = t("travel");
  let [res, setRes] = useState(false);
  let [err, setErr] = useState({ state: false, message: "" });
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let formData = useSelector((e) => e.travelReducer.formData);
  let code = fieldObject(
    useSelector((e) => e.travelReducer.selectedPlan.plan),
    "field_2"
  ).selectedValueCode;
  let selectedPlan = useSelector((e) => e.travelReducer.selectedPlan);
  let addOn = selectedPlan.RVF_addOn;
  let numberOfMembers = formData?.travellerCount;
  let startDate = moment(formData?.startDate, "DD-MM-YYYY").format(
    "DD/MM/YYYY"
  );
  let endDate = moment(formData?.endDate, "DD-MM-YYYY").format("DD/MM/YYYY");
  let tripType = formData?.tripType === "Multi-trip" ? "MULTI" : "SINGLE";
  let tripDuration = formData?.tripDuration;
  let travellerGeographyId = selectedPlan?.travellgeographyID;
 
  const { register, handleSubmit } = useForm();
  const initialMemberState = {
    PEDliverDetailsTravel: {
      checked: "NO",
      description: travelContent.medical_details.liver_disease,
    },
    PEDcancerDetailsTravel: {
      checked: "NO",
      description: travelContent.medical_details.cancer_tumor,
    },
    PEDHealthDiseaseTravel: {
      checked: "NO",
      description: travelContent.medical_details.coronary_artery_heart_disease,
    },
    PEDkidneyDetailsTravel: {
      checked: "NO",
      description: travelContent.medical_details.kidney_disease,
    },
    PEDparalysisDetailsTravel: {
      checked: "NO",
      description: travelContent.medical_details.paralysis_stroke,
    },
    PEDotherDetailsTravel: {
      checked: "NO",
      description: travelContent.medical_details.other_medical_details,
    },
    HEDTravelClaimPolicy: {
      checked: "NO",
      description: travelContent.medical_details.claimed_under_travel_policy,
    },
    HEDTravelHospitalized: {
      checked: "NO",
      description:
        travelContent.medical_details.diagnosed_hospitalized_treatment,
    },
  };
 
  const [careTravelMedicalQuestions, setCareTravelMedicalQuestions] = useState(
    Array.from({ length: numberOfMembers }, () => ({ ...initialMemberState }))
  );
  const handleCheckboxChange = (memberIndex, questionKey) => {
    setCareTravelMedicalQuestions((prevQuestions) => {
      let newQuestions = [...prevQuestions];
      newQuestions[memberIndex][questionKey] = {
        ...newQuestions[memberIndex][questionKey],
        checked:
          newQuestions[memberIndex][questionKey]?.checked === "NO"
            ? "YES"
            : "NO",
      };
      return newQuestions;
    });
  };
 
  const selector = useSelector((e) => e.travelReducer.formData);
  useEffect(() => {
    console.log(selector, "selector");
  }, []);
 
  const formSubmit = async () => {
    selector?.MedcalData && handleProposalNumber(selector);
    setRes(true);
    setErr({ state: false, message: "" });
    const allMedicalData = careTravelMedicalQuestions.map(
      (memberQuestions, memberIndex) =>
        Object.keys(memberQuestions).reduce((data, questionKey) => {
          const keyWithTraveller = `Traveller${memberIndex + 1}${questionKey}`;
          data[keyWithTraveller] =
            memberQuestions[questionKey]?.checked || "NO";
          return data;
        }, {})
    );
    dispatch(MedcalData(allMedicalData));
    selector?.MedcalData && handleProposalNumber(selector);
  };
  const agent_id = useSelector((state) =>state?.posp?.data?.agent_id);
  const employee_id = (useSelector((state) => state?.adminReducer?.loginData?.empid));
  async function handleProposalNumber(selector) {
    let { proposalDetails, MemberDetails, MedcalData, NomineeDetails } =
      selector;
 
    console.log(startDate, "startDate");
 
    function addDays(dateString, days) {
      let parts = dateString.split("/");
      let day = parseInt(parts[0], 10);
      let month = parseInt(parts[1], 10) - 1;
      let year = parseInt(parts[2], 10);
      let date = new Date(year, month, day);
      date.setDate(date.getDate() + days);
      let newDay = ("0" + date.getDate()).slice(-2);
      let newMonth = ("0" + (date.getMonth() + 1)).slice(-2);
      let newYear = date.getFullYear();
      return `${newDay}/${newMonth}/${newYear}`;
    }
 
    endDate =
      selector.tripType === "Multi-trip" ? addDays(startDate, 364) : endDate;
 
     
      const travellerAges = selector;
   
     
 
    let proposalObject = {
      org_id: 100,
      agent_id:agent_id || 1000,
      employee_id:employee_id || null,
      selectedCountries:travellerAges?.selectedCountries,
      businessTypeCd: "NEWBUSINESS",
      baseProductId: travellerGeographyId,
      baseAgentId: "20008325",
      coverType: "INDIVIDUAL",
      proposalDetails: { ...proposalDetails },
      MemberDetails: MemberDetails,
      NomineeDetails: { ...NomineeDetails },
      MedcalData: MedcalData,
      startdate: startDate,
      enddate: endDate,
      tripTypeCd: tripType,
      travelGeographyCd: travellerGeographyId,
      sumInsured: code,
      maxtripduration: tripDuration,
      addOns: `LTCPED4049,PA4046,PL4047${addOn === "1" ? ",VISAFEE4055" : ""}`,
      isPremiumCalculation: "YES",
      visitPurposeCd: "BT",
    };
 
    console.log(selector, "proposalDetails");
 
    let remainingCustomerData = {
      org_id: 100,
      agent_id: 1000,
      customer_name:
        proposalDetails?.firstName + " " + proposalDetails?.lastName,
      phone: Number(proposalDetails?.AddressDetails?.phoneNumber),
      address:
        proposalDetails?.AddressDetails?.addressLine1 +
        " " +
        proposalDetails?.AddressDetails?.addressLine2,
      state: proposalDetails?.AddressDetails?.state,
      city: proposalDetails?.AddressDetails?.city,
      pincode: Number(proposalDetails?.AddressDetails?.pincode),
      email: proposalDetails?.AddressDetails?.email,
      dob: proposalDetails?.dob,
      gender: proposalDetails?.gender === "MALE" ? "M" : "F",
      marital_status:
        proposalDetails?.martialStatus === "UNMARRIED" ? "N" : "Y",
      document_type: "PAN",
      document_number: proposalDetails?.AddressDetails?.panNumber,
      nominee_name: NomineeDetails?.nomineeName,
      nominee_relation: NomineeDetails?.relationship,
      nominee_dob: NomineeDetails?.birthDate
    };
    sendOtp(remainingCustomerData, {}, true);
    try {
      let res = await getProposalNumber(proposalObject);
      console.log(res?.responseData, "response");
      if (res?.responseData?.message === "Success") {
        dispatch({ type: "HANDLE_PROPOSAL_RES", payload: res });
        navigate("/travel/carePayment");
      } else {
        setErr({
          state: true,
          message:
            res?.intPolicyDataIO?.errorLists[0]?.errDescription ||
            "Failed to get Status",
        });
      }
    } catch (error) {
      console.log({ error });
      setErr({ state: true, message: error.message || "Failed to get Status" });
    }
  }
 
  return (
    <Paper elevation={5} sx={{ borderRadius: "1em", padding: "1em" }}>
      <form onSubmit={handleSubmit(formSubmit)}>
        {careTravelMedicalQuestions.map((memberQuestions, memberIndex) => {
          return (
            <Accordion
              key={`accordion-${memberIndex}`}
              sx={{ marginTop: "1em" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ backgroundColor: "#23a8f830" }}
              >
                <Typography>{`${selector.MemberDetails[
                  memberIndex
                ].firstname.toUpperCase()} ${selector.MemberDetails[
                  memberIndex
                ].lastname.toUpperCase()}`}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ color: "#23a8f8" }}>
                <Table>
                  <TableBody>
                    {Object.keys(memberQuestions).map((questionKey) => (
                      <TableRow key={questionKey}>
                        <TableCell>
                          <Typography>{`${memberQuestions[questionKey]?.description}`}</Typography>
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            name={questionKey}
                            id={questionKey}
                            {...register(`MedicalDetails.${questionKey}`)}
                            checked={
                              memberQuestions[questionKey]?.checked === "YES"
                            }
                            onChange={() => {
                              handleCheckboxChange(memberIndex, questionKey);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          marginTop={"1em"}
        >
          {res && err.message === "" ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Button
              type="submit"
              variant="contained"
              sx={{
                marginTop: "1em",
                backgroundColor: "#23A8F8",
                color: "white",
                ":hover": {
                  backgroundColor: "lime",
                },
              }}
            >
              Submit Medical Details
            </Button>
          )}
        </Grid>
      </form>
      {err.state && (
        <Alert severity="error" sx={{ marginTop: "1em" }}>
          {err.message}
        </Alert>
      )}
    </Paper>
  );
}
export { MedicalDetails };