import React from "react";
import { Grid } from "@mui/material";
import EmployeePersonalDetails from "./EmployeePersonalDetails";
import EmployeePermissionsForm from "./EmployeePermissionsForm";
import PermissionsForm from "./PermissionsForm";
 
const NewUser = () => {
  const [stepCount, setStepCount] = React.useState(1);
  
 
  return (
    <Grid container>
      {stepCount === 1 && (
        <EmployeePersonalDetails setStepCount={setStepCount}  />
      )}
      {stepCount === 2 && (
        <EmployeePermissionsForm setStepCount={setStepCount}  />
      )}
      {stepCount === 3 && <PermissionsForm   />}
    </Grid>
  );
};
 
export default NewUser;