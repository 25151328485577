import axios from "axios";
import { health_api } from "../../Config";
 
const API_URL = `${health_api}/insurance/v1/health/policy/quote`;
// const API_URL = 'http://localhost:8083/insurance/v1/health/policy/quote'
const PRODUCT_URL = `${health_api}/insurance/v1/health/policy/product`
 
const generateTransactionId = () => {
  const staticTransactionId = "LMV00000";
  let dynamicSequence = Array.from({ length: 8 }, () =>
    Math.floor(Math.random() * 10)
  ).join("");
  let transactionId = staticTransactionId + dynamicSequence;
  console.log(transactionId, "transactionID");
  return transactionId;
};
let generatedHDFCTxnId = generateTransactionId();
 
export const generatePremium = async (commonPostData) => {
  let data = {...commonPostData,generatedHDFCTxnId:generatedHDFCTxnId}
  
  try {
    let res = await axios.post(API_URL, data);
    return res;
  } catch (error) {
    console.error(error);
  }
};
 
export const generatePremiumById = async (commonPostData) => {
  let data = {...commonPostData,generatedHDFCTxnId:generatedHDFCTxnId}
  console.log(data,'4454455')
  try {
    let response = await axios.post(PRODUCT_URL, data);
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};