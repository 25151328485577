import React, { useState } from "react";
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MainCard from "../../../posp/ui-component/cards/MainCard";

export const RecentPolicyCard = React.memo((props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  //   return <li>{JSON.stringify(props.policy)}</li>

  return (
    <Grid item xs={12} md={12}>
      <MainCard content={false}>
        <CardContent>
          <Grid
            container
            spacing={1}
            justifyContent="start"
            alignItems="center"
          >
            <Grid item xs={6} sm={2}>
              <CardMedia
                component="img"
                src={props?.logo}
                alt="Policy Image"
                sx={{ borderRadius: 4, width: 100 }}
              />
            </Grid>

            <Grid item xs={6} sm={2}>
              <Typography
                color="text.secondary"
                variant="h4"
                sx={{ marginBottom: "8px" }}
              >
                {t("lmv.customer.premium")}
              </Typography>
              <Typography variant="h5">
                ₹{props?.policy?.premium_with_gst} (Yearly)
              </Typography>
            </Grid>

            <Grid item xs={6} sm={2}>
              <Typography
                color="text.secondary"
                variant="h4"
                sx={{ marginBottom: "8px" }}
              >
                {props?.policy?.product_type !== "MOTOR"
                  ? "SUM INSURED"
                  : "IDV"}
              </Typography>

              <Typography variant="h5">{props.policy.sum_insured}</Typography>
            </Grid>

            <Grid item xs={6} sm={2}>
              <Typography
                color="text.secondary"
                variant="h4"
                sx={{ marginBottom: "8px" }}
              >
                {t("lmv.customer.planName")}
              </Typography>
              <Typography variant="h5">
                {props?.policy?.product_type}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={2}>
              <Typography
                color="text.secondary"
                variant="h4"
                sx={{ marginBottom: "8px" }}
              >
                {t("lmv.customer.endDate")}
              </Typography>
              <Typography variant="h5">
                {props?.policy?.policy_enddate}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={2}>
              <Typography
                color="text.secondary"
                variant="h4"
                sx={{ marginBottom: "8px" }}
              >
                {t("lmv.customer.status")}
              </Typography>
              <Typography variant="h5">
                {props?.policy?.policy_status}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box>
          <CardActions
            sx={{
              justifyContent: "space-between",
              padding: "4px",
              paddingX: "12px",
            }}
          >
            <Stack direction="row">
              <Button size="small" variant="text" onClick={handleExpandClick}>
                {expanded
                  ? t("lmv.customer.hideDetails")
                  : t("lmv.customer.seeDetails")}
              </Button>
            </Stack>

            <Stack direction="row">
              {props.policy.downloadUrl && (
                <Button
                  size="small"
                  variant="contained"
                  color="info"
                  href={props.policy.downloadUrl}
                >
                  Download Policy
                </Button>
              )}
              {/* <Button size="small" variant="contained" color="info"  href={'/'}>
                                {t('lmv.customer.downloadPolicy')}
                            </Button> */}
            </Stack>
          </CardActions>
          {/* <Divider /> */}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {/* Additional data to be shown when expanded */}
            <CardContent>
              <Grid
                container
                spacing={1}
                justifyContent="start"
                alignItems="center"
              >
                <Grid item xs={6} sm={2}>
                  <Typography color="text.secondary" variant="body1">
                    {t("lmv.customer.vehicleName")}
                  </Typography>
                  {props.policy.vehicle_name}
                  {/*<Typography variant="h5">{props.policy.vehicalName}</Typography>*/}
                </Grid>

                <Grid item xs={6} sm={2}>
                  <Typography color="text.secondary" variant="body1">
                    {t("lmv.customer.vehicleModal")}
                  </Typography>
                  {props.policy.vehicle_Model}
                  {/*<Typography variant="h5">{props.policy.vehicalModal}</Typography>*/}
                </Grid>

                <Grid item xs={6} sm={2}>
                  <Typography color="text.secondary" variant="body1">
                    {t("lmv.customer.ncb")}
                  </Typography>
                  {props.policy.ncbPercentage}
                  {/*<Typography variant="h5">{props.policy.NCB}</Typography>*/}
                </Grid>

                <Grid item xs={6} sm={2}>
                  <Typography color="text.secondary" variant="body1">
                    {t("lmv.customer.registrationNo")}
                  </Typography>
                  AJ2200MIII
                  {/*<Typography variant="h5">{props.policy.RegistrationNo}</Typography>*/}
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
        </Box>
        {/* </CardContent> */}
      </MainCard>
    </Grid>
  );
});
