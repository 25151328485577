import axios from "axios"
import { health_api } from "../../Config"

const URL = `${health_api}/insurance/v1/health/master/hospital`

export const fetchHospitalsList = async () =>{
    try {
        const response = await axios.get(URL)
        console.log(response.data,'5652532')
        return response.data
    } catch (error) {
        console.error("Error Fetching Hospitals:", error.message)
        return error
    }
}