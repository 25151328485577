import React from "react";
// import "./AgeDropdown.css";
import { ageOptions } from "../../api/service.js";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
export const AgeDropdown = ({ count, Controller, control, errors }) => {
  const { t } = useTranslation();
  const travelContent = t("travel");
  return (
    <Grid item xs={12} sm={6}>
      <Controller
        name={`travellerAge${count}`}
        control={control}
        rules={{
          required: travelContent.travelerDetails.select_error,
          validate: (value) =>
            value !== "" || travelContent.travelerDetails.select_error,
        }}
        render={({ field }) => (

          <Box sx={{ padding: "1em" }}>
            <FormControl fullWidth error={errors[`travellerAge${count}`]?.message ? true : false}>
              <InputLabel id="demo-simple-select-label">
                {travelContent.travelerDetails.select_label} {count+1}
              </InputLabel>
              <Select
                {...field}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={travelContent.travelerDetails.select_label}
                value={field.value || ''}
                onChange={field.onChange}
              >
                <MenuItem value="" disabled>
                  {travelContent.travelerDetails.select_option}
                </MenuItem>
                {Object.entries(ageOptions()).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText error>
            {errors[`travellerAge${count}`]?.message}
            </FormHelperText>
          </Box>
        )}
      />
    </Grid>
  );
};

