import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase } from '@mui/material';
import { MENU_OPEN } from '../../../../../Redux/crmustomization/actions';


const LogoSection = () => {
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const defaultId = useSelector((state) => state.customization.defaultId);
  const pospProfileData = useSelector((state) => state?.posp?.data);
  const dispatch = useDispatch();

  const dashboardNavigation = () => {
    switch (userType) {
      case "customer":
        navigate("/customer/dashboard");
        break;
      case "agent":
        if (pospProfileData) {
          if (pospProfileData?.status === 'Pass') {
            navigate("/posp/dashboard");
          } else {
            navigate("/posp/personaldetails");
          }
        }
        break;
      case "employee":
        navigate("/employee");
        break;
      default:
        localStorage.clear();
        navigate("/");
        break;
    }
  };

  return (
    <ButtonBase
      disableRipple
      onClick={() => {
        dispatch({ type: MENU_OPEN, id: defaultId });
        dashboardNavigation();
      }}
    >
      <img
        src='https://main.d315p1596xx1q3.amplifyapp.com/static/media/Logo.58fdf7e33453ed97e74a7ed2a1973e2a.svg'
        alt="lmv logo"
        style={{ width: "50%" }}
      />
    </ButtonBase>
  );
};

export default LogoSection;
