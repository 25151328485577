import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
 
 
 
const DarkTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow  classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: 'rgb(255 255 255)',
      boxShadow: theme.shadows[1],
      fontSize: 13,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
  }));
 
function DarkToolTip(props) {
  return  <DarkTooltip {...props} />;
}
 
export default DarkToolTip