import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import { selfInspectionPut } from "../../api/adminService";
import { setError as setErrorMessage } from "../../../../Redux/action-creators/errorAction";
import { setSuccessMessage } from "../../../../Redux/action-creators/successAction";
import { useDispatch } from "react-redux";
 
const TransactionEdit = ({
  selectedRow,
  setSelectedRow,
  openDialog,
  setOpenDialog,
  loading,
  setLoading,
  columns,
  setRows,
}) => {
  const { handleSubmit, control, reset } = useForm();
  const dispatch = useDispatch();
  const plans = [
    "APPROVED",
    "PENDING",
    "RECONCILIATION",
    "REJECTED",
    "CANCELED",
  ];
 
  // Reset form values whenever selectedRow changes
  useEffect(() => {
    if (selectedRow) {
      reset(selectedRow);  // Reset form with selectedRow data
    }
  }, [selectedRow, reset]);
 
  const onEditSubmit = async (data) => {
    if (!selectedRow) return;
    const formData = {
      ...data,
    };
 
    setLoading(true);
    try {
      const res = await selfInspectionPut(formData);
      if (res.status === 200) {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === selectedRow.id ? { ...row, ...formData } : row
          )
        );
        dispatch(setSuccessMessage("Policy Updated Successfully !"));
      }
    } catch (error) {
      dispatch(setErrorMessage(error));
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  };
 
  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedRow(null);
  };
 
  return (
    <Dialog open={openDialog} onClose={handleDialogClose}>
      <DialogTitle
        textAlign="center"
        sx={{
          fontWeight: "bold",
          fontSize: "20px",
          textDecoration: "underline",
        }}
      >
        Edit Policy
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onEditSubmit)}>
          <Grid container columnSpacing={3} mt={1} rowSpacing={2}>
            {columns.map((column) =>
              column.field !== "pdf_link" &&
              column.field !== "id" &&
              column.field !== "edit" &&
              selectedRow &&
              selectedRow[column.field] !== null &&
              selectedRow[column.field] !== undefined &&
              selectedRow[column.field] !== "" ? (
                <Grid item xs={6} key={column.field}>
                  <Controller
                    name={column.field}
                    control={control}
                    defaultValue={selectedRow[column.field] || ""}
                    render={({ field }) => {
                      if (column.field === "policy_status") {
                        return (
                          <TextField
                            {...field}
                            label={column.headerName}
                            fullWidth
                            select
                          >
                            {plans.map((plan) => (
                              <MenuItem key={plan} value={plan}>
                                {plan}
                              </MenuItem>
                            ))}
                          </TextField>
                        );
                      }
 
                      if (column.field.toLowerCase().includes("date")) {
                        return (
                          <TextField
                            {...field}
                            label={column.headerName}
                            fullWidth
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        );
                      }
 
                      return (
                        <TextField
                          {...field}
                          label={column.headerName}
                          fullWidth
                          disabled={
                            column.field === "customer_id" ||
                            column.field === "customer_name" ||
                            column.field === "order_id"
                          }
                        />
                      );
                    }}
                  />
                </Grid>
              ) : null
            )}
          </Grid>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Button
              onClick={handleDialogClose}
              color="primary"
              variant="contained"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={loading}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
 
export default TransactionEdit;