import React, { useEffect, useState } from 'react';
import { fetchHospitalsList } from '../../api/Hospital';
import { Box, Button, Card, CardContent, Container, Grid, IconButton, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LoaderComponent from '../../../Framework/Components/Loader';

const HospitalList = () => {
  const [hospitalList, setHospitalList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchHospitallist = async () => {
    setLoading(true);
    try {
      const list = await fetchHospitalsList();
      console.log(list,'4575896895')
      if (searchInput === '') {
        setHospitalList(list.slice(0, 15));
      } else {
        const filteredList = list.filter((hospital) => {
          return hospital['Pin code'] == searchInput || hospital['Hospital Name']?.toLowerCase().includes(searchInput?.toLowerCase()) || hospital['City'].toLowerCase().includes(searchInput.toLowerCase());
        });
        setHospitalList(filteredList);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHospitallist();
  }, [searchInput]);

  console.log(hospitalList, 'hospital List');

  return (
    <Container maxWidth="lg">
      <Card
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          padding: '16px',
          flexDirection: 'column',
          borderRadius: '12px',
          backgroundColor: '#f7faff',
          marginBottom: '8px',
          boxShadow: 'none'
        }}
      >
        <CardContent style={{ paddingTop: '0px', fontWeight: 'bold' }}>
          267 Cashless hospitals in <span style={{ color: 'green' }}>Hyderabad</span>
        </CardContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <TextField
            id="name"
            label="Search using Pin,Location,Name"
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            variant="outlined"
            margin="normal"
            fullWidth
            required
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              )
            }}
          />
        </div>
        <Typography style={{ fontStyle: 'inherit' }}>22,900 hospitals in India</Typography>
      </Card>
      <div className="more-hospitals-wrapper">
        <Typography variant="h5" style={{ fontSize: '20px', fontWeight: 700, color: '#253858', marginBottom: '11px' }}>
          More Hospitals
        </Typography>
        {loading ? (
          <LoaderComponent /> // You can replace this with a loader component if needed
        ) : hospitalList && hospitalList.length > 0 ? (
          <Grid container spacing={2}>
            {hospitalList.map((hospital, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <div style={{ borderBottom: '1px solid #dfe1e6', marginTop: '16px', paddingTop: '16px', color: '#6b778c', fontSize: '14px' }}>
                  <span style={{ color: '#253858', fontSize: '19px', fontWeight: 600 }}>{hospital['Hospital Name']}</span>
                  <p>
                    {hospital['ADDRESS 1']}, {hospital['City']}, {hospital['STATE']}
                  </p>
                  {/* <p>Contact: {hospital["CONTACT NO."]}</p> */}
                </div>
              </Grid>
            ))}
          </Grid>
        ) : (
          <p>No hospitals found</p>
        )}
      </div>
    </Container>
  );
};

export default HospitalList;