import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { fetchRenewals } from "../api/adminService";
import { handleRenewals } from "../../../Redux/admin/action";
import { Button, Chip, Divider, Grid, Typography } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";

const dateFormat = (date) => {
  const dateObj = new Date(date);
  const [y, m, d] = dateObj.toISOString().split("T")[0].split("-");
  return `${d}-${m}-${y}`;
};

export default function RenewPanel() {
  const renewData = useSelector((state) => state.adminReducer.Renewals);
  const dispatch = useDispatch();

  const rows = renewData;

  const getRenewalData = async () => {
    try {
      let response = await fetchRenewals();
      const addID = (data) => data.map((row, id) => ({ ...row, id }));
      dispatch(handleRenewals(addID(response.data)));
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getRenewalData();
  }, [dispatch]);

  const renderStatusChip = (status) => {
    const statusColors = {
      PENDING: "warning",
      RENEWED: "success",
      Lost: "error",
    };

    return (
      <Chip
        label={status}
        color={statusColors[status] || "default"}
        sx={{ mt: 1,px:2 }} 
      />
    );
  };

  const columns = [
    { field: "org_id", headerName: "Organization ID", width: 150 },
    { field: "agent_id", headerName: "Agent ID", width: 150 },
    { field: "customer_id", headerName: "Customer ID", width: 150 },
    {
      field: "product_type",
      headerName: "Product Type",
      width: 150,
      valueGetter: (params) =>
        params === "TVL"
          ? "TRAVEL"
          : params === "HLT"
          ? "HEALTH"
          : params === "LON"
          ? "LOAN"
          : params === "MOT"
          ? "MOTOR"
          : null,
    },
    { field: "rc_number", headerName: "RC Number", width: 150 },
    { field: "policy_type", headerName: "Policy Type", width: 150 },
    { field: "policy_sub_type", headerName: "Policy Sub Type", width: 150 },
    { field: "provider", headerName: "Provider", width: 150 },
    { field: "policy_number", headerName: "Policy Number", width: 200 },
    { field: "application_id", headerName: "Application ID", width: 150 },
    {
      field: "created_date",
      headerName: "Created Date",
      width: 150,
      valueGetter: (params) => dateFormat(params),
    },
    {
      field: "policy_startdate",
      headerName: "Policy Start Date",
      width: 150,
      valueGetter: (params) => dateFormat(params),
    },
    {
      field: "policy_enddate",
      headerName: "Policy End Date",
      width: 150,
      valueGetter: (params) => dateFormat(params),
    },
    {
      field: "policy_status",
      headerName: "Policy Status",
      width: 150,
      renderCell: (params) => renderStatusChip(params.value),
    },
  ];

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={9}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }} p={2}>
          Renewal Notification Panel
        </Typography>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{ display: "flex", justifyContent: "space-around" }}
      >
        <Button variant="contained" size="small" endIcon={<UploadIcon />}>
          CSV Upload
        </Button>
        <Button variant="contained" size="small" endIcon={<DownloadIcon />}>
          CSV Download
        </Button>
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid item xs={12}>
        <Box
          sx={{
            height: "calc(100vh - 180px)",
            width: "100%",
            "& .actions": { color: "text.secondary" },
            "& .textPrimary": { color: "text.primary" },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 12,
                },
              },
            }}
            pageSizeOptions={[5, 10, 12]}
            disableRowSelectionOnClick
          />
        </Box>
      </Grid>
    </Grid>
  );
}
