/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Countries, mostVisitedCountries } from "../../api/service.js";
import moment from "moment";
import { AlertError } from "../../../Framework/Toaster/AlertError";
import OtpModal from "../../../Framework/Components/OtpModal";
import NextButton from "../../../Framework/Components/NextButton";
import { useTranslation } from "react-i18next";
import { styles } from "./tripdetail.js";
import {
  Box,
  Grid,
  Typography,
  FormHelperText,
  TextField,
  Item,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { sendOtp } from "../../../Framework/Add&SendOTP";
import { useSelector } from "react-redux";
function planType(selectedContinents) {
  if (
    selectedContinents.length === 1 &&
    selectedContinents[0].name === "Canada"
  ) {
    return ["Canada"];
  } else if (
    selectedContinents.every(({ continent }) => continent === "Asia")
  ) {
    return ["Asia"];
  } else if (
    selectedContinents.every(({ continent }) => continent === "Africa")
  ) {
    return ["Africa"];
  } else if (
    selectedContinents.every(({ continent }) => continent === "Europe")
  ) {
    return ["Europe"];
  } else if (selectedContinents.every(({ continent }) => continent === "ANZ")) {
    return ["ANZ"];
  } else if (
    selectedContinents.some(
      ({ continent, name }) =>
        continent === "NorthAmerica" && name === "United States Of America(USA)"
    ) ||
    selectedContinents.some(
      ({ continent, name }) => continent === "NorthAmerica" && name === "Canada"
    )
  ) {
    return ["Worldwide - Silver", "Worldwide - Gold", "Worldwide- Platinum"];
  } else {
    return [
      "WW excl US/CAN- Silver",
      "WW excl US/CAN- Gold",
      "WW excl US/CAN- Platinum",
    ];
  }
}

export const TripDetails = ({ setTripDetails }) => {
  const { t } = useTranslation();
  const travelContent = t("travel");
  let dispatch = useDispatch();
  let [user, setUser] = useState({
    proposerName: "",
    proposerMobileNo: "",
    startDate: new Date().toISOString().split("T")[0],
    endDate: "",
  });
  let [duration, setDuration] = useState(0); //TRIP DURATION
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [otpSentSuccess, setOtpSentSuccess] = useState(false);
  const [initialRender, setInitialRender] = useState(false);
  const [classs, setClasss] = useState(mostVisitedCountries());
  const [error, setError] = useState(travelContent.tripDetails.date_error1);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  const handleRemove = (selectedCountry) => {
    if (
      mostVisitedCountries().map((country) => country === selectedCountry.name)
    ) {
      const classes = classs;
      classes.map((country) => {
        if (country.country === selectedCountry.name) {
          country.select = false;
        }
      });
      setClasss(classes);
    }
    setSelectedCountries((prevSelectedCountries) =>
      prevSelectedCountries.filter(
        (country) => country.name !== selectedCountry.name
      )
    );
    setInputValue("");
  };

  useEffect(() => {
    if (user.endDate !== "") {
      const duration =
        1 +
        moment(user.endDate, "YYYY-MM-DD").diff(
          moment(user.startDate, "YYYY-MM-DD"),
          "days"
        );
      setDuration(duration);
    }
  }, [user.startDate, user.endDate]);

  //=========   COUNTRIES SELECT FUNCTIONALITY    ===========
  const [inputValue, setInputValue] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [matchedCountries, setMatchedCountries] = useState([]);
  const continentsData = Countries();

  const handleChange = (e) => {
    const inputText = e.target.value.toLowerCase();
    setInputValue(e.target.value);
    const filteredCountries = Object.keys(continentsData).reduce(
      (acc, continent) => {
        const countriesMatchingInput = continentsData[
          continent
        ].countries.filter(
          (country) =>
            country.toLowerCase().startsWith(inputText) &&
            !selectedCountries.some((selected) => selected.name === country)
        );
        return [
          ...acc,
          ...countriesMatchingInput.map((matchedCountry) => ({
            name: matchedCountry,
            continent,
          })),
        ];
      },
      []
    );

    setMatchedCountries(filteredCountries);
  };

  const handleSelect = (country, continent, index) => {
    if (
      selectedCountries.length < 50 &&
      !selectedCountries.some((selected) => selected.name === country)
    ) {
      setSelectedCountries((prevSelectedCountries) => [
        ...prevSelectedCountries,
        { name: country, continent },
      ]);
      setMatchedCountries((prevMatchedCountries) =>
        prevMatchedCountries.filter(
          (matchedCountry) => matchedCountry.name !== country
        )
      );
      setInputValue("");
      try {
        const classes = classs;
        classes[index].select = true;
        setClasss(classes);
      } catch (err) { }
    }
  };

  const employee_id = (useSelector((state) => state?.adminReducer?.loginData?.empid));


  const agent_id = useSelector((state) => state?.posp?.data?.agent_id);

  // FORM SUBMIT FUNCTIONALITY
  async function handleFormSubmit(e) {
    e.preventDefault();
    let dateDuration = duration > 0 && duration <= 365;
    if (
      selectedCountries.length !== 0 &&
      user.startDate !== "" &&
      user.endDate !== "" &&
      user.proposerName !== "" &&
      user.proposerMobileNo !== "" &&
      isPhoneNumberValid &&
      dateDuration
    ) {
      dispatch({
        type: "HANDLE_FORM_DATA",
        payload: {
          ...user,
          selectedCountries: selectedCountries,
          startDate: moment(user.startDate).format("DD-MM-YYYY"),
          endDate: moment(user.endDate).format("DD-MM-YYYY"),
          tripDuration: duration,
          planType: planType(selectedCountries),
          sumInsured: "",
          tripType: "Single",
        },
      });
      setShowOTPModal(true);

      // CUSTOMER_CREATION
      const addCustomerData = {
        customer_name: user.proposerName,
        phone: Number(user.proposerMobileNo),
        org_id: 100,
        agent_id: agent_id || 1000,
        employee_id: employee_id,
        method: "SEND",
        type: "C",
      };

      // SMS_SERVICE
      const otpData = {
        phone: Number(user.proposerMobileNo),
        org_id: "100",
        agent_id: agent_id || "1000",
        employee_id: employee_id,
        method: "SEND",
        type: "C",
      };
      let otpResponse = await sendOtp(addCustomerData, otpData);
      setOtpSentSuccess(true);
    }
  }

  // TOGGLING OTP MODAL
  const handleModal = () => {
    setShowOTPModal((prev) => !prev);
  };

  const handleDate = (event) => {
    const inputDate = new Date(event.target.value);
    const today = new Date();

    if (inputDate < today) {
      setError(error);
      setUser((prev) => {
        return { ...prev, startDate: "" };
      });
    } else {
      setError("");
    }

    setUser({ ...user, startDate: event.target.value });
  };

  return (
    <>
      <form noValidate onSubmit={(e) => handleFormSubmit(e)}>
        {/* NAME AND MOBILE NUMBER */}

        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box sx={styles.typography}>
              <TextField
                error={initialRender && user.proposerName === "" ? true : false}
                fullWidth
                sx={styles.capitalizeTextField}
                required
                value={user.proposerName}
                type="text"
                id="outlined-basic"
                label={travelContent.tripDetails.name_label}
                variant="outlined"
                placeholder={travelContent.tripDetails.name_label}
                onChange={(e) => {
                  const regex = /^[a-zA-Z\s]*$/;
                  const value = e.target.value;
                  if (regex.test(value) && value.length <= 60) {
                    setUser({
                      ...user,
                      proposerName:
                        value.charAt(0).toUpperCase() + value.slice(1),
                    });
                  }
                }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if (
                    (charCode < 65 || charCode > 90) &&
                    (charCode < 97 || charCode > 122) &&
                    charCode !== 32
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <FormHelperText error sx={styles.paddingLeft}>
                {" "}
                {initialRender && user.proposerName === ""
                  ? travelContent.tripDetails.name_error
                  : ""}
              </FormHelperText>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={styles.typography}>
              <TextField
                error={initialRender && !isPhoneNumberValid ? true : false}
                fullWidth
                value={user.proposerMobileNo}
                required
                id="outlined-basic"
                label={travelContent.tripDetails.mobile_label}
                variant="outlined"
                type="number"
                placeholder={travelContent.tripDetails.mobile_label}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  const regex = /^[6-9][0-9]{0,9}$/;

                  if (regex.test(value) && value.length <= 10) {
                    setUser((user) => ({
                      ...user,
                      proposerMobileNo: value,
                    }));
                    setIsPhoneNumberValid(value.length === 10);
                  } else if (value.length === 0) {
                    setUser((user) => ({
                      ...user,
                      proposerMobileNo: "",
                    }));
                    setIsPhoneNumberValid(false);
                  }
                }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if (charCode < 48 || charCode > 57) {
                    e.preventDefault();
                  }
                }}
              />
              <FormHelperText error sx={styles.paddingLeft}>
                {" "}
                {initialRender && !isPhoneNumberValid
                  ? travelContent.tripDetails.mobile_error
                  : ""}
              </FormHelperText>
            </Box>
          </Grid>
        </Grid>

        {/* COUNTRIES SEARCH BAR AND DROPDOWN */}
        <Grid container sx={styles.marginBottom}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              paddingLeft="1em"
              fontSize={{ xs: "16px" }}
            >
              {travelContent.tripDetails.country_label}
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} padding={{ xs: "0.5em" }}>
              <Grid container alignItems={"center"} sx={styles.containerBorder}>
                {selectedCountries.length > 0 && (
                  <Grid item sx={styles.flexBoxStyles}>
                    {selectedCountries.map((selectedCountry, index) => (
                      <Chip
                        key={index}
                        label={selectedCountry.name}
                        onDelete={() => handleRemove(selectedCountry)}
                        sx={styles.chip}
                      />
                    ))}
                  </Grid>
                )}
                <Grid item xs={12} sx={styles.flexContainerStyles}>
                  <TextField
                    fullWidth
                    placeholder={travelContent.tripDetails.search_label}
                    name="countries"
                    onChange={(e) => handleChange(e)}
                    disabled={selectedCountries.length === 50}
                    value={inputValue}
                    InputProps={{ endAdornment: <SearchIcon /> }}
                    sx={styles.outlinedInput}
                  />
                </Grid>
                {inputValue && (
                  <Grid item xs={12}>
                    <Box position={"relative"}>
                      <Box sx={styles.scrollableBox}>
                        {matchedCountries.map((country, index) => (
                          <Box
                            sx={styles.countryOption}
                            key={index}
                            onClick={() =>
                              handleSelect(country.name, country.continent)
                            }
                          >
                            <Box sx={styles.columnFlexBox}>
                              <Typography component={"span"}>
                                {country.name}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <FormHelperText error sx={styles.errorText}>
          {selectedCountries.length === 0 && initialRender
            ? travelContent.tripDetails.country_error
            : ""}
        </FormHelperText>
        {/* most visited countries */}
        <Grid
          container
          columnSpacing={{ xs: 0, sm: 2 }}
          rowSpacing={1}
          margin={"auto"}
          paddingLeft={"0.5em"}
        >
          <Grid item xs={12}>
            {" "}
            <Typography component={"h5"} paddingLeft={"1em"}>
              {travelContent.tripDetails.popular_destination}
            </Typography>
          </Grid>
          {mostVisitedCountries().map((country, index) => (
            <Grid key={index} item xs="auto">
              <Typography
                component={"p"}
                sx={
                  mostVisitedCountries()[index].select
                    ? styles.selectedChipStyles
                    : styles.chipStyles
                }
                onClick={() => {
                  handleSelect(country.country, country.continent, index);
                }}
              >
                {country.country}
              </Typography>
            </Grid>
          ))}
        </Grid>
        {/* START DATE AND END DATE */}

        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box sx={styles.typography}>
              <TextField
                error={initialRender && user.startDate === "" ? true : false}
                fullWidth
                required
                value={user.startDate}
                InputLabelProps={{
                  shrink: true,
                }}
                name="Start Date"
                InputProps={{
                  inputProps: {
                    min: new Date().toISOString().split("T")[0],
                    max: new Date(
                      new Date().getTime() + 365 * 24 * 60 * 60 * 1000
                    )
                      .toISOString()
                      .split("T")[0],
                  },
                }}
                type="date"
                onChange={(e) => handleDate(e)}
                label={travelContent.tripDetails.st_date}
                variant="outlined"
              />

              <FormHelperText error sx={styles.paddingLeft}>
                {initialRender && user.startDate === ""
                  ? travelContent.tripDetails.date_error1
                  : ""}
                {user.startDate !== ""
                  ? user.startDate >= new Date().toISOString().split("T")[0] &&
                    user.startDate <=
                    new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000)
                      .toISOString()
                      .split("T")[0]
                    ? ""
                    : travelContent.tripDetails.date_error1
                  : ""}
              </FormHelperText>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={styles.typography}>
              <TextField
                error={
                  user.endDate < user.startDate
                    ? true
                    : duration > 365
                      ? true
                      : false
                }
                fullWidth
                name="endDate"
                label={travelContent.tripDetails.end_date}
                variant="outlined"
                required
                value={user.endDate}
                type="date"
                InputProps={{
                  inputProps: {
                    min: user.startDate,
                    max: new Date(
                      new Date().getTime() + 364 * 24 * 60 * 60 * 1000
                    )
                      .toISOString()
                      .split("T")[0],
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={user.startDate === ""}
                onChange={(e) => {
                  setUser({ ...user, endDate: e.target.value });
                }}
              />
              <FormHelperText error sx={styles.paddingLeft}>
                {user.endDate < user.startDate
                  ? travelContent.tripDetails.date_error2
                  : duration > 365
                    ? travelContent.tripDetails.endDate
                    : ""}
              </FormHelperText>
            </Box>
          </Grid>

          {user.endDate !== "" ? (
            <Box sx={styles.centerText}>
              <Typography sx={styles.boldText}>
                {travelContent.tripDetails.duration} :{" "}
                <span>
                  {duration} {travelContent.tripDetails.days}
                </span>
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Grid>

        {/* BACK AND NEXT BUTTONS */}
        <Grid sx={styles.spaceBetween}>
          <NextButton
            direction="back"
            type="button"
            onClick={() => setTripDetails(false)}
          />
          <NextButton onClick={() => setInitialRender(true)} submit />
        </Grid>
      </form>
      <OtpModal
        open={showOTPModal}
        handleClose={handleModal}
        number={user.proposerMobileNo}
        typed={"travel"}
      />
      <AlertError
        open={otpSentSuccess}
        setOpen={setOtpSentSuccess}
        message="OTP sent successfully"
        severity="success"
      ></AlertError>
    </>
  );
};