import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CarDetialsPage from '../Pages/Car Pages/CarDetailsPage';
import CarQuotation from '../Pages/Car Pages/CarQuotation';
import CarProposal from '../Pages/Car Pages/CarProposal';
import PaymentSuccess from '../Pages/Payment Pages/PaymentSuccess';
import PaymentFailed from '../Pages/Payment Pages/PaymentFailed';
import CarRegNumberFetching from '../Pages/Car Pages/CarRegNumberFetching';
import BikeRegNumberFetching from '../Pages/Bike Pages/BikeRegNumberFetching';
import BikeDetialsPage from '../Pages/Bike Pages/BikeDetailsPage';
import CommercialVehicleRegNumberFetching from '../Pages/Commercial Vehicle Pages/CommercialVehicleRegNumberFetching';
import CommercialVehicleDetailsPage from '../Pages/Commercial Vehicle Pages/CommercialVehicleDetailsPage';
import CommercialVehicleQuotation from '../Pages/Commercial Vehicle Pages/CommercialVehicleQuotation';
import CommercialVehicleProposalPage from '../Pages/Commercial Vehicle Pages/CommercialVehicleProposalPage';
import BikeQuotations from '../Pages/Bike Pages/BikeQuotations'
import BikeProposal from '../Pages/Bike Pages/BikeProposal'
import HdfcKYCPage from '../Pages/HdfcKYCPage';
// import AfterPayment from '../../Pages/Motor/Payment Pages/afterPayment';

const MotorRoutes = () => {
  return (
 <Routes>   
    <Route exact path="/car" element={<CarRegNumberFetching />}></Route>
    <Route exact path="/car/details" element={<CarDetialsPage />}></Route>
    <Route exact path="/car/quotation" element={<CarQuotation />}></Route>
    <Route exact path="/car/proposal" element={<CarProposal />}></Route>

    <Route exact path="/bike" element={<BikeRegNumberFetching />}></Route>
    <Route exact path="/bike/details" element={<BikeDetialsPage />}></Route>
    <Route exact path="/bike/quotation" element={<BikeQuotations />}></Route>
    <Route exact path="/bike/quotation" element={<BikeQuotations />}></Route>
    <Route exact path="/bike/proposal" element={<BikeProposal />}></Route>

    <Route exact path="/commercial" element={<CommercialVehicleRegNumberFetching />}></Route>
    <Route exact path="/commercial/details" element={<CommercialVehicleDetailsPage />}></Route>
    <Route exact path="/commercial/quotation" element={<CommercialVehicleQuotation />}></Route>
    <Route exact path="/commercial/proposal" element={<CommercialVehicleProposalPage />}></Route>

      <Route exact path="/car/payment/success" element={<PaymentSuccess />}></Route>
      <Route exact path="/car/payment/failed" element={<PaymentFailed />}></Route>
      <Route exact path="/v1/hdfc/kyc" element={<HdfcKYCPage />}></Route>

      {/* <Route exact path="/afterPayment" element={<AfterPayment />}></Route> */}


    </Routes>
  );
};

export default MotorRoutes;
