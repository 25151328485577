import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Button,
  TextField,
  Autocomplete,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import {
  getPermissions,
  getZones,
  getReportingManager,
} from "../../api/adminService";
import { useDispatch } from "react-redux";
import { handleNewEmployee } from "../../../../Redux/admin/action";
import { getBranchDetails } from "../../api/adminService";
import { setError as setErrorMessage } from "../../../../Redux/action-creators/errorAction"



const EmployeePermissionsForm = ({ setStepCount }) => {
  const [zones, setZones] = useState({});
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [departments, setDepartments] = useState({});
  const [roles, setRoles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [branchData, setBranches] = useState([]);
const dispatch = useDispatch();
  const fetchDepartmentsAndRoles = async () => {
    try {
      const response = await getPermissions();
      return response.data;
    } catch (error) {
      dispatch(setErrorMessage("Failed to fetch departments and roles:", error))
      return {};
    }
  };
  
  const fetchEmployees = async () => {
    try {
      const response = await getReportingManager();
      return response.data;
    } catch (error) {
      dispatch(setErrorMessage("Failed to fetch employees:", error))
      return [];
    }
  };

  const fetchZones = async () => {
    try {
      const response = await getZones();
      setZones(response.data);
    } catch (error) {
      dispatch(setErrorMessage("Failed to fetch zones:", error))
    }
  };

  const BranchDeatails = async () => {
    try {
      const response = await getBranchDetails();
      setBranches(response.data);
    } catch (error) {
      dispatch(setErrorMessage("Failed to fetch branches:", error))
    }
  };

  useEffect(() => {
    BranchDeatails();
    fetchZones();
    const loadDepartmentsAndRoles = async () => {
      const data = await fetchDepartmentsAndRoles();
      setDepartments(data);
    };
    loadDepartmentsAndRoles();

    const loadEmployees = async () => {
      const data = await fetchEmployees();
      setEmployees(data);
    };
    loadEmployees();
  }, []);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      zone: "",
      state: "",
      district: "",
      department: "",
      role: "",
      branch: "",
      ReportingManager: null,
      email: "",
    },
  });

  const zone = watch("zone");
  const state = watch("state");
  const department = watch("department");
  const Role = watch("role");

  useEffect(() => {
    if (zone) {
      setStates(Object.keys(zones[zone] || {}));
      setValue("state", "");
      setValue("district", "");
    } else {
      setStates([]);
      setDistricts([]);
      setValue("state", "");
      setValue("district", "");
    }
  }, [zone, zones, setValue]);

  useEffect(() => {
    if (zone && state) {
      setDistricts(zones[zone][state] || []);
      setValue("district", "");
    } else {
      setDistricts([]);
      setValue("district", "");
    }
  }, [state, zone, zones, setValue]);

  useEffect(() => {
    if (department) {
      setRoles(departments[department] || []);
      setValue("role", "");
    } else {
      setRoles([]);
      setValue("role", "");
    }
  }, [department, departments, setValue]);
  const onSubmit = (data) => {
    dispatch(handleNewEmployee({ form: "EmpoyeeDetails", data }));
    setStepCount(3);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">Employee Permissions</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl
                fullWidth
                margin="normal"
                error={Boolean(errors.zone)}
              >
                <InputLabel>Zone</InputLabel>
                <Controller
                  name="zone"
                  control={control}
                  rules={{ required: "Zone is required" }}
                  render={({ field }) => (
                    <Select {...field} label="Zone">
                      {Object.keys(zones).map((zone) => (
                        <MenuItem key={zone} value={zone}>
                          {zone}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.zone && (
                  <FormHelperText>{errors.zone.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl
                fullWidth
                margin="normal"
                error={Boolean(errors.state)}
                disabled={!zone}
              >
                <InputLabel>State</InputLabel>
                <Controller
                  name="state"
                  control={control}
                  rules={{ required: "State is required" }}
                  render={({ field }) => (
                    <Select {...field} label="State">
                      {states.map((st) => (
                        <MenuItem key={st} value={st}>
                          {st}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.state && (
                  <FormHelperText>{errors.state.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl
                fullWidth
                margin="normal"
                error={Boolean(errors.district)}
                disabled={!state}
              >
                <InputLabel>District</InputLabel>
                <Controller
                  name="district"
                  control={control}
                  rules={{ required: "District is required" }}
                  render={({ field }) => (
                    <Select {...field} label="District">
                      {districts.map((dist) => (
                        <MenuItem key={dist} value={dist}>
                          {dist}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.district && (
                  <FormHelperText>{errors.district.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl
                fullWidth
                margin="normal"
                error={Boolean(errors.branch)}
              >
                <InputLabel>Branch</InputLabel>
                <Controller
                  name="branch"
                  control={control}
                  rules={{ required: "Branch is required" }}
                  render={({ field }) => (
                    <Select {...field} label="Branch">
                      {branchData.map((branch) => (
                        <MenuItem key={branch.id} value={branch.branchname}>
                          {`${branch.branchname} (${branch.region})`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.branch && (
                  <FormHelperText>{errors.branch.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl
                fullWidth
                margin="normal"
                error={Boolean(errors.department)}
              >
                <InputLabel>Department</InputLabel>
                <Controller
                  name="department"
                  control={control}
                  rules={{ required: "Department is required" }}
                  render={({ field }) => (
                    <Select {...field} label="Department">
                      {Object.keys(departments).map((dept) => (
                        <MenuItem key={dept} value={dept}>
                          {dept}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.department && (
                  <FormHelperText>{errors.department.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl
                fullWidth
                margin="normal"
                error={Boolean(errors.role)}
                disabled={!department}
              >
                <InputLabel>Role</InputLabel>
                <Controller
                  name="role"
                  control={control}
                  rules={{ required: "Role is required" }}
                  render={({ field }) => (
                    <Select {...field} label="Role">
                      {roles.length > 0 ? (
                        roles.map((role) => (
                          <MenuItem key={role} value={role}>
                            {role}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" disabled>
                          No roles available
                        </MenuItem>
                      )}
                    </Select>
                  )}
                />
                {errors.role && (
                  <FormHelperText>{errors.role.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {Role !== "Telecaller" && (
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={Boolean(errors.email)}
                >
                  {/* <InputLabel>Email</InputLabel> */}
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@lmvinsurance\.com$/,
                        message:
                          "Email must be from the domain 'lmvinsurance.com'",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        type="email"
                        fullWidth
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message || ""}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}

            {Role !== "Admin" && (
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={Boolean(errors.ReportingManager)}
                >
                  <Controller
                    name="ReportingManager"
                    control={control}
                    rules={{ required: "An employee must be selected" }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Autocomplete
                        options={employees}
                        disableCloseOnSelect
                        getOptionLabel={(option) =>
                          `${option.emp_name} (${option.empid}) - ${option.role}`
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.empid === value?.empid
                        }
                        onChange={(event, newValue) => onChange(newValue)}
                        onBlur={onBlur}
                        value={value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Reporting Manager"
                            variant="outlined"
                            error={Boolean(errors.ReportingManager)}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option.empid}>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={option.empid === value?.empid}
                              disabled
                            />
                            {`${option.emp_name} (${option.empid}) - ${option.role}`}
                          </li>
                        )}
                        disableClearable
                      />
                    )}
                  />
                  {errors.ReportingManager && (
                    <FormHelperText>
                      {errors.ReportingManager.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Grid container justifyContent="center" mt={2}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default EmployeePermissionsForm;
