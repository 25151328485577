import axios from "axios";
import { loan_api } from "../../Config";

const proposalURL = loan_api+"/insurance/v1/policy/loan/proposal" ;
const getImageUrl = await fetch('https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/Loan/config.json');
const data = await getImageUrl.json();

// create proposal
const createProposalSecure = async (postdata) => {
  try {
    console.log(postdata, "frontend createProposal postbody ...");
    let res = await axios.post(proposalURL, postdata);
    console.log(res.data, "backend response ...");
    return res?.data;
  } catch (error) {
    console.log("error while calling", error);
  }
};



const getPincode = async () => {
  try {
    let res = await axios.get("https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/Loan/masterpincodedata.json");
    console.log(res.data,"hgdhg")
    return res;
  } catch (error) {
    console.log("error while calling getPincode", error);
  }
};

export { createProposalSecure,data,getPincode };
