import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPendingCases, postPendingCases } from "../api/adminService";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SendIcon from "@mui/icons-material/Send";
import { setError as setErrorMessage } from "../../../Redux/action-creators/errorAction"

const PendingCases = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const columns = [
    { field: "customer_id", headerName: "Customer ID", width: 120 },
    {
      field: "created_date",
      headerName: "Create Date",
      width: 120,
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      width: 150,
      renderCell: (params) => {
        return (
          <Link to={`profile/customer/${params.row.customer_id}`}>
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "phone",
      headerName: "Mobile Number",
      width: 120,
    },
    {
      field: "product_type",
      headerName: "Product Type",
      width: 90,
    },
    {
      field: "product_subtype",
      headerName: "Sub Type",
      width: 100,
    },
    { field: "provider", headerName: "Provider", width: 200 },
    {
      field: "policy_number",
      headerName: "Policy Number",
      width: 150,
    },
    {
      field: "policy_status",
      headerName: "Status",
      width: 135,
    },
    {
      field: "premium_with_gst",
      headerName: "Premium (incl. GST)",
      width: 150,
    },
    {
      field: "sum_insured",
      headerName: "Sum Insured",
      width: 120,
    },
    { field: "reporting_employee_id", headerName: "Employee ID", width: 120 },
    { field: "reporting_employee_name", headerName: "Employee Name", width: 180 },
    { field: "agent_id", headerName: "Agent ID", width: 120 },
    { field: "agent_name", headerName: "Agent Name", width: 180 },
    { field: "telecaller_id", headerName: "Telecaller ID", width: 120 },
    { field: "telecaller_name", headerName: "Telecaller Name", width: 180 },
  ];

  const empId = useSelector((e) => e?.adminReducer?.loginData?.empid);

  const getAllData = async () => {
    setLoading(true);
    try {
      let response = await getPendingCases(empId);
      setRows(AddId(response.data));
    } catch (error) {
      dispatch(setErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const AddId = (data) => {
    return data.map((row, id) => ({
      ...row,
      id,
    }));
  };

  useEffect(() => {
    getAllData();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const payload = { ...data, empid: empId };
    setLoading(true);
    try {
      const res = await postPendingCases(payload);
      const rowsWithId = AddId(res.data);
      setRows(rowsWithId);
    } catch (error) {
      dispatch(setErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const todate = watch("fromdate");

  const onReset = () => {
    reset();
    getAllData();
  };

  return (
    <>
      {loading ? (
        <CircularProgress
          size={50}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      ) : (
        <Grid
          container
          rowSpacing={2}
          mt={1}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Grid item xs={7}>
            <form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
              <Grid
                container
                display="flex"
                alignItems="center"
                columnSpacing={2}
              >
                <Grid item xs={4}>
                  {" "}
                  <TextField
                    {...register("fromdate", {
                      required: "From Date is required",
                    })}
                    type="date"
                    id="fromdate"
                    label="From Date"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: new Date().toISOString().split("T")[0] }}
                    error={!!errors.fromdate}
                    helperText={errors.fromdate ? errors.fromdate.message : ""}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  {" "}
                  <TextField
                    {...register("todate", {
                      required: "To Date is required",
                    })}
                    type="date"
                    id="todate"
                    label="To Date"
                    error={!!errors.todate}
                    helperText={errors.todate ? errors.todate.message : ""}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                      min: todate,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} justifyContent="flex-end">
                  {" "}
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SendIcon />}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  {" "}
                  <Button
                    type="reset"
                    variant="contained"
                    endIcon={<RestartAltIcon />}
                  >
                    Reset
                  </Button>
                </Grid>

                {/* Submit Button */}
              </Grid>
            </form>
          </Grid>
          {rows.length > 0 ? (
            <Grid item xs={12}>
              <DataGrid
                rows={rows}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
              />
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px",
              }}
            >
              <Typography variant="h3">No Data Found</Typography>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default PendingCases;
