import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../api/adminService";
import EditIcon from "@mui/icons-material/Edit";
import PermissionsDialog from "./NewUser/PermissionsDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate, useRoutes } from "react-router";
import NewUser from "./NewUser/NewUser";
import { setError as setErrorMessage } from "../../../Redux/action-creators/errorAction";
import axios from "axios";

const Home = () => {
  const selector = useSelector((state) => state.adminReducer.loginData.Role);
  const empId = useSelector((state) => state.adminReducer.loginData.empid);
  const pagePermissions = useSelector(
    (state) => state.adminReducer.loginData.pages
  );
  const permissions = pagePermissions.filter(
    (page) => page.pageName === "User Management"
  )[0].permissions;
  const errorMessage = useSelector((state) => state.error.message); // Get error message from Redux state
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [permissionsData, setPermissionsData] = useState({});
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState("Active");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePermissions = (data) => {
    if (permissions.edit) {
      setPermissionsData(data);
      setOpen(true);
    }
  };

  const columns = [
    { field: "empid", headerName: "Employee ID", flex: 1, minWidth: 100 },
    { field: "emp_name", headerName: "Employee Name", flex: 1, minWidth: 150 },
    { field: "phone", headerName: "Phone", flex: 1, minWidth: 120 },
    { field: "department", headerName: "Department", flex: 1, minWidth: 120 },
    { field: "role", headerName: "Role", flex: 1, minWidth: 100 },
    {
      field: "resportingmanager",
      headerName: "Reporting Manager",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        if (params.value) {
          return `${params.value.emp_name} (${params.value.empid})`;
        } else {
          return "N/A";
        }
      },
    },
    { field: "status", headerName: "Status", flex: 1, minWidth: 100 },
    {
      field: "pages",
      headerName: "Pages",
      renderCell: (params) => {
        return (
          <IconButton
            variant="contained"
            sx={{
              backgroundColor: "#fff",
              color: "#23a8fa",
              "&:hover": { backgroundColor: "#23a8fa", color: "#fff" },
            }}
            onClick={() => handlePermissions(params.row)}
          >
            <EditIcon />
          </IconButton>
        );
      },
      flex: 1,
      minWidth: 70,
    },
    { field: "email", headerName: "Email", flex: 1, minWidth: 150 },
    { field: "salary", headerName: "Salary", flex: 1, minWidth: 100 },
    { field: "zone", headerName: "Zone", flex: 1, minWidth: 100 },
    { field: "state", headerName: "State", flex: 1, minWidth: 100 },
    { field: "district", headerName: "District", flex: 1, minWidth: 100 },
    { field: "branch", headerName: "Branch", flex: 1, minWidth: 100 },
    {
      field: "joining_date",
      headerName: "Date Of Join",
      flex: 1,
      minWidth: 120,
    },
  ];

  const getEmployees = async () => {
    try {
      setLoading(true);
      const res = await getUsers(selector);
      const rowsWithId = AddId(res.data.data);
      setRows(rowsWithId);
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
    } finally {
      setLoading(false);
    }
  };

  const AddId = (data) => data.map((row, id) => ({ ...row, id }));

  useEffect(() => {
    getEmployees();
  }, [load]);

  const handleInActiveEmployees = async (e) => {
    const value = e.target.value;
    const payload = {
      empid: empId,
      status: value,
    };

    setLoading(true);
    try {
      const res = await axios.post(
        `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/userdata`,
        payload
      );
      setStatus(value);
      const rowsWithId = AddId(res.data.data);
      setRows(rowsWithId);
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <CircularProgress
          size={50}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      ) : (
        <Paper elevation={3} style={{ padding: "20px" }}>
          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          <Grid container p={2} rowSpacing={2}>
            <Grid item xs={6}>
              <Typography variant="h3">User Management</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              columnGap={3}
            >
              <TextField
                select
                size="small"
                onChange={(e) => handleInActiveEmployees(e)}
                value={status}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>

              {permissions.add && (
                <Button
                  sx={{ float: "right" }}
                  variant="contained"
                  endIcon={<AddCircleIcon />}
                  onClick={() => navigate("newuser")}
                >
                  New User
                </Button>
              )}
            </Grid>
          </Grid>
          {rows.length > 0 && (
            <DataGrid rows={rows} columns={columns} pageSize={5} />
          )}
        </Paper>
      )}
      {open && (
        <PermissionsDialog
          permissionsData={permissionsData}
          open={open}
          setOpen={setOpen}
          setLoad={setLoad}
        />
      )}
    </div>
  );
};

export default function UserIdManagement() {
  return useRoutes([
    { index: true, element: <Home /> },
    { path: "newuser", element: <NewUser /> },
  ]);
}
