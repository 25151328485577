import React from 'react'
import { TextField, MenuItem } from '@mui/material';
const CustomTextField = ({ name, label, field, errors, options, select,margin='dense',type='text',placeholder }) => {
  return (
    <TextField
      sx={{
        backgroundColor: 'white',
        '& input:-webkit-autofill': {
          transition: 'background-color 5000s ease-in-out 0s',
          backgroundColor: 'white !important',
        },
      }}
      {...field}
      label={label}
      select={select}
      variant="outlined"
      fullWidth
      placeholder={placeholder}
      type={type}
      margin={margin}
      value={field.value || ""}
      error={Boolean(errors[name])}
      helperText={errors[name] ? errors[name].message : ""}
    >
      {select && Object.entries(options).map(([k, v], _) => <MenuItem key={_} value={v}>{k}</MenuItem>)}
    </TextField>
  )
}

export default CustomTextField