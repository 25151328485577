import {
  Button,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
  FormHelperText,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Box,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import React, { useEffect, useState, memo } from "react";
import { useForm, Controller } from "react-hook-form";
import { DataGrid } from "@mui/x-data-grid";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EditIcon from "@mui/icons-material/Edit";
import {
  getOtherPayouts,
  PostHealthRevenueData,
  payoutsUpload,
} from "../api/adminService";
import { useDispatch, useSelector } from "react-redux";
import { handleOtherPayouts } from "../../../Redux/admin/action";
import UploadIcon from "@mui/icons-material/Upload";
import PolicyReports from "./RevenueTabs/PolicyReports";
import PospRevunue from "./RevenueTabs/PospRevenue";
import EmployeeRevenue from "./RevenueTabs/EmployeeRevenue";
import axios from "axios";
import AllFormsUpdate from "./RevenueUpdateForms/AllFormsUpdate";
import AllFormsAdd from "./RevenueUpdateForms/AllFormsAdd";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import { setSuccessMessage } from "../../../Redux/action-creators/successAction";
import { setError as setErrorMessage } from "../../../Redux/action-creators/errorAction"

const IC = () => {
  const pagePermissions = useSelector(
    (state) => state.adminReducer.loginData.pages
  );
  const permissions = pagePermissions.filter(
    (page) => page.pageName === "Revenue Management"
  )[0].permissions;
  const dispatch = useDispatch();
  const otherPayouts = useSelector((state) => state.adminReducer.Other_Payouts);
  const [loading, setLoading] = useState(false);
  const [otherRows, setOtherRows] = useState([]);
  const [healthRows, setHealthRows] = useState([]);
  const [motorRows, setMotorRows] = useState([]);
  const [motorColumns, setMotorColumns] = useState([]);
  const [productData, setProductData] = useState("");
  const [uploadData, setUploadData] = useState({});
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [addDialog, setAddDialog] = useState(false);

  const handleUpdate = (row) => {
    const monthNames = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };
    const monthNumber = monthNames[row.month];
    const formattedMonthYear = `${row.year}-${monthNumber}`;
    row["month_year"] = formattedMonthYear;
    delete row.month;
    delete row.year;
    setSelectedRow(row);
    setOpenDialog(true);
  };

  const handleAdd = () => {
    setAddDialog(true);
  };

  const getOtherPayoutsData = async () => {
    setLoading(true);
    try {
      const res = await getOtherPayouts();
      if (res.status === 200) {
        dispatch(handleOtherPayouts(mapDataWithId(res.data)));
        setOtherRows(otherPayouts);
        setProductData("");
      }
    } catch (error) {
      dispatch(setErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const mapDataWithId = (data) => data.map((row, id) => ({ ...row, id }));

  useEffect(() => {
    getOtherPayoutsData();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const { handleSubmit: handleSubmitForm2 } = useForm();
  const [tab, setTab] = useState(0);
  const ProductTypeFor = watch("productType");
  const MotorTypeFor = watch("motorType");
  const productSubTypeFor = watch("productSubtype");
  const monthYearFor = watch("monthYear");


  const handleChange = (event, newValue) => setTab(newValue);

  const productSubtypes = {
    motor: [
      { id: 1, name: "New Business" },
      { id: 2, name: "Renewal" },
      { id: 3, name: "Rollover" },
    ],
    health: [
      { id: 1, name: "New Business" },
      { id: 2, name: "Renewal" },
      { id: 3, name: "Portability" },
    ],
    life: [
      { id: 1, name: "New Business" },
      { id: 2, name: "Renewal" },
      { id: 3, name: "Portability" },
    ],
    others: [],
  };

  const motorTypes = [
    { id: 1, name: "Car" },
    { id: 2, name: "Bike" },
    { id: 3, name: "Commercial" },
  ];

  const otherColumns = [
    { field: "company_name", headerName: "Company Name", width: 150 },
    { field: "plan_name", headerName: "Plan Name", width: 150 },
    { field: "product_type", headerName: "Product Type", width: 150 },
    { field: "business_type", headerName: "Business Type", width: 150 },
    { field: "commission_rate", headerName: "Commission Rate", width: 150 },
    { field: "reward_rate", headerName: "Reward Rate", width: 150 },
    { field: "add_reward", headerName: "Add Reward", width: 150 },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => {
        return (
          <IconButton
            variant="contained"
            sx={{
              backgroundColor: "#fff",
              color: "#23a8fa",
              "&:hover": { backgroundColor: "#23a8fa", color: "#fff" },
            }}
            onClick={() => handleUpdate(params.row)}
          >
            <EditIcon />
          </IconButton>
        );
      },
      flex: 1,
      minWidth: 70,
    },
    { field: "month", headerName: "Month", width: 150 },
    { field: "year", headerName: "Year", width: 150 },
  ];

  let renewalColumns = [
    { field: "company_name", headerName: "Company Name", width: 150 },
    { field: "rto_category", headerName: "Rto Category", width: 150 },
    { field: "rto_cluster", headerName: "Rto Cluster", width: 150 },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => {
        return (
          <IconButton
            variant="contained"
            sx={{
              backgroundColor: "#fff",
              color: "#23a8fa",
              "&:hover": { backgroundColor: "#23a8fa", color: "#fff" },
            }}
            onClick={() => handleUpdate(params.row)}
          >
            <EditIcon />
          </IconButton>
        );
      },
      flex: 1,
      minWidth: 70,
    },
    { field: "bike_comp", headerName: "Bike Comp", width: 150 },
    { field: "bike_saod", headerName: "Bike Saod", width: 150 },
    { field: "bikeaotp", headerName: "Bike Aotp", width: 150 },
    { field: "scootercomp", headerName: "Scooter Comp", width: 150 },
    { field: "scootercompsaod", headerName: "Scooter Compsaod", width: 150 },
    { field: "scooteraotp", headerName: "Scooter Aotp", width: 150 },
    { field: "month", headerName: "Month", width: 150 },
    { field: "year", headerName: "Year", width: 150 },
  ];

  let newColumns = [
    { field: "company_name", headerName: "Company Name", width: 150 },
    { field: "rto_category", headerName: "Rto Category", width: 150 },
    { field: "rto_cluster", headerName: "Rto Cluster", width: 150 },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => {
        return (
          <IconButton
            variant="contained"
            sx={{
              backgroundColor: "#fff",
              color: "#23a8fa",
              "&:hover": { backgroundColor: "#23a8fa", color: "#fff" },
            }}
            onClick={() => handleUpdate(params.row)}
          >
            <EditIcon />
          </IconButton>
        );
      },
      flex: 1,
      minWidth: 70,
    },
    { field: "hmc_bike", headerName: "HMC Bike", width: 150 },
    { field: "hmc_scooter", headerName: "HMC Scooter", width: 150 },
    { field: "honda_bike", headerName: "Honda Bike", width: 150 },
    { field: "honda_scooter", headerName: "Honda Scooter", width: 150 },
    {
      field: "royal_enfield_bike",
      headerName: "Royal Enfield Bike",
      width: 150,
    },
    { field: "suzuki_bike", headerName: "Suzuki Bike", width: 150 },
    { field: "suzuki_scooter", headerName: "Suzuki Scooter", width: 150 },
    { field: "tvs_bike", headerName: "TVS Bike", width: 150 },
    { field: "tvs_scooter", headerName: "TVS Scooter", width: 150 },
    { field: "yamaha_bike", headerName: "Yamaha Bike", width: 150 },
    { field: "yamaha_scooter", headerName: "Yamaha Scooter", width: 150 },
    { field: "bike_ev_all_oem", headerName: "Bike EV All OEM", width: 150 },
    {
      field: "scooter_ev_all_oem",
      headerName: "Scooter EV All OEM",
      width: 150,
    },
    { field: "month", headerName: "Month", width: 150 },
    { field: "year", headerName: "Year", width: 150 },
  ];

  let carColumns = [
    { field: "company", headerName: "Company Name", width: 150 },
    { field: "rto_category", headerName: "Rto Category", width: 150 },
    { field: "rto_cluster", headerName: "Rto Cluster", width: 150 },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => {
        return (
          <IconButton
            variant="contained"
            sx={{
              backgroundColor: "#fff",
              color: "#23a8fa",
              "&:hover": { backgroundColor: "#23a8fa", color: "#fff" },
            }}
            onClick={() => handleUpdate(params.row)}
          >
            <EditIcon />
          </IconButton>
        );
      },
      flex: 1,
      minWidth: 70,
    },
    { field: "new_diesel_comp", headerName: "New Diesel Comp", width: 150 },
    { field: "new_petrol_comp", headerName: "New Petrol Comp", width: 150 },
    { field: "old_diesel_ncb", headerName: "Old Diesel NCB", width: 150 },
    {
      field: "old_diesel_zeroncb",
      headerName: "Old Diesel Zero NCB",
      width: 150,
    },
    { field: "old_petrol_ncb", headerName: "Old Petrol NCB", width: 150 },
    {
      field: "old_petrol_zeroncb",
      headerName: "Old Petrol Zero NCB",
      width: 150,
    },
    { field: "stp_1000_diesel", headerName: "STP 1000 Diesel", width: 150 },
    { field: "stp_1000_petrol", headerName: "STP 1000 Petrol", width: 150 },
    {
      field: "stp_1000_1500_diesel",
      headerName: "STP 1000-1500 Diesel",
      width: 150,
    },
    {
      field: "stp_1000_1500_petrol",
      headerName: "STP 1000-1500 Petrol",
      width: 150,
    },
    { field: "stp_1500_diesel", headerName: "STP 1500 Diesel", width: 150 },
    { field: "stp_1500_petrol", headerName: "STP 1500 Petrol", width: 150 },
    { field: "month", headerName: "Month", width: 150 },
    { field: "year", headerName: "Year", width: 150 },
  ];

  const handleFileUpload = (setter, errorSetter) => (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setter(selectedFile);
      errorSetter("");
    } else {
      errorSetter("Please upload a valid CSV file.");
      setter(null);
    }
  };

  const renderDataGrids = () => {
    switch (productData) {
      case "HEALTH":
        return (
          <DataGrid
            rowsPerPageOptions={[5]}
            rows={healthRows}
            columns={otherColumns}
          />
        );

      case "MOTOR":
        return (
          <DataGrid
            rowsPerPageOptions={[5]}
            rows={motorRows}
            columns={motorColumns}
          />
        );

      case "Others":
        return (
          <DataGrid
            rowsPerPageOptions={[5]}
            rows={otherRows}
            columns={otherColumns}
          />
        );

      default:
        return (
          <Box
            sx={{
              height: 300,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <DataGrid rows={[]} columns={[]} />
          </Box>
        );
    }
  };

  const MemoizedDataGrids = memo(renderDataGrids);

  const renderForm = () => {
    const productType = watch("productType");
    let subtypes = [];

    switch (productType) {
      case "motor":
        subtypes = productSubtypes.motor;
        break;
      case "health":
        subtypes = productSubtypes.health;
        break;
      case "life":
        subtypes = productSubtypes.life;
        break;
      case "others":
        subtypes = productSubtypes.others;
        break;
      default:
        break;
    }

    const ProductSubmit = async (data) => {
      setUploadData(data);
      setLoading(true);
      try {
        const handleResponse = async (productType) => {
          const res = await PostHealthRevenueData(data);
          if (res.status === 200) {
            if (productType === "motor") {
              if (MotorTypeFor === "Car") {
                setMotorRows(mapDataWithId(res.data));
                setMotorColumns(carColumns);
              } else if (MotorTypeFor === "Commercial") {
                setMotorRows(mapDataWithId(res.data));
              } else if (MotorTypeFor === "Bike") {
                if (data.productSubtype === "New Business") {
                  setMotorRows(mapDataWithId(res.data));
                  setMotorColumns(newColumns);
                } else if (data.productSubtype === "Renewal") {
                  setMotorRows(mapDataWithId(res.data));
                  setMotorColumns(renewalColumns);
                } else if (data.productSubtype === "Rollover") {
                  setMotorRows(mapDataWithId(res.data));
                }
              }
              setProductData("MOTOR");
            } else if (productType === "health") {
              setHealthRows(mapDataWithId(res.data));
              setProductData("HEALTH");
            } else if (productType === "others") {
              setOtherRows(mapDataWithId(res.data));
              setProductData("Others");
            }
          }
        };

        switch (data.productType) {
          case "motor":
          case "health":
          case "others":
            await handleResponse(data.productType);
            break;
          default:
            dispatch(setErrorMessage("Unsupported product type"));
            break;
        }
      } catch (error) {
        dispatch(setErrorMessage("Error submitting product:", error));
      } finally {
        setLoading(false);
      }
    };

    const handleSubmitForm = async () => {
      if (!file) {
        setError("Please upload a file before submitting.");
        dispatch(setErrorMessage("Please upload a file before submitting."));
        return;
      }

      setLoading(true);

      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("productType", uploadData.productType);
        formData.append("motorType", uploadData.motorType);
        formData.append("provider", uploadData.provider);
        formData.append("productSubtype", uploadData.productSubtype);

        const res = await payoutsUpload(formData);

        if (res.status === 200) {
          switch (uploadData.productType) {
            case "motor":
              // Handle motor type specific logic
              break;
            case "otherType":
              // Handle other type specific logic
              break;
            default:
             dispatch(setErrorMessage("Unknown product type"));
          }
        } else {
          setError("Upload failed. Please try again.");
          dispatch(setErrorMessage("Upload failed. Please try again."));
        }
      } catch (error) {
        dispatch(setErrorMessage("Upload failed :",error));
        setError(
          "An error occurred while uploading the file. Please try again."
        );
      } finally {
        setLoading(false);
        setError("");
      }
    };

    const handleSampleDownload = async () => {
      try {
        let fileUrl = "";
        let fileName = "";

        // Handle Motor Type
        switch (ProductTypeFor) {
          case "motor": {
            if (MotorTypeFor === "Commercial") {
              fileUrl = "";
              // "https://samplecsvfiles.s3.ap-south-1.amazonaws.com/commercial_motor_upload.csv";
              fileName = "commercial_motor_upload.csv";
            } else if (MotorTypeFor === "Bike") {
              if (productSubTypeFor === "New Business") {
                fileUrl =
                  "https://samplecsvfiles.s3.ap-south-1.amazonaws.com/motor_payouts_new.csv";
                fileName = "bike_new_business.csv";
              } else if (
                productSubTypeFor === "Renewal" ||
                productSubTypeFor === "Rollover"
              ) {
                fileUrl = "";
                // "https://samplecsvfiles.s3.ap-south-1.amazonaws.com/bike_renewal.csv";
                fileName = "bike_renewal.csv";
              }
            } else if (MotorTypeFor === "Car") {
              fileUrl =
                "https://samplecsvfiles.s3.ap-south-1.amazonaws.com/car_upload.csv";
              fileName = "car_upload.csv";
            }
            break;
          }

          case "health": {
            fileUrl =
              "https://samplecsvfiles.s3.ap-south-1.amazonaws.com/healthrevenuepayout.csv";
            fileName = "healthrevenuepayout.csv";
            break;
          }

          case "life": {
            fileUrl = "";
            // "https://samplecsvfiles.s3.ap-south-1.amazonaws.com/life_upload.csv";
            fileName = "life_upload.csv";
            break;
          }

          default: {
            fileUrl =
              "https://samplecsvfiles.s3.ap-south-1.amazonaws.com/others_payouts.csv";
            fileName = "others_payouts.csv";
            break;
          }
        }
        if (!fileUrl || !fileName) {
          dispatch(setErrorMessage("No file URL or name set for the download."));
          return;
        }
        const response = await axios.get(fileUrl, { responseType: "blob" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(response.data);
        link.download = fileName;
        link.click();
      } catch (error) {
        dispatch(setErrorMessage("Error downloading the sample file:", error));
      }
    };

    const isValidProductType =
      (ProductTypeFor === "motor" && MotorTypeFor) ||
      ProductTypeFor === "health" ||
      ProductTypeFor === "life" ||
      ProductTypeFor === "others";

    return (
      <>
        {loading ? (
          <CircularProgress
            size={50}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        ) : (
          <>
            <form onSubmit={handleSubmit(ProductSubmit)}>
              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth error={!!errors.productType}>
                    <InputLabel id="product-type-label">
                      Product Type
                    </InputLabel>
                    <Controller
                      name="productType"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Product type is required" }}
                      render={({ field }) => (
                        <Select {...field} label="Product Type">
                          <MenuItem value="" disabled>
                            Select Product
                          </MenuItem>
                          <MenuItem value="motor">MOTOR</MenuItem>
                          <MenuItem value="health">HEALTH</MenuItem>
                          <MenuItem value="life">LIFE</MenuItem>
                          <MenuItem value="others">OTHERS</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.productType && (
                      <FormHelperText>
                        {errors.productType.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {ProductTypeFor === "motor" && (
                  <Grid item xs={3}>
                    <Controller
                      name="motorType"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Motor type is required" }}
                      render={({ field }) => (
                        <FormControl fullWidth error={!!errors.motorType}>
                          <InputLabel id="motor-type-label">
                            Motor Type
                          </InputLabel>
                          <Select {...field} label="Motor Type">
                            <MenuItem value="" disabled>
                              Select Motor Type
                            </MenuItem>
                            {motorTypes.map((motorType) => (
                              <MenuItem
                                key={motorType.id}
                                value={motorType.name}
                              >
                                {motorType.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.motorType && (
                            <FormHelperText>
                              {errors.motorType.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={3}>
                  {subtypes.length > 0 && (
                    <Controller
                      name="productSubtype"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Product subtype is required" }}
                      render={({ field }) => (
                        <FormControl fullWidth error={!!errors.productSubtype}>
                          <InputLabel id="product-subtype-label">
                            Subtype
                          </InputLabel>
                          <Select {...field} label="Subtype">
                            <MenuItem value="" disabled>
                              Select Subtype
                            </MenuItem>
                            {subtypes.map((subtype) => (
                              <MenuItem key={subtype.id} value={subtype.name}>
                                {subtype.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.productSubtype && (
                            <FormHelperText>
                              {errors.productSubtype.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  )}
                </Grid>

                {productType && (
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="monthYear"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Month and Year are required" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Month and Year"
                          type="month"
                          fullWidth
                          error={!!errors.monthYear}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    {errors.monthYear && (
                      <FormHelperText error>
                        {errors.monthYear.message}
                      </FormHelperText>
                    )}
                  </Grid>
                )}

                {productType && (
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    marginTop={2}
                  >
                    <Button
                      type="submit"
                      endIcon={<SaveIcon />}
                      variant="contained"
                    >
                      SUBMIT
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
            <Grid
              container
              marginTop={2}
              rowSpacing={2}
              columnSpacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              {isValidProductType && (
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    startIcon={<CloudDownloadIcon />}
                    onClick={handleSampleDownload}
                  >
                    Sample Download
                  </Button>
                </Grid>
              )}

              {isValidProductType && (
                <Grid item xs={3}>
                  {permissions?.edit && (
                    <form onSubmit={handleSubmitForm2(handleSubmitForm)}>
                      <TextField
                        type="file"
                        onChange={handleFileUpload(setFile, setError)}
                        label="Upload File"
                        variant="outlined"
                        inputProps={{ accept: ".csv" }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton type="submit" aria-label="submit">
                                <UploadIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        error={!!error}
                        helperText={error}
                      />
                    </form>
                  )}
                </Grid>
              )}

              {(isValidProductType && monthYearFor) && (
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    endIcon={<AddToQueueIcon />}
                    onClick={() => handleAdd()}
                  >
                    New Record Add
                  </Button>
                </Grid>
              )}

              <Grid item xs={12}>
                <MemoizedDataGrids />
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="tabs example"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Company Revenue" />
          <Tab label="Posp's Revenue" />
          <Tab label="Employee's Revenue" />
          <Tab label="Policy Revenue Reports" />
        </Tabs>
        {tab === 0 && <>{renderForm()}</>}
        {tab === 1 && <PospRevunue />}
        {tab === 2 && <EmployeeRevenue />}
        {tab === 3 && <PolicyReports />}
      </Grid>
      <AllFormsUpdate
        open={openDialog}
        setOpen={setOpenDialog}
        rowData={selectedRow}
      />
      <AllFormsAdd
        addDialog={addDialog}
        setAddDialog={setAddDialog}
        productType={ProductTypeFor}
        motorType={MotorTypeFor}
        subProductType={productSubTypeFor}
      />
    </Grid>
  );
};

export default IC;
