import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Badge,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PhoneIcon from "@mui/icons-material/Phone";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import FamilyDetails from "../profile/Profilefamilydetails";
import Assets from "./assets";
import PersonalDetails from "../profile/Personaldetails";
import MainCard from "../../../posp/ui-component/cards/MainCard";
import AddExistingPolicy from "./ExistingPolicies";
import { updateUserProfileData } from "../../api/customerService";
import {
  clearSuccessMessage,
  setSuccessMessage,
} from "../../../../Redux/action-creators/successAction";
import {
  clearError,
  setError,
} from "../../../../Redux/action-creators/errorAction";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_CUSTOMER_SUCCESS } from "../../../../Redux/action-type/actionType";
// import { getProfileById } from "../../api/customerService";

const Mainpage = () => {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state?.customer?.data);
  console.log(profileData , 'profile data..')
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeTabs , setActiveTabs] = useState(0)
  const [personalDetails, setPersonalDetails] = useState({
    name: profileData?.customer_name,
    gender: profileData?.gender,
    mobile: profileData?.phone,
    email: profileData?.email,
    dateOfBirth: profileData?.dob,
    annualIncome: profileData?.annual_income,
    city: profileData?.city,
    marital: profileData?.marital_status,
  });
  const [familyDetails, setFamilyDetails] = useState(profileData?.family_data || []);
  const [assets, setAssets] = useState(profileData?.assets || []);
  const [existingPolicy , setExistingPolicy] = useState(profileData?.exit_policies || [])

  const [isSaveLoaderTrue , setSaveLoaderTrue] = useState(false)

  const handleTabChange = (event, newValue) => {
    if(newValue <= activeTabs){
       setSelectedTab(newValue);
    }
  };

  const NextBtn = (data) =>{
    if (selectedTab === 0) {
      setPersonalDetails(data);
    } else if (selectedTab === 1) {
      setFamilyDetails(data)
    }else{
      setAssets(data)
    }
    setSelectedTab((prv)=> prv+1)
    setActiveTabs((prv) => prv + 1);
  }

  const PreviousBtn = (data) =>{
    if(selectedTab === 1){
      setFamilyDetails(data);
    }else if (selectedTab === 2){
      setAssets(data);
    }else{
      setExistingPolicy(data)
    }
    setSelectedTab((prv) => prv - 1);
  }

  const saveBtn = async(data) =>{
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    setExistingPolicy(data)
    const reqObject = {
      ...personalDetails,
      familyDetails: JSON.stringify(familyDetails),
      assets: JSON.stringify(assets),
      existingPolicy: JSON.stringify(data),
    };

    console.log(reqObject , 'request')

    try{
      setSaveLoaderTrue(true)
      const response = await updateUserProfileData(personalDetails?.mobile , reqObject)
      console.log(response , 'response of')
      if(response.status === 200){
        dispatch(setSuccessMessage('Profile Details Updated Successfuly...'))
        dispatch({
          type: FETCH_CUSTOMER_SUCCESS,
          payload: response?.data,
        });
      }
      setSelectedTab(0)
     
    }catch(e){
      dispatch(setError('Internal Server Error'))
    }
    setSaveLoaderTrue(false)
  }

  const RenderComponent = () =>{
    switch (selectedTab) {
      case 0:
        return (
          <PersonalDetails
            personalDetails={personalDetails}
            NextBtn={NextBtn}
          />
        );
      case 1:
        return (
          <FamilyDetails
            familyDetails={familyDetails}
            NextBtn={NextBtn}
            PreviousBtn={PreviousBtn}
          />
        );
      case 2:
        return (
          <Assets assets={assets} NextBtn={NextBtn} PreviousBtn={PreviousBtn} />
        );
      default :
        return (
          <AddExistingPolicy
            existingPolicy={existingPolicy}
            PreviousBtn={PreviousBtn}
            saveBtn={saveBtn}
            isSaveLoaderTrue={isSaveLoaderTrue}
          />
        );
    }
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={12} marginTop={2} marginBottom={2}>
            <MainCard color="primary">
              <Typography variant="h4">Profile</Typography>
              <Typography variant="body1">
                Manage your family members, contact details and financial assets
              </Typography>
            </MainCard>
          </Grid>

          <Grid item xs={12} marginBottom={2}>
            <Tabs value={selectedTab} onChange={handleTabChange} centered>
              <Tab label="Personal Details" />
              <Tab label="Family Details" disabled={activeTabs < 1} />
              <Tab label="Assets" disabled={activeTabs < 2} />
              <Tab label="Add your existing policies" disabled={activeTabs < 3}/>
            </Tabs>
          </Grid>

          <Grid item xs={12}>
            <MainCard color="primary" content={false}>
              {RenderComponent()}
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Mainpage;
