import React from 'react';
import './Loader.css'
const LoaderComponent = () => {
  return (
    <section className="dots-container">
      <Dot delay={-0.3} />
      <Dot delay={-0.1} />
      <Dot delay={0.1} />
      <Dot />
      <Dot />
    </section>
  );
};

const Dot = ({ delay = 0 }) => {
  return <div className="dot" style={{ animationDelay: `${delay}s` }}></div>;
};

export default LoaderComponent;
