import React, { useCallback, useEffect, useState } from "react";
import hdfcLogo from "../Images/HDFCLOGO 1 copy.jpg";
import { Grid, IconButton, Slider, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { generatePremiumById } from "../api/PremiumGeneration";
import {
  getHDFCTxnID,
  updatePremiumPostObject,
  updatedQuotationResult,
} from "../../Redux/Health/action";
import CustomCheckbox from "../Components/CustomCheckbox";
import DarkToolTip from "../../Framework/Components/DarkToolTip";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

const HDFCProductDetail = () => {
  const [showSlider, setShowSlider] = useState(false);
  const [sliderValue, setSliderValue] = useState(100000);
  const [hospitalCash, setHospitalCash] = useState("0");
  const [premiumWithoutGST, setPremiumWithoutGST] = useState(Number);
  const [selectedUnlimitedRestore, setSelectedUnlimitedRestore] =
    useState(false);
  const [selectedPArider, setSelectedPArider] = useState(false);
  const [illnessChecked, setIllnessChecked] = useState(false);
  const [premiumWithGST, setPremiumWithGST] = useState(Number);
  const [loading, setLoading] = useState(false);
  const premiumPostBody = useSelector(
    (state) => state?.healthReducer?.premiumPostObject
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const familyData = useSelector((state) => state.healthReducer.familyData);
  const trueCount = Object.values(familyData).filter(
    (value) => value === true
  ).length;
  console.log(familyData, "familyData", trueCount);
  const fetchPremiumDetails = useCallback(async () => {
    setLoading(true);
    console.log(premiumPostBody, "post body in HDFC");
    try {
      const result = await generatePremiumById(premiumPostBody);
      console.log("result in HDFC product detail", result);
      setPremiumWithoutGST(
        Math.round(result?.data?.body?.Response_Data_OS?.NetPremium)
      );
      setPremiumWithGST(
        result?.data?.body?.Response_Data_OS?.TotalPremium
      );
      dispatch(updatedQuotationResult(result))
      console.log(result?.data?.body?.TransactionID,"767536")
      dispatch(getHDFCTxnID(result?.data?.body?.TransactionID))
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [premiumPostBody]);

  useEffect(() => {
    fetchPremiumDetails();
  }, [fetchPremiumDetails]);

  console.log(premiumWithoutGST, premiumWithGST);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleCheckboxChange = (event) => {
    setShowSlider(event.target.checked);
    let updatedPostBody = { ...premiumPostBody };
    if (event.target.checked) {
      setIllnessChecked(!illnessChecked);
      updatedPostBody.HDFC_criticalIllness = sliderValue;
    } else {
      setIllnessChecked(!illnessChecked);
      setSliderValue(100000);
      updatedPostBody.HDFC_criticalIllness = 0;
    }
    console.log(updatedPostBody, "qjncqncnq");
    dispatch(updatePremiumPostObject(updatedPostBody));
  };

  const handleSliderChange = debounce((event, newValue) => {
    let updatedPostBody = { ...premiumPostBody };
    if (showSlider) {
      setSliderValue(newValue);
      updatedPostBody.HDFC_criticalIllness = newValue;
    }
    console.log(updatedPostBody, "qjncqncnq");
    dispatch(updatePremiumPostObject(updatedPostBody));
  }, 20);
  console.log(sliderValue);

  const handleHospitalcash = (e) => {
    setHospitalCash(e.target.value);
    let updatedPostBody = { ...premiumPostBody };
    updatedPostBody.HDFC_hospitalAddons = e.target.value;
    dispatch(updatePremiumPostObject(updatedPostBody));
  };

  const handleUnlimitedRestore = (e) => {
    let updatedPostBody = { ...premiumPostBody };
    setSelectedUnlimitedRestore(e.target.checked);
    updatedPostBody.IsUnlimitedRestore = e.target.checked;
    dispatch(updatePremiumPostObject(updatedPostBody));
  };

  const handlePARaider = (e) => {
    let updatedPostBody = { ...premiumPostBody };
    updatedPostBody.IsIPARider = e.target.checked;
    setSelectedPArider(e.target.checked);
    dispatch(updatePremiumPostObject(updatedPostBody));
  };

  const handlePolicyType = (e) => {
    let updatedPostBody = { ...premiumPostBody };
    updatedPostBody.policyType = e.target.value;
    dispatch(updatePremiumPostObject(updatedPostBody));
  };
  console.log(selectedUnlimitedRestore, "s3oifdnoi3dn3onoiniooi");
  return (
    <div className="container">
      <Grid
        container
        alignItems="center"
        spacing={1}
        item
        xs={12}
        marginTop={1}
      >
        <IconButton
          onClick={() => navigate("/health/quotations")}
          aria-label="Go back"
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="body1">Go back</Typography>
      </Grid>
      <div className="row mt-4">
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-div centeralign flex-wrap">
                <div className="logo-details view-details-logo">
                  <img src={hdfcLogo} alt="Care Health" width="100" />
                </div>
                <div className="plan-name">
                  <h2>HDFC Health</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="inner-div">
                <div class="heading_section">
                  <h3>
                    Add-ons
                    <span>
                      You should get these additional benefits to enhance your
                      current plan
                    </span>
                  </h3>
                  <div className="multi-addons-wrapper">
                    <h4 className="addons-importance">Recommended Addons:-</h4>
                    <div className="addons_wrapper">
                      <div className="addons_details">
                        <h4>Critical Illness</h4>
                        <div className="addons-info"></div>
                      </div>
                      <div className="addons_type_wrapper">
                        <div className="addons_add_buttons">
                          <CustomCheckbox
                            type="checkbox"
                            checked={showSlider}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                      </div>
                    </div>
                    {showSlider && (
                      <div className="addons_wrapper">
                        <div
                          className="addons_type_wrapper"
                          style={{ width: "90%", margin: "auto" }}
                        >
                          <div
                            className="addons_add_buttons"
                            style={{ width: "100%" }}
                          >
                            <Typography gutterBottom sx={{ color: "#505f79" }}>
                              Selected Value: {sliderValue}
                            </Typography>
                            <Slider
                              aria-label="Critical Illness Coverage"
                              defaultValue={sliderValue}
                              value={premiumPostBody.HDFC_criticalIllness}
                              valueLabelDisplay="auto"
                              step={100000}
                              min={0}
                              max={20000000}
                              onChange={handleSliderChange}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="addons_wrapper">
                      <div className="addons_details">
                        <h4>Unlimited Restore</h4>
                        <div className="addons-info"></div>
                      </div>
                      <div className="addons_type_wrapper">
                        <div className="addons_add_buttons">
                          <CustomCheckbox
                            type="checkbox"
                            onChange={handleUnlimitedRestore}
                            checked={premiumPostBody?.IsUnlimitedRestore}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="addons_wrapper">
                      <div className="addons_details">
                        <h4>
                          Personal Accident Rider
                          <span className="ms-2">
                            <DarkToolTip title="Applicable for Proposer only">
                              <InfoIcon fontSize="small" color="action" />
                            </DarkToolTip>
                          </span>
                        </h4>
                      </div>
                      <div className="addons_type_wrapper">
                        <div className="addons_add_buttons">
                          <CustomCheckbox
                            type="checkbox"
                            onChange={handlePARaider}
                            checked={premiumPostBody?.IsIPARider}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="addons_wrapper">
                      <div className="addons_details">
                        <h4>Hospital Cash</h4>
                        <div className="addons-info"></div>
                      </div>
                      <div className="addons_type_wrapper">
                        <div className="addons_type_wrapper">
                          <div className="addons_add_buttons">
                            <div className="input_group">
                              <label htmlFor="selftitle">Select amount</label>
                              <select
                                className="form_control"
                                value={hospitalCash}
                                onChange={handleHospitalcash}
                              >
                                <option value="0">0</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                                <option value="1500">1500</option>
                                <option value="2000">2000</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 right_product  ">
          <div className="right_summery_section mb-4">
            <h3>Summary</h3>
            <div className="scroll_space">
              <div className="flexRow section_right">
                <div>Base Premium - Without GST</div>
                <div>
                  <div>
                    <span>
                      {loading ? (
                        <p>Fetching...</p>
                      ) : (
                        <p>₹ {premiumWithoutGST}</p>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="rider_head">
                <h4>Selected Add-ons</h4>
                <div className="flexRow select_benefits">
                  {hospitalCash !== "0" && (
                    <div className="addon-row">
                      <span>Selected Hospital Cash</span>
                      <span>
                        <b>₹ {hospitalCash}</b>
                      </span>
                    </div>
                  )}
                  {illnessChecked && (
                    <div className="addon-row">
                      <span>Selected illness amount</span>
                      <span>
                        <b>₹ {sliderValue}</b>
                      </span>
                    </div>
                  )}
                  {selectedUnlimitedRestore && (
                    <div className="addon-row">
                      <span>Unlimited Restore</span>
                    </div>
                  )}
                  {selectedPArider && (
                    <div className="addon-row">
                      <span>Personal Accident Rider</span>
                    </div>
                  )}
                  {!(
                    hospitalCash !== "0" ||
                    illnessChecked ||
                    selectedUnlimitedRestore || selectedPArider
                  ) && <div>No add-ons selected</div>}
                </div>
              </div>
            </div>
            <div className="premium_right">
              <div className="flexRow section_premium discountSectionPremium">
                <div>Total premium + 18% GST + Selected Addons</div>
                <span>
                  {loading ? <p>Fetching...</p> : <p>₹ {premiumWithGST}</p>}
                </span>{" "}
              </div>
              <button
                type="button"
                className="primaryMainCta"
                onClick={() => navigate("/health/proposalform")}
              >
                {loading ? "Loading..." : "Proceed to HDFC Proposal"}
              </button>
            </div>
          {trueCount > 1 && (
            <div className="inner_right_section">
              <div className="scroll_space">
                <div className="rider_head">
                  <div className="premium_right">
                    <div className="input_group">
                      <label htmlFor="selftitle">Policy Type</label>
                      <select
                        className="form_control"
                        value={premiumPostBody.policyType}
                        onChange={(e) => handlePolicyType(e)}
                      >
                        <option value="Individual">Family Individual</option>
                        <option value="Floater">Family</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HDFCProductDetail;
