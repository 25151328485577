import { Grid, Modal, Tabs, Tab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import './MakeModalVarient.css';
import { AlertError } from '../../../../Framework/Toaster/AlertError';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { MandatoryFieldsSelectingErrorMessage, VehicleMainCodeErrorMessage } from '../../../../Framework/Utils/ErrorMessage';
import { useSelector } from 'react-redux';
import { AddToLocalStorage, GetDataLocalStorage } from '../../../../Framework/GlobalObj';

const CommercialMakeModelVarient = ({ open, setOpen, handleClose, vehicleMaster, formData, setFormData }) => {
  const [errorr, setErrorr] = useState(false);
  const [value, setValue] = useState('vehicle type');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [selectedBodyType, setSelectedBodyType] = useState('');
  const [selectedVehicleType, setSelectedVehicleType] = useState('');
  const [bodyTypes, setBodyTypes] = useState([]);
  const [selectedGrossWeight, setSelectedGrossWeight] = useState('');
  const [grossWeights, setGrossWeights] = useState(['0-3500', '3501-7500', '7501-12000', '12000-25000', '25001-40000', '40001-99999']);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [variants, setVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState('');
  const [fuels, setFuels] = useState([]);
  const [selectedFuel, setSelectedFuel] = useState('');
  const [vehicleMainCodeError, setVehicleMainCodeError] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [selectedPermitType, setSelectedPermitType] = useState('PUBLIC');
  const [selectedUsageType, setSelectedUsageType] = useState('');
  const [selectedCategaryType, setSelectedCategaryType] = useState('');

  const usageType = useSelector((store) => store.motorReducer.usageType);

  useEffect(() => {
    setSelectedBodyType('');
    if (selectedType === 'Passenger Carrying') {
      setBodyTypes(['Taxi', 'Bus', 'Three Wheeler', 'Three Wheeler(6+Passengers)']);
      setSelectedCategaryType('PCV')
    } else if (selectedType === 'Goods Carrying' && selectedGrossWeight) {
      setSelectedCategaryType('GCV')
      if (selectedGrossWeight === '0-3500') {
        setBodyTypes(['Pick up Van(3 Wheeler)', 'Pick up Van(4 Wheeler)', 'Tractor', 'Tipper/Dumper']);
      } else if (selectedGrossWeight === '3501-7500') {
        setBodyTypes(['Pick up Van(4 Wheeler)', 'Tipper/Dumper', 'Truck']);
      } else if (selectedGrossWeight === '7501-12000') {
        setBodyTypes(['Tipper/Dumper', 'Truck']);
      } else if (selectedGrossWeight === '12000-25000' || selectedGrossWeight === '25001-40000' || selectedGrossWeight === '40001-99999') {
        setBodyTypes(['Tipper/Dumper', 'Truck', 'Heavy Truck', 'Trailer']);
      }
    } else if (selectedType === 'Miscellaneous') {
      setSelectedCategaryType('MSD')
      setBodyTypes(['Agricultural Tractor', 'Excavator', 'BackHoe Loader', 'Harvester', 'Crane', 'Transit Mixer']);
    }
  }, [selectedType, selectedGrossWeight]);

  useEffect(() => {
    if (vehicleMaster.length > 0 && selectedBodyType) {
      let max;
      let min;
      if (selectedGrossWeight != '') {
        min = Number(selectedGrossWeight.split('-')[0]);
        max = Number(selectedGrossWeight.split('-')[1]);
      }

      if (selectedBodyType == 'Bus') {
        if (selectedVehicleType == 'Tourist Bus') {
          setSelectedUsageType('KFZPMB');
        } else if (selectedVehicleType == 'School Bus') {
          setSelectedUsageType('KFZEDU');
        } else {
          setSelectedUsageType('KFZTPB');
        }
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if (vehicle['Body Type'].toLowerCase() === selectedBodyType.toLowerCase() && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase()) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
      } else if (selectedBodyType == 'Taxi') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if (vehicle.Variant.toString().trim().toLowerCase().includes('taxi') && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase()) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZPLT');
      } else if (selectedBodyType == 'Three Wheeler') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if (vehicle['No Of Wheels'] == 3 && vehicle['Seating Capacity'] <= 6 && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase()) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZPAR');
      } else if (selectedBodyType == 'Three Wheeler(6+Passengers)') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if (vehicle['No Of Wheels'] == 3 && vehicle['Seating Capacity'] > 6 && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase()) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZPAR');
      } else if (selectedBodyType == 'Pick up Van(3 Wheeler)') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if ((vehicle['Body Type'].trim() === 'Pick Up Van' || vehicle.Variant.toString().trim().toLowerCase().includes('PICKUP VAN')) && vehicle['No Of Wheels'] == 3 && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase() && vehicle['Grosss Vehicle Weight'] >= min && vehicle['Grosss Vehicle Weight'] <= max) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());

        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZSP');
      } else if (selectedBodyType == 'Pick up Van(4 Wheeler)') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if ((vehicle['Body Type'].trim() === 'Pick Up Van' || vehicle.Variant.toString().trim().toLowerCase().includes('PICKUP VAN')) && vehicle['No Of Wheels'] == 4 && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase() && vehicle['Grosss Vehicle Weight'] >= min && vehicle['Grosss Vehicle Weight'] <= max) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZSP');
      } else if (selectedBodyType == 'Tractor' || selectedBodyType == 'Agricultural Tractor') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if ((vehicle['Body Type'].trim() === 'Tractors' || vehicle.Model.toString().trim().includes('AGRICULTURAL TRACTOR')) && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase()) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZSP');
      } else if (selectedBodyType == 'Tipper/Dumper') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if (vehicle['Body Type'].trim() === 'Tipper / Dumper' && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase() && vehicle['Grosss Vehicle Weight'] >= min && vehicle['Grosss Vehicle Weight'] <= max) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZNP');
      } else if (selectedBodyType == 'Truck') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if (vehicle['Body Type'].trim() === 'Truck' && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase() && vehicle['Grosss Vehicle Weight'] >= min && vehicle['Grosss Vehicle Weight'] <= max) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZNP');
      } else if (selectedBodyType == 'Trailer') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if ((vehicle['Body Type'].trim() === 'Trailer' || vehicle.Variant.toString().trim().toLowerCase().includes('Trailer')) && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase() && vehicle['Grosss Vehicle Weight'] >= min && vehicle['Grosss Vehicle Weight'] <= max) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        console.log('anwar123459', filteredBrands);
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZNP');
      } else if (selectedBodyType == 'Heavy Truck') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if (vehicle['Body Type'].trim() === 'Heavy Truck / Semi Trailer' && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase() && vehicle['Grosss Vehicle Weight'] >= min && vehicle['Grosss Vehicle Weight'] <= max) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZNP');
      } else if (selectedBodyType == 'Excavator') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if (vehicle['Body Type'].trim() === 'EXCAVATOR' && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase()) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZSP');
      } else if (selectedBodyType == 'BackHoe Loader') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if (vehicle['Body Type'].trim() === 'BACKHOE LOADER' && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase()) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZSP');
      } else if (selectedBodyType == 'Harvester') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if (vehicle['Body Type'].trim() === 'HARVESTER' && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase()) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZSP');
      } else if (selectedBodyType == 'Crane') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if (vehicle['Body Type'].trim() === 'CRANE' && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase()) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZSP');
      } else if (selectedBodyType == 'Transit Mixer') {
        const filteredBrands = vehicleMaster?.filter((vehicle) => {
          if (vehicle['Body Type'].trim() === 'Transit Mixer' && vehicle['vehicleType'].toLowerCase() === selectedType.toLowerCase()) {
            return vehicle;
          }
        });
        const vehicleBrands = filteredBrands?.map((vehicle) => vehicle['Make'].trim());
        setBrands(Array.from(new Set(vehicleBrands)));
        setFilteredData(filteredBrands);
        setSelectedUsageType('KFZSP');
      }
    }
  }, [selectedBodyType]);

  const handleChange = (event, newValue) => {
    setErrorr(false);
    setValue(newValue);
  };
  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    setSelectedBodyType('');
    setSelectedGrossWeight('');
  };
  const handleClickContinue = () => {
    if (selectedBodyType !== '' && filteredData.length > 0) {
      setValue('brand');
    } else {
      console.log(filteredData);
      alert('erorrrrr');
    }
  };
  const handleBodyTypeChange = (event) => {
    setSelectedBodyType(event.target.value);
  };
  const handlevehicleTypeChange = (event) => {
    setSelectedVehicleType(event.target.value);
  };
  const handleUsageType = (e) => {
    setSelectedUsageType(e.target.value);
    setValue('brand');
  };
  const handleGrossWeightChange = (event) => {
    setSelectedGrossWeight(event.target.value);
  };
  const handleBrandClick = (brand) => {
    setSelectedBrand(brand);
    setValue('model');
    const filteredDatabyMake = filteredData.filter((obj) => obj['Make'].trim().toLowerCase() === brand.trim().toLowerCase());
    // setFilteredByMake(filteredDatabyMake);
    const modelsArray = [...new Set(filteredDatabyMake.map((obj) => String(obj['Model']).trim()))];
    setModels(modelsArray.sort());
  };

  const handleModelClick = (model) => {
    setSelectedModel(model);
    setValue('variant');
    const filteredDataByModel = filteredData.filter((obj) => obj['Make'].trim().toLowerCase() === selectedBrand.trim().toLowerCase() && String(obj['Model']).trim().toLowerCase() === model.trim().toLowerCase());
    const variantsArray = [...new Set(filteredDataByModel.map((obj) => String(obj['Variant']).trim()))];
    setVariants(variantsArray.sort());
  };

  const handleVariantClick = (variant) => {
    setSelectedVariant(variant);
    setValue('fuel');

    let filteredDataByVariant = filteredData.filter((vehicle) => vehicle['Make'].trim().toLowerCase() === selectedBrand.trim().toLowerCase() && String(vehicle['Model']).trim().toLowerCase() === selectedModel.toLowerCase() && String(vehicle['Variant']).trim().toLowerCase() === variant.toLowerCase());

    let fuelsArray = [...new Set(filteredDataByVariant.map((obj) => String(obj['Fuel Type']).trim().toUpperCase()))];
    setFuels(fuelsArray);
  };

  const handleFuelClick = (fuel) => {
    setSelectedFuel(fuel);
  };

  const handlePermitType = (e) => {
    let permit = e.target.value;
    setSelectedPermitType(permit);
  };

  const handleApply = () => {
    
    const obj = GetDataLocalStorage('globalObj')
    console.log(selectedCategaryType)
    obj.vehicle.vehicleCatagory = selectedCategaryType
    if (selectedBodyType && selectedBrand && selectedFuel && selectedVariant && selectedModel && selectedUsageType) {
      let applyObj = { ...formData };
      applyObj.vehicle.makerModel = selectedModel + ' ' + selectedVariant;
      applyObj.vehicle.makerDescription = selectedBrand;
      applyObj.vehicle.model = selectedModel;
      applyObj.vehicle.variant = selectedVariant;
      applyObj.vehicle.fuelType = selectedFuel;
      applyObj.vehicle.permitType = selectedPermitType;
      applyObj.vehicle.usageType = selectedUsageType;
      applyObj.vehicle.vehicleCatagory = selectedCategaryType

      vehicleMaster.forEach((el) => {
        if (el.Make.toString().trim().toLowerCase() == selectedBrand.toString().trim().toLowerCase() && el.Model.toString().trim().toLowerCase() == selectedModel.toString().trim().toLowerCase() && el.Variant.toString().trim().toLowerCase() == selectedVariant.toString().trim().toLowerCase() && el['Fuel Type']?.toString().trim().toLowerCase() == selectedFuel.toString().trim().toLowerCase()) {
          applyObj.vehicle.vehicleMaincode = el['Vehicle Code'].toString().trim();
        }
      });
      if (applyObj.vehicle.vehicleMaincode != '') {
        // AddToLocalStorage("globalObj", applyObj);
        setOpen(false);
        setValue('vehicle type');
        setVehicleMainCodeError(false);
        setFormData(applyObj);
      } else {
        setVehicleMainCodeError(true);
      }
    } else {
      setIsSubmited(true);
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="modal-container">
      <Grid className="make-modal-varient-main-container">
        <Grid item xs={11} sm={11} md={12} container>
          <Grid item xs={11} sm={2} md={12}>
            <Tabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary" aria-label="secondary tabs example" className="make-modal-varient-tab-parent">
              <Tab value="vehicle type" label="Vehicle Type" className="make-modal-varient-tab-label" />
              <Tab value="brand" label="Brand" className="make-modal-varient-tab-label" />
              <Tab value="model" label="Model" className="make-modal-varient-tab-label" />
              <Tab value="variant" label="Select Variant" className="make-modal-varient-tab-label" />
              <Tab value="fuel" label="Fuel type" className="make-modal-varient-tab-label" />
            </Tabs>
            {/* <CloseIcon onClick={closeModal} /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="make-modal-varient-suggestion-div">
            {value == 'vehicle type' ? (
              <Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="vehicle-type-dropdown">
                    <label htmlFor="vehicleType" className="cvlabel">
                      Select Vehicle Type:
                    </label>
                    <select id="vehicleType" value={selectedType} onChange={handleTypeChange} className="cvselect">
                      <option value="">--Select Option</option>
                      <option value="Goods Carrying">Goods Carrying</option>
                      <option value="Passenger Carrying">Passenger Carrying</option>
                      <option value="Miscellaneous">Miscellaneous</option>
                    </select>
                  </div>
                  {selectedType === 'Passenger Carrying' && (
                    <div>
                      <div className="vehicle-type-dropdown">
                        <label htmlFor="bodyType" className="cvlabel">
                          Select Body Type:
                        </label>
                        <select id="bodyType" value={selectedBodyType} onChange={handleBodyTypeChange} className="cvselect">
                          <option value="">--Select body type--</option>
                          {bodyTypes?.map((type) => (
                            <option value={type}>{type}</option>
                          ))}
                        </select>
                      </div>
                      {selectedBodyType === 'Bus' && (
                        <div className="vehicle-type-dropdown">
                          <label htmlFor="bodyType" className="cvlabel">
                            Vehicle Used For
                          </label>
                          <select id="bodyType" value={selectedVehicleType} onChange={handlevehicleTypeChange} className="cvselect">
                            <option value="">--Select body type--</option>
                            <option value="Tourist Bus">Tourist Bus</option>
                            <option value="School Bus">School Bus</option>
                            <option value="Corporate Contract Bus">Corporate Contract Bus</option>
                          </select>
                        </div>
                      )}
                    </div>
                  )}
                  {selectedType === 'Goods Carrying' && (
                    <div>
                      <div className="vehicle-type-dropdown">
                        <label htmlFor="weight" className="cvlabel">
                          Select GVW Range
                        </label>
                        <select id="weight" value={selectedGrossWeight} onChange={handleGrossWeightChange} className="cvselect">
                          <option value="">--Select GVW Range--</option>

                          {grossWeights?.map((weight) => (
                            <option key={weight} value={weight}>
                              {weight}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="vehicle-type-dropdown">
                        <label htmlFor="bodyType" className="cvlabel">
                          Select body Type:
                        </label>
                        <select id="bodyType" value={selectedBodyType} onChange={handleBodyTypeChange} className="cvselect">
                          <option value="">--Select body type--</option>

                          {bodyTypes?.map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  {selectedType === 'Miscellaneous' && (
                    <div className="vehicle-type-dropdown">
                      <label htmlFor="bodyType"> Select Body Type:</label>
                      <select id="bodyType" value={selectedBodyType} onChange={handleBodyTypeChange} className="cvselect">
                        <option value="">--Select body type--</option>
                        {bodyTypes?.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {selectedType && (
                    <div className="vehicle-type-dropdown">
                      <label className="cvlabel">Select Permit Type:</label>
                      <RadioGroup row aria-label="permitType" name="permitType" value={selectedPermitType} onChange={handlePermitType}>
                        <FormControlLabel value="PUBLIC" control={<Radio />} label="Public" />
                        <FormControlLabel value="PRIVATE" control={<Radio />} label="Private" />
                      </RadioGroup>
                    </div>
                  )}
                  {selectedBodyType && (
                    <button className="make-modal-varient-apply-button" onClick={handleClickContinue}>
                      Continue
                    </button>
                  )}
                </Grid>
              </Grid>
            ) : value == 'brand' ? (
              <div>
                {brands.length > 0 ? (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container gap={4} className="state-city-modal-parent">
                      {brands.map((brand) => (
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          key={brand}
                          className="make-modal-varient-eachelement"
                          style={
                            selectedBrand === brand
                              ? {
                                  backgroundColor: '#31a0d8',
                                  color: 'white',
                                  textAlign: 'center'
                                }
                              : { textAlign: 'center' }
                          }
                          onClick={() => handleBrandClick(brand)}
                        >
                          {brand}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ) : (
                  <p>No brands available</p>
                )}
              </div>
            ) : value == 'model' ? (
              <div>
                {models.length > 0 ? (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container gap={4} className="state-city-modal-parent">
                      {models?.map((model) => (
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          key={model}
                          className="make-modal-varient-eachelement"
                          style={
                            selectedModel === model
                              ? {
                                  backgroundColor: '#31a0d8',
                                  color: 'white',
                                  textAlign: 'center'
                                }
                              : { textAlign: 'center' }
                          }
                          onClick={() => handleModelClick(model)}
                        >
                          {model}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ) : (
                  <p>No Models available</p>
                )}
              </div>
            ) : value == 'variant' ? (
              <div>
                {variants.length > 0 ? (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container gap={4} className="state-city-modal-parent">
                      {variants.map((variant) => (
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          key={variant}
                          className="make-modal-varient-eachelement"
                          style={
                            selectedVariant === variant
                              ? {
                                  backgroundColor: '#31a0d8',
                                  color: 'white',
                                  textAlign: 'center'
                                }
                              : { textAlign: 'center' }
                          }
                          onClick={() => handleVariantClick(variant)}
                        >
                          {variant}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ) : (
                  <p>No variants available</p>
                )}
              </div>
            ) : value == 'fuel' ? (
              <main>
                <div>
                  {fuels.length > 0 ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container gap={4} className="state-city-modal-parent">
                        {fuels.map((fuel) => (
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            key={fuel}
                            className="make-modal-varient-eachelement"
                            style={
                              selectedFuel === fuel
                                ? {
                                    backgroundColor: '#31a0d8',
                                    color: 'white',
                                    textAlign: 'center'
                                  }
                                : { textAlign: 'center' }
                            }
                            onClick={() => handleFuelClick(fuel)}
                          >
                            {fuel}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ) : (
                    <p>No Fuels available</p>
                  )}
                </div>
              </main>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        {value == 'fuel' ? (
          <button className="make-modal-varient-apply-button" onClick={handleApply}>
            Apply
          </button>
        ) : (
          <div />
        )}
        {isSubmited ? <AlertError open={isSubmited} setOpen={setIsSubmited} message={MandatoryFieldsSelectingErrorMessage} /> : ''}
        {vehicleMainCodeError ? <AlertError open={vehicleMainCodeError} setOpen={setVehicleMainCodeError} message={VehicleMainCodeErrorMessage} /> : ''}
        <CloseIcon sx={{ cursor: 'pointer', position: 'absolute', right: '20px', top: '20px' }} onClick={() => setOpen(false)} />
      </Grid>
    </Modal>
  );
};

export default CommercialMakeModelVarient;
