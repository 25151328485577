import React from "react";
import { Routes, Route } from "react-router-dom";
import "../../App.css";
import FamilyDetails from "../../Health/Pages/FamilyDetails";
import QuotationPage from "../../Health/Pages/QuotationPage";
import ProposalForm from "../../Health/Components/Forms/ProposalForm";
import FamilyAgeFields from "../../Health/Components/FamilyAgeFields";
import CarePaymentPage from "../../Health/Components/CarePaymentPage";
import ProductDetail from "../../Health/Pages/ProductDetail";
import HDFCProductDetail from "../../Health/Pages/HDFCProductDetail";
import HDFCKycPage from "../../Health/Pages/HDFCKycPage";
import { HealthPaymentSuccess } from "../Pages/Health info/Paymentsuccess";
 
const HealthRoutes = () => {
 
  return (
      <Routes>
        <Route path="/" element={ <FamilyDetails />} />
        <Route path="/Familyagefield" element={ <FamilyAgeFields />} />
        <Route path="/quotations" element={<QuotationPage />} />
        <Route path="/proposalform" element={<ProposalForm />} />
        <Route path="/paymentPage" element={<CarePaymentPage />} />
        <Route path="/thank_you" element={<HealthPaymentSuccess />} />
        <Route path="/product_detail/care" element={<ProductDetail/>}/>
        <Route path="/product_detail/hdfc" element={<HDFCProductDetail/>}/>
        <Route path="/hdfckyc" element={<HDFCKycPage/>} />     
        </Routes>
  );
};
 
export default HealthRoutes;