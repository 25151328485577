import axios from "axios";
import { common_api } from "../Config";

const AddCustomerData = async (customerData) => {
  try {
    let addCustomerResponse = await axios.post(
      `${common_api}/insurance/crm/v1/org/agent/customer`,
      customerData
    );
    return addCustomerResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendOtpToNumber = async (req) => {
  console.log(req);
  try {
    let sendotpresponse = await axios.post(
      `${common_api}/messages/v1/sms`,
      req
    );
    return sendotpresponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendOtp = async (
  customerData,
  otpData,
  updateCustomer = false
) => {
  try {
    let res;
    if (updateCustomer) {
      const addCustomerResponse = await AddCustomerData(customerData);
      res = addCustomerResponse;
    } else {
      const addCustomerResponse = await AddCustomerData(customerData);
      
      if (addCustomerResponse?.data?.statusCode === 200) {
        const otpResponse = await sendOtpToNumber(otpData);
        res = otpResponse;
      }
    }
    
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
