import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { health_api } from "../../Config";
import { getHDFCPolicydata } from "../../Redux/Health/action";

const HDFCKycPage = () => {
  const proposalData = useSelector((e) => e?.healthReducer?.healthproposalData);
  const dispatch = useDispatch();
  const txnID = useSelector((e)=>e?.healthReducer?.generatedHDFCTxnId)
  // const txnID = useSelector(
  //   (e) => e?.healthReducer?.premiumPostObject?.generatedHDFCTxnId
  // );
  console.log(proposalData, "proposalData in HDFC KYC page");
  let [params] = useSearchParams();
  const [checkSumData, setCheckSumData] = useState({});
  const [send, setSend] = useState(false);
  const [loading, setLoading] = useState(true);
  const [res, setRes] = useState({});

  console.log(txnID,"params set");

  useEffect(() => {
    let pehchanId = params?.get("kyc_id");
    console.log(pehchanId,"hdfc trea5ns55555555555555")
    let data = {
      ...proposalData,
      pehchanId: pehchanId,
      hdfcTraxnId: txnID,
    };

    const callApis = async() =>{
      try{
        const response = await axios.post(`${health_api}/insurance/v1/health/policy/proposal`, data)
        // const response = await axios.post(`http://localhost:8083/insurance/v1/health/policy/proposal`, data)
        console.log(response , 'reponse of proposal data')
        let resObj = response?.data?.body;
        console.log(resObj , 'reponse object');
        setRes(resObj);
        if (resObj.StatusCode === 200){
           let proposalNumber = resObj?.Policy_Details?.ProposalNumber || null;
           let totalpremium = resObj?.Policy_Details?.TotalPremium || null;
           let netPremium = resObj?.Policy_Details?.NetPremium || null;
           let successUrl = `https://main.d315p1596xx1q3.amplifyapp.com/health/thank_you`;
           let failureUrl = `https://main.d315p1596xx1q3.amplifyapp.com/health/thank_you`;
           const currentDate = new Date()
             .toISOString()
             .slice(2, 10)
             .replace(/-/g, "");
           let randomDigits = Math.floor(Math.random() * 100000)
             .toString()
             .padStart(5, "0");
           const trnsno = `1127${currentDate}${randomDigits}`;
           const responseOfGenerateCheckSum = await axios.get(
             `https://heapp21.hdfcergo.com/UAT/PaymentUtilitiesService/PaymentUtilities.asmx/GenerateRequestChecksum?TransactionNo=${trnsno}&TotalAmount=${totalpremium}&AppID=${"10295"}&SubscriptionID=S000000333&SuccessUrl=${successUrl}&FailureUrl=${failureUrl}&Source=POST`
           );
           console.log(responseOfGenerateCheckSum , 'responseOfGenerateCheckSum');
           let checksum = responseOfGenerateCheckSum?.data?.split(">")[2]?.split("<")[0];
           let resDataToRedux = {
             proposalNumber: proposalNumber,
             totalpremium: totalpremium,
             netPremium: netPremium,
             hdfcCheckSum: Number(checksum),
             intrumentno: trnsno,
             txnID: txnID,
             provider: "HDFC",
           };
           dispatch(getHDFCPolicydata(resDataToRedux));
           console.log(checksum , 'only check sum');
           let dataOfCheckSum = {
             trnsno: trnsno,
             amt: `${totalpremium}`,
             appid: "10295",
             subid: "S000000333",
             surl: `${successUrl}`,
             furl: `${failureUrl}`,
             src: "POST",
             chksum: `${checksum}`,
           };
           setCheckSumData(dataOfCheckSum);
           setSend(true);
           setLoading(false);
        }else{
          alert("KYC is not in complete state");
          setLoading(false);
        }
      }catch(err){
         console.log("checksum error", err);
         setLoading(false);
      }
    }

    //old 

    // console.log(pehchanId,"pehchan testing")
    // console.log(data, "proposal data for HDFC");
    // if (params.get("status") === "approved") {
      
    //   axios
    //     .post(`${health_api}/insurance/v1/health/policy/proposal`, data)
    //     .then((res) => {
    //       let resObj = res?.data?.body;
    //       console.log(res, "response from hdfc proposal");
    //       setRes(resObj);
    //       if (resObj.StatusCode === 200) {
    //         let proposalNumber = resObj?.Policy_Details?.ProposalNumber || null;
    //         let totalpremium = resObj?.Policy_Details?.TotalPremium || null;
    //         let netPremium = resObj?.Policy_Details?.NetPremium || null;
    //         let successUrl = `https://main.d315p1596xx1q3.amplifyapp.com/health/thank_you`;
    //         let failureUrl = `https://main.d315p1596xx1q3.amplifyapp.com/health/thank_you`;
    //         const currentDate = new Date()
    //           .toISOString()
    //           .slice(2, 10)
    //           .replace(/-/g, "");
    //         let randomDigits = Math.floor(Math.random() * 100000)
    //           .toString()
    //           .padStart(5, "0");
    //         const trnsno = `1127${currentDate}${randomDigits}`;
    //         axios
    //           .get(
    //             `https://heapp21.hdfcergo.com/UAT/PaymentUtilitiesService/PaymentUtilities.asmx/GenerateRequestChecksum?TransactionNo=${trnsno}&TotalAmount=${totalpremium}&AppID=${"10295"}&SubscriptionID=S000000333&SuccessUrl=${successUrl}&FailureUrl=${failureUrl}&Source=POST`
    //           )
    //           .then(async (res) => {
    //             console.log(res);
    //             let checksum = res.data.split(">")[2]?.split("<")[0];
    //             let resDataToRedux = {
    //               proposalNumber: proposalNumber,
    //               totalpremium: totalpremium,
    //               netPremium: netPremium,
    //               hdfcCheckSum: Number(checksum),
    //               intrumentno: trnsno,
    //               txnID: txnID,
    //               provider: "HDFC",
    //             };
    //             dispatch(getHDFCPolicydata(resDataToRedux));
    //             console.log(checksum);
    //             let dataOfCheckSum = {
    //               trnsno: trnsno,
    //               amt: `${totalpremium}`,
    //               appid: "10295",
    //               subid: "S000000333",
    //               surl: `${successUrl}`,
    //               furl: `${failureUrl}`,
    //               src: "POST",
    //               chksum: `${checksum}`,
    //             };
    //             setCheckSumData(dataOfCheckSum);
    //             setSend(true);
    //             setLoading(false);
    //           });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("checksum error", err);
    //       setLoading(false);
    //     });
    // } else {
    //   alert("KYC is not in complete state");
    // }
    callApis()
  }, []);
  console.log(checkSumData, "data");

  //form submit logic
  if (send || res?.StatusCode === 200) {
    setTimeout(() => {
      document.getElementById("form").submit();
    }, 1000);
  }

  console.log(checkSumData, "ininnowe");

  return (
    <div>
      {loading ? (
        <Box
          sx={{
            height: "600px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <form
          action="https://heapp21.hdfcergo.com/UAT/OnlineProducts/CCPGISUBSCRIBER/MakePayment.aspx"
          name="PAYMENTFORM"
          method="POST"
          _lpchecked="1"
          id="form"
        >
          <div>
            <input type="hidden" name="trnsno" value={checkSumData.trnsno} />
            <input type="hidden" name="amt" value={checkSumData.amt} />
            <input type="hidden" name="appid" value={checkSumData.appid} />
            <input type="hidden" name="subid" value={checkSumData.subid} />
            <input type="hidden" name="surl" value={checkSumData.surl} />
            <input type="hidden" name="furl" value={checkSumData.furl} />
            <input type="hidden" name="src" value="POST" />
            <input type="hidden" name="chksum" value={checkSumData.chksum} />
            <input type="hidden" name="Additionalinfo1" value="" />
            <input type="hidden" value="submit" />
          </div>
        </form>
      )}
    </div>
  );
};

export default HDFCKycPage;
