import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Grid, Box, Typography, Snackbar, Slide } from '@mui/material';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from "react-redux";
import { clearError, setError } from '../../../../Redux/action-creators/errorAction';
import { clearSuccessMessage, setSuccessMessage } from "../../../../Redux/action-creators/successAction";



const OtpPopup = ({ open, onClose, mobile, submitHandler }) => {
    const [otp, setOtp] = useState(['', '', '', '']);
    const [focusedIndex, setFocusedIndex] = useState(0);
    // const [isAlertTrue, setIsAlertTrue] = useState(false)
    // const [AlertMsg, setAlertMsg] = useState('')
    const [isLoaderTrue, setIsLoaderTrue] = useState(false)
    const dispatch = useDispatch();
    

    const handleChange = (event, index) => {
        const { value } = event.target;
        if (/^\d$/.test(value) || value === '') {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Focus the next input if available
            if (value && index < 3) {
                setFocusedIndex(index + 1);
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace') {
            event.preventDefault();
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);

            // Focus the previous input if available
            if (index > 0) {
                setFocusedIndex(index - 1);
                document.getElementById(`otp-input-${index - 1}`).focus();
            }
        }
    };

    const handleSubmit = async() => {
        // Handle OTP submission logic
        dispatch(clearError());
        dispatch(clearSuccessMessage());

        if (otp.join('').length === 4){
            setIsLoaderTrue(true)
            try{
                const response = await fetch('https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/Posp/v1/user/validateOTP', {
                    method: 'POST',
                    body: JSON.stringify({
                        "enrollType": "SIGNUP",
                        "phone": mobile,
                        "otp" : otp.join('')
                    })
                })
                const data = await response.json()
                if (data.isOtpVerified) {
                    dispatch(setSuccessMessage("Mobile Number Verified Successfuly..."))
                    // setIsAlertTrue(true)
                    // setAlertMsg("Mobile Number Verified Successfuly...")
                    submitHandler()
                    onClose();

                } else {
                    dispatch(setError(data.message))
                    // setIsAlertTrue(true)
                    // setAlertMsg(data.message)
                }
            }catch(e){
                dispatch(setError('Something Went Wrong...'))
                // setIsAlertTrue(true)
                // setAlertMsg('Something Went Wrong...')
            }
            
            setIsLoaderTrue(false)
        }
        
        
        
    };

    const resetOtp = () => {
        setOtp(['', '', '', '']);
        setFocusedIndex(0);
    };

    
    // for alert popup this below two fuctions
    // function SlideTransition(props) {
    //     return <Slide {...props} />;
    // }


    // const handleClose = () => {
    //     setIsAlertTrue(false)
    // }

    useEffect(() => {
        if (open) {
            resetOtp();
        }
    }, [open]);

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle sx={{fontSize : '16px'  , textAlign : 'center'}}>Enter OTP</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                        Verification code has been sent to your mobile number.
                    </Typography>
                    <Box py={3} display="flex" justifyContent="center">
                        <Grid container spacing={2} justifyContent="center">
                            {otp.map((value, index) => (
                                <Grid item key={index}>
                                    <TextField
                                        id={`otp-input-${index}`}
                                        type="text"
                                        inputProps={{ maxLength: 1 }}
                                        value={value}
                                        onChange={(event) => handleChange(event, index)}
                                        onKeyDown={(event) => handleKeyDown(event, index)}
                                        variant="standard"
                                        style={{ width: '2rem', textAlign: 'center' }}
                                        autoComplete="off"
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                   {isLoaderTrue ? (
                        <CircularProgress color="primary" width={25} sx={{marginLeft : '35px' , marginRight : '15px'}}/>
                   ) : (
                        <Button onClick = { handleSubmit } color = "primary">
                            Submit
                        </Button>
                   )}
                </DialogActions>
            </Dialog>

            {/* <Snackbar
                open={isAlertTrue}
                onClose={handleClose}
                TransitionComponent={SlideTransition}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

            >
                <Alert variant="filled" style={{ backgroundColor: `${AlertMsg !== 'Mobile Number Verified Successfuly...' ? '#ba000d' : 'success'}` }}>{AlertMsg}</Alert>
            </Snackbar> */}
        </>
    );
};

export default OtpPopup;