import { Grid, Modal, Tabs } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import '../MakeModalVarient/MakeModalVarient.css';
import { AlertError } from '../../../../Framework/Toaster/AlertError';
import CloseIcon from '@mui/icons-material/Close';
import { SearchNotFoundErrorMessage, MandatoryFieldsSelectingErrorMessage } from '../../../../Framework/Utils/ErrorMessage';

const AddressRtoModal = ({ open, setOpen, handleClose, rto, formData, setFormData }) => {
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [suggestionState, setSuggestionState] = useState([]);
  const [suggestionCity, setSuggestioncity] = useState([]);
  const [addressRto, setaddressRto] = useState({ state: '', city: '' });
  const [searchTextState, setSearchTextState] = useState('');
  const [searchTextCity, setSearchTextCity] = useState('');
  const [value, setValue] = useState('state');
  const [isSubmited, setIsSubmited] = useState(false);
  const [count, setCount] = useState(0);
  const [errorr, setErrorr] = useState(false);

  const handleChange = (event, newValue) => {
    setErrorr(false);
    setValue(newValue);
  };

  //********/ useEffect for separating state city and rtocode from api/********/
  useEffect(() => {
    let statearr = [];
    let cityarr = [];
    rto.length > 0 &&
      rto?.forEach((el) => {
        cityarr.push({ city: el.REGISTERED_CITY_NAME, rtoCode: el['RTO Code'] });
        statearr.push(el.registered_state_name);
      });
    statearr.sort();
    setState([...new Set(statearr)]);
    setSuggestionState([...new Set(statearr)]);
    setCount(count + 1);
    cityarr.sort(function (a, b) {
      if (a.city.toLowerCase() < b.city.toLowerCase()) {
        return -1;
      }
      if (a.city.toLowerCase() > b.city.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setCity([...new Map(cityarr.map((item) => [item['rtoCode'], item])).values()]);
    setSuggestioncity([...new Map(cityarr.map((item) => [item['rtoCode'], item])).values()]);
  }, []);

  //********/ useEffect for searching based on state city and rtocode/********/

  useEffect(() => {
    if (value == 'state' && count > 0) {
      const suggestionArr = state
        ?.filter((item) => {
          return item.toLowerCase().indexOf(searchTextState.toLowerCase()) !== -1 ? true : false;
        })
        .map((item) => item);
      if (suggestionArr.length > 0) {
        setSuggestionState(suggestionArr);
        setErrorr(false);
      } else {
        if (searchTextState.length == 0) {
          setErrorr(false);
          setSuggestionState(state);
        } else {
          setErrorr(true);
        }
      }
    } else if (count > 0) {
      let suggestionArr = city
        ?.filter((item) => {
          if (item.city.toLowerCase().indexOf(searchTextCity.toLowerCase()) !== -1) {
            return true;
          } else if (item.rtoCode.toLowerCase().indexOf(searchTextCity.toLowerCase()) !== -1) {
            return true;
          } else {
            return false;
          }
        })
        .map((item) => item);
      if (suggestionArr.length > 0) {
        setErrorr(false);
        setSuggestioncity(suggestionArr);
      } else {
        if (searchTextCity.length == 0) {
          setErrorr(false);
          setSuggestioncity(city);
        } else {
          setErrorr(true);
        }
      }
    }
  }, [searchTextCity, searchTextState]);

  const handleChangeMakeModalVarient = (el) => {
    let obj = { ...addressRto, [value]: el };
    setErrorr(false);
    setaddressRto(obj);
    if (value == 'state') {
      setValue('city');
      let cityarr = [];
      rto?.forEach((el) => {
        if (obj.state == el.registered_state_name) {
          cityarr.push({ city: el.REGISTERED_CITY_NAME, rtoCode: el['RTO Code'] });
        }
      });
      cityarr.sort(function (a, b) {
        if (a.city.toLowerCase() < b.city.toLowerCase()) {
          return -1;
        }
        if (a.city.toLowerCase() > b.city.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setCity([...new Map(cityarr.map((item) => [item['rtoCode'], item])).values()]);
      setSuggestioncity([...new Map(cityarr.map((item) => [item['rtoCode'], item])).values()]);
    }
  };

  const handleApply = () => {
    let applyObj = { ...formData };
    applyObj.rc.rtoCode = addressRto.city.rtoCode;
    applyObj.vehicle.vehicleRegisteredState = addressRto.state;
    applyObj.vehicle.vehicleRegisteredCity = addressRto.city.city;
    applyObj.vehicle.vehicleRegisteredCity = addressRto.city.city;
    if (addressRto.state && addressRto.city.city) {
      setFormData(applyObj);
      setOpen(false);
    } else {
      setIsSubmited(true);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="modal-container">
      <Grid container className="make-modal-varient-main-container">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/*******Tabs for Switching Modal content**********/}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Tabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary" aria-label="secondary tabs example" className="make-modal-varient-tab-parent">
              <Tab value="state" label="State" className="make-modal-varient-tab-label" />
              <Tab value="city" label="City & RTO Code" className="make-modal-varient-tab-label" />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="make-modal-varient-suggestion-div">
            {/****** Based On Tab Value Model will Render********/}

            {value == 'state' ? (
              <Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="srach-Box-parent-div">
                    <svg class="icon" aria-hidden="true" viewBox="0 0 24 24">
                      <g>
                        <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                      </g>
                    </svg>
                    <input type="text" value={searchTextState} className="address-search-box" onChange={(e) => setSearchTextState(e.target.value)} placeholder="Search By State Name" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container gap={5} className="state-city-modal-parent" justifyContent="center" alignItems="center">
                    {errorr ? (
                      <div style={{ color: 'red', marginTop: '10px', fontWeight: '600', textAlign: 'center' }}>{SearchNotFoundErrorMessage}</div>
                    ) : (
                      suggestionState?.map((el, i) => (
                        <Grid
                          item
                          xs={5}
                          md={3}
                          className="make-modal-varient-eachelement"
                          style={addressRto.state == el ? { backgroundColor: '#31a0d8', color: 'white', textAlign: 'center' } : { textAlign: 'center' }}
                          onClick={() => {
                            handleChangeMakeModalVarient(el);
                          }}
                          key={i}
                        >
                          {el}
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="srach-Box-parent-div">
                    <svg class="icon" aria-hidden="true" viewBox="0 0 24 24">
                      <g>
                        <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                      </g>
                    </svg>
                    <input type="text" value={searchTextCity} className="address-search-box" onChange={(e) => setSearchTextCity(e.target.value)} placeholder="Search By City or RTO Code" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container gap={4} className="state-city-modal-parent" justifyContent="center" alignItems="center">
                    {errorr ? (
                      <div style={{ color: 'red', marginTop: '10px', fontWeight: '600', textAlign: 'center' }}>{SearchNotFoundErrorMessage}</div>
                    ) : (
                      suggestionCity?.map((el, i) => (
                        <Grid
                          item
                          xs={5}
                          md={3}
                          className="make-modal-varient-eachelement"
                          style={addressRto.city.city == el.city && addressRto.city.rtoCode == el.rtoCode ? { backgroundColor: '#31a0d8', color: 'white', textAlign: 'center' } : { textAlign: 'center' }}
                          onClick={() => {
                            handleChangeMakeModalVarient(el);
                          }}
                          key={i}
                        >
                          {`${el.city}   ,${el.rtoCode}`}
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <CloseIcon sx={{ cursor: 'pointer', position: 'absolute', right: '20px', top: '20px' }} onClick={() => setOpen(false)} />
        </Grid>

        {/****** Apply All Make Modal Varient user Selected********/}

        {value == 'city' ? (
          <button className="make-modal-varient-apply-button" onClick={handleApply}>
            Apply
          </button>
        ) : (
          <div />
        )}

        {/****** Alert showing if any feild missed for selecting********/}

        {isSubmited ? <AlertError open={isSubmited} setOpen={setIsSubmited} message={MandatoryFieldsSelectingErrorMessage} /> : ''}
      </Grid>
    </Modal>
  );
};

export default AddressRtoModal;
