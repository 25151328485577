import "../TestiMonials/TestiMonials.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, CardContent, CardMedia, Stack, Typography } from "@mui/material";
 
// import simpleAdds from "../../Images/Working-rafiki.svg";
import simpleAdds from "../../Images/Working-rafiki 1.svg";
 
const AddsAdmin = () => {
  const testimonials = [
    {
      id: 1,
      name: "Shivam",
      description: "Thanking you very much for better policy guidance.",
      photo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0khaUcbpblqXKUuxIpxyGB9VqRKmENQZWjbk8uXGEIg&s",
    },
    {
      id: 2,
      name: "Rohan",
      description: "Happy to be a part of LMV family",
      photo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0khaUcbpblqXKUuxIpxyGB9VqRKmENQZWjbk8uXGEIg&s",
    },
    {
      id: 3,
      name: "Ravi",
      description: "I did not even need help from an agent! This is very good!",
      photo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0khaUcbpblqXKUuxIpxyGB9VqRKmENQZWjbk8uXGEIg&s",
    },
    // Add more testimonials as needed
  ];
 
  const settings = {
    dots: true,
    centerMode: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
 
  return (
    <Slider {...settings}>
      {testimonials.map((testimonial) => (
        <div key={testimonial.id}>
          <Card elevation={3} sx={{ mx: 2 }}>
            <Stack direction="row">
              <CardMedia
                sx={{ width: 100 }}
                component="img"
                image={simpleAdds}
                alt="Live from space album cover"
              />
              <CardContent sx={{ flex: 1 }}>
                <Typography component="div" variant="h5">
                  Live From Space
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Mac Miller
                </Typography>
              </CardContent>
            </Stack>
          </Card>
        </div>
      ))}
    </Slider>
  );
};
 
export default AddsAdmin;