import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  FormHelperText,
  Typography,
  Grid,
  Paper,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { fetchNewUser, getPagesPermissions } from "../../api/adminService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setError as setErrorMessage } from "../../../../Redux/action-creators/errorAction";
import { setSuccessMessage } from "../../../../Redux/action-creators/successAction";

const PermissionsForm = () => {
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagesLoading, setPagesLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchPermissions = async () => {
    try {
      setPagesLoading(true);
      const response = await getPagesPermissions();
      setPermissions(response.data);
    } catch (error) {
      dispatch(setErrorMessage("Failed to fetch permissions:", error));
    } finally {
      setPagesLoading(false);
    }
  };

  useEffect(() => {
    fetchPermissions();
  }, []);

  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid },
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: Object.keys(permissions).reduce((acc, key) => {
      acc[key] = permissions[key].reduce((pAcc, perm) => {
        pAcc[perm] = false;
        return pAcc;
      }, {});
      return acc;
    }, {}),
  });

  const data = watch();

  const newEmployeeData = useSelector(
    (state) => state.adminReducer.NewEmployee
  );

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const hasViewPermission = Object.values(data).some((perms) => perms.view);

    if (!hasViewPermission) {
      setError("view", {
        type: "manual",
        message: "At least one 'view' permission is required.",
      });
      return;
    } else {
      setError("view", { type: "manual", message: null });
      await trigger();
      try {
        setLoading(true);
        newEmployeeData.PermissionsForm = data;
        await fetchNewUser(newEmployeeData);
        dispatch(setSuccessMessage('Employee Added Successfully !'));
        navigate("/employee/useridmanagement");
      } catch (error) {
       dispatch(setErrorMessage(error));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (Object.values(data).some((perms) => perms.view)) {
      clearErrors("view");
    }
  }, [data, clearErrors]);

  return (
    <>
      {pagesLoading ? (
        <CircularProgress
          size={50}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            Permissions
          </Typography>
          <Grid container columnSpacing={5}>
            {Object.keys(permissions).map((page) => {
              const viewChecked = data[page]?.view || false;

              return (
                <Grid item xs={12} sm={6} md={4} key={page}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: 2,
                      marginBottom: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ marginBottom: 1, textDecoration: "underline" }}
                    >
                      {page.replace(/_/g, " ").toUpperCase()}
                    </Typography>
                    <FormGroup>
                      <Grid container>
                        {permissions[page].map((perm) => (
                          <Grid item key={`${page}-${perm}`}>
                            <Controller
                              name={`${page}.${perm}`}
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...field}
                                      disabled={perm !== "view" && !viewChecked}
                                    />
                                  }
                                  label={perm}
                                />
                              )}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
          {errors.view && (
            <FormHelperText error>{errors.view.message}</FormHelperText>
          )}
          <Grid container justifyContent="center" alignItems="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading || !isValid}
              endIcon={loading ? null : <SendIcon />}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Grid>
        </form>
      )}
    </>
  );
};

export default PermissionsForm;
