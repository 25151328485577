export const carReqObj = {
  enquiryId: 'LMV_QQ_PVT_CAR_PACKAGE_01',
  user: {
    login: '',
    userType: ''
  },
  response:{
    org_id:"",
    posp_id:""
  },
  CustomerResponse  :{
    customer_id :""
  },
 EmployeePermission:{
   user_Id:""
 },

  CustomerLoginResponse:"",
  Agent_ID :"",


  profile: {
    org: {
      orgID: '100',
      orgName: 'LMV FINANCE SERVICE'
    },
    agent: {
      agentId: '1000',
      mobileNumber: '',
      otpStatus: '',
      loginStatus: ''
    },
    customer: {
      customerId: '',
      policyYear: '',
      policyType: '', //2w car cv
      policyStatus: '', // QUT/PRS/PAY/KYC/COM
      kycLink: '',
      paymentLink: '',
      pdfLink: '',
      policyNumber: '',
      proposalNumber:'',
      applicationId: '',
      phone: '',
      otpStatus: '',
      rcNumber: '',
      correlationId:''
    },
    navigation: '',
    applicationId: '',
    number: '',
    agentId: '1000',
  },
  rc: {
    number: '',
    registrationDate: '',
    name: '',
    rtoCode: '',
    status: 'ACTIVE',
    email: '',
    phone: '',
    dob: '',
    gender: '',
    martialStatus: ''
  },

  vehicle: {
    vehicleType: 'Car',
    vehicleCatagory:'',
    isVehicleNew: false,
    isVehicleWithoutNumber: false,
    vehicleRegisteredState: '',
    vehicleRegisteredCity: '',
    vehicleMaincode: '', //ADDED
    manufactureDate: '', //Added
    category: '',
    engineNumber: '',
    chasisNumber: '',
    makerModel: '',
    variant: '',
    vehicleCc : '',
    makerDescription: '',
    model: '',
    fuelType: '',
    permitType: 'PRIVATE',
    usageType: '',
    registrationDate: '',
    loanProviderName: '',
    pucNumber: '',
    pucExpiryDate: '',
    vehicleIDV: {
      idv: '',
      defaultIdv: '',
      minimumIdv: '',
      maximumIdv: ''
    }
  },

  insurance: {
    TransactionID:'',
    bajajTransactionId:'',
    correlationId:'',
    breakinPin:'',
    ilKycRef:'',
    ckycId:'',
    isZeroDep:'',
    isRTICover:'',
    vehcileAge:"",
    presentNcbValue: '0%',
    policyType: 'COMPREHENSIVE',
    insuranceProductCode: '', //ADDED
    subInsuranceProductCode: '', //ADDED
    policyHolderType: 'INDIVIDUAL', //ADDED
    existingComprehensivePolicy: '',
    claimsMade: false,
    ncbValue: 'ZERO',
    instrumentNumber:"",
    providerName:'',
    providerNames: ['godigit','hdfc','icici', 'bajaj' ],
    netPremium: '',
    grossPremium: '',
    checksum: '123456789',
    authCode:'',
    PGtransactionId:"",
    MerchantId:"",
    paymentId:"",
    breakinId:"",
    coverages: {
      voluntaryDeductible: null,
      thirdPartyLiability: {
        isTPPD: false,
        netPremium:"",
      },
      ownDamage: {
        discount: {
          userSpecialDiscountPercent: 0,
          discounts: []
        },
        surcharge: {
          loadings: []
        },
        netPremium:"",
      },
      personalAccident: {
        selection: false,
        insuredAmount: 0,
        coverTerm: null
      },
      handiCapDiscount:{
        selection: false,
      },
      accessories: {
        cng: {
          selection: false,
          insuredAmount: 0
        },
        electrical: {
          selection: false,
          insuredAmount: 0
        },
        nonElectrical: {
          selection: false,
          insuredAmount: 0
        }
      },
      addons: {
        partsDepreciation: {
          claimsCovered: null,
          selection: false
        },
        roadSideAssistance: {
          selection: false
        },
        engineProtection: {
          selection: false
        },
        tyreProtection: {
          selection: false
        },
        rimProtection: {
          selection: false
        },
        returnToInvoice: {
          selection: false
        },
        consumables: {
          selection: false
        },
        lossOfPersonalBelongings: {
          selection: false
        }
      },

      legalLiability: {
        paidDriverLL: {
          selection: false,
          insuredCount: null
        },
        employeesLL: {
          selection: false,
          insuredCount: null
        },
        unnamedPaxLL: {
          selection: false,
          insuredCount: null
        },
        cleanersLL: {
          selection: false,
          insuredCount: null
        },
        nonFarePaxLL: {
          selection: false,
          insuredCount: null
        },
        workersCompensationLL: {
          selection: false,
          insuredCount: null
        }
      },
      unnamedPA: {
        unnamedPax: {
          selection: false,
          insuredAmount: null,
          insuredCount: null
        },
        unnamedPaidDriver: {
          selection: false,
          insuredAmount: null,
          insuredCount: null
        },
        unnamedHirer: {
          selection: false,
          insuredAmount: null,
          insuredCount: null
        },
        unnamedPillionRider: {
          selection: false,
          insuredAmount: null,
          insuredCount: null
        },
        unnamedCleaner: {
          selection: false,
          insuredAmount: null,
          insuredCount: null
        },
        unnamedConductor: {
          selection: false,
          insuredAmount: null,
          insuredCount: null
        }
      }
    },

    nomminee: {
      name: '',
      dob: '',
      relation: ''
    },

    kyc: {
      doctype: 'PAN_CARD', //AADHAR_CARD
      docNumber: '',
      consent: false,
      addresses: {
        houseNumber: '',
        street: '',
        state: '', //27state code
        district: '',
        city: '',
        country: 'IN',
        pincode: ''
      },
      pehchanId: '123456789'
    }
  },

  previousPolicyDetails: {
    isPreviousInsurerKnown: true,
    policy: true,
    policyCompany: '',
    policyNumber: '',
    policyStartDate: '',
    policyEndDate: '',
    policyTPCompany: '',
    policyTPNumber: '',
    policyTPStartDate: '',
    policyTPEndDate: '',
    policyType: '1OD_3TP',
  },
  motorQuestions: {
    furtherAgreement: null,
    selfInspection: false,
    financer: null
  },
  motorBreakIn: {
    isBreakin: false,
    isPreInspectionWaived: false,
    isPreInspectionCompleted: false,
    isDocumentUploaded: false
  },
  documents: []
};
// globalObj
export const AddToLocalStorage = (keyName, data) => {
  localStorage.setItem(keyName, JSON.stringify(data));
};

export const GetDataLocalStorage = (keyName) => {
  let data = JSON.parse(localStorage.getItem(keyName));
  return data;
};
