import axios from "axios";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import { setError as setErrorMessage } from "../../../../Redux/action-creators/errorAction";
import { setSuccessMessage } from "../../../../Redux/action-creators/successAction";
import { useDispatch } from "react-redux";

const EmployeeRevenue = () => {
  const [revenue, setRevenue] = useState([
    {
      policy_id: "170",
      product_type: "MOTOR",
      product_subtype: "BIKE",
      revenue: "45",
      employee_id: null,
      employee_revenuee: "40.5",
    },
  ]);
  const [isloading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const getEmployeeRevenue = async () => {
    setLoading(true);
 try {
  const res = await axios.get(
    "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/employeerevenue"
  );
  if (res.status === 200) {
    setRevenue([...res.data]);
  }
  setLoading(false);
 } catch (error) {
  dispatch(setErrorMessage(error));
 }
  };
  useEffect(() => {
    getEmployeeRevenue();
  }, []);

  const columns = [
    {
      field: "policy_id",
      headerName: "Policy ID",
      flex: 1,
    },
    {
      field: "employee_id",
      headerName: "Employee ID",
      flex: 1,
    },
    {
      field: "product_type",
      headerName: "Product Type",
      flex: 1,
    },
    {
      field: "product_subtype",
      headerName: "Product Subtype",
      flex: 1,
    },
    {
      field: "employee_revenuee",
      headerName: "Employee Revenue",
      flex: 1,
      renderCell: (params) => <>{Math.round(params.value)}</>,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      flex: 1,
      renderCell: (params) => <>{Math.round(params.value)}</>,
    },
  ];

  return isloading ? (
    <CircularProgress
              size={50}
              style={{ position: "absolute", top: "50%", left: "50%" }}
            />
  ) : (
    <Box sx={{ width: "100%",mt:3 }}>
      <DataGrid
        rowSelection
        rows={revenue}
        columns={columns}
        getRowId={(row) => row.policy_id}
      />
    </Box>
  );
};

export default React.memo(EmployeeRevenue);
