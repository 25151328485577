import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { useForm, Controller } from "react-hook-form";
import {
  existUserUpdatePermissions,
  getReportingManager,
  getUsers,
} from "../../api/adminService";
import { useDispatch, useSelector } from "react-redux";
import { setError as setErrorMessage } from "../../../../Redux/action-creators/errorAction";
import { setSuccessMessage } from "../../../../Redux/action-creators/successAction";

const pagesArr = {
  home: { view: true, add: true, delete: true, edit: true },
  addbranch: { view: false, add: false, delete: false, edit: false },
  useridmanagement: { view: false, add: false, delete: false, edit: false },
  policypurchase: { view: false, add: false, delete: false, edit: false },
  bussiness: { view: false, add: false, delete: false, edit: false },
  claims: { view: false, add: false, delete: false, edit: false },
  finance: { view: false, add: false, delete: false, edit: false },
  revenuemanagement: { view: false, add: false, delete: false, edit: false },
  agentactivationpanel: { view: false, add: false, delete: false, edit: false },
  renewnotificationpanel: {
    view: false,
    add: false,
    delete: false,
    edit: false,
  },
  renewpanel: { view: false, add: false, delete: false, edit: false },
  salesmanagement: { view: false, add: false, delete: false, edit: false },
  quoteperformance: { view: false, add: false, delete: false, edit: false },
};

const roleOptions = {
  Administrative: [
    "Admin",
    "Principal Officer",
    "Managing Director",
    "Chief Executive Officer",
    "Chief Administrative Officer",
    "HR Admin",
    "HR Executive",
    "HR Manager",
    "HR Recruiter",
  ],
  Operations: ["Operations Manager", "Backend Executive", "Process Manager"],
  Sales: [
    "Zonal Head",
    "State Head",
    "Business Head",
    "Area Manager",
    "Area Head",
    "Associate Area Manager",
    "Sales Manager",
    "Associate Sales Manager",
    "Sales Officer",
    "Regional Manager",
    "Branch Manager",
    "Cluster Manager",
    "Territory Manager",
  ],
  Finance: ["Account Manager", "Account Officer"],
  Telesales: ["Telecaller"],
};

const PermissionsDialog = ({ open, permissionsData, setOpen, setLoad }) => {
  let {
    emp_name,
    empid,
    branch,
    department,
    district,
    email,
    marital_status,
    phone,
    resportingmanager,
    role,
    salary,
    state,
    status,
    zone,
  } = permissionsData || {};
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...pagesArr,
      emp_name,
      empid,
      branch,
      department,
      district,
      email,
      marital_status,
      phone,
      resportingmanager,
      role,
      salary,
      state,
      status,
      zone,
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await getReportingManager();
        setEmployees(response.data);
      } catch (error) {
        dispatch(setErrorMessage("Failed to fetch employees:", error));
      }
    };

    if (open && permissionsData) {
      fetchEmployees();
    }
  }, [open, permissionsData]);

  useEffect(() => {
    if (employees.length > 0 && permissionsData) {
      const updatedPermissions = { ...pagesArr };
      permissionsData.pages.forEach((page) => {
        const pageName = page.path.toLowerCase().replace(/\s+/g, "_");
        if (updatedPermissions[pageName]) {
          updatedPermissions[pageName] = {
            view: page.permissions.view || false,
            add: page.permissions.add || false,
            delete: page.permissions.delete || false,
            edit: page.permissions.edit || false,
          };
        }
      });

      const formattedReportingManager = employees.find(
        (emp) => emp.empid === permissionsData.resportingmanager?.empid
      );

      reset({
        ...updatedPermissions,
        emp_name,
        empid,
        branch,
        department,
        district,
        email,
        marital_status,
        phone,
        resportingmanager: formattedReportingManager || null,
        role,
        salary,
        state,
        status,
        zone,
      });
    }
  }, [employees, permissionsData, reset]);
  const selector = useSelector((state) => state.adminReducer.loginData.Role);

  const onSubmit = async (data) => {
    const payload = { ...data, empid };
    try {
      setLoading(true);
      const res = await existUserUpdatePermissions(empid, payload);
      if (res.data.status === 200) {
        const response = await getUsers(selector);
        if (response.data.status === 200) {
          setOpen(false);
          setLoad(true);
          dispatch(setSuccessMessage('Employee Permissions Updated Successfully'))
        }
      }
    } catch (err) {
      dispatch(setErrorMessage(err));

    } finally {
      setLoading(false);
    }
  };

  const DepartMent = watch("department");
  const State = watch("state");
  const District = watch("district");

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          textAlign="center"
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            textDecoration: "underline",
          }}
        >
          Update User
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* User Details Section */}
            <Grid container mt={1} rowSpacing={2} columnSpacing={1}>
              <Grid item xs={6}>
                <TextField
                  label="Employee Name"
                  {...control.register("emp_name")}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Employee Phone"
                  {...control.register("phone", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[6-9]\d{9}$/,
                      message:
                        "Phone number must start with 6, 7, 8, or 9 and be exactly 10 digits long",
                    },
                  })}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    maxLength: 10,
                  }}
                  onInput={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    if (value.length > 0 && !/^[6-9]/.test(value)) {
                      e.target.value = "";
                    } else {
                      e.target.value = value;
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="department"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Department"
                      fullWidth
                      variant="outlined"
                      select
                    >
                      <MenuItem value="Administrative">
                        Administrative{" "}
                      </MenuItem>
                      <MenuItem value="Operations">Operations</MenuItem>
                      <MenuItem value="Sales">Sales</MenuItem>
                      <MenuItem value="Finance">Finance</MenuItem>
                      <MenuItem value="Telesales">TeleSales</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Role"
                      fullWidth
                      variant="outlined"
                      select
                    >
                      {roleOptions[DepartMent]?.map((role) => (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={Boolean(errors.resportingmanager)}
                >
                  <Controller
                    name="resportingmanager"
                    control={control}
                    rules={{
                      required: "A reporting manager must be selected",
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Autocomplete
                        options={employees}
                        disableCloseOnSelect
                        getOptionLabel={(option) =>
                          `${option.emp_name} (${option.empid}) - ${option.role}`
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.empid === value?.empid
                        }
                        onChange={(event, newValue) => onChange(newValue)}
                        onBlur={onBlur}
                        value={value || null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Reporting Manager List"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                  {errors.resportingmanager && (
                    <FormHelperText>
                      {errors.resportingmanager.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="email"
                  label="Email"
                  fullWidth
                  {...control.register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@lmvinsurance\.com$/,
                      message:
                        "Email must be from the domain 'lmvinsurance.com'",
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email?.message || ""}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="zone"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Zone"
                      fullWidth
                      variant="outlined"
                      select
                    >
                      {/* <MenuItem value="north">North</MenuItem> */}
                      <MenuItem value="South">South</MenuItem>
                      {/* <MenuItem value="east">East</MenuItem>
                    <MenuItem value="west">West</MenuItem> */}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="State"
                      fullWidth
                      variant="outlined"
                      select
                    >
                      <MenuItem value="Telangana">Telangana</MenuItem>
                      <MenuItem value="Andhra Pradesh">Andhra Pradesh</MenuItem>
                      <MenuItem value="Karnataka">Karnataka</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="district"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="District"
                      fullWidth
                      variant="outlined"
                      select
                    >
                      {State === "Telangana" && (
                        <MenuItem value="Hyderabad">Hyderabad</MenuItem>
                      )}
                      {State === "Telangana" && (
                        <MenuItem value="Warangal">Warangal</MenuItem>
                      )}
                      {State === "Andhra Pradesh" && (
                        <MenuItem value="Rajahmundry">Rajahmundry</MenuItem>
                      )}
                      {State === "Andhra Pradesh" && (
                        <MenuItem value="Anantapur">Anantapur</MenuItem>
                      )}
                      {State === "Andhra Pradesh" && (
                        <MenuItem value="Tirupati">Tirupati</MenuItem>
                      )}
                      {State === "Andhra Pradesh" && (
                        <MenuItem value="Vijayawada">Vijayawada</MenuItem>
                      )}
                      {State === "Andhra Pradesh" && (
                        <MenuItem value="Visakhapatnam">Visakhapatnam</MenuItem>
                      )}
                      {State === "Karnataka" && (
                        <MenuItem value="Bangalore">Bangalore</MenuItem>
                      )}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="branch"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Branch"
                      fullWidth
                      variant="outlined"
                      select
                    >
                      {District === "Hyderabad" && (
                        <MenuItem value="Punjagutta">Punjagutta</MenuItem>
                      )}
                      {District === "Warangal" && (
                        <MenuItem value="Hanamkonda">Hanamkonda</MenuItem>
                      )}
                      {District === "Rajahmundry" && (
                        <MenuItem value="Kamabalpet">Kamabalpet</MenuItem>
                      )}
                      {District === "Anantapur" && (
                        <MenuItem value="sri_new_ganesh_complex">
                          Sri New Ganesh Complex
                        </MenuItem>
                      )}
                      {District === "Tirupati" && (
                        <MenuItem value="Ramanjaru Circle">
                          Ramanjaru Circle
                        </MenuItem>
                      )}
                      {District === "Vijayawada" && (
                        <MenuItem value="Siddhartha nagar">
                          Siddhartha Nagar
                        </MenuItem>
                      )}
                      {District === "Visakhapatnam" && (
                        <MenuItem value="Sankaramatam Road">
                          Sankaramatam Road
                        </MenuItem>
                      )}
                      {District === "Bangalore" && (
                        <MenuItem value="Ulsoor">Ulsoor</MenuItem>
                      )}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Employee Salary"
                  {...control.register("salary", {
                    required: "Salary is required",
                    pattern: {
                      value: /^[1-9]\d*$/,
                      message:
                        "Salary must be a positive number greater than 0",
                    },
                  })}
                  fullWidth
                  variant="outlined"
                  onInput={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    if (value.length > 0 && value[0] === "0") {
                      e.target.value = "";
                    } else {
                      e.target.value = value;
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Status"
                      fullWidth
                      variant="outlined"
                      select
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="marital_status"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Marital Status"
                      fullWidth
                      variant="outlined"
                      select
                    >
                      <MenuItem value="married">Married</MenuItem>
                      <MenuItem value="single">Unmarried</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>

            <Divider style={{ margin: "16px 0" }} />
            <Typography variant="h3" textAlign="center">
              Pages Permission
            </Typography>

            {/* Permissions Checkboxes Section */}
            <Grid container spacing={2}>
              {Object.keys(pagesArr).map((page) => (
                <Grid item xs={12} key={page}>
                  <Typography variant="h6" gutterBottom>
                    {page.replace(/_/g, " ").toUpperCase()}
                  </Typography>
                  <Grid container spacing={1}>
                    {Object.keys(pagesArr[page]).map((permission) => (
                      <Grid item xs={6} sm={3} key={permission}>
                        <Controller
                          name={`${page}.${permission}`}
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={(e) =>
                                    field.onChange(e.target.checked)
                                  }
                                />
                              }
                              label={permission}
                            />
                          )}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <DialogActions>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <Button
                  onClick={() => setOpen(false)}
                  endIcon={<CancelIcon />}
                  variant="contained"
                  disabled={loading}
                >
                  Back
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  endIcon={<SaveIcon />}
                  type="submit" // Using form submit here
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save"}
                </Button>
              </Stack>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PermissionsDialog;
