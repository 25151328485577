import React, { useState, useEffect } from 'react';
import { Autocomplete, Box, Checkbox, CircularProgress, Grid, MenuItem, Paper, Select, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useSelector, useDispatch } from 'react-redux';
import { InsurerNamesSuccessRequest, RelationSuccessRequest, getCityStateWithPincode, getInsurerName, getKycStatus, getRelations, getSuccessPincode } from '../../../Redux/Action';
import { aadhaarValidate, dateOfBirthValidateNomminee, drivingLicenseValidate, emailValidate, FullNameValidate, mobileValidate, panCardValidate, proposalObjErrorCheck } from '../../validation/ValidationSchema';
import moment from 'moment';
import { AddToLocalStorage, GetDataLocalStorage } from '../../../Framework/GlobalObj';
import axios from 'axios';
import { AlertError } from '../../../Framework/Toaster/AlertError';
import { MandatoryFieldsErrorMessage } from '../../../Framework/Utils/ErrorMessage';
import { currencyFormat } from '../../../Framework/Utils/CurrencyFormat';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import '../Vehicle Detail/VehicleDetails.css';
import Tooltip from '@mui/material/Tooltip';
import { vehicle_api } from '../../../Config';
import { useNavigate, useLocation } from 'react-router-dom';

const ProposalDetails = () => {
  const insurerName = useSelector((store) => store.motorReducer.insurerNames);
  const relation = useSelector((store) => store.motorReducer.relation);
  const pincodeData = useSelector((store) => store.motorReducer.pincodeData);
  const kycData = useSelector((store) => store.motorReducer.kycData);
  const [nommineeChecked, setNommineeChecked] = useState(false);
  const [isLoanProvider, setIsLoanProvider] = useState('no');
  const [ispuc, setIsPuc] = useState('no');
  const [isSubmited, setIsSubmited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [req, setReq] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errorMssg, setErrorMsg] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();



  useEffect(() => {
    let insurenceName = GetDataLocalStorage('InsurenceCompanyName');
    let pincode = GetDataLocalStorage('pincodeData');
    let relation = GetDataLocalStorage('relationData');
    insurenceName ? dispatch(InsurerNamesSuccessRequest(insurenceName)) : dispatch(getInsurerName());
    relation ? dispatch(RelationSuccessRequest(relation)) : dispatch(getRelations());
    pincode ? dispatch(getSuccessPincode(pincode)) : dispatch(getCityStateWithPincode());
    let reqObj = GetDataLocalStorage('globalObj') != null ? GetDataLocalStorage('globalObj') : {};

    let pincodeArr =
      pincode?.length > 0 &&
      pincode?.filter((el) => {
        return el['Pin Code'] == Number(reqObj?.insurance?.kyc?.addresses?.pincode);
      });
    if (pincodeArr.length > 0) {
      let objReq = { ...reqObj };
      objReq.insurance.kyc.addresses.pincode = Number(pincodeArr[0]['Pin Code']);
      objReq.insurance.kyc.addresses.city = pincodeArr[0]['City'].toUpperCase();
      objReq.insurance.kyc.addresses.state = pincodeArr[0]['State'].toUpperCase();
      objReq.insurance.kyc.addresses.district = pincodeArr[0]['District'].toUpperCase();
      setReq(objReq);
    } else {
      setReq(reqObj);
    }
  }, []);

  const bajajBreakin = async () => {
    if (req?.insurance?.ProposalDetails === 'BAJAJ' && req?.previousPolicyDetails?.policyEndDate && new Date(req?.previousPolicyDetails?.policyEndDate) < new Date() && req?.vehicle?.isVehicleNew === false) {
      try {
        const response = await axios.post(`${vehicle_api}/insurance/v1/breakin`, req);
        console.log(response, "response data for breakin")
        req.insurance.breakinPin = response.data.pinNumber;
        AddToLocalStorage('globalObj', req);
      } catch (error) {
        console.error('Error calling breakin API:', error);
      }
    }
  }
  const storePremiumsInGlobalObj = () => {
    const responseData = location?.state;
    console.log(responseData, "dataofPremiums");

    if (Array?.isArray(responseData)) {
      console.log(responseData, "providers");
  
      responseData?.forEach((provider) => {
        console.log(provider, "provider");
  
        // Safely access premiums, IDV, and correlationId
        const thirdPartyPremium = provider?.coverages?.thirdPartyLiablity?.netPremium || null;
        const ownDamagePremium = provider?.coverages?.ownDamage?.netPremium || null;
        const vehicleIdv = provider?.vehicleIDV?.vehicleIDV?.idv || null;
        const correlationId = provider?.correlationId || null;
  
        if (
          thirdPartyPremium !== null ||
          ownDamagePremium !== null ||
          vehicleIdv !== null ||
          correlationId !== null
        ) {
          // Ensure `req` is defined
          if (!req) {
            req = {};
          }
  
          // Ensure `req.insurance` and its nested properties are initialized
          if (!req.insurance) {
            req.insurance = { coverages: { thirdPartyLiability: {}, ownDamage: {} } };
          } else {
            req.insurance.coverages = req.insurance.coverages || {
              thirdPartyLiability: {},
              ownDamage: {},
            };
          }
  
          // Ensure `req.vehicle` and its nested properties are initialized
          if (!req.vehicle) {
            req.vehicle = { vehicleIDV: {} };
          }
  
          // Ensure `req.profile.customer` is initialized
          if (!req.profile) {
            req.profile = { customer: {} };
          } else {
            req.profile.customer = req.profile.customer || {};
          }
  
          // Assign values to `req` object
          req.insurance.coverages.thirdPartyLiability.netPremium = thirdPartyPremium;
          req.insurance.coverages.ownDamage.netPremium = ownDamagePremium;
          req.vehicle.vehicleIDV.idv = vehicleIdv;
          req.profile.customer.correlationId = correlationId;
  
          // Add to local storage
          AddToLocalStorage('globalObj', req);
        } else {
          console.log("No valid premiums, IDV, or correlationId found for the provider.");
        }
      });
    } else {
      console.log("No providers found or responseData is not an array.");
    }
  };



  useEffect(() => {
    storePremiumsInGlobalObj();
  }, [storePremiumsInGlobalObj])

  const handleSubmit = async () => {
    setIsSubmited(true);
    req.profile.navigation = '/car/proposal';
    req.profile.customer.policyStatus = 'PRS';
    let objReq = { ...req };
    AddToLocalStorage('globalObj', req);
    if (!req?.vehicle?.isVehicleNew) {
      let insurerCode = insurerName?.filter((el) => {
        if (el.Name == req.previousPolicyDetails.policyCompany) {
          return true;
        } else if (el.godigitShortCode == req.previousPolicyDetails.policyCompany) {
          return true;
        } else {
          return false;
        }
      });
      objReq.previousPolicyDetails.policyCompany = `${insurerCode[0]?.godigitShortCode}`;
    }
    if (req?.vehicle?.isVehicleNew || req?.vehicle?.isVehicleWithoutNumber ? true : Object.values(req?.rc).every((key) => key != '') ? true : false) {
      if (Object.values(req?.insurance?.kyc).every((key) => key != '') && req.vehicle.engineNumber != '' && req.vehicle.chasisNumber != '') {
        if (Object.values(req?.insurance?.kyc?.addresses).every((key) => key != '')) {
          let obj = {
            email: req?.rc?.email,
            pan: req?.insurance?.kyc?.docNumber,
            aadhar: req?.insurance?.kyc?.docNumber,
            driving: req?.insurance?.kyc?.docNumber,
            nomineedob: req?.insurance?.kyc.docNumber,
            doctype: req?.insurance?.kyc?.doctype,
            dob: req?.rc?.dob,
            nomineedob: req?.insurance?.nomminee?.dob
          };
          let errorr = proposalObjErrorCheck(obj);
          if (Object.values(errorr).every((key) => key == '')) {
            if (req?.insurance?.providerName == 'HDFC') {
              axios.post(`${vehicle_api}/insurance/kyc-verified`, req)
                .then((res) => {
                  console.log(res, "response kyc verified")
                  if (res.data.body.data.iskycVerified === 1) {
                    req.insurance.kyc.pehchanId = res.data.body.data.kyc_id;
                    AddToLocalStorage('globalObj', req);
                    let kycId = req.insurance.kyc.pehchanId
                    navigate(`/motor/v1/hdfc/kyc`, { state: { kyc_id: kycId } })
                  } else if (res.data.data.iskycVerified === 0) {
                    let redirect_link = res.data.data.redirect_link
                    req.profile.customer.kycLink = redirect_link;
                    AddToLocalStorage('globalObj', req);
                    window.location.href = redirect_link;
                  }
                })
                .catch((err) => {
                  console.log('err', err);
                });
            } else if (req?.insurance?.providerName === "ICICI") {
              axios
                .post(`${vehicle_api}/insurance/v1/kyc/verification`, req)
                .then((res) => {
                  const kyc_data = res?.data?.kyc_details;
                  req.insurance.ilKycRef = kyc_data?.il_kyc_ref_no;
                  req.insurance.ckycId = kyc_data?.ckyc_number;
                  AddToLocalStorage("globalObj", req);
                  if (res?.data?.statusMessage === "Success") {
                    axios
                      .post(
                        `${vehicle_api}/insurance/v1/motor/policy/proposal`,
                        req
                      )
                      .then((res) => {
                        console.log(res, "console in line number 204")
                        if (res?.data?.status === "Success") {
                          let data = res.data;
                          // req.insurance.grossPremium = data.finalPremium;
                          req.CustomerResponse.customer_id =
                            data.generalInformation.customerId;
                          req.profile.customer.proposalNumber =
                            data.generalInformation.proposalNumber;
                          AddToLocalStorage("globalObj", req);
                          // In case Vehicle Is in Breakin Case
                          if (res?.data?.body?.breakingFlag === true) {
                            axios
                              .post(
                                `${vehicle_api}/icici/v1/generateBreakinID`,
                                req
                              )
                              .then((res) => {
                                console.log(res, "ressss");
                              });
                          }
                          axios
                            .post(
                              `${vehicle_api}/insurance/v1/motor/policy/payment`,
                              req
                            )
                            .then((res) => {
                              console.log(res, "payment response for icici");
                              if (res.status === 200) {
                                const id = res.data;
                                req.insurance.correlationId = id;
                                AddToLocalStorage("globalObj", req);
                                window.location.href = `https://paygate.icicilombard.com/pgi/payment/PaymentMethod?transactionid=${res.data}`;
                              }
                            });
                        }
                      });
                  }
                })
                .catch((err) => {
                  console.log("err", err);
                });
            } else if (req?.insurance?.providerName === 'BAJAJ') {
              if (req?.insurance?.providerName === 'BAJAJ' && req?.previousPolicyDetails?.policyEndDate && new Date(req?.previousPolicyDetails?.policyEndDate) < new Date() && req?.vehicle?.isVehicleNew === false) {
                try {
                  const response = await axios.post(`${vehicle_api}/insurance/v1/breakin`, req);
                  console.log(response, "response data for breakin")
                  req.insurance.breakinPin = response.data.pinNumber;
                  AddToLocalStorage('globalObj', req);
                } catch (error) {
                  console.error('Error calling breakin API:', error);
                }
              }
              axios.post(`${vehicle_api}/insurance/v1/kyc/verification`, req)
                .then((res) => {
                  if (res.status === 200) {
                    axios.post(`${vehicle_api}/insurance/v1/motor/policy/proposal`, req)
                      .then((res) => {
                        console.log(res, "proposal response of bajaj")
                        if (res?.data?.errorcode === 0) {
                          console.log("entered")
                          window.location.href = `https://webservicesint.bajajallianz.com/Insurance/WS/new_cc_payment.jsp?requestId=${req?.insurance?.bajajTransactionId}&Username=webservice@lmv.com&sourceName=WS_MOTOR`
                        }
                      })
                  }
                })
            } else {
              axios({
                method: 'post',
                url: `${vehicle_api}/insurance/v1/motor/policy/proposal`,
                data: objReq
              })
                .then((res) => {
                  console.log('ressssss proposal', res);
                  // let applicationId =JSON.parse(res.data.body)?.body.applicationId;;
                  let applicationId = res?.data?.body?.applicationId;
                  console.log('application', applicationId);
                  req.profile.customer.applicationId = applicationId;
                  req.profile.customer.policyNumber = res?.data?.body?.policyNumber;

                  AddToLocalStorage('globalObj', req);
                  let data = {
                    applicationId,
                    cancelReturnUrl: `https://main.dvip8s9uwum45.amplifyapp.com/motor/payment/failed`,
                    successReturnUrl: `https://main.dvip8s9uwum45.amplifyapp.com/motor/payment/success`,
                    insurance: {
                      providerName: 'GODIGIT'
                    }
                  };
                  if (res?.data?.body?.motorBreakIn?.isBreakin && req?.vehicle?.vehicleType !== 'Bike') {
                    setIsLoading(false);
                    setError(true);
                    setErrorMsg('vehicle is in breakin, please upload the document as per instruction provided in sms');
                  } else {
                    if (res?.data?.statusCode == 200) {

                      axios({
                        method: 'post',
                        url: `${vehicle_api}/insurance/v1/motor/policy/payment`,
                        data: data
                      })

                        .then((res) => {
                          console.log('ressssss payment', res);
                          if (res.data.statusCode !== 400 && res.status == 200) {
                            req.profile.customer.paymentLink = res.data;
                            req.profile.customer.policyStatus = 'PAY';
                            AddToLocalStorage('globalObj', req);
                            setIsLoading(false);
                            window.location.href = res.data;
                          } else {
                            setIsLoading(false);
                            setError(true);
                            setErrorMsg(res?.data?.body?.message);
                          }
                        })
                        .catch((err) => {
                          setIsLoading(false);
                          console.log(err);
                        });
                    } else {
                      setIsLoading(false);
                      setError(true);
                      setErrorMsg(res?.data?.body?.error?.validationMessages[0]);
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            setOpen(true);
            setIsLoading(false);
          }
        } else {
          setOpen(true);
          setIsLoading(false);
        }
      } else {
        setOpen(true);
        setIsLoading(false);
      }
    } else {
      setOpen(true);
      setIsLoading(false);
    }
    if (!req?.vehicle?.isVehicleNew) {
      let insurerCode = insurerName?.filter((el) => {
        if (el.Name == req.previousPolicyDetails.policyCompany) {
          return true;
        } else if (el.godigitShortCode == req.previousPolicyDetails.policyCompany) {
          return true;
        } else {
          return false;
        }
      });
      req.previousPolicyDetails.policyCompany = `${insurerCode[0]?.Name}`;
    }
    bajajBreakin();
  };

  const policyData = useSelector((store) => store?.dashboardReducer?.policy);

  useEffect(() => {
    console.log(errorMssg, 'errorMssg');
    if (errorMssg !== '' && errorMssg?.includes('Duplicate policy already exists')) {
      setError(false);
      dispatch(getKycStatus(errorMssg.split(' ')[4]));
    }
  }, [errorMssg]);

  useEffect(() => {
    if (Object.keys(kycData).length > 0) {
      if (kycData?.body?.paymentStatus == 'PAID' && kycData?.body?.kycVerificationStatus == 'NOT_DONE') {
        let obj = { ...req };
        obj.profile.customer.policyNumber = errorMssg.split(' ')[4];
        AddToLocalStorage('globalObj', obj);
        navigate('/motor/car/payment/success');
      }
    }
  }, [kycData]);

  const handleChangerc = (e, name) => {
    if (e.target.name == 'name') {
      let name1 = e.target.value;
      let name2 = name1.toUpperCase();
      let objrc = { ...req.rc, [e.target.name]: name2 };
      setReq({ ...req, ['rc']: objrc });
    } else if (e.target.name == 'name') {
      let objrc = { ...req.rc, [e.target.name]: e.target.value.toUpperCase() };
      setReq({ ...req, ['rc']: objrc });
    } else {
      let objrc = { ...req.rc, [e.target.name]: e.target.value };
      setReq({ ...req, ['rc']: objrc });
    }
  };

  const handleChangeVehicle = (e, name) => {
    let objVehicle = { ...req.vehicle, [e.target.name]: e.target.value.toUpperCase() };
    setReq({ ...req, ['vehicle']: objVehicle });
  };

  const handleChangePreviousInsurer = (e, name) => {
    if (e.target.name == 'policyEndDate' && new Date(e.target.value) < new Date()) {
      let objInsurer = {
        ...req.previousPolicyDetails,
        [e.target.name]: e.target.value,
        ['isBreakIn']: true
      };
      setReq({ ...req, ['previousPolicyDetails']: objInsurer });
    } else {
      let objInsurer = {
        ...req.previousPolicyDetails,
        [e.target.name]: e.target.value,
        ['isBreakIn']: false
      };
      setReq({ ...req, ['previousPolicyDetails']: objInsurer });
    }
    // }
  };

  const handleChangePreviousInsurerAutocomplete = (e, name) => {
    if (name == 'policyCompany' || name == 'policyTPCompany') {
      let objInsurer = {
        ...req.previousPolicyDetails,
        [name]: e.target.innerHTML
      };
      setReq({ ...req, ['previousPolicyDetails']: objInsurer });
    } else {
      let objInsurer = {
        ...req.previousPolicyDetails,
        [name]: e.target.value
      };
      setReq({ ...req, ['previousPolicyDetails']: objInsurer });
    }
  };

  console.log('reqqq', req);

  const handleChangeNomminee = (e, name) => {
    let objNomminee = {
      ...req.insurance.nomminee,
      [e.target.name]: e.target.value
    };
    let parentobj = { ...req.insurance, ['nomminee']: objNomminee };
    setReq({ ...req, ['insurance']: parentobj });
    // }
  };

  const handleChangeAddress = (e) => {
    if (e.target.name == 'pincode' && e.target.value.length == 6) {
      console.log('pincode', pincodeData);
      let pincodeArr =
        pincodeData.length > 0 &&
        pincodeData?.filter((el) => {
          return el['Pin Code'] == Number(e.target.value);
        });
      if (pincodeArr.length > 0) {
        let objReq = { ...req };
        objReq.insurance.kyc.addresses.pincode = Number(pincodeArr[0]['Pin Code']);
        objReq.insurance.kyc.addresses.city = pincodeArr[0]['City'].toUpperCase();
        objReq.insurance.kyc.addresses.state = pincodeArr[0]['State'].toUpperCase();
        objReq.insurance.kyc.addresses.district = pincodeArr[0]['District'].toUpperCase();
        setReq(objReq);
      } else {
        alert('Please Enter Correct Pincode');
      }
    } else if (e.target.name == 'pincode' && e.target.value.length == 0) {
      let objReq = { ...req };
      objReq.insurance.kyc.addresses.pincode = '';
      objReq.insurance.kyc.addresses.city = '';
      objReq.insurance.kyc.addresses.state = '';
      objReq.insurance.kyc.addresses.district = '';
      setReq(objReq);
    } else {
      let objAddress = {
        ...req.insurance.kyc.addresses,
        [e.target.name]: e.target.value.toUpperCase()
      };
      let objAddressParent = { ...req.insurance.kyc, ['addresses']: objAddress };
      let objAddressContainer = { ...req.insurance, ['kyc']: objAddressParent };
      setReq({ ...req, ['insurance']: objAddressContainer });
    }
  };

  const handleChangeKyc = (e) => {
    let objKyc = { ...req.insurance.kyc, [e.target.name]: e.target.value.toUpperCase() };
    let objKycParent = { ...req.insurance, ['kyc']: objKyc };
    setReq({ ...req, ['insurance']: objKycParent });
  };

  const handleChangeKycConsent = (e) => {
    let objKycConsent = { ...req.insurance.kyc, ['consent']: e.target.checked };
    let objKycParentConsent = { ...req.insurance, ['kyc']: objKycConsent };
    setReq({ ...req, ['insurance']: objKycParentConsent });
  };

  const handleChangeInsurence = (e) => {
    let Obj = { ...req.insurance, [e.target.name]: e.target.value };
    setReq({ ...req, ['insurance']: Obj });
  };

  console.log('req', req);

  if (isLoading) {
    return (
      <Box sx={{ height: '700px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <div>
      <Grid container gap={5} justifyContent="center" className="proposal-kyc-form-container">
        {/* Form */}

        <Grid item xs={12} sm={12} md={8} lg={8}>
          {/* Customer Personal details along with contact and date of birth*/}

          <Grid className="additional-details-container">
            <Grid className="additionaldetails">Personal Details</Grid>
            <Grid container spacing={{ xs: 0, sm: 0, md: 2 }} className="gridenginenumber">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Full Name<span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input type="text" className="enginenumber" placeholder="Eg: Akshay Gupta" name="name" value={req?.rc?.name || ''} onChange={(e) => handleChangerc(e)} />
                <br />
                {isSubmited && req?.rc?.name == '' ? <span className="error-validation">Please Enter Full Name</span> : <span />}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Email<span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input type="text" className="enginenumber" placeholder="Eg: abc@gmail.com" name="email" value={req?.rc?.email || ''} onChange={(e) => handleChangerc(e)} />
                <br />
                {isSubmited && emailValidate(req?.rc?.email) != '' ? <span className="error-validation">{emailValidate(req?.rc?.email)}</span> : <span />}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Marital Status<span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <FormControl className="kyc-proposal-select-martial-status-parent">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="kyc-proposal-select-martial-status enginenumber"
                    name="martialStatus"
                    value={req?.rc?.martialStatus || ''}
                    sx={{ borderRadius: '12px', width: '100%' }}
                    onChange={(e) => handleChangerc(e)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: '10px',
                          fontFamily: 'Bradley Hand'
                        }
                      }
                    }}
                  >
                    <MenuItem value={'single'}>Single</MenuItem>
                    <MenuItem value={'married'}>Married</MenuItem>
                  </Select>
                  {isSubmited && req?.rc?.martialStatus == '' ? <span className="error-validation">Please select Martial Status</span> : <span />}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Mobile<span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input type="number" className="enginenumber" placeholder="Eg: 1234567891" name="phone" value={req?.rc?.phone || ''} onChange={(e) => handleChangerc(e)} />
                <br />
                {isSubmited && mobileValidate(req?.rc?.phone) != '' ? <span className="error-validation">{mobileValidate(req?.rc?.phone)}</span> : <span />}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Gender<span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <FormControl>
                  <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="gender" value={req?.rc?.gender || ''} onChange={(e) => handleChangerc(e)}>
                    <FormControlLabel value="MALE" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Male</span>} />
                    <FormControlLabel value="FEMALE" control={<Radio />} label={<span>Female</span>} />
                  </RadioGroup>
                  <br />
                  {isSubmited && req?.rc?.gender == '' ? <span className="error-validation">Please select Gender</span> : <span />}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className="datePicker">
                <label className="label_input">
                  Date Of Birth
                  <span style={{ color: 'red' }}>* </span>
                  <Tooltip title="Age Must Be Above 18">
                    <InfoRoundedIcon />
                  </Tooltip>
                </label>
                <br />
                <input type="date" className="enginenumber" name="dob" value={req?.rc?.dob || ''} onChange={(e) => handleChangerc(e)} />

                <br />
                {isSubmited && dateOfBirthValidateNomminee(req?.rc?.dob) != '' ? <span className="error-validation">{dateOfBirthValidateNomminee(req?.rc?.dob)}</span> : <span />}
              </Grid>
            </Grid>
          </Grid>

          {/* Vehicle Additional Details like Engine number, chassis number */}

          <Grid className="additional-details-container">
            <Grid className="additionaldetails">Additional Details</Grid>
            <Grid container spacing={{ xs: 0, sm: 0, md: 2 }} className="gridenginenumber">
              {req?.vehicle?.isVehicleWithoutNumber ? (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <label className="label_input">
                    Registration Number
                    <span>*</span>
                  </label>
                  <br />
                  <input type="text" className="enginenumber" placeholder="MH12MK0000" value={req?.rc?.number || ''} name="number" onChange={(e) => handleChangerc(e)} />
                  <br />
                  {isSubmited && req?.rc?.number == '' ? <span className="error-validation">Please Enter Registration Number</span> : <span />}
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Engine Number
                  <span style={{ color: 'red' }}>* </span>
                  <Tooltip title="Engine Number Must Be 10 Charecters Between (A-Z) And (0-9)">
                    <InfoRoundedIcon />
                  </Tooltip>
                </label>
                <br />
                <input type="text" className="enginenumber" placeholder="Eg: 52WVC10338" value={req?.vehicle?.engineNumber || ''} name="engineNumber" onChange={(e) => handleChangeVehicle(e)} />
                <br />
                {isSubmited && req?.vehicle?.engineNumber == '' ? <span className="error-validation">Please Enter Engine Number</span> : <span />}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Chassis Number
                  <span style={{ color: 'red' }}>*</span>
                  <Tooltip title="Chassis Number Must Be 17 Charecters Between (A-Z) And (0-9)">
                    <InfoRoundedIcon />
                  </Tooltip>
                </label>
                <br />
                <input type="text" className="enginenumber" placeholder="Eg: MA6MFBC1BBT096358" value={req?.vehicle?.chasisNumber || ''} name="chasisNumber" onChange={(e) => handleChangeVehicle(e)} />
                <br />
                {isSubmited && req?.vehicle?.chasisNumber == '' ? <span className="error-validation">Please Enter chasis Number</span> : <span />}
              </Grid>

              {/* is on Loan */}

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">Vehicle On Loan</label>
                <br />
                <FormControl>
                  <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={isLoanProvider} onChange={(e) => setIsLoanProvider(e.target.value)}>
                    <FormControlLabel value="yes" control={<Radio />} label={<span className="span_radio">Yes</span>} />
                    <FormControlLabel value="no" control={<Radio />} label={<span className="span_radio">No</span>} />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {isLoanProvider == 'yes' ? (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <label className="label_input">Loan Provider</label>
                  <br />
                  <input type="text" className="enginenumber" name="loanProviderName" value={req?.vehicle?.loanProviderName || ''} onChange={(e) => handleChangeVehicle(e)} />
                  <br />
                  {isSubmited && isLoanProvider == 'yes' && req?.vehicle?.loanProviderName == '' ? <span className="error-validation">Please Enter Valid Loan Provider</span> : <span />}
                </Grid>
              ) : (
                <div></div>
              )}

              {/* PUC */}

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">Do you have valid PUC ?</label>
                <br />
                <FormControl>
                  <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={ispuc} onChange={(e) => setIsPuc(e.target.value)}>
                    <FormControlLabel value="yes" control={<Radio />} label={<span className="span_radio">Yes</span>} />
                    <FormControlLabel value="no" control={<Radio />} label={<span className="span_radio">No</span>} />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {ispuc == 'yes' ? (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <label className="label_input">PUC No</label>
                  <br />
                  <input type="text" className="enginenumber" name="pucNumber" value={req?.vehicle?.pucNumber || ''} onChange={(e) => handleChangeVehicle(e)} />
                  <br />
                  {isSubmited && ispuc == 'yes' && req?.vehicle?.pucNumber == '' ? <span className="error-validation">Please Enter PUC Number</span> : <span />}
                </Grid>
              ) : (
                <div></div>
              )}

              {ispuc == 'yes' ? (
                <Grid item xs={12} sm={12} md={6} lg={6} className="datePicker">
                  <label className="label_input">PUC Valid UpTo</label>
                  <br />
                  <input type="date" className="enginenumber" name="pucExpiryDate" value={req?.vehicle?.pucExpiryDate || ''} onChange={(e) => handleChangeVehicle(e)} />

                  <br />
                  {isSubmited && ispuc == 'yes' && req?.vehicle?.pucExpiryDate == '' ? <span className="error-validation">Please Enter PUC Expire Date</span> : <span />}
                </Grid>
              ) : (
                <div></div>
              )}
            </Grid>
          </Grid>

          {/* Previous Policy Detail */}
          {!req?.vehicle?.isVehicleNew && req?.previousPolicyDetails?.isPreviousInsurerKnown ? (
            <Grid className="additional-details-container">
              <Grid className="additionaldetails">Previous Policy Detail</Grid>
              <Grid container spacing={{ xs: 0, sm: 0, md: 2 }} className="gridenginenumber">
                <Grid item xs={12} sm={12} md={6} lg={6} className="kyc-proposal-autocomplete-parent">
                  <FormControl>
                    <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" className="radiobutton" name="policyType" value={req?.previousPolicyDetails?.policyType || ''} onChange={(e) => handleChangePreviousInsurerAutocomplete(e, 'policyType')}>
                      <FormControlLabel value="1OD_3TP" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Comprehensive</span>} />
                      <FormControlLabel value="0OD_1TP" control={<Radio />} label={<span style={{ fontSize: '14px' }}>Third Party</span>} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={{ xs: 0, sm: 0, md: 2 }} className="gridenginenumber">
                <Grid item xs={12} sm={12} md={6} lg={6} className="kyc-proposal-autocomplete-parent">
                  <label className="label_input">
                    Previous Insurer Name<span style={{ color: 'red' }}>*</span>
                  </label>
                  <br />
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={insurerName?.map((option) => (option ? option.Name : ''))}
                    value={req?.previousPolicyDetails?.policyCompany || ''}
                    onChange={(e) => {
                      handleChangePreviousInsurerAutocomplete(e, 'policyCompany');
                    }}
                    className="kyc-proposal-autocomplete"
                    PaperComponent={({ children }) => <Paper style={{ width: '250px', borderRadius: '10px' }}>{children}</Paper>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search'
                        }}
                        name="policyCompany"
                      />
                    )}
                  />
                  {isSubmited && req?.previousPolicyDetails?.policyCompany == '' ? <span className="error-validation">Please Select Previous Insurer Name</span> : <span />}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <label className="label_input">
                    Previous Policy Number<span style={{ color: 'red' }}>*</span>
                  </label>
                  <br />
                  <input type="text" className="enginenumber" placeholder="Eg: 12345678910" value={req?.previousPolicyDetails?.policyNumber || ''} name="policyNumber" onChange={(e) => handleChangePreviousInsurer(e)} />
                  <br />
                  {isSubmited && req?.previousPolicyDetails?.policyNumber == '' ? <span className="error-validation">Please Enter Previous Policy Number</span> : <span />}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="datePicker">
                  <label className="label_input">
                    Start Date<span style={{ color: 'red' }}>*</span>
                  </label>
                  <br />
                  <input type="date" className="enginenumber" placeholder="Eg: 12345678910" value={req?.previousPolicyDetails?.policyStartDate || ''} name="policyStartDate" onChange={(e) => handleChangePreviousInsurer(e)} />

                  <br />
                  {isSubmited && req?.previousPolicyDetails?.policyStartDate == '' ? <span className="error-validation">Please Enter Policy Start Date</span> : <span />}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="datePicker">
                  <label className="label_input">
                    End Date<span style={{ color: 'red' }}>*</span>
                  </label>
                  <br />
                  <input type="date" className="enginenumber" placeholder="Eg: 12345678910" value={req?.previousPolicyDetails?.policyEndDate || ''} name="policyEndDate" onChange={(e) => handleChangePreviousInsurer(e)} />

                  <br />
                  {isSubmited && req?.previousPolicyDetails?.policyEndDate == '' ? <span className="error-validation">Please Enter Policy End Date</span> : <span />}
                </Grid>
              </Grid>
              {req?.insurance?.policyType == 'OWN DAMAGE' ? (
                <Grid container spacing={{ xs: 0, sm: 0, md: 2 }} className="gridenginenumber">
                  <Grid item xs={12} sm={12} md={6} lg={6} className="kyc-proposal-autocomplete-parent">
                    <label className="label_input">
                      Previous ThirdParty Policy Insurer Name<span style={{ color: 'red' }}>*</span>
                    </label>
                    <br />
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      disableClearable
                      options={insurerName?.map((option) => (option ? option.Name : ''))}
                      value={req?.previousPolicyDetails?.policyTPCompany || ''}
                      onChange={(e) => {
                        handleChangePreviousInsurerAutocomplete(e, 'policyTPCompany');
                      }}
                      className="kyc-proposal-autocomplete"
                      PaperComponent={({ children }) => <Paper style={{ width: '250px', borderRadius: '10px' }}>{children}</Paper>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            type: 'search'
                          }}
                          name="policyCompany"
                        />
                      )}
                    />
                    <br />
                    {isSubmited && req?.previousPolicyDetails?.policyTPCompany == '' ? <span className="error-validation">Please Select ThirdParty Insurer Name</span> : <span />}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <label className="label_input">
                      ThirdParty Policy Number<span style={{ color: 'red' }}>*</span>
                    </label>
                    <br />
                    <input type="text" className="enginenumber" placeholder="Eg: 12345678910" value={req?.previousPolicyDetails?.policyTPNumber || ''} name="policyTPNumber" onChange={(e) => handleChangePreviousInsurer(e)} />
                    <br />
                    {isSubmited && req?.previousPolicyDetails?.policyTPNumber == '' ? <span className="error-validation">Please Enter ThirdParty Policy Number</span> : <span />}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} className="datePicker">
                    <label className="label_input">
                      ThirdParty Policy Start Date<span style={{ color: 'red' }}>*</span>
                    </label>
                    <br />
                    <input type="datetime-local" className="enginenumber" placeholder="Eg: 12345678910" value={req?.previousPolicyDetails?.policyTPStartDate || ''} name="policyTPStartDate" onChange={(e) => handleChangePreviousInsurer(e)} />

                    <br />
                    {isSubmited && req?.previousPolicyDetails?.policyTPStartDate == '' ? <span className="error-validation">Please Enter ThirdParty Start Date</span> : <span />}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} className="datePicker">
                    <label className="label_input">
                      ThirdParty Policy End Date<span style={{ color: 'red' }}>*</span>
                    </label>
                    <br />
                    <input type="datetime-local" className="enginenumber" placeholder="Eg: 12345678910" value={req?.previousPolicyDetails?.policyTPEndDate || ''} name="policyTPEndDate" onChange={(e) => handleChangePreviousInsurer(e)} />

                    <br />
                    {isSubmited && req?.previousPolicyDetails?.policyTPEndDate == '' ? <span className="error-validation">Please Enter ThirdParty End Date</span> : <span />}
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          ) : (
            ''
          )}

          <Grid className="additional-details-container">
            <Grid className="additionaldetails">Nominee Details</Grid>
            <Grid container spacing={{ xs: 0, sm: 0, md: 2 }} className="gridenginenumber">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Nominee Name <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input type="text" className="enginenumber" placeholder="Eg: Akshay Gupta" value={req?.insurance?.nomminee?.name || ''} name="name" onChange={(e) => handleChangeNomminee(e)} />
                <br />
                {isSubmited && FullNameValidate(req?.insurance?.nomminee.name) != '' ? <span className="error-validation">{FullNameValidate(req?.insurance?.nomminee?.name || '')}</span> : <span />}
                <br />
                {isSubmited && nommineeChecked && FullNameValidate(req?.insurance?.nomminee.name) != '' ? <span className="error-validation">{FullNameValidate(req?.insurance?.nomminee?.name || '')}</span> : <span />}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Nominee Date Of Birth
                  <span style={{ color: 'red' }}>*</span>
                  <Tooltip title="Age Must Be Above 18">
                    <InfoRoundedIcon />
                  </Tooltip>
                </label>
                <br />
                <input type="date" className="enginenumber" value={req?.insurance?.nomminee?.dob || ''} name="dob" onChange={(e) => handleChangeNomminee(e)} />

                <br />
                {isSubmited && dateOfBirthValidateNomminee(req?.insurance?.nomminee?.dob) != '' ? <span className="error-validation">{dateOfBirthValidateNomminee(req?.insurance?.nomminee?.dob)}</span> : <span />}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Nominee Relation <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <FormControl className="kyc-proposal-select-martial-status-parent">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="kyc-proposal-select-martial-status"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          fontFamily: 'Bradley Hand'
                        }
                      }
                    }}
                    value={req?.insurance?.nomminee?.relation || ''}
                    name="relation"
                    onChange={(e) => handleChangeNomminee(e)}
                  >
                    {relation &&
                      relation.length > 0 &&
                      relation?.map((el) => (
                        <MenuItem value={el['lmv Request']} key={el['lmv Request']}>
                          {el['lmv Request']}
                        </MenuItem>
                      ))}
                  </Select>
                  <br />
                  {isSubmited && req?.insurance?.nomminee?.relation == '' ? <span className="error-validation">Please Select Nominee Relation</span> : <span />}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* ) : (
            <div></div>
          )} */}

          {/* Customers current address where insurance companies can contact*/}

          <Grid className="additional-details-container">
            <Grid className="additionaldetails">Address Details</Grid>
            <Grid container spacing={{ xs: 0, sm: 0, md: 2 }} className="gridenginenumber">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  House No<span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input type="text" className="enginenumber" name="houseNumber" value={req?.insurance?.kyc?.addresses?.houseNumber || ''} onChange={(e) => handleChangeAddress(e)} />
                <br />
                {isSubmited && req?.insurance?.kyc?.addresses?.houseNumber == '' ? <span className="error-validation">Please Enter House Number</span> : <span />}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Street Name<span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input type="text" className="enginenumber" name="street" value={req?.insurance?.kyc?.addresses?.street || ''} onChange={(e) => handleChangeAddress(e)} />
                <br />
                {isSubmited && req?.insurance?.kyc?.addresses?.street == '' ? <span className="error-validation">Please Enter Street Name</span> : <span />}
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Pincode<span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="enginenumber"
                  name="pincode"
                  value={req?.insurance?.kyc?.addresses?.pincode || ''}
                  onChange={(e) => handleChangeAddress(e)}
                  // min="0"
                  // max="6"
                  maxLength={6}
                />
                <br />
                {isSubmited && req?.insurance?.kyc?.addresses?.pincode == '' ? <span className="error-validation">Please Enter Pincode</span> : <span />}
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  City<span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input type="text" className="enginenumber" name="city" value={req?.insurance?.kyc?.addresses?.city || ''} onChange={(e) => handleChangeAddress(e)} disabled={true} />
                <br />
                {isSubmited && req?.insurance?.kyc?.addresses?.city == '' ? <span className="error-validation">Please Enter city</span> : <span />}
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  Country <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input type="text" className="enginenumber" name="country" value={'INDIA'} onChange={(e) => handleChangeAddress(e)} disabled={true} />
                <br />
                {isSubmited && req?.insurance?.kyc?.addresses?.country == '' ? <span className="error-validation">Please Enter Country</span> : <span />}
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">
                  State<span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input type="text" className="enginenumber" name="state" value={req?.insurance?.kyc?.addresses?.state || ''} onChange={(e) => handleChangeAddress(e)} disabled={true} />
                <br />
                {isSubmited && req?.insurance?.kyc?.addresses?.state == '' ? <span className="error-validation">Please Enter State</span> : <span />}
              </Grid>
            </Grid>
          </Grid>

          {/* Customer Pan, Aadhar or other details which helps to complete KYC*/}

          <Grid className="additional-details-container">
            <Grid className="additionaldetails">Select Identification Type For KYC</Grid>
            <Grid container spacing={{ xs: 0, sm: 0, md: 2 }} className="gridenginenumber">
              <Grid item xs={12} sm={12} md={12}>
                <FormControl>
                  <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="doctype" className="radiobutton" value={req?.insurance?.kyc?.doctype || ''} onChange={(e) => handleChangeKyc(e)}>
                    <FormControlLabel value="AADHAR_CARD" control={<Radio />} label={<span className="span_radio">Aadhar Card</span>} />
                    <FormControlLabel value="DRIVING_LICENSE" control={<Radio />} label={<span className="span_radio">Driving License</span>} />
                    <FormControlLabel value="PAN_CARD" control={<Radio />} label={<span className="span_radio">Pan Card</span>} />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, sm: 0, md: 2 }} className="gridenginenumber">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label style={{ fontWeight: 550, fontSize: '13px' }}>
                  {`${req?.insurance?.kyc?.doctype?.split('_').join(' ')} NUMBER`}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                {req?.insurance?.kyc?.doctype === 'AADHAR_CARD' ? (
                  <div>
                    <input type="text" className="enginenumber" name="docNumber" value={req?.insurance?.kyc?.docNumber || ''} onChange={(e) => handleChangeKyc(e)} />
                    <br />
                    {isSubmited && aadhaarValidate(req?.insurance?.kyc?.docNumber) != '' ? <span className="error-validation">{aadhaarValidate(req?.insurance?.kyc?.docNumber)}</span> : <span />}
                  </div>
                ) : req?.insurance?.kyc?.doctype === 'PAN_CARD' ? (
                  <div>
                    {' '}
                    <input type="text" className="enginenumber" name="docNumber" value={req?.insurance?.kyc?.docNumber || ''} onChange={(e) => handleChangeKyc(e)} />
                    <br />
                    {isSubmited && panCardValidate(req?.insurance?.kyc?.docNumber) != '' ? <span className="error-validation">{panCardValidate(req?.insurance?.kyc?.docNumber)}</span> : <span />}
                  </div>
                ) : (
                  <div>
                    {' '}
                    <input type="text" className="enginenumber" name="docNumber" value={req?.insurance?.kyc?.docNumber || ''} onChange={(e) => handleChangeKyc(e)} />
                    <br />
                    {isSubmited && drivingLicenseValidate(req?.insurance?.kyc?.docNumber) != '' ? <span className="error-validation">{drivingLicenseValidate(req?.insurance?.kyc?.docNumber)}</span> : <span />}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className="datePicker">
                <label style={{ fontWeight: 550, fontSize: '13px' }}>
                  DATE OF BIRTH
                  <span style={{ color: 'red' }}>* </span>
                  <Tooltip title="Age Must Be Above 18">
                    <InfoRoundedIcon />
                  </Tooltip>
                </label>
                <br />
                <input type="date" className="enginenumber" value={req?.rc?.dob || ''} name="dob" onChange={(e) => handleChangerc(e)} />

                <br />
                {isSubmited && dateOfBirthValidateNomminee(req?.rc?.dob) != '' ? <span className="error-validation">{dateOfBirthValidateNomminee(req?.rc?.dob)}</span> : <span />}
              </Grid>
            </Grid>
            <div>
              <div className="kyc-consent-div">
                <div>
                  <Checkbox checked={req?.insurance?.kyc?.consent || false} onChange={(e) => handleChangeKycConsent(e)} />
                </div>
                <div className="kyc-consent-content">I here by give my consent to the Company to verify my identity through Central KYC Registryor UIDAI or through any other modes for the purpose of undertaking KYC</div>
              </div>
              <br />
              {isSubmited && !req?.insurance?.kyc?.consent ? (
                <span style={{ marginLeft: '50px' }} className="error-validation">
                  Please Give Consent In The checkBox
                </span>
              ) : (
                <span />
              )}
            </div>
            <div className="kyc_details">
              <div>Complete KYC to fetch customer details</div>
              <div>Kindly complete E-KYC/C-KYC to Proceed further</div>
            </div>
          </Grid>
        </Grid>

        {/* collecting data from Rc and Quotation page and display to customer for refference*/}

        <Grid item xs={12} sm={12} md={3} lg={3} className="proposal-kyc-main-container">
          <Grid className="proposal-kyc-vehicleDetail-container-parent">
            <Grid className="proposal-kyc-vehicle-details">Vehicle Detail</Grid>
            <Grid container className="proposal-kyc-vehicleDetail-container" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">Vehicle Model</label> <br />
                <div>{req?.vehicle?.makerModel}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">Registration Number</label>
                <br />
                <div>{req?.rc?.number}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">Vehicle Category</label> <br />
                <div>{req?.vehicle?.category}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">Registration Date</label> <br />
                <div>{req?.vehicle?.registrationDate ? moment(req?.vehicle?.registrationDate).format('DD-MM-YYYY') : ''}</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="proposal-kyc-premium-container-parent">
            <Grid className="proposal-kyc-vehicle-details">Plan Summary</Grid>
            <Grid container className="proposal-kyc-premium-container" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">Plan Type</label> <br />
                <div>{req?.insurance?.policyType || ''}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">IDV Cover</label> <br />
                <div>{currencyFormat(req?.vehicle?.vehicleIDV?.idv || '')}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">NCB</label> <br />
                <div>{req?.insurance?.presentNcbValue || ''}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="label_input">Premium</label> <br />
                <div>₹{currencyFormat(Math.floor(Number(req?.insurance?.netPremium)))}</div>
              </Grid>
            </Grid>
            <Grid className="">
              <b style={{ marginLeft: '10px', fontSize: '18px' }}>Selected Add-ons</b>
            </Grid>
            <Grid container className="proposal-kyc-premium-container" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="">
                  {req?.insurance?.coverages?.addons ? (
                    <>
                      <div className="row d-flex">
                        <div className="col-md-12">
                          {Object.keys(req?.insurance?.coverages?.addons || {}).map((addon_name) => {
                            let related_value_obj = req?.insurance?.coverages?.addons[addon_name];

                            if (related_value_obj?.selection) {
                              return (
                                <div className="col-md-12">
                                  {addon_name.charAt(0).toUpperCase() +
                                    addon_name
                                      .slice(1)
                                      .replace(/([A-Z])/g, ` $1`)
                                      .trim()}
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid className="">
              <b style={{ marginLeft: '10px', fontSize: '18px' }}>Additional Covers</b>
            </Grid>
            <Grid container className="proposal-kyc-premium-container" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  {req?.insurance?.coverages ? (
                    <>
                      <div className="row d-flex">
                        <div className="col-md-12">
                          {Object.keys(req?.insurance?.coverages?.legalLiability || {}).map((legal_liability) => {
                            let related_value_obj = req?.insurance?.coverages?.legalLiability[legal_liability];
                            if (related_value_obj?.selection) {
                              return (
                                <div className="col-md-12" key={legal_liability}>
                                  {legal_liability.charAt(0).toUpperCase() +
                                    legal_liability
                                      .slice(1)
                                      .replace(/([A-Z])/g, ' $1')
                                      .trim()}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container className="proposal-kyc-premium-container" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  {req?.insurance?.coverages ? (
                    <>
                      <div className="row d-flex">
                        <div className="col-md-12">
                          {Object.keys(req?.insurance?.coverages?.unnamedPA || {}).map((unnamed_cover) => {
                            const related_value_obj = req?.insurance?.coverages?.unnamedPA?.[unnamed_cover];
                            if (related_value_obj?.selection) {
                              return (
                                <div className="col-md-12" key={unnamed_cover}>
                                  {unnamed_cover.charAt(0).toUpperCase() +
                                    unnamed_cover.slice(1).replace(/([A-Z])/g, ' $1').trim()}{' '}
                                  {'₹' + currencyFormat(related_value_obj.insuredAmount)}
                                </div>
                              );
                            }
                            return null; // Ensure a fallback for cases where selection is false
                          })}
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid className="">
              <b style={{ marginLeft: '10px', fontSize: '18px' }}>Accessories</b>
            </Grid>
            <Grid container className="proposal-kyc-premium-container" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  {req?.insurance?.coverages ? (
                    <>
                      <div className="row d-flex">
                        <div className="col-md-12">
                          {Object.keys(req?.insurance?.coverages?.accessories || {}).map((accessories_name) => {
                            let related_value_obj = req?.insurance?.coverages?.accessories[accessories_name];
                            if (related_value_obj?.selection) {
                              return (
                                <div className="col-md-12 ">
                                  {accessories_name.charAt(0).toUpperCase() +
                                    accessories_name
                                      .slice(1)
                                      .replace(/([A-Z])/g, ' $1')
                                      .trim()}
                                  {'₹' + currencyFormat(related_value_obj.insuredAmount)}
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid className="">
              <b style={{ marginLeft: '10px', fontSize: '18px' }}>Other Covers</b>
            </Grid>
            <Grid container className="proposal-kyc-premium-container" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  {req?.insurance?.coverages ? (
                    <>
                      <div className="row d-flex">
                        <div className="col-md-12">
                          {Object.keys(req?.insurance?.coverages?.personalAccident || {}).map((name) => {
                            let related_value_obj = req?.insurance?.coverages?.personalAccident[name];
                            if (related_value_obj?.selection) {
                              return (
                                <div className="col-md-12 ">
                                  {name.charAt(0).toUpperCase() +
                                    name
                                      .slice(1)
                                      .replace(/([A-Z])/g, ' $1')
                                      .trim()}
                                  {'₹' + currencyFormat(related_value_obj.insuredAmount)}
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <button
        className="kyc_payment_button"
        onClick={() => {
          setIsLoading(() => true);
          handleSubmit();
        }}
      >
        PROCEED TO PAYMENT
      </button>

      <AlertError open={open} setOpen={setOpen} message={MandatoryFieldsErrorMessage} />
      <AlertError open={error} setOpen={setError} message={errorMssg} />
    </div>
  );
};

export default ProposalDetails;
