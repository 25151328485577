import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
// import { FileDownload, SmsFailed } from '@mui/icons-material';
import axios from 'axios';
import { health_api } from '../../../Config';
import Box from '@mui/material/Box';

export const HealthPaymentSuccess = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [downloading,setDownloading] = useState(false)
  const [policyNum,setPolicyNumber] = useState("")
  let provider = useSelector((e) => e?.healthReducer?.selectedProvider)
  const hdfcPolicyData = useSelector((state) => state?.healthReducer?.hdfcPolicydata);
  const txnID = useSelector((e)=>e?.healthReducer?.generatedHDFCTxnId);
  const careProposalNum = useSelector((state) => state?.healthReducer.proposalNum);

  useEffect(() => {
    let reqBody 
    if(provider === "CARE"){
      reqBody={
        proposalNum:careProposalNum,
        provider:"CARE"
      }
    }else if(provider === "HDFC"){
      reqBody=
        hdfcPolicyData
    }
    const fetchData = async () => {
      try {
        if (hdfcPolicyData || careProposalNum) {
          const response = await axios.post(`${health_api}/insurance/v1/health/policy/submitpayment`, reqBody);
          setPaymentDetails(response?.data);
          setPolicyNumber(response?.data?.policyNumber)
        } else {
          console.error('hdfcPolicyData is undefined');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [hdfcPolicyData]);

  const handleDownload = async () => {
    let req
    if(provider === "HDFC"){
       req = {
        provider:"HDFC",
        txnID: txnID,
        policyNum: policyNum,
      };
    }else if(provider === "CARE"){
      req={
        provider:"CARE",
        policynum:policyNum
      }
    }
    console.log(req,"request")
   
    try {
      setDownloading(true)
      const res = await axios.post(
        `${health_api}/insurance/v1/health/policy/pdfgenerate`,
        req
      );
      console.log(res,provider,"responseee")
      let base64String 
      if(provider === "HDFC"){
        console.log(res,"response")
        base64String = res?.data?.Resp_Policy_Document?.PDF_BYTES;
      }else if(provider === "CARE"){
        console.log(res,"response")
        base64String = res?.data?.intFaveoGetPolicyPDFIO?.dataPDF;
      }
      let fileName = "lmv_customerId.pdf";
      const data = base64String.replace(/^data:application\/pdf;base64,/, "");  
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      console.log(url,"blob url")
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = fileName;
      anchor.style.display = "none";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setDownloading(false)
    } catch (error) { 
      setDownloading(false)
      console.log(error, "couldn't download pdf");
    }
  };


  // const showKycStatus = true;

  if (isLoading || !paymentDetails) {
    return (
      <Box sx={{ height: '700px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="payment-container">
      <div className="payment-success-table-container">
      <div className="payment-success-table">
          <div className="payment-success-table-header">
            Amount
            <span style={{ fontWeight: '500', fontSize: '18px' }}>₹ {paymentDetails?.premium}</span>
          </div>
          <div className="payment-success-table-divider">
            <ul className="payment-success-list">
              <li>
                <span>Proposal Number</span>
                <span className="application-id">
                {paymentDetails?.proposalNumber}
                </span>
              </li>
              <li>
                <span>Policy Number</span>
                <span>
                    {paymentDetails?.policyNumber}
                </span>
              </li>
              <li>
                <span>Policy Status</span>
                <span>
                    {paymentDetails?.policystatus}
                </span>
              </li>
              <li>
                <span>Merchant Name</span>
                <span>LMVIB</span>
              </li>
              <li>
                <span>Payment Method</span>
                <span>Online</span>
              </li>
              <li>
                <span>Policy Start Date</span>
                {paymentDetails?.startDate}
              </li>
              <li>
                <span>Policy Provider</span>
                <span>{provider}</span>
              </li>
            </ul>
          </div>
        </div>

      <div className="kyc-failed-container">
          <div className="kyc-failed-content">
            <p className="kyc-failed-content-title">Download Policy</p>
            <p>
              Download your <span style={{ color: '#23A8FA' }}>Policy</span> here.
            </p>
            <Button className="kyc-failed-btn" onClick={handleDownload}>
              <span style={{ fontWeight: '700', color: 'white' }}>{downloading ? "Downloading...":"Download"}</span>
            </Button>
          </div>
          <div style={{ marginLeft: '22px' }}>
            {/* {showKycStatus ? <FileDownload /> : <SmsFailed />} */}
          </div>
        </div>
      </div>
    </div>
  );
};
