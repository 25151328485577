import React, { useEffect, useState } from 'react';
import TestiMonialsDetails from '../TestiMonialsDetails/TestiMonialsDetails';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import userPic from '../../image/user-one.png';
import './TestiMonials.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css';


const TestiMonials = () => {
  const testimonials = [
    {
      id: 1,
      name: 'Shivam',
      description: 'Thanking you very much for better policy guidance.',
      photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0khaUcbpblqXKUuxIpxyGB9VqRKmENQZWjbk8uXGEIg&s'
    },
    {
      id: 2,
      name: 'Rohan',
      description: 'Happy to be a part of LMV family',
      photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0khaUcbpblqXKUuxIpxyGB9VqRKmENQZWjbk8uXGEIg&s'
    },
    {
      id: 3,
      name: 'Ravi',
      description: 'I did not even need help from an agent! This is very good!',
      photo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0khaUcbpblqXKUuxIpxyGB9VqRKmENQZWjbk8uXGEIg&s'
    },
    // Add more testimonials as needed
  ];

  const settings = {
    dots: true,
    centerMode: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };


  return (
    <div className='testimonial-section'>
    <Slider {...settings}>
      {testimonials.map(testimonial => (
        <div key={testimonial.id}>
          <div className="testimonial-card">
            <img src={testimonial.photo} alt={testimonial.name} className="testimonial-photo" />
            <h3 className="testimonial-name">{testimonial.name}</h3>
            <p className="testimonial-description">{testimonial.description}</p>
          </div>
        </div>
      ))}
    </Slider>
    </div>
  );
};

export default TestiMonials;
  