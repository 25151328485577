import React, { useEffect, useState } from "react";
import {
  Avatar,
  CardContent,
  Grid,
  Link,
  TextField,
  Typography,
  CardMedia,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import {
  clearError,
  setError,
} from "../../../../Redux/action-creators/errorAction";
import {
  clearSuccessMessage,
  setSuccessMessage,
} from "../../../../Redux/action-creators/successAction";
import { useTranslation } from "react-i18next";
import { otpValidation, validateUserByPhone } from "../../api/adminService";
import { generateOtp } from "../../../customer/api/customerService";
import { setToken } from "../../../../Redux/action-creators/authAction";
import MainCard from "../../../posp/ui-component/cards/MainCard";
import { gridSpacing } from "../../../../Redux/crmustomization/constant";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import employeSvg from "../../images/Employee_login.svg";

const AdminLogin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(6);
  const navigate = useNavigate();
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [step, setStep] = useState(1); // Step 1 for mobile number, Step 2 for OTP;
  const [otpSend, setOtpSend] = useState(false);

  useEffect(() => {
    let timer;
    if (resendDisabled) {
      timer = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [resendDisabled, countdown]);

  useEffect(() => {
    if (countdown === 0) {
      setResendDisabled(false);
      setCountdown(60); // Reset countdown
    }
  }, [countdown]);

  const handleResendOtp = () => {
    setResendDisabled(true);
  };

  const handleMobileNumber = async () => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const mobileNumber = getValues("mobileNumber");
    const isNumber = () => !isNaN(Number(mobileNumber));
    if (isNumber && mobileNumber.toString().trim().length === 10) {
      validateUser(mobileNumber.toString().trim());
    } else {
      dispatch(setError(t("lmv.customer.invalidMobile")));
    }
  };

  const validateUser = async (phone) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const payload = {
      phone
    };
    setOtpSend(true)
    await validateUserByPhone(payload).then((response) => {
      if (response.data.status >= 400) {
        dispatch(setError(response.data.message));
        setOtpSend(false)
      } else {
        sendOtp(phone);
      }
    });
  };

  const sendOtp = (phonenumber) => {
    setOtpSend(true);
    dispatch(clearError());
    dispatch(clearSuccessMessage());

    generateOtp({
      phone: phonenumber,
    }).then((response) => {
      if (response.status > 400) {
        dispatch(setError(t("lmv.serverError")));
        setOtpSend(false);
      } else {
        dispatch(setSuccessMessage(t("lmv.customer.otpSuccessMsg")));
        setStep(2);
        setOtpSend(false);
      }
    });
  };

  const onSubmit = (data) => {
    setOtpSend(true);
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    otpValidation({
      phone: data.mobileNumber,
      otp: data.otp,
    }).then((response) => {
      if (response.status > 400) {
        dispatch(setError(t("lmv.serverError")));
        setOtpSend(false);
      } else {
        if (response.data.isOtpVerified) {
          dispatch(setSuccessMessage(t('lmv.customer.loginSuccess')));
          localStorage.setItem('jwtToken', response.data.data.token);
          localStorage.setItem('phone', response.data.data.phone);
          localStorage.setItem('userType', 'employee');
          dispatch(setToken(response.data.data));
          navigate('/employee');
        } else {
          dispatch(setError(response.data.message))
        }
      }
    });
  };

  const handlePhoneChange = () => {
    setStep(1);
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ minHeight: "100vh" }}
      >
        <Grid component={Stack} item xs={false} sm={4} md={7}>
          <CardMedia
            sx={{ width: "400px", height: "400px", m: "auto" }}
            component="img"
            src={employeSvg}
            alt="Employee SVG"
          />
        </Grid>
        <Grid
          component={Stack}
          item
          xs={12}
          sm={8}
          md={5}
          elevation={6}
          sx={{ m: 'auto' }}
        >
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MainCard
                content={false}
                border={true}
                sx={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
                boxShadow={true}
                darkTitle={false}
              >
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} alignItems="center">
                      <Avatar sx={{ m: 1, mx: 'auto' }}>
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography textAlign='center' variant="h3">Employee Login</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="mobileNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: t("lmv.requiredField"),
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message: t("lmv.customer.invalidMobile"),
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t("lmv.customer.mobileFormFieldLabel")}
                            variant="outlined"
                            margin="normal"
                            id="mobileNumber"
                            fullWidth
                            error={!!errors.mobileNumber}
                            disabled={step !== 1}
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                            }}
                          />
                        )}
                      />
                      {errors.mobileNumber?.message && (
                        <Typography style={{ color: "red" }} variant="caption">
                          {errors.mobileNumber?.message}
                        </Typography>
                      )}
                    </Grid>
                    {step === 1 && (
                      <Grid item xs={12}>
                        <Button
                          disabled={otpSend}
                          variant="contained"
                          fullWidth
                          onClick={handleMobileNumber}
                        >
                          {!otpSend ? t("lmv.customer.proceed") : ""}
                          {otpSend && <CircularProgress size="20px" />}
                        </Button>
                      </Grid>
                    )}
                    {step === 2 && (
                      <>
                        <Grid item xs={12}>
                          <Controller
                            name="otp"
                            control={control}
                            defaultValue=""
                            rules={{ required: t("lmv.requiredField") }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                margin="normal"
                                fullWidth
                                label={t("lmv.customer.otp")}
                                type="text"
                                id="otp"
                                error={Boolean(errors.otp)}
                                onInput={(e) => {
                                  if (e.target.value.length > 6) {
                                    e.target.value = e.target.value.slice(0, 6);
                                  }
                                }}
                              />
                            )}
                          />
                          {errors.otp?.message && (
                            <Typography
                              style={{ color: "red" }}
                              variant="caption"
                            >
                              {errors.otp?.message}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            disabled={otpSend}
                            variant="contained"
                            fullWidth
                          >
                            {!otpSend ? (
                              t("lmv.customer.login")
                            ) : (
                              <CircularProgress size="24px" />
                            )}
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="text"
                            fullWidth
                            disabled={resendDisabled}
                            onClick={handleResendOtp}
                          >
                            {resendDisabled
                              ? `${t(
                                "lmv.customer.resend"
                              )} Otp in ${countdown} sec`
                              : t("lmv.customer.resend")}
                          </Button>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between">

                        {step === 2 && (
                          <Grid item>
                            <Link onClick={handlePhoneChange}>
                              {t("lmv.customer.changeMobileNumber")}
                            </Link>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </MainCard>
            </form>
          </CardContent>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminLogin;