/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Grid, TextField, Paper, Button, MenuItem } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { NomineeDetails } from "../../../Redux/Travel/action";
import { useTranslation } from "react-i18next";
const NomineeDetail = ({ setValueTab }) => {
  const { t } = useTranslation();
  const travelContent = t("travel");
  console.log(travelContent, "travellllll");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const selector = useSelector((e) => e.travelReducer.formData.proposalDetails);
  const nSelector = useSelector((e) => e.travelReducer.formData.NomineeDetails);
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    console.log(data, "dataaaa");
    setValueTab(3);
    dispatch(NomineeDetails(data));
  };
  const today = new Date();
  const maxDOB = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .split("T")[0];
  const minDOB = new Date(
    new Date().getFullYear() - 99,
    new Date().getMonth(),
    new Date().getDate()
  )
    .toISOString()
    .split("T")[0];

  return (
    <Paper
      elevation={5}
      sx={{ padding: { xs: "1em 0 0 0.5em", sm: "1em" }, borderRadius: "1em" }}
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container padding={"1em"}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
          >
            <Controller
              name="nomineeName"
              control={control}
              defaultValue={nSelector?.nomineeName || ""}
              rules={{
                required: "Nominee Name is Required.",
                pattern: {
                  value: /^[a-zA-Z]*$/,
                  message: "Only alphabets are allowed",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={travelContent.nominee_details.full_name}
                  type="text"
                  variant="outlined"
                  fullWidth
                  error={!!errors.nomineeName}
                  helperText={errors.nomineeName && errors.nomineeName.message}
                  onChange={(e) => {
                    const regex = /^[a-zA-Z\s]*$/;
                    const value = e.target.value;
                    if (regex.test(value) && value.length <= 60) {
                      field.onChange(
                        e.target.value.charAt(0).toUpperCase() +
                        e.target.value.slice(1)
                      );
                    }
                  }}
                  onKeyPress={(e) => {
                    const charCode = e.which ? e.which : e.keyCode;
                    if (
                      (charCode < 65 || charCode > 90) &&
                      (charCode < 97 || charCode > 122) &&
                      charCode !== 32
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
          >
            <Controller
              name="birthDate"
              control={control}
              defaultValue={nSelector?.birthDate || ""}
              rules={{
                required: "Nominee Age Must be 18 to 99.",
                validate: {
                  ageLimit: (value) => {
                    const selectedDate = new Date(value);
                    const ageDifference =
                      today.getFullYear() - selectedDate.getFullYear();
                    const minDate = new Date(
                      today.getFullYear() - 99,
                      today.getMonth(),
                      today.getDate()
                    );
                    const isWithinAgeLimit =
                      ageDifference >= 18 &&
                      (ageDifference < 99 ||
                        (ageDifference === 99 && selectedDate >= minDate));

                    return isWithinAgeLimit || "Nominee Age Must be 18 to 99.";
                  },
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={travelContent.nominee_details.n_dob}
                  type="date"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.birthDate)}
                  helperText={errors.birthDate ? errors.birthDate.message : ""}
                  InputProps={{
                    inputProps: {
                      max: maxDOB,
                      min: minDOB,
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
          >
            <Controller
              name="relationship"
              control={control}
              rules={{ required: "Nominee Relatioship is Required." }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  select
                  label={travelContent.nominee_details.rel}
                  error={!!errors.relationship}
                  helperText={
                    errors.relationship && errors.relationship.message
                  }
                >
                  <MenuItem value="" disabled>
                    {travelContent.nominee_details.select_rel}
                  </MenuItem>
                  <MenuItem value="BROTHER">
                    {travelContent.nominee_details.bro}
                  </MenuItem>
                  <MenuItem value="SISTER">
                    {travelContent.nominee_details.sis}
                  </MenuItem>
                  <MenuItem value="MOTHER">
                    {travelContent.nominee_details.mother}
                  </MenuItem>
                  <MenuItem value="FATHER">
                    {travelContent.nominee_details.father}
                  </MenuItem>
                  <MenuItem value="GRAND FATHER">
                    {travelContent.nominee_details.grand_father}
                  </MenuItem>
                  <MenuItem value="GRAND MOTHER">
                    {travelContent.nominee_details.grand_mother}
                  </MenuItem>
                  <MenuItem
                    value="HUSBAND"
                    disabled={selector.martialStatus === "UNMARRIED"}
                  >
                    {travelContent.nominee_details.husband}
                  </MenuItem>
                  <MenuItem
                    value="WIFE"
                    disabled={selector.martialStatus === "UNMARRIED"}
                  >
                    {travelContent.nominee_details.wife}
                  </MenuItem>
                  <MenuItem
                    value="DAUGHTER"
                    disabled={selector.martialStatus === "UNMARRIED"}
                  >
                    {travelContent.nominee_details.daughter}
                  </MenuItem>
                  <MenuItem
                    value="SON"
                    disabled={selector.martialStatus === "UNMARRIED"}
                  >
                    {travelContent.nominee_details.son}
                  </MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "1em",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#23A8F8",
                color: "white",
                ":hover": {
                  backgroundColor: "lime",
                },
              }}
            >
              Submit Nominee Details
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export { NomineeDetail };