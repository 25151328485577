import {
  Box,
  Typography,
  Stack,
  Button,
  Grid,
  Icon,
  IconButton,
  TextField,
  MenuItem,
} from "@mui/material";
import React, { useState} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "react-datepicker/dist/react-datepicker.css";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import PolicyIcon from "@mui/icons-material/Policy";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AddBox from "../common/AddBox/AddBox";
import EditBox from "../common/EditBox/EditBox";

const PolicyClaims = () => {
  const rawData = [
    {
      UserId: "XYZ789",
      Date: "2024-06-25",
      "Amount to Claimed": 120.75,
      "Claim Amount": 90.5,
      Type: "Travel Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 90.5,
      Filter: "Expense",
    },
    {
      UserId: "DEF456",
      Date: "2024-07-10",
      "Amount to Claimed": 85.0,
      "Claim Amount": 60.75,
      Type: "Travel Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 60.75,
      Filter: "Expense",
    },
    {
      UserId: "PQR234",
      Date: "2024-05-15",
      "Amount to Claimed": 150.25,
      "Claim Amount": 110.0,
      Type: "Travel Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 110.0,
      Filter: "Expense",
    },
    {
      UserId: "MNO567",
      Date: "2024-07-03",
      "Amount to Claimed": 95.5,
      "Claim Amount": 70.25,
      Type: "Travel Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 70.25,
      Filter: "Expense",
    },
    {
      UserId: "STU890",
      Date: "2024-06-12",
      "Amount to Claimed": 110.0,
      "Claim Amount": 80.75,
      Type: "Travel Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 80.75,
      Filter: "Expense",
    },

    {
      UserId: "ABC123",
      Date: "2024-07-19",
      "Amount to Claimed": 200.0,
      "Claim Amount": 150.0,
      Type: "Motor Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 150.0,
      Filter: "Expense",
    },
    {
      UserId: "XYZ456",
      Date: "2024-06-25",
      "Amount to Claimed": 180.5,
      "Claim Amount": 120.75,
      Type: "Motor Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 120.75,
      Filter: "Expense",
    },
    {
      UserId: "DEF789",
      Date: "2024-07-10",
      "Amount to Claimed": 250.75,
      "Claim Amount": 200.5,
      Type: "Motor Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 200.5,
      Filter: "Expense",
    },
    {
      UserId: "PQR234",
      Date: "2024-05-15",
      "Amount to Claimed": 300.25,
      "Claim Amount": 240.75,
      Type: "Motor Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 240.75,
      Filter: "Expense",
    },
    {
      UserId: "MNO567",
      Date: "2024-07-03",
      "Amount to Claimed": 150.25,
      "Claim Amount": 100.5,
      Type: "Motor Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 100.5,
      Filter: "Expense",
    },

    {
      UserId: "STU890",
      Date: "2024-06-12",
      "Amount to Claimed": 680.0,
      "Claim Amount": 580.5,
      Type: "Loan Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 580.5,
      Filter: "Expense",
    },
    {
      UserId: "GHI678",
      Date: "2024-07-08",
      "Amount to Claimed": 520.75,
      "Claim Amount": 420.25,
      Type: "Loan Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 420.25,
      Filter: "Expense",
    },
    {
      UserId: "JKL901",
      Date: "2024-05-20",
      "Amount to Claimed": 600.0,
      "Claim Amount": 500.0,
      Type: "Loan Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 500.0,
      Filter: "Expense",
    },
    {
      UserId: "WXY345",
      Date: "2024-06-30",
      "Amount to Claimed": 620.25,
      "Claim Amount": 520.5,
      Type: "Loan Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 520.5,
      Filter: "Expense",
    },
    {
      UserId: "LMN789",
      Date: "2024-07-05",
      "Amount to Claimed": 680.5,
      "Claim Amount": 580.25,
      Type: "Loan Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 580.25,
      Filter: "Expense",
    },
    {
      UserId: "STU890",
      Date: "2024-06-12",
      "Amount to Claimed": 380.0,
      "Claim Amount": 330.75,
      Type: "Health Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 330.75,
      Filter: "Expense",
    },
    {
      UserId: "GHI678",
      Date: "2024-07-08",
      "Amount to Claimed": 290.75,
      "Claim Amount": 240.25,
      Type: "Health Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 240.25,
      Filter: "Expense",
    },
    {
      UserId: "JKL901",
      Date: "2024-05-20",
      "Amount to Claimed": 330.5,
      "Claim Amount": 280.75,
      Type: "Health Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 280.75,
      Filter: "Expense",
    },
    {
      UserId: "WXY345",
      Date: "2024-06-30",
      "Amount to Claimed": 360.25,
      "Claim Amount": 310.5,
      Type: "Health Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 310.5,
      Filter: "Expense",
    },
    {
      UserId: "LMN789",
      Date: "2024-07-05",
      "Amount to Claimed": 370.5,
      "Claim Amount": 320.25,
      Type: "Health Insurance",
      "Payment Status": "Pending",
      "Claim Status": "Submitted",
      "Amount Claimed": 320.25,
      Filter: "Expense",
    },
  ];
  const [rows, setRows] = useState(rawData);
  const [selectedTable, setSelectedTable] = useState("all");

  const handleProductTypeChange = (e) => {
    const type = e.target.value;
    setSelectedTable(type);
    let healtProducts;
    switch (type) {
      case "Health Insurance":
      case "Loan Insurance":
      case "Travel Insurance":
      case "Motor Insurance":
        console.log(type);
        healtProducts = rawData.filter((product) => product.Type === type);
        setRows(healtProducts);
        break;

      default:
        setRows(rawData);
        break;
    }
  };

  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const titleHeaders = [
    "UserId",
    "Date",
    "Amount to Claimed",
    "Claim Amount",
    "Type",
    "Payment Status",
    "Claim Status",
    "Amount Claimed",
    "Filter",
  ];
  return (
    <Box>
      <Grid container p={3} rowGap={2}>
        <Grid item xs={4}>
          <Typography variant="h4">Policy Claims</Typography>
        </Grid>
        <Grid item xs={8} component={Stack} justifyContent="flex-end" gap={2}>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            type="date"
            label="From"
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            type="date"
            label="To"
          />
        </Grid>
        <Grid item xs={4} />
        <Grid
          item
          xs={8}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          outline="none"
          gap={2}
        >
          <Button
            variant="outlined"
            startIcon={<RotateLeftIcon />}
            onClick={() => {
              setSelectedTable("all");
              setRows(rawData);
            }}
          >
            Reset
          </Button>
          <Button variant="outlined" startIcon={<ViewInArIcon />}>
            Bulk
          </Button>
          <Button
            variant="outlined"
            startIcon={<PolicyIcon />}
            onClick={() => setOpenAdd(true)}
          >
            Policy
          </Button>
          <Button variant="outlined" startIcon={<ReceiptIcon />}>
            CSV
          </Button>
          <TextField
            onChange={handleProductTypeChange}
            size="small"
            sx={{ minWidth: "160px" }}
            value={selectedTable}
            label="Product Type"
            select
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="Motor Insurance">Motor</MenuItem>
            <MenuItem value="Health Insurance">Health</MenuItem>
            <MenuItem value="Travel Insurance">Travel</MenuItem>
            <MenuItem value="Loan Insurance">Loan</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <AddBox data={titleHeaders} open={openAdd} setOpen={setOpenAdd} />
      <EditBox
        data={titleHeaders}
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ bgcolor: "#009eff0a" }}>
            <TableRow>
              {titleHeaders.map((title) => (
                <TableCell>{title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.Name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.UserId}
                </TableCell>
                <TableCell width={120}>{row.Date} </TableCell>
                <TableCell width={160}>{row["Amount to Claimed"]}</TableCell>
                <TableCell width={120}>{row["Claim Amount"]}</TableCell>
                <TableCell>{row["Type"]}</TableCell>
                <TableCell>{row["Payment Status"]}</TableCell>
                <TableCell>{row["Claim Status"]}</TableCell>
                <TableCell>{row["Amount Claimed"]}</TableCell>
                <TableCell>
                  <IconButton
                    sx={{ color: "#23a8fa" }}
                    onClick={() => setOpenEdit(true)}
                  >
                    <Icon color="inherit" sx={{cursor:"pointer"}} >edit</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PolicyClaims;
