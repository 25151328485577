import { FETCH_POSP_FAILURE, FETCH_POSP_SUCCESS} from "../../action-type/posp/actionType";


const initialState = {
    data: null,
    error: null,
};

const pospReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POSP_SUCCESS:
            return { ...state, data: action.payload, error: null };
        case FETCH_POSP_FAILURE:
            return { ...state, data: null, error: action.payload };
        default:
            return state;
    }
};

export default pospReducer;