import { Routes, Route } from "react-router-dom";
import { TravellHomePage } from "../pages/home/TravellHomePage";
import { QuotationPage } from "../pages/quotation/QuotationPage";
import { ProductDetailsPage } from "../pages/productDetail/ProductDetailsPage";
import { ProposalDetailsPage } from "../pages/proposal/ProposalDetailsPage";
import { CarePaymentPage } from "../pages/payment/CarePaymentPage";
import { TravelPaymentSuccess } from "../pages/payment/paymentSucess";

function TravelRoutes() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" Component={TravellHomePage} />
        <Route exact path="/quotes" Component={QuotationPage} />
        <Route exact path="/products" Component={ProductDetailsPage} />
        <Route exact path="/proposals" Component={ProposalDetailsPage} />
        <Route exact path="/carePayment" Component={CarePaymentPage} />
        <Route exact path ="/thankyou" Component={TravelPaymentSuccess}/>
      </Routes>
    </div>
  );
}

export default TravelRoutes;
