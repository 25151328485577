
import React from 'react';
import { Tooltip, Container } from '@mui/material';
import "./ProposalDetails.css";

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const ProposalDetails = () => {
  let navigate = useNavigate();
  const proposalResponse = useSelector((e) => e.loanReducer.ProposalData.intPolicyDataIO.policy);
  console.log(proposalResponse,"response for")
// handle payment details
  const handlePayment = async () => {
    try {
      navigate('/loan/payment');
    } catch (error) {
      console.log(error)
    }
  }

  return (<>

    <Container maxWidth="xl" sx={{ mt: '1em' }}>
      <div className="col-12 col-sm-12 col-md-10 col-lg-11 mx-auto px-3 rounded">
        <div>
          <div>
            <div className="quotation_main_card  mb_25">
              <div className="d_flex quotation_upr_div">
                <div className="logo_container">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/9/90/Care_health_insurance_logo.png"
                    alt="CARE HEALTH"
                    className="logo_box"
                  />
                </div>
                <div className="quotation_content">
                  <div className="content_box_cs">
                    <div className="plane_name plan_details">
                      <div className="title_pa">  
                        Care Insurance
                      </div>
                      <div className="suminsured_text">
                        Sum Insurance :1000000
                      </div>
                    </div>
                    <div className="plan_feature plan_details">
                      <div
                        className="health_check_compare"
                        style={{ marginRight: "30px" }}
                      >
                        <ul
                          className="features_list"
                          style={{ listStyleType: "none" }}
                        >
                          <li className="font-12 pb-3">
                            <VerifiedUserIcon
                              fontSize="small"
                              style={{ marginRight: "5px", color: "#03a9f4" }}
                            />
                            <span
                              data-toggle="tooltip"
                              title=""
                              className=" font-blue"
                              aria-describedby="ui-tooltip-3"
                            >
                              Proposal Number
                            </span>
                            <span> : {proposalResponse.proposalNum}</span>
                          </li>
                          <li className="font-12">
                            <VerifiedUserIcon
                              fontSize="small"
                              style={{ marginRight: "5px", color: "#03a9f4" }}
                            />
                            <span
                              data-toggle="tooltip"
                              title=""
                              className=" font-blue"
                              aria-describedby="ui-tooltip-3"
                            >
                              Loan Amount
                            </span>
                            <span> : {proposalResponse.loanAmount}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="health_check_compare">
                        <ul
                          className="features_list"
                          style={{ listStyleType: "none" }}
                        >
                          <li className="font-12 pb-3">
                            <VerifiedUserIcon
                              fontSize="small"
                              style={{ marginRight: "5px", color: "#03a9f4" }}
                            />
                            <span
                              data-toggle="tooltip"
                              title=""
                              className=" font-blue"
                              aria-describedby="ui-tooltip-3"
                            >
                              Loan Account Number
                            </span>
                            <span> : {proposalResponse.loanAccNum
                            }</span>
                          </li>
                          <li className="font-12">
                            <VerifiedUserIcon
                              fontSize="small"
                              style={{ marginRight: "5px", color: "#03a9f4" }}
                            />
                            <span
                              data-toggle="tooltip"
                              title=""
                              className=" font-blue"
                              aria-describedby="ui-tooltip-3"
                            >
                              Disbursal Date
                            </span>
                            <span> : {proposalResponse.loanDisbursalDt
                            }</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="text_right hal_buy_btn_btn plan_details">
                      <button className="buy_btn" onClick={() => { handlePayment() }}>
                        <Tooltip title="Buy Plan" placement="bottom">
                          <span>
                            <i className="fa-solid fa-indian-rupee-sign mr_5"></i>₹
                            {proposalResponse.premium}
                          </span>
                        </Tooltip>
                        <p>*Excluding GST</p>
                        <small>Buy Now</small>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d_flex quotation_bottom_div">
                <button className="features_btn" >
                  Features <ChevronRightIcon />
                </button>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </>
  );
};

export default ProposalDetails;


