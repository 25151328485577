// assets
import { IconDashboard ,IconHelp , IconSettings, IconUser, IconListDetails,IconShieldPlus , IconExclamationCircle} from '@tabler/icons-react';

// constant
// const icons = { IconDashboard ,IconHelp, IconSettings, IconUser, IconListDetails,IconShieldPlus , IconExclamationCircle};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  // title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: 'dashboard',
      icon: IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'profile',
      title: 'profile',
      type: 'item',
      url: 'profile',
      icon: IconUser,
     
    },

    {
      id: 'policies',
      title: 'Policies',
      type: 'item',
      url: 'policies',
      icon: IconListDetails,
      breadcrumbs: false
    },
    {
      id: 'policy-claims',
      title: 'Policy Claims',
      type: 'item',
      url: 'policy-claims',
      icon: IconShieldPlus,
      breadcrumbs: false
    },
    {
      id: 'register-claims',
      title: 'Register Claim',
      type: 'item',
      url: 'register-claims',
      icon: IconExclamationCircle,
      breadcrumbs: false
    },
    {
      id: 'setting',
      title: 'Settings',
      type: 'item',
      url: 'setting',
      icon: IconSettings,
      breadcrumbs: false
    },
    {
      id: 'helpline',
      title: 'Helpline',
      type: 'item',
      url: 'helpline',
      icon: IconHelp,
      breadcrumbs: false
    },
  ]
};

export default dashboard;
