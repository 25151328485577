import React, { useState, useEffect } from "react";

import health from "../../../Framework/Images/Health-Home.png";
import bike from "../../../Framework/Images/Bike.svg";
import commercial from "../../../Framework/Images/commercial.svg";
import travel from "../../../Framework/Images/plane.png";
import loan from "../../../Framework/Images/Loan.svg";
import { useNavigate } from "react-router-dom";
import car from "../../../Framework/Images/car.svg";
import {
  customerInquiryData,
  InsurerNamesSuccessRequest,
  NCBSuccessRequest,
  RelationSuccessRequest,
  citySuccessRequest,
  getCityData,
  getCityStateWithPincode,
  getInsurerName,
  getNCBValue,
  getRelations,
  getSuccessPincode,
  getVehicleMaster,
} from "../../../Redux/Action";
import {
  GetDataLocalStorage,
  carReqObj,
  AddToLocalStorage,
} from "../../../Framework/GlobalObj";
import { useDispatch } from "react-redux";
import "../../../Framework/Pages/Main.css";
import RoadBike from "../../../Vehicle/Images/Bike-Page/BikeGif.gif";
import RoadImg from "../../../Vehicle/Images/commercial-page/truckwithbuilding.gif";
import roadCar from "../../../Vehicle/Images/Car-Page/carwithbuilding.gif";
import RegistrationNumberFetching from "../../../Vehicle/Components/RegistrationNumberFetching/RegistrationNumberFetching";
import {
  Box,
  Button,
  Card,
  useTheme,
  Container,
  Grid,
  Typography,
  Chip,
} from "@mui/material";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";
import { Stack } from "@mui/system";
import AddsAdmin from "../../../Framework/Pages/AddsAdmin/AddsAdmin";

const PolicyPurchase = () => {
  const theme = useTheme();
  const [req, setReq] = useState(carReqObj);
  const [activeCards, setActiveCards] = useState({
    "Pvt Car": true,
    MotorBike: false,
    "Goods Carrying": false,
  });
  const [imageSrc, setImageSrc] = useState(roadCar);
  const [isSubmited, setIsSubmited] = useState(false);

  //Testing
  const [customerDetails, setCustomerDetails] = useState({
    customer_Name: "",
    phone: "",
  });

  const [reqs, setReqs] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Mui States for modal
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let ncbData = GetDataLocalStorage("NcbData");
    let insurenceName = GetDataLocalStorage("InsurenceCompanyName");
    // let vehicleMaster = GetDataLocalStorage("vehicleMasterData");
    let cityData = GetDataLocalStorage("cityData");
    let pincode = GetDataLocalStorage("pincodeData");
    let relation = GetDataLocalStorage("relationData");
    // dispatch(getMarketingData());
    ncbData ? dispatch(NCBSuccessRequest(ncbData)) : dispatch(getNCBValue());
    insurenceName
      ? dispatch(InsurerNamesSuccessRequest(insurenceName))
      : dispatch(getInsurerName());
    cityData ? dispatch(citySuccessRequest(cityData)) : dispatch(getCityData());
    pincode
      ? dispatch(getSuccessPincode(pincode))
      : dispatch(getCityStateWithPincode());
    relation
      ? dispatch(RelationSuccessRequest(relation))
      : dispatch(getRelations());
  }, []);

  const handleClick = (value) => {
    const updatedActiveCards = { ...activeCards };
    for (const cardType in updatedActiveCards) {
      updatedActiveCards[cardType] = cardType === value;
    }

    setActiveCards(updatedActiveCards);
    let obj = { ...req };
    if (value === "Pvt Car") {
      setImageSrc(roadCar);
      obj.vehicle.vehicleType = "Car";
      obj.profile.customer.policyType = "Pvt Car";
      AddToLocalStorage("globalObj", obj);
      setReq(obj);
      dispatch(getVehicleMaster(value));
      //  navigate('/motor/car');
    } else if (value === "MotorBike") {
      setImageSrc(RoadBike);
      obj.vehicle.vehicleType = "Bike";
      obj.profile.customer.policyType = "MotorBike";
      AddToLocalStorage("globalObj", obj);
      setReq(obj);
      dispatch(getVehicleMaster(value));
      // navigate('/motor/bike');
    } else if (value === "Goods Carrying") {
      setImageSrc(RoadImg);
      obj.vehicle.vehicleType = "Commercial";
      obj.profile.customer.policyType = "Goods Carrying";
      AddToLocalStorage("globalObj", obj);
      setReq(obj);
      dispatch(getVehicleMaster("Goods Carrying"));
      // navigate('/motor/commercial');
    }
  };

  // console.log("reqqqq", req);

  useEffect(() => {
    let obj = GetDataLocalStorage("globalObj");
    if (obj) {
      setReqs(obj);
    } else {
      setReqs(carReqObj);
    }
  }, []);

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            zIndex: -1,
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <img
            src={imageSrc}
            alt=""
            style={{
              position: "absolute",
              width: "100%",
              left: 0,
              bottom: 0,
            }}
          />
        </Box>
        <Container maxWidth="lg">
          <Grid container spacing={2} mt={1} flexGrow={1}>
            <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={200}>
                <div
                  className={`addition-service-card ${
                    activeCards["Pvt Car"] ? "active" : ""
                  }`}
                  onClick={() => handleClick("Pvt Car")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="addition-service-card-img">
                    <img src={car} alt="" />
                  </div>
                  <div className="addition-service-card-title">Car</div>
                </div>
              </Grow>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={300}>
                <div
                  className={`addition-service-card ${
                    activeCards["MotorBike"] ? "active" : ""
                  }`}
                  onClick={() => handleClick("MotorBike")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="addition-service-card-img">
                    <img src={bike} alt="" />
                  </div>
                  <div className="addition-service-card-title">Bike</div>
                </div>
              </Grow>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={400}>
                <div
                  className={`addition-service-card ${
                    activeCards["Goods Carrying"] ? "active" : ""
                  }`}
                  onClick={() => handleClick("Goods Carrying")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="addition-service-card-img">
                    <img src={commercial} alt="" />
                  </div>
                  <div className="addition-service-card-title">Commercial</div>
                </div>
              </Grow>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={500}>
                <div
                  className="addition-service-card"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/health")}
                >
                  <div className="addition-service-card-img">
                    <img src={health} alt="" />
                  </div>
                  <div className="addition-service-card-title">Health </div>
                  {/* <div className="tooltip">Coming Soon</div> */}
                </div>
              </Grow>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={600}>
                <div
                  className="addition-service-card"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/travel")}
                >
                  <div className="addition-service-card-img">
                    <img src={travel} alt="" />
                  </div>
                  <div className="addition-service-card-title">Travel </div>
                  {/* <div className="tooltip">Coming Soon</div> */}
                </div>
              </Grow>
            </Grid>
            <Grid item xs={4} sm={3} md={3} lg={2}>
              <Grow in={true} timeout={700}>
                <div
                  className="addition-service-card"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/loan")}
                >
                  <div className="addition-service-card-img">
                    <img src={loan} alt="loan" />
                  </div>
                  <div className="addition-service-card-title">Loan </div>
                </div>
              </Grow>
            </Grid>
          </Grid>
        </Container>

        <Slide
          direction="up"
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={400}
        >
          <Container
            maxWidth="sm"
            sx={{
              mt: 8,
              [theme.breakpoints.up("md")]: { mt: 5 },
              [theme.breakpoints.up("xs")]: { mt: 1 },
              // border: "solid"
            }}
          >
            <div className="fetching-section">
              {req && Object.keys(req).length > 0 ? (
                <RegistrationNumberFetching req={req} setreq={setReq} />
              ) : (
                ""
              )}
            </div>
          </Container>
        </Slide>
      </Box>
      <Container sx={{ mt: { xs: 5, md: 10 } }}>
        <Box mb={5}>
          <AddsAdmin />
        </Box>
        <Card sx={{ p: 3, my: 2 }} elevation={3}>
          <Typography
            gutterBottom
            textAlign="center"
            variant="h3"
            fontWeight={600}
          >
            Polices On Queue
          </Typography>
          <Stack rowGap={2} mt={2}>
            {[1, 2, 3, 4, 5].map((e) => (
              <Card
                key={e}
                elevation={1}
                sx={{ display: "flex", padding: "1rem" }}
              >
                <Box flex={2} display={"flex"} alignItems="center">
                  <Box flex={1}>
                    <Typography>Transaction Id : 12345XXXX</Typography>
                  </Box>
                  <Box flex={1}>
                    <Typography>Vara Krishna</Typography>
                  </Box>
                  <Box flex={1}>
                    <Typography>23/11/2024</Typography>
                  </Box>
                </Box>
                <Box
                  flex={1}
                  display={"flex"}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box flex={1}>
                    <Chip color="info" variant="filled" label="Car Insurance" />
                  </Box>
                  <Button variant="contained">Continue</Button>
                </Box>
              </Card>
            ))}
          </Stack>
        </Card>
      </Container>
    </div>
  );
};

export default PolicyPurchase;
