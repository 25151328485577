import React, { useEffect } from "react";
import Loader from '../../../Framework/Components/Loader';
import { useSelector } from "react-redux";
import { Grid,Box } from "@mui/material";



 function CarePaymentPage() {
  const proposalNum = useSelector((state) =>state.travelReducer.proposalResponse.intPolicyDataIO.policy.proposalNum);
 
  const handleSubmit = async () => {
    try {
      const form = document.getElementById("form");
      let data = await form.submit()
      return data
    } catch (error) {
      console.error("Error during payment:", error);
    }
  };
 
  useEffect(() => {
    setTimeout(() => {
      handleSubmit();
    }, 1000);
  }, [proposalNum]);
 
  return (
    <Grid>
      <Box sx={{minHeight: "calc(100vh - 75px)",display:'grid',placeItems:"center",backgroundColor:"#0000004f"}}><Loader /></Box>
       <form
        action="https://apiuat.religarehealthinsurance.com/portalui/PortalPayment.run"
        name="PAYMENTFORM"
        method="post"
        id="form"
      >
        <input type="hidden" name="proposalNum" value={proposalNum} />
        <input type="hidden" name="returnURL" value="https://main.d315p1596xx1q3.amplifyapp.com/travel/thankyou"/>
      </form> 
    </Grid>
  );
}


export {CarePaymentPage};