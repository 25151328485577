import { CancelRounded } from "@mui/icons-material";
import {
  alpha,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";
import { useForm } from "react-hook-form";

const EditBox = ({ data, openEdit, setOpenEdit }) => {
  const { register, handleSubmit } = useForm();

  if (!data || data.length === 0) {
    return null;
  }
               
  const record = data[0];
  console.log(record);

  const onSubmit = (formData) => {
    console.log("Submitted Data: ", formData);
    setOpenEdit(false);
  };

  return (
    <Dialog TransitionComponent={Slide} keepMounted open={openEdit} fullWidth>
      <DialogTitle>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Typography align="center" variant="h2">
            Edit Record
          </Typography>
          <IconButton onClick={() => setOpenEdit((prev) => !prev)}>
            <CancelRounded />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{ backgroundColor: alpha(blue[500], 0.1) }}>
            <CardContent>
              <Grid container columnSpacing={3} mt={3} rowGap={3}>
                {Object.keys(record).map((key, index) => (
                  <Grid key={index} item xs={4}>
                    <TextField
                      fullWidth
                      size="small"
                      label={key.replace(/_/g, " ").toUpperCase()}
                      {...register(key)}
                      defaultValue={record[key]}
                    />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
          <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
            <Button type="submit" variant="contained" color="primary">
              Submit Details
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditBox;
