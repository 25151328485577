import axios from "axios";
// import { health_api } from "../../Config";

const URL = "https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/Health/healthpincodedata.json";

export  const fetchPincode = async () => {
  try {
    const response = await axios.get(URL);
    console.log(response, "5652532");
    return response.data;
  } catch (error) {
    console.error("Error Fetching Hospitals:", error.message);
    return error;
  }
};
