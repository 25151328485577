/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { QuotationCard } from "./QuotationCard";
import Loader from '../../../Framework/Components/Loader';
import { useEffect, useState } from "react";
import { premiumCalculation } from "../../api/service";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";

export const QuotationPage = () => {
  const { t } = useTranslation();
  const travelContent =  (t("travel"));
  let [planNotAvali, setPlanNotAvail] = useState(false); // radio btn enable for multi-trip plan avail
  let [suitablePlans, setSuitablePlans] = useState([]); // store the plans from api
  let [tripType, setTripType] = useState(false); //toggle single multi
  let data = useSelector((e) => e.travelReducer.formData);
  let [loading, setLoading] = useState(false);

  let dispatch = useDispatch();
  let {
    selectedCountries,
    proposerName,
    proposerMobileNo,
    planType,
    ...postdata
  } = data;

  async function getQuote() {
    // DISABLE TO SELECT MULTI-TRIP IF IT'S AFRICA ANZ EUROPE CANADA
    if (planType.length < 2 && planType[0] !== "Asia") {
      setPlanNotAvail(true); // disable multi-trip
    }
    dispatch({
      type: "HANDLE_FORM_DATA",
      payload: { tripType: tripType ? "Multi-trip" : "Single" },
    });
    setSuitablePlans([]);

    try {
      setLoading(true);
      for (let i = 0; i < planType.length; i++) {
        let newPostData = {
          ...postdata,
          planName: planType[i],
          sumInsured: "",
          tripType: tripType ? "Multi-trip" : "Single",
          tripDuration: tripType ? 366 : postdata.tripDuration,
        };
        let postBody = {
          partnerId: "303",
          abacusId: "5216",
          postedField: { ...newPostData },
        };
        let res = await premiumCalculation(postBody); //QUOTATION API
        if (res !== undefined) {
          setSuitablePlans((prev) => [...prev, res]);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getQuote();
  }, [tripType]);

  function handleTripChange() {
    // SINGLE OR MULTI-TRIP
    setTripType((prev) => !prev);
  }

  return (
    <div style={{ minHeight: "calc(100vh - 75px)", maxHeight: "fit-content" }}>
      <div className="border col-12 col-sm-12 col-md-6 col-lg-3 mx-auto px-3 rounded my-3">
        <h6 className="text-center py-2 rounded mt-2" style={{ background: "#23a8fa",color:"#fff" }}>
         {travelContent.quotes.plan_type}
        </h6>
        <RadioGroup
          onChange={handleTripChange}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="Single"
          name="radio-buttons-group"
        >
          <div className="row">
            <h6 className="col-6">
              <FormControlLabel
                value="Single"
                control={<Radio />}
                label={travelContent.quotes.trip_single}
              />
            </h6>
            <div className="col-6">
              <FormControlLabel
                 disabled={planNotAvali}
                value="Multi-Trip"
                control={<Radio />}
                label={travelContent.quotes.trip_multi}
              />
            </div>
          </div>
        </RadioGroup>
      </div>

      <div className="col-12 col-sm-12 col-md-10 col-lg-11 mx-auto px-3 rounded">
        <div>
          {suitablePlans.length !== 0 ? (
            suitablePlans.map((e, _) => (
              <QuotationCard planType={planType} data={e} key={_} id={_} />
            ))
          ) : (
            <></>
          )}
          {loading && <Loader />}
        </div>
      </div>
    </div>
  );
};
