import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { AddToLocalStorage, GetDataLocalStorage } from '../../Framework/GlobalObj';
import { Box, CircularProgress } from '@mui/material';
import { vehicle_api } from '../../Config';
import { useSearchParams } from 'react-router-dom';

const HdfcKYCPage = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [parms] = useSearchParams();
  const [send1, setSend] = useState(false);
  const [res, setRes] = useState({});

  useEffect(() => {
    let req = GetDataLocalStorage('globalObj');
    req.insurance.kyc.pehchanId = parms?.get('kyc_id');
    const fetchData = async() => {
    if (parms?.get('status') === 'pending') {
      axios
        .post(`${vehicle_api}/insurance/v1/motor/policy/proposal`, req)
        .then(async(res) => {
          let resObj = res?.data?.body;
          setRes(resObj);
          if (resObj?.StatusCode === 200) {
            console.log("enetered in if condition")
            let applicationId = resObj?.Customer_Details?.CustomerID || null;
            let proposalNumber = resObj?.Policy_Details?.ProposalNumber || null;
            let totalpremium = resObj?.Policy_Details?.TotalPremium || null;
            let successUrl = `https://main.d315p1596xx1q3.amplifyapp.com/motor/car/payment/success`;
            let failureUrl = `https://main.d315p1596xx1q3.amplifyapp.com/motor/car/payment/failed`;
            req.profile.customer.applicationId = applicationId;
            req.profile.customer.proposalNumber = proposalNumber;
            req.insurance.grossPremium = totalpremium;
            req.insurance.netPremium = resObj?.Policy_Details?.NetPremium;
            AddToLocalStorage('globalObj', req);
            const currentDate = new Date().toISOString().slice(2, 10).replace(/-/g, '');
            let randomDigits = Math.floor(Math.random() * 100000).toString().padStart(5, '0');
            const trnsno = `1127${currentDate}${randomDigits}`;

            req.insurance.instrumentNumber = trnsno;

            // Dynamically generate the trnsno with the current date
            await axios
              .get(`https://heapp21.hdfcergo.com/UAT/PaymentUtilitiesService/PaymentUtilities.asmx/GenerateRequestChecksum?TransactionNo=${trnsno}&TotalAmount=${totalpremium}&AppID=${'10295'}&SubscriptionID=S000000333&SuccessUrl=${successUrl}&FailureUrl=${failureUrl}&Source=POST`)
              .then(async (res) => {
                let checksum = res?.data?.split('>')[2].split('<')[0];
                req.insurance.checksum = Number(checksum);
                console.log('res cheksum', res, checksum);
                let data1 = {
                  trnsno: trnsno,
                  amt: `${totalpremium}`,
                  appid: '10295',
                  subid: 'S000000333',
                  surl: `${successUrl}`,
                  furl: `${failureUrl}`,
                  src: 'POST',
                  chksum: `${checksum}`
                };
                setData(data1);
                AddToLocalStorage('globalObj', req);
                setSend(true);
                console.log(send1, "sending the request")
                setLoading(false);
                console.log(data, 'data');
                // Simulate a 3-second delay before redirecting
              })
              .catch((err) => {
                console.log('checksum error', err);
              });
          }
        })
        .catch((err) => {
          console.log('proposal error', err);
        });
    } else {
      alert('KYC is not in complete state');
    }
    };
    fetchData();
  }, []);

  if (send1 || res?.StatusCode === 200) {
  setTimeout(() => {
  document?.getElementById('form')?.submit();
  }, 1000);
  }

 console.log(data,"data")
  return (
    <div>
      {loading ? (
        <Box sx={{ height: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <form action="https://heapp21.hdfcergo.com/UAT/OnlineProducts/CCPGISUBSCRIBER/MakePayment.aspx" name="PAYMENTFORM" method="POST" _lpchecked="1" id="form">
          <div>
            <input type="hidden" name="trnsno" value={data?.trnsno} />
            <input type="hidden" name="amt" value={data?.amt} />
            <input type="hidden" name="appid" value={data?.appid} />
            <input type="hidden" name="subid" value={data?.subid} />
            <input type="hidden" name="surl" value={data?.surl} />
            <input type="hidden" name="furl" value={data?.furl} />
            <input type="hidden" name="src" value="POST" />
            <input type="hidden" name="chksum" value={data?.chksum} />
            <input type="hidden" name="Additionalinfo1" value="" />
            <input type="hidden" value="submit" />
          </div>
        </form>
      )}
    </div>
  );
};

export default HdfcKYCPage;
