import { useNavigate, useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../../posp/layout/MainLayout/Sidebar";
import { SET_MENU } from "../../../../Redux/crmustomization/actions";
import { drawerWidth } from "../../../../Redux/crmustomization/constant";
import { Box, Toolbar, useMediaQuery, AppBar, LinearProgress } from "@mui/material";
import getMenuItems from "../../../admin/menu-items/index";
import Header from "../../../posp/layout/MainLayout/Header";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect } from "react";
import '../../../../crm/posp/assets/scss/style.scss'
import AdminHome from "../../pages/AdminHome";
import UserIdManagement from "../../pages/UserIdManagement";
import BusinessManagement from "../../pages/BusinessManagement";
import SalesManagement from "../../pages/SalesManagement";
import PolicyClaims from "../../pages/PolicyClaims";
import QuotePerformance from "../../pages/QuotePerformance";
import Support from "../../pages/Support";
import IC from "../../pages/IC";
import PendingCases from "../../pages/PendingCases";
import AgentActivationPanel from "../../pages/AgentActivationPanel";
import RenewNotificationPanel from "../../pages/RenewNotificationPanel";
import RenewPanel from "../../pages/RenewPanel";
import BranchAddition from "../../pages/BranchAddition";
import FinanceRevenue from "../../pages/FinanceRevenue";
import Settings from "../../pages/Setting";
import SelfInspectionPanel from "../../pages/SelfInspectionPanel";
import PolicyPurchase from "../../pages/PolicyPurchase";
import { getEmployeeProfile } from "../../api/adminService";
import NewUser from "../../pages/NewUser/NewUser";
import Profile from "../../pages/Profile/profile";
import { handleLoginData } from "../../../../Redux/admin/action";

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "theme",
})(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    "margin",
    open
      ? {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }
      : {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }
  ),
  [theme.breakpoints.up("md")]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`,
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "20px",
    width: `calc(100% - ${drawerWidth}px)`,
    padding: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "10px",
    width: `calc(100% - ${drawerWidth}px)`,
    padding: "16px",
    marginRight: "10px",
  },
}));

const AdminNavbar = () => {
  const phno = useSelector((state) => state.auth.data.phone);
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const profile = useSelector((state) => state.adminReducer.loginData);


  const fetchEmployeeProfile = async () => {
    const res = await getEmployeeProfile(phno);
    dispatch(handleLoginData(res.data))
  }

  useEffect(() => {
    fetchEmployeeProfile()
  }, [])





  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  const routeMapping = {
    home: <AdminHome />,
    useridmanagement: <UserIdManagement />,
    bussiness: <BusinessManagement />,
    salesmanagement: <SalesManagement />,
    claims: <PolicyClaims />,
    quoteperformance: <QuotePerformance />,
    settings: <Settings />,
    support: <Support />,
    revenuemanagement: <IC />,
    pendingcases: <PendingCases />,
    agentactivationpanel: <AgentActivationPanel />,
    renewnotificationpanel: <RenewNotificationPanel />,
    renewpanel: <RenewPanel />,
    selfinspectionpanel: <SelfInspectionPanel />,
    addbranch: <BranchAddition />,
    finance: <FinanceRevenue />,
    policypurchase: <PolicyPurchase />
  }

  const EmployeeRoutes = () => useRoutes([
    ...profile.pages.map((childRoutes, _) => {
      return {
        index: _ === 0,
        path: _ !== 0 ? childRoutes.path : "",
        element: routeMapping[childRoutes.path],
        children: childRoutes.path === 'useridmanagement' ? [
          {
            path: 'newuser',
            element: <NewUser />
          }
        ] : childRoutes.path === 'salesmanagement' ? [
          { path: 'profile/agent/:id', element: <Profile /> },
          { path: 'profile/customer/:id', element: <Profile /> }
        ]
          : childRoutes.path === 'pendingcases' ? [
            { path: 'profile/agent/:id', element: <Profile /> },
            { path: 'profile/customer/:id', element: <Profile /> }
          ] : childRoutes.path === 'agentactivationpanel' ? [
            { path: 'profile/agent/:id', element: <Profile /> }
          ] : childRoutes.path === 'selfinspectionpanel' ? [
            { path: 'profile/agent/:id', element: <Profile /> },
            { path: 'profile/customer/:id', element: <Profile /> }
          ] : []
      }
    }),
    {
      path: '*',
      element: <>No Routes Matched</>
    }
  ]);


  return (
    <Box>{
      profile ?
        <Box sx={{ display: "flex" }}>
          <AppBar
            enableColorOnDark
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{
              bgcolor: theme.palette.background.default,
              transition: leftDrawerOpened
                ? theme.transitions.create("width")
                : "none",
            }}
          >
            <Toolbar>
              <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
            </Toolbar>
          </AppBar>
          <Sidebar
            drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
            drawerToggle={handleLeftDrawerToggle}
            menuItems={getMenuItems(profile)}
          />
          <Main theme={theme} open={leftDrawerOpened}>
            <EmployeeRoutes />
          </Main>
        </Box>
        :
        <LinearProgress />
    }
    </Box>
  );
};

export default AdminNavbar;
