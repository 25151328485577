export const styles = {
    gridContainer: {
      padding: { xs: "0", md: "2em" },
      rowGap: "2em",
    },
    box: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      width: "100%",
    },
    buttonActive: {
      transition: "all .2s linear",
      backgroundColor: "#23a8fa",
      color: "#fff",
      width: "55px",
      height: "55px",
      boxShadow: "#23a8fa8a 0px 15px 25px, #23a8fa60 0px 5px 10px",
    },
    buttonInactive: {
      fontSize: "1.3em",
      width: "55px",
      height: "55px",
      cursor: "pointer",
      borderRadius: "5px",
      boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
    },
    centerContent: {
      display: "flex",
      justifyContent: "center",
    },
    inputField: {
      padding: "1em",
    },
    centeredText: {
      width: "100%",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "18px",
    },
    spacedButtons: {
      display: "flex",
      justifyContent: "center",
      mt: 2,
    },
  };
  
