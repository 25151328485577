import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddToLocalStorage, GetDataLocalStorage } from '../../../Framework/GlobalObj';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import gst from '../../Images/EXgst-01.png';
import { currencyFormat } from '../../../Framework/Utils/CurrencyFormat';
import '../../../Framework/Pages/Main.css';
import { Card, Button, CardMedia, Checkbox, FormControlLabel, FormGroup, Grid, DialogActions, Dialog, Slide, DialogTitle, IconButton, DialogContent, DialogContentText, } from '@mui/material';
import bajajImage from '../../Images/bajaj.png'
import godigitImage from '../../Images/digit.png'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';


const QuoteCard = ({ data, handleCheckBox, req, setReq, idvAmount, response }) => {
  
  const [go, setGo] = useState(false);
  const [arr, setArr] = useState([]);
  const [provider, setprovider] = useState('');
  const [check, setCheck] = useState(false);
  const [imgurl, setImgUrl] = useState('');
  const [openModel, setOpenModel] = React.useState(false);
  const [idv, setIdv] = useState();
  const [getLocalData, setgetLocalData] = useState({});
  const navigate = useNavigate();
  const handleOpenModel = () => setOpenModel(true);
  const handleCloseModel = () => setOpenModel(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [expanded, setExpanded] = React.useState(false);
  const [insurerData, setInsurerData] = useState([]);
  const insurerName = useSelector((store) => store.motorReducer.insurerNames);
  const quotation = useSelector((store) => store.motorReducer.quotationData);

  useEffect(() => {
    if (Array?.isArray(insurerName)) {
      insurerName?.forEach((el) => {
        if (data?.providerName === el.shortCode || '') {
          setImgUrl(el.ImageUrlLink);
        }
      });
      if (data?.providerName === 'GODIGIT') {
        setImgUrl(godigitImage);
      }
      if (data.providerName === 'BAJAJ') {
        setImgUrl(bajajImage)
      }
    }
    setgetLocalData(GetDataLocalStorage('globalObj'));
  }, []);

  useEffect(() => {
    handleCheckBox(data, check);
  }, [check]);

  useEffect(() => {
    if (response) {
      response.forEach((res) => {
        let min = res?.vehicleIDV?.vehicleIDV?.minimumIdv;
        let max = res?.vehicleIDV?.vehicleIDV?.maximumIdv;
        let providerName = res?.providerName;
        if (idvAmount >= min && idvAmount <= max) {
          setArr([res?.vehicleIDV?.vehicleIDV]);
          setGo(true);
          setprovider(providerName);
        }
      });
    }
  }, [idvAmount]);

  useEffect(() => {
    let dataIdv = data?.vehicleIDV?.vehicleIDV?.idv;
    if (dataIdv === '') {
      const storedIdv = GetDataLocalStorage('globalObj');
      const idvValue = storedIdv?.vehicle?.vehicleIDV?.idv;
      setIdv(idvValue);
    } else {
      setIdv(dataIdv);
    }
  }, []);

  const handleChange = (e) => {
    setCheck(e.target.checked);
  };

  const handleExpandedChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onClickHandler = () => {
    let obj = { ...req };
    obj.insurance.providerName = data.providerName;
    obj.insurance.netPremium = data?.netPremium;
    obj.insurance.grossPremium = data?.grossPremium;
    obj.vehicle.vehicleIDV.idv = data.vehicleIDV.idv;
    setReq(obj);
    AddToLocalStorage('globalObj', obj);
    switch (req.vehicle.vehicleType) {
      case 'Car':
        console.log(response, "Navigating to Car Proposal Page...");
        navigate('/motor/car/proposal', { state: response });
        break;
      case 'Bike':
        console.log(response, "Navigating to Bike Proposal Page...");
        navigate('/motor/bike/proposal', { state: response });
        break;
      case 'Commercial':
        console.log(response, "Navigating to Commercial Proposal Page...");
        navigate('/motor/commercial/proposal', { state: response });
        break;
      default:
        console.error("Unknown vehicle type:", req.vehicle.vehicleType);
    }
  };

  let selectedAddonsTotal = 0;
  console.log(data, "hello data")
  Object.keys(data?.coverages?.addons || {}).forEach((addon_name) => {
    let related_name = data?.coverages?.addons[addon_name];
    if (related_name?.selection) {
      const addonAmount = parseFloat(related_name?.netPremium);
      selectedAddonsTotal += addonAmount;
    }
  });

  let selectedAdditionalTotal = 0;
  let selectedUnanmedTotal = 0;
  Object?.keys(data?.coverages?.unnamedPA || {})?.forEach((selected_option) => {
    let related_name = data?.coverages?.unnamedPA[selected_option];
    if (related_name?.selection) {
      const unNamedPaCover = parseFloat(related_name?.netPremium?.split(' ')[1]);
      selectedUnanmedTotal += unNamedPaCover;
    }
  });
  const adjustedNetPremium = Math.floor(selectedAdditionalTotal) + selectedUnanmedTotal;
  let discountAmount;
  if (data?.providerName === 'BAJAJ') {
    const uwAmount = parseFloat(data?.discounts?.otherDiscounts[0]?.CAmount);
    const ncbAmount = parseFloat(data?.discounts?.otherDiscounts[0]?.NcbAmount);
    discountAmount = uwAmount + ncbAmount;
  } else if (data?.providerName === 'HDFC') {
    const ncbAmount = parseFloat(data?.discounts?.otherDiscounts[0]?.NcbAmount);
    discountAmount = ncbAmount;
  } else if (data?.providerName === 'GODIGIT') {
    const ncbAmount = parseFloat(data?.discounts?.otherDiscounts[0]?.NcbAmount);
    discountAmount = ncbAmount;
  }else if (data?.providerName === 'ICICI') {
    const ncbAmount = parseFloat(data?.discounts?.otherDiscounts[0]?.NcbAmount);
    discountAmount = ncbAmount;
  }

  return (
    <Grid item xs={12} sm={6} >
      <div>
        <>
          <Card sx={{ p: 2, borderRadius: '9px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
            <Box display={'flex'}>
              <Box flex={1} sx={{ display: "flex", flexDirection: { xs: 'column', lg: 'row' }, rowGap: 2 }}>
                {data?.providerName === 'GODIGIT' ? <CardMedia
                  component="img"
                  sx={{ borderRadius: '0.6em', height: { xs: 60 }, width: { xs: 100, md: 120 } }}
                  image={godigitImage}
                /> : <CardMedia
                  component="img"
                  sx={{ borderRadius: '0.6em', height: { xs: 60 }, width: { xs: 100, md: 120 } }}
                  image={imgurl}
                />}
                <Box flex={1} display={'flex'} sx={{ justifyContent: { lg: 'center' } }}>
                  <Box sx={{ display: { xs: 'flex', lg: 'block' }, gap: 1 }}>
                    <Typography gutterBottom variant='body1' color={'text.secondary'}><b>IDV Cover</b></Typography>
                    <Typography variant='body2' color={'text.primary'}>
                      ₹<b style={{ fontSize: '18px' }}>{currencyFormat(Math.floor(data?.vehicleIDV?.vehicleIDV.idv))}</b>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box >
                <Button onClick={onClickHandler} sx={{ borderRadius: '0.4em' }} variant='contained' endIcon={<ArrowForwardRoundedIcon />}>
                  <Typography variant='body1'>
                    {data?.providerName === 'HDFC' ? <Typography> ₹ {currencyFormat(Math.floor(data?.netPremium.split(' ')[1]))}</Typography> : <Typography> ₹ {currencyFormat(Math.floor(data?.netPremium))}</Typography>}
                  </Typography></Button>
              </Box>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <FormGroup>
                <FormControlLabel control={<Checkbox onChange={(e) => handleChange(e)} size='small' />} sx={{ fontStyle: 'bold' }} label="Compare" />
              </FormGroup>
              <Box>
                <Typography onClick={handleOpenModel} sx={{ cursor: 'pointer', fontStyle: 'bold' }} variant='caption' color={'text.secondary'}><b>View Details</b></Typography>
              </Box>
            </Box>
          </Card>
        </>
        {/* )} */}

        <Dialog TransitionComponent={Slide} open={openModel} scroll='paper' fullWidth>
          <DialogTitle sx={{ justifyContent: 'space-between', display: 'flex' }}>
            <Typography variant='h4'>Premium Breakup</Typography>
            <IconButton onClick={handleCloseModel}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}>

              {getLocalData?.vehicle?.isVehicleNew === true || getLocalData?.insurance?.claimsMade === true ? '' : <Accordion>
                <AccordionSummary aria-controls="panel4bh-content" id="panel4bh-header">
                  <Typography sx={{ width: '51%', flexShrink: 0 }}>Premium Without Discount</Typography>
                  {data.providerName === 'HDFC' ? <Typography sx={{ color: 'text.secondary' }}>₹ {currencyFormat(Math.floor(data?.totalPremium))}</Typography> : <Typography sx={{ color: 'text.secondary' }}>₹ {currencyFormat(Math.floor(data?.totalPremium))}</Typography>
                  }
                </AccordionSummary>
              </Accordion>}
              {getLocalData?.vehicle?.isVehicleNew === true || getLocalData?.insurance?.claimsMade === true ? (
                ''
              ) : (
                <Accordion expanded={expanded === 'panel2'} onChange={handleExpandedChange('panel2')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <Typography sx={{ width: '51%', flexShrink: 0 }}>Discounts</Typography>
                    {data.providerName === 'BAJAJ' ? <Typography sx={{ color: 'text.secondary' }} style={{ marginLeft: '10px' }} className="addon-amount">
                      ₹ {currencyFormat(Math.floor(discountAmount)) || 0}
                    </Typography>:<Typography sx={{ color: 'text.secondary' }} style={{ marginLeft: '10px' }} className="addon-amount">
                      ₹ -{currencyFormat(Math.floor(discountAmount)) || 0}
                    </Typography>}
                  </AccordionSummary>
                  <AccordionDetails sx={{ maxHeight: '150px', overflowY: 'scroll' }}>
                    <div>
                      <ul>
                        {data?.providerName === 'BAJAJ' ? (
                          <>
                            <li>
                              Commercial Discount - <span style={{ marginLeft: '95px' }}>{currencyFormat(Math.floor(data?.discounts?.otherDiscounts[0]?.CAmount)) || 0}</span>
                            </li>
                            <li>
                              NCB Discount - <span style={{ marginLeft: '140px' }}>{currencyFormat(Math.floor(data?.discounts?.otherDiscounts[0]?.NcbAmount)) || 0}</span>
                            </li>
                          </>
                        ) : data?.providerName === 'HDFC' ? (
                          ''
                        ) : (
                          <>
                            <li>
                              NCB Discount - <span style={{ marginLeft: '140px' }}>{currencyFormat(Math.floor(data?.discounts?.otherDiscounts[0]?.NcbAmount)) || 0}</span>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
              {getLocalData?.vehicle?.isVehicleNew === true ? <Accordion>
                <AccordionSummary aria-controls="panel4bh-content" id="panel4bh-header">
                  <Typography sx={{ width: '51%', flexShrink: 0 }}>Premium</Typography>
                  {data.providerName === 'HDFC' ? <Typography sx={{ color: 'text.secondary' }}>₹ {currencyFormat(Math.floor(data?.netPremium?.split(' ')[1]))}</Typography> : <Typography sx={{ color: 'text.secondary' }}>₹ {currencyFormat(Math.floor(data?.netPremium))}</Typography>
                  }
                </AccordionSummary>
              </Accordion> : <Accordion>
                <AccordionSummary aria-controls="panel4bh-content" id="panel4bh-header">
                  <Typography sx={{ width: '51%', flexShrink: 0 }}>Premium With Discount</Typography>
                  {data.providerName === 'HDFC' ? <Typography sx={{ color: 'text.secondary' }}>₹ {currencyFormat(Math.floor(data?.netPremium?.split(' ')[1]))}</Typography> : <Typography sx={{ color: 'text.secondary' }}>₹ {currencyFormat(Math.floor(data?.netPremium))}</Typography>
                  }
                </AccordionSummary>
              </Accordion>}

              <Accordion expanded={expanded === 'panel1'} onChange={handleExpandedChange('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                  <Typography sx={{ width: '51%', flexShrink: 0 }}>Add-ons</Typography>
                  <Typography sx={{ color: 'text.secondary' }} style={{ marginLeft: '10px' }} className="addon-amount">
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ maxHeight: '150px', overflowY: 'scroll' }}>
                  <div>
                    {Object.keys(data?.coverages?.addons || {})?.map((addon_name) => {
                      let related_name = data?.coverages?.addons[addon_name];
                      if (related_name?.selection) {
                        return (
                          <div className="d-flex">
                            <div className="col-md-5">
                              <ul>
                                <li>
                                  {addon_name.charAt(0).toUpperCase() +
                                    addon_name
                                      .slice(1)
                                      .replace(/([A-Z])/g, ` $1`)
                                      .trim()}
                                </li>
                              </ul>
                            </div>
                            {data.providerName === 'HDFC' ? <div className="col-md-2 addons-amount" style={{ marginLeft: '50px' }}>
                              ₹ {currencyFormat(Math.floor(related_name?.netPremium))}
                            </div> : ''}
                          </div>
                        );
                      }
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleExpandedChange('panel3')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                  <Typography sx={{ width: '51%', flexShrink: 0 }}>Personal Accident (Included In Premium)</Typography>
                  <Typography sx={{ color: 'text.secondary' }} style={{ marginLeft: '10px' }}>
                    ₹ {currencyFormat(data?.coverages?.personalAccident?.netPremium)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ maxHeight: '150px', overflowY: 'scroll' }}>
                  <div></div>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel4'} onChange={handleExpandedChange('panel4')}>
                <AccordionSummary aria-controls="panel4bh-content" id="panel4bh-header">
                  <Typography sx={{ width: '49%', flexShrink: 0 }}>
                    <p>Taxes</p>
                    <p>GST@18</p>
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }} style={{ marginTop: '15px', marginLeft: '10px' }}>
                    ₹ {currencyFormat(Math.floor(data?.serviceTax.totalTax))}
                  </Typography>
                </AccordionSummary>
              </Accordion>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            {data.providerName === 'HDFC' ? <Typography variant='h5' ><b>Total Premium:</b>₹ {currencyFormat(Math.floor(data?.grossPremium?.split(' ')[1]))}</Typography> : <Typography variant='h5'><b>Total Premium:</b>₹ {currencyFormat(Math.floor(data?.grossPremium))}</Typography>}
            <Button onClick={onClickHandler} variant='contained'>Buy Now</Button>
          </DialogActions>
        </Dialog>
      </div>
    </Grid>
  );
};

export default QuoteCard;
