import { Button, Grid, Typography, Stack, Input, IconButton, Snackbar, Slide } from "@mui/material";
import Alert from '@mui/material/Alert';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';      
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from "react-redux";
import { clearError, setError } from '../../../../Redux/action-creators/errorAction';
import { clearSuccessMessage, setSuccessMessage } from "../../../../Redux/action-creators/successAction";
 
 
const Documents = (props) => {
    const [allDocuments, setAllDocuments] = useState({ pancard_doc: null, aadhar_doc: null,  degree_doc: null, cheque_doc: null, profile_photo_doc: null, other_doc: null })
    const [errors, setErrors] = useState({ pancard_error: '', aadhar_error : '' , degree_error : '' , cheque_error : '' , profile_photo_error : '' , other_error : ''})
    
    const dispatch = useDispatch();
 
    useEffect(()=>{
        setAllDocuments(props?.documentsData)
    }, [])
 
    const checkErrors = () =>{
        let isErrorTrue = false
        const { pancard_doc, aadhar_doc , degree_doc , cheque_doc , profile_photo_doc , other_doc} = allDocuments
        if(pancard_doc){
            setErrors((prv) =>({...prv , pancard_error : ''}))
        }else{
            setErrors((prv) => ({ ...prv, pancard_error: 'upload pancard...' }))
            isErrorTrue = true
        }
 
        if (aadhar_doc) {
            setErrors((prv) => ({ ...prv, aadhar_error: '' }))
        } else {
            setErrors((prv) => ({ ...prv, aadhar_error: 'upload aadhar...' }))
            isErrorTrue = true
        }
 
 
        if (degree_doc) {
            setErrors((prv) => ({ ...prv, degree_error: '' }))
        } else {
            setErrors((prv) => ({ ...prv, degree_error: 'upload degree certificate...' }))
            isErrorTrue = true
        }
 
 
        if (cheque_doc) {
            setErrors((prv) => ({ ...prv, cheque_error: '' }))
        } else {
            setErrors((prv) => ({ ...prv, cheque_error: 'upload cheque...' }))
            isErrorTrue = true
        }
 
 
        if (profile_photo_doc) {
            setErrors((prv) => ({ ...prv, profile_photo_error: '' }))
        } else {
            setErrors((prv) => ({ ...prv, profile_photo_error: 'upload profile pic...' }))
            isErrorTrue = true
        }
 
        return isErrorTrue
    }

    const checkFileSize = (event) =>{
        const { name, files } = event.target
        let file_size = files[0].size;
        let fileSize = parseInt(file_size / 1024);
        if (fileSize <= 5120) {
            setAllDocuments((prv) => ({ ...prv, [name]: files[0] }));
            setErrors((prv) => ({ ...prv, [`${name.split('doc').join('')}error`]: '' }))

        } else {
            dispatch(setError('upload maximum file size is 5MB'))
            // setIsAlertTrue(true)
            // setAlertMsg('upload maximum file size is 5MB');
            setAllDocuments((prv) => ({ ...prv, [name]: null }));
            event.target.value = null
            // setErrorMsg((prv) => ({ ...prv, [`${name}_error`]: 'Upload file...' }))

        }
    }
 
    const changeHandler = (event) =>{
        dispatch(clearError());
        dispatch(clearSuccessMessage());
        const { name, files } = event.target
         if (name === 'profile_photo_doc'){
            if (/\.(jpg|jpeg|png)$/i.test(files[0]?.name)){
                checkFileSize(event)
            }else{
                dispatch(setError('Invalid file type. Please upload a .jpg, .jpeg or .png file.'))
                event.target.value = null
                setAllDocuments((prv) => ({ ...prv, [name]: null }));
            }

        }
        // else if (/\.(jpg|jpeg|png|pdf|webp)$/i.test(files[0]?.name)) {
        //     checkFileSize(event)
            
        //  } 
         else if (/\.(pdf)$/i.test(files[0]?.name)) {
             checkFileSize(event)

         }
         else {
            dispatch(setError('Invalid file type. Please upload a .pdf file.'))
            event.target.value = null
            // setIsAlertTrue(true)
            // setAlertMsg('Invalid file type. Please upload a .jpg, .jpeg, .png, or .pdf file.')
            setAllDocuments((prv) => ({ ...prv, [name]: null }));
           
        }
    }
 
    const submitHandler = async(e) =>{
        e.preventDefault()
        if(!checkErrors()){
           await props?.callPostApi(allDocuments)
        }
        // await props.callPostApi(allDocuments)
    }
 
 
   
    return (
        <form onSubmit={submitHandler} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={4}>
 
                <Grid item xs={12} md={4}>
                    <Input
                        accept="image/*"
                        id="Pan Card"
                        type="file"
                        sx={{ display: 'none' }}
                        onChange={changeHandler}
                        name='pancard_doc'
                    />
                    <label htmlFor="Pan Card" style={{ width: '100%' }}>
                        <Stack direction={'row'}  justifyContent={'center'} alignItems={'center'} height={55} sx={{ border: `2px dashed ${errors.pancard_error ? 'red' : '#2196f3'}`}} >
                            <IconButton
                                component="span">
                                <CloudUploadIcon style={{ color: 'black' }} />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                                {allDocuments.pancard_doc?.name ? allDocuments.pancard_doc.name.slice(0,15) : 'Pan Card'}
                            </Typography>
                        </Stack>
                    </label>
 
                    {errors.pancard_error && (
                        <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
                            {errors.pancard_error}
                        </Typography>
                    )}
                   
                </Grid>
 
 
                <Grid item xs={12} md={4}>
                    <Input
                        accept="image/*"
                        id="Aadhar Card"
                        type="file"
                        sx={{ display: 'none' }}
                        onChange={changeHandler}
                        name='aadhar_doc'
                    />
                    <label htmlFor="Aadhar Card" style={{ width: '100%' }}>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} height={55} sx={{ border: `2px dashed ${errors.aadhar_error ? 'red' : '#2196f3'}` }}>
                            <IconButton
                                component="span">
                                <CloudUploadIcon style={{ color: 'black' }} />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                                {allDocuments.aadhar_doc?.name ? allDocuments.aadhar_doc.name.slice(0, 15) : 'Aadhar Card'}
                            </Typography>
                        </Stack>
                    </label>
 
                    {errors.aadhar_error && (
                        <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
                            {errors.aadhar_error}
                        </Typography>
                    )}
 
                </Grid>
 
 
                <Grid item xs={12} md={4}>
                    <Input
                        accept="image/*"
                        id="Degree"
                        type="file"
                        sx={{ display: 'none' }}
                        onChange={changeHandler}
                        name='degree_doc'
                    />
                    <label htmlFor="Degree" style={{ width: '100%' }}>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} height={55} sx={{ border: `2px dashed ${errors.degree_error ? 'red' : '#2196f3'}` }}>
                            <IconButton
                                component="span">
                                <CloudUploadIcon style={{ color: 'black' }} />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                                {allDocuments.degree_doc?.name ? allDocuments.degree_doc.name.slice(0, 15) : 'Degree'}
                            </Typography>
                        </Stack>
                    </label>
 
                    {errors.degree_error && (
                        <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
                            {errors.degree_error}
                        </Typography>
                    )}
 
                </Grid>
 
 
                <Grid item xs={12} md={4}>
                    <Input
                        accept="image/*"
                        id="Cheque"
                        type="file"
                        sx={{ display: 'none' }}
                        onChange={changeHandler}
                        name='cheque_doc'
                    />
                    <label htmlFor="Cheque" style={{ width: '100%' }}>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} height={55} sx={{ border: `2px dashed ${errors.cheque_error ? 'red' : '#2196f3'}` }}>
                            <IconButton
                                component="span">
                                <CloudUploadIcon style={{ color: 'black' }} />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                                {allDocuments.cheque_doc?.name ? allDocuments.cheque_doc.name.slice(0, 15) : 'Cheque'}
                            </Typography>
                        </Stack>
                    </label>
 
                    {errors.cheque_error && (
                        <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
                            {errors.cheque_error}
                        </Typography>
                    )}
 
                </Grid>
 
                <Grid item xs={12} md={4}>
                    <Input
                        accept="image/*"
                        id="Profile photo"
                        type="file"
                        sx={{ display: 'none' }}
                        onChange={changeHandler}
                        name='profile_photo_doc'
                    />
                    <label htmlFor="Profile photo" style={{ width: '100%' }}>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} height={55} sx={{ border: `2px dashed ${errors.profile_photo_error ? 'red' : '#2196f3'}` }}>
                            <IconButton
                                component="span">
                                <CloudUploadIcon style={{ color: 'black' }} />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                                {allDocuments.profile_photo_doc?.name ? allDocuments.profile_photo_doc.name.slice(0, 15) : 'Profile photo'}
                            </Typography>
                        </Stack>
                    </label>
 
                    {errors.profile_photo_error && (
                        <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
                            {errors.profile_photo_error}
                        </Typography>
                    )}
                </Grid>
 
 
                <Grid item xs={12} md={4}>
                    <Input
                        accept="image/*"
                        id="Other document"
                        type="file"
                        sx={{ display: 'none' }}
                        onChange={changeHandler}
                        name='other_doc'
                    />
                    <label htmlFor="Other document" style={{ width: '100%' }}>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} height={55} sx={{ border: '2px dashed #2196f3' }}>
                            <IconButton
                                component="span">
                                <CloudUploadIcon style={{ color: 'black' }} />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                                {allDocuments.other_doc?.name ? allDocuments.other_doc.name.slice(0, 15) : 'Other document'}
                            </Typography>
                        </Stack>
                    </label>
 
                </Grid>
 
 
            </Grid>
            <Stack direction={"row"} justifyContent={'end'} marginTop={3}>
                <Button variant="contained" color="primary" sx={{ marginRight: '20px' }} onClick={() => props.clickBackBtn(allDocuments)}>Back</Button>
                {props?.isLoaderTrue ? (
                    <Button variant="contained" color="primary" type="button">
                        <CircularProgress color="inherit" />
                    </Button>
                ) : (
                    <Button variant="contained" color="primary" type="submit">Submit</Button>
                )}
            </Stack>
 
 
        </form>
    )
}
 
export default Documents