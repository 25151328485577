import { Backdrop, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function CarePaymentPage() {
  const proposalNum = useSelector((state) => state?.healthReducer.proposalNum);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleSubmit = async () => {
    try {
      const form = document.getElementById("form");
      let data = form.submit();
      setResponse(data);
      return data;
    } catch (error) {
      setLoading(false);
      console.error("Error during payment:", error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleSubmit();
    }, 1000);
  }, [proposalNum]);

  return (
    <div>
      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            flexDirection: "column",
            gap: 5,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
          <Typography>Redirecting to care payment page</Typography>
        </Backdrop>
      )}
      <form
        action="https://api.religarehealthinsurance.com/portalui/PortalPayment.run"
        name="PAYMENTFORM"
        method="post"
        id="form"
      >
        <input type="hidden" name="proposalNum" value={proposalNum} />
        <input
          type="hidden"
          name="returnURL"
          value="https://main.d315p1596xx1q3.amplifyapp.com/health/thank_you"
        />
      </form>
    </div>
  );
}
