import {
    FETCH_QUESTIONS_SUCCESS,
    SET_CURRENT_QUESTION,
    SUBMIT_ANSWERS_SUCCESS,
    SET_TIMER,
    SET_USER_RESPONSE,
    SET_ERROR,
    CLEAR_EXAM_STATE
}  from "../../../action-type/posp/actionType";

const initialState = {
    questions: [],
    currentQuestionIndex: 0,
    timer: null,
    userResponses: {},
    result: null,
    error: null,  
  };
  
  const examReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_QUESTIONS_SUCCESS:
        return { ...state, questions: action.payload, error: null };
      case SET_CURRENT_QUESTION:
        return { ...state, currentQuestionIndex: action.payload };
      case SET_TIMER:
        return { ...state, timer: action.payload };
      case SET_USER_RESPONSE:
        return {
          ...state,
          userResponses: {
            ...state.userResponses,
            [action.payload.questionId]: action.payload.option,
          },
        };
      case SUBMIT_ANSWERS_SUCCESS:
        return { ...state, result: action.payload, error: null };
      case SET_ERROR:
        return { ...state, error: action.payload };
      case CLEAR_EXAM_STATE :
        return {
          ...state,
          questions: [],
          currentQuestionIndex: 0,
          timer: null,
          result : null,
          userResponses: {},
          error: null
        }
      default:
        return state;
    }
  };
  
  export default examReducer;
  

  
