import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Divider,
  Avatar,
  TextField,
  Stack,
  MenuItem,
  IconButton,
} from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { useForm } from "react-hook-form";
import { fetchVehicleDetials } from "../../api/customerService";
import {v4 as uuidv4} from 'uuid'
import { clearError } from "../../../../Redux/action-creators/errorAction";
import { clearSuccessMessage } from "../../../../Redux/action-creators/successAction";
import { useDispatch } from "react-redux";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";



//component 2
const VehicleCard = ({ vehicle, deleteVehicle }) => (
  <Card
    sx={{
      display: "flex",
      mb: 2,
      alignItems: "center",
      width: "100%",
      backgroundColor: "#f0f4ff",
      position: "relative",
    }}
  >
    <Avatar
      // src={vehicle.image}
      alt={vehicle.name}
      sx={{ width: 56, height: 56, marginLeft: 2 }}
    >
      {vehicle?.vehicle_type === "Car" ? (
        <LocalTaxiIcon />
      ) : vehicle?.vehicle_type === "Bike" ? (
        <TwoWheelerIcon />
      ) : (
        <LocalShippingIcon />
      )}
    </Avatar>
    <CardContent sx={{ flex: "1 0 auto", paddingLeft: 2 }}>
      <Typography variant="body1" fontWeight="bold">
        Name:{" "}
        <span style={{ fontWeight: "normal" }}>{vehicle?.vehicle_make}</span>
      </Typography>

      <Typography variant="body2" color="textSecondary" fontWeight="bold">
        Number:{" "}
        <span style={{ fontWeight: "normal" }}>{vehicle?.vehicle_number}</span>
      </Typography>

      <Typography variant="body2" color="textSecondary" fontWeight="bold">
        Model & Variant:{" "}
        <span style={{ fontWeight: "normal" }}>{vehicle?.vehicle_model}</span>
      </Typography>

      <Typography variant="body2" color="textSecondary" fontWeight="bold">
        Vehicle CC:{" "}
        <span style={{ fontWeight: "normal" }}> {vehicle?.vehicle_cc}</span>
      </Typography>

      <Typography variant="body2" color="textSecondary" fontWeight="bold">
        Mfg year & Fuel Type:{" "}
        <span style={{ fontWeight: "normal" }}>
          {vehicle?.manufacturer_year} • {vehicle?.vehicle_fuel_type}
        </span>
      </Typography>

      <Typography variant="body2" color="textSecondary" fontWeight="bold">
        registration date:{" "}
        <span style={{ fontWeight: "normal" }}>
          {" "}
          {vehicle?.registration_date}
        </span>
      </Typography>
    </CardContent>

    <Box
      sx={{ alignSelf: "flex-start", position: "absolute", top: 2, right: 2 }}
    >
      <IconButton
        // onClick={() => handleDeleteMember(index)}
        sx={{ fontSize: "50px" }}
        onClick={() => deleteVehicle(vehicle?.id)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
    {/* <Button>&gt;</Button> */}
  </Card>
);

// component 1
const Assets = ({ assets, NextBtn, PreviousBtn }) => {
   const dispatch = useDispatch();
  const {
    formState: { errors, isSubmitted },
    register,
    setValue,
    handleSubmit,
    trigger,
    reset
  } = useForm();
  const [vehicles, setVehicles] = useState(
    assets
    //   [
    //   {
    //     name: "MAHINDRA AND MAHINDRA XUV700",
    //     registration: "TS-09-FV-6055",
    //     fuel: "Diesel",
    //     year: "2022",
    //     image: "car1.png",
    //     vehicle_type: "Bike",
    //   },
    //   {
    //     name: "MARUTI Vitara Brezza",
    //     registration: "TS-07-HA-9646",
    //     fuel: "Diesel",
    //     year: "2020",
    //     image: "car2.png",
    //     vehicle_type: "Car",
    //   },
    // ]
  );

  const [showInput, setShowInput] = useState(false);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAddVehicleClick = () => {
    setShowInput(true);
  };

   const handleChange = async (e) => {
     const { name, value } = e.target;
     setValue(name, value);
     {
       isSubmitted && (await trigger(name));
     }
   };

  const fetchVehicleDetails = async (data) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    setLoading(true)
    try{
      const response = await fetchVehicleDetials({ vehicleNumber  : data['vehicle name']});
      if(response.status === 200){
        const mappingObject = {
          id: uuidv4(),
          vehicle_number: response?.data?.vehicleNumber,
          vehicle_make: response?.data?.vehicleManufacturerName,
          vehicle_model: response?.data?.model,
          vehicle_variant: response?.data?.vehicleColour,
          vehicle_fuel_type: response?.data?.type,
          vehicle_cc: response?.data?.vehicleCubicCapacity,
          manufacturer_year: response?.data?.vehicleManufacturingMonthYear,
          registration_date: response?.data?.regDate,
          vehicle_type : data['vehicle type']
        };

        setVehicles((prv) => ([...prv , mappingObject]))
        setShowInput(false)
        reset()
      }else{
        dispatch(setError("Something Went Wrong"));
      }
    }catch(e){
      dispatch(setError("Internal Server Error"));
    }finally{
      setLoading(false)
    }

    return {
      name: "NEW VEHICLE MODEL",
      registration: vehicleNumber?.toUpperCase(),
      fuel: "Petrol",
      year: "2023",
      image: "car_new.png",
    };
  };

  const deleteVehicle = (id)=>{
     const updateVehicle = vehicles.filter((eachValue) => eachValue?.id !== id);
     setVehicles(updateVehicle);
  }

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#ffffff",
        borderRadius: 2,
        padding: "20px",
      }}
    >
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar sx={{ backgroundColor: "#fff", marginRight: 1 }}>
          <DirectionsCarIcon
            sx={{ fontSize: "2rem", color: "#0000ff", mr: 1 }}
          />
        </Avatar>
        <Typography variant="h4">Vehicle Details</Typography>
      </Box>

      {vehicles?.length === 0 ? (
        // empty text
        <Typography
          variant="h3"
          textAlign={"center"}
          marginTop={5}
          marginBottom={5}
        >
          Empty
        </Typography>
      ) : (
        // data card
        <>
          <Box>
            <Grid container spacing={3}>
              {vehicles.map((vehicle, index) => (
                <Grid item xs={12} md={6}>
                  <VehicleCard
                    key={vehicle?.id}
                    vehicle={vehicle}
                    deleteVehicle={deleteVehicle}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Divider sx={{ my: 2 }} />
        </>
      )}

      <Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* form */}
          {showInput ? (
            <>
              <Stack
                direction={"column"}
                spacing={2}
                width={"70%"}
                component="form"
                onSubmit={handleSubmit(fetchVehicleDetails)}
              >
                <Stack spacing={2} direction={"row"} width={"100%"}>
                  <TextField
                    {...register("vehicle name", {
                      required: "Vehicle number is required",
                      pattern: {
                        value: /^[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{4}$/,
                        message: "Invalid Vehicle Number",
                      },
                    })}
                    fullWidth
                    name="vehicle name"
                    label="Enter Vehicle Number"
                    variant="outlined"
                    placeholder="Eg: TS07HA0000"
                    // value={vehicleNumber}
                    // onChange={handleVehicleNumberChange}
                    disabled={loading}
                    error={!!errors["vehicle name"]}
                    helperText={
                      errors["vehicle name"]
                        ? errors["vehicle name"].message
                        : ""
                    }
                    onInput={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                    }}
                  />

                  <TextField
                    fullWidth
                    name="vehicle type"
                    label="Select Vehicle Type"
                    select
                    {...register("vehicle type", {
                      required: "vehicle type is required...",
                    })}
                    variant="outlined"
                    // value={vehicleNumber}
                    onChange={handleChange}
                    error={!!errors["vehicle type"]}
                    helperText={
                      errors["vehicle type"]
                        ? errors["vehicle type"].message
                        : ""
                    }
                    disabled={loading}
                  >
                    <MenuItem value="Car">Car</MenuItem>
                    <MenuItem value="Bike">Bike</MenuItem>
                    <MenuItem value="Commercial">Commercial</MenuItem>
                  </TextField>
                </Stack>

                <Stack direction={"row"} spacing={2} justifyContent={"end"}>
                  <Button
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    type="button"
                    disabled={loading}
                    sx={{ mt: 2 }}
                    onClick={() => {
                      setShowInput(false);
                      reset();
                    }}
                  >
                    Close
                  </Button>

                  <Button
                    variant="contained"
                    // fullWidth
                    type="submit"
                    sx={{ mt: 2 }}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "ADD"}
                  </Button>
                </Stack>
              </Stack>
            </>
          ) : (
            // buttons
            <Stack
              spacing={2}
              direction={"row"}
              sx={{ alignSelf: "center", marginTop: "30px" }}
            >
              <Button variant="contained" onClick={() => PreviousBtn(vehicles)}>
                Back
              </Button>

              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddVehicleClick}
              >
                ADD VEHICLE
              </Button>

              <Button variant="contained" onClick={() => NextBtn(vehicles)}>
                Next
              </Button>
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Assets;
