import { Card, CardContent, Grid, Box, Typography } from "@mui/material";
import React from "react";

import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const QuotePerformance = () => {
  const bajaj =
    "https://images.seeklogo.com/logo-png/52/1/bajaj-group-logo-png_seeklogo-524595.png";
  const icici =
    "https://pbs.twimg.com/profile_images/1477924435969462272/ZQADGPv5_400x400.png";
  const godigit =
    "https://akm-img-a-in.tosshub.com/businesstoday/images/story/202306/godigit-sixteen_nine.jpg?size=948:533";
  const care =
    "https://upload.wikimedia.org/wikipedia/commons/9/90/Care_health_insurance_logo.png";
  const hdfc =
    "https://www.hdfcergo.com/images/default-source/home/logo_hdfc.svg";

  const cardData = [
    {
      title: "CAR - % - No.",
      data: [
        { image: bajaj, percentage: "12%", number: "1" },
        { image: icici, percentage: "42%", number: "0" },
        { image: godigit, percentage: "32%", number: "0" },
      ],
    },
    {
      title: "CAR EXPIRED -  % - No.",
      data: [
        { image: hdfc, percentage: "18%", number: "1" },
        { image: godigit, percentage: "22%", number: "0" },
        { image: icici, percentage: "38%", number: "0" },
      ],
    },

    {
      title: "BIKE -  % - No.",
      data: [
        { image: bajaj, percentage: "08%", number: "1" },
        { image: icici, percentage: "21%", number: "0" },
        { image: godigit, percentage: "17%", number: "0" },
      ],
    },
    {
      title: "COMMERCIAL -  % - No.",
      data: [
        { image: godigit, percentage: "18%", number: "1" },
        { image: hdfc, percentage: "17%", number: "0" },
        { image: bajaj, percentage: "15%", number: "0" },
      ],
    },
    {
      title: "TRAVEL - % - No.",
      data: [
        { image: care, percentage: "30%", number: "1" },
        { image: care, percentage: "12%", number: "0" },
        { image: care, percentage: "21%", number: "0" },
      ],
    },

    {
      title: "HEALTH -  % - No.",
      data: [
        { image: hdfc, percentage: "22%", number: "1" },
        { image: care, percentage: "42%", number: "0" },
        { image: hdfc, percentage: "32%", number: "0" },
      ],
    },

    {
      title: "MOTOR -  % - No.",
      data: [
        { image: godigit, percentage: "12%", number: "1" },
        { image: icici, percentage: "42%", number: "0" },
        { image: godigit, percentage: "32%", number: "0" },
      ],
    },
    {
      title: "LOAN -  % - No.",
      data: [
        { image: care, percentage: "07%", number: "1" },
        { image: care, percentage: "14%", number: "0" },
        { image: care, percentage: "33%", number: "0" },
      ],
    },
  ];

  return (
    <div>
      <Grid container sx={{ backgroundColor: "#f0f0f0", padding: "20px" }}>
        <Grid xs={12} lg={12}>
          <Typography variant="h2">Quote Performance</Typography>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="flex-end"
            marginBottom="10px"
          >
            <Grid
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              paddingRight="10px"
              paddingTop="10px"
            >
              <Button
                variant="outlined"
                color="primary"
                endIcon={<FileDownloadIcon />}
                sx={{
                  boxShadow: "1px 1px 3px gray",
                  borderRadius: "10px",
                  marginLeft: "10px",
                  backgroundColor: "white",
                }}
              >
                TW Dump
              </Button>
              <Button
                variant="outlined"
                color="primary"
                endIcon={<FileDownloadIcon />}
                sx={{
                  boxShadow: "1px 1px 3px gray",
                  borderRadius: "10px",
                  marginLeft: "10px",
                  backgroundColor: "white",
                }}
              >
                Car Dump
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              boxShadow: 2,
              borderRadius: "15px",
              backgroundColor: "white",
            }}
          >
            <Grid container justifyContent="end" alignItems="center">
              <Grid item xs={12} justifyContent="center" padding="20px">
                <Grid container spacing={6}>
                  {cardData.map((card, index) => (
                    <Grid item xs={6} md={4} lg={3} key={index}>
                      <Card
                        sx={{
                          boxShadow: 3,
                          position: "relative",
                          borderRadius: 4,
                        }}
                      >
                        <CardContent style={{ textAlign: "center" }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                            textAlign="center"
                            color="blue"
                            component="div"
                          >
                            {card.title}
                          </Typography>
                          <hr />
                          {card.data.map((cardItem, cardIndex) => (
                            <Grid
                              container
                              spacing={1}
                              marginTop="10px"
                              key={cardIndex}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs={4}>
                                <Box
                                  sx={{ boxShadow: 3, position: "relative" }}
                                >
                                  <img
                                    height="45px"
                                    width="110px"
                                    src={cardItem.image}
                                    alt={cardIndex}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "18px", fontWeight: "bold" }}
                                >
                                  {cardItem.percentage}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "18px", fontWeight: "bold" }}
                                >
                                  {cardItem.number}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default QuotePerformance;
