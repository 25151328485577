import React from "react";
import { Card, CardContent, CardHeader, Typography, List, ListItem, ListItemText, Grid } from '@mui/material';
// import './HealthBreakUp.css';

const HealthBreakUp = ({ quote }) => {

  const convertPremiumToNumber = (premium) => {
    let parsedPremium;
   
    if (typeof premium === 'string') {
      parsedPremium = parseFloat(premium.replace(/,/g, ''));
    } else {
      parsedPremium = premium;
    }
   
    // Check if the number has decimal places and round it
    if (!Number.isInteger(parsedPremium)) {
      parsedPremium = Math.round(parsedPremium * 100) / 100;
    }
   
    return parsedPremium;
  };

  console.log('iwmcwwiomvioewmv', quote)
  const basePremium = convertPremiumToNumber(quote?.premium)
  const premium = convertPremiumToNumber(quote?.premiumWithGST)
  console.log(basePremium)
  console.log(premium)
  const gstAdded = premium - basePremium;
  console.log(gstAdded)
 
  return (
    <Grid container justifyContent="center" spacing={2}>
    <Grid item xs={12} sm={8} md={6}>
      <Card>
        <CardHeader
          title="Break Up"
          titleTypographyProps={{ variant: 'h5', align: 'center', style: { fontSize: '24px' } }}
          style={{ backgroundColor: '#f7faff', borderBottom: '2px solid #ccc', fontSize: '20px', fontWeight: 700, color: '#253858' }}// Style for the header
        />
        <CardContent>
          <Grid container spacing={2}>
            {[
              { label: 'Base Premium', value: basePremium },
              { label: 'GST@18%', value: gstAdded.toFixed(1) },
              { label: 'Premium', value: premium },
            ].map((item, index) => (
              <Grid item xs={12} key={index}>
                <Typography variant="body1" display="flex" justifyContent="space-between">
                  <span style={{ color: '#253858', fontWeight: "600" }}>{item.label}</span>
                  <span>{`Rs ${item.value}`}</span>
                </Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
  );
};
 
export default HealthBreakUp;