import * as React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

export const AlertError = ({ open, setOpen, message, severity }) => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      spacing={2}
    >
      <Alert
        onClose={handleClose}
        severity={severity || "error"}
        sx={{ width: "100%",mt:"70px"}}
      >
        
        {message}

      </Alert>
    </Snackbar>
  );
};
