import React, { useCallback, useEffect, useState } from 'react';
import './ProductDetails.css';
import { useDispatch, useSelector } from 'react-redux';
import { updatePremiumPostObject, updatedQuotationResult } from '../../Redux/Health/action';
import CustomCheckbox from '../../Health/Components/CustomCheckbox';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderComponent from '../../Framework/Components/Loader';
import { generatePremiumById } from '../api/PremiumGeneration';
import careLogo from '../../Health/Images/CHIL LOGO TYPE 1.jpg';
import { Grid, IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const ProductDetail = () => {
  const premiumPostBody = useSelector((state) => state?.healthReducer?.premiumPostObject);
  const [addons, setAddons] = useState([]);
  const [isField43Disabled, setIsField43Disabled] = useState(false);
  const [isInstantCoverDisabled, setIsInstantCoverDisabled] = useState(false);
  const [summary, setSummary] = useState({});
  const [loading, setLoading] = useState(false);
  const [basePremium, setBasePremium] = useState('');
  const [fieldOPSsI, setFieldOPSsI] = useState(false);
  const params = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const familyData = useSelector((state) => state.healthReducer.familyData);
  const trueCount = Object.values(familyData).filter(value => value === true).length;

  const fetchPremiumDetails = useCallback(async () => {
    setLoading(true);
    let newPostBody = { ...premiumPostBody, abacusId: params.abacusId };
    try {
      const result = await generatePremiumById(newPostBody);
      dispatch(updatedQuotationResult(result));
      setSummary(result?.data?.body?.data);
      setAddons([result?.data?.body?.data?.extraFields][0] || []);
      const basePremium = result?.data?.body?.data?.outputFields[0]?.premium;
      const premium = basePremium === '' ? result?.data?.body?.data?.outputFields[0]?.premium : basePremium;
      setBasePremium(premium);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [premiumPostBody]);

  useEffect(() => {
    fetchPremiumDetails();
  }, [fetchPremiumDetails]);

  const handlePedTenureChange = (value) => {
    const updatedPostBody = { ...premiumPostBody };
    updatedPostBody.field_PED_TENURE = value;
    dispatch(updatePremiumPostObject(updatedPostBody));
  };
  const handleOPDSelectChange = (fieldName, value) => {
    const updatedPostBody = {
      ...premiumPostBody,
      [fieldName]: '1',
      field_OPD_SI: value
    };
    dispatch(updatePremiumPostObject(updatedPostBody));
  };

  const handleCheckBoxChange = (fieldName, checked) => {
    const updatedPostBody = { ...premiumPostBody };
    updatedPostBody.productId = params.abacusId;
    if (checked) {
      updatedPostBody[fieldName] = '1';
      if (fieldName === 'field_IC') {
        updatedPostBody.field_OPD = '0';
        updatedPostBody.field_43 = '0';
        setIsField43Disabled(true);
        setIsInstantCoverDisabled(false);
      } else if (fieldName === 'field_43') {
        updatedPostBody.field_PED_TENURE = '1 Year';
        setIsInstantCoverDisabled(true);
      } else if (fieldName === 'field_PED_TENURE') {
        updatedPostBody.field_43 = '0';
        setIsInstantCoverDisabled(true);
      } else if (fieldName === 'field_OPD' && params.abacusId === '2402') {
        setFieldOPSsI(true);
      }else if(fieldName === "field_NCB"){
        updatedPostBody.field_NCB = "1"
      }
    } else {
      updatedPostBody[fieldName] = '0';
      if (fieldName === 'field_IC') {
        setIsField43Disabled(false);
      } else if (fieldName === 'field_43' || fieldName === 'field_PED_TENURE') {
        setIsInstantCoverDisabled(false);
      }
    }

    dispatch(updatePremiumPostObject(updatedPostBody));
  };
  const handlePolicyType = (e) => {
    let updatedPostBody = { ...premiumPostBody };
    updatedPostBody.policyType = e.target.value;
    dispatch(updatePremiumPostObject(updatedPostBody));
  };

  return (
    <div className="container">
      <Grid container alignItems="center" spacing={1} item xs={12} marginTop={1}>
            <IconButton onClick={() =>navigate('/health/quotations')} aria-label="Go back">
              <ArrowBack />
            </IconButton>
            <Typography variant="body1">Go back</Typography>
          </Grid>
      <div className="row mt-4">
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-div centeralign flex-wrap">
                <div className="logo-details view-details-logo">
                  <img src={careLogo} alt="Care Health" width="100" />
                </div>
                <div className="plan-name">
                  <h2>{summary?.abacusData?.title}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="inner-div">
                <div class="heading_section">
                  <h3>
                    Add-ons
                    <span>You should get these additional benefits to enhance your current plan</span>
                  </h3>
                  <div className="multi-addons-wrapper">
                    <h4 className="addons-importance">Mandatory Addons:-</h4>
                    {addons
                      ?.filter((addon) => addon.selectedValue === 'checked')
                      .map((addon) => (
                        <div className="addons_wrapper" key={addon.fieldName}>
                          <div className="addons_details">
                            <h4>{addon.label}</h4>
                            <div className="addons-info">
                              <span>{addon.tooltip}</span>
                            </div>
                          </div>
                          <div className="addons_type_wrapper">
                            <div className="addons_inner_box">
                              <p>
                                <span>Premium</span>₹ {Math.round(addon.dataValues * 1.18)}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="multi-addons-wrapper">
                    <h4 className="addons-importance">Recommended Addons:-</h4>
                    {addons
                      ?.filter((addon) => addon.selectedValue !== 'checked' && addon.isVisible && addon.label !== '')
                      .map((addon) => (
                        <div className="addons_wrapper" key={addon.fieldName}>
                          <div className="addons_details">
                            <h4>{addon.label}</h4>
                            <div className="addons-info">
                              <span>{addon.tooltip}</span>
                            </div>
                          </div>
                          <div className="addons_type_wrapper">
                            <div className="addons_inner_box">
                              <p>
                                <span>Premium</span>₹ {Math.round(addon.dataValues * 1.18)}
                              </p>
                            </div>
                            <div className="addons_add_buttons">
                              <CustomCheckbox checked={premiumPostBody[addon.fieldName] === '1'} onChange={(e) => handleCheckBoxChange(addon.fieldName, e.target.checked)} disabled={(addon.fieldName === 'field_43' && isField43Disabled) || (addon.fieldName === 'field_IC' && isInstantCoverDisabled)} />
                            </div>
                          </div>
                        </div>
                      ))}

                    <div>
                      {!isField43Disabled && isInstantCoverDisabled ? (
                        <div className="addons_wrapper">
                          <div className="addons_details">
                            <h4>Tenure</h4>
                          </div>
                          <div className="addons_type_wrapper">
                            <div className="addons_add_buttons">
                              <div className="input_group">
                                <label htmlFor="selftitle">
                                  Waiting Period
                                  <span className="star"></span>
                                </label>
                                <select className="form_control" value={premiumPostBody['field_PED_TENURE']} onChange={(e) => handlePedTenureChange(e.target.value)}>
                                  <option value="1 Year">1 Year</option>
                                  <option value="2 Year">2 Year</option>
                                  <option value="3 Year">3 Year</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div>
                      {fieldOPSsI ? (
                        <div className="addons_wrapper">
                          <div className="addons_details">
                            <h4>Amount</h4>
                          </div>
                          <div className="addons_type_wrapper">
                            <div className="addons_add_buttons">
                              <div className="input_group">
                                <label htmlFor="selftitle">amount</label>
                                <select className="form_control" value={premiumPostBody['field_OPD_SI']} onChange={(e) => handleOPDSelectChange('field_OPD', e.target.value)}>
                                  <option value="3000">3000</option>
                                  <option value="5000">5000</option>
                                  <option value="10000">10000</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 right_product ">
          <div className="right_summery_section">
            <h3>Summary</h3>
            <div className="scroll_space">
              <div className="flexRow section_right">
                <div>Base Premium</div>
                <div>
                  {' '}
                  <div>
                    {' '}
                    <span>₹ {Math.round(parseFloat(basePremium?.replace(/,/g, '')))}</span>
                  </div>{' '}
                </div>
              </div>
              <div className="rider_head">
                <h4>Selected Add-ons</h4>
                {loading ? (
                  <p>
                    <LoaderComponent />
                  </p>
                ) : (
                  <div className="flexRow select_benefits">
                    {addons.some((addon) => addon.selectedValue === '1') ? (
                      addons
                        .filter((addon) => addon.selectedValue === '1')
                        .map((addon) => (
                          <div key={addon.fieldName} className="addon-row">
                            <span>{addon.label}</span>
                            <span>
                              <b>₹ {Math.round(addon.dataValues * 1.18)}</b>
                            </span>
                          </div>
                        ))
                    ) : (
                      <div>No add-ons selected</div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="premium_right">
              <div className="flexRow section_premium discountSectionPremium">
                <div>Total premium + 18% GST + Selected Addons</div>
                <span>₹ {summary?.grandTotal?.selectedValue}</span>
              </div>
              <button type="button" className="primaryMainCta" onClick={() => navigate('/health/proposalform')}>
                Proceed to Proposal
              </button>
            </div>
          {trueCount > 1 && (
            <div className="inner_right_section">
              <div className="scroll_space">
                <div className="rider_head">
                  <div className="premium_right">
                    <div className="input_group">
                      <label htmlFor="selftitle">Policy Type</label>
                      <select
                        className="form_control"
                        value={premiumPostBody.policyType}
                        onChange={(e) => handlePolicyType(e)}
                      >
                        <option value="Individual">Family Individual</option>
                        <option value="Floater">Family</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      </div>
    </div>
  );
};

export default ProductDetail;
