import careLogo from '../Images/CHIL LOGO TYPE 1.jpg';
import hdfcLogo from "../Images/HDFCLOGO 1 copy.jpg"
import bajajLogo from "../Images/Bajaj_Allianz_Insurance.jpg"
 
export let careSupremeBenefits = [
    { name: "Claims paid within 3 months", benefit: "100%" },
    {
      name: "Room rent limit",
      benefit: "Any category",
      detail:
        "Room rent limit is the maximum amount per day cost allowed by the insurer. Related expenses, such as doctor consultation etc. are paid in proportion to the room rent limit. Look out for plans with No Room Rent limit",
    },
    {
      name: "Restoration of cover",
      benefit:
        "Rs 5 lakh unlimited times a year; for related and unrelated illness",
      detail:
        "Very useful feature, in case the sum Insured gets exhausted in a year due to multiple claims or a large claim. Insurer restores the sum insured to provide continued coverage to policyholders.",
    },
    {
      name: "Renewal Bonus",
      benefit:
        "Rs 7.5 lakh per year for first 2 years and Rs 5 lakh per year for next 3 years up to maximum of Rs 35 lakh irrespective of claim",
      detail:
        "Renewal bonus is the extra sum insured added at the time of policy renewal as per the terms and conditions specified in the policy.",
    },
    {
      name: "Cashless hospitals",
      benefit: "10293 cashless hospitals in India",
      detail:
        "Hospitals that have a direct tie-up with the insurance company, where insured can avail cashless treatment. Larger the network in your area, better it is.",
    },
    {
      name: "Pre-hospitalization coverage",
      benefit: "60 Days",
      detail:
        "Expenses incurred BEFORE hospitalization, such as doctor visits, diagnostic tests etc. Insurance company pays for such expenses upto a certain number of days (ex. 30-90 days) prior to hospitalization.",
    },
    {
      name: "Post-hospitalization coverage",
      benefit: "180 days",
      detail:
        "Expenses incurred AFTER hospitalization, such as doctor visits, medicines etc. Insurance company pays for such expenses upto a certain number of days (ex. 30-180 days) after the hospitalization.",
    },
    {
      name: "Day care treatment",
      benefit: "All day care treatments",
      detail:
        "Treatments that do not require hospitalization, such as Cataract, Chemo etc.",
    },
    {
      name: "Hospitalization at home",
      benefit: "Up to Rs 5 lakh",
      detail:
        "This feature covers for scenarios where hospital bed is not available AND the doctor has recommended treatment at home.",
    },
    {
      name: "Ambulance charges",
      benefit: "Up to Rs 10,000",
      detail:
        "Covers for ambulance charges before or after the hospitalization.",
    },
  ];
 
 
  export let productDetails = [
    {
      provider: "Care",
      product: "Care Supreme",
      features: [
        {
          feature: "Room Rent",
          benefit: "Covered Upto Sum Insured",
        },
        {
          feature: "Health Check-Up",
          benefit: "Once for all Insured every policy year",
        },
        {
          feature: "Consumables Benefit",
          benefit:
            "Optional Under Claim Shield Coverage of expense related to 68 Non-payable items during Hospitalization",
        },
        {
          feature: "Pre Existing Disease",
          benefit:
            "Applicable PED Wait Period of 4 Years, will be reduced to 1,2 and 3 Years - Optional",
        },
        {
          feature: "Pre Hospitalization",
          benefit: "Covered upto 60 Days",
        },
        {
          feature: "OPD Treatment",
          benefit: "Optional Coverage",
        },
        {
          feature: "OPD Treatment ? Dental",
          benefit: "Optional Coverage Under OPD",
        },
        {
          feature: "Emergency Air Ambulance",
          benefit: "Upto 5 lacs per year",
        },
      ],
    },
    {
      provider: "care",
      product: "Care Advantage",
      features: [
        {
          feature: "Room Rent",
          benefit: "Covered Upto Sum Insured",
        },
        {
          feature: "Health Check-Up",
          benefit: "Once for all Insured every policy year",
        },
        {
          feature: "Compassionate Travel",
          benefit: "Up to Rs.5000 per policy Year for SI>=5 Lacs",
        },
        {
          feature: "Consumable Allowance ",
          benefit: "Max 7 days per Hospitalization, covered after 3 days",
        },
        {
          feature: "Ambulance Cover",
          benefit: "Up to Rs 1,000",
        },
        {
          feature: "Second Opinion",
          benefit:
            "Once per policy year per Insured Person for each major illness/injury",
        },
        {
          feature: "Dialysis Cover ",
          benefit: "Up to Rs. 1,000 per sitting for 24 months",
        },
        {
          feature: "Pre Hospitalization",
          benefit: "(if hospitalization exceeds 10 days)",
        },
      ],
    },
    {
      provider: "care",
      product: "Care Classic",
      features: [
        {
          feature: "Room Rent",
          benefit: "Covered Upto Sum Insured",
        },
        {
          feature: "Health Check-Up",
          benefit: "Once for all Insured every policy year",
        },
        {
          feature: "Compassionate Travel",
          benefit: "Up to Rs.5000 per policy Year for SI>=5 Lacs",
        },
        {
          feature: "Consumables Benefit",
          benefit: "Covered",
        },
        {
          feature: "Global Cover",
          benefit: "Optional",
        },
        {
          feature: "Second Opinion",
          benefit:
            "Once per policy year per Insured Person for each major illness/injury",
        },
        {
          feature: "Pre Existing Disease",
          benefit: "Covered After 48 Months",
        },
        {
          feature: "Pre Hospitalization",
          benefit: "60 Days",
        },
      ],
    },
    {
      provider: "BAJAJ",
      product: "Bajaj Health Guard",
      features: [
        {
          feature: "In-patient Hospitalization Treatment",
          benefit:
            "If You are hospitalized on the advice of a Medical practitioner as defined under Policy because of Illness or Accidental Bodily Injurysustained or contracted during the Policy Period, then We will pay You, Reasonable and Customary Medical Expenses incurred subject to",
        },
        {
          feature: "Pre-Hospitalization",
          benefit:
            "The Medical Expenses incurred during the 60 days immediately before You were Hospitalized, provided that: Such MedicalExpenses were incurred for the same illness/injury for which subsequent Hospitalization was required, and We have accepted aninpatient Hospitalization claim under Inpatient Hospitalization Treatment",
        },
        {
          feature: "Post-Hospitalization",
          benefit:
            "The Medical Expenses incurred during the 90 days immediately after You were discharged post Hospitalization provided that: Suchcosts are incurred in respect of the same illness/injury for which the earlier Hospitalization was required, and We have accepted aninpatient Hospitalization claim under Inpatient Hospitalization Treatment",
        },
        {
          feature: "Road Ambulance",
          benefit:
            "We will pay the reasonable cost to a maximum of Rs. 20,000/- per Policy Year incurred on an ambulance offered by a healthcareor ambulance service provider for transferring You to the nearest Hospital with adequate emergency facilities for the provision ofhealth services following an Emergency",
        },
        {
          feature: "Day Care Procedures",
          benefit:
            "We will pay You the medical expenses as listed above under In-patient Hospitalization Treatment for Day care procedures /Surgeries taken as an inpatient in a hospital or day care center but not in the outpatient department. Refer Annexure I of PolicyWordings for list of Day Care Procedures",
        },
        {
          feature: "Convalescence Benefit:",
          benefit:
            "In the event of Insured Hospitalized for a disease/illness/injury for a continuous period exceeding 10 days, We will pay benefitamount as per the plan opted subject to below limit",
        },
        {
          feature: "Preventive Health Check Up",
          benefit:
            "At the end of block of every continuous period as mentioned in coverage during which You have held Our Health Guard Policy, Youare eligible for a free Preventive Health checkup. We will reimburse the amount as per the plan opted, subject to below limits",
        },
        {
          feature: "Bariatric Surgery Cover",
          benefit:
            "If You are hospitalized on the advice of a Medical practitioner because of Conditions mentioned below which required You toundergo Bariatric Surgery during the Policy Period, then We will pay You, Reasonable and Customary Expenses related to BariatricSurgery",
        },
      ],
    },
    {
      provider: "HDFC",
      product: "Optima Secure",
      features: [
        {
          feature: "Hospitalisation expenses",
          benefit:
            "Covers medical expenses for both in-patient and day care hospitalisation.",
        },
        {
          feature: "AYUSH treatment",
          benefit:
            "Covers hospitalisation expenses incurred on alternate lines of treatment for Ayurveda, Yoga and Naturopathy, Unani, Siddha and Homeopathy",
        },
        {
          feature: "Home healthcare",
          benefit:
            "Treatment (requiring hospitalisation) availed at home on a cashless basis, if prescribed by the treating medical practitioner",
        },
        {
          feature: "Domiciliary hospitalisation",
          benefit:
            " Treatment at home if advised by the doctor and only if the  insured person is not in a condition to be transferred to a hospital or on account of non-availability of room in hospital",
        },
        {
          feature: "Pre and post hospitalisation",
          benefit:
            "Covers medical expenses incurred 60 days immediately before hospitalisation and 180 days post discharge",
        },
        {
          feature: "Organ donor expenses",
          benefit:
            "Covers medical expenses incurred on harvesting the organ from the donor",
        },
        {
          feature: "Emergency air ambulance",
          benefit:
            "Covers expenses incurred on air ambulance services for transporting the insured person in case of an emergency",
        },
        {
          feature: "Protect benefit",
          benefit:
            "Pays listed non-medical expenses like consumables, gloves, masks and so on.",
        },
        {
          feature: "Plus benefit",
          benefit:
            "Increase in 50% of base sum insured every policy year,maximum up to 100%, irrespective of claims",
        },
        {
          feature: "Secure benefit",
          benefit:
            "Additional coverage equivalent to 100% of the base sum insured available for claims from day 1",
        },
        {
          feature: "Automatic restore benefit",
          benefit:
            "Restoration of 100% of base sum insured automatically on partial or complete utilization of sum insured",
        },
        {
          feature: "Daily cash for shared accommodation",
          benefit:
            "Per day pay-out for choosing shared accommodation in a network hospital, for hospitalisations exceeding 48 hours",
        },
        {
          feature: "E-opinion for critical illness",
          benefit:
            "E-opinion for any of the listed 51 critical illnesses through our network provider globally",
        },
        {
          feature: "Preventive health check-up",
          benefit:
            "Helps you keep track of your health status by availing a preventive health check-up post completion of every policy year, irrespective of claims",
        },
      ],
    },
  ];
 
  export let Images = [
    {
      provider: "CARE",
      image: careLogo,
    },
    {
      provider: "HDFC",
      image: hdfcLogo,
    },
    {
      provider: "BAJAJ",
      image: bajajLogo,
    },
  ];