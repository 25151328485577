import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { AddToLocalStorage, GetDataLocalStorage } from '../../../Framework/GlobalObj';
import TextField from '@mui/material/TextField';
import { InsurerNamesSuccessRequest, NCBSuccessRequest, getInsurerName, getNCBValue, getQuotationData } from '../../../Redux/Action';
import options from '../../../Framework/Components/option';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { Accordion, Typography, AccordionSummary, Box, CircularProgress, Alert, Badge, Stack, Drawer, Toolbar, Button, Container, Grid, Skeleton, ListItemText, ListItem, List, Divider, Slider, AccordionDetails, InputAdornment, Dialog, DialogContent, FormControl, RadioGroup, Radio, DialogActions } from '@mui/material';
import { currencyFormat } from '../../../Framework/Utils/CurrencyFormat';
import SAODThirdPartyDetails from '../Models/ThirdPartyDetailsModal/SAODThirdPartyDetails';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AlertError } from '../../../Framework/Toaster/AlertError';
import '../../../Framework/Pages/Main.css';
import IconButton from '@mui/material/IconButton';
import AddToCompareModal from './AddToCompareModal';
import { useTranslation } from "react-i18next";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import QuoteCard from './QuoteCard';
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import { useParams, useLocation, useNavigate } from 'react-router';
import insurerData from '../../data/companyInsuranceMaster.json';
const QuotationDetails = () => {
  const { t } = useTranslation();
  const vehicleContent = t("vehicle");
  const quotationDetails = vehicleContent.QuotationDetails;
  const [updatedIdv, setUpdatedIdv] = useState();
  const [idvValue, setIdvValue] = useState();
  const [showQuotations, setShowQuotations] = useState(false);
  const [liability, setLiability] = useState(false);
  const [opened, setOpened] = useState(false);
  const [saodTPDetailsOpen, setSaodTPDetailsOpen] = useState(false);
  const [addons, setAddons] = useState([]);
  const [show, setShow] = useState(false);
  const [accidentCover, setAccidentCover] = useState({});
  const [handiCapedCover, setHandiCapedCover] = useState({})
  const [open, setOpen] = React.useState(false);
  const [arr, setArr] = useState([]);
  const [quotationData, setQuotationData] = useState({});
  const [req, setReq] = useState([]);
  const dispatch = useDispatch();
  const [accessories, setAccessories] = useState({});
  const [additionalCover, setAdditionalCover] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMssg, setErrorMsg] = useState('');
  const [addonsList, setAddonsList] = useState(options);
  const [alertWarning, setAlertWarning] = useState('')
  const [hdfcErrorMsg, setHdfcErrorMsg] = useState('')
  const [selectedCount, setSelectedCount] = useState(0);
  const [minimumIdv, setMinimumIdv] = useState([]);
  const [maximumIdv, setMaximumIdv] = useState([]);
  const [idvAmount, setIdvAmount] = useState(0);
  const [quotes, setQuotes] = useState([]);
  const [openAddons, setOpenAddons] = useState(false);
  const [openDeclaration, setOpenDeclaration] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [previousPolicyZeroDep, setPreviousPolicyZeroDep] = useState('');
  const [previousPolicyReturnToInvoice, setPreviousPolicyReturnToInvoice] = useState('');

  const quotation = useSelector((store) => store.motorReducer.quotationData);
  const insurerName = useSelector((store) => store.motorReducer.insurerNames);
  const ncbData = useSelector((store) => store.motorReducer.ncbData);

  const location = useLocation();
  const navigate = useNavigate();

  // Encode the request object into a query parameter string
  const sentObj = btoa(JSON.stringify(req));

  const TabPanel = (props) => {
    const { children, value, index } = props;
    return <div>{value === index && <h1>{children}</h1>}</div>;
  };

  useEffect(() => {
    if (quotationData?.response?.providers) {
      setLoading(false);
      setQuotes(prevQuotes => [...prevQuotes, ...quotationData.response.providers]);
    }
  }, [quotationData]);
  useEffect(() => {
    setQuotationData(quotation);
    if (quotation && Object.keys(quotation).length > 0 && !quotation?.error?.errorCode) {
      if (quotation?.status === 400 || quotation?.error?.errorCode === 400) {
        setError(true);
        if (quotation.error?.validationMessages[0]?.includes('UW rules violated!!')) {
          setErrorMsg('Sorry Digit is not serviceable to the vehicle or place where the vehicle is registered!!');
        } else {
          setErrorMsg(quotation.error?.validationMessages[0]);
        }
      } else {
        let arrAddons = options.filter((el) => {
          if (quotation?.response?.providers?.coverages?.addons[el.value]) {
            return el;
          }
        });
        setAddonsList(arrAddons);
        quotation?.providers?.discounts?.otherDiscounts?.forEach((el) => {
          if (el?.discountType === 'NCB_DISCOUNT' && Object.keys(req).length > 0) {
            req.insurance.presentNcbValue = `${el?.discountPercent}%`;
          }
        });
      }
      setLoading(false);
      if (quotation?.response?.providers?.length > 0) {
        const initialIdv = quotation?.response?.providers?.find(provider => provider.vehicleIDV?.vehicleIDV?.idv)?.vehicleIDV?.vehicleIDV?.idv;
        setIdvAmount(initialIdv || 0);
      }
    } else if (quotation && Object.keys(quotation).length > 0) {
      setError(true);

      if (quotation.error?.validationMessages[0]?.includes('UW rules violated!!')) {
        setErrorMsg('Sorry Digit is not serviceable to the vehicle or place where the vehicle is registered!!');
      } else {
        setErrorMsg(quotation.error?.validationMessages[0]);
      }
      setLoading(false);
    }
  }, [quotation]);




  let iciciCorrelationId = null;
  const getCorrelationId = () => {
    const responseData = quotationData?.response?.providers;
    if (Array?.isArray(responseData)) {
      responseData?.forEach(provider => {
        const providerName = provider?.providerName;
        const correlationId = provider?.correlationId;
        if (providerName === 'ICICI' && correlationId) {
          iciciCorrelationId = correlationId;
          console.log(iciciCorrelationId, "iciciCorrelationId")
          req.profile.customer.correlationId = iciciCorrelationId;
          AddToLocalStorage('globalObj', req);
        }
      });
    } else {
      console.log("No providers found or responseData is not an array.");
    }
  };

  useEffect(() => {
    getCorrelationId();
  }, [getCorrelationId])

  let bajajTransactionId = null;
  const bajajTrans = () => {
    const responseData = quotationData?.response?.providers;
    if (Array?.isArray(responseData)) {
      responseData?.forEach(provider => {
        const providerName = provider?.providerName;
        const transacionid = provider?.TransactionID;
        if (providerName === 'BAJAJ' && transacionid) {
          bajajTransactionId = transacionid
          req.insurance.bajajTransactionId = bajajTransactionId;
          AddToLocalStorage('globalObj', req);
        }
      });
    } else {
      console.log("No providers found or responseData is not an array.");
    }
  };

  bajajTrans();
  useEffect(() => {
    let insurenceName = GetDataLocalStorage('InsurenceCompanyName');
    let ncbDataa = GetDataLocalStorage('NcbData');
    ncbDataa ? dispatch(NCBSuccessRequest(ncbDataa)) : dispatch(getNCBValue());
    insurenceName ? dispatch(InsurerNamesSuccessRequest(insurenceName)) : dispatch(getInsurerName());
    setReq(GetDataLocalStorage('globalObj') != null ? GetDataLocalStorage('globalObj') : {});
    const iciciCorrelationId = quotationData?.response?.providers?.find(provider => provider?.name === 'ICICI')?.correlationId;
    if (iciciCorrelationId) {
      req.insurance.correlationId = iciciCorrelationId
      AddToLocalStorage('globalObj', req);
    }
    getCorrelationId();

  }, []);

  useEffect(() => {
    setLoading(true);
    if (Object.keys(req).length > 0) {
      if (req.previousPolicyDetails.policyCompany != '') {
        insurerName.forEach((el) => {
          if (el.Name == req.previousPolicyDetails.policyCompany || el.godigitShortCode == req.previousPolicyDetails.policyCompany) {
            req.previousPolicyDetails.policyCompany = el.godigitShortCode;
          }
          if (el.Name == req.previousPolicyDetails.policyTPCompany || el.godigitShortCode == req.previousPolicyDetails.policyTPCompany) {
            req.previousPolicyDetails.policyTPCompany = el.godigitShortCode;
          }
        });
      }
      dispatch(getQuotationData(req));
      if (req.previousPolicyDetails.policyCompany != '') {
        insurerName.forEach((el) => {
          if (el.Name == req.previousPolicyDetails.policyCompany || el.godigitShortCode == req.previousPolicyDetails.policyCompany) {
            req.previousPolicyDetails.policyCompany = el.Name;
          }
          if (el.Name == req.previousPolicyDetails.policyTPCompany || el.godigitShortCode == req.previousPolicyDetails.policyTPCompany) {
            req.previousPolicyDetails.policyTPCompany = el.Name;
          }
        });
      }
    }
  }, [req]);

  const handleOpen = () => setOpen(true);
  const handleOpened = () => setOpened(false);

  const handleClose = (e, reason) => {
    if (reason && reason == 'backdropClick') {
      return;
    } else {
      setOpen(false);
      setSaodTPDetailsOpen(false);
    }
  };

  const handleAdditionalCovers = (event, addition_name) => {
    if (event.target.value === '') {
      additionalCover[addition_name] = {
        selection: false,
        [event.target.name]: null,
        insuredCount: null
      };
      setAdditionalCover({ ...additionalCover });
    } else {
      additionalCover[addition_name] = {
        [event.target.name]: event.target.value,
        selection: true,
        insuredCount: 1
      };
      setAdditionalCover({ ...additionalCover });
    }
  };

  const handleAdditionalApply = () => {
    const un_named_pa = ['unnamedPax', 'unnamedPaidDriver', 'unnamedCleaner', 'unnamedConductor'];

    for (const [key, value] of Object.entries(additionalCover)) {
      if (un_named_pa.includes) {
        req.insurance.coverages.unnamedPA[key] = value;
      }
    }
    let legal_liability_obj = {
      selection: false,
      insuredCount: null
    };

    if (liability) {
      legal_liability_obj = {
        selection: true,
        insuredCount: 1
      };
    }
    req.insurance.coverages.legalLiability.paidDriverLL = legal_liability_obj;
    req.insurance.coverages.legalLiability.employeesLL = legal_liability_obj;
    req.insurance.coverages.legalLiability.unnamedPaxLL = legal_liability_obj;
    req.insurance.coverages.legalLiability.cleanersLL = legal_liability_obj;
    req.insurance.coverages.legalLiability.workersCompensationLL = legal_liability_obj;

    setReq({ ...req });
    handleAccessoriesClick();
  };

  const handleChangePolicyType = (e) => {
    console.log(e.target.value, "check...")
    if (e.target.value == 'OWN DAMAGE') {
      setSaodTPDetailsOpen(true);
    } else {
      const policyType_obj = { ...req.insurance, [e.target.name]: e.target.value };
      setReq({ ...req, insurance: policyType_obj });
    }
  };

  const handleChangeClaim = (e) => {
    if (e.target.value == true) {
      req.insurance.ncbValue = 'ZERO';
    }
    let cliam_obj = { ...req.insurance, [e.target.name]: JSON.parse(e.target.value) };
    setReq({ ...req, insurance: cliam_obj });
  };

  const handleIdv = (e, value) => {
    const idv = e.target.value;
    setIdvAmount(idv);
    setUpdatedIdv(idv);
  };

  const IdvApply = () => {
    let obj = { ...req };
    obj.vehicle.vehicleIDV.idv = updatedIdv;
    setReq(obj);
    setIdvValue(updatedIdv);
    handleAccessoriesClick();
    setShowQuotations(true);
  };

  const handleAccessories = (event, access_name) => {
    if (event.target.value === '') {
      accessories[access_name] = {
        selection: false,
        [event.target.name]: 0
      };
      setAccessories({ ...accessories });
    } else {
      accessories[access_name] = {
        [event.target.name]: event.target.value,
        selection: true
      };
      setAccessories({ ...accessories });
    }
  };

  const handleOtherCover = (event, access_name) => {
    if (event.target.value === '') {
      accidentCover[access_name] = {
        [event.target.name]: 0,
        selection: false
      };
      setAccidentCover({ ...accidentCover });
    } else {
      accidentCover[access_name] = {
        [event.target.name]: event.target.value,
        selection: true
      };
      setAccidentCover({ ...accidentCover });
    }
  };
  const handleHandiiCapedCover = (event, access_name) => {
    if (event.target.value === '') {
      handiCapedCover[access_name] = {
        [event.target.name]: 0,
        selection: false
      };
      setHandiCapedCover({ ...handiCapedCover });
    } else {
      handiCapedCover[access_name] = {
        [event.target.name]: event.target.value,
        selection: true
      };
      setHandiCapedCover({ ...handiCapedCover });
    }
  }
  const handleOtherCoversApply = () => {
    let global_accessories = req?.insurance?.coverages?.personalAccident
    for (const [key, value] of Object.entries(accidentCover)) {
      global_accessories = { ...value };
    }
    if (req?.insurance?.coverages?.personalAccident) {
      req.insurance.coverages.personalAccident = global_accessories;
      setReq({ ...req });
      handleAccessoriesClick();
    }
    let global_accessories1 = req?.insurance?.coverages?.handiCapDiscount
    for (const [key, value] of Object.entries(handiCapedCover)) {
      global_accessories1 = { ...value };
    }
    if (req?.insurance?.coverages?.handiCapDiscount) {
      req.insurance.coverages.handiCapDiscount = global_accessories1;
      setReq({ ...req });
      handleAccessoriesClick();
    }
  };

  const handleAccessoriesApply = () => {
    let global_accessories = req?.insurance?.coverages?.accessories;
    for (const [key, value] of Object.entries(accessories)) {
      global_accessories[key] = { ...value };
    }
    if (req?.insurance?.coverages?.accessories) {
      req.insurance.coverages.accessories = global_accessories;
      setReq({ ...req });
      handleAccessoriesClick();
    }
  };

  const handleAddons = (e, addon) => {
    const updatedAddons = e.target.checked
      ? [...addons, addon]
      : addons.filter(selectedAddon => selectedAddon !== addon);
    const cleanArray = updatedAddons.filter(item => item !== null && item !== undefined)
    setAddons(cleanArray);
  };


  const handleAccessoriesClick = () => {
    AddToLocalStorage('globalObj', req);
  };

  const getImageUrl = (data) => {
    return (
      insurerName.length > 0 &&
      insurerName.filter((obj) => {
        if (obj.shortCode === data.providerName) {
          return obj;
        }
      })[0]
    );
  };

  const handleCheckBox = (data, checkBoxStatus, req) => {
    if (checkBoxStatus) {
      const obj = {
        ...data,
        ...req,
        imageUrl: getImageUrl(data)
      };
      setArr([...arr, obj]);
      setSelectedCount(selectedCount + 1)
    } else {
      const removedData = arr.filter((obj) => obj?.providerName !== data?.providerName);
      setArr(removedData);
      setSelectedCount(selectedCount - 1)
    }
  };

  const handleChangeInsurence = (e) => {
    let ncbObj = { ...req.insurance, [e.target.name]: e.target.value };
    setReq({ ...req, insurance: ncbObj });
  };

  useEffect(() => {
    const providers = quotationData?.response?.providers;
    if (providers && providers.length > 0) {
      let newMinIdv = Infinity;
      let newMaxIdv = -Infinity;
      providers.forEach(provider => {
        const minimumIDV = provider?.vehicleIDV?.vehicleIDV?.minimumIdv
        const maximumIDV = provider?.vehicleIDV?.vehicleIDV?.maximumIdv;

        if (minimumIDV && maximumIDV) {
          const minIdvValue = parseInt(minimumIDV);
          const maxIdvValue = parseInt(maximumIDV);

          newMinIdv = Math.min(newMinIdv, minIdvValue);
          newMaxIdv = Math.max(newMaxIdv, maxIdvValue);
        }
      });

      setMinimumIdv(newMinIdv);
      setMaximumIdv(newMaxIdv);
    }
  }, [quotationData])

  const scrollBarStyles = {
    overflow: 'auto', // Enable scrollbars
    '&::-webkit-scrollbar': {
      width: '0.7em', // Width of the scrollbar
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F5F7F8', // Color of the track
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#87cbf5', // Color of the thumb
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#5AB2FF', // Color of the thumb on hover
    },
  }
  const scrollBarHidden = {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }

  const handleApply = () => {
    req.insurance.isZeroDep = previousPolicyZeroDep
    req.insurance.isRTICover = previousPolicyReturnToInvoice
    AddToLocalStorage('globalObj', req)
    handleAddonsApply();
    setOpenAddons(false);
    setOpenDeclaration(false);
  };

  const handleAddonsChange = (e) => {
    const { value, checked } = e.target;
    handleAddons(e, value);
    if (checked && (value === 'partsDepreciation' || value === 'returnToInvoice')) {
      setSelectedAddons((prev) => [...prev, value]);
      setOpenDeclaration(true);
    } else if (!checked) {
      setSelectedAddons((prev) => prev.filter((addon) => addon !== value));
    }
  };

  const getAddonTitle = (addon) => {
    const titles = {
      partsDepreciation: "Zero Depreciation",
      returnToInvoice: "Return to Invoice",
    };
    return titles[addon];
  };
  const handlePreviousPolicyZeroDepChange = (event) => {
    setPreviousPolicyZeroDep(event.target.value);
  };

  const handlePreviousPolicyReturnToInvoiceChange = (event) => {
    setPreviousPolicyReturnToInvoice(event.target.value);
  };

  const handleOpenDeclaration = () => {
    setOpenDeclaration(!openDeclaration);
  };

  const handleAddonsApply = (e) => {
    const arr2 = {
      partsDepreciation: {
        selection: false,
      },
      roadSideAssistance: {
        selection: false
      },
      engineProtection: {
        selection: false
      },
      tyreProtection: {
        selection: false
      },
      rimProtection: {
        selection: false
      },
      returnToInvoice: {
        selection: false
      },
      consumables: {
        selection: false
      },
      keyAndLockProtect: {
        selection: false
      },
      lossOfPersonalBelongings: {
        selection: false
      }
    };
    const obj = { ...req };
    obj.insurance.coverages.addons = arr2;
    addons.forEach((el) => {
      obj.insurance.coverages.addons[el]['selection'] = true;
    });
    setReq(obj);
    handleAccessoriesClick();
  };
  // ====
  const [isMobile, setIsMobile] = useState(false);

  console.log(quotation?.response?.providers, 'providers');

  let addonData;
  let selectedKeys;
  if (quotation?.response?.providers && quotation?.response?.providers[0]?.coverages?.addons) {
    addonData = quotation?.response?.providers[0]?.coverages?.addons;
    selectedKeys = Object?.keys(addonData)?.filter(key => addonData[key]?.selection === true);
  }

  useEffect(() => {
    if (selectedKeys) {
      setAddons(selectedKeys);
    }
  }, [addonData])

  const NavItems = (
    <Box sx={{ width: '300px' }}>

      <Toolbar>
        <Box component={Stack} gap={2} flexDirection='row' alignItems='center'>
          <TuneRoundedIcon />
          <Typography variant='subtitle1'><b>Sort & Filter</b></Typography></Box>
      </Toolbar>

      <Divider />
      <List>
        <ListItem sx={{ display: { md: 'none', xs: 'block' } }}>
          <ListItemText primary={req?.vehicle?.makerModel + "," + req?.vehicle?.model + "," + req?.vehicle?.variant} />
        </ListItem>
        <ListItem sx={{ display: { md: 'none', xs: 'block' } }}>
          <ListItemText primary={req?.vehicle?.isVehicleNew ? 'dd/mm/yyyy' : moment(req?.previousPolicyDetails?.policyEndDate != '') ? moment(req?.previousPolicyDetails?.policyEndDate).format('DD-MM-YYYY') : ''} />
        </ListItem>
        <ListItem>
          <TextField
            select
            defaultValue={'COMPREHENSIVE'}
            name="policyType"
            labelId="demo-simple-select"
            label={'Policy Type'}
            value={req?.insurance?.policyType}
            onChange={(e) => handleChangePolicyType(e)}
            sx={{ width: "100%" }}
          >
            {/* Comprehensive for New or Existing Vehicles */}
            {req?.vehicle?.isVehicleNew ? (
              <MenuItem value={'COMPREHENSIVE'}>
                COMPREHENSIVE (1OD-3TP)
              </MenuItem>
            ) : (
              <MenuItem value={'COMPREHENSIVE'}>
                COMPREHENSIVE
              </MenuItem>
            )}

            {/* Third Party - Only for Existing Vehicles */}
            {!req?.vehicle?.isVehicleNew && (
              <MenuItem value={'THIRD PARTY'}>
                THIRD PARTY
              </MenuItem>
            )}

            {/* Own Damage - Only for Existing Vehicles */}
            {!req?.vehicle?.isVehicleNew && (
              <MenuItem value={'OWN DAMAGE'}>
                STAND ALONE OWN DAMAGE
              </MenuItem>
            )}
          </TextField>

        </ListItem>
        {req?.vehicle?.isVehicleNew === false ? <ListItem>
          <Stack direction='row' width='100%' gap={1}>
            <TextField size='small' select label="Claims made" name="claimsMade" value={req?.insurance?.claimsMade} onChange={(e) => handleChangeClaim(e)} fullWidth>
              <MenuItem value={true}>
                Yes
              </MenuItem>
              <MenuItem value={false}>
                No
              </MenuItem>
            </TextField>
            {req?.insurance?.claimsMade == false ? (
              <TextField select size='small'
                fullWidth
                label={quotationDetails?.ncb}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="ncbValue"
                value={req?.insurance?.ncbValue || ''}
                onChange={(e) => handleChangeInsurence(e)}
              >
                {ncbData &&
                  ncbData.length > 0 &&
                  ncbData?.map((data) => {
                    return (
                      <MenuItem value={data.value} key={data.value}>
                        {data.valuePercentage}
                      </MenuItem>
                    );
                  })}
              </TextField>

            ) : (
              ''
            )}
          </Stack>
        </ListItem> : ''}

      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <Accordion defaultExpanded sx={{ width: '100%', boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <b>Set IDV</b>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                value={idvAmount} onChange={(e) => handleIdv(e)} name="idv"
                InputProps={{
                  startAdornment: <InputAdornment position="start"><CurrencyRupeeIcon fontSize='small' /></InputAdornment>,
                }} label="IDV value"></TextField>
              <Slider
                valueLabelDisplay="auto"
                name="idv"
                value={idvAmount}
                // value={1520830}

                onChange={handleIdv} min={minimumIdv} max={maximumIdv}
              />
              <Stack direction='row' columnGap={1} sx={{ pb: 1 }}>
                <TextField
                  value={currencyFormat(minimumIdv)}
                  label="₹ Min" size='small'></TextField>
                <TextField
                  value={currencyFormat(maximumIdv)}
                  label="₹ Max" size='small'></TextField>
              </Stack>
              <Stack justifyContent="flex-end" flexDirection='row'>
                <Button size='small'
                  onClick={IdvApply} variant='contained'>Apply</Button>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </ListItem>

        <ListItem disablePadding>
          <Accordion sx={{ width: '100%', boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <b>Add-ons</b>
              <Divider />
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {/* {options?.map((el) => {
                  const is_checked = addons.includes(el.value);
                  return (
                    <FormControlLabel
                      key={el.title}
                      className="twocolelement"
                      control={
                        <Checkbox
                          name={el.value}
                          onChange={handleAddonsChange}
                          value={el.value}
                          checked={is_checked}
                          color="primary"
                        />
                      }
                      label={el.title}
                    />
                  );
                })} */}
                {options?.map((el) => {
                  const is_checked = addons.includes(el.value);
                  return (
                    <FormControlLabel
                      key={el.title}
                      className="twocolelement"
                      control={
                        <Checkbox
                          name={el.value}
                          onChange={handleAddonsChange}
                          value={el.value}
                          checked={is_checked}
                          color="primary"
                        />
                      }
                      label={el.title}
                    />
                  );
                })}
                <Stack justifyContent="flex-end" flexDirection="row">
                  <Button size="small" onClick={handleAddonsApply} variant='contained' >Apply</Button>
                </Stack>
              </List>

              {req?.vehicle?.isVehicleNew === true ? '' : <Dialog open={openDeclaration} onClose={handleOpenDeclaration} className="">
                <DialogContent>
                  <div className="row declaration-modal-heading">
                    <div className="col-10"><h4>Declaration</h4></div>
                    <div className="col-2">
                      <CloseIcon className="text-primary" sx={{ cursor: 'pointer' }} onClick={handleOpenDeclaration} />
                    </div>
                  </div>
                  <div className="declaration-content">
                    <h6>Please read and accept the declaration for the selected add-ons.</h6>
                    {selectedAddons.includes("partsDepreciation") && (
                      <div key="partsDepreciation">
                        <p>In your previous policy, did you take {getAddonTitle("partsDepreciation")}?</p>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="previous-policy-zeroDep"
                            name="previousPolicy-partsDepreciation"
                            value={previousPolicyZeroDep}
                            onChange={handlePreviousPolicyZeroDepChange}
                          >
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                    {selectedAddons.includes("returnToInvoice") && (
                      <div key="returnToInvoice">
                        <p>In your previous policy, did you take {getAddonTitle("returnToInvoice")}?</p>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="previous-policy-returnToInvoice"
                            name="previousPolicy-returnToInvoice"
                            value={previousPolicyReturnToInvoice}
                            onChange={handlePreviousPolicyReturnToInvoiceChange}
                          >
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}
                  </div>
                </DialogContent>
                <DialogActions>
                  <button className="btn btn-primary" onClick={handleApply}>
                    Accept
                  </button>
                </DialogActions>
              </Dialog>}
            </AccordionDetails>
          </Accordion>
        </ListItem>

        <ListItem disablePadding>
          <Accordion sx={{ width: '100%', boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <b>Additional Covers</b>
              <Divider />
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {req?.vehicle?.vehicleType === 'Commercial' ? (
                  <div>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox onChange={(e) => setLiability(e.target.checked)} value={liability} />} label="Legal Liability" />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox onChange={(e) => setShow(e.target.checked)} />} label="Un Named PA" />
                      {show === true ? (
                        <div>
                          <h6 className="mt-4">Un Named Passenger</h6>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="insuredAmount"
                            label="Un Named Passenger"
                            value={additionalCover?.unnamedPax?.insuredAmount}
                            onChange={(e) => handleAdditionalCovers(e, 'unnamedPax')}
                            sx={{ width: '80%' }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  width: '5%',
                                  borderRadius: '10px',
                                  fontFamily: 'Bradley Hand'
                                }
                              }
                            }}
                          >
                            <MenuItem value={50000}>50,000</MenuItem>
                            <MenuItem value={100000}>1,00,000</MenuItem>
                            <MenuItem value={150000}>1,50,000</MenuItem>
                            <MenuItem value={200000}>2,00,000</MenuItem>
                          </Select>
                        </div>
                      ) : (
                        ''
                      )}
                      <div>
                        <div>
                          {show === true ? (
                            <div>
                              <h6 className="mt-4">Un Named Paid Driver</h6>

                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="insuredAmount"
                                label="Un Named Paid Driver"
                                value={additionalCover?.unnamedPaidDriver?.insuredAmount}
                                onChange={(e) => handleAdditionalCovers(e, 'unnamedPaidDriver')}
                                sx={{ width: '80%' }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      width: '5%',
                                      borderRadius: '10px',
                                      fontFamily: 'Bradley Hand'
                                    }
                                  }
                                }}
                              >
                                <MenuItem value={50000}>50,000</MenuItem>
                                <MenuItem value={100000}>1,00,000</MenuItem>
                                <MenuItem value={150000}>1,50,000</MenuItem>
                                <MenuItem value={200000}>2,00,000</MenuItem>
                              </Select>
                            </div>
                          ) : (
                            ''
                          )}
                          {show === true ? (
                            <div>
                              <h6 className="mt-4">Un Named Conductor</h6>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="insuredAmount"
                                label="Un Named Conductor"
                                value={additionalCover?.unnamedConductor?.insuredAmount}
                                onChange={(e) => handleAdditionalCovers(e, 'unnamedConductor')}
                                sx={{ width: '80%' }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      width: '5%',
                                      borderRadius: '10px',
                                      fontFamily: 'Bradley Hand'
                                    }
                                  }
                                }}
                              >
                                <MenuItem value={50000}>50,000</MenuItem>
                                <MenuItem value={100000}>1,00,000</MenuItem>
                                <MenuItem value={150000}>1,50,000</MenuItem>
                                <MenuItem value={200000}>2,00,000</MenuItem>
                              </Select>
                            </div>
                          ) : (
                            ''
                          )}
                          {show === true ? (
                            <div>
                              <h6 className="mt-4">Un Named Cleaner</h6>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="insuredAmount"
                                label="Un Named Cleaner"
                                value={additionalCover?.unnamedCleaner?.insuredAmount}
                                onChange={(e) => handleAdditionalCovers(e, 'unnamedCleaner')}
                                sx={{ width: '80%' }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      width: '5%',
                                      borderRadius: '10px',
                                      fontFamily: 'Bradley Hand'
                                    }
                                  }
                                }}
                              >
                                <MenuItem value={50000}>50,000</MenuItem>
                                <MenuItem value={100000}>1,00,000</MenuItem>
                                <MenuItem value={150000}>1,50,000</MenuItem>
                                <MenuItem value={200000}>2,00,000</MenuItem>
                              </Select>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </FormGroup>
                  </div>
                ) : (<FormGroup>
                  <FormControlLabel control={<Checkbox onChange={(e) => setLiability(e.target.checked)} value={liability} />} label="Legal Liability" />
                  <FormControlLabel control={<Checkbox onChange={(e) => setShow(e.target.checked)} />} label="Un Named PA" />
                  {show === true ? (
                    <div>
                      <h6 className="mt-1">Un Named Passenger</h6>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="insuredAmount"
                        fullWidth
                        value={additionalCover?.unnamedPax?.insuredAmount}
                        onChange={(e) => handleAdditionalCovers(e, 'unnamedPax')}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              width: '5%',
                              borderRadius: '10px',
                              fontFamily: 'Bradley Hand'
                            }
                          }
                        }}
                      >
                        <MenuItem value={50000}>50,000</MenuItem>
                        <MenuItem value={100000}>1,00,000</MenuItem>
                        <MenuItem value={150000}>1,50,000</MenuItem>
                        <MenuItem value={200000}>2,00,000</MenuItem>
                      </Select>
                    </div>
                  ) : (
                    ''
                  )}
                  <div>
                    <div>
                      {show === true ? (
                        <div>
                          <h6 className="mt-1">Un Named Paid Driver</h6>

                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="insuredAmount"
                            fullWidth
                            value={additionalCover?.unnamedPaidDriver?.insuredAmount}
                            onChange={(e) => handleAdditionalCovers(e, 'unnamedPaidDriver')}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  width: '5%',
                                  borderRadius: '10px',
                                  fontFamily: 'Bradley Hand'
                                }
                              }
                            }}
                          >
                            <MenuItem value={50000}>50,000</MenuItem>
                            <MenuItem value={100000}>1,00,000</MenuItem>
                            <MenuItem value={150000}>1,50,000</MenuItem>
                            <MenuItem value={200000}>2,00,000</MenuItem>
                          </Select>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </FormGroup>
                )}
                <Stack justifyContent="flex-end" flexDirection="row">
                  <Button size="small" onClick={handleAdditionalApply} variant='contained' >Apply</Button>
                </Stack>
              </List>
            </AccordionDetails>
          </Accordion>
        </ListItem>
        <ListItem disablePadding>
          <Accordion sx={{ width: '100%', boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <b>Accessories</b>
              <Divider />
            </AccordionSummary>
            <AccordionDetails>
              <List>

                <div className="row">

                  <TextField helperText="Min:10000 to Max:50000" fullWidth id="outlined-basic" name="insuredAmount" value={accessories?.electrical?.insuredAmount} label="Electrical Accessories" variant="outlined" onChange={(event) => handleAccessories(event, 'electrical')} />
                  <TextField helperText="Min:10000 to Max:50000" fullWidth id="outlined-basic" name="insuredAmount" value={accessories?.nonElectrical?.insuredAmount} label="Non-Electrical Accessories" variant="outlined" onChange={(event) => handleAccessories(event, 'nonElectrical')} />
                  <TextField helperText="Min:10000 to Max:50000" fullWidth id="outlined-basic" name="insuredAmount" value={accessories?.cng?.insuredAmount} label="CNG" variant="outlined" onChange={(event) => handleAccessories(event, 'cng')} />

                </div>

                <Stack justifyContent="flex-end" flexDirection="row">
                  <Button size="small" onClick={handleAccessoriesApply} variant='contained' >Apply</Button>
                </Stack>
              </List>
            </AccordionDetails>
          </Accordion>
        </ListItem>
        <ListItem disablePadding>
          <Accordion sx={{ width: '100%', boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <b>Other Covers</b>
              <Divider />
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <TextField id="outlined-basic" name="insuredAmount" value={accidentCover?.personalAccident?.insuredAmount} label="Personal Acciedent" variant="outlined" onChange={(e) => handleOtherCover(e, 'personalAccident')} />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={handiCapedCover?.handiCapDiscount?.selection}
                        onChange={(e) => handleHandiiCapedCover(e, 'handiCapedCover')}
                      />
                    }
                    label="Is Customer Handicaped"
                  />
                </FormGroup>


                <Stack justifyContent="flex-end" flexDirection="row">
                  <Button size="small" onClick={handleOtherCoversApply} variant='contained' >Apply</Button>
                </Stack>
              </List>
            </AccordionDetails>
          </Accordion>
        </ListItem>
      </List>
    </Box>
  );

  return (<>
    <div>
      <div>
        {alertWarning && <Alert variant="filled" action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setAlertWarning('')
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        } severity='warning'>{alertWarning}</Alert>}
      </div>
      <div>
        {hdfcErrorMsg && <Alert variant="filled" action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setHdfcErrorMsg('')
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        } severity='error'>{hdfcErrorMsg}</Alert>}
      </div>
      <div>
        {Object.keys(req).length > 1 ? <SAODThirdPartyDetails open={saodTPDetailsOpen} setOpen={setSaodTPDetailsOpen} req={req} setReq={setReq} handleClose={handleClose} insurerName={insurerName} /> : ''}
      </div>
      <div>
        <AlertError open={error} setOpen={setError} message={errorMssg} />
      </div>
    </div>

    <Stack direction='row'>
      <Box sx={{ borderRight: '1px solid #0000001f' }}>
        <Drawer variant='temporary' onClose={() => setIsMobile(false)} open={isMobile}>
          {NavItems}
        </Drawer>
        <Box sx={{ display: { xs: 'none', md: 'block' }, maxHeight: 'calc(100dvh - 65px)', overflowY: 'scroll', ...scrollBarStyles }}>
          {NavItems}
        </Box>
      </Box>

      <Box flex={1} sx={{ maxHeight: 'calc(100dvh - 65px)', overflowY: 'scroll', ...scrollBarHidden }}>
        <Toolbar sx={{ justifyContent: 'space-between', display: { md: 'none' }, backgroundColor: '#edf7ff61', position: "sticky" }}>
          <Typography variant='body2'>Showing 10 search results</Typography>
          <Button
            size='small'
            variant='outlined'
            startIcon={<FilterAltIcon fontSize='small' />}
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={() => { setIsMobile((prev) => !prev) }}
            sx={{ borderRadius: 999, fontSize: '0.5em' }}
          >
            <Typography variant='subtitle2'>Filter</Typography>
          </Button>
        </Toolbar>



        <Container maxWidth='lg' sx={{ py: { xs: 1, md: 3 } }}>
          <Grid container columns={18} spacing={2}>
            <Grid item xs={18}>
              <Grid container Gap={2} rowGap={3}>

                {/* quote cards */}
                <Grid container rowSpacing={2}>
                  <Stack direction="row" sx={{ width: '100%', marginTop: '30px', display: { xs: 'none', md: 'flex' } }}>
                    <ListItem>
                      <b>Vehicle: </b> <ListItemText primary={req?.vehicle?.makerModel + ', ' + req?.vehicle?.model + ' ' + req?.vehicle?.variant} />
                    </ListItem>
                    <ListItem>
                      <b>Registration Date:</b>
                      <ListItemText
                        primary={
                          req?.vehicle?.registrationDate
                            ? moment(req.vehicle.registrationDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
                            : ''
                        }
                      />
                    </ListItem>
                    {req?.vehicle?.isVehicleNew === false ? <ListItem><b>Policy End Date:</b><ListItemText primary={moment(req?.previousPolicyDetails?.policyEndDate).format('DD-MM-YYYY')}></ListItemText></ListItem> : ''}
                  </Stack>
                  <Divider sx={{ width: '100%' }} />
                  <Grid container rowSpacing={2} mt={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {loading ? (
                      <Stack width={'100%'} height={500} direction={'row'} justifyContent={'center'} alignItems={'center'}>
                        <CircularProgress sx={{ mx: 'auto' }} >
                          Fetching Best Plans
                        </CircularProgress>
                      </Stack>
                    ) : (
                      quotationData?.response?.providers?.map((e, i) =>
                        <QuoteCard data={e} handleCheckBox={handleCheckBox} req={req} setReq={setReq} idvAmount={idvValue} response={quotationData?.response?.providers} />
                      )
                    )
                    }
                  </Grid>
                </Grid>
                {/* cmpr btn */}
                <div>
                  <Badge badgeContent={arr.length} color='primary'>
                    <Button onClick={() => {
                      if (arr.length <= 3) {
                        handleOpen()
                      } else {
                        alert("You can only compare up to 3 items.")
                      }
                    }}
                      variant="contained"
                      startIcon={<CompareArrowsRoundedIcon />}>Compare
                    </Button>
                  </Badge>
                  <AddToCompareModal open={open} handleClose={handleClose} arr={arr} req={req} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>

      </Box>

      <Stack width={300} rowGap={3} py={3} pr={3} sx={{ display: { xs: 'none', md: 'flex' }, maxHeight: 'calc(100dvh - 65px)', overflowY: 'scroll', ...scrollBarHidden }}>
        {[1, 2].map((e) => <Skeleton sx={{ borderRadius: '1em', minHeight: '120px' }} variant='rectangular' animation="pulse"></Skeleton>)}
      </Stack>
    </Stack>
  </>
  );
};

export default QuotationDetails;
