import { Button, Grid, TextField,  MenuItem,  Stack } from "@mui/material";
import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
// import { useFormContext } from 'react-hook-form';

const BankDetails = (props) => {
    const { register, handleSubmit, trigger, formState: { errors, isSubmitted }, getValues, setValue } = useForm()

    useEffect(() => {
        // it is usefull when user click back button
        for (let name in props.bankData) {
            setValue(name, props.bankData[name])
        }
    }, [])

    const changeHandler = async (e) => {

        const { name, value } = e.target
        if (name === 'account_number') {
            setValue(name, value.slice(0, 18))

        } else if (name === 'ifsc_code') {
            setValue(name, value.slice(0, 11).toUpperCase())
        } 
        else {
            setValue(name, value.toUpperCase())
        }

        { isSubmitted && await trigger(name) }

    }

    const submitHandler = (e) => {
        const { clickNextBtn } = props
        clickNextBtn(e)
    }

    return (
        <form onSubmit={handleSubmit(submitHandler)} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={4}>


                <Grid item xs={12} md={4}>
                    <TextField fullWidth label="Account Holder Name" color="primary" name="account_holder_name" autoComplete="off"
                        {...register('account_holder_name', {
                            required: 'Please Enter Holder Name',
                            pattern: {
                                value: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,

                                message: 'Invalid Holder Name'
                            }
                        })}
                        onChange={changeHandler}
                        error={!!errors.account_holder_name}
                        helperText={errors.account_holder_name ? errors.account_holder_name.message : ''}
                    />
                </Grid>


                <Grid item xs={12} md={4}>
                    <TextField fullWidth label="Bank Name" color="primary" name="bank_name" autoComplete="off"
                        {...register('bank_name', {
                            required: 'Please Enter Bank Name',
                            pattern: {
                                value: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,

                                message: 'Invalid Bank Name'
                            }
                        })}
                        onChange={changeHandler}
                        error={!!errors.bank_name}
                        helperText={errors.bank_name ? errors.bank_name.message : ''}
                    />
                </Grid>


                <Grid item xs={12} md={4}>
                    <TextField fullWidth label="Branch Name" color="primary" name="branch_name" autoComplete="off"
                        {...register('branch_name', {
                            required: 'Please Enter Branch Name',
                            pattern: {
                                value: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,

                                message: 'Invalid Branch Name'
                            }
                        })}
                        onChange={changeHandler}
                        error={!!errors.branch_name}
                        helperText={errors.branch_name ? errors.branch_name.message : ''}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField fullWidth label="Account Type" select color="primary" name="account_type" defaultValue={props.bankData.account_type || ''}
                        {...register('account_type', {
                            required: 'Please Select Account Type'
                        })}
                        onChange={changeHandler}
                        error={!!errors.account_type}
                        helperText={errors.account_type ? errors.account_type.message : ''}
                    >
                        <MenuItem value="Current">Current</MenuItem>
                        <MenuItem value="Savings">Savings</MenuItem>
                    </TextField>
                </Grid>


                <Grid item xs={12} md={4}>
                    <TextField fullWidth type="number" label="Account Number" color="primary" name="account_number"
                        {...register('account_number', {
                            required: 'Please Enter Account Number',
                            pattern: {
                                value: /^\d{5,18}$/,
                                message: 'Invalid Account Number'
                            }
                        })}
                        onChange={changeHandler}
                        error={!!errors.account_number}
                        helperText={errors.account_number ? errors.account_number.message : ''}
                    />
                </Grid>
                

                <Grid item xs={12} md={4}>
                    <TextField fullWidth label="IFSC Code" color="primary" name="ifsc_code" autoComplete="off"
                        {...register('ifsc_code', {
                            required: 'Please Enter IFSC CODE',
                            pattern: {
                                value: /^[A-Z]{4}0[A-Z0-9]{6}$/,
                                message: 'Invalid IFSC CODE'
                            }
                        })}
                        onChange={changeHandler}
                        error={!!errors.ifsc_code}
                        helperText={errors.ifsc_code ? errors.ifsc_code.message : ''}
                    />
                </Grid>

            </Grid>
            <Stack direction={"row"} justifyContent={'end'} marginTop={3}>
                <Button variant="contained" color="primary" sx={{ marginRight: '20px' }} onClick={() => props.clickBackBtn(getValues())}>Back</Button>
                <Button variant="contained" color="primary" type="submit">Next</Button>
            </Stack>

        </form>
    )
}

export default BankDetails