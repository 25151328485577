import React from 'react';
import './CustomCheckboxTravel.css';
import { useDispatch } from 'react-redux';
const CustomCheckboxTravel = ({state,toggle}) => {
let dispatch = useDispatch()
 
  return (
    <label className="custom-checkbox-container">
      <input
        type="checkbox"
        checked={state.rvf}
        className="custom-checkbox-input"
        onChange={() => {
          dispatch({type:"HANDLE_RVF_ADDON"})
          toggle((prev) => {
            return { ...prev, rvf: !prev.rvf };
          });
        }}
      />
      <div className="custom-checkbox-checkmark"></div>
      {"" && <span>{"label"}</span>}
    </label>
  );
};

export default CustomCheckboxTravel;