import {
    FETCH_POSP_DOCUMENTS_DATA_FAILURE,
    FETCH_POSP_DOCUMENTS_DATA_SUCCESS,
} from "../../action-type/posp/actionType";

const initialState = {
    list: null,
    error: null,
};

const pospdocumentsDataReducer = (data = initialState, action) => {
    switch (action.type) {
        case FETCH_POSP_DOCUMENTS_DATA_SUCCESS:
            return {...data, list: action.payload};
        case FETCH_POSP_DOCUMENTS_DATA_FAILURE:
            return {...data, error: action.error};
        default:
            return data;
    }
};

export default pospdocumentsDataReducer;