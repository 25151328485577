import { legacy_createStore, applyMiddleware, compose, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { dashboardReducer } from './Dashboard/DashboardReducer';
import { motorReducer } from './Reducer';
import { healthReducer } from './Health/healthReducer';
import { travelReducer } from './Travel/travelReducer';
import { loanReducer } from './Loan/loanReducer';
import authReducer from './reducers/authReducer';
import apiReducer from "./reducers/menuReducer";
import errorReducer from "./reducers/errorReducer";
import successReducer from "./reducers/successReducer";
import customerReducer from "./reducers/customerReducer";
import masterDataReducer from "./reducers/masterDataReducer";
import pincodeMasterDataReducer from "./reducers/pincodeMasterDataReducer";

import customizationReducer from './crmustomization/customizationReducer';
import pospReducer from './reducers/posp/pospReducer';
import documentsDataReducer from './reducers/documentsDataReducer';
import pospdocumentsDataReducer from './reducers/posp/pospdocumentsDataReducer';
import examReducer from './reducers/posp/examinationreducer/question_reducer';
import { adminReducer } from './admin/adminReducer';
const composeEnhancer = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE_ || compose;
const persistConfig = {
  key: 'root',
  storage
};
const rootReducer = combineReducers({ adminReducer,motorReducer, dashboardReducer, healthReducer, travelReducer,loanReducer,auth: authReducer,
  customer:customerReducer, 
  menu: apiReducer,
  error: errorReducer,
  success:successReducer,
  masterData: masterDataReducer,
  pincodeMasterData: pincodeMasterDataReducer,
  customization: customizationReducer,
  posp: pospReducer,
  documentsData: documentsDataReducer,
  pospDocumenstData: pospdocumentsDataReducer,
  exam: examReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = legacy_createStore(persistedReducer, composeEnhancer(applyMiddleware(thunk)));

const persistor = persistStore(store);
export { store, persistor };
