import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, TextField, MenuItem, Button, IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";


const FamilyDetailsPage = ({ familyDetails, NextBtn, PreviousBtn }) => {
  const {
    formState: { errors, isSubmitted },
    register,
    setValue,
    handleSubmit,
    trigger,
    reset,
  } = useForm();
  const [familyMembers, setFamilyMembers] = useState(familyDetails);
  const [showForm, setShowForm] = useState(false);

  const handleAddFamilyMember = () => {
    setShowForm(true);
  };

  const handleSaveMember = (data) => {
    setFamilyMembers([
      ...familyMembers,
      { relation: data?.relation, name: data?.name, dob: data?.dateOfBirth },
    ]);
    setShowForm(false);
    reset();
  };

  const handleDeleteMember = (index) => {
    const updatedMembers = familyMembers.filter((_, i) => i !== index);
    setFamilyMembers(updatedMembers);
  };

  return (
    <Box
      sx={{
        margin: "0",
        padding: "15px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", m: 2 }}>
        <FamilyRestroomIcon
          sx={{ fontSize: "2rem", color: "#0000ff", mr: 1 }}
        />
        <Typography variant="h4">Family Details</Typography>
      </Box>

      {/* data */}
      {familyMembers.length !== 0 ? (
        familyMembers?.map((member, index) => (
          <Paper
            key={index}
            sx={{
              padding: "20px",
              borderRadius: "8px",
              bgcolor: "#f5f8fd",
              marginBottom: "16px",
            }}
          >
            <Typography variant="h4" sx={{ mb: 2 }}>
              {member.relation}
            </Typography>

            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item xs={10} md={5}>
                <TextField
                  fullWidth
                  label="Member Name"
                  variant="outlined"
                  value={member.name}
                  disabled
                />
              </Grid>

              <Grid item xs={10} md={5}>
                <TextField
                  fullWidth
                  label="Date Of Birth"
                  variant="outlined"
                  value={member.dob}
                  disabled
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => handleDeleteMember(index)}
                  sx={{ fontSize: "50px" }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        ))
      ) : (
        // empty text
        <Typography
          variant="h3"
          textAlign={"center"}
          marginTop={5}
          marginBottom={5}
        >
          Empty
        </Typography>
      )}

      {/* form */}
      {showForm ? (
        <Paper sx={{ borderRadius: "8px", bgcolor: "#f5f8fd" }}>
          <form onSubmit={handleSubmit(handleSaveMember)}>
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "2px", padding: "10px" }}
            >
              <Grid item xs={12} display={"flex"} justifyContent={"right"}>
                <CloseIcon
                  sx={{ marginRight: "15px", cursor: "pointer" }}
                  onClick={() => {setShowForm(false); reset()}}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  select
                  fullWidth
                  label="Select Relation"
                  {...register("relation", {
                    required: "Please Select relation",
                  })}
                  error={!!errors.relation}
                  helperText={errors.relation ? errors.relation.message : ""}
                  // value={selectedRelation}
                  onChange={async (e) => {
                    setValue(e.target.name, e.target.value);
                    isSubmitted && (await trigger(e.target.name));
                  }}
                >
                  <MenuItem value="Spouse">Spouse</MenuItem>
                  <MenuItem value="Son">Son</MenuItem>
                  <MenuItem value="Daughter">Daughter</MenuItem>
                  <MenuItem value="Father">Father</MenuItem>
                  <MenuItem value="Mother">Mother</MenuItem>
                  <MenuItem value="Brother">Brother</MenuItem>
                  <MenuItem value="Sister">Sister</MenuItem>
                  <MenuItem value="Father-in-law">Father-in-law</MenuItem>
                  <MenuItem value="Mother-in-law">Mother-in-law</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Member Name"
                  variant="outlined"
                  {...register("name", {
                    required: "Please Enter Your Name",
                    pattern: {
                      value: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,
                      message: "Invalid First Name",
                    },
                  })}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                  // value={memberName}
                  // onChange={(e) => setMemberName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Date Of Birth"
                  variant="outlined"
                  type="date"
                  {...register("dateOfBirth", {
                    required: "Please Select date of birth",
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.dateOfBirth}
                  helperText={
                    errors.dateOfBirth ? errors.dateOfBirth.message : ""
                  }
                  // value={dob}
                  // onChange={(e) => setDob(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleSaveMember}
                  sx={{ marginTop: 2 }}
                  type="submit"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      ) : (
        // buttons
        <Stack
          spacing={2}
          direction={"row"}
          sx={{ alignSelf: "center", marginTop: "30px" }}
        >
          <Button
            variant="contained"
            onClick={() => PreviousBtn(familyMembers)}
          >
            Back
          </Button>

          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddFamilyMember}
          >
            Add a Family Member
          </Button>

          <Button variant="contained" onClick={() => NextBtn(familyMembers)}>
            Next
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default FamilyDetailsPage;
