import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import CakeIcon from "@mui/icons-material/Cake";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import GenderIcon from "@mui/icons-material/Wc";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import PolicyIcon from "@mui/icons-material/Policy";
import { Link, useLocation } from "react-router-dom";
import { getAgent, getCustomer } from "../../api/adminService";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileData } from "../../../../Redux/admin/action";

const Profile = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      if (location.pathname.includes("customer")) {
        const customerId = location.pathname.split("customer/")[1];
        const res = await getCustomer(customerId);
        console.log(res.data, "res.data");
        dispatch(fetchProfileData(res.data));
      } else if (location.pathname.includes("agent")) {
        const agentId = location.pathname.split("agent/")[1];
        const res = await getAgent(agentId);
        dispatch(fetchProfileData(res.data));
      } else {
        alert("else");
        dispatch(fetchProfileData({}));
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching profile data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const selector = useSelector((state) => state.adminReducer.profileData);
  const profileData = selector?.profile_details;
  console.log(selector, "profileData");
  const paymentLogs =
    selector?.paymentlogs &&
    selector?.paymentlogs?.map((item, id) => {
      return {
        ...item,
        id,
      };
    });
  const customerLogs =
    selector?.customerlogs &&
    selector?.customerlogs?.map((item, id) => {
      return {
        ...item,
        id,
      };
    });

  const [formData, setFormData] = useState({
    mobile: "******0445", // Default value
    dob: "10/03/2024",
    email: "Email@gmail.com",
    utm: "Direct",
    cpQuoteId: "----",
    applicationNumber: "23456792883673883",
    quoteNo: "QB16143354231951297443",
  });

  const documents = [
    { name: "PAN Front", icon: "", image: "" },
    { name: "PAN Back", icon: "", image: "" },
    { name: "Aadhar Front", icon: "", image: "" },
    { name: "Aadhar Back", icon: "", image: "" },
    { name: "Cheque", icon: "", image: "" },
    { name: "Certifaction", icon: "", image: "" },
  ];

  const DocumentCard = ({ name, icon, image, onUpload }) => {
    return (
      <>
        <Grid container alignItems="center">
          <Grid
            item
            xs={6}
            md={8}
            display="flex"
            justifyContent="start"
            alignItems="center"
          >
            <Typography variant="" marginLeft={1}>
              {name}
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} />
          <Grid item xs={2} md={1} display="flex" justifyContent="flex-end">
            <CheckCircleSharpIcon color="success" />
          </Grid>
        </Grid>
      </>
    );
  };

  const agent_Column = [
    { field: "customer_id", headerName: "Customer_id", width: 120 },
    {
      field: "provider",
      headerName: "Provider",
      width: 120,
    },
    {
      field: "product_type",
      headerName: "Type",
      width: 90,
    },
    {
      field: "product_subtype",
      headerName: "Product_SubType",
      width: 160,
    },
    {
      field: "updated_date",
      headerName: "Updated Date",
      width: 200,
    },
    { field: "updated_by", headerName: "Updated By", width: 120 },
    {
      field: "premium",
      headerName: "Premium",
      width: 110,
    },
  ];

  const customer_Column = [
    { field: "customer_id", headerName: "Customer_Id", width: 120 },
    { field: "customer_name", headerName: "Customer_Name", width: 170 },
    { field: "phone", headerName: "Phone", width: 120 },
    { field: "policy_number", headerName: "Policy_Number", width: 170 },
    {
      field: "policy_status",
      headerName: "Policy_Status",
      width: 135,
    },
    { field: "city", headerName: "City", width: 120 },
  ];

  return (
    <>
      {profileData && !loading && (
        <>
          <Grid
            container
            style={{ padding: "20px", backgroundColor: "#f0f0f0" }}
          >
            <Grid item xs={12} md={12} mb={3}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h2" gutterBottom>
                    Profile Management
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={9}>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Card
                            sx={{
                              boxShadow: 3,
                              position: "relative",
                              borderRadius: 4,
                            }}
                          >
                            <CardContent>
                              <Grid container spacing={1} alignItems="center">
                                <Grid
                                  item
                                  xs={12}
                                  md={2}
                                  container
                                  justifyContent="center"
                                >
                                  <Avatar
                                    alt={
                                      profileData?.customer_name || "Profile"
                                    }
                                    src={profileData?.profilePhoto || ""}
                                    sx={{ width: "100%", height: "100%" }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={10} container>
                                  <Typography
                                    variant="h5"
                                    component="div"
                                    gutterBottom
                                  >
                                    {profileData.name || "Name"}
                                  </Typography>
                                  <Grid container spacing={1}>
                                    <Grid
                                      item
                                      xs={12}
                                      md={3}
                                      container
                                      alignItems="center"
                                    >
                                      <PhoneIcon sx={{ marginRight: 1 }} />
                                      <Typography
                                        variant="body1"
                                        color="text.secondary"
                                      >
                                        {profileData.phone || "XXXXXXXXXXX"}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={2}
                                      container
                                      alignItems="center"
                                    >
                                      <GenderIcon sx={{ marginRight: 1 }} />
                                      <Typography
                                        variant="body1"
                                        color="text.secondary"
                                      >
                                        {profileData.gender === "M"
                                          ? "Male"
                                          : "Female" || "M/F"}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={7}
                                      container
                                      alignItems="center"
                                    >
                                      <LocationOnIcon sx={{ marginRight: 1 }} />
                                      <Typography
                                        variant="body1"
                                        color="text.secondary"
                                      >
                                        {profileData.address || "XXXXXXXXXXXXX"}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={6}
                                      container
                                      alignItems="center"
                                    >
                                      <EmailIcon sx={{ marginRight: 1 }} />
                                      <Typography
                                        variant="body1"
                                        color="text.secondary"
                                      >
                                        {profileData.email || "abc@gmail.com"}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={4}
                                      container
                                      alignItems="center"
                                    >
                                      <CakeIcon sx={{ marginRight: 1 }} />
                                      <Typography
                                        variant="body1"
                                        color="text.secondary"
                                      >
                                        {profileData.dob || "DD/MM/YYYY"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card
                            sx={{
                              width: "100%",
                              borderRadius: 4,
                              boxShadow: 3,
                              position: "relative",
                            }}
                          >
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                Document Details
                              </Typography>
                              <Typography variant="h7">
                                Photo, Aadhaar and cancelled Cheque
                              </Typography>
                              {documents.map((document) => (
                                <DocumentCard
                                  key={document.name}
                                  name={document.name}
                                  icon={document.icon}
                                  image={document.image}
                                />
                              ))}
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card
                            sx={{
                              width: "100%",
                              borderRadius: 4,
                              boxShadow: 3,
                              position: "relative",
                            }}
                          >
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                Payout Details
                              </Typography>
                              <div style={{ marginBottom: "15px" }}>
                                <Typography
                                  variant="body1"
                                  style={{ color: "#555", fontWeight: "bold" }}
                                >
                                  Pan Number:
                                </Typography>
                                <Typography
                                  variant="body1"
                                  style={{ color: "#333" }}
                                >
                                  {profileData.pancard || "xxxxxxxxxx"}
                                </Typography>
                              </div>
                              <div style={{ marginBottom: "15px" }}>
                                <Typography
                                  variant="body1"
                                  style={{ color: "#555", fontWeight: "bold" }}
                                >
                                  Dob:
                                </Typography>
                                <Typography
                                  variant="body1"
                                  style={{ color: "#333" }}
                                >
                                  {profileData.dob || "00-00-0000"}
                                </Typography>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12}>
                          <Card
                            sx={{
                              width: "100%",
                              boxShadow: 3,
                              position: "relative",
                            }}
                          >
                            <Grid
                              container
                              sx={{
                                boxShadow: 4,
                                borderRadius: "25px",
                                backgroundColor: "white",
                              }}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item xs={6}>
                                <Link to="/admin/AllPolicies">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PolicyIcon />}
                                    sx={{
                                      boxShadow: "1px 1px 3px gray",
                                      borderRadius: "15px",
                                      marginLeft: "10px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    View All Policies
                                  </Button>
                                </Link>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                display="flex"
                                justifyContent="flex-end"
                              ></Grid>

                              <Grid item xs={12}>
                                <Paper sx={{ padding: 2 }}>
                                  <Typography
                                    variant="h2"
                                    textAlign="center"
                                    gutterBottom
                                    color="#23A8FA"
                                  >
                                    Policy Details
                                  </Typography>
                                  <Divider />
                                  <Grid
                                    component={Card}
                                    container
                                    sx={{
                                      mt: 5,
                                      padding: "5px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid item xs={12} md={6}>
                                      <Typography variant="h6" gutterBottom>
                                        POLICY: #2805204056125102000
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={6}
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <Typography variant="h6" gutterBottom>
                                        Premium (Incl. GST): ₹ 30,318
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    sx={{
                                      padding: "5px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid item xs={6} md={3} lg={2}>
                                      <Box padding="5px">
                                        <img
                                          src="https://m.economictimes.com/thumb/msid-83775383,width-1200,height-900,resizemode-4,imgsize-102884/12.jpg"
                                          alt=""
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      md={3}
                                      lg={3}
                                      textAlign="center"
                                    >
                                      <Typography
                                        variant="h2"
                                        fontSize="20px"
                                        gutterBottom
                                        color="#23A8FA"
                                      >
                                        Sum Insured
                                      </Typography>
                                      <Typography variant="body1" gutterBottom>
                                        5 Lakhs
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      md={3}
                                      lg={3}
                                      textAlign="center"
                                    >
                                      <Typography
                                        variant="h2"
                                        fontSize="20px"
                                        gutterBottom
                                        color="#23A8FA"
                                      >
                                        Tenure
                                      </Typography>
                                      <Typography variant="body1" gutterBottom>
                                        1 Year
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      md={3}
                                      lg={4}
                                      textAlign="center"
                                    >
                                      <Typography
                                        variant="h2"
                                        fontSize="20px"
                                        gutterBottom
                                        color="#23A8FA"
                                      >
                                        Optima Restore Floater
                                      </Typography>
                                      <Typography variant="body1" gutterBottom>
                                        1 Adults & 2 Children
                                      </Typography>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    container
                                    sx={{
                                      padding: "5px",
                                      display: "flex",
                                    }}
                                  >
                                    {/* First Column */}
                                    <Grid item xs={12} md={6} lg={4}>
                                      <Box sx={{ padding: 2 }}>
                                        <Typography
                                          variant="h6"
                                          fontSize="20px"
                                          fontWeight="bold"
                                          color="#23A8FA"
                                          gutterBottom
                                        >
                                          Payment Date
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          gutterBottom
                                        >
                                          11 Mar, 2023
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          fontSize="20px"
                                          fontWeight="bold"
                                          color="#23A8FA"
                                          gutterBottom
                                        >
                                          Policy Date:
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          gutterBottom
                                        >
                                          11 Mar, 2023
                                        </Typography>
                                      </Box>
                                    </Grid>

                                    {/* Second Column */}
                                    <Grid item xs={12} md={6} lg={4}>
                                      <Box sx={{ padding: 2 }}>
                                        <Typography
                                          variant="h6"
                                          fontSize="20px"
                                          fontWeight="bold"
                                          color="#23A8FA"
                                          gutterBottom
                                        >
                                          This Policy Covers
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          marginTop="4px"
                                          gutterBottom
                                        >
                                          Renu Natharam
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          marginTop="4px"
                                          gutterBottom
                                        >
                                          Varju Natharam
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          marginTop="4px"
                                          gutterBottom
                                        >
                                          Arvind Natharam
                                        </Typography>
                                      </Box>
                                    </Grid>

                                    {/* Third Column */}
                                    <Grid item xs={12} md={6} lg={4}>
                                      <Box sx={{ padding: 2 }}>
                                        <Typography
                                          variant="h6"
                                          fontSize="20px"
                                          fontWeight="bold"
                                          color="#23A8FA"
                                          gutterBottom
                                        >
                                          Payment Details
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          gutterBottom
                                        >
                                          Payment Mode - Net Banking
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          gutterBottom
                                        >
                                          Creation Type - Manual
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          gutterBottom
                                        >
                                          Personal Guardian - Insurer
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          gutterBottom
                                        >
                                          Creation Date - 17 Apr, 2023
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          gutterBottom
                                        >
                                          Created By -
                                          sachin.dwarkanath@swastika.co.in
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <hr />
                                    <Grid container spacing={5} padding="5px">
                                      {/* Third Column */}
                                      <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        lg={4}
                                        textAlign="center"
                                      >
                                        <Card
                                          sx={{ boxShadow: 2, borderRadius: 4 }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "18px",
                                              fontWeight: "bold",
                                              color: "#23A8FA",
                                            }}
                                          >
                                            Mobile
                                          </Typography>
                                          <Typography variant="h6">
                                            {formData.mobile}
                                          </Typography>
                                        </Card>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        lg={4}
                                        textAlign="center"
                                      >
                                        <Card
                                          sx={{ boxShadow: 2, borderRadius: 4 }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "18px",
                                              fontWeight: "bold",
                                              color: "#23A8FA",
                                            }}
                                          >
                                            Date Of Birth
                                          </Typography>
                                          <Typography variant="h6">
                                            {formData.dob}
                                          </Typography>
                                        </Card>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        lg={4}
                                        textAlign="center"
                                      >
                                        <Card
                                          sx={{ boxShadow: 2, borderRadius: 4 }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "18px",
                                              fontWeight: "bold",
                                              color: "#23A8FA",
                                            }}
                                          >
                                            Email
                                          </Typography>
                                          <Typography variant="h6">
                                            {formData.email}
                                          </Typography>
                                        </Card>
                                      </Grid>

                                      {/* Third Column */}
                                      <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        lg={4}
                                        textAlign="center"
                                      >
                                        <Card
                                          sx={{ boxShadow: 2, borderRadius: 4 }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "18px",
                                              fontWeight: "bold",
                                              color: "#23A8FA",
                                            }}
                                          >
                                            UTM:
                                          </Typography>
                                          <Typography variant="h6">
                                            {formData.utm}
                                          </Typography>
                                        </Card>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        lg={4}
                                        textAlign="center"
                                      >
                                        <Card
                                          sx={{ boxShadow: 2, borderRadius: 4 }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "18px",
                                              fontWeight: "bold",
                                              color: "#23A8FA",
                                            }}
                                          >
                                            CP Quote Id
                                          </Typography>
                                          <Typography variant="h6">
                                            {formData.cpQuoteId}
                                          </Typography>
                                        </Card>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        lg={4}
                                        textAlign="center"
                                      >
                                        <Card
                                          sx={{ boxShadow: 2, borderRadius: 4 }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "18px",
                                              fontWeight: "bold",
                                              color: "#23A8FA",
                                            }}
                                          >
                                            Application Number:
                                          </Typography>
                                          <Typography variant="h6">
                                            {formData.applicationNumber}
                                          </Typography>
                                        </Card>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        lg={4}
                                        textAlign="center"
                                      >
                                        <Card
                                          sx={{ boxShadow: 2, borderRadius: 4 }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "18px",
                                              fontWeight: "bold",
                                              color: "#23A8FA",
                                            }}
                                          >
                                            Quote No:
                                          </Typography>
                                          <Typography variant="h6">
                                            {formData.quoteNo}
                                          </Typography>
                                        </Card>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{
                        minHeight: "100%",
                        alignItems: "start",
                        justifyContent: "center",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Card
                            sx={{
                              boxShadow: 3,
                              position: "relative",
                              borderRadius: 4,
                            }}
                          >
                            <CardContent>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Typography variant="h6" color="#23A8FA">
                                    Access Status
                                  </Typography>
                                </Grid>
                                <Stack
                                  direction="column"
                                  rowGap={2}
                                  item
                                  xs={12}
                                >
                                  <Select fullWidth value={"active"}>
                                    <MenuItem value="active">Active</MenuItem>
                                  </Select>
                                  <Select fullWidth value={"Relationshipmgr"}>
                                    <MenuItem value="Relationshipmgr">
                                      Relationship Manager
                                    </MenuItem>
                                  </Select>
                                  <TextField
                                    value={"active"}
                                    fullWidth
                                    placeholder="status"
                                  />
                                </Stack>
                                <Grid item xs={12}>
                                  <Typography variant="h6" color="#23A8FA">
                                    Channel
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Select fullWidth value={"referrer"}>
                                    <MenuItem value="referrer">
                                      Referrer
                                    </MenuItem>
                                  </Select>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="h6" color="#23A8FA">
                                    Branch Name
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    value="Indore"
                                    aria-readonly
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="h6" color="#23A8FA">
                                    Profile Status
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Select fullWidth value={"approved"}>
                                    <MenuItem value="approved">
                                      Approved
                                    </MenuItem>
                                  </Select>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="h6" color="#23A8FA">
                                    Remarks
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Select fullWidth value={"approved"}>
                                    <MenuItem value="approved">
                                      Approved
                                    </MenuItem>
                                  </Select>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12}>
                          <Card
                            sx={{
                              boxShadow: 3,
                              position: "relative",
                              borderRadius: 4,
                            }}
                          >
                            <CardContent>
                              <Grid container rowSpacing={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="h4"
                                    textAlign="center"
                                    color="#23A8FA"
                                  >
                                    Modify Policy Details
                                  </Typography>
                                  <hr />
                                </Grid>
                                <TextField
                                  label="Application Number"
                                  fullWidth
                                  placeholder="status"
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  label="Policy Number"
                                  fullWidth
                                  placeholder="status"
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  label="Business Type"
                                  fullWidth
                                  placeholder="status"
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  label="PIVC"
                                  fullWidth
                                  placeholder="status"
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  label="Attribution"
                                  id="custom-css-outlined-input"
                                  fullWidth
                                  placeholder="status"
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  label="Name"
                                  fullWidth
                                  placeholder="status"
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  label="Select Dispose"
                                  fullWidth
                                  placeholder="status"
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  label="Remarks"
                                  fullWidth
                                  placeholder="status"
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  label="Endorsement"
                                  fullWidth
                                  placeholder="status"
                                  sx={{ mb: 2 }}
                                />
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            {customerLogs && (
              <Grid item xs={12}>
                <Card
                  sx={{
                    width: "100%",
                    borderRadius: 4,
                    boxShadow: 3,
                    position: "relative",
                  }}
                >
                  <CardContent>
                    <Typography variant="h3" color="#23A8FA" mb={2}>
                      Customer Logs
                    </Typography>
                    <Box sx={{ height: 400, width: "100%" }}>
                      <DataGrid
                        rows={customerLogs}
                        columns={customer_Column}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid item xs={12}>
              <Card
                sx={{
                  width: "100%",
                  borderRadius: 4,
                  boxShadow: 3,
                  position: "relative",
                }}
              >
                <CardContent>
                  <Typography variant="h3" color="#23A8FA" mb={2}>
                    Payment Logs
                  </Typography>
                  <Box sx={{ height: 400, width: "100%" }}>
                    <DataGrid
                      rows={paymentLogs}
                      columns={agent_Column}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      checkboxSelection
                      disableRowSelectionOnClick
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
      {loading && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress color="primary" size={50} />
        </Stack>
      )}
    </>
  );
};

export default Profile;
