import React from "react";
import { useSelector } from "react-redux";
import { fieldObject } from "../../api/service";
import { useTranslation } from "react-i18next";
import {  Grid, Typography, Paper } from "@mui/material";
import { Images } from "../../api/service.js";
function Sidebar() {
  const { t } = useTranslation();
  const travelContent = t("travel");
  let planName = useSelector((e) => e.travelReducer.selectedPlan.planName);
  let basePremium = useSelector(
    (e) => e.travelReducer.selectedPlan.plan.outputFields[0].premium
  );
  let totalPremium = useSelector(
    (e) => e.travelReducer.selectedPlan.plan.grandTotal.selectedValue
  );
  let data = useSelector((e) => e.travelReducer.selectedPlan.plan);
  let sumInsure = fieldObject(data, "field_2").selectedValue;
  return (
  <>
        <Paper elevation={5}    sx={{
          display: {xs:"flex",sm:"grid"},borderRadius:"1em",
          placeItems:"center",padding:"1em"
        }} >
             <Grid item xs={6} sm={12} padding={"1em"}>
        <img
              width={"100"}
              height={"100"}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Care_health_insurance_logo.png/220px-Care_health_insurance_logo.png"
              alt=""
            />
          </Grid>
          <Grid item xs={6} sm={12} padding={{sx:"1em"}} >
            <Typography component="p" color={"grey"} fontSize={"small"}>
              {travelContent.products_page.plan_name} :{" "}
              <Typography component="span" color={"black"} fontSize={"small"}>Explore {planName}</Typography>
            </Typography>
            <Typography component="p"  color={"grey"} fontSize={"small"}>
              {travelContent.products_page.premium} :{" "}
              <Typography component="span" color={"black"}fontSize={"small"} >₹ {basePremium}</Typography>
            </Typography>
            <Typography component="p"  color={"grey"} fontSize={"small"}>
              {travelContent.quotes.sum_insured} :{" "}
              <Typography component="span" color={"black"}fontSize={"small"}>
                {sumInsure < 100 ? sumInsure + "K" : sumInsure / 100 + "Lac"}
              </Typography>
            </Typography>
            <Typography component="p" fontSize={"small"} color={"grey"}>
              {travelContent.products_page.tt_pre} :{" "} 
              <Typography component="span" color={"black"} fontSize={"small"}>₹ {totalPremium}</Typography>
            </Typography>
          </Grid>
 
        </Paper>

        <Grid container display={{xs:"none",sm:"block"}} padding={"1em"}>
          <img src={Images.Travel_Form} alt="" sx={{height:"100%",width:"100%"}} />
        </Grid>
        </>
  );
}
export { Sidebar };
