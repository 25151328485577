import React, { useEffect, useState } from "react";
import "./Quotationcard.css";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ModalComponent from "./ModalComponent";
import { generatePremiumById } from "../api/PremiumGeneration";
import { Images, careSupremeBenefits, productDetails } from "../Data/Benifits";
import { Tooltip, LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updatedQuotationResult,
  updatePremiumPostObject,
  setSelectedProvider,
} from "../../Redux/Health/action";
import { useNavigate } from "react-router-dom";
import { generatePremium } from "../api/PremiumGeneration";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const CareAdvantageCard = ({
  sumInsured,
  tenure,
  pincode,
  provider,
  collectData,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productFeatures, setProductFeatures] = useState([]);
  const [premium, setPremium] = useState(null);
  const [image, setImage] = useState(null);
  const [ncbValue, setncbValue] = useState(0);
  const [globalcoverageValue, setGlobalcoverageValue] = useState(null);
  const [coverageValue, setcoverageValue] = useState(true);
  const [selectedAddonsCount, setSelectedAddonsCount] = useState(0);

  const [selectedPEDYear, setSelectedPEDYear] = useState(
    "WW Excl. US and Canada"
  );
  const [isPEDChecked, setIsPEDChecked] = useState(true);
  const [check, setCheck] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const premiumPostBody = useSelector(
    (state) => state?.healthReducer?.premiumPostObject
  );

  const handleChange = (e) => {
    setCheck(e.target.checked);
    collectData(e.target.checked, quote);
  };

  const [postBody, setPostBody] = useState(premiumPostBody);
  const fetchQuotationData = async () => {
    setLoading(true);
    try {
      let quote = await generatePremium({
        ...postBody,
        providers: ["care"],
        field_GC: selectedPEDYear,
      });
      if (premiumPostBody.selectedCoverage > 100) {
        setQuote(quote?.data?.data[0]);
      } else {
        setQuote(quote?.data?.data[1]);
      }

      let newPostBody = {
        ...premiumPostBody,
        abacusId: 6120,
        provider: "CARE",
      };

      const result = await generatePremiumById(newPostBody);

      let ncbValue = result.data.body.data.extraFields[0].selectedValue;
      console.log("Fetched NCB Value:", ncbValue); // Add this log

      setncbValue(ncbValue);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchQuotationData();
    console.log("Current NCB Value after Fetch:", ncbValue);
  }, [postBody]);

  // useEffect(() => {
  //   handleCheckBox(quote, check, image);
  // }, [check]);

  useEffect(() => {
    setPostBody(premiumPostBody);
  }, [premiumPostBody]);

  const handlePEDYearChange = (e) => {
    const selectedYear = e.target.value;
    setSelectedPEDYear(selectedYear);
    const updatedPostBody = {
      ...postBody,
      field_PED_TENURE: selectedYear,
      field_43: selectedYear ? "1" : "0",
    };
    setPostBody(updatedPostBody);
  };

  const getbenefitsForProduct = (productName) => {
    const benefitsData = productDetails?.find(
      (benefit) =>
        benefit?.product?.toLowerCase() === productName?.toLowerCase()
    );
    return benefitsData?.features;
  };

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  const findImage = (providerName) => {
    let imageObj = Images?.find((e) => e?.provider === providerName);
    return imageObj?.image;
  };

  useEffect(() => {
    let features = getbenefitsForProduct(quote?.productName);
    setProductFeatures(features);
    let image = findImage(quote?.provider);
    setImage(image);
    let premium = quote?.premiumWithGST;
    let premiumValue =
      typeof premium === "string"
        ? parseFloat(premium.replace(/,/g, ""))
        : premium;
    let formattedPremium = premiumValue
      ?.toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setPremium(formattedPremium);
  }, [quote]);

  const handleNavigate = (quote) => {
    let provider = quote.provider;
    let code = quote.code;
    const updatedPostBody = {
      ...postBody,
      provider: provider,
      abacusId: code,
    };
    navigate("/health/proposalform");
    dispatch(setSelectedProvider(provider));

    dispatch(updatedQuotationResult(quote));
    dispatch(updatePremiumPostObject(updatedPostBody));
  };
  const maxAddons = premiumPostBody.selectedCoverage > 100 ? 4 : 5;
  const handleCheckboxChange = (name, checked) => {
    const isChecked = checked;
    setPostBody((prevPostBody) => {
      const updatedPostBody = {
        ...prevPostBody,
        [name]: isChecked ? "1" : "0",
      };
      return updatedPostBody;
    });

    setSelectedAddonsCount((prevCount) => {
      return isChecked ? prevCount + 1 : prevCount - 1;
    });

    if (
      name === "field_NCB" &&
      premiumPostBody.highestElderAge >= 76 &&
      premiumPostBody.highestElderAge <= 99
    )
      return;
    if (name === "field_43") {
      setIsPEDChecked(checked);
    }
    const updatedPostBody = { ...postBody, [name]: checked ? "1" : "0" };
    setPostBody(updatedPostBody);
  };
  useEffect(() => {
    setSelectedAddonsCount(0);
  }, [sumInsured, tenure, pincode, provider]);

  // useEffect(()=>{

  //         setSelectedAddonsCount(0);
  // },[selectedAddonsCount])

  const handlePedTenureChange = (value) => {
    setLoading(true);
    localStorage.setItem("CareGlobalStorage", value); // Worldwide Excluding US and Canada;
    const coverageValueBool =
      value === "WW Excl. US and Canada" || value === "Worldwide"
        ? false
        : true;
    setcoverageValue(coverageValueBool);
    const globalCoverageValue =
      value === "WW Excl. US and Canada"
        ? "Worldwide Excluding US and Canada"
        : "Worldwide Including US and Canada";
    setGlobalcoverageValue(globalCoverageValue);
    setSelectedPEDYear(value);
    const updatedPostBody = { ...premiumPostBody, field_GC: value };
    setPostBody(updatedPostBody);
    // setOpen(coverageValueBool);

    // updatedPostBody.field_GC = value;
    // dispatch(updatePremiumPostObject(updatedPostBody));
  };

  return (
    <>
      <ModalComponent
        open={modalOpen}
        handleClose={handleModal}
        quote={quote}
        features={careSupremeBenefits}
      />
      {loading ? (
        <LinearProgress />
      ) : quote ? (
        <div className="health_quotation_main_card  mb_25 ">
          <div className="mt-2">
            <input
              className="form-check-input"
              onChange={(e) => handleChange(e)}
              type="checkbox"
              style={{
                fontSize: "25px",
                marginLeft: "10px",
                border: "1px solid",
              }}
            />
          </div>
          {/* <div className="mt-2">
            <input
              className="form-check-input"
              onChange={(e) => handleChange(e)}
              type="checkbox"
              style={{
                fontSize: "25px",
                marginLeft: "10px",
                border: "1px solid",
              }}
            />
          </div> */}
          <div className="d_flex quotation_upr_div">
            <div className="logo_container">
              <img src={image} alt="CARE HEALTH" className="logo_box" />
            </div>

            <div className="quotation_content">
              <div className="content_box_cs">
                <div className="plane_name plan_details">
                  <div className="title_pa">{quote?.productName}</div>
                  <div className="suminsured_text">
                    Sum Insured : ₹{quote?.sumInsured}
                  </div>
                  <b>{quote?.term || quote?.tenure} year policy</b>
                </div>

                <div className="plan_feature plan_details">
                  <div
                    className="health_check_compare"
                    style={{ marginRight: "30px" }}
                  >
                    <ul className="features_list">
                      {productFeatures?.slice(0, 4)?.map((item, i) => (
                        <li key={i} className="font-12">
                          <Tooltip title={item.benefit} placement="right-start">
                            <HealthAndSafetyIcon
                              fontSize="small"
                              style={{ marginRight: "5px", color: "#03a9f4" }}
                            />
                            <span className="font-blue">{item.feature}</span>
                          </Tooltip>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="health_check_compare">
                    <ul className="features_list">
                      {productFeatures?.slice(4, 8)?.map((item, i) => (
                        <li key={i} className="font-12">
                          <Tooltip title={item.benefit} placement="right-start">
                            <HealthAndSafetyIcon
                              fontSize="small"
                              style={{ marginRight: "5px", color: "#03a9f4" }}
                            />
                            <span className="font-blue">{item.feature}</span>
                          </Tooltip>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="text_right hal_buy_btn_btn plan_details">
                  <a onClick={() => handleNavigate(quote)} className="buy_btn">
                    <Tooltip title="Buy Plan" placement="bottom">
                      <span>
                        <i className="fa-solid fa-indian-rupee-sign mr_5"></i>₹{" "}
                        {premium}
                      </span>
                    </Tooltip>
                    <p>*Including GST</p>
                    <small>BUY NOW</small>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="d_flex quotation_bottom_div">
            <button onClick={handleModal} className="features_btn">
              View Features <ChevronRightIcon />
            </button>
          </div>

          <div className="add_addon_div">
            <div className="d_flex addons_flex_container">
              {ncbValue == "checked" ? (
                <div className="health_addons_input_box">
                  <label>
                    <input
                      type="checkbox"
                      name="field_NCB"
                      checked={true}
                      disabled
                    />
                    <span>NCB Super</span>
                  </label>
                </div>
              ) : null}
              {console.log("NCB Value on Render:", ncbValue)}
              {premiumPostBody.selectedCoverage > 100 ? (
                <>
                  <div className="health_addons_input_box">
                    <label>
                      <input
                        checked={true}
                        type="checkbox"
                        name="field_GC"
                        disabled
                      />
                      <span>Global Coverage</span>
                    </label>
                  </div>

                  <div className="health_addons_input_box">
                    <select
                      className="ped-year-dropdown"
                      value={selectedPEDYear}
                      onChange={(e) => handlePedTenureChange(e.target.value)}
                    >
                      {/* <option value="Select">Select</option> */}
                      <option value="WW Excl. US and Canada">
                        Worldwide Excluding US and Canada
                      </option>
                      <option value="Worldwide">
                        Worldwide Inccluding US and Canada
                      </option>
                    </select>
                  </div>
                </>
              ) : null}

              <div className="health_addons_input_box">
                <label>
                  <input
                    type="checkbox"
                    name="field_34"
                    checked={Number(postBody?.field_34)}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.name, e.target.checked)
                    }
                    disabled={
                      selectedAddonsCount >= maxAddons && !postBody?.field_34
                    }
                  />
                  <span>Room Rent Modification</span>
                </label>
              </div>

              <div className="health_addons_input_box">
                <label>
                  <input
                    checked={Number(postBody?.field_SS)}
                    type="checkbox"
                    name="field_SS"
                    onChange={(e) =>
                      handleCheckboxChange(e.target.name, e.target.checked)
                    }
                    disabled={
                      selectedAddonsCount >= maxAddons && !postBody?.field_SS
                    }
                  />
                  <span>Smart Select</span>
                </label>
              </div>

              <div className="health_addons_input_box">
                <label>
                  <input
                    checked={Number(postBody?.field_CS)}
                    type="checkbox"
                    name="field_CS"
                    onChange={(e) =>
                      handleCheckboxChange(e.target.name, e.target.checked)
                    }
                    disabled={
                      selectedAddonsCount >= maxAddons && !postBody?.field_CS
                    }
                  />
                  <span>Care Shield</span>
                </label>
              </div>

              <div className="health_addons_input_box">
                <label>
                  <input
                    checked={Number(postBody.field_35)}
                    type="checkbox"
                    name="field_35"
                    onChange={(e) =>
                      handleCheckboxChange(e.target.name, e.target.checked)
                    }
                    disabled={
                      selectedAddonsCount >= maxAddons && !postBody?.field_35
                    }
                  />
                  <span>Air Ambulance</span>
                </label>
              </div>

              <div className="health_addons_input_box">
                <label>
                  <input
                    checked={Number(postBody.field_43)}
                    type="checkbox"
                    name="field_43"
                    onChange={(e) =>
                      handleCheckboxChange(e.target.name, e.target.checked)
                    }
                    disabled={
                      selectedAddonsCount >= maxAddons && !postBody?.field_43
                    }
                  />
                  <span>Reduction in PED</span>
                </label>
              </div>

              <div className="health_addons_input_box">
                <label>
                  <input
                    checked={Number(postBody.field_AHC)}
                    type="checkbox"
                    name="field_AHC"
                    onChange={(e) =>
                      handleCheckboxChange(e.target.name, e.target.checked)
                    }
                    disabled={
                      selectedAddonsCount >= maxAddons && !postBody?.field_AHC
                    }
                  />
                  <span>Annual Health Check-up</span>
                </label>
              </div>

              <div className="health_addons_input_box">
                <label>
                  <input
                    checked={Number(postBody.field_OPD)}
                    type="checkbox"
                    name="field_OPD"
                    onChange={(e) =>
                      handleCheckboxChange(e.target.name, e.target.checked)
                    }
                    disabled={
                      selectedAddonsCount >= maxAddons && !postBody?.field_OPD
                    }
                  />
                  <span>Care OPD</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CareAdvantageCard;
