import React from 'react';
import cv1 from '../../Images/commercial-page/cv-01.svg';
import cv2 from '../../Images/commercial-page/cv-02.svg';
import QuotationDetails from '../../Components/Quotation/QuotationDetails';
import '../../../Framework/Pages/Main.css';
import { useNavigate } from 'react-router';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const CommercialVehicleQuotation = () => {
  const naviagte = useNavigate();
  
  const handlebackBtn = () => {
    naviagte('/motor/commercialdetails');
  };

  return (
    <div>
      <QuotationDetails />
    </div>
  );
};

export default CommercialVehicleQuotation;
