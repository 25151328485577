import React, { useEffect, useState } from "react";
import "./QuotationPage.css";
import CompareArrowsRoundedIcon from "@mui/icons-material/CompareArrowsRounded";
import AddToCompareModal from "../Components/AddToCompareModal";

import QuotationCard from "../../Health/Components/QuotationCard";
import CareAdvantageCard from "../../Health/Components/CareAdvantageCard";
import BajajQuardCard from "../../Health/Components/BajajQuardCard";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
  Badge,
  Button,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { generatePremium } from "../api/PremiumGeneration";
import { updatePremiumPostObject } from "../../Redux/Health/action";
import HdfcOptimaSecureCard from "../Components/HdfcOptimaSecureCard";

const QuotationPage = () => {
  const navigate = useNavigate();
  const premiumPostBody = useSelector(
    (state) => state?.healthReducer?.premiumPostObject
  );
  const dispatch = useDispatch();
  const [pincode, setPincode] = useState(premiumPostBody?.pincode || "");
  const [loading, setLoading] = useState(false);
  const [pincodeError, setPincodeError] = useState(false);
  const [open, setOpen] = useState(false);
  const [req, setReq] = useState([]);

  const [arr, setArr] = useState([]);
  const [provider, setProvider] = useState("Providers");
  const [sumInsured, setSumInsured] = useState(
    premiumPostBody?.selectedCoverage || ""
  );
  const [tenure, setTenure] = useState(premiumPostBody?.tenure || "");

  let tenureOptions = ["1 Year", "2 Year", "3 Year"];
  let individualSumInsured = [
    "5",
    "7",
    "10",
    "15",
    "25",
    "50",
    "100",
    "200",
    "300",
    "600",
  ];
  let providers = ["Providers", "CARE", "HDFC", "BAJAJ"];

  // let postBody = { ...premiumPostBody, providers: ["care"] }

  const handleSumInsuredChange = (event) => {
    setSumInsured(event.target.value);
    dispatch(
      updatePremiumPostObject({
        ...premiumPostBody,
        selectedCoverage: event.target.value,
      })
    );
  };
  const handleTenureChange = (event) => {
    setTenure(event.target.value);
    dispatch(
      updatePremiumPostObject({
        ...premiumPostBody,
        tenure: event.target.value,
      })
    );
  };

  const handlePincodeChange = (event) => {
    const value = event.target.value;
    if (value.length <= 6) {
      setPincode(value);
    }
    const isValidPincode = value.length === 6;
    setPincodeError(!isValidPincode);

    if (isValidPincode) {
      dispatch(
        updatePremiumPostObject({
          ...premiumPostBody,
          pincode: value,
        })
      );
    }
  };

  const handleProviderChange = (event) => {
    setProvider(event.target.value);
  };
  // console.log( useSelector((e) => e?.healthReducer?.quotationData),"quotationData in quotation page");

  const handleOpen = () => setOpen(true);
  const handleClose = (e, reason) => {
    // if (reason && reason == "backdropClick") {
    //   return;
    // } else {
    //   setOpen(false);
    //   // setSaodTPDetailsOpen(false);
    // }
    setOpen(false);
  };
  const collectData = (check, data) => {
    console.log(data, "data from card");
    console.log(JSON.stringify(data));
    if (check) {
      setArr([...arr, data]);
    } else {
      console.log(
        arr.filter((item) => item.productName !== data.productName),
        "data from else"
      );
      setArr(arr.filter((item) => item.productName !== data.productName));
    }
  };

  console.log(
    useSelector((state) => state.healthReducer.premiumPostObject),
    "premiumPostObject"
  );

  return (
    <>
      <Grid container spacing={2} style={{ margin: "10px 0" }}>
        {/* left side container */}
        <Grid item xs={11} md={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              justifyContent: "space-between",
            }}
            gap={2}
          >
            {/* back button */}
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <IconButton
                onClick={() => navigate("/health/Familyagefield")}
                aria-label="Go back"
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="overline">Go back</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
                alignItems: "center",
                margin: { xs: "10px auto", md: "0 10px" },
                width: { xs: "100%", md: "auto" },
              }}
            >
              <Badge badgeContent={arr.length} color="primary">
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  sx={{
                    width: { xs: "60%", sm: "75%", md: "160px" },
                    maxWidth: "250px",
                    height: "40px",
                    borderRadius: "8px",
                    boxShadow: 3,
                    "&:hover": {
                      backgroundColor: "#1976d2",
                      boxShadow: 6,
                    },
                    backgroundColor: "#2196f3",
                    color: "white",
                    textTransform: "none",
                    fontWeight: "bold",
                    padding: { xs: "8px 16px", md: "10px 20px" }, // Adjust padding for different screen sizes
                    fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" }, // Adjust font size
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  startIcon={<CompareArrowsRoundedIcon />}
                >
                  Compare
                </Button>
              </Badge>
              <AddToCompareModal
                open={open}
                handleClose={handleClose}
                arr={arr}
                req={req}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { sx: "row", md: "column" },
                gap: { xs: 2, md: 3 },
                flexWrap: "wrap",
              }}
            >
              {/* sum insured */}
              <TextField
                disabled={loading}
                label="Sum Insured"
                sx={{ minWidth: 120 }}
                select
                value={sumInsured}
                onChange={handleSumInsuredChange}
              >
                {individualSumInsured.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option} Lakhs
                  </MenuItem>
                ))}
              </TextField>

              {/* tenure */}
              <TextField
                disabled={loading}
                sx={{ minWidth: 120 }}
                label="Tenure"
                select
                value={tenure}
                onChange={handleTenureChange}
              >
                {tenureOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              {/* providers */}
              <TextField
                disabled={loading}
                sx={{ minWidth: 120 }}
                label="Providers"
                placeholder="Select provider"
                select
                value={provider}
                onChange={handleProviderChange}
              >
                {providers.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              {/* pincode */}
              <TextField
                disabled={loading}
                sx={{ minWidth: 120 }}
                label="Pincode"
                value={pincode}
                onChange={handlePincodeChange}
                error={pincodeError}
                helperText={pincodeError ? "Pincode must be 6 digits" : ""}
              />
            </Box>
          </Box>
        </Grid>

        {/* right side container */}
        <Grid item xs={12} md={9}>
          <Grid container>
            <Grid item xs={12}>
              <div className="quotation_main_container">
                {(provider === "CARE" || provider === "Providers") && (
                  <QuotationCard collectData={collectData} />
                )}
                {(provider === "CARE" || provider === "Providers") && (
                  <CareAdvantageCard
                    sumInsured={sumInsured}
                    tenure={tenure}
                    pincode={pincode}
                    provider={provider}
                    collectData={collectData}
                  />
                )}
                {(provider === "HDFC" || provider === "Providers") && (
                  <HdfcOptimaSecureCard collectData={collectData} />
                )}
                {(provider === "BAJAJ" || provider === "Providers") && (
                  <BajajQuardCard collectData={collectData} />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default QuotationPage;
