import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSelfInspecData, selfInspectionPut } from "../api/adminService";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import TransactionEdit from "./Sales/TransactionEdit";
import { IdBasedPermissions } from "./EmployeePagePermissions/IdBasedPermissions";
import { setError as setErrorMessage } from "../../../Redux/action-creators/errorAction";

const SelfInspectionPanel = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const empId = useSelector((state) => state?.adminReducer?.loginData?.empid);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();
  const empidLogin = useSelector((state) => state.adminReducer.loginData.empid);
  const dispatch = useDispatch();
  const columns = [
    {
      field: "order_id",
      headerName: "Policy ID",
      width: 100,
    },
    {
      field: "customer_id",
      headerName: "Customer ID",
      width: 120,
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      width: 220,
      renderCell: (params) => params.row.customer_name?.toUpperCase(),
    },
    {
      field: "channels",
      headerName: "Channel Type",
      width: 220,
      renderCell: (params) => params.row.channels?.toUpperCase(),
    },
    IdBasedPermissions.includes(empidLogin) && {
      field: "edit",
      headerName: "Edit",
      width: 80,
      renderCell: (params) => (
        <IconButton
          sx={{ color: "#23a8fa" }}
          onClick={() => handleEditClick(params.row)}
        >
          <EditIcon />
        </IconButton>
      ),
    },

    {
      field: "rc_number",
      headerName: "Vehicle Number",
      width: 100,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "vehicle_make",
      headerName: "Vehicle Make",
      width: 200,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "vehicle_model",
      headerName: "Vehicle Model",
      width: 200,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "vehicle_fuel_type",
      headerName: "Vehicle Fuel Type",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "commercial_vehicle_type",
      headerName: "Commercial Vehicle Type",
      width: 200,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "vehicle_chassis_number",
      headerName: "Vehicle Chassis Number",
      width: 200,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "vehicle_engine_number",
      headerName: "Vehicle Engine Number",
      width: 200,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "product_type",
      headerName: "Product Type",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "product_subtype",
      headerName: "Product Sub Type",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "policy_type",
      headerName: "Policy Type",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "policy_sub_type",
      headerName: "Policy Sub Type",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "provider",
      headerName: "Provider",
      width: 180,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "policy_number",
      headerName: "Policy Number",
      width: 320, 
    },    
    {
      field: "policy_startdate",
      headerName: "Start Date",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "policy_enddate",
      headerName: "End Date",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "premium_with_gst",
      headerName: "Gross Premium",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "premium_without_gst",
      headerName: "Net Premium",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "sum_insured",
      headerName: "Sum Insured",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "created_date",
      headerName: "Policy Created Date",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "policy_issue_date",
      headerName: "Policy Issue Date",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "reporting_employee_id",
      headerName: "Employee ID",
      width: 120,
    },
    {
      field: "reporting_employee_name",
      headerName: "Employee Name",
      width: 180,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "agent_id",
      headerName: "Agent ID",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "agent_name",
      headerName: "Agent Name",
      width: 180,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "telecaller_id",
      headerName: "Telecaller ID",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "telecaller_name",
      headerName: "Telecaller Name",
      width: 180,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "state",
      headerName: "Employee State",
      width: 120,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "district",
      headerName: "Employee Location",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "policy_status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "pdf_link",
      headerName: "PDF Download",
      width: 180,
      renderCell: (params) => (
        <a href={params.row.pdf_link} target="_blank" rel="noopener noreferrer">
          Download PDF
        </a>
      ),
    },
  ];

  const AddId = (data) => {
    return data.map((row, id) => ({ ...row, id }));
  };

  const getAllData = async () => {
    setLoading(true);
    try {
      const response = await getSelfInspecData(empId);
      setRows(AddId(response.data));
    } catch (error) {
      dispatch(setErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, [empId]);

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenDialog(true);
    Object.keys(row).forEach((key) => {
      setValue(key, row[key]);
    });
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedRow(null);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await selfInspectionPut({ ...data, empid: empId });
      setRows(AddId(response.data));
    } catch (error) {
      dispatch(setErrorMessage(error));
    } finally {
      setLoading(false);
      handleDialogClose();
    }
  };

  const onReset = () => {
    reset();
    getAllData();
  };

  return (
    <>
      {loading ? (
        <CircularProgress
          size={50}
          sx={{ position: "absolute", top: "50%", left: "50%" }}
        />
      ) : (
        <Grid
          container
          rowSpacing={2}
          mt={1}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* Main Form with Fromdate and Todate */}
          <Grid item xs={7}>
            <form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
              <Grid
                container
                display="flex"
                alignItems="center"
                columnSpacing={2}
              >
                <Grid item xs={4}>
                  <TextField
                    {...register("fromdate", {
                      required: "From Date is required",
                    })}
                    type="date"
                    label="From Date"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: new Date().toISOString().split("T")[0] }}
                    error={!!errors.fromdate}
                    helperText={errors.fromdate ? errors.fromdate.message : ""}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    {...register("todate", { required: "To Date is required" })}
                    type="date"
                    label="To Date"
                    error={!!errors.todate}
                    helperText={errors.todate ? errors.todate.message : ""}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                      min: watch("fromdate"),
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SendIcon />}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="reset"
                    variant="contained"
                    endIcon={<RestartAltIcon />}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          {/* Data Table */}
          <Grid item xs={12}>
            <DataGrid
              rows={rows}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
            />
          </Grid>

          {/* Edit Dialog */}
          <TransactionEdit
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            loading={loading}
            setLoading={setLoading}
            setRows={setRows}
            columns={columns}
          />
        </Grid>
      )}
    </>
  );
};

export default SelfInspectionPanel;
