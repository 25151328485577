import React from 'react'
import "./TextLoader.css"

export const TextLoader = () => {

  return (
    <div >
        <div className="sm-loader" ></div>
    </div>
  )
}
