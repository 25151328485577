import React from 'react';
import vectorobject from '../../Images/Car-Page/Vector-Smart-Object1-01-01.png';
import carnumberela from '../../Images/Car-Page/Car-and-U-Vector-Smart-Object-01.png';
import carreverse from '../../Images/Car-Page/car-and-u-reverse.png';
import ProposalDetails from '../../Components/Proposal/ProposalDetails';
import logo from '../../../Framework/Images/Logo.svg';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router';

const CarProposal = () => {
  const naviagte = useNavigate();
  const handlebackBtn = () => {
    naviagte('/motor/bike/quotation');
  };

  return (
    <div className="proposal-kyc-container">
      <div className="kychedding">Proposal Form</div>
      <div className="kyclogo">
        <div>
          <div className="infologo">
            <div className="back_icon">
              <KeyboardBackspaceIcon onClick={handlebackBtn} />
            </div>
            <h6>
              Step 3 (<span className="inprogress">In Progress</span>)
            </h6>
          </div>
          <div>
            <img src={carnumberela} alt="car and umberela" className="carnumberela" />
          </div>
        </div>
        <div>
          <img src={vectorobject} alt="vector object" className="vectorobject" />
        </div>
      </div>
      <ProposalDetails />
      <div className="bottomdiv">
        <div>
          <img src={carnumberela} alt="carnumberela2" className="carnumberela2" />
        </div>
        <div>***</div>
        <div>
          <img src={carreverse} alt="carnumberela3" className="carnumberela3" />
        </div>
      </div>
      <div>
        <img src={logo} alt="logo" className="logo_bottom" />
      </div>
    </div>
  );
};

export default CarProposal;