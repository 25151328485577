import React, { useState, useEffect } from 'react'
import '../../Pages/Car Pages/CarRegNumberFetching.css'
import bikeleftimage from "../../Images/Bike-Page/Vector-Smart-Object1-01.png";
import bikestart from "../../Images/Bike-Page/motorcycle-start.svg";
import bikemid from "../../Images/Bike-Page/motorcycle-middle.svg";
import bikeend from "../../Images/Bike-Page/motorcycle-end.svg";
import RegistrationNumberFetching from '../../Components/RegistrationNumberFetching/RegistrationNumberFetching'
import { GetDataLocalStorage } from "../../../Framework/GlobalObj";
import RoadBike from '../../Images/Bike-Page/bluebike.gif'
import { useNavigate } from 'react-router';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const BikeRegNumberFetching = () => {
  const [req, setreq] = useState({});
  const naviagte = useNavigate();

  useEffect(() => {
    let obj = GetDataLocalStorage("globalObj");
    setreq(obj)
  }, [])

  const handlebackBtn = () => {
    naviagte('/');
  };

  return (
    <div>

      <div className="registration-number-top-section">
      <div className="back_icon">
          <KeyboardBackspaceIcon onClick={handlebackBtn} />
        </div>
        <div className="bg-road-car">
        <img src={RoadBike} alt="" className="roadimg" />
        </div>
        <RegistrationNumberFetching req={req} setreq={setreq} />
      </div>
      <div className="mt-5 text-center justify-content-center container-fluid">
        <h1 className="fw-bold">Bike Insurance & Its Types</h1>
        <p  className="mt-3 fs-3">
        Bike insurance, also known as motorcycle insurance, is a type of insurance coverage specifically designed for motorcycles. It provides financial protection against losses or damages to your bike as well as liability coverage for injuries or property damage you may cause to others while riding your motorcycle
        </p>
        <div className="row mt-5">
          <div className="col-lg-4 col-md-12">
            <img src={bikestart} alt="" style={{ width: "156.32px" ,height: "91.04px" }} />
            <p style={{fontSize: "22px", fontWeight: "500"}}>Liability Coverage</p>
            <p>
            This is the most basic and mandatory form of car insurance in most places. It covers the costs associated with injuries or property damage you cause to others in an accident.
            </p>
          </div>
          <div className="col-lg-4 col-md-12">
            <img src={bikemid} alt="" style={{  width: "156.32px" ,height: "91.04px"}} />
            <p style={{fontSize: "22px", fontWeight: "500"}}>Collision Coverage</p>
            <p >
            This coverage pays for the repairs or replacement of your vehicle in the event of a collision, regardless of who is at fault.
            </p>
          </div>
          <div className="col-lg-4 col-md-12">
            <img
              src={bikeend}
              alt=""
              style={{ width: "156.32px" ,height: "91.04px"}}
            />
            <p style={{fontSize: "22px", fontWeight: "500"}}>Comprehensive Coverage</p>
            <p >
            This coverage protects against non-collision events such as theft, vandalism, fire, or damage caused by natural disasters.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BikeRegNumberFetching