import React from 'react';
import './CustomCheckbox.css'; 

const CustomCheckbox = ({ checked, onChange, disabled, label , width, height }) => {
  const containerClassName = `custom-checkbox-container ${disabled ? 'custom-checkbox-disabled' : ''}`;


  return (
    <label className={containerClassName}>
      <input
        type="checkbox"
        className="custom-checkbox-input"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <div className="custom-checkbox-checkmark"></div>
      {label && <span>{label}</span>}
    </label>
  );
};

export default CustomCheckbox;
