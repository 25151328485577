import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleData } from "../../../Redux/Action";
import { useNavigate } from "react-router";
import { AddToLocalStorage } from "../../../Framework/GlobalObj";
import { registrationNumberValidate } from "../../validation/ValidationSchema";
import { CircularProgress } from "@mui/material";
import shieldImg from '../../Images/Car-Page/bgshield.svg'
import '../RegistrationNumberFetching/RegistrationNumberFetching.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
 
 
const RegistrationNumberFetching = ({req,setreq}) => {
    const [vehicleNumber, setVehicleNumber] = useState("");
    const [isValidVehicleNumber, setIsValidVehicleNumber] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error,setError]=useState(false);
    const [errormsg,setErrorMsg]=useState("");
    const [errorButton,setErrorButton]=useState(false)
 
    const vehicleData = useSelector((store) => store.motorReducer.vehicleData);
     
     
    const dispatch = useDispatch();
    const navigate = useNavigate();
   
    useEffect(()=>{
      if (Object.keys(vehicleData).length > 0 && vehicleData?.status_code && vehicleData?.status_code!==200) {  
        setErrorMsg("Sorry! No Vehicle Is Found With This Registration Number");  
          setError(true);
          setErrorButton(false);
        }else if(Object.keys(vehicleData).length > 0 && errorButton && vehicleData?.regDate != null) {
          let obj = { ...req };
          obj.rc.number = vehicleNumber;
          obj.vehicle.isVehicleWithoutNumber = false;
          obj.vehicle.isVehicleNew = false;
      if (obj.vehicle.vehicleType === 'Bike') {
      if (vehicleData?.vehicleCategory === '2WN' || vehicleData?.vehicleCategory === 'MCYN') {
        obj.vehicle.isVehicleNew = false;
        AddToLocalStorage("globalObj", obj);
        navigate("/motor/bike/details");
      } else {
        setErrorMsg("Please Enter A Valid Two Wheeler Number");
        setError(true)
        setErrorButton(false);
      }
    } else if (obj?.vehicle?.vehicleType === 'Car') {
      if (vehicleData?.vehicleCategory === 'LMV' || vehicleData?.vehicleCategory === 'MCRN') {
        obj.vehicle.isVehicleNew = false;
        AddToLocalStorage("globalObj", obj);
        navigate("/motor/car/details");
      } else {
        setErrorMsg("Please Enter A Valid Four Wheeler Number");
        setError(true)
        setErrorButton(false);
      }
    } else if (obj?.vehicle?.vehicleType === 'Commercial') {
      console.log("in commercial1")
      if (vehicleData?.vehicleCategory !== 'LMV' && vehicleData?.vehicleCategory !== '2WN') {
        obj.vehicle.isVehicleNew = false;
        AddToLocalStorage("globalObj", obj);
        navigate("/motor/commercial/details");
      }else {
        setErrorMsg("Please Enter A Valid Commercial Vehicle Number");
        setError(true)
        setErrorButton(false);
      }
    }
        }
  },[vehicleData])
 
    // Valiation For Vehicle Number
    const handleVehicleNumberChange = (event) => {
        const inputVehicleNumber = event.target.value;
        setVehicleNumber(inputVehicleNumber.toUpperCase());
        setIsValidVehicleNumber(registrationNumberValidate(inputVehicleNumber));
        setError(false)
    };
 
   
    // Handle Submit Button
    const handleSubmit = (regNum) => {
        setIsSubmitted(true);
        if (isValidVehicleNumber=="" && vehicleNumber) {
            setErrorButton(true)
            dispatch(getVehicleData(vehicleNumber));
        }
    };
 
    const handleNewCarClick = () => {
        let obj2 = { ...req };
        obj2.vehicle.isVehicleNew = true;
        obj2.vehicle.isVehicleWithoutNumber = false;
        obj2.rc.number = "";
        if(obj2.vehicle.vehicleType=='Car'){
          AddToLocalStorage("globalObj", obj2);
        navigate("/motor/car/details");
        }else if(obj2.vehicle.vehicleType=='Bike'){        
            AddToLocalStorage("globalObj", obj2);
            navigate("/motor/bike/details");
        }else if(obj2.vehicle.vehicleType=='Commercial'){
            AddToLocalStorage("globalObj", obj2);
            navigate("/motor/commercial/details");
        }
        window.location.reload()
    };
 
    const handleNCarWithoutNumberClick = () => {
        let obj2 = { ...req };
        obj2.vehicle.isVehicleWithoutNumber = true;
        obj2.vehicle.isVehicleNew = false;
        obj2.rc.number = "";
        if(obj2.vehicle.vehicleType=='Car'){
          AddToLocalStorage("globalObj", obj2);
        navigate("/motor/car/details");
        }else if(obj2.vehicle.vehicleType=='Bike'){    
            AddToLocalStorage("globalObj", obj2);
            navigate("/motor/bike/details");
        }else if(obj2.vehicle.vehicleType=='Commercial'){
            AddToLocalStorage("globalObj", obj2);
            navigate("/motor/commercial/details");
        }
        window.location.reload()
    }
    return (
        <div className="registration-number-top-section-enter-registration-box">
        <img src={shieldImg} alt="" className="shield-img" />
        <div className="enter-registration-inner-box">
            <div className="enter-registration-inner-box-center">
                <input type="text" placeholder="Eg: MH12MK0000" onChange={handleVehicleNumberChange} value={vehicleNumber} />
                {isSubmitted && error ? (
                  <div className="text-danger validate-warning">{errormsg}</div>
                ) : isSubmitted ? (<div className="text-danger validate-warning">{registrationNumberValidate(vehicleNumber)}</div>):""
                }
                {errorButton ? <div style={{margin:"15px",display:"flex",justifyContent:"center",alignItems:"center"}}><CircularProgress /></div> :
                <div className="registration-number-top-button" onClick={handleSubmit}>
                    <div className="registration-number-top-button-innericons">
                        <img src="" alt="" />
                        <ArrowForwardIcon  style={{ color: "#23A8FA" }}/>
                    </div>
                        <button type="submit" onClick={handleSubmit}>Fetch Your {req?.vehicle?.vehicleType} Details</button>
                   
                </div>
}
                <div className="new-vehical-registration-box">
                    <span onClick={handleNewCarClick} >New {req?.vehicle?.vehicleType} ? </span>
                    <span onClick={handleNCarWithoutNumberClick}>Continue Without a {req?.vehicle?.vehicleType} Number</span>
                </div>
 
            </div>
        </div>
 
</div>
    )
}
 
export default RegistrationNumberFetching