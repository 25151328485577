import React from "react";
import { useState } from "react";
import { AgeDropdown } from "./AgeDropdown";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { handleForm } from "../../../Redux/Travel/action.js";
import { ageOptions } from "../../api/service.js";
import NextButton from "../../../Framework/Components/NextButton";
import { styles } from "./TravellerDetail.js";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
export const TravellerDetails = ({ setTripDetails }) => {
  const { t } = useTranslation();
  const travelContent = t("travel");
  let dispatch = useDispatch();
  let {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let [travellerCount, setTravellerCount] = useState(0);
  // RENDER MULTIPLE INPUT FIELDS
  function RenderTravellerAgeInputFields() {
    let input = [];
    for (let i = 1; i <= travellerCount; i++) {
      input.push(
        <AgeDropdown
          key={i}
          count={i}
          Controller={Controller}
          control={control}
          errors={errors}
        />
      );
    }
    return input;
  }

  // HANDLE FORM SUBMITTED
  function handleFormSubmit(data) {
    setTripDetails(true);
    dispatch(handleForm({ ...data, travellerCount: travellerCount + 1 }));
  }

  return (
    <form autoComplete="on" onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container sx={styles.gridContainer}>
        <Box sx={styles.box}>
          <Button
            onClick={() => {
              setTravellerCount(0);
            }}
            sx={
              travellerCount === 0 ? styles.buttonActive : styles.buttonInactive
            }
          >
            1
          </Button>
          <Button
            onClick={() => {
              setTravellerCount(1);
            }}
            sx={
              travellerCount === 1 ? styles.buttonActive : styles.buttonInactive
            }
          >
            2
          </Button>

          <Button
            onClick={() => {
              setTravellerCount(2);
            }}
            sx={
              travellerCount === 2 ? styles.buttonActive : styles.buttonInactive
            }
          >
            3
          </Button>

          <Button
            onClick={() => {
              travellerCount < 5 && setTravellerCount(travellerCount + 1);
            }}
            sx={
              travellerCount >= 3 ? styles.buttonActive : styles.buttonInactive
            }
          >
            +
          </Button>
        </Box>
        <Grid container sx={styles.centerContent}>
          <Grid item xs={12} sm={travellerCount === 0 ? 12 : 6}>
            {
              <Controller
                name="proposerAge"
                control={control}
                rules={{
                  required: travelContent.travelerDetails.select_error,
                  validate: (value) =>
                    value !== "" || travelContent.travelerDetails.select_error,
                }}
                render={({ field }) => (
                  <Box sx={styles.inputField}>
                    <FormControl fullWidth error={!!errors["proposerAge"]}>
                      <InputLabel id="demo-simple-select-label">
                        {travelContent.travelerDetails.select_label} 1
                      </InputLabel>
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={travelContent.travelerDetails.select_label}
                        value={field.value || ""}
                        onChange={field.onChange}
                      >
                        <MenuItem value="" disabled>
                          {travelContent.travelerDetails.select_option}
                        </MenuItem>
                        {Object.entries(ageOptions()).map(([value, label]) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error>
                        {errors["proposerAge"]?.message}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                )}
              />
            }
          </Grid>
          {RenderTravellerAgeInputFields()}
        </Grid>
      </Grid>

      <Typography sx={styles.centeredText}>
        {" "}
        {travelContent.travelerDetails.select_traveller} :{" "}
        <span>{travellerCount + 1}</span>
      </Typography>
      <Box sx={styles.spacedButtons}>
        <NextButton submit />
      </Box>
    </form>
  );
};
