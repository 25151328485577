import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Tabs,
  Tab,
  IconButton,
  Dialog,
  DialogTitle,
  Slider,
  MenuItem,
  TextField,
  Button,
  Stack,
  FormControl,
  Autocomplete,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { agentActivepanel, getUsers } from "../api/adminService";
import { Link, Route, Routes } from "react-router-dom";
import axios from "axios";
import Profile from "./Profile/profile";
import EditIcon from "@mui/icons-material/Edit";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { IdBasedPermissions } from "./EmployeePagePermissions/IdBasedPermissions";
import { setSuccessMessage } from "../../../Redux/action-creators/successAction";
import { setError as setErrorMessage } from "../../../Redux/action-creators/errorAction";

// Helper function to add ID to each row
const AddId = (data) => {
  return data.map((row, id) => ({
    ...row,
    id,
  }));
};

const AgentActivationPanel = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [agentLoading, setAgentLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [agentRows, setAgentRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [employees, setEmployees] = useState([]);
  const Role = useSelector((state) => state.adminReducer.loginData.Role);
  const pagePermissions = useSelector(
    (state) => state.adminReducer.loginData.pages
  );
  const permissions = pagePermissions.filter(
    (page) => page.pageName === "Agent Activation"
  )[0].permissions;

  const empidLogin = useSelector((state) => state.adminReducer.loginData.empid);
  const selector = useSelector((state) => state.adminReducer.loginData.Role);
  const dispatch = useDispatch();
  const columns = [
    {
      field: "agent_id",
      headerName: "Agent ID",
      width: 150,
      renderCell: (params) => (
        <Link to={`profile/agent/${params.value}`}>{params.value}</Link>
      ),
    },
    { field: "agent_name", headerName: "Agent Name", width: 200 },
    { field: "phone", headerName: "Agent Phone", width: 150 },
    { field: "email", headerName: "Agent Email", width: 250 },
    { field: "address", headerName: "Agent Address", width: 250 },
    { field: "state", headerName: "Agent State", width: 150 },
    { field: "city", headerName: "Agent City", width: 150 },
    { field: "district", headerName: "Agent District", width: 150 },
    { field: "landmark", headerName: "AgentLandmark", width: 180 },
    { field: "pincode", headerName: "Agent Pincode", width: 120 },
    {
      field: "created_date",
      headerName: "Agent Created Date",
      width: 180,
      renderCell: (params) => {
        return new Date(params.value).toLocaleDateString("en-GB");
      },
    },
    {
      field: "gender",
      headerName: "Agent Gender",
      width: 120,
      renderCell: (params) => {
        return params.value === "M" ? "MALE" : "FEMALE";
      },
    },
    {
      field: "dob",
      headerName: "Agent Date of Birth",
      width: 180,
      renderCell: (params) => {
        return new Date(params.value).toLocaleDateString("en-GB");
      },
    },
    { field: "reference", headerName: "Reference", width: 150 },
    { field: "qualification", headerName: "Agent Qualification", width: 180 },
    { field: "agent_plan", headerName: "Agent Plan", width: 150 },
    { field: "percentage", headerName: "Percentage", width: 100 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "reporting_employee_id",
      headerName: "Reporting Employee ID",
      width: 150,
      renderCell: (params) => (
        <Link to={`profile/employee/${params.value}`}>{params.value}</Link>
      ),
    },
    {
      field: "reporting_employee_name",
      headerName: "Reporting Employee Name",
      width: 200,
    },
    {
      field: "manager_employee_id",
      headerName: "Manager Employee ID",
      width: 150,
    },
    {
      field: "account_name",
      headerName: "Account Name",
      width: 180,
    },
    { field: "bank_name", headerName: "Bank Name", width: 180 },
    {
      field: "account_number",
      headerName: "Account Number",
      width: 180,
    },
    { field: "ifsc_code", headerName: "IFSC Code", width: 180 },
    {
      field: "account_type",
      headerName: "Account Type",
      width: 150,
    },
    { field: "pan_card", headerName: "PAN Card", width: 180 },
    { field: "aadhar_card", headerName: "Aadhar Card", width: 180 },
    {
      field: "branch_name",
      headerName: "Bank Branch Name",
      width: 180,
    },
  ];

  const agentColumns = [
    {
      field: "agent_id",
      headerName: "Agent ID",
      width: 150,
      renderCell: (params) => (
        <Link to={`profile/agent/${params.value}`}>{params.value}</Link>
      ),
    },
    { field: "agent_name", headerName: "Agent Name", width: 200 },
    { field: "phone", headerName: "Phone", width: 150 },
    ...(permissions.edit
      ? [
          {
            field: "edit",
            headerName: "Edit",
            renderCell: (params) => (
              <IconButton
                variant="contained"
                sx={{
                  backgroundColor: "#fff",
                  color: "#23a8fa",
                  "&:hover": { backgroundColor: "#23a8fa", color: "#fff" },
                }}
                onClick={() => handleAgentPlans(params)}
              >
                <EditIcon />
              </IconButton>
            ),
            flex: 1,
            minWidth: 70,
          },
        ]
      : []),
    { field: "employee_id", headerName: "Employee ID", width: 180 },
    { field: "agent_plan", headerName: "Agent Plan", width: 150 },
    { field: "percentage", headerName: "Percentage", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "city", headerName: "City", width: 150 },
    { field: "pincode", headerName: "Pincode", width: 120 },
  ];

  // Fetch Agent Data
  const getAgentData = async () => {
    setLoading(true);
    const res = await agentActivepanel(empidLogin);
    setLoading(false);
    setRows(res.data);
  };

  const getEmployees = async () => {
    try {
      const res = await getUsers(selector);
      setEmployees(res.data.data);
    } catch (error) {
      dispatch(setErrorMessage(error));
    }
  };

  useEffect(() => {
    getAgentData();
    getEmployees();
  }, []);

  useEffect(() => {
    const fetchAgentApprovals = async () => {
      try {
        setAgentLoading(true);

        const res = await axios.get(
          "https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/agentapprovals"
        );
        setAgentRows(AddId(res.data));
      } catch (error) {
        dispatch(setErrorMessage(error));
      } finally {
        setAgentLoading(false);
      }
    };

    if (selectedTab === 1) {
      fetchAgentApprovals();
    }
  }, [selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // New Tab Content
  const NewTabContent = () => (
    <Box>
      {agentLoading ? (
        <CircularProgress
          size={50}
          style={{ position: "absolute", top: "50%", left: "50%" }}
        />
      ) : (
        <DataGrid
          sx={{
            "& .MuiDataGrid-scrollbar": {
              ariaHidden: "false",
            },
            "& .MuiDataGrid-root": {
              position: "relative",
            },
          }}
          rows={agentRows}
          columns={agentColumns}
          slots={{ toolbar: GridToolbar }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      )}
    </Box>
  );

  const handleAgentPlans = (params) => {
    setSelectedAgent(params.row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAgent(null);
  };

  const EditAgent = ({ open, handleClose, selectedAgent }) => {
    const selectedEmployee = employees.find(
      (e) => e.empid === Number(selectedAgent.employee_id)
    );
    const {
      control,
      handleSubmit,
      watch,
      formState: { errors },
      setValue,
    } = useForm({
      defaultValues: {
        employee_id: selectedEmployee || null,
        agent_plan: selectedAgent?.agent_plan || "",
      },
    });
    const md = watch("agent_plan");

    const onSubmit = async (data) => {
      const payload = {
        ...data,
        agent_id: selectedAgent?.agent_id,
        employee_id: data?.employee_id?.empid,
      };
      setOpen(false);
      try {
        setAgentLoading(true);
        const res = await axios.put(
          `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/agentapprovals`,
          payload
        );
        if (res.data.length > 0) {
          setAgentRows(AddId(res.data));
        }
      } catch (error) {
        dispatch(setErrorMessage(error));
      } finally {
        setAgentLoading(false);
      }
    };

    useEffect(() => {
      const sliderValue = getSliderValue(md);
      setValue("slider_value", sliderValue);
    }, [md, setValue]);

    const getSliderValue = (plan) => {
      switch (plan) {
        case "Silver":
          return 50;
        case "Gold":
          return 60;
        case "Diamond":
          return 70;
        case "Platinum":
          return 80;
        case "MD Desk Approvals":
          return 90;
        default:
          return 50;
      }
    };

    if (!selectedAgent) return null;

    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle textAlign="center" variant="h3">
          Edit Agent Plans
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ padding: "2em" }}>
            <Stack direction="row" spacing={2}>
              <Typography>Agent ID: {selectedAgent?.agent_id}</Typography>
              <Typography>Agent Name: {selectedAgent?.agent_name}</Typography>
            </Stack>

            {/* Employee Autocomplete */}
            <FormControl
              fullWidth
              margin="normal"
              error={Boolean(errors.employee_id)}
            >
              <Controller
                name="employee_id"
                control={control}
                rules={{ required: "An employee must be selected" }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    options={employees}
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                      `${option.emp_name} (${option.empid}) - ${option.role}`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.empid === value?.empid
                    }
                    onChange={(event, newValue) => onChange(newValue)}
                    onBlur={onBlur}
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employee List"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
              {errors.employee_id && (
                <FormHelperText>{errors.employee_id.message}</FormHelperText>
              )}
            </FormControl>

            {/* Agent Plan Dropdown */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: "1em",
                marginTop: "1em",
              }}
            >
              <Controller
                name="agent_plan"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Agent Plan"
                    fullWidth
                    margin="normal"
                  >
                    <MenuItem value="Silver">Silver</MenuItem>
                    <MenuItem value="Gold">Gold</MenuItem>
                    <MenuItem value="Diamond">Diamond</MenuItem>
                    <MenuItem value="Platinum">Platinum</MenuItem>
                    {Role !== "Admin" && (
                      <MenuItem value="MD Desk Approvals">
                        MD Desk Approvals
                      </MenuItem>
                    )}
                  </TextField>
                )}
              />

              {/* Slider */}
              {md === "MD Desk Approvals" && (
                <Controller
                  name="slider_value"
                  control={control}
                  render={({ field }) => (
                    <Slider
                      {...field}
                      value={field.value}
                      aria-label="Default"
                      valueLabelDisplay="on"
                      valueLabelFormat={(value) => `${value}%`}
                      min={0}
                      max={100}
                    />
                  )}
                />
              )}
            </Box>

            <Controller
              name="status"
              defaultValue="Active"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Status"
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </TextField>
              )}
            />

            {/* Submit Button */}
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={agentLoading}
              >
                Save Changes
              </Button>
            </Stack>
          </Box>
        </form>
      </Dialog>
    );
  };

  return (
    <Box>
      <Typography variant="h3">Agent Activation</Typography>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label="Agent Activation Panel" />
        {IdBasedPermissions.includes(empidLogin) && (
          <Tab label="Agent Approval Panel" />
        )}
      </Tabs>
      {selectedTab === 0 && (
        <Box>
          {loading ? (
            <CircularProgress
              size={50}
              style={{ position: "absolute", top: "50%", left: "50%" }}
            />
          ) : (
            <Box sx={{ width: "100%" }}>
              <DataGrid
                sx={{
                  "& .MuiDataGrid-scrollbar": {
                    ariaHidden: "false",
                  },
                  "& .MuiDataGrid-root": {
                    position: "relative",
                  },
                }}
                rows={rows}
                columns={columns}
                getRowId={(row) => row.agent_id}
                {...([70000, 71092, 70001, 71035,1001].includes(empidLogin) && { slots: { toolbar: GridToolbar } })}
              />
            </Box>
          )}
        </Box>
      )}
      {selectedTab === 1 && <NewTabContent />}
      {open && (
        <EditAgent
          open={open}
          handleClose={handleClose}
          selectedAgent={selectedAgent}
        />
      )}
    </Box>
  );
};

const AgentActivationPanelWithRoutes = () => (
  <Routes>
    <Route path="/" element={<AgentActivationPanel />} />
    <Route path="/profile/agent/:id" element={<Profile />} />
  </Routes>
);

export default AgentActivationPanelWithRoutes;
