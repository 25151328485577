import {
  PERSONAL_DETAILS,
  ADDRESS_DETAILS,
  LOAN_DETAILS,
  NOMINEE_DETAILS,
  PROPOSAL_NUMBER
} from "./Action";
import { PROPOSAL_RESPONSE } from "./Action";


const initialState = {
  RequestBody: {
    personalDetails: {
      firstName: "",
      lastName: "",
      title: "MR",
      gender: "MALE",
      relation: "SELF",
      dob: ""
    },
    addressDetails: {
      permanentAddress: {
        addressLine1: "",
        addressLine2: "",
        email: "",
        phno: "",
        state: "",
        city: "",
        pincode: ""
      },
      communicationAddress: {
        addressLine1: "",
        addressLine2: "",
        email: "",
        phno: "",
        state: "",
        city: "",
        pincode: ""
      }
    },
    loanDetails: {
      loanAccountNumber: "",
      loanAmount: "",
      loanTenure: "",
      loanDisbursalDate: ""
    },
    nomineeDetails: {
      nomineeName: "",
      nomineeAge: "",
      nomineeRelation: "",
      documentType: "",
      documentNumber: ""
    }
  },
  StatusResponse: {},
  ProposalData: {},
};

const loanReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PERSONAL_DETAILS:
      return { ...state, RequestBody: { ...state.RequestBody, personalDetails: { ...payload } } };
    case ADDRESS_DETAILS:
      return { ...state, RequestBody: { ...state.RequestBody, addressDetails: { ...payload } } };
    case LOAN_DETAILS:
      return { ...state, RequestBody: { ...state.RequestBody, loanDetails: { ...payload } } };
    case NOMINEE_DETAILS:
      return { ...state, RequestBody: { ...state.RequestBody, nomineeDetails: { ...payload } } };
      case PROPOSAL_NUMBER:
        return {
          ...state,
          proposalNumber:payload

        }
    case PROPOSAL_RESPONSE:
      return {
        ...state,
        ProposalData: payload,
      };
    default:
      return state;
  }
};

export {loanReducer};
