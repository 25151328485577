import React, { useEffect, useState } from "react";
import "../Components/FamilyAgeFields.css";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AlertError } from "../../Framework/Toaster/AlertError";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import { updatePremiumPostObject } from "../../Redux/Health/action";
import { sendOtp } from "../../Framework/Add&SendOTP";
import Otpmodal from "../../Framework/Components/OtpModal";
import DarkToolTip from "../../Framework/Components/DarkToolTip";
import {fetchPincode} from "../api/Pincode"

const FamilyAgeFields = () => {
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [medicalCondition, setMedicalCondition] = useState("No");
  const [otpSentSuccess, setOtpSentSuccess] = useState(false);
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const familyData = useSelector((state) => state?.healthReducer?.familyData);

  const { t } = useTranslation();
  let ids = {
    org_id: "100",
    agent_id: "1000",
  };

  let tenure = ["1 Year", "2 Year", "3 Year"];
  let individualSumInsured = [
    "5",
    "7",
    "10",
    "15",
    "25",
    "50",
    "100",
    "200",
    "300",
    "600",
  ];

  const handleModal = () => {
    setShowOTPModal((prev) => !prev);
  };

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useFormPersist("healthuser data", {
    watch,
    setValue,
    storage: window.localStorage,
  });

  useEffect(() => {
    localStorage.setItem("any_previous_medical_condition", medicalCondition);
  }, []);

  const renderChildAgeFields = (count, type) => {
    let ageInputs = [];
    const ageOptions = [
      "91 days",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
    ];
    for (let i = 0; i < count; i++) {
      const fieldName = `${type}Age${i + 1}`;
      console.log(fieldName, "fieldName");
      ageInputs.push(
        <Grid key={i} item xs={6} sm={4}>
          <div key={i} className="inputGroup">
            <label key={`${type}${i + 1}`}>
              {errors[fieldName] ? (
                <p className="text-danger">{errors[fieldName].message}</p>
              ) : (
                `${type} ${i + 1}`
              )}
            </label>
            <div>
              <select
                {...register(fieldName, {
                  required: "This field is required",
                })}
              >
                <option value="" selected>
                  Select Age
                </option>
                {ageOptions.map((age, index) => (
                  <option key={index} value={age}>
                    {age}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Grid>
      );
    }
    return ageInputs;
  };

  const handleMedicalConditionChange = (value) => {
    localStorage.setItem("any_previous_medical_condition", value);
    setMedicalCondition(value);
  };

  const onSubmit = handleSubmit(async (data) => {
    const familyMembers = [
      "self",
      "spouse",
      "son",
      "daughter",
      "father",
      "mother",
      "fatherInLaw",
      "motherInLaw",
    ];
    const selectedCount = familyMembers.filter(
      (member) => familyData[member] === true
    ).length;

    let policyType = "Individual";
    if (selectedCount > 1) {
      policyType = "Floater";
    }
    let agesArray = [];
    Object.keys(data).forEach((key) => {
      if (key.toLowerCase().includes("age") && key != "selectedCoverage") {
        if (data[key] === "91 days") {
          agesArray.push("0");
        } else {
          agesArray.push(data[key]);
        }
      }
    });

    let maxAge = Math.max(...agesArray);
    agesArray = agesArray.filter((age) => age !== maxAge);

    let noOfChildren = 0;
    if (familyData.son) {
      noOfChildren += familyData.sonCount;
    }
    if (familyData.daughter) {
      noOfChildren += familyData.daughterCount;
    }
    let noOfPeople = 0;
    if (familyData.self) {
      noOfPeople++;
    }
    if (familyData.spouse) {
      noOfPeople++;
    }
    if (familyData.father) {
      noOfPeople++;
    }
    if (familyData.mother) {
      noOfPeople++;
    }
    if (familyData.fatherInLaw) {
      noOfPeople++;
    }
    if (familyData.motherInLaw) {
      noOfPeople++;
    }
    if (familyData.son) {
      noOfPeople += familyData.sonCount;
    }
    if (familyData.daughter) {
      noOfPeople += familyData.daughterCount;
    }

    let noOfParents = 0;
    if (familyData.father) {
      noOfParents++;
    }
    if (familyData.mother) {
      noOfParents++;
    }
    if (familyData.fatherInLaw) {
      noOfParents++;
    }
    if (familyData.motherInLaw) {
      noOfParents++;
    }
   function getCityAndStateByPincode(pincode, pinCodeData) {
    const matchingEntries = pinCodeData.filter(entry => entry["Pin Code"].toString() === pincode);
    console.log("Matching Entries:", matchingEntries);  
    if (matchingEntries.length > 0) {
        const { City, State } = matchingEntries[0];
        return { city: City, state: State };
    } else {
        return { city: null, state: null };
    }
}

    const postData = {
      familyAges: agesArray,
      firstname: data?.firstname,
      highestElderAge: maxAge?.toString(),
      lastname: data?.lastname,
      noOfChildren: noOfChildren?.toString(),
      noOfPeople: noOfPeople?.toString(),
      noOfParents: noOfParents?.toString(),
      number: data?.number,
      pincode: data?.pincode,
      policyType: policyType,
      selectedCoverage: data?.selectedCoverage,
      tenure: data?.tenure,
      any_previous_medical_condition: medicalCondition,
    };
    const pinCodeData = await fetchPincode();
    const location = getCityAndStateByPincode(postData.pincode, pinCodeData);

postData.city = location.city;
postData.state = location.state;

    setPhone(data?.number);

    dispatch(updatePremiumPostObject(postData));
    let addCustomerData = {
      customer_name: data.firstname,
      pincode: data.pincode,
      org_id: ids.org_id,
      agent_id: ids.agent_id,
      phone: data.number,
      method: "SEND",
      type: "C",
      state:location.state,
      city:location.city

    };

    let otpData = {
      phone: data.number,
      org_id: ids.org_id,
      agent_id: ids.agent_id,
      method: "SEND",
      type: "C",
    };

    console.log(otpData, addCustomerData, "7989051850");
    // navigate("/health/quotations");
    handleModal();
    try {
      let otpResponse = await sendOtp(addCustomerData, otpData);
      console.log("otpresponse in family age fields", otpResponse);
      setOtpSentSuccess(true);
    } catch (error) {
      console.log(error);
    }
  });
  return (
    <>
      <Container>
        <Grid container spacing={1}>
          {/* left side container */}
          <Grid item sm={6} display={{ xs: "none", md: "block" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              borderRadius="8px"
              padding="20px"
            >
              <img
                src="/Health/Images/banner2.svg"
                alt="Banner"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </Grid>

          {/* right side container */}
          <Grid item xs={12} sm={12} md={6}>
            <form onSubmit={onSubmit} className="user_details_form">
              <Box borderRadius="8px" padding="20px">
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    color: "#0d398f ",
                    marginBottom: "10px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Life is uncertain, but your health doesn't have to be.{" "}
                </Typography>

                <Grid container spacing={2} justifyContent="start">
                  <Grid item xs={12} sm={6}>
                    <div className="inputGroup">
                      <input
                        placeholder="Enter Your first name"
                        {...register("firstname", {
                          required: "First Name is required",
                          // pattern: {
                          //   value: /^[a-zA-Z]*$/,
                          //   message: "Only alphabets are allowed",
                          // },
                        })}
                      />
                      <label>
                        {errors.firstname ? (
                          <p className="text-danger">
                            {errors.firstname.message}
                          </p>
                        ) : (
                          "First Name"
                        )}
                      </label>
                      <div className="input_info_icon">
                        <DarkToolTip
                          title={
                            "Ensure your name is same as PAN name. Your policy can be rejected if this is incorrect"
                          }
                        >
                          <InfoIcon fontSize="small" color="action" />
                        </DarkToolTip>
                      </div>
                    </div>
                  </Grid>
                  {familyData.self && (
                    <Grid item xs={12} sm={4}>
                      <div className="inputGroup">
                        <input
                          placeholder="Enter age"
                          type="number"
                          {...register("selfage", {
                            required: "Age is required",
                            validate: (val) => {
                              if (val < 18) {
                                return "Age > 18";
                              } else if (val > 99) {
                                return "Age < 99";
                              }
                            },
                          })}
                        />
                        <label>
                          {errors.selfage ? (
                            <p className="text-danger">
                              {errors.selfage.message}
                            </p>
                          ) : (
                            "Self Age"
                          )}
                        </label>
                      </div>
                    </Grid>
                  )}

                  {/* spouse age */}
                  {familyData.spouse && (
                    <Grid item xs={12} sm={4}>
                      <div className="inputGroup">
                        <input
                          placeholder="Enter age"
                          type="number"
                          {...register("spouseage", {
                            required: "Age is required",
                            validate: (val) => {
                              if (val < 18) {
                                return "Age > 18";
                              } else if (val > 99) {
                                return "Age < 99";
                              }
                            },
                          })}
                        />
                        <label>
                          {errors.spouseage ? (
                            <p className="text-danger">
                              {errors.spouseage.message}
                            </p>
                          ) : (
                            "Spouse Age"
                          )}
                        </label>
                      </div>
                    </Grid>
                  )}

                  {/* son age */}
                  {familyData.son &&
                    familyData.sonCount > 0 &&
                    renderChildAgeFields(familyData.sonCount, "Son", "son")}

                  {/* daughter age */}
                  {familyData.daughter &&
                    familyData.daughterCount > 0 &&
                    renderChildAgeFields(
                      familyData.daughterCount,
                      "Daughter",
                      "daughter"
                    )}

                  {/* mother age */}
                  {familyData.mother && (
                    <Grid item xs={12} sm={4}>
                      <div className="inputGroup">
                        <input
                          placeholder="Enter age"
                          type="number"
                          {...register("motherage", {
                            required: "Age is required",
                            validate: (val) => {
                              if (val < 18) {
                                return "Age > 18";
                              } else if (val > 99) {
                                return "Age < 99";
                              }
                            },
                          })}
                        />
                        <label>
                          {errors.motherage ? (
                            <p className="text-danger">
                              {errors.motherage.message}
                            </p>
                          ) : (
                            "Mother age"
                          )}
                        </label>
                      </div>
                    </Grid>
                  )}

                  {/* father age */}
                  {familyData.father && (
                    <Grid item xs={12} sm={4}>
                      <div className="inputGroup">
                        <input
                          placeholder="Enter age"
                          type="number"
                          {...register("fatherage", {
                            required: "Age is required",
                            validate: (val) => {
                              if (val < 18) {
                                return "Age > 18";
                              } else if (val > 99) {
                                return "Age < 99";
                              }
                            },
                          })}
                        />
                        <label>
                          {errors.fatherage ? (
                            <p className="text-danger">
                              {errors.fatherage.message}
                            </p>
                          ) : (
                            "Father Age"
                          )}
                        </label>
                      </div>
                    </Grid>
                  )}

                  {/* mother-in-law age */}
                  {familyData.motherInLaw && (
                    <Grid item xs={12} sm={4}>
                      <div className="inputGroup">
                        <input
                          placeholder="Enter age"
                          type="number"
                          {...register("motherinlawage", {
                            required: "Age is required",
                            validate: (val) => {
                              if (val < 18) {
                                return "Age > 18";
                              } else if (val > 99) {
                                return "Age < 99";
                              }
                            },
                          })}
                        />
                        <label>
                          {errors.motherinlawage ? (
                            <p className="text-danger">
                              {errors.motherinlawage.message}
                            </p>
                          ) : (
                            "Mother-In-Law Age"
                          )}
                        </label>
                      </div>
                    </Grid>
                  )}

                  {/* father-in-law age */}
                  {familyData.fatherInLaw && (
                    <Grid item xs={12} sm={4}>
                      <div className="inputGroup">
                        <input
                          placeholder="Enter age"
                          type="number"
                          {...register("fatherinlawage", {
                            required: "Age is required",
                            validate: (val) => {
                              if (val < 18) {
                                return "Age > 18";
                              } else if (val > 99) {
                                return "Age < 99";
                              }
                            },
                          })}
                        />
                        <label>
                          {errors.fatherinlawage ? (
                            <p className="text-danger">
                              {errors.fatherinlawage.message}
                            </p>
                          ) : (
                            " Father-In-Law Age"
                          )}
                        </label>
                      </div>
                    </Grid>
                  )}

                  {/*phone number */}
                  <Grid item xs={12} sm={6}>
                    <div className="inputGroup">
                      <input
                        placeholder="Enter your phone Number"
                        type="number"
                        {...register("number", {
                          required: "Phone no. is required",
                          pattern: {
                            value: /^[1-9]\d{9}$/,
                            message: "Invalid phone number",
                          },
                        })}
                      />
                      <label>
                        {errors.number ? (
                          <p className="text-danger">{errors.number.message}</p>
                        ) : (
                          "Mobile"
                        )}
                      </label>
                    </div>
                  </Grid>

                  {/* pincode */}
                  <Grid item xs={12} sm={6}>
                    <div className="inputGroup">
                      <input
                        placeholder="Enter your pincode"
                        type="number"
                        {...register("pincode", {
                          required: "Pincode is required",
                          pattern: {
                            value: /^\d{6}$/,
                            message: "Invalid pincode",
                          },
                        })}
                      />
                      <label>
                        {errors.pincode ? (
                          <p className="text-danger">
                            {errors.pincode.message}
                          </p>
                        ) : (
                          "Pincode"
                        )}
                      </label>
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={1}
                  justifyContent="start"
                  marginTop={1}
                >
                  {/* tenure */}
                  <Grid item xs={12} sm={6}>
                    <div className="inputGroup">
                      <label>
                        {errors.tenure ? (
                          <p className="text-danger">{errors.tenure.message}</p>
                        ) : (
                          "Tenure"
                        )}
                      </label>
                      <select
                        {...register("tenure", {
                          required: "This field is required",
                        })}
                      >
                        <option value="" selected>
                          Select Tenure
                        </option>
                        {tenure.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>

                  {/* sum Insured */}
                  <Grid item xs={12} sm={6}>
                    <div className="inputGroup">
                      <label>
                        {errors.selectedCoverage ? (
                          <p className="text-danger">
                            {errors.selectedCoverage.message}
                          </p>
                        ) : (
                          "Sum Insured"
                        )}
                      </label>
                      <select
                        {...register("selectedCoverage", {
                          required: "This field is required",
                        })}
                      >
                        <option value="" selected>
                          Select Coverage Amount
                        </option>
                        {individualSumInsured.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>

                  {/* medical condition */}
                  <Grid item xs={12} md={6}>
                    <div className="medical_condition_inputs">
                      <span className="radio_label">
                        Any Previous Medical Conditions?
                      </span>
                      <div className="radio-inputs">
                        <label className="radio">
                          <input
                            type="radio"
                            id="medicalConditionsYes"
                            name="medicalConditions"
                            value="yes"
                            checked={medicalCondition === "Yes"}
                            onChange={() => handleMedicalConditionChange("Yes")}
                          />
                          <span className="name">Yes</span>
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            id="medicalConditionsNo"
                            name="medicalConditions"
                            value="no"
                            checked={medicalCondition === "No"}
                            onChange={() => handleMedicalConditionChange("No")}
                          />
                          <span className="name">No</span>
                        </label>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    justifyContent="space-between"
                    display="flex"
                  >
                    <button
                      className="btnNextHealth health_btn back_btn"
                      type="button"
                      id="btnNext1"
                      onClick={() => navigate("/health")}
                    >
                      Back
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            fill="currentColor"
                            d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                          ></path>
                        </svg>
                      </div>
                    </button>
                    <button className="btnNextHealth health_btn" type="submit">
                      Next
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            fill="currentColor"
                            d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                          ></path>
                        </svg>
                      </div>
                    </button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>
      <Otpmodal
        open={showOTPModal}
        handleClose={handleModal}
        number={phone}
        typed={"health"}
      />
      <AlertError
        open={otpSentSuccess}
        setOpen={setOtpSentSuccess}
        message="OTP sent successfully"
        severity="success"
      ></AlertError>
    </>
  );
};
export default FamilyAgeFields;
