import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
 
const Helpline = () => {
  return (
    <Box sx={{ bgcolor: "#eaf2fd", minHeight: "100vh", padding: "20px" }}>
      <Grid
        container
        sx={{
          display: "flex",
 
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Main Content */}
        <Grid item xs={12} md={10} sx={{ padding: "20px" }}>
          <Paper sx={{ padding: "20px", borderRadius: "8px" }}>
            <Typography
              variant="h3"
              sx={{
                bgcolor: "#785ef0",
                color: "#fff",
                padding: "10px",
                borderRadius: "8px",
              }}
            >
              Service requests
            </Typography>
 
            {/* No service request message */}
            <Box
              sx={{
                mt: 4,
                textAlign: "center",
                padding: "40px",
                border: "2px dashed #d9d9d9",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Box component="span" sx={{ fontSize: "35px", mr: 2 }}>
                  🎟️
                </Box>
                No service request yet!
              </Typography>
              <Typography variant="body1">
                Please raise a service request, if you have any concerns with
                your policy.
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid container    sx={{
          display: "flex",
 
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Grid item xs={12} md={10} sx={{ padding: "20px" }}>
          <Paper sx={{ padding: "20px", borderRadius: "8px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" gutterBottom>
                Helpline
              </Typography>
              <Typography variant="h5" gutterBottom>
                180-180-3333
              </Typography>
              <Typography variant="h5" gutterBottom>
                <span className="text-success"> (Toll-Free)</span>
              </Typography>
            </Box>
 
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" gutterBottom>
                <a href="" className="text-primary">
                  lmvinsurancebrokingservices@email.com
                </a>
              </Typography>
            </Box>
 
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h6">Address:</Typography>
              <Typography variant="h6">
                6-3-1089/F, RVR Towers, Level 1, Raj Bhawan Road, Somajiguda
                Hyderabad TG 300082 IN
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
 
export default Helpline;