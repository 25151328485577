import {LOGIN_SUCCESS, LOGOUT} from "../action-type/actionType";

export const setToken = (data) => {
    return {
        type: LOGIN_SUCCESS,
        payload: data
    };
};

export const logout = () => {
    return {
        type: LOGOUT,
    };
};