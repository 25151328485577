import { Button, Grid, TextField, MenuItem, Stack, CircularProgress, InputAdornment } from "@mui/material";
import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
// import { useFormContext } from 'react-hook-form';

const Address = (props) => {
    const { register, handleSubmit, trigger, formState: { errors, isSubmitted }, setValue , getValues} = useForm()
    const [address, setAddress] = useState({
        district: '',
        state: '',
        country: '',
        city : []
    })

    
    const [isLoaderTrue , setIsLoaderTrue] = useState(false)

    useEffect(() => {
        
        // it is usefull when user click back button
        const {addressData} = props
        
        for (let name in addressData) {
            setValue(name, props.addressData[name])
        }

        setAddress((prv) => ({ ...prv, district: addressData.district, state: addressData.state, country: addressData.country}))
        
        if (addressData.pincode?.length !== 0 && addressData.pincode !== undefined){
            const callPincodeApi = async () => {
                setIsLoaderTrue(true)
                const data = await searchPinCode(addressData.pincode)
                console.log(data)
                setAddress((prv) => ({ ...prv,city: data.Data }))
                setIsLoaderTrue(false)
            }
            callPincodeApi()
        }

       
        
    }, [])


    const submitHandler = (e) => {
        const { clickNextBtn } = props
        clickNextBtn({...address , ...e})
        
        
    }


    const searchPinCode = async (pincode) => {
        const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`)
        const data = await response.json()
        if (data[0].Status === 'Success') {
            const array = []
            for (let value of data[0].PostOffice) {
                array.push(value.Name)
            }
            const updatedData = { District: data[0].PostOffice[0].District, Country: data[0].PostOffice[0].Country, State: data[0].PostOffice[0].State, Data: [...array] }
            return updatedData
        } else {
            return []
        }
    }

    const changeHandler = async(e) =>{

        const {name , value} =e.target
        if (name === "pincode"){
            setValue(name, value.slice(0, 6))
            if(value.length === 6){
                setIsLoaderTrue(true)
                const data = await searchPinCode(value)
                if (data.length !== 0){
                    setAddress((prv) => ({ ...prv, district: data.District, state: data.State, country: data.Country, city: data.Data}))
                }else{
                    alert('Invalid Pin Code')
                    setValue('city' , '')
                    
                    setAddress((prv) => ({ ...prv, district: '', state: '', country: '', city: [] }))
                }
                setIsLoaderTrue(false)
            }
        }else{
            setValue(name, value.toUpperCase())
        }

        { isSubmitted && await trigger(name) }

    }

    return (
        <form onSubmit={handleSubmit(submitHandler)} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={4}>

                <Grid item xs={12} md={4}>
                    <TextField fullWidth label="Address Line" color="primary" name="address_line" autoComplete="off"
                        {...register('address_line', {
                            required: 'Please Enter Address',
                            pattern: {
                                value: /^([a-zA-z0-9/\\''(),-\s]{2,255})$/,
                                message: 'Invalid Address'
                            }
                        })}
                        onChange={changeHandler}
                        error={!!errors.address_line}
                        helperText={errors.address_line ? errors.address_line.message : ''}
                    />
                </Grid>


                <Grid item xs={12} md={4}>
                    <TextField fullWidth label="Land Mark" color="primary" name="land_mark" autoComplete="off"
                        {...register('land_mark', {
                            required: 'Please Enter Landmark',
                            pattern: {
                                value: /^([a-zA-z0-9/\\''(),-\s]{2,255})$/,

                                message: 'Invalid Landmark'
                            }
                        })}
                        onChange={changeHandler}
                        error={!!errors.land_mark}
                        helperText={errors.land_mark ? errors.land_mark.message : ''}
                    />
                </Grid>


                <Grid item xs={12} md={4}>
                    <TextField fullWidth type="number" label="Pin Code" color="primary" name="pincode"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isLoaderTrue && <CircularProgress size={24} />}
                                </InputAdornment>
                            )
                        }}

                        sx={{
                            '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                WebkitAppearance: 'none',
                                margin: 0,
                            },
                        }}
                        {...register('pincode', {
                            required: 'Please Enter Pin code',
                            pattern: {
                                value: /^[1-9][0-9]{5}$/,
                                message: 'Invalid Pin Code'
                            }
                        })}
                        onChange={changeHandler}
                        error={!!errors.pincode}
                        helperText={errors.pincode ? errors.pincode.message : ''}
                    />
                </Grid>


                <Grid item xs={12} md={4}>
                    <TextField fullWidth label="Select City" select color="primary" autoComplete="off" defaultValue={props.addressData.city || ''}
                        {...register('city', {
                            required: 'Please Select city'
                        })}
                        onChange={changeHandler}
                        error={!!errors.city}
                        helperText={errors.city ? errors.city.message : ''}
                    >
                        
                        {address?.city?.map((eachValue , index) =>(
                            <MenuItem value={eachValue.toUpperCase()} key={index}>{eachValue}</MenuItem>
                        ))}
                        
                    </TextField>
                </Grid>


                <Grid item xs={12} md={4}>
                    <TextField fullWidth label="District" color="primary" disabled
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={address.district}
                     />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField fullWidth label="State" color="primary" disabled
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={address.state}
                     />
                </Grid>


                <Grid item xs={12} md={4}>
                    <TextField fullWidth label="Country" color="primary" disabled 
                        InputLabelProps={{
                            shrink: true,
                        }} 
                        value={address.country}
                    />
                </Grid>


            </Grid>
            <Stack direction={"row"} justifyContent={'end'} marginTop={3}>
                <Button variant="contained" color="primary" sx={{ marginRight: '20px' }} onClick={() => props.clickBackBtn({ ...address, ...getValues() })}>Back</Button>
                <Button variant="contained" color="primary"type="submit" >Next</Button>
            </Stack>
            
        </form>
    )
}

export default Address