import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  alpha,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
  TextField,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { CancelRounded } from "@mui/icons-material";
import { blue } from "@mui/material/colors";

const AddBox = ({ data, open, setOpen }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [motorType, setMotorType] = useState("");

  if (!data || !Array.isArray(data) || data.length === 0) {
    return null;
  }

  const record = data[0];
  if (!record || typeof record !== "object") {
    return null;
  }

  console.log(record);
  const motortype = record.motorType;

  const onSubmit = (formData) => {
    console.log("Submitted Data: ", formData);
    setOpen(false);
  };

  return (
    <Dialog
      TransitionComponent={Slide}
      keepMounted
      open={open}
      fullWidth
      aria-labelledby="dialog-title"
    >
      <DialogTitle>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Typography align="center" variant="h2">
            Add Record
          </Typography>
          <IconButton onClick={() => setOpen((prev) => !prev)}>
            <CancelRounded />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{ backgroundColor: alpha(blue[500], 0.1) }}>
            <CardContent>
              <Grid container columnSpacing={3} mt={3} rowGap={3}>
                {Object.keys(record)
                  .filter(
                    (key) =>
                      key !== "company_name" &&
                      key !== "business_type" &&
                      key !== "bike_comp_1_1" &&
                      key !== "bike_comp_1_2" &&
                      key !== "bike_comp_1_3" &&
                      key !== "bike_saod" &&
                      key !== "bikeaotp_0_1" &&
                      key !== "bikeaotp_0_2" &&
                      key !== "bikeaotp_0_3" &&
                      key !== "scootercomp_1_1" &&
                      key !== "scootercomp_1_2" &&
                      key !== "scootercomp_1_3" &&
                      key !== "scootercompsaod" &&
                      key !== "scooteraotp_0_1" &&
                      key !== "scooteraotp_0_2" &&
                      key !== "scooteraotp_0_3"
                  )
                  .map((key) => (
                    <Grid key={key} item xs={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label={key.replace(/_/g, " ").toUpperCase()}
                        {...register(key, { required: true })}
                        error={!!errors[key]}
                        helperText={errors[key] ? "This field is required" : ""}
                        defaultValue={record[key]}
                      />
                    </Grid>
                  ))}

                {motortype === "Bike" && (
                  <Grid item xs={4}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!errors["2w_type"]}
                    >
                      <InputLabel id="2w-type-label">2W Type</InputLabel>
                      <Controller
                        name="2w_type"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="2w-type-label"
                            onChange={(e) => {
                              field.onChange(e);
                              setMotorType(e.target.value);
                            }}
                            value={motorType}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="Bike">Bike</MenuItem>
                            <MenuItem value="Scooter">Scooter</MenuItem>
                          </Select>
                        )}
                      />
                      {errors["2w_type"] && (
                        <Typography color="error" variant="caption">
                          This field is required
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                )}

                {/* Month and Year Fields */}
                {/* <Grid item xs={4}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Month"
                    {...register("month", { required: true })}
                    error={!!errors.month}
                    helperText={errors.month ? "This field is required" : ""}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Year"
                    {...register("year", { required: true })}
                    error={!!errors.year}
                    helperText={errors.year ? "This field is required" : ""}
                  />
                </Grid> */}

                {motorType === "Bike" && (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Bike SAOD"
                        {...register("bike_saod", { required: true })}
                        error={!!errors.bike_saod}
                        helperText={
                          errors.bike_saod ? "This field is required" : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Bike AOTP"
                        {...register("bike_aotp", { required: true })}
                        error={!!errors.bike_aotp}
                        helperText={
                          errors.bike_aotp ? "This field is required" : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Bike Comp"
                        {...register("bike_comp", { required: true })}
                        error={!!errors.bike_comp}
                        helperText={
                          errors.bike_comp ? "This field is required" : ""
                        }
                      />
                    </Grid>
                  </>
                )}

                {motorType === "Scooter" && (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Scooter SAOD"
                        {...register("scooter_saod", { required: true })}
                        error={!!errors.scooter_saod}
                        helperText={
                          errors.scooter_saod ? "This field is required" : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Scooter AOTP"
                        {...register("scooter_aotp", { required: true })}
                        error={!!errors.scooter_aotp}
                        helperText={
                          errors.scooter_aotp ? "This field is required" : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Scooter Comp"
                        {...register("scooter_comp", { required: true })}
                        error={!!errors.scooter_comp}
                        helperText={
                          errors.scooter_comp ? "This field is required" : ""
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
          <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
            <Button type="submit" variant="contained" color="primary">
              Submit Details
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddBox;
