export const mobileValidate = (mobile) => {
  if (mobile) {
    if (/^[6-9]\d{9}$/.test(mobile)) {
      return '';
    } else {
      return 'Please Enter Valid Mobile Number';
    }
  } else {
    return 'Please Enter Mobile Number';
  }
};

export const emailValidate = (email) => {
  if (email) {
    if (/\S+@\S+\.\S+/.test(email)) {
      return '';
    } else {
      return 'Please Enter Valid Email Address';
    }
  } else {
    return 'Please Enter Email Address';
  }
};

export const panCardValidate = (pan) => {
  if (pan) {
    if (/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/.test(pan)) {
      return '';
    } else {
      return 'Please Enter Valid Pancard Number';
    }
  } else {
    return 'Please Enter Pan Number';
  }
};

export const aadhaarValidate = (aadhar) => {
  if (aadhar) {
    if (/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/.test(aadhar)) {
      return '';
    } else {
      return 'Please Enter Valid Aadhar Number';
    }
  } else {
    return 'Please Enter Aadhar Number';
  }
};

export const otpValidation = (otp) => {
  if (otp) {
    if (otp.join('') === '9999') {
      return '';
    } else {
      return 'Sorry! Invalid OTP';
    }
  } else {
    return 'Please Enter OTP To Proceed';
  }
};

export const registrationNumberValidate = (reg) => {
  if (reg) {
    if (/^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$/.test(reg)) {
      return '';
    } else {
      return 'Please Enter the Valid Registration Number';
    }
  } else {
    return 'Please Enter the Registration Number';
  }
};

export const drivingLicenseValidate = (license) => {
  if (license) {
    if (/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/.test(license)) {
      //if (/^([A-Z]{2})(\d{2}|\d{3})[a-zA-Z]{0,1}(\d{4})(\d{7})$/.test(license)) {
      return '';
    } else {
      return 'Please Enter Valid Driving License Number';
    }
  } else {
    return 'Please Enter Driving License Number';
  }
};

export const FullNameValidate = (name) => {
  if (name) {
    if (/(^[A-Za-z]{3,16})([ ]{0,1})([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{3,16})?([ ]{0,1})?([A-Za-z]{3,16})/.test(name)) {
      return '';
    } else {
      return 'Please Enter Valid First & Last Name';
    }
  } else {
    return 'Please Enter Your Full Name';
  }
};

export const dateOfBirthValidateNomminee = (dob) => {
  if (dob) {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (Number(age) >= 18) {
      return '';
    } else {
      return 'Age Should Be Minimum 18 Years';
    }
  } else {
    return 'Please Enter Date Of Birth';
  }
};

export const proposalObjErrorCheck = (obj) => {
  let error = {};
  error.email = emailValidate(obj.email);
  if (obj.doctype == 'AADHAR_CARD') {
    error.aadhar = aadhaarValidate(obj.aadhar);
  } else if (obj.doctype == 'PAN_CARD') {
    error.pan = panCardValidate(obj.pan);
  } else {
    error.driving = drivingLicenseValidate(obj.driving);
  }
  // if (obj.nomineedob) {
  error.nommineedob = dateOfBirthValidateNomminee(obj.nomineedob);
  //}
  error.dob = dateOfBirthValidateNomminee(obj.dob);
  return error;
};
