import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { setError as setErrorMessage } from "../../../../Redux/action-creators/errorAction";
import { useDispatch } from "react-redux";

const plans = [
  { plan_name: "Silver", Percentage: 50 },
  { plan_name: "Gold", Percentage: 60 },
  { plan_name: "Diamond", Percentage: 70 },
  { plan_name: "Platinum", Percentage: 80 },
  { plan_name: "Employedesk_approvals", Percentage: 90 },
];

export default function PospRevunue() {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [columns, setColumns] = React.useState([]);
  const [selectedProductType, setSelectedProductType] = React.useState("");
  const dispatch = useDispatch();

  const handlePlanChange = async (id, newPlan, agent_id, policy_id) => {
    const reqObj = {
      plan_name: newPlan,
      agentId: agent_id,
      policyId: policy_id,
    };

    setLoading(true);
    setError(null);

    try {
      const res = await axios.put(
        `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/posprevenue`,
        reqObj
      );
      if (res.status === 200) {
        const flattenedData = res.data;
        setRows(mapDataWithId(flattenedData));
        setColumns(defaultColumns);

        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === id ? { ...row, agent_plan: newPlan } : row
          )
        );
      } else {
        dispatch(setErrorMessage('Failed to Fetch Data'));
      }
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
      setError(error.message);
    } finally {
      setSelectedProductType("");
      setLoading(false);
    }
  };

  const defaultColumns = [
    { field: "agent_id", headerName: "Agent ID", width: 150 },
    { field: "employee_id", headerName: "Employee ID", width: 150 },
    { field: "policy_id", headerName: "Policy ID", width: 150 },
    { field: "policy_num", headerName: "Policy Number", width: 200 },
    { field: "product_type", headerName: "Product Type", width: 150 },
    { field: "product_subtype", headerName: "Product Subtype", width: 150 },
    { field: "motor_type", headerName: "Motor Type", width: 150 },
    { field: "revenue", headerName: "Revenue", type: "number", width: 200 },
    {
      field: "agent_revenue",
      headerName: "Agent Revenue",
      type: "number",
      width: 200,
    },
    {
      field: "agent_plan",
      headerName: "Agent Plan",
      width: 150,
      renderCell: (params) => (
        <FormControl fullWidth>
          <Select
            labelId={`select-label-${params.id}`}
            value={params.row.agent_plan || ""}
            onChange={(e) =>
              handlePlanChange(
                params.id,
                e.target.value,
                params.row.agent_id,
                params.row.policy_id
              )
            }
          >
            <MenuItem value="" disabled>
              Select Plan
            </MenuItem>
            {plans.map((plan) => (
              <MenuItem key={plan.plan_name} value={plan.plan_name}>
                {plan.plan_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
    {
      field: "percentage",
      headerName: "Percentage",
      type: "number",
      width: 150,
    },
    { field: "created_date", headerName: "Created Date", width: 200 },
    {
      field: "sum_insured",
      headerName: "Sum Insured",
      type: "number",
      width: 150,
    },
    {
      field: "od_premium",
      headerName: "OD Premium",
      type: "number",
      width: 150,
    },
    {
      field: "tp_premium",
      headerName: "TP Premium",
      type: "number",
      width: 150,
    },
    {
      field: "premium_without_gst",
      headerName: "Premium (Without GST)",
      type: "number",
      width: 200,
    },
    {
      field: "premium_with_gst",
      headerName: "Premium (With GST)",
      type: "number",
      width: 200,
    },
  ];

  const motorColumns = [
    { field: "policy_id", headerName: "Policy ID", width: 150 },
    { field: "policy_num", headerName: "Policy Number", width: 200 },
    { field: "product_type", headerName: "Product Type", width: 150 },
    { field: "product_subtype", headerName: "Product Subtype", width: 150 },
    { field: "agent_id", headerName: "Agent ID", width: 150 },
    {
      field: "agent_plan",
      headerName: "Agent Plan",
      width: 150,
      renderCell: (params) => (
        <FormControl fullWidth>
          <Select
            labelId={`select-label-${params.id}`}
            value={params.row.agent_plan || ""}
            onChange={(e) =>
              handlePlanChange(
                params.id,
                e.target.value,
                params.row.agent_id,
                params.row.policy_id
              )
            }
          >
            <MenuItem value="" disabled>
              Select Plan
            </MenuItem>
            {plans.map((plan) => (
              <MenuItem key={plan.plan_name} value={plan.plan_name}>
                {plan.plan_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
    {
      field: "od_premium",
      headerName: "OD Premium",
      type: "number",
      width: 150,
    },
    {
      field: "tp_premium",
      headerName: "TP Premium",
      type: "number",
      width: 150,
    },
    {
      field: "premium_with_gst",
      headerName: "Premium with GST",
      type: "number",
      width: 150,
    },
    {
      field: "premium_without_gst",
      headerName: "Premium without GST",
      type: "number",
      width: 150,
    },
  ];

  const healthColumns = [
    { field: "policy_id", headerName: "Policy ID", width: 150 },
    { field: "policy_num", headerName: "Policy Number", width: 200 },

    { field: "product_subtype", headerName: "Product Subtype", width: 150 },
    { field: "agent_id", headerName: "Agent ID", width: 150 },
    {
      field: "agent_plan",
      headerName: "Agent Plan",
      width: 150,
      renderCell: (params) => (
        <FormControl fullWidth>
          <Select
            labelId={`select-label-${params.id}`}
            value={params.row.agent_plan || ""}
            onChange={(e) =>
              handlePlanChange(
                params.id,
                e.target.value,
                params.row.agent_id,
                params.row.policy_id
              )
            }
          >
            <MenuItem value="" disabled>
              Select Plan
            </MenuItem>
            {plans.map((plan) => (
              <MenuItem key={plan.plan_name} value={plan.plan_name}>
                {plan.plan_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
    {
      field: "agent_revenue",
      headerName: "Agent Revenue",
      type: "number",
      width: 200,
    },
    {
      field: "premium_without_gst",
      headerName: "Premium (Without GST)",
      type: "number",
      width: 200,
    },
    {
      field: "premium_with_gst",
      headerName: "Premium (With GST)",
      type: "number",
      width: 200,
    },
  ];

  const othersColumns = [
    { field: "policy_id", headerName: "Policy ID", width: 150 },
    { field: "policy_num", headerName: "Policy Number", width: 200 },
    { field: "product_type", headerName: "Product Type", width: 150 },
    {
      field: "premium_without_gst",
      headerName: "Premium (Without GST)",
      type: "number",
      width: 200,
    },
  ];

  const getAllPolicyReports = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await axios.get(
        `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/posprevenue`
      );
      if (res.status === 200) {
        const flattenedData = res.data;
        setRows(mapDataWithId(flattenedData));
        setColumns(defaultColumns);
      } else {
        dispatch(setErrorMessage('Failed to Fetch Data'));
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
      setError(error.message);
    } finally {
      setSelectedProductType("");
      setLoading(false);
    }
  };

  const mapDataWithId = (data) => data.map((row, id) => ({ ...row, id }));

  const handleProductTypeChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedProductType(selectedValue);

    if (selectedValue) {
      await handleSubmit(selectedValue);
    } else {
      setRows([]);
      setColumns(defaultColumns);
    }
  };

  const handleSubmit = async (productType) => {
    setLoading(true);
    setError(null);
    try {
      let res;
      let updatedColumns;
      let updatedRows;

      switch (productType) {
        case "motor":
          res = await axios.post(
            `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/posprevenue`,
            { product_type: "motor" }
          );
          updatedColumns = motorColumns;
          updatedRows = mapDataWithId(res.data); 
          break;
        case "health":
          res = await axios.post(
            `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/posprevenue`,
            { product_type: "health" }
          );
          updatedColumns = healthColumns;
          updatedRows = mapDataWithId(res.data); 
          break;
        case "others":
          res = await axios.post(
            `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/posprevenue`,
            { product_type: "others" }
          );
          updatedColumns = othersColumns;
          updatedRows = mapDataWithId(res.data); 
          break;
        default:
          getAllPolicyReports();
      }

      if (res.status === 200) {
        setRows(updatedRows);
        setColumns(updatedColumns); 
      } else {
        dispatch(setErrorMessage('Failed to Fetch Data'));
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
      setError(error.message);
    } finally {
      setSelectedProductType("");
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getAllPolicyReports();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Stack direction="row" m={2} display="flex" justifyContent="flex-end">
        <TextField
          select
          label="Select Product Type"
          sx={{ width: "15%" }}
          value={selectedProductType}
          onChange={handleProductTypeChange}
        >
          <MenuItem value="">Select Product Type</MenuItem>
          <MenuItem value="motor">Motor</MenuItem>
          <MenuItem value="health">Health</MenuItem>
          <MenuItem value="others">Others</MenuItem>
        </TextField>
      </Stack>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20]}
      />
    </Box>
  );
}
