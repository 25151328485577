import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Grid,
} from "@mui/material";

const AllFormsUpdate = ({ open, setOpen, rowData }) => {
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (rowData) {
      setFormData(rowData);
    }
  }, [rowData]);

  const handleInputChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSave = () => {
    const [year, month] = formData.month_year.split("-");
    const updatedData = {
      ...formData,
      month,
      year,
    };

    console.log("Saving data:", updatedData);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle textAlign="center" fontSize="20px">
        Edit Row Data
      </DialogTitle>

      <Grid container padding={3} columnSpacing={2}>
        {rowData &&
          Object.keys(rowData).map((key) => {
            if (key === "id") {
              return null;
            }

            if (key === "month_year") {
              return (
                <Grid item xs={6} key={key}>
                  <TextField
                    label="Month and Year"
                    type="month"
                    value={formData[key] || ""}
                    onChange={(e) => handleInputChange(key, e.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              );
            }
            return (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  label={key.replace(/_/g, " ").toUpperCase()}
                  value={formData[key] || ""}
                  onChange={(e) => handleInputChange(key, e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            );
          })}
      </Grid>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AllFormsUpdate;
