import React, { useEffect } from 'react';
import VehicleDetails from '../../Components/Vehicle Detail/VehicleDetails';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CallIcon from '@mui/icons-material/Call';
import { useNavigate } from 'react-router';

const CommercialVehicleDetailsPage = () => {
  const naviagte = useNavigate();

  const handlebackBtn = () => {
    naviagte('/motor/commercial');
  };
  return (
    <div>
      <main className="vehical_detail_main_container">
        <div className="vehical_detail_main_container-contact">
          <div className="back_arrow_button">
            <KeyboardBackspaceIcon onClick={handlebackBtn} />
          </div>
          <div className="address-box">
            <div className="address">
              <p>Helpline</p>
              <a href="tel:+919825152468">98 2515 2468</a>
            </div>
            <div className="phone-icon">
              <CallIcon style={{ color: '#23A8FA' }} />
            </div>
          </div>

          <div className="vehical_detail_main_container-heading">
            Vehicle Details
            <div id="container-stars">
              <div id="stars"></div>
            </div>
            <div id="glow">
              <div class="circle"></div>
              <div class="circle"></div>
            </div>
          </div>

          <div className="pagination">
            <div className="pagination_counter">1</div>
            <span className="number_of_pages">2</span>
          </div>
        </div>
        <VehicleDetails />
      </main>
      {/* <VehicleDetails /> */}
    </div>
  );
};

export default CommercialVehicleDetailsPage;
