import {
    FETCH_DOCUMENTS_DATA_FAILURE,
    FETCH_DOCUMENTS_DATA_SUCCESS,
} from "../action-type/actionType";
 
const initialState = {
    list: null,
    error: null,
};
 
const documentsDataReducer = (data = initialState, action) => {
    switch (action.type) {
        case FETCH_DOCUMENTS_DATA_SUCCESS:
            return {...data, list: action.payload};
        case FETCH_DOCUMENTS_DATA_FAILURE:
            return {...data, error: action.error};
        default:
            return data;
    }
};
 
export default documentsDataReducer;