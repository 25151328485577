import * as types from './DashboardActionTypes';

const initialState = {
  masterData: [],
  userPermissions: {},
  individualUserRole: {},
  dashboardCardData: {},
  userManagementData: {},
  customerLogs: {},
  salesManagementLogs: {},
  companyManagementLogs: {},
  agentManagementLogs: {},
  revenueData: {},
  orgData: [],
  agentData: [],
  customerData: [],
  policy: [],
  policyData: [],
  updatedCustomerDetails: {},
  updatedAccessData: {},
  updatedPolicyDetails: {},
  POSPRegistrationDetails: {},
  POSPLoginData: {},
  POSPOTP: {},
  updatedPOSP: {},
  updatedPayout: {},
  postedPOSP: {},
  pospPayout: {},
  DataPosting: [],
  OpenLogin: '',
  updatedCustDocs: {},
  UpdatedPospAccess: {},
  applicationNames: []
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.Get_Dashboard_Data_Success:
      return {
        ...state,
        dashboardCardData: payload
      };

    case types.Get_User_Management_Success:
      return {
        ...state,
        userManagementData: payload
      };

    case types.Get_Customer_Logs_Success:
      return {
        ...state,
        customerLogs: payload
      };

    case types.Get_Sales_Management_Logs_Success:
      return {
        ...state,
        salesManagementLogs: payload
      };

    case types.Get_Company_Management_Logs_Success:
      return {
        ...state,
        companyManagementLogs: payload
      };

    case types.Get_Agent_Management_Logs_Success:
      return {
        ...state,
        agentManagementLogs: payload
      };

    case types.Get_Revenue_Logs_Success:
      return {
        ...state,
        revenueData: payload
      };

    case types.Get_Agent_Data_Success:
      return {
        ...state,
        agentData: payload
      };

    case types.Get_Customer_Data_Success:
      return {
        ...state,
        customerData: payload
      };
    case types.Get_Policy_Data_Success:
      return {
        ...state,
        policyData: payload.body
      };

    case types.Post_Org_Data_Success:
      return {
        ...state,
        orgData: payload
      };
    case types.Get_Org_Data_Success:
      return {
        ...state,
        orgData: payload
      };
    case types.Post_Agent_Data_Success:
      return {
        ...state,
        agentData: payload
      };
    case types.Delete_Org_Data_Success:
      return {
        ...state,
        id: payload
      };
    case types.Edit_Org_Data_Success:
      return {
        ...state,
        id: payload
      };
    case types.Add_Customer_Data_Success:
      return {
        ...state,
        customerData: payload
      };
    case types.Edit_Agent_Data_Success:
      return {
        ...state,
        id: payload
      };
    case types.Delete_Agent_Data_Success:
      return {
        ...state,
        id: payload
      };
    case types.Delete_Customer_Data_Success:
      return {
        ...state,
        id: payload
      };
    case types.Add_Policy_Data_Success:
      return {
        ...state,
        policy: payload
      };
    case types.Add_Policy_Data_Dashboard_Success:
      return {
        ...state,
        policy: payload
      };
    case types.Edit_Policy_Data_Success:
      return {
        ...state,
        id: payload
      };
    case types.Delete_Policy_Data_Success:
      return {
        ...state,
        id: payload
      };

    case types.UPDATE_CUSTOMER_PERSONAL_DATA:
      return {
        ...state,
        updatedCustomerDetails: payload
      };

    case types.UPDATE_ACCESS_DATA:
      return {
        ...state,
        updatedAccessData: payload
      };

    case types.UPDATE_POLICY_DETAILS:
      return {
        ...state,
        updatedPolicyDetails: payload
      };

    case types.POST_POSP_REGISTRATION:
      return {
        ...state,
        POSPRegistrationDetails: payload
      };
    case types.POSP_LOGIN_DETAILS:
      return {
        ...state,
        POSPLoginData: payload
      };

    case types.GET_VERIFICATION_CODE: {
      return {
        ...state,
        POSPOTP: payload
      };
    }

    case types.UPDATED_POSP_DETAILS: {
      return {
        ...state,
        updatedPOSP: payload
      };
    }

    case types.UPDATED_POSP_PAYOUT: {
      return {
        ...state,
        updatedPayout: payload
      };
    }

    case types.GET_POSP_REGISTRATION: {
      return {
        ...state,
        postedPOSP: payload
      };
    }

    case types.POST_POSP_PAYOUT: {
      return {
        ...state,
        pospPayout: payload
      };
    }

    case types.DATA_POSTING: {
      return {
        ...state,
        DataPosting: payload
      };
    }

    case types.OPEN_LOGIN: {
      return {
        ...state,
        OpenLogin: payload
      };
    }

    case types.UPDATE_CUSTOMER_DOCS: {
      return {
        ...state,
        updatedCustDocs: payload
      };
    }

    case types.UPDATE_POSP_ACCESS: {
      return {
        ...state,
        UpdatedPospAccess: payload
      };
    }

    case types.GET_MASTER_DATA: {
      return {
        ...state,
        masterData: payload
      };
    }

    case types.USER_ROLE_PERMISSIONS: {
      return {
        ...state,
        userPermissions: payload
      };
    }

    case types.GET_INDIVIDUAL_USERROLE: {
      return {
        ...state,
        individualUserRole: payload
      };
    }

    case types.APPLICATION_NAMES: {
      return {
        ...state,
        applicationNames: payload
      };
    }

    default:
      return state;
  }
};

export { dashboardReducer };
