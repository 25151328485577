import React, { useState } from 'react';
import { Button, Grid, Checkbox, FormControlLabel, Box, Typography, Stack, InputAdornment } from '@mui/material';
import { setAddressDetails } from '../../../Redux/Loan/Action';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import OtpModal from "../../../Framework/Components/OtpModal"
import CustomTextField from "../../components/CustomTextField"
import { useTranslation } from "react-i18next";
import { sendOtp } from "../../../Framework/Add&SendOTP";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { getPincode } from '../../api/service';



const AddressDetails = ({ setStepCount }) => {
    const agent_id = useSelector((state) => state.posp.data?.agent_id);
    const employee_id = (useSelector((state) => state?.adminReducer?.loginData?.empid));
    let [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const loanContent = t("loan");
    const { addressdetails } = loanContent;
    const {
        control,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
    let [open, setOpen] = useState(false);
    let [showOTPModal, setShowOTPModal] = useState(false);
    let aDetails = useSelector((e) => e.loanReducer.RequestBody.addressDetails);
    let { permanentAddress, communicationAddress } = aDetails;
    let [phno, setPhno] = useState('');
    let customerDetails = useSelector((e) => e.loanReducer);

    // submit handler
    async function onSubmit(data) {
        let address = {
            permanentAddress: {},
            communicationAddress: {}
        };
        if (!open) {
            // permanent and communication address same copy object 
            let common = {
                addressLine1: data.paddress1,
                addressLine2: data.paddress2,
                email: data.pemail,
                phno: data.pphno,
                state: data.pstate,
                city: data.pcity,
                pincode: data.ppincode
            }
            address = {
                permanentAddress: { ...common },
                communicationAddress: { ...common }
            }
        }
        else {
            // both are different address
            let permanent = {
                addressLine1: data.paddress1,
                addressLine2: data.paddress2,
                email: data.pemail,
                phno: data.pphno,
                state: data.pstate,
                city: data.pcity,
                pincode: data.ppincode
            }
            let communication = {
                addressLine1: data.caddress1,
                addressLine2: data.caddress2,
                email: data.cemail,
                phno: data.cphno,
                state: data.cstate,
                city: data.ccity,
                pincode: data.cpincode
            }
            address = {
                permanentAddress: { ...permanent },
                communicationAddress: { ...communication }
            }
        }
        dispatch(setAddressDetails(address));
        setShowOTPModal(true);
        setPhno(address.communicationAddress.phno);
        // CUSTOMER_CREATION
        const addCustomerData = {
            customer_name: customerDetails?.RequestBody?.personalDetails?.firstName + " " + customerDetails?.RequestBody?.personalDetails?.lastName,
            phone: Number(address?.communicationAddress?.phno),
            org_id: 100,
            agent_id: agent_id || 1000,
            employee_id: employee_id || null,
            method: "SEND",
            type: "C",
            dob: customerDetails?.RequestBody?.personalDetails?.dob,
            email: customerDetails?.RequestBody?.addressDetails?.communicationAddress?.email,
            address: customerDetails?.RequestBody?.addressDetails?.communicationAddress?.addressLine1 + customerDetails?.RequestBody?.addressDetails?.communicationAddress?.addressLine2,
            state: customerDetails?.RequestBody?.addressDetails?.communicationAddress?.state,
            city: customerDetails?.RequestBody?.addressDetails?.communicationAddress?.city,
            pincode: customerDetails?.RequestBody?.addressDetails?.communicationAddress?.pincode,
            gender: customerDetails?.RequestBody?.personalDetails?.gender
        }

        // SMS_SERVICE
        const otpData = {
            phone: Number(address?.communicationAddress?.phno),
            org_id: "100",
            agent_id: '1000',
            method: "SEND",
            type: "C",
        }
        let otpResponse = await sendOtp(addCustomerData, otpData);
        console.log("otp here", otpResponse);
    }
    // OTP_MODAL_TOGGLER
    function handleModal() {
        setShowOTPModal((prev) => !prev);
    }

    // pincode_search
    async function searchPincode(e) {
        if (e.length === 6) {
            setLoading(true);
            try {
                let res = await getPincode();
                console.log(res);
                if (res.status === 200) {
                    clearErrors("ppincode");
                    let state = res.data.filter((state) => state.PINCODE === e);
                    console.log(state[0])
                    setValue("pstate", state[0].STATECD);
                    setValue("pcity", state[0].CITYCD);
                } else {
                    setValue("pstate", "");
                    setValue("pcity", "");
                    setError("ppincode", {
                        type: "manual",
                        message: "pincode doesn't exist",
                    });
                }
            } catch (error) {
                console.log({ error: "internal error" });
            } finally {
                setLoading(false);
            }
        } else {
            setValue("pstate", "");
            setValue("pcity", "");
            setLoading(false);
        }
    }

    return (
        <Box>
            <Box mb={2}>
                <Typography sx={{ fontWeight: 900, color: '#383838' }} variant='h5' textAlign={"center"}>{addressdetails.addressdetails}</Typography>
            </Box>
            <Box sx={{ padding: "0 20px" }}>
                <Typography variant='body2' sx={{ fontWeight: 600, color: '#383838', marginBottom: '1em' }}>{addressdetails.permanentaddress}</Typography>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                name="paddress1"
                                control={control}
                                // defaultValue={permanentAddress?.addressLine1}
                                rules={{ required: addressdetails.address1required }}
                                render={({ field }) => (
                                    <CustomTextField
                                        name='paddress1'
                                        label={addressdetails.address1}
                                        field={field}
                                        errors={errors}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="paddress2"
                                control={control}
                                // defaultValue={permanentAddress?.addressLine2}
                                rules={{ required: addressdetails.address2required }}
                                render={({ field }) => (
                                    <CustomTextField
                                        name='paddress2'
                                        label={addressdetails.address2}
                                        field={field}
                                        errors={errors}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="pemail"
                                control={control}
                                // defaultValue={permanentAddress?.email}
                                rules={{
                                    required: addressdetails.emailrequired,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: addressdetails.invalidemail
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomTextField
                                        name='pemail'
                                        label={addressdetails.email}
                                        field={field}
                                        errors={errors}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="pphno"
                                control={control}
                                // defaultValue={permanentAddress?.phno}
                                rules={{
                                    required: addressdetails.phonerequire,
                                    pattern: {
                                        value: /^[6-9]\d{9}$/,
                                        message: addressdetails.invalidphone
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomTextField
                                        name='pphno'
                                        label={addressdetails.phone}
                                        field={{
                                            ...field, onChange: (e) => {
                                                const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                                                field.onChange(value);
                                            }
                                        }}
                                        errors={errors}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name="ppincode"
                                control={control}
                                // defaultValue={permanentAddress?.pincode}
                                rules={{
                                    required: addressdetails.pincoderequire,
                                    pattern: {
                                        value: /^\d{6}$/,
                                        message: addressdetails.invalidpincode
                                    }
                                }}
                                render={({ field }) => (
                                    <CustomTextField
                                        name='ppincode'
                                        label={addressdetails.pincode}
                                        field={{
                                            ...field,
                                            onChange: (e) => {
                                                const value = e.target.value.replace(/\D/g, '').slice(0, 6);
                                                field.onChange(value);
                                                searchPincode(value);
                                            },
                                            InputProps: {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {loading && (
                                                            <CircularProgress size={20} color="secondary" />
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            },
                                        }}
                                        errors={errors}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name="pstate"
                                control={control}
                                // defaultValue={permanentAddress?.state}
                                // rules={{ required: addressdetails.staterequire }}
                                render={({ field }) => (
                                    <CustomTextField
                                        name='pstate'
                                        label={addressdetails.state}
                                        field={{
                                            ...field,
                                            InputProps: {
                                                readOnly: true,
                                            },
                                        }}
                                        errors={errors}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name="pcity"
                                control={control}
                                // defaultValue={permanentAddress?.city}
                                // rules={{ required: addressdetails.cityrequrie }}
                                render={({ field }) => (
                                    <CustomTextField
                                        name='pcity'
                                        label={addressdetails.city}
                                        field={{
                                            ...field,
                                            InputProps: {
                                                readOnly: true,
                                            },
                                        }}
                                        errors={errors}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={!open} onChange={() => { setOpen((prev) => !prev) }} />}
                                label={addressdetails.checkbox}
                            />
                        </Grid>
                        {open && <Grid item xs={12}>
                            <Box>
                                <Typography variant='body2' sx={{ fontWeight: 600, color: '#383838', marginBottom: '1em' }}>{addressdetails.communicationaddress}</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="caddress1"
                                            control={control}
                                            defaultValue={communicationAddress?.addressLine1}
                                            rules={{ required: addressdetails.address1required }}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    name='caddress1'
                                                    label={addressdetails.address1}
                                                    field={field}
                                                    errors={errors}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="caddress2"
                                            control={control}
                                            defaultValue={communicationAddress?.addressLine2}
                                            rules={{ required: addressdetails.address2required }}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    name='caddress2'
                                                    label={addressdetails.address2}
                                                    field={field}
                                                    errors={errors}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="cemail"
                                            control={control}
                                            defaultValue={communicationAddress?.email}
                                            rules={{
                                                required: addressdetails.emailrequired,
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: addressdetails.invalidemail
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    name='cemail'
                                                    label={addressdetails.email}
                                                    field={field}
                                                    errors={errors}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="cphno"
                                            control={control}
                                            defaultValue={communicationAddress?.phno}
                                            rules={{
                                                required: addressdetails.phonerequire,
                                                pattern: {
                                                    value: /^[6-9]\d{9}$/,
                                                    message: addressdetails.invalidphone
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    name='cphno'
                                                    label={addressdetails.phone}
                                                    field={{
                                                        ...field, onChange: (e) => {
                                                            const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                                                            field.onChange(value);
                                                        }
                                                    }}
                                                    errors={errors}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="cpincode"
                                            control={control}
                                            defaultValue={communicationAddress?.pincode}
                                            rules={{
                                                required: addressdetails.pincoderequire,
                                                pattern: {
                                                    value: /^\d{6}$/,
                                                    message: addressdetails.invalidpincode
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    name='cpincode'
                                                    label={addressdetails.pincode}
                                                    field={{
                                                        ...field,
                                                        onChange: (e) => {
                                                            const value = e.target.value.replace(/\D/g, '').slice(0, 6);
                                                            field.onChange(value);
                                                            // searchPincode(value)
                                                        },
                                                        InputProps: {
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {loading && (
                                                                        <CircularProgress size={20} color="secondary" />
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        },
                                                    }}
                                                    errors={errors}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="cstate"
                                            control={control}
                                            defaultValue={communicationAddress?.state}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    name='cstate'
                                                    label={addressdetails.state}
                                                    field={{
                                                        ...field,
                                                        InputProps: {
                                                            readOnly: true,
                                                        },
                                                    }}
                                                    errors={errors}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name="ccity"
                                            control={control}
                                            defaultValue={communicationAddress?.city}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    name='ccity'
                                                    label={addressdetails.city}
                                                    field={{
                                                        ...field,
                                                        InputProps: {
                                                            readOnly: true,
                                                        },
                                                    }}
                                                    errors={errors}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>}
                        <Grid item xs={12} padding={'0 15px'} paddingBottom={"12px"}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Button
                                    onClick={() => { setStepCount((prev) => prev - 1) }}
                                    sx={{
                                        backgroundColor: '#23a8fa', borderRadius: '999px',
                                        fontWeight: 700,
                                    }}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                >
                                    Back
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor: '#23a8fa', borderRadius: '999px',
                                        fontWeight: 700,
                                    }}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Next
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <OtpModal open={showOTPModal} handleClose={handleModal} number={phno} typed={"loan"} setStepCount={setStepCount}
            />
        </Box>
    )
}

export { AddressDetails }