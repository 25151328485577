export const Get_Dashboard_Data_Request = 'Get_Dashboard_Data_Request';
export const Get_Dashboard_Data_Success = 'Get_Dashboard_Data_Success';
export const Get_Dashboard_Error = 'Get_Dashboard_Error';

export const Get_Customer_Logs_Success = 'Get_Customer_Logs_Success';
export const Get_Sales_Management_Logs_Success = 'Get_Sales_Management_Logs_Success';
export const Get_Company_Management_Logs_Success = 'Get_Company_Management_Logs_Success';

export const Get_Agent_Management_Logs_Success = 'Get_Agent_Management_Logs_Success';
export const Get_User_Management_Success = 'Get_User_Management_Success';
export const Get_Revenue_Logs_Success = 'Get_Revenue_Success';

export const Get_Sales_summary_Success = 'Get_Sales_summary_Success';
export const Get_Channels_Summary_Logs = 'Get_Channels_Summary_Logs';
export const Get_Agent_Data_Success = 'Get_Agent_Data_Success';

export const Get_Org_Data_Success = 'Get_Org_Data_Success';
export const Get_Customer_Data_Success = 'Get_Customer_Data_Success';
export const Get_Policy_Data_Success = 'Get_Policy_Data_Success';

export const Post_Org_Data_Success = 'Post_Org_Data_Success';
export const Post_Agent_Data_Success = 'Post_Agent_Data_Success';
export const Delete_Org_Data_Success = 'Delete_Org_Data_Success';

export const Edit_Org_Data_Success = 'Edit_Org_Data_Success';
export const Delete_Agent_Data_Success = 'Delete_Agent_Data_Success';
export const Edit_Agent_Data_Success = 'Edit_Agent_Data_Success';

export const Add_Customer_Data_Success = "Add_Customer_Data_Success";
export const Add_Customer_Dashboard_Data_Success = "Add_Customer_Dashboard_Data_Success";

export const Edit_Customer_Data_Success = "Edit_Data_Customer_Success";
export const Delete_Customer_Data_Success = "Delete_Customer_Data_Success";

export const Add_Policy_Data_Success = "Add_Policy_Data_Success";
export const Add_Policy_Data_Dashboard_Success = "Add_Policy_Data_Dashboard_Success";
export const Edit_Policy_Data_Success = "Edit_Policy_Data_Success";
export const Delete_Policy_Data_Success = "Delete_Policy_Data_Success";

export const Get_Customer_Success = 'Get_Customer_Success';

export const UPDATE_CUSTOMER_PERSONAL_DATA ="UPDATE_CUSTOMER_PERSONAL_DATA";
export const UPDATE_ACCESS_DATA = "UPDATE_ACCESS_DATA";
export const UPDATE_POLICY_DETAILS = "UPDATE_POLICY_DETAILS" ;

export const POST_POSP_REGISTRATION = 'POST_POSP_REGISTRATION';
export const POSP_LOGIN_DETAILS = 'POSP_LOGIN_DETAILS';
export const GET_VERIFICATION_CODE = 'GET_VERIFICATION_CODE';
export const GET_POSP_REGISTRATION ="GET_POSP_REGISTRATION" 

export const UPDATED_POSP_DETAILS = "UPDATED_POSP_DETAILS" ;
export const UPDATED_POSP_PAYOUT = "UPDATED_POSP_PAYOUT";

export const POST_POSP_PAYOUT = "POST_POSP_PAYOUT";

export const DATA_POSTING = "DATA_POSTING";
export const OPEN_LOGIN ="PEN_LOGIN" ;

export const UPDATE_CUSTOMER_DOCS ="UPDATE_CUSTOMER_DOCS";

export const UPDATE_POSP_ACCESS = "UPDATE_POSP_ACCESS";

export const POST_MASTER_DATA_PERMISSIONS = "POST_MASTER_DATA_PERMISSIONS";
export const GET_MASTER_DATA = "GET_MASTER_DATA";
export const USER_ROLE_PERMISSIONS ="USER_ROLE_PERMISSIONS";
export const GET_INDIVIDUAL_USERROLE ="GET_INDIVIDUAL_USERROLE";

export const APPLICATION_NAMES = 'APPLICATION_NAMES';