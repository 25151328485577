// routing
import Routes from '../posp/routes'
import '../../crm/posp/assets/scss/style.scss'
const PospApp = () => {
  return (
    <>
          <Routes />
    </>
  )
}

export default PospApp
