let initialState = {
  formData: {
    proposalDetails:{},
    MemberDetails:[],
    NomineeDetails:{},
    MedcalData:[]
  },
  selectedPlan: {
    plan: {},
    LTF_addOn: "1",
    RVF_addOn: "0",
    planName: "",
    travellgeographyID: "",
  },
  smsResponse: {},
  smsVerificationResponse: {},
};

function travelReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (action.type) {
    case "HANDLE_FORM_DATA":
      return { ...state, formData: { ...state.formData, ...action.payload } };
    case "HANDLE_SELECTED_PLAN":
      return {
        ...state,
        selectedPlan: { ...state.selectedPlan, plan: { ...action.payload } },
      };
    case "HANDLE_RVF_ADDON":
      return {
        ...state,
        selectedPlan: {
          ...state.selectedPlan,
          RVF_addOn: state.selectedPlan.RVF_addOn === "0" ? "1" : "0",
        },
      };

    case "HANDLE_PLAN_NAME":
      return {
        ...state,
        selectedPlan: { ...state.selectedPlan, planName: action.payload },
      };
    case "HANDLE_TRAVELLGEOGRAPHY_NO":
      return {
        ...state,
        selectedPlan: {
          ...state.selectedPlan,
          travellgeographyID: action.payload,
        },
      };
    case "HANDLE_PROPOSAL_RES":
      return { ...state, proposalResponse: { ...action.payload } };
    case type.Mobile_OTP_Request:
      return {
        ...state,
        isLoading: false,
        isError: false,
        smsResponse: payload,
      };
    case type.Mobile_OTP_Verification_Request:
      return {
        ...state,
        isLoading: false,
        isError: false,
        smsVerificationResponse: payload,
      };
    case "proposalDetails":
      return {
        ...state,
        formData: { ...state.formData, proposalDetails: { ...payload } },
      };
      case "MemberDetails":
        return{
        ...state,formData:{...state.formData,MemberDetails:[...payload]}
      };
      case "NomineeDetails":
        return{
          ...state,formData:{...state.formData,NomineeDetails:{...payload}}
        };
        case "MedcalData":
          return{
            ...state,formData:{...state.formData,MedcalData:[...payload]}
          }
    default:
      return state;
  }
}

export { travelReducer };
