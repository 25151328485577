import React from 'react';
import vectorobject from '../../Images/Car-Page/Vector-Smart-Object1-01-01.png';
import carnumberela from '../../Images/Car-Page/Car-and-U-Vector-Smart-Object-01.png';
import carreverse from '../../Images/Car-Page/car-and-u-reverse.png';
import QuotationDetails from '../../Components/Quotation/QuotationDetails';
import '../../../Framework/Pages/Main.css';
import { useNavigate } from 'react-router';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const CarQuotation = () => {
  const naviagte = useNavigate();

  const handlebackBtn = () => {
    naviagte('/motor/car/details');
  };

  return (
    <div>
      <QuotationDetails />
    </div>
  );
};

export default CarQuotation;
