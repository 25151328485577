/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { MemberDetails } from "../../../Redux/Travel/action";
import {
  Paper,
  Grid,
  TextField,
  MenuItem,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
const MemberForm = ({ setValueTab }) => {
  const { t } = useTranslation();
  const travelContent = t("travel");
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    setError,
  } = useForm();
  const dispatch = useDispatch();
  const selector = useSelector((e) => e.travelReducer?.formData);
  const [expanded, setExpanded] = useState(0);
  const hasErrors = Object.keys(errors).length > 0;
  const [state, setState] = useState(true);
  const onSubmit = (data) => {
    const memberData = [];
    const uniquePassport = [];
    const numForms = selector?.travellerCount;

    for (let i = 0; i < numForms; i++) {
      const member = {
        title: data[`title${i}`],
        firstname: data[`firstName${i}`],
        lastname: data[`lastName${i}`],
        dob: data[`dob${i}`],
        maritalStatus: data[`maritalStatus${i}`],
        passport: data[`passport${i}`],
        gender: data[`gender${i}`],
        pincode: data[`pincode${i}`],
        addressLine1: data[`addressLine1${i}`],
        addressLine2: data[`addressLine2${i}`],
        email: data[`email${i}`],
        phoneNumber: data[`phoneNumber${i}`],
      };
      memberData.push(member);
      uniquePassport.push(member.passport);
    }
    setError("passport" + unique(uniquePassport), {
      type: "manual",
      message: travelContent.errorsss.dupErr,
    });
    if (unique(uniquePassport) === undefined) {
      setValueTab(2);
      dispatch(MemberDetails(memberData));
    }
  };
  function unique(arr) {
    let see = [];
    for (let i = 0; i < arr.length; i++) {
      if (see.includes(arr[i])) {
        return i;
      } else {
        see.push(arr[i]);
      }
    }
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  useEffect(() => {
    if (expanded === null && selector?.travellerCount > 0) {
      setExpanded(0);
    }
  }, [expanded, selector?.travellerCount]);

  const handleTitleChange = async (event, _) => {
    const selectedTitle = event.target.value;
    setValue(`title${_}`, selectedTitle);
    const updatedGender = selectedTitle === "MR" ? "MALE" : "FEMALE";
    setValue(`gender${_}`, updatedGender);
    await trigger([`title${_}`, `gender${_}`]);
  };

  const handleGenderChange = async (event, _) => {
    const selectedGender = event.target.value;
    setValue(`gender${_}`, selectedGender);
    const updatedTitle = selectedGender === "MALE" ? "MR" : "MS";
    setValue(`title${_}`, updatedTitle);
    await trigger([`gender${_}`, `title${_}`]);
  };

  const today = new Date();
  const oneDayAgo = new Date();
  oneDayAgo.setDate(today.getDate() - 1);
  const minDOB = new Date(
    new Date().getFullYear() - 99,
    new Date().getMonth(),
    new Date().getDate()
  )
    .toISOString()
    .split("T")[0];

  const eachTravellerForm = (_) => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
        >
          <Controller
            name={`title${_}`}
            control={control}
            rules={{ required: travelContent.errorsss.title_err }}
            defaultValue={_ === 0 ? selector?.proposalDetails?.title : ""}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={travelContent.proposer_details.title}
                error={!!errors[`title${_}`]}
                helperText={errors[`title${_}`]?.message}
                select
                onChange={(event) => handleTitleChange(event, _)}
              >
                <MenuItem value="MR">
                  {travelContent.proposer_details.mr}
                </MenuItem>
                <MenuItem value="MS">
                  {travelContent.proposer_details.ms}
                </MenuItem>
              </TextField>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
        >
          <Controller
            name={`firstName${_}`}
            control={control}
            rules={{ required: travelContent.errorsss.firstName_err }}
            defaultValue={_ === 0 ? selector?.proposalDetails?.firstName : ""}
            render={({ field }) => (
              <TextField
                {...field}
                label={travelContent.proposer_details.firstName}
                error={!!errors[`firstName${_}`]}
                helperText={
                  errors[`firstName${_}`]
                    ? travelContent.errorsss.firstName_err
                    : ""
                }
                fullWidth
                onChange={(e) =>
                  field.onChange(
                    e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1)
                  )
                }
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
        >
          <Controller
            name={`lastName${_}`}
            control={control}
            rules={{ required: travelContent.errorsss.lastName_err }}
            defaultValue={_ === 0 ? selector?.proposalDetails?.lastName : ""}
            render={({ field }) => (
              <TextField
                {...field}
                label={travelContent.proposer_details.lastName}
                error={!!errors[`lastName${_}`]}
                helperText={
                  errors[`lastName${_}`]
                    ? travelContent.errorsss.lastName_err
                    : ""
                }
                fullWidth
                onChange={(e) =>
                  field.onChange(
                    e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1)
                  )
                }
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
        >
          <Controller
            name={`dob${_}`}
            control={control}
            defaultValue={_ === 0 ? selector?.proposalDetails?.dob : ""}
            rules={{
              required: travelContent.errorsss.firstDate_err,
              validate: {
                ageLimit: (value) => {
                  const selectedDate = new Date(value);

                  const ageDifference =
                    today.getTime() - selectedDate.getTime();
                  const oneDayDifference =
                    today.getTime() - oneDayAgo.getTime();

                  const minDate = new Date(
                    today.getFullYear() - 99,
                    today.getMonth(),
                    today.getDate()
                  );

                  const isWithinAgeLimit =
                    _ === 0
                      ? ageDifference >= 18 * 365.25 * 24 * 60 * 60 * 1000 &&
                        (ageDifference < 99 * 365.25 * 24 * 60 * 60 * 1000 ||
                          (ageDifference ===
                            99 * 365.25 * 24 * 60 * 60 * 1000 &&
                            selectedDate >= minDate))
                      : ageDifference >= oneDayDifference &&
                        (ageDifference < 99 * 365.25 * 24 * 60 * 60 * 1000 ||
                          (ageDifference ===
                            99 * 365.25 * 24 * 60 * 60 * 1000 &&
                            selectedDate >= minDate));

                  return isWithinAgeLimit
                    ? true
                    : travelContent.errorsss.secondDate_err +
                        (_ === 0
                          ? travelContent.errorsss.third_err
                          : travelContent.errorsss.fourth_err) +
                        travelContent.errorsss.fifth_err;
                },
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label={travelContent.proposer_details.dob}
                type="date"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={!!errors[`dob${_}`]?.message}
                helperText={errors[`dob${_}`]?.message}
                InputProps={{
                  inputProps: {
                    max: new Date(
                      new Date().getFullYear() - (_ === 0 ? 18 : 0),
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                      .toISOString()
                      .split("T")[0],
                    min: minDOB,
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
        >
          <Controller
            name={`maritalStatus${_}`}
            control={control}
            rules={{ required: travelContent.errorsss.marital_err }}
            defaultValue={
              _ === 0 ? selector?.proposalDetails?.martialStatus : ""
            }
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={travelContent.proposer_details.marital_status}
                error={!!errors[`maritalStatus${_}`]}
                helperText={errors[`maritalStatus${_}`]?.message}
                select
              >
                <MenuItem value="MARRIED">{travelContent.proposer_details.married}</MenuItem>
                <MenuItem value="UNMARRIED">{travelContent.proposer_details.unmarried}</MenuItem>
              </TextField>
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
        >
          <Controller
            name={`gender${_}`}
            control={control}
            rules={{ required: travelContent.errorsss.gender_err }}
            defaultValue={_ === 0 ? selector?.proposalDetails?.gender : ""}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={travelContent.proposer_details.gender}
                error={!!errors[`gender${_}`]}
                helperText={errors[`gender${_}`]?.message}
                select
                onChange={(event) => handleGenderChange(event, _)}
              >
                <MenuItem value="MALE">{travelContent.proposer_details.male}</MenuItem>
                <MenuItem value="FEMALE">{travelContent.proposer_details.female}</MenuItem>
              </TextField>
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
        >
          <Controller
            name={`addressLine1${_}`}
            control={control}
            rules={{ required: travelContent.errorsss.add1_err }}
            defaultValue={
              selector?.proposalDetails?.AddressDetails?.addressLine1
            }
            render={({ field }) => (
              <TextField
                {...field}
                label={travelContent.proposer_details.address_1}
                error={!!errors[`addressLine1${_}`]}
                helperText={
                  errors[`addressLine1${_}`] ? travelContent.errorsss.add1_err : ""
                }
                fullWidth
                onChange={(e) =>
                  field.onChange(
                    e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1)
                  )
                }
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
        >
          <Controller
            name={`addressLine2${_}`}
            control={control}
            rules={{ required: travelContent.errorsss.add2_err}}
            defaultValue={
              selector?.proposalDetails?.AddressDetails?.addressLine2
            }
            render={({ field }) => (
              <TextField
                {...field}
                label={travelContent.proposer_details.address_2}
                error={!!errors[`addressLine2${_}`]}
                helperText={
                  errors[`addressLine2${_}`] ? travelContent.errorsss.add2_err : ""
                }
                fullWidth
                onChange={(e) =>
                  field.onChange(
                    e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1)
                  )
                }
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
        >
          <Controller
            name={`email${_}`}
            control={control}
            rules={{
              required: travelContent.errorsss.email_err,
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: travelContent.errorsss.email_format_err,
              },
            }}
            defaultValue={selector?.proposalDetails?.AddressDetails?.email}
            render={({ field }) => (
              <TextField
                type="email"
                {...field}
                label={travelContent.proposer_details.email}
                error={!!errors[`email${_}`]}
                helperText={
                  errors[`email${_}`] ? errors[`email${_}`].message : ""
                }
                fullWidth
                value={field.value || ""}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
        >
          <Controller
            name={`pincode${_}`}
            control={control}
            rules={{
              required: travelContent.errorsss.pincode_err,
              pattern: {
                value: /^\d{6}$/,
                message: travelContent.errorsss.pincode_format_err,
              },
            }}
            defaultValue={selector?.proposalDetails?.AddressDetails?.pincode}
            render={({ field }) => (
              <TextField
                type="number"
                label={travelContent.proposer_details.pincode}
                error={!!errors[`pincode${_}`]}
                helperText={
                  errors[`pincode${_}`] ? errors[`pincode${_}`].message : ""
                }
                fullWidth
                value={field.value || ""}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "").slice(0, 6);
                  field.onChange(value);
                }}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
        >
          <Controller
            name={`phoneNumber${_}`}
            control={control}
            rules={{
              required: travelContent.errorsss.phone_err,
              pattern: {
                value: /^[6-9][0-9]{9}$/,
                message:travelContent.errorsss.phone_format_err ,
              },
            }}
            defaultValue={
              selector?.proposalDetails?.AddressDetails?.phoneNumber
            }
            render={({ field }) => (
              <TextField
                {...field}
                label={travelContent.proposer_details.phone_number}
                error={!!errors[`phoneNumber${_}`]}
                helperText={
                  errors[`phoneNumber${_}`]
                    ? errors[`phoneNumber${_}`].message
                    : ""
                }
                fullWidth
                value={field.value || ""}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "").slice(0, 10);
                  field.onChange(value);
                }}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
        >
          <Controller
            name={`passport${_}`}
            control={control}
            rules={{
              required: travelContent.errorsss.passport_err,
              pattern: {
                value: /^[A-Za-z][0-9]{7}$/,
                message: travelContent.errorsss.passport_format_err,
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={travelContent.member_details.passport}
                error={!!errors[`passport${_}`]?.message}
                helperText={errors[`passport${_}`]?.message}
                value={field.value || ""}
                onChange={(e) => {
                  const value = e.target.value
                    .toUpperCase()
                    .replace(/[^A-Za-z0-9]/g, "")
                    .slice(0, 8);
                  field.onChange(value);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    );
  };
  const count = selector?.travellerCount;
  const hasErrorForForm = (index) => {
    const formKeyPattern = new RegExp(`\\d+$`);
    for (const key in errors) {
      if (
        formKeyPattern.test(key) &&
        parseInt(key.match(formKeyPattern)[0]) === index
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <Paper
      elevation={5}
      sx={{ borderRadius: "1em", padding: "1em", marginBottom: "2em" }}
    >
      {hasErrors && (
        <Snackbar
          open={state}
          // autoHideDuration={2000}
          // onClose={() => setState(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          style={{ top: "125px", right: "35px", transform: "none" }}
        >
          <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
           {travelContent.errorsss.allFeilds_err}
          </Alert>
        </Snackbar>
      )}

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {Array(selector?.travellerCount)
          .fill("a")
          .map((e, index) => (
            <Grid key={index}>
              <Accordion
                sx={{
                  margin: "1em 0",
                  border: hasErrorForForm(index)
                    ? "1px solid ##FF0000"
                    : "none",
                }}
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                  sx={{
                    border: hasErrorForForm(index)
                      ? "1px solid #FF0000"
                      : "none",
                    color: hasErrorForForm(index) ? "#FF0000" : "none",
                  }}
                >
                  <Typography>{travelContent.member_details.traveler} {index + 1}</Typography>
                </AccordionSummary>
                <AccordionDetails>{eachTravellerForm(index)}</AccordionDetails>
              </Accordion>
            </Grid>
          ))}

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "1em",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{
              marginTop: "1em",
              backgroundColor: "#23A8F8",
              color: "white",
              ":hover": {
                backgroundColor: "lime",
              },
            }}
          >
            Submit Members Details
          </Button>
        </Grid>
      </form>
    </Paper>
  );
};

export { MemberForm };
