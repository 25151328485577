import {FETCH_PIN_CODE_MASTER_DATA_FAILURE, FETCH_PIN_CODE_MASTER_DATA_SUCCESS} from "../action-type/actionType";

const initialState = {
    list: [],
    error: null,
};

const pincodeMasterDataReducer = (masterData = initialState, action) => {
    switch (action.type) {
        case FETCH_PIN_CODE_MASTER_DATA_SUCCESS:
            return {...masterData, list: action.payload};
        case FETCH_PIN_CODE_MASTER_DATA_FAILURE:
            return {...masterData, error: action.error};
        default:
            return masterData;
    }
};

export default pincodeMasterDataReducer;