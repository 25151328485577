import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  ButtonGroup,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  editBranch,
  getBranchDetails,
  postBranches,
  postStatusBranches,
} from "../api/adminService";
import { green, red } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { setError as setErrorMessage } from "../../../Redux/action-creators/errorAction";
import { setSuccessMessage } from './../../../Redux/action-creators/successAction';

const BranchAddition = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const [pincode, setPincode] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [branchOptions, setBranchOptions] = useState([]);
  const [rows, setRows] = useState([]);
  const [componentLoading, setComponentLoading] = useState(false);
  const [status, setStatus] = useState("Active");
  const dispatch = useDispatch();
  const columns = [
    { field: "region", headerName: "Region", width: 150 },
    { field: "state", headerName: "State", width: 150 },
    { field: "branchname", headerName: "Branch Name", width: 150 },
    { field: "district", headerName: "District", width: 150 },
    { field: "pincode", headerName: "Pincode", width: 100 },
    { field: "status", headerName: "Status", width: 100 },
    {
      field: "edit",
      headerName: "Edit",
      width: 120,
      renderCell: (params) => {
        return (
          <FormControl>
            <ButtonGroup
              value={params.row.status}
              onClick={async (e) => {
                const newStatus = e.target.value;
                const payload = {
                  ...params.row,
                  status: newStatus,
                };
                setComponentLoading(true);
                try {
                  const res = await editBranch(params.row.id, payload);
                  if (res.status === 200) {
                    dispatch(setSuccessMessage("Branch Edited successfully"));
                  }
                  setRows(res.data);
                } catch (error) {
                  dispatch(setErrorMessage(error.message || error.toString()));
                } finally {
                  setComponentLoading(false);
                }
              }}
            >
              {/* Active - Success color */}
              {params.row.status === "Inactive" && (
                <FormControlLabel
                  value="Active"
                  control={
                    <Checkbox
                      sx={{
                        color: green[500],
                        "&.Mui-checked": { color: green[700] },
                      }}
                    />
                  }
                  label="Active"
                  labelPlacement="end"
                />
              )}
              {params.row.status === "Active" && (
                <FormControlLabel
                  value="Inactive"
                  control={
                    <Checkbox
                      sx={{
                        color: red[500],
                        "&.Mui-checked": { color: red[700] },
                      }}
                    />
                  }
                  label="Inactive"
                  labelPlacement="end"
                />
              )}
            </ButtonGroup>
          </FormControl>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchBranches = async () => {
      setComponentLoading(true);
      try {
        const res = await getBranchDetails();
        setRows(res.data);
      } catch (error) {
        dispatch(setErrorMessage(error.message || error.toString()));
      } finally {
        setComponentLoading(false);
      }
    };
    fetchBranches();
  }, []);
  const handlePincodeChange = async (event) => {
    let value = event.target.value;
    if (/^\d{0,6}$/.test(value)) {
      if (value.length === 1 && value === "0") {
        return;
      }

      setPincode(value);
      if (value.length === 6) {
        setLoading(true);
        try {
          const response = await fetch(
            `https://api.postalpincode.in/pincode/${value}`
          );
          const data = await response.json();
          if (data[0]?.Status === "Success") {
            const { Division, State, District } = data[0]?.PostOffice[0];
            setValue("region", Division);
            setValue("state", State);
            setValue("district", District);
            const postOffices = data[0]?.PostOffice || [];
            const branchnames = postOffices.map((office) => ({
              name: office.Name,
              value: office.Pincode,
            }));
            setBranchOptions(branchnames);
            setApiError("");
          } else {
            setApiError("Invalid pincode. No data found.");
            dispatch(setErrorMessage("Invalid pincode. No data found."));
          }
        } catch (error) {
          setApiError("Failed to fetch data. Please try again later.");
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);
    setComponentLoading(true);
    try {
      const res = await postStatusBranches({ status: newStatus });
      setRows(res.data);
    } catch (error) {
     dispatch(setErrorMessage(error.message || error.toString()));
    } finally {
      setComponentLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setComponentLoading(true);
    try {
      const res = await postBranches(data);
      if(res.status === 200){
        dispatch(setSuccessMessage("Branch Added successfully"));
      setRows(res.data);
      reset();}
    } catch (error) {
      dispatch(setErrorMessage(error.message || error.toString()));
    } finally {
      setComponentLoading(false);
    }
  };

  return (
    <>
      {" "}
      {componentLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          <Typography variant="h3" gutterBottom>
            Add Branch
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
              <Grid container columnSpacing={3}>
                {/* Pincode Field */}
                <Grid item xs={4}>
                  <TextField
                    label="Pincode"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="text"
                    value={pincode}
                    onInput={handlePincodeChange}
                    {...register("pincode", {
                      required: "Pincode is required",
                      minLength: {
                        value: 6,
                        message: "Pincode must be exactly 6 digits long",
                      },
                      maxLength: {
                        value: 6,
                        message: "Pincode must be exactly 6 digits long",
                      },
                    })}
                    error={!!errors.pincode}
                    helperText={
                      errors.pincode ? errors.pincode.message : apiError || ""
                    }
                    InputProps={{
                      endAdornment: loading ? (
                        <CircularProgress size={24} sx={{ marginRight: 1 }} />
                      ) : null,
                    }}
                    sx={{
                      "& .MuiFormHelperText-root": {
                        color:
                          errors.pincode || apiError
                            ? "error.main"
                            : "text.secondary",
                      },
                    }}
                  />
                </Grid>

                {/* Region Field */}
                <Grid item xs={4}>
                  <TextField
                    label="Region"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    {...register("region", { required: "Region is required" })}
                    error={!!errors.region}
                    helperText={errors.region ? errors.region.message : ""}
                    disabled
                    InputLabelProps={{
                      shrink: watch("region") ? true : false,
                    }}
                  />
                </Grid>
                {/* State Field */}
                <Grid item xs={4}>
                  <TextField
                    label="State"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    {...register("state", { required: "State is required" })}
                    error={!!errors.state}
                    helperText={errors.state ? errors.state.message : ""}
                    disabled
                    InputLabelProps={{
                      shrink: watch("state") ? true : false, // this is fine
                    }}
                  />
                </Grid>
                {/* District Field */}
                <Grid item xs={4}>
                  <TextField
                    label="District"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    {...register("district", {
                      required: "District is required",
                    })}
                    error={!!errors.district}
                    helperText={errors.district ? errors.district.message : ""}
                    disabled
                    InputLabelProps={{
                      shrink: watch("district") ? true : false,
                    }}
                  />
                </Grid>
                {/* Branch Name Field */}
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={!!errors.branchname}
                  >
                    <InputLabel>Branch Name</InputLabel>
                    <Select
                      label="Branch Name"
                      {...register("branchname", {
                        required: "Branch name is required",
                      })}
                      defaultValue=""
                    >
                      {branchOptions.map((option, index) => (
                        <MenuItem
                          key={`${option.value}-${index}`}
                          value={option.name}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.branchname && (
                      <FormHelperText>
                        {errors.branchname.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              {/* Submit Button */}
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Submit
              </Button>
            </Grid>
          </form>
          <Grid item xs={12} mt={3}>
            <Grid container m={2}>
              <Grid item xs={2}>
                <Typography variant="h3" gutterBottom>
                  Branch List
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                display="flex"
                justifyContent="flex-end"
                pr={4}
              >
                <TextField
                  select
                  label="Status"
                  value={status}
                  onChange={handleStatusChange}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <DataGrid rows={rows} columns={columns} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BranchAddition;
