export const styles = {
  container: {
    padding: { xs: "0", md: "2em" },
    rowGap: "2em",
  },
  hiddenBox: {
    display: { xs: "none", md: "flex" },
    alignItems: "center",
  },
  mainBox: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    padding: "5em",
    position: "relative",
  },
  gridItem: {
    paddingRight: { sm: "0", lg: "3em" },
    paddingLeft: { sm: "0", lg: "3em" },
    marginTop: { sm: "0", lg: "40px" },
  },
  paper: {
    padding: { xs: "", sm: "1em" },
    display: "flex",
    alignItems: "center",
    borderRadius: "0.5em",
  },
  subTitle: {
    textAlign: "center",
    fontSize: { sm: "18px", md: "28px" },
    fontWeight: "bold",
    margin: { xs: "1.5em", md: "0" },
  },
  typography: {
    width: "100%",
    position: "absolute",
    top: "50px",
    textAlign: "center",
    fontSize: "32px",
    fontWeight: "bold",
  },
};
