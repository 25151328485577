import React, { useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { TravellerDetails } from "./TravellerDetails";
import { TripDetails } from "./TripDetails";
import { useTranslation } from "react-i18next";
import { Stack } from "react-bootstrap";
import { styles } from "./TravelHome.js";
import { Images } from "../../api/service.js";
export const TravellHomePage = () => {
  // TOGGLE FORM1 AND FORM2
  let [tripDetails, setTripDetails] = useState(false);
  const { t } = useTranslation();
  const travelContent = t("travel");
  return (
    <Box sx={styles.container}>
      <Grid container>
        <Grid item sm={6} sx={styles.hiddenBox}>
          <Box sx={styles.mainBox}>
            <Typography sx={styles.typography}>
              {travelContent.travelHomePage.travel_title}
            </Typography>
            <img
              src={!tripDetails ? Images.Travel_Landing : Images.Travel_Coverage}
              alt=""
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={styles.gridItem}>
          <Paper elevation={5} sx={styles.paper}>
            <Stack>
              <Typography sx={styles.subTitle}>
                {tripDetails
                  ? travelContent.travelHomePage.trip_details
                  : travelContent.travelHomePage.que_1}
              </Typography>
              <Box>
                {!tripDetails ? (
                  <TravellerDetails setTripDetails={setTripDetails} />
                ) : (
                  <TripDetails setTripDetails={setTripDetails} />
                )}
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
