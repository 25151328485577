import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  Paper,
  Typography,
  Badge,
  IconButton,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import flagimg from "../../images/2922516_27130.jpg";
import mailimg from "../../images/gmail-old-svgrepo-com.svg";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
const PersonalDetails = ({ personalDetails, NextBtn }) => {
  const {
    formState: { errors, isSubmitted },
    register,
    setValue,
    handleSubmit,
    trigger,
  } = useForm();
  const [selectedTab, setSelectedTab] = useState("mobile");
  const [openMobileDialog, setOpenMobileDialog] = useState(false);
  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");

  useEffect(() => {
    setValue("name", personalDetails?.name);
    const mapTheGender = (value) => {
      if (value === "MALE" || value === "M") {
        return "M";
      } else if (value === "FEMALE" || value === "F") {
        return "F";
      }
      return null;
    };
    setValue("gender", mapTheGender(personalDetails?.gender));
    setValue("mobile", personalDetails?.mobile);
    setValue("email", personalDetails?.email);
    setValue("dateOfBirth", personalDetails?.dateOfBirth);
    setValue("marital", personalDetails?.marital);
    setValue("annualIncome", personalDetails?.annualIncome);
    setValue("city", personalDetails?.city);
  }, []);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    {
      isSubmitted && (await trigger(name));
    }
  };

  const submitProfileDetails = (data) => {
    const mapObject = {
      name: data?.name,
      gender: data?.gender,
      mobile: data?.mobile,
      email: data?.email,
      dateOfBirth: data?.dateOfBirth,
      marital: data?.marital,
      annualIncome: data?.annualIncome,
      city: data?.city,
    };
    NextBtn(mapObject);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleOpenMobileDialog = () => {
    setOpenMobileDialog(true);
  };

  const handleCloseMobileDialog = () => {
    setOpenMobileDialog(false);
  };

  const handleOpenOtpDialog = () => {
    setOpenMobileDialog(false); // Close the mobile dialog
    setOpenOtpDialog(true); // Open the OTP dialog
  };

  const handleCloseOtpDialog = () => {
    setOpenOtpDialog(false);
  };

  const handleMobileSubmit = () => {
    // Here you can handle sending OTP logic
    handleOpenOtpDialog();
  };

  const handleOtpSubmit = () => {
    // Here you can handle verifying OTP logic
    console.log("OTP Submitted:", otp);
    handleCloseOtpDialog();
  };

  return (
    <Box>
      <Box sx={{ padding: 2 }}>
        {/* heading */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <PersonPinIcon sx={{ fontSize: "2rem", color: "#0000ff", mr: 1 }} />
          <Typography variant="h4">Personal Details</Typography>
        </Box>
        <form onSubmit={handleSubmit(submitProfileDetails)}>
          <Grid
            container
            spacing={2}
            display="flex"
            justifyContent="space-between"
          >
            {/* name */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                defaultValue={personalDetails?.name}
                fullWidth
                label="Full Name"
                variant="outlined"
                name="fullName"
                {...register("name", {
                  required: "Please Enter Your Name",
                  pattern: {
                    value: /^[A-Za-z]+(?:\s[A-Za-z]+)*$/,
                    message: "Invalid First Name",
                  },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                // value={formData.fullName}
                // onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
              />
            </Grid>

            {/* gender */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                name="gender"
                fullWidth
                label="Select Gender"
                select
                defaultValue={personalDetails?.gender}
                color="primary"
                {...register("gender", {
                  required: "Please Select gender",
                })}
                // value={formData.gender}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                error={!!errors.gender}
                helperText={errors.gender ? errors.gender.message : ""}
              >
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
              </TextField>
            </Grid>

            {/* number */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                label="Mobile Number"
                variant="outlined"
                name="mobileNumber"
                defaultValue={personalDetails?.mobile}
                // value={formData.mobileNumber}
                {...register("mobile", {
                  required: "Please Enter Mobile Number",
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: "Invalid mobile Number",
                  },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 10, // Restricts the maximum length of input
                }}
                disabled
                // onChange={handleChange}
                error={!!errors.mobile}
                helperText={errors.mobile ? errors.mobile.message : ""}
              />
            </Grid>

            {/* email */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                label="Email ID"
                variant="outlined"
                name="email"
                defaultValue={personalDetails?.email}
                // value={formData.email}
                {...register("email", {
                  required: "Please Enter Email",
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    message: "Invalid email",
                  },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            </Grid>

            {/* dob */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                type="date"
                fullWidth
                label="Date of Birth"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                name="dateOfBirth"
                defaultValue={personalDetails.dateOfBirth}
                // value={formData.dateOfBirth}
                {...register("dateOfBirth", {
                  required: "Please Select date of birth",
                })}
                // onChange={handleChange}
                error={!!errors.dateOfBirth}
                helperText={
                  errors.dateOfBirth ? errors.dateOfBirth.message : ""
                }
              />
            </Grid>

            {/* marital status */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                label="Marital status"
                select
                color="primary"
                name="marital"
                defaultValue={personalDetails?.marital}
                // value={formData.marital}
                {...register("marital", {
                  required: "Please Select marital status",
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                error={!!errors.marital}
                helperText={errors.marital ? errors.marital.message : ""}
              >
                <MenuItem value="Y">Married</MenuItem>
                <MenuItem value="N">Single</MenuItem>
              </TextField>
            </Grid>

            {/* annual income */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                label="Annual Income"
                select
                color="primary"
                name="annualIncome"
                defaultValue={personalDetails?.annualIncome}
                // value={formData.annualIncome}
                {...register("annualIncome", {
                  required: "Please Select Annual Income",
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                error={!!errors.annualIncome}
                helperText={
                  errors.annualIncome ? errors.annualIncome.message : ""
                }
              >
                <MenuItem value="upto 3 lakhs">Upto 3 lakhs</MenuItem>
                <MenuItem value="3-6 lakhs">3-6 lakhs</MenuItem>
                <MenuItem value="above 6 lakhs">Above 6 lakhs</MenuItem>
              </TextField>
            </Grid>

            {/* city */}
            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                label="City"
                variant="outlined"
                name="city"
                // value={formData.city}
                defaultValue={personalDetails?.city}
                {...register("city", {
                  required: "Please Enter City",
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.city}
                helperText={errors.city ? errors.city.message : ""}
                // onChange={handleChange}
              />
            </Grid>
          </Grid>

          {/* save btn */}
          <Box sx={{ marginTop: 3, display: "flex", justifyContent: "right" }}>
            <Button variant="contained" color="primary" type="submit">
              Next
            </Button>
          </Box>
        </form>
      </Box>

      {/* contact details */}
      {/* <Paper sx={{ padding: "20px", borderRadius: "8px", bgcolor: "#f5f8fd" }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <PhoneIcon sx={{ fontSize: "2rem", color: "#0000ff", mr: 1 }} />
          <Typography variant="h4">Contact details</Typography>
        </Box>

        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tab
            value="mobile"
            label={
              <Badge badgeContent={1} color="secondary">
                Mobile Numbers
              </Badge>
            }
          />
          <Tab
            value="email"
            label={
              <Badge badgeContent={1} color="secondary">
                Email address
              </Badge>
            }
          />
        </Tabs>

        {selectedTab === "mobile" && (
          <Paper
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              padding: "10px",
              borderRadius: "8px",
              borderColor: "#e0e0e0",
              borderStyle: "solid",
              borderWidth: "1px",
            }}
          >
            <img
              src={flagimg}
              alt="India flag"
              style={{ marginRight: "10px", width: "40px", height: "30px" }}
            />
            <Typography variant="h4" sx={{ flexGrow: 1 }}>
              {profileData.phone} 
            </Typography>
            <Button
              variant="contained"
              size="small"
              sx={{
                bgcolor: "#e0e0e0",
                color: "#7e57c2",
                borderRadius: "8px",
                textTransform: "none",
                mr: 2,
              }}
            >
              main
            </Button>
            <IconButton>
              <ArrowForwardIosIcon sx={{ color: "#007bff" }} />
            </IconButton>
          </Paper>
        )}

        {selectedTab === "email" && (
          <Paper
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              padding: "10px",
              borderRadius: "8px",
              borderColor: "#e0e0e0",
              borderStyle: "solid",
              borderWidth: "1px",
            }}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              <img
                src={mailimg}
                alt="Mail icon"
                style={{ marginRight: "10px", width: "30px", height: "30px" }}
              />
            {formData.email} 
            </Typography>
            <Button
              variant="contained"
              size="small"
              sx={{
                bgcolor: "#e0e0e0",
                color: "#7e57c2",
                borderRadius: "8px",
                textTransform: "none",
                mr: 2,
              }}
            >
              main
            </Button>
            <IconButton>
              <ArrowForwardIosIcon sx={{ color: "#007bff" }} />
            </IconButton>
          </Paper>
        )}

        * add phone or email btn 
        <Box textAlign="center" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              bgcolor: "#007bff",
              color: "#fff",
            }}
            onClick={handleOpenMobileDialog}
          >
            <AddIcon sx={{ mr: 1 }} /> Add Email
          </Button>
        </Box>
      </Paper>

      Mobile Number Dialog
      <Dialog open={openMobileDialog} onClose={handleCloseMobileDialog}>
        <DialogTitle>Add Mobile Number</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your mobile number to receive an OTP.
          </DialogContentText>
          <TextField
            autoFocus
            sx={{ marginTop: "15px" }}
            margin="dense"
            label="Mobile Number"
            type="text"
            fullWidth
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMobileDialog}>Cancel</Button>
          <Button onClick={handleMobileSubmit}>Send OTP</Button>
        </DialogActions>
      </Dialog>

      OTP Dialog
      <Dialog open={openOtpDialog} onClose={handleCloseOtpDialog}>
        <DialogTitle>Enter OTP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the OTP sent to your mobile number.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="OTP"
            type="text"
            fullWidth
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOtpDialog}>Cancel</Button>
          <Button onClick={handleOtpSubmit}>Submit</Button>
        </DialogActions>
      </Dialog> */}
    </Box>
  );
};

export default PersonalDetails;
