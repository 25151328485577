import React, { useState } from "react";
import { Box, Grid, Tabs, Tab } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import MainCard from "../../posp/ui-component/cards/MainCard";
import Highcharts from "highcharts";

const TabPanel = (props) => {
  let chartData = [];
  let chartTitle = "";
  const { value } = props;
  const panelName = {
    0: "metrics",
    4: "districts",
    3: "insurers",
    1: "individuals",
    2: "teams",
  };
  // Sample JSON data
  const data = {
    districts: [
      { name: "VISAKHAPATNAM", revenue_in_crores: 120.5, percentage: 25.4 },
      { name: "HYDERABAD", revenue_in_crores: 90.3, percentage: 19.8 },
      { name: "VIJAYAWADA", revenue_in_crores: 75.0, percentage: 16.3 },
      { name: "BANGALORE", revenue_in_crores: 60.2, percentage: 13.1 },
      { name: "KADAPA", revenue_in_crores: 50.0, percentage: 10.9 },
      { name: "KAKINADA", revenue_in_crores: 40.0, percentage: 8.7 },
      { name: "CHITTOOR", revenue_in_crores: 35.5, percentage: 7.7 },
      { name: "TIRUPATI", revenue_in_crores: 30.0, percentage: 6.5 },
    ],
    insurers: [
      {
        name: "Life Insurance Company",
        revenue_in_crores: 200.0,
        percentage: 30.0,
      },
      {
        name: "ACKO General Insurance",
        revenue_in_crores: 150.0,
        percentage: 22.5,
      },
      {
        name: "Max Life Insurance",
        revenue_in_crores: 100.0,
        percentage: 15.0,
      },
      { name: "SBI Life Insurance", revenue_in_crores: 80.0, percentage: 12.0 },
      {
        name: "Go Digit General Insurance",
        revenue_in_crores: 60.0,
        percentage: 9.0,
      },
      { name: "HDFC Life Insurance", revenue_in_crores: 50.0, percentage: 7.5 },
      {
        name: "Bajaj Allianz General Insurance",
        revenue_in_crores: 40.0,
        percentage: 6.0,
      },
      {
        name: "Exide Life Insurance",
        revenue_in_crores: 20.0,
        percentage: 3.0,
      },
    ],
    metrics: [
      { name: "Re Insurance", value_in_crores: 500.0, percentage: 60.0 },
      { name: "B2B", value_in_crores: 165.0, percentage: 10.0 },
      { name: "B2C", value_in_crores: 165.0, percentage: 15.0 },
      { name: "B2B2C", value_in_crores: 165.0, percentage: 15.0 },
    ],
    individuals: [
      { name: "LAKSHMI", profit_in_crores: 120.0, percentage: 30.0 },
      { name: "ARAVIND", profit_in_crores: 100.0, percentage: 25.0 },
      { name: "SATYA", profit_in_crores: 80.0, percentage: 20.0 },
      { name: "MANIKANTA", profit_in_crores: 60.0, percentage: 15.0 },
      { name: "AKHIL", profit_in_crores: 40.0, percentage: 10.0 },
    ],
    teams: [
      { name: "Insurance Broking", revenue_in_crores: 300.0, percentage: 35.0 },
      { name: "Sales Team", revenue_in_crores: 250.0, percentage: 29.0 },
      { name: "Telecom Team", revenue_in_crores: 150.0, percentage: 17.5 },
      { name: "Finance Team", revenue_in_crores: 100.0, percentage: 11.5 },
      { name: "IT Team", revenue_in_crores: 70.0, percentage: 8.0 },
    ],
  };

  switch (panelName[value]) {
    case "districts":
      chartData = data.districts;
      chartTitle = "Revenue Distribution by Districts";
      break;
    case "insurers":
      chartData = data.insurers;
      chartTitle = "Revenue Distribution by Insurers";
      break;
    case "metrics":
      chartData = data.metrics;
      chartTitle = "Metrics Distribution";
      break;
    case "individuals":
      chartData = data.individuals;
      chartTitle = "Profit Distribution by Individuals";
      break;
    case "teams":
      chartData = data.teams;
      chartTitle = "Revenue Distribution by Teams";
      break;
    default:
      chartData = [];
      chartTitle = "No Data Available";
  }

  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: chartTitle,
    },
    tooltip: {
      pointFormat:
        "{series.name}: <b>{point.percentage:.1f}%</b> (${point.y} Crores)",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.1f}%",
        },
      },
    },
    series: [
      {
        name: "Value",
        colorByPoint: true,
        data: chartData.map((item) => ({
          name: item.name,
          y:
            item.revenue_in_crores ||
            item.value_in_crores ||
            item.profit_in_crores,
        })),
      },
    ],
  };
  const optionsStatic = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Revenue Expenses",
    },
    tooltip: {
      pointFormat:
        "{series.name}: <b>{point.percentage:.1f}%</b> (${point.y} Lakhs)",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.1f}%",
        },
      },
    },
    series: [
      {
        name: "Value",
        colorByPoint: true,
        data: [
          {
            name: "Other Expenses",
            y: 5,
          },
          {
            name: "Salary",
            y: 10,
          },
          {
            name: "Overheads",
            y: 10,
          },
          {
            name: "Bonus",
            y: 5,
          },
          {
            name: "Rent",
            y: 10,
          },
          {
            name: "Asset Cost",
            y: 10,
          },
          {
            name: "Insurance",
            y: 40,
          },
          {
            name: "Reimbursement",
            y: 15,
          },
          {
            name: "Internet Bill",
            y: 5,
          },
          {
            name: "Incentives",
            y: 5,
          },
        ],
      },
    ],
  };

  return (
    <MainCard>
      <Grid container>
        <Grid item xs={6}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Grid>
        <Grid item xs={6}>
          <HighchartsReact highcharts={Highcharts} options={optionsStatic} />
        </Grid>
      </Grid>
    </MainCard>
  );
};

const FinanceRevenue = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Real-Time P&L" id="tab-0" aria-controls="tabpanel-0" />
          <Tab label="Individual P&L" id="tab-1" aria-controls="tabpanel-1" />
          <Tab
            label="Team wise Revenue"
            id="tab-2"
            aria-controls="tabpanel-2"
          />
          <Tab
            label="Insurer wise Revenue"
            id="tab-3"
            aria-controls="tabpanel-3"
          />
          <Tab
            label="Location wise Revenue"
            id="tab-4"
            aria-controls="tabpanel-4"
          />
        </Tabs>
        <TabPanel value={value} index={0}></TabPanel>
      </Box>
    </Box>
  );
};

export default FinanceRevenue;
