import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { mappedAddObj } from "../RevenueUpdateForms/AllFormAdd";
import axios from "axios";
import { setSuccessMessage } from './../../../../Redux/action-creators/successAction';
import { useDispatch } from "react-redux";

const AllFormsAdd = ({
  addDialog,
  setAddDialog,
  productType,
  motorType,
  subProductType,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    unregister,  
  } = useForm();

  const dispatch = useDispatch();

  const onSubmit = async(data) => {
    const updatedData = {
      ...data,
      productType,
      motorType,
      subProductType,
    };

    // try {
    //   const res = await axios.post(`https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/addrevenue`, updatedData);
    //   console.log(res.data,'gsdhghgdgdhg');
    //   if(res.status === 200){
    //     dispatch(setSuccessMessage("Data saved successfully"));
    //     setAddDialog(false);
    //     reset(); 
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
    dispatch(setSuccessMessage("New Record Added successfully"));
    console.log("Saving data:", updatedData);


  };

  const handleClose = () => {
    setAddDialog(false);
    reset(); 
    unregister(); 
  };

  useEffect(() => {
    if (addDialog) {
      reset();
    }
  }, [addDialog, reset]);

  const renderFields = (category) => {
    return Object.keys(category).map((key) => {
      if (key === "id") return null;
      if (key === "month_year") {
        return (
          <Grid item xs={6} key={key}>
            <Controller
              name={key}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Month and Year"
                  type="month"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  error={Boolean(errors[key])} 
                  helperText={errors[key]?.message || ""} 
                />
              )}
              rules={{ required: "This field is required" }}
            />
          </Grid>
        );
      }
      return (
        <Grid item xs={6} key={key}>
          <Controller
            name={key}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={key.replace(/_/g, " ").toUpperCase()}
                fullWidth
                margin="normal"
                required
                error={Boolean(errors[key])} 
                helperText={errors[key]?.message || ""} 
              />
            )}
            rules={{ required: "This field is required" }}
          />
        </Grid>
      );
    });
  };

  return (
    <Dialog open={addDialog} onClose={handleClose}>
      <DialogTitle textAlign="center" fontSize="20px">
        Add Row Data
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container padding={2} columnSpacing={2}>
          {(productType === "others" || productType === "health") &&
            renderFields(mappedAddObj.otherColumns)}

          {productType === "motor" && motorType === "Car" &&
            renderFields(mappedAddObj.carColumns)}

          {productType === "motor" && motorType === "Bike" && subProductType === "New Business" &&
            renderFields(mappedAddObj.newBikeColumns)}

          {productType === "motor" && motorType === "Bike" &&
            (subProductType === "Renewal" || subProductType === "Rollover") &&
            renderFields(mappedAddObj.oldBikeColumns)}
        </Grid>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AllFormsAdd;
