import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Box, Typography, Stack, IconButton, Grid } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ClearIcon from "@mui/icons-material/Clear";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// import { currencyFormat } from "../../../Framework/Utils/CurrencyFormat";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import HdfcImg from "../Images/HDFCLOGO 1 copy.jpg";
import careImg from "../Images/CHIL LOGO TYPE 1.jpg";
import bajajImg from "../Images/Bajaj_Allianz_Insurance.jpg";
//import logo from "../Images/Logo.png";
const AddToCompareModal = ({ arr, open, handleClose }) => {
  const [expanded, setExpanded] = useState(false);
  const [ownDamageExpand, setOwnDamageExpand] = useState(false);
  const [addonsExpand, setAddonsExpand] = useState(false);
  const premiumPostbody = useSelector(
    (state) => state.healthReducer.premiumPostObject
  );
  console.log(JSON.stringify(arr));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: 3,
    maxWidth: "950px",
    height: "500px",
    overflowY: "scroll",
  };

  const toggleExpansion = () => {
    setExpanded(!expanded);
    setAddonsExpand(!addonsExpand);
    setOwnDamageExpand(!ownDamageExpand);
  };
  const toggleExpansion1 = () => {
    setAddonsExpand(!addonsExpand);
  };
  const toggleExpansion2 = () => {
    setOwnDamageExpand(!ownDamageExpand);
  };

  const handleDownloadPDF = () => {
    const comparisonContainer = document.getElementById("comparison-container");

    const options = {
      logging: true,
      allowTaint: true,
      useCORS: true,
      scale: 2,
      backgroundColor: "#ffffff",
    };

    const familyDetails = {
      Coverage: premiumPostbody?.selectedCoverage,
      familyCombination: `${
        premiumPostbody?.noOfPeople - premiumPostbody?.noOfChildren
      } Adult ${premiumPostbody?.noOfChildren} Child`,
      eldestMemberAge: premiumPostbody?.highestElderAge || "N/A",
      quoteDate: new Date().toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }),
      pdfDate: new Date().toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }),
    };

    html2canvas(comparisonContainer, options)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        // --- HEADER ---
        // const logoBase64 =
        const logoWidth = 30; // Adjust size as needed
        const logoHeight = 20;
        // pdf.addImage(logoBase64, "PNG", 10, 10, logoWidth, logoHeight); // Position the logo on the left

        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(14);
        //  pdf.text("Comparison Report", 35, 20); // Adjust position to account for the logo

        pdf.setFontSize(10);
        pdf.text(`Quote Date: ${familyDetails.quoteDate}`, 150, 20);
        pdf.text(`PDF Date: ${familyDetails.pdfDate}`, 150, 25);

        // --- FAMILY DETAILS ---
        pdf.setFontSize(10);
        const familyText = `Selected Coverage: ${familyDetails.Coverage} | Family Combination: ${familyDetails.familyCombination} | Eldest Member Age: ${familyDetails.eldestMemberAge} `;
        pdf.text(familyText, 20, 35);
        const startX = 10;
        const endX = 200;
        const lineY = 37;
        pdf.line(startX, lineY, endX, lineY);

        // --- MAIN CONTENT ---
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const imgWidth = 190;
        const imgHeight = (canvas.height / canvas.width) * imgWidth;

        const centerX = (pageWidth - imgWidth) / 2;
        const centerY = (pageHeight - imgHeight) / 2;

        pdf.addImage(imgData, "PNG", centerX, 50, imgWidth, imgHeight);

        // --- FOOTER ---
        const footerHeight = 45;
        const footerPosition = pageHeight - footerHeight;

        const infoText =
          "PS: The above quotations are subject to verification by respective insurance companies.";
        pdf.setFontSize(10);
        pdf.text(infoText, 20, footerPosition);

        pdf.setFont("helvetica", "bold");
        pdf.text("LMV IT Solutions Private Limited", 20, footerPosition + 10);

        pdf.setFont("helvetica", "normal");
        pdf.text(
          "Regd. Off: 1234, ABC Towers, Tech Park, Hitech City, Hyderabad, Telangana – 500081.",
          20,
          footerPosition + 15
        );

        pdf.text(
          "IRDAI CoR No: 987 | Broker Code: LMV/23",
          20,
          footerPosition + 20
        );
        pdf.text(
          "Website: www.lmvitsolutions.com | Toll Free: 1800-123-4567",
          20,
          footerPosition + 25
        );

        pdf.setDrawColor(0);
        pdf.setLineWidth(0.5);
        pdf.line(10, footerPosition - 5, 200, footerPosition - 5);

        pdf.save("comparison.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  const CustomPriceBtn = ({ price }) => {
    return (
      <Button
        variant="contained"
        sx={{
          padding: "10px 18px",
          borderRadius: "10px",
          backgroundColor: "#23a8fa",
          width: "140px",
        }}
      >
        <Typography variant="body1">
          <CurrencyRupeeIcon fontSize="12px" /> {price}
        </Typography>
      </Button>
    );
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ top: "10%" }}
    >
      <Box sx={style}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          pl={2}
        >
          <Box>
            <Typography id="modal-modal-title" variant="h5" fontWeight={500}>
              Comparison
            </Typography>
          </Box>
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
              padding: "1px",
              border: "1px solid #e3e3e3",
              borderRadius: "9999px",
              boxShadow: "10px 9px 10px #e3e3e3",
            }}
          >
            <IconButton color="primary">
              <DownloadIcon onClick={handleDownloadPDF}></DownloadIcon>
            </IconButton>
            <IconButton color="error">
              <MailOutlineIcon></MailOutlineIcon>
            </IconButton>
            <IconButton>
              <WhatsAppIcon color="success"></WhatsAppIcon>
            </IconButton>
            <IconButton color="error">
              <ClearIcon onClick={handleClose}></ClearIcon>
            </IconButton>
          </Stack>
        </Stack>
        <Box id="comparison-container">
          <Stack>
            <Grid container p={2}>
              <Grid
                item
                xs={4}
                component={Stack}
                direction={"row"}
                alignItems={"center"}
              ></Grid>
              <Grid id="" item xs={8}>
                <Stack direction={"row"}>
                  {arr.map((el, index) => {
                    return (
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100px",
                            height: "56px",
                            overflow: "hidden",
                            borderRadius: "8px",
                          }}
                        >
                          <img
                            src={
                              el?.provider == "CARE"
                                ? careImg
                                : el?.provider == "BAJAJ"
                                ? bajajImg
                                : HdfcImg
                            }
                            alt=""
                            width="300"
                            height="100%"
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Stack>
              </Grid>
            </Grid>
            <Grid container p={2}>
              <Grid
                item
                xs={4}
                component={Stack}
                direction={"row"}
                alignItems={"center"}
              >
                <Typography variant="h6">Base Premium</Typography>
              </Grid>
              <Grid item xs={8}>
                <Stack direction={"row"}>
                  {arr.map((el, index) => {
                    const amount =
                      el?.provider === "HDFC" ? el?.premium : el?.premium;
                    return (
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CustomPriceBtn price={amount} />
                      </Box>
                    );
                  })}
                </Stack>
              </Grid>
            </Grid>
            <Grid container p={2}>
              <Grid item xs={4}>
                SumInsured
              </Grid>
              <Grid item xs={8}>
                <Stack direction={"row"}>
                  {arr.map((el, index) => {
                    return (
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        ₹{el.sumInsured}
                      </Box>
                    );
                  })}
                </Stack>
              </Grid>
            </Grid>
            <Box sx={{ borderBottom: "1px solid lightgray" }}>
              <Accordion
                sx={{
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Grid container>
                    <Grid item xs={4}>
                      Service Tax/GST (D)
                    </Grid>
                    <Grid item xs={8}>
                      <Stack direction={"row"}>
                        {arr.map((el, index) => {
                          return (
                            <Box
                              sx={{
                                flex: 1,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {el?.provider === "CARE"
                                ? el.premiumWithGST.replace(/,/g, "") -
                                  el.premium.replace(/,/g, "")
                                : Math.round(el.premiumWithGST - el.premium)}
                            </Box>
                          );
                        })}
                      </Stack>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
              <Accordion>
                <Grid container p={2}>
                  <Grid
                    item
                    xs={4}
                    component={Stack}
                    direction={"row"}
                    alignItems={"center"}
                  >
                    <Typography variant="h6">Premium</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Stack direction={"row"}>
                      {arr.map((el, index) => {
                        const amount =
                          el?.provider === "HDFC"
                            ? el?.premiumWithGST
                            : el?.premiumWithGST;
                        return (
                          <Box
                            sx={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            ₹{amount}
                          </Box>
                        );
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </Accordion>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddToCompareModal;
