import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
 
const Support = () => {
  return (
      <Grid container justifyContent="center" alignItems="center" height="85vh" >
        <Grid item xs={12} sm={12} md={10} lg={6}>
           
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #ddd',
                borderRadius: '14px',
                padding: '20px',
                width: '100%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add box shadow
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  <img src="" alt="" />
                </Box>
 
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'  }}>
                  <Typography variant="h4" gutterBottom >
                    Helpline
                  </Typography>
                  <Typography variant="h5" gutterBottom >
                    180-180-3333
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    180-180-1111 <span className="text-success"> (Toll-Free)</span>
                  </Typography>
                </Box>
 
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'  }}>
                  <Typography variant="h5" gutterBottom>
                    <a href="" className="text-primary" >
                      lmvinsurancebrokingservices@email.com
                    </a>
                  </Typography>
                </Box>
 
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant="h6" >
                    Address:  
                  </Typography>
                  <Typography variant="h6" >
                    6-3-1089/F, RVR Towers, Level 1,<br /> Raj Bhawan Road,<br /> Somajiguda Hyderabad TG 300082 IN
                  </Typography>
                </Box>
              </Box>
           
         
        </Grid>
      </Grid>
   
  )
}
 
export default Support