/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Grid,
  TextField,
  MenuItem,
  Button,
  Paper,
  Box,
  InputAdornment,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Sidebar } from "./Sidebar";
import { MemberForm } from "./MemberDetails";
import { NomineeDetail } from "./NomineeDetail";
import { MedicalDetails } from "./MedicalDetails";
import { ProposalDetails } from "../../../Redux/Travel/action";
import { useTranslation } from "react-i18next";
import axios from "axios";
export const ProposalDetailsPage = () => {
  const { t } = useTranslation();
  const travelContent = t("travel");
  const [stage, setStage] = useState(0);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    clearErrors,
    setError,
  } = useForm();
  const [value, setValueTab] = useState(0);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const handleChange = (event, newValue) => {
    if (newValue >= 0 && newValue <= value) {
      setValueTab(newValue);
    }
  };
  const selector = useSelector((e) => e.travelReducer.formData);
  const handleTitleChange = async (event) => {
    const selectedTitle = event.target.value;
    setValue("title", selectedTitle);
    const updatedGender = selectedTitle === "MR" ? "MALE" : "FEMALE";
    setValue("gender", updatedGender);
    await trigger(["title", "gender"]);
    setValue("selector.proposalDetails.title", selectedTitle);
  };

  const handleGenderChange = async (event) => {
    const selectedGender = event.target.value;
    setValue("gender", selectedGender);
    const updatedTitle = selectedGender === "MALE" ? "MR" : "MS";
    setValue("title", updatedTitle);
    await trigger(["gender", "title"]);
    setValue("selector.proposalDetails.gender", selectedGender);
  };
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    state === ""&&     setError("pincode", {
      type: "manual",
      message: "pincode doesn't exist",
    });
   if(state !==""&& city !==""){
    let obj = {
      title: data.title,
      lastName: data.lastName,
      dob: data.dob,
      martialStatus: data.maritalStatus,
      gender: data.gender,
      AddressDetails: {
        pincode: data.pincode,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        email: data.email,
        panNumber: data.panNumber.toUpperCase(),
        phoneNumber: data.phoneNumber,
        state: state,
        city: city,
      },
      firstName: data.firstName,
    };
    dispatch(ProposalDetails(obj));
    setStage(stage + 1);
    setValueTab(1);
   }
  };
  const today = new Date();
  const maxDOB = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .split("T")[0];
  const minDOB = new Date(
    new Date().getFullYear() - 99,
    new Date().getMonth(),
    new Date().getDate()
  )
    .toISOString()
    .split("T")[0];

  const [loading, setLoading] = useState(false);
  async function searchPincode(e) {
    if (e.length === 6) {
      try {
        let api = `https://api.postalpincode.in/pincode/${e}`;
        let response = await axios.get(api);
        if (response.data[0].Status === "Success") {
          clearErrors("");
          setLoading(false);
          setState(response.data[0].PostOffice[0].State);
          setCity(response.data[0].PostOffice[0].District);
        } else {
          setError("pincode", {
            type: "manual",
            message: "pincode doesn't exist",
          });
          setState("");
          setCity("");
        }
      } catch (error) {
        console.log({ error: "internal error" });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setState("");
      setCity("");
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label={travelContent.proposer_details.proposer} />
            <Tab
              label={travelContent.member_details.members}
              disabled={value < 1}
            />
            <Tab
              label={travelContent.nominee_details.nominee_title}
              disabled={value < 2}
            />
            <Tab
              label={travelContent.medical_details.medical_title}
              disabled={value < 3}
            />
          </Tabs>
        </Box>
      </Grid>

      <Grid container marginBottom={"1.5em"}>
        <Grid item xs={12} sm={3} sx={{ padding: "1em" }}>
          <Sidebar />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container>
            <Grid item xs={12} sx={{ padding: "1em 1em 0 1em" }}>
              {value === 0 && (
                <Paper
                  elevation={5}
                  sx={{ borderRadius: "1em", padding: "1em" }}
                >
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
                      >
                        <Controller
                          name="title"
                          control={control}
                          defaultValue={selector?.proposalDetails?.title || ""}
                          rules={{ required: travelContent.errorsss.title_err }}
                          render={({ field }) => (
                            <TextField
                              select
                              label={travelContent.proposer_details.title}
                              {...field}
                              fullWidth
                              onChange={handleTitleChange}
                              error={!!errors.title}
                              helperText={errors.title?.message}
                            >
                              <MenuItem value="MR">
                                {travelContent.proposer_details.mr}
                              </MenuItem>
                              <MenuItem value="MS">
                                {travelContent.proposer_details.ms}
                              </MenuItem>
                            </TextField>
                          )}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
                      >
                        <Controller
                          name="firstName"
                          control={control}
                          rules={{
                            required: travelContent.errorsss.firstName_err,
                          }}
                          defaultValue={selector?.proposerName}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={travelContent.proposer_details.firstName}
                              onChange={(e) =>
                                field.onChange(
                                  e.target.value.charAt(0).toUpperCase() +
                                    e.target.value.slice(1)
                                )
                              }
                              error={!!errors.firstName}
                              helperText={errors.firstName?.message}
                              fullWidth
                              disabled
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
                      >
                        <Controller
                          name="lastName"
                          control={control}
                          defaultValue={
                            selector?.proposalDetails?.lastName || ""
                          }
                          rules={{
                            required: travelContent.errorsss.lastName_err,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={travelContent.proposer_details.lastName}
                              error={!!errors.lastName}
                              helperText={errors.lastName?.message}
                              fullWidth
                              onChange={(e) =>
                                field.onChange(
                                  e.target.value.charAt(0).toUpperCase() +
                                    e.target.value.slice(1)
                                )
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
                      >
                        <Controller
                          name="dob"
                          control={control}
                          defaultValue={selector?.proposalDetails?.dob || ""}
                          rules={{
                            required: travelContent.errorsss.firstDate_err,
                            validate: {
                              ageLimit: (value) => {
                                const selectedDate = new Date(value);
                                const ageDifference =
                                  today.getFullYear() -
                                  selectedDate.getFullYear();
                                const minDate = new Date(
                                  today.getFullYear() - 99,
                                  today.getMonth(),
                                  today.getDate()
                                );
                                const isWithinAgeLimit =
                                  ageDifference >= 18 &&
                                  (ageDifference < 99 ||
                                    (ageDifference === 99 &&
                                      selectedDate >= minDate));

                                return (
                                  isWithinAgeLimit ||
                                  travelContent.errorsss.secondDate_err
                                );
                              },
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={travelContent.proposer_details.dob}
                              type="date"
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              error={Boolean(errors.dob)}
                              helperText={errors.dob ? errors.dob.message : ""}
                              InputProps={{
                                inputProps: {
                                  max: maxDOB,
                                  min: minDOB,
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
                      >
                        <Controller
                          name="maritalStatus"
                          control={control}
                          defaultValue={
                            selector?.proposalDetails?.martialStatus || ""
                          }
                          rules={{
                            required: travelContent.errorsss.marital_err,
                          }}
                          render={({ field }) => (
                            <TextField
                              select
                              fullWidth
                              label={
                                travelContent.proposer_details.marital_status
                              }
                              error={!!errors.maritalStatus}
                              helperText={errors.maritalStatus?.message}
                              {...field}
                            >
                              <MenuItem value="MARRIED">
                                {travelContent.proposer_details.married}
                              </MenuItem>
                              <MenuItem value="UNMARRIED">
                                {travelContent.proposer_details.unmarried}
                              </MenuItem>
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
                      >
                        <Controller
                          name="gender"
                          control={control}
                          defaultValue={selector?.proposalDetails?.gender || ""}
                          rules={{
                            required: travelContent.errorsss.gender_err,
                          }}
                          render={({ field }) => (
                            <TextField
                              select
                              fullWidth
                              label={travelContent.proposer_details.gender}
                              error={!!errors.gender}
                              helperText={errors.gender?.message}
                              {...field}
                              onChange={handleGenderChange}
                            >
                              <MenuItem value="MALE">
                                {travelContent.proposer_details.male}
                              </MenuItem>
                              <MenuItem value="FEMALE">
                                {travelContent.proposer_details.female}
                              </MenuItem>
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
                      >
                        <Controller
                          name="addressLine1"
                          control={control}
                          defaultValue={
                            selector?.proposalDetails?.AddressDetails
                              ?.addressLine1 || ""
                          }
                          rules={{ required: travelContent.errorsss.add1_err }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={travelContent.proposer_details.address_1}
                              error={!!errors.addressLine1}
                              helperText={
                                errors.addressLine1
                                  ? travelContent.errorsss.add1_err
                                  : ""
                              }
                              fullWidth
                              onChange={(e) =>
                                field.onChange(
                                  e.target.value.charAt(0).toUpperCase() +
                                    e.target.value.slice(1)
                                )
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
                      >
                        <Controller
                          name="addressLine2"
                          control={control}
                          defaultValue={
                            selector?.proposalDetails?.AddressDetails
                              ?.addressLine2 || ""
                          }
                          rules={{ required: travelContent.errorsss.add2_err }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={travelContent.proposer_details.address_2}
                              error={!!errors.addressLine2}
                              helperText={
                                errors.addressLine2
                                  ? travelContent.errorsss.add2_err
                                  : ""
                              }
                              fullWidth
                              onChange={(e) =>
                                field.onChange(
                                  e.target.value.charAt(0).toUpperCase() +
                                    e.target.value.slice(1)
                                )
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
                      >
                        <Controller
                          name="email"
                          control={control}
                          defaultValue={
                            selector?.proposalDetails?.AddressDetails?.email ||
                            ""
                          }
                          rules={{
                            required: travelContent.errorsss.email_err,
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: travelContent.errorsss.email_format_err,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="email"
                              {...field}
                              label={travelContent.proposer_details.email}
                              error={!!errors.email}
                              helperText={
                                errors.email ? errors.email.message : ""
                              }
                              fullWidth
                              value={field.value || ""}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
                      >
                        <Controller
                          name="pincode"
                          control={control}
                          defaultValue={
                            selector?.proposalDetails?.AddressDetails
                              ?.pincode || ""
                          }
                          rules={{
                            required: travelContent.errorsss.pincode_err,
                            pattern: {
                              value: /^\d{6}$/,
                              message:
                                travelContent.errorsss.pincode_format_err,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              type="number"
                              label={travelContent.proposer_details.pincode}
                              error={!!errors.pincode}
                              helperText={
                                errors.pincode ? errors.pincode.message : ""
                              }
                              fullWidth
                              value={field.value || ""}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {loading && (
                                      <CircularProgress
                                        size={20}
                                        color="secondary"
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                const value = e.target.value
                                  .replace(/\D/g, "")
                                  .slice(0, 6);
                                field.onChange(value);
                                searchPincode(value);
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
                      >
                        <Controller
                          name="phoneNumber"
                          control={control}
                          defaultValue={selector?.proposerMobileNo}
                          rules={{
                            required: travelContent.errorsss.phone_err,
                            pattern: {
                              value: /^[6-9][0-9]{9}$/,
                              message: travelContent.errorsss.phone_format_err,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={
                                travelContent.proposer_details.phone_number
                              }
                              error={!!errors.phoneNumber}
                              helperText={
                                errors.phoneNumber
                                  ? errors.phoneNumber.message
                                  : ""
                              }
                              fullWidth
                              disabled
                              onChange={(e) => {
                                const value = e.target.value
                                  .replace(/\D/g, "")
                                  .slice(0, 10);
                                field.onChange(value);
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        padding={{ xs: "1em 0 0 0.5em", sm: "1em" }}
                      >
                        <Controller
                          name="panNumber"
                          control={control}
                          defaultValue={
                            selector?.proposalDetails?.AddressDetails
                              ?.panNumber || ""
                          }
                          rules={{
                            validate: (value) => {
                              if (value.trim() === "") return true;
                              return (
                                /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(
                                  value
                                ) || travelContent.errorsss.pan_err
                              );
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              placeholder="Pan Number*"
                              {...field}
                              label={travelContent.proposer_details.pan}
                              error={!!errors.panNumber}
                              helperText={
                                errors.panNumber ? errors.panNumber.message : ""
                              }
                              fullWidth
                              inputProps={{
                                sx: { textTransform: "uppercase" },
                              }}
                              value={field.value || ""}
                              onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                  field.onChange(e);
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            marginTop: "1em",
                            backgroundColor: "#23A8F8",
                            color: "white",
                            ":hover": {
                              backgroundColor: "lime",
                            },
                          }}
                        >
                          Submit Proposal Details
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              )}
            </Grid>
            <Grid item xs={12} sx={{ padding: "0 1em" }}>
              {value === 1 && <MemberForm setValueTab={setValueTab} />}
            </Grid>
            <Grid item xs={12} sx={{ padding: "0 1em" }}>
              {value === 2 && <NomineeDetail setValueTab={setValueTab} />}
            </Grid>
            <Grid item xs={12} sx={{ padding: "0 1em" }}>
              {value === 3 && <MedicalDetails />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
