import {CLEAR_SUCCESS, SET_SUCCESS} from "../action-type/actionType";

export const setSuccessMessage = (message) => ({
    type: SET_SUCCESS,
    payload: message,
});

export const clearSuccessMessage = () => ({
    type: CLEAR_SUCCESS,
});
