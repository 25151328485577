import React, { useState } from "react";
import {
  Button,
  Grid,
  Stack,
  Typography,
  Box,
  Paper,
  IconButton,
  FormHelperText,
  CircularProgress,
  Snackbar,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Modal,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { PersonalDetails } from "../PersonalDetails/PersonalDetails";
import { AddressDetails } from "../AddressDetails/AddressDetails";
import { LoanDetails } from "../LoanDetails/LoanDetails";
import { NomineeDetails } from "../NomineeDetails/NomineeDetails";
import { proposalNumber } from "../../../Redux/Loan/Action";
import CustomTextField from "../../components/CustomTextField";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { data } from "../../api/service";
const Home = () => {
  const { t } = useTranslation();
  const loanContent = t("loan");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const steps = [
    loanContent.personaldetails.personaldetails,
    loanContent.addressdetails.addressdetails,
    loanContent.loandetails.loandetails,
    loanContent.nomineedetails.nomineedetails,
  ];

  let [stepCount, setStepCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  let [statusLoading, setStatusLoading] = useState(!true);
  let [err, setErr] = useState({ state: false, message: "" });

  // close the modal for the policy status
  const handleClose = () => {
    setOpen(false);
  };

  // form Switch based on stepCount
  function formSwitch() {
    switch (stepCount) {
      case 0:
        return (
          <PersonalDetails setOpen={setOpen} setStepCount={setStepCount} />
        );
      case 1:
        return <AddressDetails setStepCount={setStepCount} />;
      case 2:
        return <LoanDetails setStepCount={setStepCount} />;
      case 3:
        return <NomineeDetails setStepCount={setStepCount} />;
      default:
        return (
          <PersonalDetails setOpen={setOpen} setStepCount={setStepCount} />
        );
    }
  }

  // Function to handle the check policy status action
  const onSubmit = async (data) => {
    try { 
    let  proposalNum  = data.proposalNum;
        dispatch(proposalNumber(proposalNum));
        navigate("/loan/thankyou");
    } catch (error) {
      setErr({ state: true, message: "Failed to get Status" });
    } finally {
      setStatusLoading(false);
    }
  };

  // image slider
  const imgSlider = () => {
    switch (stepCount) {
      case 0:
        return (
          <img src={data.imageUrl.LoanInsurance_Personal_Details} alt="img1" />
        );
      case 1:
        return <img src={data.imageUrl.LoanInsurance_Address} alt="img2" />;
      case 2:
        return <img src={data.imageUrl.LoanInsurance} alt="img3" />;
      case 3:
        return <img src={data.imageUrl.LoanInsurance_Nominee} alt="img4" />;
      default:
        return (
          <img src={data.imageUrl.LoanInsurance_Personal_Details} alt="img1" />
        );
    }
  };

  return (
    <>
      <Box minWidth={"300px"}>
        <Grid
          container
          sx={{ minHeight: { lg: "100dvh" }, alignItems: { lg: "center" } }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 6em",
                    overflow: "hidden",
                  }}
                >
                  {imgSlider()}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack
                  height={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    padding: { xs: "8px 10px", sm: "16px 32px", md: "0 3em" },
                  }}
                >
                  <Box sx={{ width: "100%", marginBottom: "1em" }}>
                    <Stepper activeStep={stepCount} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                  <Paper
                    elevation={5}
                    sx={{
                      borderRadius: "1em",
                      overflow: "hidden",
                      padding: "16px 0 ",
                    }}
                  >
                    <Box>
                      <Box>{formSwitch()}</Box>
                    </Box>
                  </Paper>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            backgroundColor: "#fff",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "8px",
          }}
        >
          <Box maxWidth={"600px"} margin={"auto"}>
            <Paper elevation={3} sx={{ borderRadius: ".8em" }}>
              <Box padding={"1em"}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mb={1}
                >
                  <Typography variant="h6" fontWeight={700} color={"#444444"}>
                    Get Policy Status
                  </Typography>
                  <IconButton size="small" onClick={handleClose}>
                    <CancelIcon />
                  </IconButton>
                </Stack>
                <Stack
                  sx={{
                    padding: "1em",
                    borderRadius: ".7em",
                    backgroundColor: "#ebebeb61",
                    border: "1px solid #d9d9d9",
                  }}
                >
                  <form
                    sx={{ my: 2 }}
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Controller
                      name="proposalNum"
                      control={control}
                      rules={{
                        required: "Proposal number is required",
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          name="proposalNumber"
                          label="Proposal Number"
                          placeholder="XXXXXXXXX4045"
                          field={{
                            ...field,
                            onChange: (e) => {
                              const value = e.target.value
                                .replace(/\D/g, "")
                                .slice(0, 13);
                              field.onChange(value);
                            },
                          }}
                          errors={errors}
                        />
                      )}
                    />
                    <FormHelperText sx={{ mb: 2, fontSize: ".7em" }}>
                      Enter Your 13 Digit Proposal Number{" "}
                    </FormHelperText>
                    <Box sx={{ my: 1, position: "relative" }}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#23a8fa",
                          borderRadius: "999px",
                          fontWeight: 700,
                        }}
                        type="submit"
                        disabled={statusLoading}
                        fullWidth
                      >
                        Check Status
                        <CircularProgress size={"sm"} />
                      </Button>
                      {statusLoading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Box>
                  </form>
                </Stack>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={err.state}
        autoHideDuration={4000}
        onClose={() => {
          setErr({ state: false, message: "" });
        }}
        message="Failed to Create Proposal"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          {err.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Home;
