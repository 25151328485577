import {
  Box,
  Divider,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  TextField,
  MenuItem,
  CardContent,
  Card,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  NavigateNext,
  Business,
  Refresh,
  Loop,
  Paid,
} from "@mui/icons-material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getProductClaims, getPremium, getSales } from "../api/adminService";
import MainCard from "../../posp/ui-component/cards/MainCard";
import { gridSpacing } from "../../../Redux/crmustomization/constant";
import { useSelector } from "react-redux";
const AdminHome = () => {
  const role = useSelector((state)=> state.adminReducer.loginData.Role);
  const [tab, setTab] = useState("New Business");
  const [productClaims, setProductClaims] = useState([]);
  const [premiumData, setPremiumData] = useState();
  const [salesdata, setSalesData] = useState([]);
  const [dateRange, setDateRange] = useState({
    from: "",
    to: "",
  });
  const [period, setPeriod] = useState("month");



  // max date yesterday
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedYesterday = yesterday.toISOString().split("T")[0];

  function productClaimsMapper(productClaims) {
    let newclaims = [];
    productClaims.forEach((element) => {
      let obj = {};
      obj.name = element.product;
      obj.y = element.percentage;
      newclaims.push(obj);
    });
    return newclaims;
  }

  // make api call for getting total premium based on type
  useEffect(() => {
    let res = async () => {
      let res = await getPremium(period);
      setPremiumData(res.data);
    };
    res();
  }, [tab, period]);

  // make api call for getting product claims based on type
  useEffect(() => {
    let res = async () => {
      let res = await getProductClaims(tab);
      let productClaims = res?.data?.productClaims;
      productClaims = productClaimsMapper(productClaims);
      setProductClaims(productClaims);
    };
    res();
  }, [tab]);





  // make api call for getting total sales based on type
  useEffect(() => {
    let res = async () => {
      let res = await getSales(tab);
      setSalesData(res);
    };
    res();
  }, [tab]);

  const breadcrumbs = [
    <Link
      component={Typography}
      variant="h5"
      underline="hover"
      key="1"
      color="inherit"
      href="/"
    >
      Dashboard
    </Link>,
    <Typography key="3" color="#23a8fa" variant="h5">
      {role}
    </Typography>,
  ];

  const cardArray = [
    {
      title: "New Business",
      icon: <Business />,
      des: "+55% This Month Business",
    },
    { title: "Rollover", icon: <Refresh />, des: "+55% This Month Rollover" },
    { title: "Renewal", icon: <Loop />, des: "+55% This Month Renewal" },
    { title: "Revenue", icon: <Paid />, des: "+55% This Month Revenue" },
  ];

  const permiumChartStyles = {
    chart: {
      type: "column",
    },
    title: {
      text: "Total Revenue",
      align: "left",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xAxis: {
      categories: premiumData && [...premiumData.categories],
    },
    yAxis: {
      title: {
        text: "Total Revenue",
      },
      min: 0,
      labels: {
        formatter: function () {
          return this.value
            ? this.value >= 100
              ? this.value / 100 + " Crore"
              : this.value + " Lakh"
            : this.value;
        },
      },
      max: null,
    },
    series: premiumData && [...premiumData.data],
  };

  const options2 = {
    chart: {
      type: "column",
    },
    title: {
      text: `${period}ly Sales`,
      align: "left",
    },
    subtitle: {
      text: `This ${period} Renewals`,
      align: "left",
    },
    xAxis: {
      categories: ["MON", "TUE", "WED", "THU", "", "SAT", "SUN"],
    },
    yAxis: {
      title: {
        text: "Number of Renewals",
      },
      min: 0,
    },
    plotOptions: {
      column: {
        pointWidth: 20,
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Life",
        data: [60, 40, 55, 33, 12, 19, 40],
      },
      {
        name: "Non-Life",
        data: [30, 50, 40, 40, 60, 70, 40],
      },
    ],
  };
  const piechart1 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: true,
      type: "pie",
    },
    title: {
      text: "Product Claims",
      align: "left",
    },
    subtitle: {
      text: "Product Wise Claims",
      align: "left",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        innerSize: "40%",
        borderRadius: 10,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          distance: -30,
          format: "{point.percentage:.1f}%",
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Products",
        data: [...productClaims],
      },
    ],
  };

  // fun to toggle type
  const handleTab = (tab) => setTab(tab);

  // func to change period => week month year
  const handlePeriodChange = (e) => setPeriod(e.target.value);

  const handleDateRange = (e) => {
    const { name, value } = e.target;
    if (name === "from") {
      setDateRange({ ...dateRange, from: value });
    } else {
      setDateRange({ ...dateRange, to: value });
    }
  };

  return (
    <>
      <Divider />
      <Stack
        sx={{ py: 2, bgcolor: "#ecf9ff69" }}
        justifyContent={"center"}
        direction="row"
      >
        <Stack sx={{ textAlign: "center", color: "#23a8fa" }} spacing={2}>
          <Typography variant="body2" fontWeight={700}>
            Welcome
          </Typography>
          <Typography variant="h4" textTransform={"uppercase"} fontWeight={600}>
            {/* {profile} */}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Box
        component={Stack}
        spacing={2}
        px={4}
        direction={"row"}
        justifyContent="space-between"
        py={2}
        alignItems={"center"}
      >
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Stack direction="row" columnGap={3}>
          <Stack direction="row" columnGap={1}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              value={dateRange["from"]}
              onChange={handleDateRange}
              name="from"
              label="From"
              type="date"
              size="small"
            ></TextField>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              value={dateRange["to"]}
              onChange={handleDateRange}
              to="to"
              label="To"
              type="date"
              size="small"
              inputProps={{
                max: formattedYesterday, // Set yesterday as the maximum date
              }}
            ></TextField>
            <TextField
              size="small"
              sx={{ minWidth: "120px" }}
              label="Sort by"
              value={period}
              onChange={handlePeriodChange}
              select
            >
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="year">Year</MenuItem>
            </TextField>
          </Stack>
        </Stack>
      </Box>
      <Divider />
      <Stack
        sx={{ px: 4, py: 3, flexDirection: { md: 'row' } }}
        columnGap={3}
        rowGap={1}
      >
        {cardArray.map((item, i) => (
          <Card
            key={i}
            sx={{
              cursor: "pointer",
              flex: 1,
              backgroundColor: tab === item.title ? "#23a8fa" : "#0000000a",
              color: tab === item.title ? "#fff" : "5f5f5f",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              transition: "0.3s",
              "&:hover": {
                boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
              },
            }}
            onClick={() => {
              handleTab(item.title);
            }}
          >
            <CardContent
              component={Stack}
              spacing={1}
              sx={{ paddingBottom: "0px" }}
            >
              <Typography
                variant="h5"
                component="h2"
                color={"inherit"}
              >
                <Box component='span' mr={1}>
                  {item.icon}
                </Box>
                {item.title}
              </Typography>
              <Typography variant="body2" component="p">
                {item.des}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Stack>

      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <MainCard content={false}>
                <CardContent>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={permiumChartStyles}
                  />
                </CardContent>
              </MainCard>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={7}>
                  <MainCard content={false}>
                    <CardContent>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options2}
                      />
                    </CardContent>
                  </MainCard>
                </Grid>
                <Grid item xs={12} md={5}>
                  <MainCard content={false}>
                    <CardContent>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={piechart1}
                      />
                    </CardContent>
                  </MainCard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminHome;
