import { Autocomplete, Button, CardContent, Grid, MenuItem, Paper, TextareaAutosize, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { cardStyle, imgStyle, textBoxStyle } from "./RegisterClaimStyles";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { clearSuccessMessage, setSuccessMessage } from "../../../../Redux/action-creators/successAction";
import { clearError, setError } from "../../../../Redux/action-creators/errorAction";
import { useTranslation } from "react-i18next";
import { getAllPolicies, getPoliciesDataById, postClaimDetail } from "../../api/customerService";
import { gridSpacing } from '../../../../Redux/crmustomization/constant';
import MainCard from '../../../posp/ui-component/cards/MainCard';


const RegisterClaim = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const customerData = useSelector(state => state.customer.data);
    const masterData = useSelector(state => state.masterData.list);
    const [policies, setPolicies] = useState([]);
    const { control, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            claim_type: '',
            claim_subtype: '',
            insurer: '',
            policy_number: '',
            mobile_number: customerData.phone
        }
    });
    const claim_type = watch('claim_type');
 
    useEffect(() => {
        getPolicies();
    }, [])
 
    const claimSubtype = () => {
        if (!claim_type) return [];
        return masterData.claimSubType.filter(item => item.claimType === claim_type) || [];
    };
 
    const getPolicies = () => {
        dispatch(clearError());
        dispatch(clearSuccessMessage());
        getPoliciesDataById(customerData.customer_id).then(response => {
            if (response.status > 400) {
                dispatch(setError(t('lmv.serverError')))
            } else {
                setPolicies(response.data);
            }
        }).catch((error) => {
            dispatch(setError(t('lmv.serverError') + " " + error.toString()));
        }).catch((error) => {
            dispatch(setError(t('lmv.serverError') + " " + error.toString()));
        })
    }
 
    const onSubmit = (data) => {
        dispatch(clearError());
        dispatch(clearSuccessMessage());
        const payload = {
            customer_id: customerData.customer_id,
            claim_type: data.claim_type,
            claim_subtype: data.claim_subtype,
            insurer: data.insurer,
            policy_number: data.policy_number,
            mobile_number: data.mobile_number,
            reason: data.reason,
        }
        postClaimDetail(payload).then(response => {
            if (response.status > 400) {
                dispatch(setError(t('lmv.serverError')))
            } else {
                dispatch(setSuccessMessage(t('lmv.successPost')));
            }
        }).catch((error) => {
            dispatch(setError((t('lmv.serverError')) + error.toString()));
        })
    };

    return (
        <>
        <MainCard content={false} >
            <CardContent>
                <Grid container spacing={gridSpacing}>
                    {/* <Grid item xs={4}>
                        <Typography variant="h4">
                            {t('lmv.customer.claimPageHeading')}
                        </Typography>
                    </Grid> */}
                    <Grid item >

                        <Typography variant="h6">
                            {t('lmv.customer.claimPageSubHeading')}
                        </Typography>
                    </Grid>
                    <Grid item >

                        <Typography variant="body1" gutterBottom>
                            {t('lmv.customer.claimPageSubHeading2')}
                        </Typography>

                    </Grid>
                </Grid>
            </CardContent>
        </MainCard>

        {/* <Grid container sx={{ px: 2 }} gap={1}>*/}

        <MainCard content={false} title={t('lmv.customer.claimPageHeading')}>

            <CardContent>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={6} md={4}>
                                    <Typography variant="subtitle1">{t('lmv.customer.claimTypeLabel')}</Typography>
                                    <Controller
                                        name="claim_type"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                fullWidth
                                                error={!!errors.claim_type}
                                                helperText={errors.claim_type?.message}
                                            >
                                                {masterData.claimType.map((claimType) => (
                                                    <MenuItem key={claimType.code} value={claimType.code}>
                                                        {claimType.description}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                        rules={{ required: t('lmv.requiredField') }}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Typography variant="subtitle1">{t('lmv.customer.claimSubTypeLable')}</Typography>
                                    <Controller
                                        name="claim_subtype"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                fullWidth
                                                error={!!errors.claim_subtype}
                                                helperText={errors.claim_subtype?.message}
                                            >
                                                {claimSubtype().map((data) => (
                                                    <MenuItem key={data.code} value={data.code}>
                                                        {data.description}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                        rules={{ required: t('lmv.requiredField') }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle1">
                                        {t('lmv.customer.insurerFormFieldLabel')}
                                    </Typography>
                                    <Controller
                                        name="insurer"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                fullWidth
                                                error={!!errors.insurer}
                                                helperText={errors.insurer?.message}
                                            >
                                                {masterData.insurer.map((data) => (
                                                    <MenuItem key={data.code} value={data.description}>
                                                        {data.description}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                        rules={{ required: t('lmv.requiredField') }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle1">
                                        {t('lmv.customer.policyNumberFieldLabel')}
                                    </Typography>
                                    {
                                        policies &&
                                        <Controller
                                            name="policyNumber"
                                            defaultValue={null}
                                            control={control}
                                            rules={{ required: t('lmv.requiredField') }}
                                            render={({ field, fieldState: { error } }) => (
                                                <Autocomplete
                                                    {...field}
                                                    onChange={(_, data) => field.onChange(data)}
                                                    options={policies}
                                                    getOptionLabel={(policy) => policy.policy_number}
                                                    isOptionEqualToValue={(policy, value) => policy.policy_number === value.policy_number}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={!!error}
                                                            helperText={error ? error.message : null}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle1">
                                        {t('lmv.customer.mobileFormFieldLabel')}
                                    </Typography>
                                    <Controller
                                        name="mobile_number"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                margin={'dense'}
                                                fullWidth
                                                error={!!errors.mobile_number}
                                                helperText={errors.mobile_number?.message}
                                                disabled={true}
                                            />
                                        )}
                                        rules={{ required: t('lmv.requiredField') }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography
                                        variant="subtitle1">{t('lmv.customer.reasonFormFieldLabel')}</Typography>
                                    <Controller
                                        name="reason"
                                        control={control}
                                        rules={{ required: t('lmv.requiredField') }}
                                        defaultValue=""
                                        error={!!errors.reason}
                                        helperText={errors.reason?.message}
                                        render={({ field }) => (
                                            <TextareaAutosize
                                                {...field}
                                                minRows={4}
                                                placeholder={t('lmv.customer.reasonPlaceholder')}
                                                style={{ width: '100%', minHeight: '5%', padding: '12px' }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} display="flex" justifyContent="center">
                                    <Button variant="contained" size='large' color="info" type="submit">
                                        {t('lmv.customer.submit')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </CardContent>
        </MainCard>
    </>
    )
}

export default RegisterClaim
