import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import ProposalDetails from "../pages/ProposalDetails/ProposalDetails";
import PaymentPage from "../pages/PaymentDetails/PaymentPage";
import PaymentSuccessPage from "../pages/PaymentDetails/PaymentSucessPage";
const LoanRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/proposalDetails" element={<ProposalDetails />} />
      <Route exact path="/payment" element={<PaymentPage />} />
      <Route exact path="/thankyou" element={<PaymentSuccessPage/>} />
    </Routes>
  );
};

export default LoanRoutes;
