import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import MainContent from "./MainContent";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerData } from "../../../Redux/action-creators/customerAction";
import { getMenuDetails } from "../../customer/api/customerService";
import Sidebar from "../../posp/layout/MainLayout/Sidebar";
import { SET_MENU } from "../../../Redux/crmustomization/actions";
import { drawerWidth } from "../../../Redux/crmustomization/constant";
import { Box, Toolbar, useMediaQuery, AppBar } from "@mui/material";
import menuItem from "../../customer/menu-items";
import Header from "../../posp/layout/MainLayout/Header";
import "../../../crm/posp/assets/scss/style.scss";

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "theme",
})(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    "margin",
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }
  ),
  [theme.breakpoints.up("md")]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`,
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "20px",
    width: `calc(100% - ${drawerWidth}px)`,
    padding: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "10px",
    width: `calc(100% - ${drawerWidth}px)`,
    padding: "16px",
    marginRight: "10px",
  },
}));

const NavBar = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const authData = useSelector((state) => state.auth.data);
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();

  const [menuItemss, setMenuItemss] = useState([]);

  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    getMenu();
    loadCustomerData();
  }, []);

  const getMenu = () => {
    getMenuDetails().then((response) => {
      if (response.status > 400) {
        console.log("Server Error");
      } else {
        console.log(response.data);
        setMenuItemss(response.data);
      }
    });
  };

  const loadCustomerData = async () => {
    dispatch(fetchCustomerData(authData.phone));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>
      <Sidebar
        drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
        menuItems={menuItem}
      />
      <Main theme={theme} open={leftDrawerOpened}>
        <Routes>
          {menuItemss.map((menuItem, index) => {
            return (
              <Route
                key={"route" + index}
                exact
                path={menuItem.path}
                element={<MainContent menuItem={menuItem} />}
              />
            );
          })}
        </Routes>
      </Main>
    </Box>
  );
};

export default NavBar;
