import React, { useEffect, useState } from "react";
import {
  Box,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { mainGrid } from "./userLoginStyle";
import {
  clearSuccessMessage,
  setSuccessMessage,
} from "../../../../Redux/action-creators/successAction";
import {
  clearError,
  setError,
} from "../../../../Redux/action-creators/errorAction";
import { setToken } from "../../../../Redux/action-creators/authAction";
import { formFieldError } from "../../../../Framework/common-css/style";
import {
  fetchCustomerData,
  generateOtp,
  otpValidation,
  validateUserByPhone,
} from "../../api/customerService";
import MainCard from "../../../posp/ui-component/cards/MainCard";
import { gridSpacing } from "../../../../Redux/crmustomization/constant";
import { FETCH_CUSTOMER_SUCCESS } from "../../../../Redux/action-type/actionType";

const UserLogin = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [step, setStep] = useState(1); // Step 1 for mobile number, Step 2 for OTP

  let timer;

  useEffect(() => {
    if (resendDisabled) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendDisabled]);

  useEffect(() => {
    if (countdown === 0) {
      setResendDisabled(false);
      clearInterval(timer);
      setCountdown(60); // Reset countdown
    }
  }, [countdown]);

  const handleResendOtp = () => {
    setResendDisabled(true);
  };

  const handleMobileNumber = () => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const mobileNumber = getValues("mobileNumber");
    const isNumber = () => !isNaN(Number(mobileNumber));
    if (isNumber && mobileNumber.toString().trim().length === 10) {
      setLoading(true); // Start loading
      validateUser(mobileNumber.toString().trim());
    } else {
      dispatch(setError(t("lmv.customer.invalidMobile")));
    }
  };

  const validateUser = async (phone) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    const payload = {
      enrollType: "SIGNIN",
      phone: phone,
    };

    try {
      const response = await validateUserByPhone(payload);
      if (response?.status > 400) {
        dispatch(setError(t("lmv.serverError")));
        setLoading(false);
      } else {
        if (response?.data?.signInRequestFlag) {
          sendOtp(phone);
        } else {
          dispatch(setError(response?.data?.message));
          setLoading(false);
        }
      }
    } catch (e) {
      dispatch(setError("Internal server error"));
      setLoading(false);
    }
  };

  const sendOtp = async (phonenumber) => {
    dispatch(clearError());
    dispatch(clearSuccessMessage());
    try {
      const response = await generateOtp({ phone: phonenumber });
      if (response?.status > 400) {
        dispatch(setError(t("lmv.serverError")));
      } else {
        dispatch(setSuccessMessage(t("lmv.customer.otpSuccessMsg")));
        setStep(2);
      }
    } catch (e) {
      dispatch(setError(t("Internal Server Error")));
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    if (step === 1) {
      handleMobileNumber();
    } else {
      setLoading(true);
      dispatch(clearError());
      dispatch(clearSuccessMessage());

      try {
        const otpObject = {
          phone: data?.mobileNumber,
          otp: data?.otp,
          enrollType: "SIGNIN",
        };
        const response = await otpValidation(otpObject);

        if (response.status > 400) {
          dispatch(setError(t("lmv.serverError")));
        } else {
          if (response?.data?.isOtpVerified) {
            const customerData = await fetchCustomerData(data?.mobileNumber);
            console.log(customerData?.data , 'customer data')
            if (customerData?.status === 200) {
              dispatch(setSuccessMessage(t("lmv.customer.loginSuccess")));
              localStorage.setItem("jwtToken", response?.data?.data?.token);
              localStorage.setItem("phone", response?.data?.data?.phone);
              localStorage.setItem("userType", "customer");
              dispatch(setToken(response?.data?.data));
              dispatch({
                type: FETCH_CUSTOMER_SUCCESS,
                payload: customerData?.data,
              });
              navigate("/customer/dashboard");
            } else {
              dispatch(setError("Internal Server Error"));
            }
          } else {
            dispatch(setError(t("Otp Miss Match")));
          }
        }
      } catch (e) {
        dispatch(setError(t("Internal Server Error")));
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePhoneChange = () => {
    setStep(1);
  };

  const navigateToNewUser = () => {
    navigate("/customer/auth/user-signup");
  };

  return (
    <>
      <CardContent>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item md={5}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <MainCard
                  content={false}
                  border={true}
                  sx={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
                  boxShadow={true}
                  darkTitle={true}
                >
                  <CardContent>
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={12}>
                        <Typography variant="h4" align="center">
                          {t("lmv.customer.loginNow")}
                        </Typography>
                      </Grid>

                      {/* mobile number */}
                      <Grid item xs={12}>
                        <Controller
                          name="mobileNumber"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: t("lmv.requiredField"),
                            pattern: {
                              value: /^[0-9]{10}$/,
                              message: t("lmv.customer.invalidMobile"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={t("lmv.customer.mobileFormFieldLabel")}
                              variant="outlined"
                              margin="normal"
                              id="mobileNumber"
                              fullWidth
                              error={!!errors.mobileNumber}
                              disabled={step !== 1}
                              onInput={(e) => {
                                if (e.target.value.length > 10) {
                                  e.target.value = e.target.value.slice(0, 10);
                                }
                              }}
                            />
                          )}
                        />
                        {errors.mobileNumber?.message && (
                          <Typography
                            style={{ color: "red" }}
                            variant="caption"
                          >
                            {errors.mobileNumber?.message}
                          </Typography>
                        )}
                      </Grid>

                      {/* proceed button */}
                      {step === 1 && (
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={handleMobileNumber}
                            disabled={loading} // Disable while loading
                            startIcon={
                              loading && <CircularProgress size={20} />
                            } // Show spinner while loading
                          >
                            {loading ? `proceeding` : t("lmv.customer.proceed")}
                          </Button>
                        </Grid>
                      )}

                      {/* otp field */}
                      {step === 2 && (
                        <>
                          <Grid item xs={12}>
                            <Controller
                              name="otp"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: t("lmv.requiredField"),
                                pattern: {
                                  value: /^\d{4}$/,
                                  message: "please enter 4 digit otp",
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  margin="normal"
                                  fullWidth
                                  label={t("lmv.customer.otp")}
                                  type="text"
                                  id="otp"
                                  error={Boolean(errors.otp)}
                                  onInput={(e) => {
                                    if (e.target.value.length > 4) {
                                      e.target.value = e.target.value.slice(
                                        0,
                                        4
                                      );
                                    }
                                  }}
                                />
                              )}
                            />
                            {errors.otp?.message && (
                              <Typography
                                style={{ color: "red" }}
                                variant="caption"
                              >
                                {errors.otp?.message}
                              </Typography>
                            )}
                          </Grid>

                          {/* submit button */}
                          <Grid item xs={12}>
                            <Button
                              type="submit"
                              variant="contained"
                              fullWidth
                              disabled={loading} // Disable while loading
                              startIcon={
                                loading && <CircularProgress size={20} />
                              } // Show spinner while loading
                            >
                              {loading ? `loading` : t("lmv.customer.login")}
                            </Button>
                          </Grid>

                          <Grid item xs={12}>
                            <Button
                              variant="text"
                              fullWidth
                              disabled={resendDisabled}
                              onClick={handleResendOtp}
                            >
                              {resendDisabled
                                ? `${t(
                                    "lmv.customer.resend"
                                  )} Otp in ${countdown} sec`
                                : t("lmv.customer.resend")}
                            </Button>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                          <Grid item sx={{ cursor: "pointer" }}>
                            <Link onClick={navigateToNewUser}>
                              {t("lmv.customer.newUserRegister")}
                            </Link>
                          </Grid>
                          {step === 2 && (
                            <Grid item sx={{ cursor: "pointer" }}>
                              <Link onClick={handlePhoneChange}>
                                {t("lmv.customer.changeMobileNumber")}
                              </Link>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </MainCard>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default UserLogin;
