import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete, Paper, Modal } from '@mui/material';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './SAODThirdPartyDetails.css';
import { MandatoryFieldsErrorMessage } from '../../../../Framework/Utils/ErrorMessage';
import { AlertError } from '../../../../Framework/Toaster/AlertError';
import { AddToLocalStorage } from '../../../../Framework/GlobalObj';

const SAODThirdPartyDetails = ({ open, setOpen, req, setReq, handleClose, insurerName }) => {
  const [data, setData] = useState({ ...req });
  const [isSubmited, setIsSubmited] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  useEffect(() => {
    let obj = { ...req };
    setData(obj);
  }, []);

  const handleChangePreviousInsurerAutocomplete = (e, name) => {
    let objInsurer = {
      ...data.previousPolicyDetails,
      [name]: e.target.innerHTML
    };
    setData({ ...data, ['previousPolicyDetails']: objInsurer });
  };

  const handleChangePreviousInsurer = (e, name) => {
    if (name == 'policyCompany' || name == 'policyTPCompany') {
      let objInsurer = {
        ...data.previousPolicyDetails,
        [name]: e.target.innerHTML
      };
      setData({ ...data, ['previousPolicyDetails']: objInsurer });
    } else {
      let objInsurer = {
        ...data.previousPolicyDetails,
        [name]: e.target.value
      };
      setData({ ...data, ['previousPolicyDetails']: objInsurer });
    }
  };
  const handleApplySAOD = () => {
    let objprev = { ...data.previousPolicyDetails, ['policyType']: '1OD_0TP' };
    let objData = { ...data, ['previousPolicyDetails']: objprev };
    setData(objData);

    let mandatatoryDetails = objData.previousPolicyDetails;
    if (Object.values(mandatatoryDetails).every((key) => key !== '')) {
      let objFormData = { ...objData };
      insurerName.forEach((el) => {
        if (el.Name == objFormData.previousPolicyDetails.policyCompany) {
          objFormData.previousPolicyDetails.policyCompany = el.godigitShortCode;
        }
        if (el.Name == objFormData.previousPolicyDetails.policyTPCompany) {
          objFormData.previousPolicyDetails.policyTPCompany = el.godigitShortCode;
        }
      });
      objFormData.insurance.policyType = 'OWN DAMAGE';
      setReq(objFormData);
      AddToLocalStorage('globalObj', objFormData);
      handleClose();
    } else {
      setIsSubmited(true);
      setErrorOpen(true);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} className="modal-container">
      <div className="centered-modal">
        <div className="modal-content" style={{backgroundColor:"white"}}>
          <Grid container spacing={2} justifyContent="center" className="SOAAD-modal-container">
            <Grid item xs={12} md={6}>
              <label className="label_input">
                Previous Insurer Name<span style={{ color: 'red' }}>*</span>
              </label>
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={insurerName?.map((option) => (option ? option?.Name : ''))}
                value={data?.previousPolicyDetails?.policyCompany || ''}
                onChange={(e) => {
                  handleChangePreviousInsurerAutocomplete(e, 'policyCompany');
                }}
                className="SAOD-autocomplete"
                PaperComponent={({ children }) => <Paper>{children}</Paper>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      type: 'search'
                    }}
                    name="policyCompany"
                  />
                )}
              />
              {isSubmited && data?.previousPolicyDetails?.policyCompany == '' ? <span className="error-validation">Please Select Previous Insurer Name</span> : <span />}
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="label_input">
                Previous Policy Number<span style={{ color: 'red' }}>*</span>
              </label>
              <input type="text" className="SAODTP" placeholder="Eg: 12345678910" value={data?.previousPolicyDetails?.policyNumber || ''} name="policyNumber" onChange={(e) => handleChangePreviousInsurer(e, 'policyNumber')} />
              {isSubmited && data?.previousPolicyDetails?.policyNumber == '' ? <span className="error-validation">Please Enter Previous Policy Number</span> : <span />}
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="label_input">
                Start Date <span style={{ color: 'red' }}>*</span>
              </label>
              <input type="date" className="SAODTP" placeholder="Eg: 12345678910" value={data?.previousPolicyDetails?.policyStartDate || ''} name="policyStartDate" onChange={(e) => handleChangePreviousInsurer(e, 'policyStartDate')} />
              {isSubmited && data?.previousPolicyDetails?.policyStartDate == '' ? <span className="error-validation">Please Enter Policy Start Date</span> : <span />}
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="label_input">
                End Date<span style={{ color: 'red' }}>*</span>
              </label>
              <br />
              <input type="date" className="SAODTP" placeholder="Eg: 12345678910" value={data?.previousPolicyDetails?.policyEndDate || ''} name="policyEndDate" onChange={(e) => handleChangePreviousInsurer(e, 'policyEndDate')} />
              <br />
              {isSubmited && data?.previousPolicyDetails?.policyEndDate == '' ? <span className="error-validation">Please Enter Policy End Date</span> : <span />}
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="label_input">
                Previous Third Party Policy Insurer Name
                <span style={{ color: 'red' }}>*</span>
              </label>
              <br />
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={insurerName?.map((option) => (option ? option.Name : ''))}
                value={data?.previousPolicyDetails?.policyTPCompany || ''}
                onChange={(e) => {
                  handleChangePreviousInsurerAutocomplete(e, 'policyTPCompany');
                }}
                className="SAOD-autocomplete"
                PaperComponent={({ children }) => <Paper>{children}</Paper>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      type: 'search'
                    }}
                    name="policyCompany"
                  />
                )}
              />
              {isSubmited && data?.previousPolicyDetails?.policyTPCompany == '' ? <span className="error-validation">Please Select Third Party Insurer Name</span> : <span />}
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="label_input">
                Third Party Policy Number<span style={{ color: 'red' }}>*</span>
              </label>
              <input type="text" className="SAODTP" placeholder="Eg: 12345678910" value={data?.previousPolicyDetails?.policyTPNumber || ''} name="policyTPNumber" onChange={(e) => handleChangePreviousInsurer(e, 'policyTPNumber')} />
              {isSubmited && data?.previousPolicyDetails?.policyTPNumber == '' ? <span className="error-validation">Please Enter Third Party Policy Number</span> : <span />}
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="label_input">
                Third Party Policy Start Date & Time
                <span style={{ color: 'red' }}>*</span>
              </label>
              <br />
              <input type="datetime-local" className="SAODTP" placeholder="Eg: 12345678910" value={data?.previousPolicyDetails?.policyTPStartDate || ''} name="policyTPStartDate" onChange={(e) => handleChangePreviousInsurer(e, 'policyTPStartDate')} step="1" />
              {isSubmited && data?.previousPolicyDetails?.policyTPStartDate == '' ? <span className="error-validation">Please Enter Third Party Start Date & Time</span> : <span />}
            </Grid>
            <Grid item xs={12} md={6}>
              <label className="label_input">
                Third Party Policy End Date & Time
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input type="datetime-local" className="SAODTP" placeholder="Eg: 12345678910" value={data?.previousPolicyDetails?.policyTPEndDate || ''} name="policyTPEndDate" onChange={(e) => handleChangePreviousInsurer(e, 'policyTPEndDate')} step="1" />
              {isSubmited && data?.previousPolicyDetails?.policyTPEndDate == '' ? <span className="error-validation">Please Enter Third Party End Date & Time</span> : <span />}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="SAOD-button">
              <button className="SAOD-apply-button" onClick={handleApplySAOD}>
                Submit
              </button>
            </div>
          </Grid>
          <CloseIcon sx={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }} onClick={() => setOpen(false)} />
          <AlertError open={errorOpen} setOpen={setErrorOpen} message={MandatoryFieldsErrorMessage} />
        </div>
      </div>
    </Modal>
  );
};

export default SAODThirdPartyDetails;
