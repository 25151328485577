const pincodeData = [
    {
     "Pin Code": 110001,
     "City": "New Delhi",
     "District": "Central Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110001,
     "City": "New Delhi",
     "District": "New Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110002,
     "City": "New Delhi",
     "District": "Central Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110003,
     "City": "New Delhi",
     "District": "Central Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110003,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110004,
     "City": "New Delhi",
     "District": "Central Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110005,
     "City": "New Delhi",
     "District": "Central Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110006,
     "City": "New Delhi",
     "District": "Central Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110006,
     "City": "New Delhi",
     "District": "North Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110007,
     "City": "New Delhi",
     "District": "North Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110008,
     "City": "New Delhi",
     "District": "Central Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110009,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110010,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110011,
     "City": "New Delhi",
     "District": "Central Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110012,
     "City": "New Delhi",
     "District": "Central Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110013,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110014,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110015,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110016,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110017,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110018,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110019,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110020,
     "City": "New Delhi",
     "District": "New Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110020,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110021,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110022,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110023,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110024,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110025,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110026,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110027,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110028,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110029,
     "City": "New Delhi",
     "District": "New Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110029,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110030,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110031,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110032,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110032,
     "City": "New Delhi",
     "District": "North East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110033,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110034,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110035,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110036,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110037,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110038,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110039,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110040,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110041,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110042,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110043,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110044,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110045,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110046,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110047,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110048,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110049,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110050,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110051,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110052,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110053,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110053,
     "City": "New Delhi",
     "District": "North East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110054,
     "City": "New Delhi",
     "District": "North Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110055,
     "City": "New Delhi",
     "District": "Central Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110056,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110057,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110058,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110059,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110060,
     "City": "New Delhi",
     "District": "Central Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110061,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110062,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110063,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110064,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110064,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110065,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110066,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110067,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110068,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110069,
     "City": "New Delhi",
     "District": "Central Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110070,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110071,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110072,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110073,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110074,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110075,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110076,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110077,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110077,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110078,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110078,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110079,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110080,
     "City": "New Delhi",
     "District": "South Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110081,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110082,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110083,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110084,
     "City": "New Delhi",
     "District": "North Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110085,
     "City": "New Delhi",
     "District": "North Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110085,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110086,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110087,
     "City": "New Delhi",
     "District": "West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110088,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110089,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110090,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110090,
     "City": "New Delhi",
     "District": "North East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110091,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110092,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110093,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110093,
     "City": "New Delhi",
     "District": "North East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110094,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110094,
     "City": "New Delhi",
     "District": "North East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110094,
     "City": "New Delhi",
     "District": "North West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110095,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110096,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110097,
     "City": "New Delhi",
     "District": "South West Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 110098,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 114001,
     "City": "New Delhi",
     "District": "East Delhi",
     "State": "Delhi"
    },
    {
     "Pin Code": 121001,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121002,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121003,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121004,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121005,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121006,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121007,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121008,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121009,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121010,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121012,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121013,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121101,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121102,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121103,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121104,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121105,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121106,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121106,
     "City": "New Delhi",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121107,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 121705,
     "City": "Faridabad",
     "District": "Faridabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 122000,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122001,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122002,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122003,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122004,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122005,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122006,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122007,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122008,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122009,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122010,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122011,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122012,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122013,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122015,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122016,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122017,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122018,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122020,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122022,
     "City": "Gurugram",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122027,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122050,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122051,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122052,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122101,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122102,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122103,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122104,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122105,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122106,
     "City": "Rewari",
     "District": "Dharuhera",
     "State": "Haryana"
    },
    {
     "Pin Code": 122107,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122108,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122413,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122414,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122502,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122503,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122504,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122505,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122506,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 122508,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 123001,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123021,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123021,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123023,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123024,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123027,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123028,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123029,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123031,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123034,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123034,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123035,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123101,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123102,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123103,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123106,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 123106,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123110,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123209,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123301,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123302,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123303,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123303,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123306,
     "City": "Mahendragarh",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123401,
     "City": "Gurgaon",
     "District": "Gurgaon",
     "State": "Haryana"
    },
    {
     "Pin Code": 123401,
     "City": "Bikaner",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123401,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123402,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123411,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123411,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123412,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123412,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123414,
     "City": "Gurgaon",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123501,
     "City": "Mahendragarh",
     "District": "Mahendragarh",
     "State": "Haryana"
    },
    {
     "Pin Code": 123501,
     "City": "Rewari",
     "District": "Rewari",
     "State": "Haryana"
    },
    {
     "Pin Code": 123506,
     "City": "GURGAON",
     "District": "GURGAON",
     "State": "Haryana"
    },
    {
     "Pin Code": 123508,
     "City": "Gurgaon",
     "District": "GURGAON",
     "State": "Haryana"
    },
    {
     "Pin Code": 124001,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124003,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124004,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124010,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124021,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124022,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124102,
     "City": "Jhajjar",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124103,
     "City": "Jhajjar",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124104,
     "City": "Jhajjar",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124105,
     "City": "Bahadurgarh",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124105,
     "City": "Jhajjar",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124106,
     "City": "Jhajjar",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124107,
     "City": "Jhajjar",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124108,
     "City": "Jhajjar",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124109,
     "City": "Jhajjar",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124111,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124112,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124113,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124141,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124142,
     "City": "Jhajjar",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124146,
     "City": "Jhajjar",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124201,
     "City": "Jhajjar",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124202,
     "City": "Jhajjar",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124301,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124303,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124401,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124404,
     "City": "Bahadurgarh",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124404,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124406,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124411,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124412,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124501,
     "City": "Bahadurgarh",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124501,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124504,
     "City": "Bahadurgarh",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124505,
     "City": "Bahadurgarh",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124506,
     "City": "Bahadurgarh",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124507,
     "City": "Bahadurgarh",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124508,
     "City": "Bahadurgarh",
     "District": "Jhajjar",
     "State": "Haryana"
    },
    {
     "Pin Code": 124513,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 124514,
     "City": "Rohtak",
     "District": "Rohtak",
     "State": "Haryana"
    },
    {
     "Pin Code": 125001,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125004,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125005,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125006,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125007,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125011,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125033,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125037,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125038,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125039,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125042,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125044,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125047,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125048,
     "City": "Fatehabad",
     "District": "Fatehabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 125049,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125050,
     "City": "Fatehabad",
     "District": "Fatehabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 125051,
     "City": "Fatehabad",
     "District": "Fatehabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 125051,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125052,
     "City": "Fatehabad",
     "District": "Fatehabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 125052,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125053,
     "City": "Fatehabad",
     "District": "Fatehabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 125054,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125055,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125056,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125058,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125060,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125075,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125076,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125077,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125078,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125101,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125102,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125103,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125104,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125106,
     "City": "Fatehabad",
     "District": "Fatehabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 125110,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125111,
     "City": "Fatehabad",
     "District": "Fatehabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 125112,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125113,
     "City": "Fatehabad",
     "District": "Fatehabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 125113,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125120,
     "City": "Fatehabad",
     "District": "Fatehabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 125120,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125121,
     "City": "Hisar",
     "District": "Hisar",
     "State": "Haryana"
    },
    {
     "Pin Code": 125133,
     "City": "Fatehabad",
     "District": "Fatehabad",
     "State": "Haryana"
    },
    {
     "Pin Code": 125201,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 125401,
     "City": "Sirsa",
     "District": "Sirsa",
     "State": "Haryana"
    },
    {
     "Pin Code": 126101,
     "City": "Jind",
     "District": "Jind",
     "State": "Haryana"
    },
    {
     "Pin Code": 126102,
     "City": "Jind",
     "District": "Jind",
     "State": "Haryana"
    },
    {
     "Pin Code": 126103,
     "City": "Jind",
     "District": "Jind",
     "State": "Haryana"
    },
    {
     "Pin Code": 126110,
     "City": "Jind",
     "District": "Jind",
     "State": "Haryana"
    },
    {
     "Pin Code": 126111,
     "City": "Jind",
     "District": "Jind",
     "State": "Haryana"
    },
    {
     "Pin Code": 126112,
     "City": "Jind",
     "District": "Jind",
     "State": "Haryana"
    },
    {
     "Pin Code": 126113,
     "City": "Jind",
     "District": "Jind",
     "State": "Haryana"
    },
    {
     "Pin Code": 126114,
     "City": "Jind",
     "District": "Jind",
     "State": "Haryana"
    },
    {
     "Pin Code": 126115,
     "City": "Jind",
     "District": "Jind",
     "State": "Haryana"
    },
    {
     "Pin Code": 126116,
     "City": "Jind",
     "District": "Jind",
     "State": "Haryana"
    },
    {
     "Pin Code": 126125,
     "City": "Jind",
     "District": "Jind",
     "State": "Haryana"
    },
    {
     "Pin Code": 126152,
     "City": "Jind",
     "District": "Jind",
     "State": "Haryana"
    },
    {
     "Pin Code": 127021,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127022,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127025,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127026,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127027,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127028,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127029,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127030,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127031,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127032,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127035,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127040,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127041,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127042,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127043,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127045,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127046,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127110,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127111,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127114,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127201,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127306,
     "City": "Charkhi Dadri",
     "District": "Charkhi Dadri",
     "State": "Haryana"
    },
    {
     "Pin Code": 127307,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127308,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127309,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 127310,
     "City": "Bhiwani",
     "District": "Bhiwani",
     "State": "Haryana"
    },
    {
     "Pin Code": 131001,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131004,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131021,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131022,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131023,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131024,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131027,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131028,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131029,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131030,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131039,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131101,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131102,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131103,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131301,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131302,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131304,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131305,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131306,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131402,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131403,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131408,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 131409,
     "City": "Sonipat",
     "District": "Sonipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132001,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132013,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132022,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132023,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132024,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132036,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132037,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132039,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132040,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132041,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132042,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132044,
     "City": "Rajound ",
     "District": "Rajound ",
     "State": "Haryana"
    },
    {
     "Pin Code": 132046,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132054,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132101,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132102,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132103,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132104,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132105,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132106,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132107,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132108,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132109,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132112,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132113,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132114,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132115,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132116,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132117,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 132118,
     "City": "Kurukshetra ",
     "District": "Kurukshetra ",
     "State": "Haryana"
    },
    {
     "Pin Code": 132122,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132132,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132140,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132145,
     "City": "Panipat",
     "District": "Panipat",
     "State": "Haryana"
    },
    {
     "Pin Code": 132157,
     "City": "Karnal",
     "District": "Karnal",
     "State": "Haryana"
    },
    {
     "Pin Code": 133001,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133003,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133004,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133005,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133006,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133021,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133101,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133102,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133103,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 133104,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133118,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133201,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133202,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133203,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133204,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 133205,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133206,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 133207,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 133301,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 133302,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134001,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 134002,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 134003,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 134005,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 134007,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 134009,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 134011,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 134101,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134102,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134103,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134104,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134107,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134108,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134109,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134112,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134113,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134114,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134115,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134116,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134117,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134118,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 134118,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134201,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 134202,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 134202,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134203,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 134203,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134204,
     "City": "Ambala",
     "District": "Ambala",
     "State": "Haryana"
    },
    {
     "Pin Code": 134204,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 134205,
     "City": "Panchkula",
     "District": "Panchkula",
     "State": "Haryana"
    },
    {
     "Pin Code": 135001,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 135002,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 135003,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 135004,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 135021,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 135101,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 135102,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 135103,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 135105,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 135106,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 135133,
     "City": "Yamuna Nagar",
     "District": "Yamuna Nagar",
     "State": "Haryana"
    },
    {
     "Pin Code": 136020,
     "City": "Kaithal",
     "District": "Kaithal",
     "State": "Haryana"
    },
    {
     "Pin Code": 136021,
     "City": "Kaithal",
     "District": "Kaithal",
     "State": "Haryana"
    },
    {
     "Pin Code": 136026,
     "City": "Kaithal",
     "District": "Kaithal",
     "State": "Haryana"
    },
    {
     "Pin Code": 136027,
     "City": "Kaithal",
     "District": "Kaithal",
     "State": "Haryana"
    },
    {
     "Pin Code": 136030,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 136033,
     "City": "Kaithal",
     "District": "Kaithal",
     "State": "Haryana"
    },
    {
     "Pin Code": 136034,
     "City": "Kaithal",
     "District": "Kaithal",
     "State": "Haryana"
    },
    {
     "Pin Code": 136035,
     "City": "Kaithal",
     "District": "Kaithal",
     "State": "Haryana"
    },
    {
     "Pin Code": 136038,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 136042,
     "City": "Kaithal",
     "District": "Kaithal",
     "State": "Haryana"
    },
    {
     "Pin Code": 136043,
     "City": "Kaithal",
     "District": "Kaithal",
     "State": "Haryana"
    },
    {
     "Pin Code": 136044,
     "City": "Kaithal",
     "District": "Kaithal",
     "State": "Haryana"
    },
    {
     "Pin Code": 136117,
     "City": "Kaithal",
     "District": "Kaithal",
     "State": "Haryana"
    },
    {
     "Pin Code": 136118,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 136119,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 136128,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 136129,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 136130,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 136131,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 136132,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 136135,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 136136,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 136156,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 137103,
     "City": "Kurukshetra",
     "District": "Kurukshetra",
     "State": "Haryana"
    },
    {
     "Pin Code": 140001,
     "City": "Ropar",
     "District": "Ropar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140001,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140021,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140101,
     "City": "Ropar",
     "District": "Ropar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140101,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140102,
     "City": "Ropar",
     "District": "Ropar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140102,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140103,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140103,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140108,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140109,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140110,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140111,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140112,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140112,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140113,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140114,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140115,
     "City": "Ropar",
     "District": "Ropar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140115,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140116,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140117,
     "City": "Ropar",
     "District": "Ropar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140117,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140118,
     "City": "Ropar",
     "District": "Ropar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140119,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 140119,
     "City": "Ropar",
     "District": "Ropar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140119,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140123,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140124,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140125,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 140125,
     "City": "Ropar",
     "District": "Ropar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140125,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140126,
     "City": "Ropar",
     "District": "Ropar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140126,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140133,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 140133,
     "City": "Ropar",
     "District": "Ropar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140133,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140201,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140201,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140203,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140301,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140301,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140306,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140307,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140307,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140308,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140401,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140402,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140405,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 140406,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 140406,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140407,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 140408,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 140412,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 140412,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140413,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140413,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140417,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 140417,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140501,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140501,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140506,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140506,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140507,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140601,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140602,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 140602,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140602,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140603,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140603,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140603,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 140604,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140701,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140702,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140801,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 140802,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 140802,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 140901,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 140901,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 141000,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141001,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141002,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141003,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141004,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141005,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141006,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141007,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141008,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141009,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141010,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141012,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141013,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141014,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141015,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141016,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141017,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141101,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141102,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141103,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141104,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141105,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141106,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141107,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141108,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141109,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141110,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141112,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141113,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141114,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141115,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141116,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141117,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141118,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141119,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141120,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141121,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141122,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141123,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141124,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141125,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141126,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141127,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141201,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141202,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141203,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141204,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141205,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141206,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141302,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141303,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141308,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141309,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141311,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141316,
     "City": "Jalandhar",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141401,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141411,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 141411,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141411,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 141412,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141413,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141414,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141415,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141416,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141417,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141418,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141419,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141421,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 141421,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141422,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 141801,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 141801,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 142001,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142002,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142003,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142011,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142021,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142022,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142023,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142024,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142025,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142026,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142027,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142028,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 142029,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142030,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142031,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142032,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142033,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142034,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142035,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142036,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 142037,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142038,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142039,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142040,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142041,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 142042,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142043,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 142043,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142044,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 142045,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142046,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142047,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 142048,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142049,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142050,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 142051,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 142052,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 142053,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142054,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142055,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142056,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142057,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142058,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142059,
     "City": "Firozpur",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 142060,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143000,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143001,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143002,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143003,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143004,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143005,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143006,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143006,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143008,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143009,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143009,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143021,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143022,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143036,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143101,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143102,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143103,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143104,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143105,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143106,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143107,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143107,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143108,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143109,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143110,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143111,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143111,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143112,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143112,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143113,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143113,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143114,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143114,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143115,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143115,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143116,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143117,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143117,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143118,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143119,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143149,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143201,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143202,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143203,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143204,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143205,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143301,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143301,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143302,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143302,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143303,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143303,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143304,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143304,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143305,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143305,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143401,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143402,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143406,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143406,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143407,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143407,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143408,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143408,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143409,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143409,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143410,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143410,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143411,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143411,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143412,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143412,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143413,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143413,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143414,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143415,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143415,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143416,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143416,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143419,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143419,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143421,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143422,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143501,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143501,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143502,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143503,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143504,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143505,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143506,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143507,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143508,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143510,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143511,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143512,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143513,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143514,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143515,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143516,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143517,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143518,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143519,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143520,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143521,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143523,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143524,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143525,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143526,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143527,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143528,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143529,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143530,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143531,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143532,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143533,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143533,
     "City": "Pathankot",
     "District": "Pathankot",
     "State": "Punjab"
    },
    {
     "Pin Code": 143534,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143534,
     "City": "Pathankot",
     "District": "Pathankot",
     "State": "Punjab"
    },
    {
     "Pin Code": 143535,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143536,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143537,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143601,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143601,
     "City": "Tarn Taran",
     "District": "Tarn Taran",
     "State": "Punjab"
    },
    {
     "Pin Code": 143602,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143603,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 143604,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143605,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 143606,
     "City": "Amritsar",
     "District": "Amritsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144001,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144002,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144003,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144004,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144005,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144006,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144007,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144008,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144009,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144010,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144011,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144012,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144013,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144014,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144020,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144021,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144022,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144023,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144024,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144025,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144026,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144027,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144028,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144029,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144029,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144030,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144031,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144032,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144032,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144033,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144033,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144034,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144035,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144036,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144037,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144039,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144040,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144041,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144042,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144043,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144044,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144101,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144102,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144103,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144104,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144105,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144106,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144201,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144202,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144203,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144204,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144205,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144206,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144207,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144208,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144209,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144210,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144211,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144212,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144213,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144214,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144216,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144221,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144222,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144223,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144224,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144224,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144301,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144302,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144303,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144304,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144305,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144306,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144311,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144401,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144402,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144402,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144403,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144403,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144404,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144405,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144406,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144407,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144408,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144409,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144410,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144411,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144415,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144416,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144417,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144417,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144418,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144419,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144421,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144422,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144501,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144502,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144502,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144503,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144504,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144505,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144506,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144507,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144508,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144509,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144510,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144511,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144511,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144512,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144513,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144514,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144515,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144516,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144517,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144518,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144519,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144520,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144521,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144521,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144522,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144522,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144523,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144524,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144525,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144525,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144526,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144527,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144527,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144528,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144529,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144530,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144531,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144532,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 144532,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144533,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 144601,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144602,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144603,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144606,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144619,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144620,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144621,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144622,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144623,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144623,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144624,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144625,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144626,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144628,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144629,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144629,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144630,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144631,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144632,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144633,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144701,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144702,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144702,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144703,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144801,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144802,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144803,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144804,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 144805,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144806,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 144819,
     "City": "Kapurthala",
     "District": "Kapurthala",
     "State": "Punjab"
    },
    {
     "Pin Code": 145001,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145001,
     "City": "Pathankot",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145002,
     "City": "Jalandhar",
     "District": "Jalandhar",
     "State": "Punjab"
    },
    {
     "Pin Code": 145021,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145022,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145022,
     "City": "Pathankot",
     "District": "Pathankot",
     "State": "Punjab"
    },
    {
     "Pin Code": 145023,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145023,
     "City": "Pathankot",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145023,
     "City": "Pathankot",
     "District": "Pathankot",
     "State": "Punjab"
    },
    {
     "Pin Code": 145024,
     "City": "Pathankot",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145024,
     "City": "Pathankot",
     "District": "Pathankot",
     "State": "Punjab"
    },
    {
     "Pin Code": 145025,
     "City": "Pathankot",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145025,
     "City": "Pathankot",
     "District": "Pathankot",
     "State": "Punjab"
    },
    {
     "Pin Code": 145026,
     "City": "Pathankot",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145026,
     "City": "Pathankot",
     "District": "Pathankot",
     "State": "Punjab"
    },
    {
     "Pin Code": 145027,
     "City": "Pathankot",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145027,
     "City": "Pathankot",
     "District": "Pathankot",
     "State": "Punjab"
    },
    {
     "Pin Code": 145029,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145029,
     "City": "Pathankot",
     "District": "Pathankot",
     "State": "Punjab"
    },
    {
     "Pin Code": 145101,
     "City": "Gurdaspur",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145101,
     "City": "Pathankot",
     "District": "Gurdaspur",
     "State": "Punjab"
    },
    {
     "Pin Code": 145101,
     "City": "Pathankot",
     "District": "Pathankot",
     "State": "Punjab"
    },
    {
     "Pin Code": 146001,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146021,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146022,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146023,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146024,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146101,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146102,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146103,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146104,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146105,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146106,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146107,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146108,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146109,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146110,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146111,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146112,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146113,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146114,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146115,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 146115,
     "City": "Nawanshahr",
     "District": "Nawanshahr",
     "State": "Punjab"
    },
    {
     "Pin Code": 146116,
     "City": "Hoshiarpur",
     "District": "Hoshiarpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 147001,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147002,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147003,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147004,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147005,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147006,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147007,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147021,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147101,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147102,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147103,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147104,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147105,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147111,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147201,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147202,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147203,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 147203,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147204,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147205,
     "City": "Patiala",
     "District": "Patiala",
     "State": "Punjab"
    },
    {
     "Pin Code": 147301,
     "City": "Fatehgarh Sahib",
     "District": "Fatehgarh Sahib",
     "State": "Punjab"
    },
    {
     "Pin Code": 148001,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148002,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148005,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148017,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148018,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148019,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148020,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148021,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148022,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148023,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148024,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148024,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148025,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148025,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148026,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148027,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148028,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148029,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148030,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148031,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148032,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148033,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148034,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148035,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148036,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148038,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148100,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148101,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148102,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148102,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148103,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148104,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148105,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148106,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148106,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148107,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148107,
     "City": "Ludhiana",
     "District": "Ludhiana",
     "State": "Punjab"
    },
    {
     "Pin Code": 148108,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148108,
     "City": "Sangrur",
     "District": "Sangrur",
     "State": "Punjab"
    },
    {
     "Pin Code": 148109,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148110,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148112,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148113,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148115,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 148116,
     "City": "Barnala",
     "District": "Barnala",
     "State": "Punjab"
    },
    {
     "Pin Code": 151001,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151002,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151003,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151004,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151005,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151101,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151102,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151103,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151104,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151105,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151106,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151108,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151110,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151111,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151201,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151202,
     "City": "Faridkot",
     "District": "Faridkot",
     "State": "Punjab"
    },
    {
     "Pin Code": 151202,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 151203,
     "City": "Faridkot",
     "District": "Faridkot",
     "State": "Punjab"
    },
    {
     "Pin Code": 151204,
     "City": "Faridkot",
     "District": "Faridkot",
     "State": "Punjab"
    },
    {
     "Pin Code": 151205,
     "City": "Faridkot",
     "District": "Faridkot",
     "State": "Punjab"
    },
    {
     "Pin Code": 151206,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151207,
     "City": "Faridkot",
     "District": "Faridkot",
     "State": "Punjab"
    },
    {
     "Pin Code": 151208,
     "City": "Faridkot",
     "District": "Faridkot",
     "State": "Punjab"
    },
    {
     "Pin Code": 151208,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 151209,
     "City": "Faridkot",
     "District": "Faridkot",
     "State": "Punjab"
    },
    {
     "Pin Code": 151210,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 151211,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 151212,
     "City": "Faridkot",
     "District": "Faridkot",
     "State": "Punjab"
    },
    {
     "Pin Code": 151213,
     "City": "Faridkot",
     "District": "Faridkot",
     "State": "Punjab"
    },
    {
     "Pin Code": 151301,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151302,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151401,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151501,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151501,
     "City": "Mansa",
     "District": "Mansa",
     "State": "Punjab"
    },
    {
     "Pin Code": 151502,
     "City": "Mansa",
     "District": "Mansa",
     "State": "Punjab"
    },
    {
     "Pin Code": 151503,
     "City": "Mansa",
     "District": "Mansa",
     "State": "Punjab"
    },
    {
     "Pin Code": 151504,
     "City": "Mansa",
     "District": "Mansa",
     "State": "Punjab"
    },
    {
     "Pin Code": 151505,
     "City": "Mansa",
     "District": "Mansa",
     "State": "Punjab"
    },
    {
     "Pin Code": 151506,
     "City": "Mansa",
     "District": "Mansa",
     "State": "Punjab"
    },
    {
     "Pin Code": 151507,
     "City": "Mansa",
     "District": "Mansa",
     "State": "Punjab"
    },
    {
     "Pin Code": 151508,
     "City": "Mansa",
     "District": "Mansa",
     "State": "Punjab"
    },
    {
     "Pin Code": 151509,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151509,
     "City": "Mansa",
     "District": "Mansa",
     "State": "Punjab"
    },
    {
     "Pin Code": 151510,
     "City": "Bathinda",
     "District": "Bathinda",
     "State": "Punjab"
    },
    {
     "Pin Code": 151510,
     "City": "Mansa",
     "District": "Mansa",
     "State": "Punjab"
    },
    {
     "Pin Code": 151511,
     "City": "Mansa",
     "District": "Mansa",
     "State": "Punjab"
    },
    {
     "Pin Code": 152001,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152002,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152003,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152004,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152005,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152021,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152022,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152022,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 152023,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152024,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152025,
     "City": "Faridkot",
     "District": "Faridkot",
     "State": "Punjab"
    },
    {
     "Pin Code": 152025,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 152026,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 152028,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152028,
     "City": "Moga",
     "District": "Moga",
     "State": "Punjab"
    },
    {
     "Pin Code": 152031,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 152032,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 152033,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152101,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 152103,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 152107,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 152112,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 152113,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 152114,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 152115,
     "City": "Muktsar",
     "District": "Muktsar",
     "State": "Punjab"
    },
    {
     "Pin Code": 152116,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152117,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152118,
     "City": "Fazilka",
     "District": "Fazilka",
     "State": "Punjab"
    },
    {
     "Pin Code": 152121,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152122,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152123,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152124,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152126,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152127,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152128,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152130,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152131,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 152132,
     "City": "Firozpur",
     "District": "Firozpur",
     "State": "Punjab"
    },
    {
     "Pin Code": 160001,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160002,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160003,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160003,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 160004,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160005,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160006,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160007,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160008,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160009,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160010,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160011,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160012,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160014,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160014,
     "City": "Rupnagar",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160015,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160016,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160017,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160018,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160019,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160020,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160022,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160023,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160025,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160026,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160027,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160030,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160031,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160032,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160034,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160035,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160036,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160041,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160043,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160044,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160047,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160055,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160056,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160057,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160058,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160059,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160061,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160062,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160063,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160064,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160065,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160066,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160069,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160071,
     "City": "Chandigarh",
     "District": "Rupnagar",
     "State": "Punjab"
    },
    {
     "Pin Code": 160101,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160102,
     "City": "Chandigarh",
     "District": "Chandigarh",
     "State": "Chandigarh"
    },
    {
     "Pin Code": 160103,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 160104,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 160106,
     "City": "Mohali",
     "District": "Mohali",
     "State": "Punjab"
    },
    {
     "Pin Code": 170012,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171001,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171002,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171003,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171004,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171005,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171006,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171007,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171008,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171009,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171010,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171011,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171012,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171013,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171018,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171019,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171102,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171103,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171201,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171202,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171203,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171204,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171205,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171206,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171207,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171208,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171209,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171210,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171211,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171212,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171213,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171214,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171215,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171216,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171217,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171218,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171219,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171220,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171221,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171222,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171223,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171224,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171225,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171226,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171226,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171301,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171302,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171303,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 171304,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172001,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172001,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172002,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172021,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172022,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172023,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172024,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172025,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172026,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172027,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172028,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172029,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172030,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172030,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172031,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172032,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172033,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172034,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172101,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172101,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172102,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172103,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172104,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172105,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172106,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172107,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172108,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172109,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172110,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172111,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172112,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172113,
     "City": "Lahul and Spiti",
     "District": "Lahul and Spiti",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172114,
     "City": "Lahul and Spiti",
     "District": "Lahul and Spiti",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172115,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172116,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172117,
     "City": "Lahul and Spiti",
     "District": "Lahul and Spiti",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172118,
     "City": "Kinnaur",
     "District": "Kinnaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172201,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 172201,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173001,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173020,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173021,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173022,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173023,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173024,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173025,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173026,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173027,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173028,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173029,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173030,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173031,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173032,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173101,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173102,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173104,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173201,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173202,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173203,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173204,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173205,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173206,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173207,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173208,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173209,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173210,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173211,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173212,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173213,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173214,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173215,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173217,
     "City": "Shimla",
     "District": "Shimla",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173217,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173218,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173219,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173220,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173221,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173222,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173223,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173223,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173225,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173227,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173229,
     "City": "Sirmaur",
     "District": "Sirmaur",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173229,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173230,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173233,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173234,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 173235,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174001,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174002,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174003,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174004,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174005,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174011,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174012,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174013,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174015,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174017,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174021,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174023,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174024,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174026,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174027,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174028,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174029,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174030,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174031,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174032,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174033,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174034,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174035,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174036,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174101,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174102,
     "City": "Nalagarh",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174103,
     "City": "Solan",
     "District": "Solan",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174201,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174301,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174302,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174303,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174304,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174305,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174306,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174307,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174308,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174309,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174310,
     "City": "Bilaspur (HP)",
     "District": "Bilaspur (HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174311,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174312,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174313,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174314,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174315,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174316,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174317,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174319,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174320,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174321,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174401,
     "City": "Sundarnagar",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174402,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174405,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174503,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 174505,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175001,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175002,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175003,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175004,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175005,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175006,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175007,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175008,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175009,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175010,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175011,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175012,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175013,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175013,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175014,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175015,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175016,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175017,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175018,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175019,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175021,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175023,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175024,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175025,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175026,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175027,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175028,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175029,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175030,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175031,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175032,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175033,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175034,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175035,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175036,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175037,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175038,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175039,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175040,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175042,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175045,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175046,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175047,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175048,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175049,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175050,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175051,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175052,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175101,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175102,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175103,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175104,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175105,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175106,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175106,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175121,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175122,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175123,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175123,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175124,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175125,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175125,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175126,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175128,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175129,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175130,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175131,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175132,
     "City": "Lahul and Spiti",
     "District": "Lahul and Spiti",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175133,
     "City": "Lahul and Spiti",
     "District": "Lahul and Spiti",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175134,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175134,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175136,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175137,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175138,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175139,
     "City": "Lahul and Spiti",
     "District": "Lahul and Spiti",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175140,
     "City": "Lahul and Spiti",
     "District": "Lahul and Spiti",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175141,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175141,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175142,
     "City": "Lahul and Spiti",
     "District": "Lahul and Spiti",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 175143,
     "City": "Kullu",
     "District": "Kullu",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176001,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176016,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176021,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176022,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176023,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176024,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176025,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176026,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176027,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176028,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176029,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176030,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176031,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176032,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176033,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176036,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176037,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176038,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176039,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176040,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176041,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176042,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176043,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176044,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176045,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176047,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176048,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176049,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176051,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176052,
     "City": "Dharamshala",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176052,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176052,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176053,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176054,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176055,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176056,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176057,
     "City": "Dharamshala",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176058,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176059,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176060,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176061,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176062,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176063,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176064,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176065,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176066,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176071,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176073,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176075,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176076,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176077,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176081,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176082,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176083,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176084,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176085,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176086,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176086,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176087,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176087,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176088,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176088,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176089,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176090,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176091,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176092,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176093,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176094,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176095,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176096,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176097,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176098,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176101,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176101,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176102,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176103,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176103,
     "City": "Palampur",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176107,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176108,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176109,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176110,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176111,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176115,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176120,
     "City": "Mandi",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176121,
     "City": "Padhar",
     "District": "Mandi",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176122,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176123,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176125,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176126,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176128,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176200,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176201,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176202,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176203,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176204,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176205,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176206,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176207,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176208,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176209,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176210,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176211,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176213,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176214,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176215,
     "City": "Dharamshala",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176215,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176216,
     "City": "Dharamshala",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176217,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176218,
     "City": "Dharamshala",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176219,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176222,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176225,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176301,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176302,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176303,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176304,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176304,
     "City": "Dalhousie",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176305,
     "City": "Dalhousie",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176306,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176308,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176309,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176310,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176311,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176312,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176313,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176314,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176315,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176316,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176317,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176318,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176319,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176320,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176321,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176323,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176324,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176325,
     "City": "Chamba",
     "District": "Chamba",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176401,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176402,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176403,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176501,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176502,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 176601,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177001,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177004,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177005,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177006,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177007,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177020,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177021,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177022,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177023,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177024,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177025,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177026,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177027,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177028,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177029,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177030,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177031,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177032,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177033,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177033,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177034,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177038,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177039,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177040,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177041,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177041,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177042,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177043,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177044,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177045,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177046,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177048,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177101,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177103,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177104,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177105,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177106,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177107,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177108,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177109,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177110,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177110,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177111,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177112,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177113,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177114,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177116,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177117,
     "City": "Kangra",
     "District": "Kangra",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177118,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177119,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177201,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177202,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177203,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177204,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177205,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177206,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177207,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177208,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177209,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177210,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177211,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177212,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177213,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177219,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177220,
     "City": "Una",
     "District": "Una",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177301,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177401,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177501,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 177601,
     "City": "Hamirpur(HP)",
     "District": "Hamirpur(HP)",
     "State": "Himachal Pradesh"
    },
    {
     "Pin Code": 180001,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180002,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180003,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180004,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180005,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180006,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180007,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180009,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180010,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180011,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180012,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180013,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180015,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180016,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180017,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180018,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180019,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 180020,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181101,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181102,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181111,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181121,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181122,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181123,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181124,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181131,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181132,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181133,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181141,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181143,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181145,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181152,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181201,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181202,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181203,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181204,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181205,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181206,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181207,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181221,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181224,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 181401,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182101,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182104,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182121,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182122,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182124,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182124,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182125,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182126,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182127,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182128,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182141,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182142,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182143,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182144,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182144,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182145,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182146,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182147,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182148,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182161,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182201,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182202,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182203,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182204,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182204,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182205,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182206,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182221,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182222,
     "City": "Doda",
     "District": "Doda",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182301,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182311,
     "City": "Reasi",
     "District": "Reasi",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182311,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182312,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182313,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182315,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182320,
     "City": "Reasi",
     "District": "Reasi",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 182320,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184101,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184102,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184104,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184120,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184121,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184121,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184121,
     "City": "Poonch",
     "District": "Poonch",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184121,
     "City": "Jammu",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184141,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184142,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184143,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184144,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184145,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184148,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184151,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184152,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184201,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184202,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184203,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184204,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184205,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184205,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 184206,
     "City": "Kathua",
     "District": "Kathua",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185101,
     "City": "Poonch",
     "District": "Poonch",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185102,
     "City": "Poonch",
     "District": "Poonch",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185121,
     "City": "Poonch",
     "District": "Poonch",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185121,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185131,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185132,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185135,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185151,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185152,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185153,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185154,
     "City": "Jammu",
     "District": "Jammu",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185155,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185156,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185201,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185202,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185203,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185203,
     "City": "Udhampur",
     "District": "Udhampur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185211,
     "City": "Poonch",
     "District": "Poonch",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185212,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185233,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185233,
     "City": "Reasi",
     "District": "Reasi",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 185234,
     "City": "Rajauri",
     "District": "Rajauri",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190000,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190001,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190002,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190003,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190004,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190005,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190006,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190007,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190008,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190009,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190010,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190011,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190012,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190014,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190015,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190015,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190017,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190018,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190019,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190019,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190020,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190021,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190023,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190024,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190025,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 190210,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191101,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191102,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191103,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191103,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191111,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191112,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191112,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191113,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191121,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191131,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191132,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191201,
     "City": "Ganderbal",
     "District": "Ganderbal",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191202,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 191203,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192101,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192102,
     "City": "Qazigund",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192121,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192121,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192121,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192121,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192122,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192122,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192123,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192124,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192124,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192125,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192125,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192126,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192129,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192201,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192202,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192210,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192211,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192211,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192212,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192221,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192221,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192230,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192231,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192231,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192232,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192233,
     "City": "Kulgam",
     "District": "Kulgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192301,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192302,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192303,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192304,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192305,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192305,
     "City": "Shopian",
     "District": "Shopian",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192306,
     "City": "Pulwama",
     "District": "Pulwama",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 192401,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193101,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193103,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193108,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193121,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193122,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193123,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193201,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193202,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193221,
     "City": "Kupwara",
     "District": "Kupwara",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193222,
     "City": "Kupwara",
     "District": "Kupwara",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193223,
     "City": "Kupwara",
     "District": "Kupwara",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193224,
     "City": "Kupwara",
     "District": "Kupwara",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193225,
     "City": "Kupwara",
     "District": "Kupwara",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193301,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193302,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193302,
     "City": "Kupwara",
     "District": "Kupwara",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193303,
     "City": "Ananthnag",
     "District": "Ananthnag",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193303,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193303,
     "City": "Kupwara",
     "District": "Kupwara",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193401,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193401,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193401,
     "City": "Srinagar",
     "District": "Srinagar",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193402,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193403,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193404,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193411,
     "City": "Budgam",
     "District": "Budgam",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193501,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193502,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193503,
     "City": "Bandipur",
     "District": "Bandipur",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193503,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 193504,
     "City": "Baramulla",
     "District": "Baramulla",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194101,
     "City": "Leh",
     "District": "Leh",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194102,
     "City": "Kargil",
     "District": "Kargil",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194103,
     "City": "Kargil",
     "District": "Kargil",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194104,
     "City": "Leh",
     "District": "Leh",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194105,
     "City": "Kargil",
     "District": "Kargil",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194105,
     "City": "Leh",
     "District": "Leh",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194106,
     "City": "Leh",
     "District": "Leh",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194109,
     "City": "Kargil",
     "District": "Kargil",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194201,
     "City": "Leh",
     "District": "Leh",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194301,
     "City": "Kargil",
     "District": "Kargil",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194302,
     "City": "Kargil",
     "District": "Kargil",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194401,
     "City": "Leh",
     "District": "Leh",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194402,
     "City": "Leh",
     "District": "Leh",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194403,
     "City": "Kargil",
     "District": "Kargil",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 194404,
     "City": "Leh",
     "District": "Leh",
     "State": "Jammu and Kashmir"
    },
    {
     "Pin Code": 200016,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201001,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201002,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201003,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201004,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201005,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201006,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201007,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201008,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201008,
     "City": "Noida",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201009,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201010,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201011,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201012,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201013,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201014,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201015,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201016,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201017,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201018,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201019,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201031,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201101,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201102,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201103,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201201,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201202,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201204,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201206,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201301,
     "City": "Noida",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201301,
     "City": "Noida",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201302,
     "City": "Noida",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201303,
     "City": "Noida",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201303,
     "City": "Noida",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201304,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201304,
     "City": "Noida",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201305,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201305,
     "City": "Noida",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201306,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201306,
     "City": "Noida",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201307,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201307,
     "City": "Noida",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201308,
     "City": "Noida",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201309,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201309,
     "City": "Noida",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201310,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201310,
     "City": "Noida",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201311,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201312,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201313,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201314,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201316,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201403,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 201505,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202001,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202002,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202002,
     "City": "Hathras",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202121,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202121,
     "City": "Hathras",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202122,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202123,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202124,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202124,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202125,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202126,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202127,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202128,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202129,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202130,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202131,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202132,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202133,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202134,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202135,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202136,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202137,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202138,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202139,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202139,
     "City": "Hathras",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202140,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202141,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202142,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202143,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202145,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202146,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202150,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202155,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202165,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202170,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202280,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202281,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202282,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202389,
     "City": "Debai",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202390,
     "City": "Debai",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202391,
     "City": "Debai",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202392,
     "City": "Debai",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202393,
     "City": "Debai",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202394,
     "City": "Debai",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202395,
     "City": "Debai",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202396,
     "City": "Debai",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202397,
     "City": "Debai",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202413,
     "City": "Kundarki",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202414,
     "City": "Kundarki",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 202415,
     "City": "Kundarki",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203001,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203002,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203129,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203131,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203131,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203132,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203135,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203141,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203141,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203150,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203155,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203155,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203201,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203201,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203202,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203202,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203203,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203203,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203205,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203206,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203207,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203208,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203209,
     "City": "Gautam Buddha Nagar",
     "District": "Gautam Buddha Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203389,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203390,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203391,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203392,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203393,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203394,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203395,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203396,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203397,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203398,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203399,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203401,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203402,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203403,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203405,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203407,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203408,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203409,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203411,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 203412,
     "City": "Bulandshahr",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204101,
     "City": "Hathras",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204102,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204102,
     "City": "Hathras",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204211,
     "City": "Aligarh",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204211,
     "City": "Hathras",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204212,
     "City": "Aligarh",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204213,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204213,
     "City": "Aligarh",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204214,
     "City": "Aligarh",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204214,
     "City": "Hathras",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204215,
     "City": "Aligarh",
     "District": "Aligarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204215,
     "City": "Aligarh",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 204216,
     "City": "Aligarh",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205001,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205119,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205121,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205135,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205142,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205147,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205152,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205247,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205261,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205262,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205262,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205263,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205264,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205265,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205267,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205268,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205301,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205302,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205303,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 205304,
     "City": "Mainpuri",
     "District": "Mainpuri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206001,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206002,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206003,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206016,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206120,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206121,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206122,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206123,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206124,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206125,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206126,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206127,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206128,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206129,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206130,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206131,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206241,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206242,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206243,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206244,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206245,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206246,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206247,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206248,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206249,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206250,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206251,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206252,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206253,
     "City": "Etawah",
     "District": "Etawah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206255,
     "City": "Auraiya",
     "District": "Auraiya",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206301,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206302,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206303,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 206451,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207001,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207002,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207003,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207120,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207121,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207122,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207123,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207124,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207125,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207241,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207242,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207243,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207244,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207245,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207246,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207247,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207248,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207249,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207250,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207301,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207301,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207302,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207401,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207402,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207403,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207501,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207502,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207504,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 207505,
     "City": "Etah",
     "District": "Etah",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208001,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208001,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208002,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208003,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208004,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208005,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208006,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208007,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208008,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208009,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208010,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208011,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208012,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208013,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208014,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208015,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208016,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208017,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208018,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208019,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208020,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208021,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208022,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208023,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208024,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208025,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208026,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 208027,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209101,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209101,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209111,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209112,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209115,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209121,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209125,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209201,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209202,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209203,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209203,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209204,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209205,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209206,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209208,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209209,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209210,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209214,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209217,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209301,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209302,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209303,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209304,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209305,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209306,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209307,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209308,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209310,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209311,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209312,
     "City": "Kanpur Dehat",
     "District": "Kanpur Dehat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209401,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209402,
     "City": "Kanpur Nagar",
     "District": "Kanpur Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209501,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209502,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209503,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209504,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209505,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209601,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209602,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209621,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209622,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209625,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209651,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209652,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209715,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209720,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209721,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209721,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209722,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209723,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209724,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209725,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209726,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209727,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209728,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209729,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209731,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209732,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209733,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209734,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209735,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209736,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209738,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209739,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209743,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209745,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209747,
     "City": "Kannauj",
     "District": "Kannauj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209749,
     "City": "Farrukhabad",
     "District": "Farrukhabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209801,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209821,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209825,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209827,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209831,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209841,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209859,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209861,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209862,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209863,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209864,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209865,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209866,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209867,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209868,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209869,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209870,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 209871,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210001,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210005,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210010,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210120,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210121,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210123,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210125,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210126,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210128,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210129,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210201,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210202,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210202,
     "City": "Chitrakoot",
     "District": "Chitrakoot",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210203,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210203,
     "City": "Chitrakoot",
     "District": "Chitrakoot",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210204,
     "City": "Chitrakoot",
     "District": "Chitrakoot",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210205,
     "City": "Chitrakoot",
     "District": "Chitrakoot",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210206,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210206,
     "City": "Chitrakoot",
     "District": "Chitrakoot",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210207,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210207,
     "City": "Chitrakoot",
     "District": "Chitrakoot",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210208,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210208,
     "City": "Chitrakoot",
     "District": "Chitrakoot",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210209,
     "City": "Banda",
     "District": "Banda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210209,
     "City": "Chitrakoot",
     "District": "Chitrakoot",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210301,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210341,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210421,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210421,
     "City": "Hamirpur",
     "District": "Mahoba",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210422,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210423,
     "City": "Hamirpur",
     "District": "Mahoba",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210424,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210424,
     "City": "Hamirpur",
     "District": "Mahoba",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210425,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210425,
     "City": "Mahoba",
     "District": "Mahoba",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210426,
     "City": "Mahoba",
     "District": "Mahoba",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210427,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210427,
     "City": "Hamirpur",
     "District": "Mahoba",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210428,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210429,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210429,
     "City": "Hamirpur",
     "District": "Mahoba",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210430,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210431,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210431,
     "City": "Hamirpur",
     "District": "Mahoba",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210432,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210433,
     "City": "Mahoba",
     "District": "Mahoba",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210501,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210502,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210504,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210504,
     "City": "Mahoba",
     "District": "Mahoba",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210505,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210506,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210507,
     "City": "Hamirpur",
     "District": "Hamirpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 210507,
     "City": "Mahoba",
     "District": "Mahoba",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211001,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211002,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211003,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211004,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211005,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211006,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211007,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211008,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211009,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211010,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211011,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211011,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211012,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211013,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211014,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211015,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211016,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211017,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211018,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 211019,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212104,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212105,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212106,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212107,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212108,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212109,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212111,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212201,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212201,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212202,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212203,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212203,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212204,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212204,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212205,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212206,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212207,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212208,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212208,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212212,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212213,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212213,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212214,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212216,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212216,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212217,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212217,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212218,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212218,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212301,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212302,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212303,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212305,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212306,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212307,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212401,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212401,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212402,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212404,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212405,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212502,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212503,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212507,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212507,
     "City": "Kaushambi",
     "District": "Kaushambi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212601,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212620,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212621,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212622,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212631,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212635,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212641,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212645,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212650,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212651,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212652,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212653,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212654,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212655,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212656,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212657,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212658,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212659,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212661,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212663,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212664,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 212665,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 216221,
     "City": "Fatehpur",
     "District": "Fatehpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221001,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221002,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221003,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221004,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221005,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221006,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221007,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221008,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221009,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221010,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221011,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221100,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221101,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221102,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221103,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221104,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221105,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221106,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221107,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221108,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221109,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221110,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221112,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221115,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221115,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221116,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221201,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221202,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221204,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221206,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221207,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221208,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221209,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221210,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221301,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221302,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221303,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221304,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221304,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221305,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221306,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221306,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221307,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221308,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221309,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221310,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221311,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221313,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221314,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221401,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221401,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221402,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221402,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221403,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221404,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221405,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221406,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221409,
     "City": "Sant Ravidas Nagar",
     "District": "Sant Ravidas Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221502,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221503,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221505,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221507,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221508,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221601,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221602,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221602,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221603,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221603,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221701,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221705,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221706,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221706,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221709,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221711,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221712,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221713,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221715,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221716,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221717,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 221718,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222001,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222002,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222003,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222101,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222103,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222105,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222107,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222108,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222109,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222113,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222125,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222127,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222128,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222129,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222131,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222132,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222133,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222135,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222136,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222137,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222138,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222139,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222141,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222142,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222143,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222144,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222145,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222146,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222148,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222149,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222161,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222162,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222165,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222170,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222175,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222180,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222181,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222201,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222202,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222203,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222204,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222301,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222301,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222302,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 222303,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223101,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223102,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223103,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223104,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223105,
     "City": "Jaunpur",
     "District": "Jaunpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223107,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223221,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223222,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223222,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223223,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223224,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223225,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223226,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 223227,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224001,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224116,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224116,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224117,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224117,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224118,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224118,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224119,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224119,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224120,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224120,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224121,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224121,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224122,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224123,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224123,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224125,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224126,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224127,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224127,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224129,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224132,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224133,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224135,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224135,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224137,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224139,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224141,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224141,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224143,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224145,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224146,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224147,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224149,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224151,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224152,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224152,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224153,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224153,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224155,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224157,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224158,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224158,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224159,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224161,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224161,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224164,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224164,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224168,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224171,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224171,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224172,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224176,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224181,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224182,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224182,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224183,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224186,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224188,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224189,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224189,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224190,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224195,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224195,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224201,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224201,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224202,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224202,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224203,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224203,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224204,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224205,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224205,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224206,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224206,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224207,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224207,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224208,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224208,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224209,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224209,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224210,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224225,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224227,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224228,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224228,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224229,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224229,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224230,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224231,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224232,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224234,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224234,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224235,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224238,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224284,
     "City": "Ambedkar Nagar",
     "District": "Ambedkar Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 224284,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225001,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225002,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225003,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225119,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225120,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225121,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225122,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225123,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225124,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225125,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225126,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225201,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225202,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225203,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225204,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225205,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225206,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225207,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225208,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225301,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225302,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225303,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225304,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225305,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225306,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225401,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225402,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225403,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225404,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225405,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225406,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225407,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225408,
     "City": "Faizabad",
     "District": "Faizabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225409,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225411,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225412,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225413,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225414,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225415,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225416,
     "City": "Barabanki",
     "District": "Barabanki",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 225441,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226001,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226002,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226003,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226004,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226005,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226006,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226007,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226008,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226009,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226010,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226011,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226012,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226013,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226014,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226015,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226016,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226017,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226018,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226019,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226020,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226021,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226022,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226023,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226024,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226025,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226026,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226027,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226028,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226029,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226030,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226031,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226101,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226102,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226103,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226104,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226201,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226202,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226203,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226301,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226302,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226303,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226304,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226401,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 226501,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227101,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227105,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227107,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227111,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227115,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227116,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227125,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227131,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227201,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227202,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227205,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227301,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227302,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227304,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227305,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227308,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227309,
     "City": "Lucknow",
     "District": "Lucknow",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227405,
     "City": "Amethi",
     "District": "Amethi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227406,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227407,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227408,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227409,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227411,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227412,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227413,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227801,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227805,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227806,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227807,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227808,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227809,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227811,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227812,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227813,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227814,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227815,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227816,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 227817,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228001,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228118,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228119,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228120,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228121,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228125,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228131,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228132,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228133,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228141,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228142,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228145,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228151,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228155,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228159,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228161,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228171,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 228411,
     "City": "Sultanpur",
     "District": "Sultanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229001,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229010,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229103,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229120,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229121,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229122,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229123,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229124,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229125,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229126,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229127,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229128,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229129,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229130,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229135,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229201,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229202,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229203,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229204,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229205,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229206,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229207,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229208,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229209,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229210,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229211,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229212,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229215,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229216,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229301,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229302,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229303,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229304,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229305,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229306,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229307,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229308,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229309,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229310,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229311,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229316,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229401,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229402,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229404,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229405,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229406,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229408,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229410,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229411,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229412,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229413,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229501,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229504,
     "City": "Allahabad",
     "District": "Allahabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229801,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229802,
     "City": "Raebareli",
     "District": "Raebareli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229841,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 229881,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230001,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230002,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230121,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230124,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230125,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230126,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230127,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230128,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230129,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230130,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230131,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230132,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230134,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230135,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230136,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230137,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230138,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230139,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230141,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230142,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230143,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230144,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230201,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230202,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230204,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230301,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230302,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230304,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230306,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230401,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230402,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230403,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230404,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230405,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230501,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230502,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 230503,
     "City": "Pratapgarh (UP)",
     "District": "Pratapgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231001,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231002,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231205,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231206,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231207,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231208,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231209,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231210,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231210,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231211,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231212,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231213,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231215,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231216,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231217,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231218,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231219,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231220,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231221,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231222,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231223,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231224,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231225,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231226,
     "City": "Sonbhadra",
     "District": "Sonbhadra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231301,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231302,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231303,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231304,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231305,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231306,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231307,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231309,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231311,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231312,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231313,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231314,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 231501,
     "City": "Mirzapur",
     "District": "Mirzapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232001,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232101,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232101,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232102,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232102,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232103,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232103,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232104,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232104,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232105,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232105,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232106,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232106,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232107,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232107,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232108,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232108,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232109,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232109,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232110,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232110,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232111,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232111,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232115,
     "City": "Varanasi",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232118,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232118,
     "City": "Varanasi",
     "District": "Varanasi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232120,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232221,
     "City": "Chandauli",
     "District": "Chandauli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232325,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232326,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232327,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232328,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232329,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232330,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232331,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232332,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232333,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232336,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232339,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 232340,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233001,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233002,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233221,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233222,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233223,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233224,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233225,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233226,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233227,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233228,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233229,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233230,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233231,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233232,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233233,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233300,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233301,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233302,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233303,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233304,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233305,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233306,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233307,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233310,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 233311,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241001,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241121,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241122,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241123,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241124,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241125,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241126,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241127,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241201,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241202,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241203,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241204,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241301,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241302,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241303,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241304,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241305,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241401,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241402,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241403,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241404,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241405,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241406,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241407,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241502,
     "City": "Hardoi",
     "District": "Hardoi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 241503,
     "City": "Unnao",
     "District": "Unnao",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242001,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242021,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242042,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242123,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242127,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242220,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242221,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242223,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242226,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242301,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242303,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242305,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242306,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242307,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242401,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242405,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242406,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242407,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 242605,
     "City": "Shahjahanpur",
     "District": "Shahjahanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243001,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243002,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243003,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243003,
     "City": "Pilibhit",
     "District": "Pilibhit",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243004,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243005,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243006,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243122,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243123,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243123,
     "City": "Pilibhit",
     "District": "Pilibhit",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243126,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243201,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243202,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243203,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243301,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243302,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243303,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243401,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243402,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243403,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243407,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243501,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243502,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243503,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243504,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243505,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243506,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243601,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243630,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243631,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243632,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243633,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243634,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243635,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243636,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243637,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243638,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243639,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243641,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243701,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243720,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243722,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243723,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243724,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243725,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243726,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243727,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 243751,
     "City": "Budaun",
     "District": "Budaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244001,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244102,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244102,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244103,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244104,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244221,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244222,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244223,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244225,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244231,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244235,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244235,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244236,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244241,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244242,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244245,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244251,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244255,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244301,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244302,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244303,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244304,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244304,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244401,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244402,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244410,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244411,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244412,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244413,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244414,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244415,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244501,
     "City": "Jyotiba Phule Nagar",
     "District": "Jyotiba Phule Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244501,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244504,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244601,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244602,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244701,
     "City": "Rampur",
     "District": "Rampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244711,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 244712,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 244713,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 244713,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 244714,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 244715,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 244715,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 244715,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 244716,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 244717,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 244901,
     "City": "Rampur",
     "District": "Rampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244921,
     "City": "Rampur",
     "District": "Rampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244922,
     "City": "Rampur",
     "District": "Rampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244923,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244923,
     "City": "Rampur",
     "District": "Rampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244924,
     "City": "Rampur",
     "District": "Rampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244925,
     "City": "Moradabad",
     "District": "Moradabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244925,
     "City": "Rampur",
     "District": "Rampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244926,
     "City": "Rampur",
     "District": "Rampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244927,
     "City": "Rampur",
     "District": "Rampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 244928,
     "City": "Rampur",
     "District": "Rampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245101,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245201,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245205,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245206,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245207,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245208,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245301,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245304,
     "City": "Ghaziabad",
     "District": "Ghaziabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245401,
     "City": "Gulaothi",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245402,
     "City": "Gulaothi",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245403,
     "City": "Gulaothi",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245405,
     "City": "Gulaothi",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245408,
     "City": "Gulaothi",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 245412,
     "City": "Gulaothi",
     "District": "Bulandshahr",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246001,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246113,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246121,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246123,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246124,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246125,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246127,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246128,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246129,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246130,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246131,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246141,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246142,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246144,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246146,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246147,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246148,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246149,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246155,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246159,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246161,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246162,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246163,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246164,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246165,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246166,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246167,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246169,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246171,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246172,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246173,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246174,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246175,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246176,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246177,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246179,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246186,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246189,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246192,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246193,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246194,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246275,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246276,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246277,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246278,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246279,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246285,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246401,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246419,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246421,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246422,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246424,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246425,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246426,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246427,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246428,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246429,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246429,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246431,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246435,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246439,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246440,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246441,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246442,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246443,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246444,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246445,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246446,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246448,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246449,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246453,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246455,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246469,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246471,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246472,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246473,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246474,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246475,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246481,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246482,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246483,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246486,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246487,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246488,
     "City": "Chamoli",
     "District": "Chamoli",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246495,
     "City": "Rudraprayag",
     "District": "Rudraprayag",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 246701,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246721,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246722,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246723,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246724,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246725,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246726,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246727,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246728,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246729,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246731,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246732,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246733,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246734,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246735,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246736,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246737,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246745,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246746,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246747,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246749,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246761,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246762,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246763,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246764,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 246765,
     "City": "Bijnor",
     "District": "Bijnor",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247001,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247002,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247113,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247120,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247121,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247122,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247129,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247231,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247232,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247340,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247341,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247342,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247343,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247451,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247452,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247453,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247551,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247554,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247656,
     "City": "Roorkee",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247661,
     "City": "Roorkee",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247662,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247662,
     "City": "Haridwar",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247663,
     "City": "Haridwar",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247664,
     "City": "Roorkee",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247665,
     "City": "Roorkee",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247665,
     "City": "Roorkee",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247666,
     "City": "Roorkee",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247667,
     "City": "Roorkee",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247668,
     "City": "Roorkee",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247669,
     "City": "Saharanpur",
     "District": "Saharanpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247670,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247670,
     "City": "Roorkee",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247671,
     "City": "Haridwar",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247769,
     "City": "Saharanpur",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 247771,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247772,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247773,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247774,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247775,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247776,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247777,
     "City": "Shamli",
     "District": "Shamli",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 247778,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 248001,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248002,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248003,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248005,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248006,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248007,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248008,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248009,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248011,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248012,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248013,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248014,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248015,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248102,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248121,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248122,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248123,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248124,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248125,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248140,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248141,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248142,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248143,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248144,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 248145,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248146,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248158,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248159,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248165,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248171,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248179,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248195,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248196,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248197,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248198,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 248199,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249001,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249121,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249122,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249123,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249124,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249125,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249126,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249128,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249128,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249130,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249131,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249131,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249132,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249134,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249135,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249137,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249141,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249141,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249145,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249146,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249151,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249152,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249155,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249161,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249165,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249165,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249171,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249175,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249180,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249181,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249185,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249186,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249192,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249193,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249194,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249195,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249196,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249196,
     "City": "Uttarkashi",
     "District": "Uttarkashi",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249199,
     "City": "Tehri Garhwal",
     "District": "Tehri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249201,
     "City": "Rishikesh",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249202,
     "City": "Rishikesh",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249203,
     "City": "Rishikesh",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249204,
     "City": "Dehradun",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249204,
     "City": "Rishikesh",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249205,
     "City": "Rishikesh",
     "District": "Dehradun",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249301,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249302,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249304,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249306,
     "City": "Pauri Garhwal",
     "District": "Pauri Garhwal",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249401,
     "City": "Haridwar",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249402,
     "City": "Haridwar",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249403,
     "City": "Haridwar",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249404,
     "City": "Haridwar",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249405,
     "City": "Haridwar",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249407,
     "City": "Haridwar",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249408,
     "City": "Haridwar",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249410,
     "City": "Haridwar",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 249411,
     "City": "Haridwar",
     "District": "Haridwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 250001,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250002,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250003,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250004,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250005,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250101,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250103,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250104,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250106,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250110,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250205,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250221,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250222,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250223,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250341,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250342,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250344,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250345,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250401,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250402,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250404,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250406,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250501,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250502,
     "City": "Meerut",
     "District": "Meerut",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250601,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250606,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250609,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250611,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250615,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250617,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250619,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250620,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250621,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250622,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250623,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250625,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250626,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 250645,
     "City": "Bagpat",
     "District": "Bagpat",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251001,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251002,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251003,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251201,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251202,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251203,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251301,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251305,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251306,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251307,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251308,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251309,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251310,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251311,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251314,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251315,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251316,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251318,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251319,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251320,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 251327,
     "City": "Muzaffarnagar",
     "District": "Muzaffarnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261001,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261121,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261125,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261131,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261135,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261136,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261141,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261145,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261151,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261201,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261202,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261203,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261204,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261205,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261206,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261207,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261208,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261301,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261302,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261303,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261401,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261402,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261403,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261404,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261405,
     "City": "Sitapur",
     "District": "Sitapur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261501,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261502,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261505,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 261506,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262001,
     "City": "Pilibhit",
     "District": "Pilibhit",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262121,
     "City": "Pilibhit",
     "District": "Pilibhit",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262122,
     "City": "Pilibhit",
     "District": "Pilibhit",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262124,
     "City": "Pilibhit",
     "District": "Pilibhit",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262201,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262201,
     "City": "Pilibhit",
     "District": "Pilibhit",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262202,
     "City": "Pilibhit",
     "District": "Pilibhit",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262203,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262203,
     "City": "Pilibhit",
     "District": "Pilibhit",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262302,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262302,
     "City": "Pilibhit",
     "District": "Pilibhit",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262305,
     "City": "Pilibhit",
     "District": "Pilibhit",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262308,
     "City": "Champawat",
     "District": "Champawat",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262308,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262309,
     "City": "Champawat",
     "District": "Champawat",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262310,
     "City": "Champawat",
     "District": "Champawat",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262311,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262401,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262402,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262405,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262406,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262407,
     "City": "Bareilly",
     "District": "Bareilly",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262501,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262502,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262520,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262521,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262522,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262523,
     "City": "Champawat",
     "District": "Champawat",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262523,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262524,
     "City": "Champawat",
     "District": "Champawat",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262525,
     "City": "Champawat",
     "District": "Champawat",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262526,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262527,
     "City": "Champawat",
     "District": "Champawat",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262528,
     "City": "Champawat",
     "District": "Champawat",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262529,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262530,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262531,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262532,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262533,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262534,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262540,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262541,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262542,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262543,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262544,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262545,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262546,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262547,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262550,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262551,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262552,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262553,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262554,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262555,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262561,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262576,
     "City": "Pithoragarh",
     "District": "Pithoragarh",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262580,
     "City": "Champawat",
     "District": "Champawat",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262580,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 262701,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262702,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262721,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262722,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262723,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262724,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262725,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262726,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262727,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262728,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262801,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262802,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262803,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262804,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262805,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262901,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262902,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262903,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262904,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262905,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262906,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 262907,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 263001,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263002,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263126,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263127,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263128,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263132,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263134,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263135,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263136,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263137,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263138,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263138,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263139,
     "City": "Haldwani",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263140,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263145,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263146,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263148,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263149,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263150,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263151,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263152,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263153,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263154,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263156,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263157,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263158,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263159,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263160,
     "City": "Udham Singh Nagar",
     "District": "Udham Singh Nagar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263162,
     "City": "Nainital",
     "District": "Nainital",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263601,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263619,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263620,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263621,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263622,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263623,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263624,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263624,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263625,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263626,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263628,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263628,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263629,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263630,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263631,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263632,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263633,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263634,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263635,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263636,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263637,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263638,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263639,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263640,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263641,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263642,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263643,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263645,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263646,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263651,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263652,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263653,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263655,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263656,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263658,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263659,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263660,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263661,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263663,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263664,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263665,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263667,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263676,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263678,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263679,
     "City": "Bageshwar",
     "District": "Bageshwar",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 263680,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 266001,
     "City": "Almora",
     "District": "Almora",
     "State": "Uttarakhand"
    },
    {
     "Pin Code": 271001,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271002,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271003,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271122,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271123,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271124,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271125,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271126,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271129,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271201,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271201,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271202,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271203,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271204,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271204,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271205,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271206,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271207,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271208,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271208,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271209,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271209,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271210,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271215,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271301,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271302,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271303,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271304,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271305,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271305,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271306,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271307,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271307,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271308,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271309,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271310,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271311,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271312,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271313,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271319,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271401,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271402,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271403,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271502,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271503,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271504,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271504,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271601,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271602,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271603,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271604,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271604,
     "City": "Gonda",
     "District": "Gonda",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271607,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271609,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271801,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271802,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271802,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271803,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271804,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271804,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271805,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271821,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271821,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271824,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271825,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271830,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271831,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271835,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271840,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271841,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271845,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271845,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271851,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271855,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271855,
     "City": "Kheri",
     "District": "Kheri",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271861,
     "City": "Balrampur (U P)",
     "District": "Balrampur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271865,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271865,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271870,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271870,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271871,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271871,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271872,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271875,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271875,
     "City": "Shrawasti",
     "District": "Shrawasti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271881,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271882,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271901,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271902,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271903,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 271904,
     "City": "Bahraich",
     "District": "Bahraich",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272001,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272002,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272123,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272124,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272125,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272125,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272126,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272126,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272127,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272128,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272129,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272129,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272130,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272131,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272148,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272148,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272148,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272150,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272151,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272151,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272152,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272152,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272153,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272153,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272154,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272154,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272155,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272161,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272162,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272163,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272164,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272165,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272165,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272170,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272170,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272171,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272172,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272173,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272175,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272175,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272176,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272177,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272178,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272181,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272182,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272189,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272190,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272191,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272191,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272192,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272193,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272194,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272194,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272195,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272199,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272201,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272201,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272202,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272203,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272204,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272205,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272206,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272207,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272208,
     "City": "Siddharthnagar",
     "District": "Siddharthnagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272270,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272271,
     "City": "Sant Kabir Nagar",
     "District": "Sant Kabir Nagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272301,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 272302,
     "City": "Basti",
     "District": "Basti",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273001,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273002,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273003,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273004,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273005,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273006,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273007,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273008,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273009,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273010,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273012,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273013,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273014,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273015,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273016,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273017,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273151,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273152,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273155,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273157,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273157,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273158,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273161,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273162,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273163,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273163,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273164,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273165,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273201,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273202,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273203,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273207,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273209,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273211,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273212,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273213,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273301,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273301,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273302,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273303,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273303,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273304,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273305,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273306,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273306,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273308,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273309,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273310,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273311,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273401,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273402,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273403,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273404,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273405,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273405,
     "City": "Maharajganj",
     "District": "Maharajganj",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273406,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273407,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273408,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273409,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273411,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273412,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 273413,
     "City": "Gorakhpur",
     "District": "Gorakhpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274001,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274149,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274182,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274201,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274202,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274203,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274204,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274205,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274206,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274207,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274208,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274301,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274302,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274303,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274304,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274305,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274306,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274401,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274402,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274402,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274403,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274404,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274405,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274406,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274407,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274408,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274409,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274501,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274502,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274505,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274506,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274508,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274509,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274601,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274602,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274603,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274604,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274701,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274702,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274703,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274704,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274705,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274801,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274802,
     "City": "Kushinagar",
     "District": "Kushinagar",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274806,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274807,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 274808,
     "City": "Deoria",
     "District": "Deoria",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275101,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275101,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275102,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275103,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275105,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275201,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275202,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275203,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275204,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275205,
     "City": "Ghazipur",
     "District": "Ghazipur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275301,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275301,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275302,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275302,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275303,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275304,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275304,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275305,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275305,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275306,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275306,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275307,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 275307,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276001,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276121,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276122,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276123,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276124,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276125,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276126,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276126,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276127,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276128,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276129,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276129,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276131,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276135,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276136,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276137,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276138,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276139,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276140,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276141,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276142,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276143,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276201,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276202,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276203,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276204,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276205,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276206,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276207,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276208,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276288,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276301,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276302,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276303,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276304,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276305,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276305,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276306,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276306,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276402,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276402,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276403,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276403,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276404,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276405,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276405,
     "City": "Mau",
     "District": "Mau",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 276406,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277001,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277121,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277123,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277124,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277201,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277202,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277203,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277204,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277205,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277207,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277208,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277209,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277210,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277211,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277213,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277214,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277216,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277219,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277301,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277302,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277303,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277304,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277401,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277402,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277403,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277501,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277502,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277503,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277504,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 277506,
     "City": "Ballia",
     "District": "Ballia",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281001,
     "City": "Hathras",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281001,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281002,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281003,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281004,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281005,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281006,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281104,
     "City": "Hathras",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281121,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281122,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281123,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281201,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281202,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281203,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281204,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281205,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281206,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281301,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281302,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281303,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281305,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281306,
     "City": "Hathras",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281307,
     "City": "Hathras",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281308,
     "City": "Hathras",
     "District": "Hathras",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281308,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281401,
     "City": "Azamgarh",
     "District": "Azamgarh",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281401,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281403,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281404,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281405,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281406,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281501,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281502,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 281504,
     "City": "Mathura",
     "District": "Mathura",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 282001,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 282002,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 282003,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 282004,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 282005,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 282006,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 282007,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 282008,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 282009,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 282010,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283101,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283102,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283103,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283104,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283105,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283110,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283111,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283112,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283113,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283114,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283115,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283119,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283121,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283122,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283123,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283124,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283125,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283126,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283130,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283135,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283136,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283141,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283142,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283145,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283151,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283152,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283201,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283202,
     "City": "Agra",
     "District": "Agra",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283203,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283204,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283205,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283206,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 283207,
     "City": "Firozabad",
     "District": "Firozabad",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284001,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284002,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284003,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284120,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284121,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284122,
     "City": "Lalitpur",
     "District": "Lalitpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284123,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284123,
     "City": "Lalitpur",
     "District": "Lalitpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284124,
     "City": "Lalitpur",
     "District": "Lalitpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284125,
     "City": "Lalitpur",
     "District": "Lalitpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284126,
     "City": "Lalitpur",
     "District": "Lalitpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284127,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284128,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284135,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284136,
     "City": "Lalitpur",
     "District": "Lalitpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284201,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284202,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284203,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284203,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284204,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284204,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284205,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284206,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284301,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284302,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284303,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284303,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284304,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284305,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284306,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284306,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284401,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284402,
     "City": "Lalitpur",
     "District": "Lalitpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284403,
     "City": "Lalitpur",
     "District": "Lalitpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284404,
     "City": "Lalitpur",
     "District": "Lalitpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284405,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284405,
     "City": "Lalitpur",
     "District": "Lalitpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284406,
     "City": "Lalitpur",
     "District": "Lalitpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284419,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 284501,
     "City": "Lalitpur",
     "District": "Lalitpur",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285001,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285121,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285122,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285123,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285124,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285125,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285125,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285126,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285127,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285128,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285129,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285130,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285131,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285201,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285202,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285203,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285204,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285205,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285205,
     "City": "Jhansi",
     "District": "Jhansi",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285206,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 285223,
     "City": "Jalaun",
     "District": "Jalaun",
     "State": "Uttar Pradesh"
    },
    {
     "Pin Code": 300201,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 300501,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301001,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301002,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301016,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301018,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301019,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301020,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301021,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301022,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301023,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301024,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301025,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301026,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301027,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301028,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301030,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301035,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301401,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301402,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301403,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301404,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301405,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301406,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301407,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301408,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301409,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301410,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301411,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301412,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301413,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301414,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301415,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301416,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301424,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301427,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301604,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301701,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301702,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301703,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301704,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301705,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301706,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301707,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301708,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301709,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301712,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301713,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 301714,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302001,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302002,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302003,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302004,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302005,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302006,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302007,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302008,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302010,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302011,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302012,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302013,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302015,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302016,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302017,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302018,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302019,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302020,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302021,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302022,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302023,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302024,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302025,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302026,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302027,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302028,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302029,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302031,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302032,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302033,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302034,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302035,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302036,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302037,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302039,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302040,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 302902,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303001,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303002,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303003,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303004,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303005,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303006,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303007,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303008,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303009,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303011,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303012,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303026,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303101,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303102,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303103,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303104,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303105,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303106,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303107,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303108,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303109,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303110,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303119,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303120,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303121,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303122,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303209,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303301,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303302,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303303,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303304,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303305,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303305,
     "City": "Jaipur",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303305,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303306,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303307,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303309,
     "City": "Dausa",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303313,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303315,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303323,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303325,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303326,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303327,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303328,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303329,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303331,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303338,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303348,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303501,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303502,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303503,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303503,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303504,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303505,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303506,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303507,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303507,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303508,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303509,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303509,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303510,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303511,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303601,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303602,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303603,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303604,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303609,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303701,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303702,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303703,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303704,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303706,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303712,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303801,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303802,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303803,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303804,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303805,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303806,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303807,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303901,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303902,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303903,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303904,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303905,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303906,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 303908,
     "City": "Jaipur",
     "District": "Jaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304001,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304009,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304020,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304021,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304022,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304023,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304024,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304025,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304501,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304502,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304503,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304504,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304505,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304506,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304507,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304801,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304802,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304803,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 304804,
     "City": "Tonk",
     "District": "Tonk",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305001,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305002,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305003,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305004,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305005,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305006,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305007,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305008,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305009,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305011,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305012,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305021,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305022,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305023,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305024,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305025,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305026,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305201,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305202,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305203,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305204,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305205,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305206,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305207,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305401,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305402,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305403,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305404,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305405,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305406,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305407,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305408,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305412,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305415,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305601,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305621,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305622,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305623,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305624,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305625,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305627,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305628,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305629,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305630,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305801,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305802,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305811,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305812,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305813,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305814,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305815,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305816,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305817,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305819,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305901,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305921,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305922,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305922,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305923,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305924,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305925,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305925,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305926,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 305927,
     "City": "Ajmer",
     "District": "Ajmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306001,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306021,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306022,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306023,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306025,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306101,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306102,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306103,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306104,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306105,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306107,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306114,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306115,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306116,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306119,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306126,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306301,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306302,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306303,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306304,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306305,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306306,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306307,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306308,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306309,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306401,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306421,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306422,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306501,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306502,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306503,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306504,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306601,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306602,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306603,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306701,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306702,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306703,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306704,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306705,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306706,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306707,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306708,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306709,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306901,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306902,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 306912,
     "City": "Pali",
     "District": "Pali",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307001,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307019,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307022,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307023,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307024,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307025,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307026,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307027,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307028,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307029,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307030,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307031,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307042,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307043,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307501,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307510,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307511,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307512,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307513,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307514,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307515,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307801,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307802,
     "City": "Sirohi",
     "District": "Sirohi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 307803,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311001,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311011,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311021,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311022,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311023,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311024,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311025,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311026,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311029,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311030,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311031,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311201,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311202,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311203,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311204,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311301,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311302,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311401,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311402,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311403,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311404,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311407,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311408,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311601,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311602,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311603,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311604,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311605,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311801,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311802,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311803,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311804,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311805,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311806,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311807,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311808,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311809,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 311810,
     "City": "Bhilwara",
     "District": "Bhilwara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312000,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312001,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312021,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312022,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312023,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312024,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312025,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312026,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312027,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312201,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312202,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312203,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312204,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312205,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312206,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312207,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312401,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312402,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312403,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312404,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312601,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312602,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312603,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312604,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312605,
     "City": "Pratapgarh (Rajasthan)",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312606,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312612,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312613,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312614,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312615,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312616,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312617,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312619,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312620,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312622,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312623,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 312901,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313001,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313002,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313003,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313004,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313011,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313012,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313015,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313016,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313022,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313024,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313025,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313026,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313027,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313028,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313031,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313038,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313201,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313202,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313203,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313204,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313205,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313206,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313206,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313207,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313211,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313212,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313301,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313321,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313322,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313323,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313324,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313325,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313327,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313328,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313329,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313330,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313331,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313332,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313333,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313334,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313338,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313341,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313342,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313601,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313602,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313603,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313604,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313605,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313611,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313701,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313702,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313703,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313704,
     "City": "Rajsamand",
     "District": "Rajsamand",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313704,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313705,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313706,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313708,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313801,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313802,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313803,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313804,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313805,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313901,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313902,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313903,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313904,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313905,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 313906,
     "City": "Udaipur",
     "District": "Udaipur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314001,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314011,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314021,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314022,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314023,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314024,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314025,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314026,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314027,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314028,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314029,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314030,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314031,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314032,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314034,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314035,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314036,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314037,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314038,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314401,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314402,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314403,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314404,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314405,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314406,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314801,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 314804,
     "City": "Dungarpur",
     "District": "Dungarpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 320004,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321001,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321021,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321022,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321023,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321024,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321025,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321026,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321028,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321201,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321202,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321203,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321204,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321205,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321206,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321301,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321302,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321303,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321401,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321402,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321403,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321404,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321405,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321406,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321407,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321408,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321409,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321410,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321411,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321601,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321602,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321603,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321605,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321606,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321607,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321608,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321609,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321610,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321611,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321612,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321613,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321614,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321615,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321633,
     "City": "Alwar",
     "District": "Alwar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 321642,
     "City": "Bharatpur",
     "District": "Bharatpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322001,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322021,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322023,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322024,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322025,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322026,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322027,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322028,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322029,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322030,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322033,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322033,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322034,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322201,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322202,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322202,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322203,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322203,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322204,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322204,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322205,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322211,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322212,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322212,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322213,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322214,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322215,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322215,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322216,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322218,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322218,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322219,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322219,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322220,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322220,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322221,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322222,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322230,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322234,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322235,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322236,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322238,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322240,
     "City": "Dausa",
     "District": "Dausa",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322241,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322242,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322243,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322243,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322249,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322251,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322252,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322254,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322255,
     "City": "Karauli",
     "District": "Karauli",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322701,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322702,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322703,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 322704,
     "City": "Sawai Madhopur",
     "District": "Sawai Madhopur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323001,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323021,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323022,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323023,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323024,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323025,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323301,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323302,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323303,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323304,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323305,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323306,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323307,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323601,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323602,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323603,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323613,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323614,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323615,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323616,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323801,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323802,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 323803,
     "City": "Bundi",
     "District": "Bundi",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 324001,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 324002,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 324003,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 324004,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 324005,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 324006,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 324007,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 324008,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 324009,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 324010,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325001,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325003,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325004,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325006,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325008,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325009,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325201,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325202,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325202,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325203,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325204,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325205,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325206,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325207,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325207,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325208,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325209,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325214,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325215,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325216,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325217,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325218,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325219,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325220,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325221,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325222,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325223,
     "City": "Baran",
     "District": "Baran",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325600,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325601,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 325602,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326001,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326021,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326022,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326023,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326024,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326033,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326034,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326035,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326036,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326037,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326038,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326039,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326501,
     "City": "Chittorgarh",
     "District": "Chittorgarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326501,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326502,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326512,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326513,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326514,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326515,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326516,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326517,
     "City": "Jhalawar",
     "District": "Jhalawar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326517,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326518,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326519,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326520,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326529,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 326530,
     "City": "Kota",
     "District": "Kota",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327001,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327021,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327022,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327023,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327024,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327025,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327026,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327027,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327029,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327031,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327032,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327033,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327034,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327601,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327602,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327603,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327604,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327605,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327606,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 327801,
     "City": "Banswara",
     "District": "Banswara",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328001,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328021,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328022,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328023,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328024,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328025,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328026,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328027,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328028,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328029,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328030,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328031,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328035,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 328041,
     "City": "Dholpur",
     "District": "Dholpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331001,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331021,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331022,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331023,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331024,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331025,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331026,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331027,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331028,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331029,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331030,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331031,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331033,
     "City": "Dausa",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331301,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331302,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331303,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331304,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331305,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331401,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331402,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331403,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331411,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331501,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331502,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331503,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331504,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331505,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331506,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331507,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331517,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331518,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331701,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331801,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331801,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331802,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331803,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331803,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331811,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 331811,
     "City": "Churu",
     "District": "Churu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332001,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332002,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332021,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332023,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332024,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332025,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332026,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332027,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332028,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332029,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332030,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332031,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332041,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332042,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332301,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332302,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332303,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332304,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332305,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332307,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332311,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332312,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332315,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332316,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332317,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332318,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332401,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332402,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332403,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332404,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332405,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332406,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332411,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332601,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332602,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332603,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332701,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332702,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332703,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332705,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332706,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332707,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332708,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332709,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332710,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332711,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332712,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332713,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332714,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332715,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332716,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332718,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332719,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332721,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332722,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332730,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332735,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332736,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332742,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332744,
     "City": "Sikar",
     "District": "Sikar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 332746,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333001,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333011,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333012,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333021,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333022,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333023,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333024,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333025,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333026,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333027,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333028,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333029,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333030,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333031,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333032,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333033,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333034,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333035,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333036,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333041,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333042,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333053,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333302,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333303,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333304,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333305,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333307,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333308,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333501,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333502,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333503,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333504,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333514,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333515,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333516,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333701,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333702,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333704,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333705,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333707,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 333801,
     "City": "Jhujhunu",
     "District": "Jhujhunu",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334001,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334002,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334003,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334004,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334006,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334021,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334022,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334023,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334201,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334202,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334302,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334303,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334305,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334401,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334402,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334403,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334601,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334602,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334603,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334604,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334801,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334802,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334803,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334804,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 334808,
     "City": "Bikaner",
     "District": "Bikaner",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335001,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335002,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335021,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335022,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335023,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335024,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335024,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335025,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335027,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335037,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335038,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335039,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335040,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335041,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335051,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335061,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335062,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335063,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335064,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335065,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335073,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335501,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335502,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335503,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335504,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335511,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335512,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335513,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335523,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335524,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335525,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335526,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335528,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335701,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335702,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335703,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335704,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335705,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335707,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335711,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335771,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335801,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335802,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335803,
     "City": "Hanumangarh",
     "District": "Hanumangarh",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335804,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335805,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 335901,
     "City": "Ganganagar",
     "District": "Ganganagar",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341001,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341012,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341021,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341022,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341023,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341024,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341025,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341026,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341027,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341028,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341029,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341030,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341031,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341301,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341302,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341303,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341304,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341305,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341306,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341316,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341317,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341318,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341319,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341501,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341502,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341503,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341504,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341505,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341506,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341507,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341508,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341509,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341510,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341511,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341512,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341513,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341514,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341515,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341516,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341517,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341518,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341519,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341520,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341522,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341528,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341529,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341533,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341542,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 341551,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342001,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342002,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342003,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342004,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342005,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342006,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342007,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342008,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342009,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342010,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342011,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342012,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342013,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342014,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342015,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342016,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342021,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342022,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342023,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342024,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342025,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342026,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342027,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342028,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342029,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342032,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342037,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342301,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342302,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342302,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342303,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342304,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342305,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342306,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342307,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342308,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342309,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342310,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342311,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342312,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342314,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342601,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342602,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342603,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342604,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342605,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342606,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342608,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342612,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342801,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342802,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342901,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 342902,
     "City": "Nagaur",
     "District": "Nagaur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343001,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343002,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343006,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343021,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343022,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343023,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343024,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343025,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343027,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343028,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343029,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343030,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343032,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343039,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343040,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343041,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343042,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343048,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 343049,
     "City": "Jalor",
     "District": "Jalor",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344001,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344011,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344012,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344013,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344021,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344022,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344023,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344024,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344025,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344026,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344027,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344028,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344031,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344032,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344033,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344034,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344035,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344036,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344037,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344043,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344044,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344501,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344502,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344701,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344702,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344703,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344704,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344705,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344706,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344708,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 344801,
     "City": "Barmer",
     "District": "Barmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345001,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345001,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345002,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345021,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345021,
     "City": "Jodhpur",
     "District": "Jodhpur",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345022,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345023,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345024,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345025,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345026,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345027,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345028,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345031,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345033,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 345034,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 348221,
     "City": "Jaisalmer",
     "District": "Jaisalmer",
     "State": "Rajasthan"
    },
    {
     "Pin Code": 360001,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360002,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360003,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360004,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360005,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360006,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360007,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360020,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360021,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360022,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360023,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360024,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360025,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360030,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360035,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360040,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360050,
     "City": "Jasdan ",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360055,
     "City": "Surendra Nagar",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360055,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360060,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360070,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360080,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360110,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360110,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360230,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360311,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360320,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360330,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360360,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360370,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360375,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360380,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360405,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360410,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360421,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360430,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360440,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360450,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360452,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360460,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360470,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360480,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360480,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360490,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360490,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360490,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360490,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360510,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360510,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360515,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360520,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360530,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360530,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360531,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360540,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360545,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360550,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360560,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360570,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360575,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360576,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360577,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360578,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360579,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360590,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 360590,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361001,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361002,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361003,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361004,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361005,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361006,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361007,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361008,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361009,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361010,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361011,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361012,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361013,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361110,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361120,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361130,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361140,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361141,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361142,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361150,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361160,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361162,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361170,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361210,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361220,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361230,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361240,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361250,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361280,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361305,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361306,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361310,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361315,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361320,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361325,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361330,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361335,
     "City": "Dwarka",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361345,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361347,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 361350,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362001,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362002,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362004,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362011,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362015,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362020,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362030,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362037,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362110,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362120,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362130,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362135,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362140,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362150,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362205,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362215,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362220,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362222,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362225,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362226,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362227,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362229,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362230,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362230,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362235,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362240,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362245,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362250,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362255,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362260,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362263,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362265,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362266,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362268,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362269,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362275,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362276,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362310,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362315,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362510,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362520,
     "City": "Diu",
     "District": "Diu",
     "State": "Daman and Diu"
    },
    {
     "Pin Code": 362530,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362540,
     "City": "Diu",
     "District": "Diu",
     "State": "Daman and Diu"
    },
    {
     "Pin Code": 362550,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362560,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362565,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362570,
     "City": "Diu",
     "District": "Diu",
     "State": "Daman and Diu"
    },
    {
     "Pin Code": 362610,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362620,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362620,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362625,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362630,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362640,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362650,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362650,
     "City": "Porbandar",
     "District": "Porbandar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362710,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362715,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362720,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362725,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 362730,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363001,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363002,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363020,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363030,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363035,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363040,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363110,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363115,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363310,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363320,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363330,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363351,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363410,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363415,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363421,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363423,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363425,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363427,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363430,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363435,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363440,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363510,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363520,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363530,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363610,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363621,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363630,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363641,
     "City": "Morbi",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363642,
     "City": "Morbi",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363650,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363655,
     "City": "Jamnagar",
     "District": "Jamnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363660,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 363670,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364001,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364002,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364003,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364004,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364005,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364006,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364050,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364060,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364070,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364081,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364110,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364120,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364130,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364135,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364140,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364145,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364146,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364150,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364210,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364225,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364230,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364240,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364250,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364260,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364265,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364270,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364275,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364280,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364290,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364295,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364305,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364310,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364311,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364313,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364320,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364330,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364465,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364470,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364485,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364490,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364505,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364510,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364515,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364521,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364522,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364525,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364530,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364530,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364545,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364710,
     "City": "Botad",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364720,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364730,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364740,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364750,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364760,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 364765,
     "City": "Bhavnagar",
     "District": "Bhavnagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365220,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365410,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365421,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365430,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365435,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365440,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365440,
     "City": "Junagadh",
     "District": "Junagadh",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365450,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365455,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365456,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365460,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365480,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365480,
     "City": "Rajkot",
     "District": "Rajkot",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365535,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365540,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365541,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365545,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365550,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365555,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365560,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365565,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365601,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365610,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365620,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365630,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365635,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365640,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365645,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365650,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 365660,
     "City": "Amreli",
     "District": "Amreli",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370001,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370015,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370020,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370030,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370040,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370105,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370110,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370130,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370135,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370140,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370145,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370150,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370155,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370160,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370165,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370170,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370201,
     "City": "Gandhidham",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370203,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370205,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370210,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370230,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370240,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370405,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370410,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370415,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370421,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370425,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370427,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370429,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370430,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370435,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370445,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370450,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370455,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370460,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370465,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370475,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370485,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370490,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370510,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370511,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370601,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370602,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370605,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370610,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370615,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370620,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370625,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370627,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370630,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370640,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370645,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370650,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370655,
     "City": "Bhuj",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370660,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370665,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370670,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 370675,
     "City": "Kutch",
     "District": "Kutch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380000,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380001,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380002,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380004,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380005,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380006,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380007,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380008,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380009,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380010,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380012,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380013,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380014,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380015,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380016,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380018,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380019,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380021,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380022,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380023,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380024,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380025,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380026,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380027,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380028,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380029,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380030,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380045,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380050,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380051,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380052,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380053,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380054,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380055,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380056,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380058,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380059,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380060,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380060,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380061,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380063,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380065,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380066,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 380081,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382001,
     "City": "Gandhi Nagar",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382003,
     "City": "Gandhi Nagar",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382005,
     "City": "Gandhi Nagar",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382006,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382007,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382008,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382010,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382011,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382013,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382014,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382016,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382020,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382021,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382024,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382026,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382028,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382030,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382041,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382042,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382045,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382051,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382110,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382115,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382115,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382120,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382120,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382130,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382130,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382140,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382145,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382150,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382155,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382165,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382170,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382170,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382210,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382213,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382220,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382225,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382230,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382240,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382245,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382250,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382255,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382260,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382265,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382270,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382305,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382308,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382315,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382315,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382320,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382321,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382325,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382330,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382340,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382345,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382346,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 382350,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382352,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382355,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382405,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382410,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382415,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382418,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382421,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382421,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382422,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382423,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382424,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382424,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382425,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382426,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382427,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382428,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382430,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382433,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382433,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382435,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382440,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382443,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382445,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382449,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382450,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382451,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382455,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382460,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382463,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382465,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382470,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382475,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382480,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382481,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382610,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382620,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382630,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382640,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382650,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382705,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382710,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382711,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382715,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382719,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382721,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382725,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382727,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382728,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382729,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382730,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382732,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382735,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382740,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382745,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382750,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382755,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382760,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382765,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382775,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382780,
     "City": "Surendra Nagar",
     "District": "Surendra Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382810,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382815,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382820,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382825,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382830,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382835,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382840,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382845,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382850,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382855,
     "City": "Gandhi Nagar",
     "District": "Gandhi Nagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382860,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382865,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 382870,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383001,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383006,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383010,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383030,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383110,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383120,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383205,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383210,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383215,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383220,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383225,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383230,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383235,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383240,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383245,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383246,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383250,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383251,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383255,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383260,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383270,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383275,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383276,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383305,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383307,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383310,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383315,
     "City": "Modasa",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383316,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383317,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383320,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383325,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383330,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383335,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383340,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383345,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383345,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383350,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383355,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383410,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383421,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383422,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383430,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383434,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383440,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383450,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383460,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 383462,
     "City": "Sabarkantha",
     "District": "Sabarkantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384001,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384002,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384003,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384011,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384012,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384110,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384120,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384130,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384140,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384151,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384160,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384170,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384205,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384210,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384212,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384215,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384220,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384221,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384225,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384228,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384229,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384230,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384235,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384240,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384241,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384245,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384246,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384250,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384255,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384260,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384260,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384265,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384272,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384273,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384275,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384285,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384290,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384305,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384310,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384315,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384320,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384325,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384330,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384335,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384340,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384345,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384350,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384355,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384360,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384365,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384385,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384410,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384421,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384430,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 384435,
     "City": "Mahesana",
     "District": "Mahesana",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385001,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385002,
     "City": "Palanpur",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385010,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385110,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385120,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385130,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385135,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385210,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385310,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385320,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385326,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385330,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385340,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385350,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385360,
     "City": "Patan",
     "District": "Patan",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385410,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385421,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385505,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385506,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385510,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385515,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385519,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385520,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385525,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385530,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385535,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385540,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385545,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385550,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385555,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385560,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385565,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385566,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385570,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 385575,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 386070,
     "City": "Banaskantha",
     "District": "Banaskantha",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387001,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387002,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387110,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387115,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387120,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387130,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387210,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387210,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387220,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387230,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387240,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387250,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387305,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387310,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387315,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387320,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387325,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387330,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387331,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387335,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387340,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387345,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387345,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387350,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387355,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387360,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387365,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387370,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387375,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387380,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387385,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387411,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387430,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387510,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387520,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387530,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387540,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387550,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387560,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387570,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387610,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387620,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387630,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387635,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387640,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387650,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387710,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387710,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 387810,
     "City": "Ahmedabad",
     "District": "Ahmedabad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388001,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388110,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388120,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388121,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388130,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388131,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388140,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388140,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388150,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388160,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388170,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388180,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388180,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388205,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388210,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388215,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388215,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388216,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388220,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388220,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388225,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388230,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388235,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388235,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388239,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388240,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388245,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388250,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388255,
     "City": "Balasinor",
     "District": "Mahisagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388260,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388265,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388270,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388305,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388306,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388307,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388310,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388315,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388320,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388325,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388330,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388335,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388340,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388345,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388350,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388355,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388360,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388365,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388370,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388410,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388421,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388430,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388440,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388440,
     "City": "Kheda",
     "District": "Kheda",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388450,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388460,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388465,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388470,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388480,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388510,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388520,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388530,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388540,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388543,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388545,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388550,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388560,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388570,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388580,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388590,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388610,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388620,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388625,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388630,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388640,
     "City": "Anand",
     "District": "Anand",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388710,
     "City": "Kheda",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388710,
     "City": "Kheda",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 388713,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389001,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389002,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389110,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389110,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389115,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389120,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389130,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389140,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389145,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389146,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389151,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389152,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389154,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389155,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389160,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389170,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389172,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389172,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389175,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389180,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389180,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389190,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389190,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389210,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389220,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389230,
     "City": "Lunwada",
     "District": "Mahisagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389232,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389235,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389239,
     "City": "Lunwada",
     "District": "Mahisagar",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389240,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389250,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389260,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389265,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389310,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389320,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389330,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389340,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389341,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389350,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389360,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389365,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389370,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389380,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389380,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389382,
     "City": "Dahod",
     "District": "Dahod",
     "State": "Gujarat"
    },
    {
     "Pin Code": 389390,
     "City": "Panch Mahals",
     "District": "Panch Mahals",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390001,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390002,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390003,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390004,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390005,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390006,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390007,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390008,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390009,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390010,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390011,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390012,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390013,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390014,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390015,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390016,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390017,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390018,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390019,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390020,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390021,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390022,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390023,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390024,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 390025,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391101,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391105,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391107,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391110,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391110,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391111,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391115,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391120,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391121,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391121,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391125,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391130,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391135,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391140,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391145,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391150,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391151,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391152,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391155,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391156,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391160,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391165,
     "City": "Chhotaudepur",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391168,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391170,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391175,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391210,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391220,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391240,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391243,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391244,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391245,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391250,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391310,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391320,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391330,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391340,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391345,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391346,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391350,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391410,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391421,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391430,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391440,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391445,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391450,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391460,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391510,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391520,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391530,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391740,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391745,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391750,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391760,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391761,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391770,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391774,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391775,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391780,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391810,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 391810,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392001,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392002,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392011,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392012,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392012,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392015,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392015,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392020,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392020,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392021,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392025,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392025,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392030,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392030,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392031,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392035,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392040,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392040,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392110,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392110,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392130,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392130,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392140,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392140,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392150,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392150,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392150,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392150,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392155,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392160,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392160,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392162,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392165,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392170,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392170,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392180,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392180,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392201,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392210,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392210,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392215,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392220,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392220,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392230,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392240,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 392310,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393001,
     "City": "Ankleshwar",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393001,
     "City": "Ankleshwar",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393002,
     "City": "Ankleshwar",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393010,
     "City": "Ankleshwar",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393010,
     "City": "Ankleshwar",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393017,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393020,
     "City": "Ankleshwar",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393020,
     "City": "Ankleshwar",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393025,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393025,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393030,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393040,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393040,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393041,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393041,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393041,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393050,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393050,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393050,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393105,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393105,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393110,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393110,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393115,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393115,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393120,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393120,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393125,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393125,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393125,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393130,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393130,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393130,
     "City": "Vadodara",
     "District": "Vadodara",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393135,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393135,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393140,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393145,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393145,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393150,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393150,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393151,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393151,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393155,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 393155,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394101,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394105,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394107,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394110,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394111,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394115,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394116,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394120,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394120,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394125,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394130,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394140,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394150,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394155,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394160,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394163,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394170,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394180,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394185,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394190,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394210,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394211,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394220,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394221,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394230,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394235,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394240,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394245,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394246,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394248,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394250,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394270,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394305,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394310,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394315,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394317,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394320,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394325,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394326,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394327,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394330,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394335,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394340,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394345,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394350,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394352,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394355,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394360,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394365,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394365,
     "City": "Tapi",
     "District": "Tapi",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394370,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394375,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394375,
     "City": "Tapi",
     "District": "Tapi",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394380,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394380,
     "City": "Tapi",
     "District": "Tapi",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394405,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394410,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394421,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394430,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394440,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394445,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394500,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394510,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394515,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394516,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394517,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394518,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394520,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394530,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394540,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394550,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394601,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394607,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394620,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394630,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394630,
     "City": "Tapi",
     "District": "Tapi",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394633,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394633,
     "City": "Tapi",
     "District": "Tapi",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394635,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394635,
     "City": "Tapi",
     "District": "Tapi",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394640,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394641,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394641,
     "City": "Tapi",
     "District": "Tapi",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394650,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394651,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394651,
     "City": "Tapi",
     "District": "Tapi",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394655,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394655,
     "City": "Tapi",
     "District": "Tapi",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394660,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394670,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394680,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394680,
     "City": "Tapi",
     "District": "Tapi",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394690,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394710,
     "City": "The Dangs",
     "District": "The Dangs",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394715,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394715,
     "City": "The Dangs",
     "District": "The Dangs",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394716,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394716,
     "City": "The Dangs",
     "District": "The Dangs",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394720,
     "City": "The Dangs",
     "District": "The Dangs",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394730,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394730,
     "City": "The Dangs",
     "District": "The Dangs",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394810,
     "City": "Bharuch",
     "District": "Bharuch",
     "State": "Gujarat"
    },
    {
     "Pin Code": 394810,
     "City": "Narmada",
     "District": "Narmada",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395001,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395002,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395003,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395004,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395005,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395006,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395007,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395008,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395009,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395010,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395011,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395012,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395017,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395023,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395083,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395101,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395345,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 395620,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396001,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396002,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396007,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396020,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396030,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396035,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396040,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396045,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396050,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396051,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396051,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396055,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396060,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396065,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396105,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396110,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396115,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396120,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396125,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396126,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396130,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396135,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396140,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396145,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396150,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396155,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396165,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396170,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396171,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396180,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396185,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396191,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396193,
     "City": "Dadra and Nagar Haveli",
     "District": "Dadra and Nagar Haveli",
     "State": "Dadra and Nagar Haveli"
    },
    {
     "Pin Code": 396193,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396195,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396210,
     "City": "Daman",
     "District": "Daman",
     "State": "Daman and Diu"
    },
    {
     "Pin Code": 396215,
     "City": "Daman",
     "District": "Daman",
     "State": "Daman and Diu"
    },
    {
     "Pin Code": 396220,
     "City": "Daman",
     "District": "Daman",
     "State": "Daman and Diu"
    },
    {
     "Pin Code": 396230,
     "City": "Silvassa",
     "District": "Dadra and Nagar Haveli",
     "State": "Dadra and Nagar Haveli"
    },
    {
     "Pin Code": 396230,
     "City": "Silvassa",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396233,
     "City": "Silvassa",
     "District": "Dadra and Nagar Haveli",
     "State": "Dadra and Nagar Haveli"
    },
    {
     "Pin Code": 396235,
     "City": "Dadra and Nagar Haveli",
     "District": "Dadra and Nagar Haveli",
     "State": "Dadra and Nagar Haveli"
    },
    {
     "Pin Code": 396235,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396240,
     "City": "Dadra and Nagar Haveli",
     "District": "Dadra and Nagar Haveli",
     "State": "Dadra and Nagar Haveli"
    },
    {
     "Pin Code": 396240,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396300,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396310,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396321,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396325,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396350,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396360,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396370,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396375,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396380,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396385,
     "City": "Valsad",
     "District": "Valsad",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396403,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396406,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396409,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396412,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396415,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396418,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396421,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396424,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396427,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396430,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396433,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396436,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396439,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396440,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396443,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396445,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396450,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396460,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396463,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396466,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396469,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396472,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396475,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396510,
     "City": "Surat",
     "District": "Surat",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396521,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396530,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396540,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396560,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396570,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396580,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 396590,
     "City": "Navsari",
     "District": "Navsari",
     "State": "Gujarat"
    },
    {
     "Pin Code": 400001,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400001,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400002,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400003,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400004,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400005,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400006,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400007,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400008,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400009,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400010,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400011,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400012,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400013,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400014,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400015,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400016,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400017,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400018,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400019,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400020,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400021,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400022,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400023,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400024,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400025,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400026,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400027,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400028,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400029,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400030,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400031,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400032,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400033,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400034,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400035,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400036,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400037,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400038,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400039,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400040,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400042,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400043,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400045,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400046,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400047,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400048,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400049,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400050,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400051,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400052,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400053,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400054,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400055,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400056,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400057,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400058,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400059,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400060,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400061,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400062,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400063,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400064,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400065,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400066,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400067,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400068,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400069,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400070,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400071,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400072,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400073,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400074,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400075,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400076,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400077,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400078,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400079,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400080,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400081,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400082,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400083,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400084,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400085,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400086,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400087,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400088,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400089,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400090,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400091,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400092,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400093,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400094,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400095,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400096,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400097,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400098,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400099,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400101,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400102,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400103,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400104,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400105,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400202,
     "City": "Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400410,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400601,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400602,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400603,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400604,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400605,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400606,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400607,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400608,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400609,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400610,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400611,
     "City": "Navi Mumbai",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400612,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400613,
     "City": "Navi Mumbai",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400613,
     "City": "Navi Mumbai",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400614,
     "City": "Navi Mumbai",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400615,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400701,
     "City": "Navi Mumbai",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400702,
     "City": "Navi Mumbai",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400703,
     "City": "Navi Mumbai",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400704,
     "City": "Navi Mumbai",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400705,
     "City": "Navi Mumbai",
     "District": "Mumbai",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400706,
     "City": "Navi Mumbai",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400707,
     "City": "Navi Mumbai",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400708,
     "City": "Navi Mumbai",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400709,
     "City": "Navi Mumbai",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 400710,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401101,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401102,
     "City": "Palghar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401103,
     "City": "Palghar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401104,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401105,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401106,
     "City": "Vasai Virar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401107,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401201,
     "City": "Vasai Virar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401202,
     "City": "Vasai Virar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401203,
     "City": "Vasai Virar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401204,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401205,
     "City": "Vasai Virar",
     "District": "Palghar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401206,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401207,
     "City": "Vasai Virar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401208,
     "City": "Vasai Virar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401209,
     "City": "Vasai Virar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401210,
     "City": "Vasai Virar",
     "District": "Palghar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401301,
     "City": "Vasai Virar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401302,
     "City": "Vasai Virar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401303,
     "City": "Vasai Virar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401304,
     "City": "Vasai Virar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401305,
     "City": "Vasai Virar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401401,
     "City": "Palghar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401402,
     "City": "Palghar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401403,
     "City": "Palghar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401404,
     "City": "Palghar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401405,
     "City": "Palghar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401406,
     "City": "Palghar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401407,
     "City": "Palghar",
     "District": "Palghar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401501,
     "City": "Palghar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401502,
     "City": "Palghar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401503,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401504,
     "City": "Palghar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401505,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401506,
     "City": "Palghar",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401601,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401602,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401603,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401604,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401605,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401606,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401607,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401608,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401609,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401610,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401701,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401702,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 401703,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402001,
     "City": "Raigarh(MH)",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402002,
     "City": "Raigarh(MH)",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402042,
     "City": "Raigarh(MH)",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402101,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402102,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402103,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402104,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402105,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402106,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402107,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402108,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402109,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402110,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402111,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402112,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402113,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402114,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402115,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402116,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402117,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402120,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402122,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402125,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402126,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402201,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402202,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402203,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402204,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402207,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402208,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402209,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402301,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402302,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402303,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402304,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402305,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402306,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402307,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402308,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402309,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402401,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402402,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402403,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402404,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402405,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 402503,
     "City": "BICHOLIM",
     "District": "BICHOLIM",
     "State": "Goa"
    },
    {
     "Pin Code": 403001,
     "City": "Panjim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403002,
     "City": "Panjim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403004,
     "City": "Panjim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403005,
     "City": "Panjim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403006,
     "City": "Panjim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403007,
     "City": "Panjim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403101,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403102,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403103,
     "City": "Shiroda",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403104,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403105,
     "City": "Sattari",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403105,
     "City": "Ponda",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403106,
     "City": "Ponda",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403107,
     "City": "Ponda",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403107,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403107,
     "City": "Ponda",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403107,
     "City": "Sanquelim",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403108,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403109,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403110,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403114,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403114,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403115,
     "City": "Ponda",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403115,
     "City": "Tiswadi",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403201,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403202,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403203,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403204,
     "City": "Salcette",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403206,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403401,
     "City": "Ponda",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403401,
     "City": "Ponda",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403402,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403403,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403404,
     "City": "Ponda",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403404,
     "City": "Ponda",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403406,
     "City": "Ponda",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403406,
     "City": "Ponda",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403407,
     "City": "Usgaon",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403409,
     "City": "Ponda",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403410,
     "City": "Dharbandora",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403501,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403502,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403502,
     "City": "Bicholim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403503,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403503,
     "City": "Bicholim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403503,
     "City": "Pernem",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403504,
     "City": "Bicholim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403505,
     "City": "Bicholim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403505,
     "City": "North Goa",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403505,
     "City": "Sattari",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403506,
     "City": "Sattari",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403507,
     "City": "Mapusa",
     "District": "Mapusa",
     "State": "Goa"
    },
    {
     "Pin Code": 403507,
     "City": "Mapusa",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403508,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403509,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403510,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403511,
     "City": "Saligao",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403512,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403512,
     "City": "Pernem",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403513,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403513,
     "City": "Pernem",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403515,
     "City": "Candolim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403516,
     "City": "Calangute",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403517,
     "City": "Siolim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403521,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403521,
     "City": "Tiswadi",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403523,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403524,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403524,
     "City": "Pernem",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403526,
     "City": "Bardez",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403527,
     "City": "Pernem",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403529,
     "City": "Bicholim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403530,
     "City": "Sattari",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403601,
     "City": "Margao",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403602,
     "City": "Margao",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403603,
     "City": "Margao",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403604,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403701,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403702,
     "City": "Canacona",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403703,
     "City": "Quepem",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403703,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403704,
     "City": "Sanguem",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403705,
     "City": "Quepem",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403706,
     "City": "Curchorem",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403706,
     "City": "Ponda",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403706,
     "City": "Quepem",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403707,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403708,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403709,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403710,
     "City": "Marmugao",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403710,
     "City": "Mormugao",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403710,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403711,
     "City": "Mormugao",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403712,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403713,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403714,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403715,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403716,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403717,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403718,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403719,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403720,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403721,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403722,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403723,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403724,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403725,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403726,
     "City": "Mormugao",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403728,
     "City": "Canacona",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403729,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403731,
     "City": "Salcette",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403801,
     "City": "Mormugao",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403802,
     "City": "Vasco-da-gama",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403803,
     "City": "Vasco-da-gama",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403804,
     "City": "Vasco-da-gama",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 403806,
     "City": "Vasco-da-gama",
     "District": "South Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 404003,
     "City": "Panjim",
     "District": "North Goa",
     "State": "Goa"
    },
    {
     "Pin Code": 410003,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410010,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410037,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410101,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410102,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410112,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410201,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410202,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410203,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410204,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410205,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410206,
     "City": "Navi Mumbai",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410207,
     "City": "Navi Mumbai",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410208,
     "City": "Navi Mumbai",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410209,
     "City": "Navi Mumbai",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410210,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410216,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410217,
     "City": "Navi Mumbai",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410218,
     "City": "Navi Mumbai",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410220,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410221,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410222,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410301,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410302,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410401,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410402,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410403,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410405,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410406,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410416,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410501,
     "City": "Pimpri Chinchwad",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410502,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410503,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410504,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410505,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410506,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410507,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410508,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410509,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410510,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410511,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410512,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410513,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410515,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 410516,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411001,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411002,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411003,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411004,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411005,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411006,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411007,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411008,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411009,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411011,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411012,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411013,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411014,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411015,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411016,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411017,
     "City": "Pimpri Chinchwad",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411018,
     "City": "Pimpri Chinchwad",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411019,
     "City": "Pimpri Chinchwad",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411020,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411021,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411022,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411023,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411024,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411025,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411026,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411027,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411028,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411029,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411030,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411031,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411032,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411033,
     "City": "Pimpri Chinchwad",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411034,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411035,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411036,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411037,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411038,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411039,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411040,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411041,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411042,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411043,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411044,
     "City": "Pimpri Chinchwad",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411045,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411046,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411047,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411048,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411051,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411052,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411053,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411057,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411058,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411060,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411061,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411062,
     "City": "Pimpri Chinchwad",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411067,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411068,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 411225,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412002,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412042,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412062,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412073,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412101,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412102,
     "City": "Baramati",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412103,
     "City": "Baramati",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412104,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412105,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412106,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412107,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412108,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412109,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412110,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412111,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412112,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412114,
     "City": "Pimpri Chinchwad",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412115,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412201,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412202,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412203,
     "City": "Daund",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412204,
     "City": "Daund",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412205,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412206,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412206,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412207,
     "City": "Daund",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412208,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412209,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412210,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412211,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412212,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412213,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412214,
     "City": "Daund",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412215,
     "City": "Daund",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412216,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412218,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412219,
     "City": "Daund",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412220,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412301,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412303,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412304,
     "City": "Baramati",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412305,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412306,
     "City": "Baramati",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412307,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412308,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412311,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412312,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412401,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412402,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412403,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412404,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412405,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412406,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412408,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412409,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412410,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412411,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412412,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412501,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412531,
     "City": "Pune",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412801,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412802,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412803,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412804,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412805,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 412806,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413001,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413002,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413003,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413004,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413005,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413006,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413007,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413008,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413101,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413102,
     "City": "Baramati",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413103,
     "City": "Indapur",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413104,
     "City": "Indapur",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413105,
     "City": "Indapur",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413106,
     "City": "Indapur",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413107,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413108,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413109,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413110,
     "City": "Baramati",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413111,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413112,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413113,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413114,
     "City": "Indapur",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413115,
     "City": "Baramati",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413116,
     "City": "Indapur",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413118,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413120,
     "City": "Indapur",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413130,
     "City": "Daund",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413132,
     "City": "Indapur",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413133,
     "City": "Baramati",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413201,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413202,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413203,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413204,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413205,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413206,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413207,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413208,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413209,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413210,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413211,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413212,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413213,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413214,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413215,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413216,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413217,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413218,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413219,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413220,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413221,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413222,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413223,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413224,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413226,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413227,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413228,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413229,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413248,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413249,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413250,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413251,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413252,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413253,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413255,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413301,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413302,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413303,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413304,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413305,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413306,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413307,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413308,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413309,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413310,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413313,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413314,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413315,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413316,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413317,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413319,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413322,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413324,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413401,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413402,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413403,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413404,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413405,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413406,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413409,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413410,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413411,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413412,
     "City": "Solapur",
     "District": "Solapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413501,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413502,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413502,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413503,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413504,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413505,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413506,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413507,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413508,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413509,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413510,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413510,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413511,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413511,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413512,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413513,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413514,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413514,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413515,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413516,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413516,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413517,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413517,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413518,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413518,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413519,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413520,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413521,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413521,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413522,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413523,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413523,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413524,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413524,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413525,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413526,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413527,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413527,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413528,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413529,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413529,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413530,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413531,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413531,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413532,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413532,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413534,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413544,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413544,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413580,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413581,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413581,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413582,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413582,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413601,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413602,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413603,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413604,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413605,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413606,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413607,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413607,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413608,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413623,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413624,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413701,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413702,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413703,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413704,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413705,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413706,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413707,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413708,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413709,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413710,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413711,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413712,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413713,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413714,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413715,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413716,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413717,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413718,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413719,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413720,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413721,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413722,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413723,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413725,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413726,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413728,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413736,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413737,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413738,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413739,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413801,
     "City": "Daund",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 413802,
     "City": "Daund",
     "District": "Pune",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414001,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414002,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414003,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414005,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414006,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414101,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414102,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414103,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414105,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414106,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414110,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414111,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414113,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414201,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414202,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414203,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414204,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414205,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414208,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414301,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414302,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414303,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414304,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414305,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414306,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414401,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414402,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414403,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414501,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414502,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414503,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414504,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414505,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414506,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414601,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414602,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414603,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414604,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414605,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414606,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414607,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414609,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 414701,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415001,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415002,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415003,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415004,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415010,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415011,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415012,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415013,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415014,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415015,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415019,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415020,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415021,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415022,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415023,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415101,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415102,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415103,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415104,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415105,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415106,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415107,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415108,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415109,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415110,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415111,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415112,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415114,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415115,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415116,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415122,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415124,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415202,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415203,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415205,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415206,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415207,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415208,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415209,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415211,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415212,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415213,
     "City": "Raigarh(MH)",
     "District": "Raigarh(MH)",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415214,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415236,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415301,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415302,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415303,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415304,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415305,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415306,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415307,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415308,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415309,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415310,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415311,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415312,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415313,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415315,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415401,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415402,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415403,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415404,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415405,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415406,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415407,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415408,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415409,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415410,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415411,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415412,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415413,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415414,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415415,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415466,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415501,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415502,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415503,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415504,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415505,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415506,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415507,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415508,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415509,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415510,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415511,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415512,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415513,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415514,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415515,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415516,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415517,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415518,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415519,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415520,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415521,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415522,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415523,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415524,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415525,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415526,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415527,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415528,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415530,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415536,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415537,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415538,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415539,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415540,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415601,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415602,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415603,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415604,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415605,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415606,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415607,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415608,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415609,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415610,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415611,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415612,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415613,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415614,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415615,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415616,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415617,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415618,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415619,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415620,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415621,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415626,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415628,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415629,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415634,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415637,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415639,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415640,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415641,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415643,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415701,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415702,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415703,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415705,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415706,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415708,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415709,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415710,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415711,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415712,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415713,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415714,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415715,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415715,
     "City": "Satara",
     "District": "Satara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415716,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415717,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415718,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415719,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415720,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415722,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415724,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415726,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415727,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415728,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415729,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415730,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415801,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415802,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415803,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415804,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415805,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415806,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 415807,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416001,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416002,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416003,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416004,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416005,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416006,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416007,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416008,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416010,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416011,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416012,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416013,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416101,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416102,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416103,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416104,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416105,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416106,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416107,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416108,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416109,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416110,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416111,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416112,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416113,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416114,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416115,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416116,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416117,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416118,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416119,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416120,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416121,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416122,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416143,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416144,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416146,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416201,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416202,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416203,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416204,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416205,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416206,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416207,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416208,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416209,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416210,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416211,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416212,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416213,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416214,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416215,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416216,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416217,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416218,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416219,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416220,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416221,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416222,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416223,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416229,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416230,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416231,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416232,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416234,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416235,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416236,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416301,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416302,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416303,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416304,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416305,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416306,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416307,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416308,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416309,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416310,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416311,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416312,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416313,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416314,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416315,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416316,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416319,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416401,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416402,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416403,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416404,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416405,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416406,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416407,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416408,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416409,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416410,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416411,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416412,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416413,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416414,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416415,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416416,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416417,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416418,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416419,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416420,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416425,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416436,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416437,
     "City": "Sangli",
     "District": "Sangli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416501,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416502,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416503,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416504,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416505,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416506,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416507,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416508,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416509,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416510,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416511,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416512,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416513,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416514,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416515,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416516,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416517,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416518,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416519,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416520,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416521,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416522,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416523,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416524,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416525,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416526,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416527,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416528,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416529,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416531,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416534,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416549,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416550,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416551,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416552,
     "City": "Kolhapur",
     "District": "Kolhapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416601,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416602,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416603,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416604,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416605,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416606,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416608,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416609,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416610,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416611,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416612,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416613,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416614,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416615,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416616,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416620,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416623,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416626,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416628,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416630,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416632,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416701,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416702,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416703,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416704,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416704,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416705,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416707,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416709,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416712,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416713,
     "City": "Ratnagiri",
     "District": "Ratnagiri",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416801,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416803,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416804,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416805,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416806,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416807,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416810,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416811,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416812,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 416813,
     "City": "Sindhudurg",
     "District": "Sindhudurg",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 420018,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421001,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421002,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421003,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421004,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421005,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421101,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421102,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421103,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421126,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421201,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421202,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421203,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421204,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421205,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421206,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421301,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421302,
     "City": "Bhiwandi",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421303,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421304,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421305,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421306,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421308,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421311,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421312,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421401,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421402,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421403,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421501,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421502,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421503,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421504,
     "City": "Badlapur",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421505,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421506,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421601,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421602,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421603,
     "City": "Thane",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421605,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 421607,
     "City": "Kalyan-Dombivali",
     "District": "Thane",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422001,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422002,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422003,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422004,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422005,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422006,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422007,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422008,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422009,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422010,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422011,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422012,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422013,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422036,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422065,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422101,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422102,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422103,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422104,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422105,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422112,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422113,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422201,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422202,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422203,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422204,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422205,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422206,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422207,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422208,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422209,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422210,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422211,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422212,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422213,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422214,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422215,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422221,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422222,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422301,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422302,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422303,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422304,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422305,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422306,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422308,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422401,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422402,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422403,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422501,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422502,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422601,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422602,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422603,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422604,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422605,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422606,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422608,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422610,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422611,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422620,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 422622,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423101,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423102,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423104,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423105,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423106,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423107,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423108,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423109,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423110,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423111,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423117,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423201,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423202,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423203,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423204,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423205,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423206,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423208,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423212,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423213,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423301,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423302,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423303,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423304,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423401,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423402,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423403,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423501,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423502,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423601,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423602,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423603,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423604,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423605,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423607,
     "City": "Ahmed Nagar",
     "District": "Ahmed Nagar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423701,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423702,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 423703,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424001,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424002,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424003,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424004,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424005,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424006,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424101,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424102,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424103,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424104,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424105,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424106,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424107,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424108,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424109,
     "City": "Nashik",
     "District": "Nashik",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424119,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424201,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424202,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424203,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424204,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424205,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424206,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424207,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424208,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424209,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424301,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424302,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424303,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424304,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424305,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424306,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424307,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424308,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424309,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424310,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424311,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 424318,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425001,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425002,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425003,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425004,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425101,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425102,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425103,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425104,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425105,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425107,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425108,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425109,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425110,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425111,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425112,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425113,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425114,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425115,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425116,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425127,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425135,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425201,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425203,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425301,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425302,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425303,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425304,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425305,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425306,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425307,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425308,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425309,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425310,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425311,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425324,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425327,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425401,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425402,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425403,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425404,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425405,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425406,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425407,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425408,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425409,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425410,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425411,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425412,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425413,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425413,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425414,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425415,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425416,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425417,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425418,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425419,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425420,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425421,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425422,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425422,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425423,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425424,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425426,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425427,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425428,
     "City": "Dhule",
     "District": "Dhule",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425432,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425442,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425444,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425448,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425452,
     "City": "Nandurbar",
     "District": "Nandurbar",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425501,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425502,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425503,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425504,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425505,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425506,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425507,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425508,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 425524,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 426001,
     "City": "Jalgaon",
     "District": "Jalgaon",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 430001,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431001,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431002,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431003,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431004,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431005,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431006,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431007,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431008,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431009,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431010,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431101,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431102,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431103,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431104,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431105,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431105,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431106,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431107,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431109,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431110,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431111,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431112,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431113,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431114,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431114,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431115,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431116,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431117,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431118,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431120,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431121,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431121,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431122,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431123,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431124,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431125,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431126,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431127,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431128,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431129,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431130,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431131,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431132,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431132,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431133,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431134,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431134,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431135,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431136,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431137,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431142,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431143,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431144,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431147,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431148,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431150,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431151,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431153,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431154,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431202,
     "City": "Aurangabad",
     "District": "Aurangabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431202,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431203,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431204,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431205,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431206,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431207,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431208,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431209,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431210,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431211,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431212,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431213,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431214,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431215,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431401,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431402,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431501,
     "City": "Hingoli",
     "District": "Hingoli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431501,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431502,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431503,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431503,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431504,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431505,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431506,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431507,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431508,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431509,
     "City": "Hingoli",
     "District": "Hingoli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431509,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431510,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431511,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431512,
     "City": "Hingoli",
     "District": "Hingoli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431512,
     "City": "Jalna",
     "District": "Jalna",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431513,
     "City": "Hingoli",
     "District": "Hingoli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431514,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431515,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431516,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431517,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431518,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431519,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431520,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431521,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431522,
     "City": "Latur",
     "District": "Latur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431522,
     "City": "Osmanabad",
     "District": "Osmanabad",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431523,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431530,
     "City": "Beed",
     "District": "Beed",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431536,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431537,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431540,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431541,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431542,
     "City": "Hingoli",
     "District": "Hingoli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431601,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431602,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431603,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431604,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431605,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431606,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431646,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431701,
     "City": "Hingoli",
     "District": "Hingoli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431702,
     "City": "Hingoli",
     "District": "Hingoli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431703,
     "City": "Hingoli",
     "District": "Hingoli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431704,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431705,
     "City": "Hingoli",
     "District": "Hingoli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431707,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431708,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431709,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431710,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431711,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431712,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431713,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431714,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431715,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431716,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431717,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431718,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431719,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431720,
     "City": "Parbhani",
     "District": "Parbhani",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431721,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431722,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431723,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431731,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431736,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431741,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431742,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431743,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431745,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431746,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431750,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431801,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431802,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431803,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431804,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431805,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431806,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431807,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431808,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431809,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431810,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 431811,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 434001,
     "City": "Nanded",
     "District": "Nanded",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440001,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440002,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440003,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440004,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440005,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440006,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440007,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440008,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440009,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440010,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440012,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440013,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440014,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440015,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440016,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440017,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440018,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440019,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440020,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440021,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440022,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440023,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440024,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440025,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440026,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440027,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440029,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440030,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440032,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440033,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440034,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440035,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440036,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440037,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440081,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440230,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 440401,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441001,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441002,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441101,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441102,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441103,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441104,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441105,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441106,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441107,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441108,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441109,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441110,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441111,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441112,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441113,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441122,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441123,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441133,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441201,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441202,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441203,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441204,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441205,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441206,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441207,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441207,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441208,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441209,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441210,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441212,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441214,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441215,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441217,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441221,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441222,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441223,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441224,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441225,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441228,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441301,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441302,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441303,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441304,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441305,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441306,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441401,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441404,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441501,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441502,
     "City": "Nagpur",
     "District": "Nagpur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441601,
     "City": "Gondia",
     "District": "Gondia",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441614,
     "City": "Gondia",
     "District": "Gondia",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441701,
     "City": "Gondia",
     "District": "Gondia",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441702,
     "City": "Gondia",
     "District": "Gondia",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441801,
     "City": "Gondia",
     "District": "Gondia",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441802,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441803,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441804,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441805,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441806,
     "City": "Gondia",
     "District": "Gondia",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441807,
     "City": "Gondia",
     "District": "Gondia",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441809,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441901,
     "City": "Gondia",
     "District": "Gondia",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441902,
     "City": "Gondia",
     "District": "Gondia",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441903,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441904,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441905,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441906,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441907,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441908,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441909,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441910,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441911,
     "City": "Gondia",
     "District": "Gondia",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441912,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441913,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441914,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441915,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441916,
     "City": "Gondia",
     "District": "Gondia",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441923,
     "City": "Gondia",
     "District": "Gondia",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 441924,
     "City": "Bhandara",
     "District": "Bhandara",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442001,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442003,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442101,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442102,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442104,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442105,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442106,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442111,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442201,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442202,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442203,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442301,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442302,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442302,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442303,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442304,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442305,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442306,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442307,
     "City": "Wardha",
     "District": "Wardha",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442401,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442402,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442403,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442404,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442406,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442501,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442502,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442503,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442504,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442504,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442505,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442507,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442603,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442604,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442605,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442605,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442606,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442606,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442701,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442702,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442703,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442704,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442705,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442707,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442709,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442710,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442710,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442901,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442902,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442903,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442903,
     "City": "Gadchiroli",
     "District": "Gadchiroli",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442904,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442905,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442906,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442907,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442908,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442914,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442916,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442917,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 442918,
     "City": "Chandrapur",
     "District": "Chandrapur",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443001,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443002,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443101,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443102,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443103,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443104,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443106,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443112,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443201,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443202,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443203,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443204,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443206,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443301,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443302,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443303,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443304,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443308,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443401,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443402,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443403,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 443404,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444001,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444002,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444003,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444004,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444004,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444005,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444006,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444101,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444102,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444103,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444104,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444105,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444106,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444106,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444107,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444108,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444109,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444110,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444111,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444117,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444126,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444201,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444202,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444203,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444204,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444301,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444302,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444303,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444304,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444306,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444311,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444312,
     "City": "Buldhana",
     "District": "Buldhana",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444401,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444402,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444403,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444404,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444405,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444407,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444409,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444501,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444502,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444503,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444504,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444505,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444506,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444506,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444507,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444510,
     "City": "Washim",
     "District": "Washim",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444511,
     "City": "Akola",
     "District": "Akola",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444601,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444602,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444603,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444604,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444605,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444606,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444607,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444701,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444702,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444704,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444705,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444706,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444707,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444708,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444709,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444710,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444711,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444717,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444719,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444720,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444723,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444801,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444802,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444803,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444804,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444805,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444806,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444807,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444808,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444809,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444810,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444813,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444901,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444902,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444903,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444904,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444905,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444906,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444907,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444908,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 444914,
     "City": "Amravati",
     "District": "Amravati",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445001,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445002,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445101,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445102,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445103,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445105,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445106,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445109,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445110,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445201,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445202,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445203,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445204,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445205,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445206,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445207,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445209,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445210,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445211,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445215,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445216,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445230,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445301,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445302,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445303,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445304,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445305,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445306,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445307,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445308,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445323,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445401,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 445402,
     "City": "Yavatmal",
     "District": "Yavatmal",
     "State": "Maharashtra"
    },
    {
     "Pin Code": 450001,
     "City": "Burhanpur",
     "District": "Burhanpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450001,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450006,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450008,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450051,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450052,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450110,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450111,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450112,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450112,
     "City": "Khandwa",
     "District": "Khandwa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450114,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450115,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450116,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450116,
     "City": "Khandwa",
     "District": "Khandwa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450117,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450117,
     "City": "Khandwa",
     "District": "Khandwa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450118,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450119,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450220,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450221,
     "City": "Burhanpur",
     "District": "Burhanpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450221,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450223,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450327,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450331,
     "City": "Burhanpur",
     "District": "Burhanpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450331,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450332,
     "City": "Burhanpur",
     "District": "Burhanpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450332,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450333,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450334,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450335,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450337,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450337,
     "City": "Khandwa",
     "District": "Khandwa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450441,
     "City": "Burhanpur",
     "District": "Burhanpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450445,
     "City": "Burhanpur",
     "District": "Burhanpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450445,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450551,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450551,
     "City": "Khandwa",
     "District": "Khandwa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450551,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450554,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450554,
     "City": "Khandwa",
     "District": "Khandwa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450554,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450661,
     "City": "Khandwa",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450661,
     "City": "Khandwa",
     "District": "Khandwa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450771,
     "City": "Khandwa",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450771,
     "City": "Khandwa",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450881,
     "City": "Khandwa",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 450991,
     "City": "Khandwa",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451001,
     "City": "Khargone",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451007,
     "City": "Khargone",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451110,
     "City": "Barwaha",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451111,
     "City": "Barwaha",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451112,
     "City": "Barwaha",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451113,
     "City": "Barwaha",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451115,
     "City": "Barwaha",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451115,
     "City": "Barwaha",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451115,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451117,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451220,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451221,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451222,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451223,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451224,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451225,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451228,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451229,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451239,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451331,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451331,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451332,
     "City": "Khargone",
     "District": "Khargone",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451332,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451335,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451440,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451441,
     "City": "East Nimar",
     "District": "East Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451441,
     "City": "Khargone",
     "District": "Khargone",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451441,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451442,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451442,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451443,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451444,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451447,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451449,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451551,
     "City": "Barwaha",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451551,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451552,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451555,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451556,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451563,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451659,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451660,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451660,
     "City": "Khargone",
     "District": "Khargone",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451660,
     "City": "West Nimar",
     "District": "West Nimar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451661,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451665,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451666,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451770,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 451881,
     "City": "Barwani",
     "District": "Barwani",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452001,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452002,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452003,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452004,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452005,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452006,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452007,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452008,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452009,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452010,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452011,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452012,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452013,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452014,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452015,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452016,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452017,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452018,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452020,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452037,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452056,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 452101,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453001,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453111,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453112,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453115,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453220,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453235,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453331,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453332,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453441,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453446,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453551,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453555,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453556,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453562,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453576,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453643,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453652,
     "City": "Dewas",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453661,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 453771,
     "City": "Indore",
     "District": "Indore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454001,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454010,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454111,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454115,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454116,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454221,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454276,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454331,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454333,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454335,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454365,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454441,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454446,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454448,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454449,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454552,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454554,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454571,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454660,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454665,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454729,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454749,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454773,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454774,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454775,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 454779,
     "City": "Dhar",
     "District": "Dhar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455001,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455003,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455010,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455048,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455111,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455112,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455115,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455116,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455117,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455118,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455119,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455201,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455221,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455223,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455225,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455227,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455332,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455336,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455339,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455440,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 455459,
     "City": "Dewas",
     "District": "Dewas",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456001,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456002,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456003,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456006,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456008,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456009,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456010,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456101,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456221,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456222,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456224,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456313,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456331,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456335,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456337,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456440,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456441,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456443,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456450,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456550,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456551,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456552,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456661,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456663,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456664,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456665,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456666,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456668,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456770,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456771,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 456776,
     "City": "Ujjain",
     "District": "Ujjain",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457001,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457111,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457114,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457118,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457119,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457222,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457226,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457331,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457333,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457336,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457339,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457340,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457441,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457550,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457550,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457551,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457552,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457555,
     "City": "Ratlam",
     "District": "Ratlam",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457661,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457769,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457770,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457772,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457773,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457775,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457777,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457779,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457882,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457885,
     "City": "Alirajpur",
     "District": "Alirajpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457885,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457887,
     "City": "Alirajpur",
     "District": "Alirajpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457887,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457888,
     "City": "Alirajpur",
     "District": "Alirajpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457888,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457893,
     "City": "Alirajpur",
     "District": "Alirajpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457990,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 457993,
     "City": "Jhabua",
     "District": "Jhabua",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458001,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458002,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458110,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458113,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458116,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458118,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458220,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458226,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458228,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458272,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458330,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458336,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458339,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458339,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458389,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458441,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458468,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458470,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458474,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458553,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458553,
     "City": "Neemuch",
     "District": "Neemuch",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458556,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458558,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458588,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458660,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458664,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458667,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458669,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458676,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458771,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458775,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458778,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458880,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458883,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458888,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458895,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 458990,
     "City": "Mandsaur",
     "District": "Mandsaur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460001,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460004,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460005,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460046,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460110,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460220,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460225,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460330,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460440,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460443,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460444,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460447,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460449,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460551,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460553,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460554,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460557,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460660,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460661,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460663,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460663,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460665,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460666,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 460668,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461001,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461005,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461110,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461111,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461111,
     "City": "Harda",
     "District": "Harda",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461111,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461114,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461115,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461116,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461122,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461221,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461223,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461224,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461228,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461228,
     "City": "Harda",
     "District": "Harda",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461228,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461251,
     "City": "Harda",
     "District": "Harda",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461331,
     "City": "Harda",
     "District": "Harda",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461331,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461335,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461441,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461441,
     "City": "Harda",
     "District": "Harda",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461441,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461446,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461551,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461661,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461668,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461771,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461771,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461775,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461881,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461990,
     "City": "Hoshangabad",
     "District": "Hoshangabad",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 461990,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462001,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462002,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462003,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462004,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462006,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462007,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462008,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462010,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462011,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462012,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462013,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462015,
     "City": "Betul",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462016,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462017,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462018,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462020,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462021,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462022,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462023,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462024,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462026,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462027,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462030,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462031,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462032,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462033,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462036,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462037,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462038,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462039,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462040,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462041,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462042,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462043,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462044,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462045,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462046,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462047,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462066,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462101,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462120,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 462420,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 463101,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 463106,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 463111,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 463120,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464001,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464002,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464111,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464112,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464113,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464114,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464220,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464221,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464224,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464226,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464228,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464240,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464240,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464258,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464331,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464337,
     "City": "Vidisha",
     "District": "Vidisha",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464551,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464651,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464661,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464665,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464668,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464671,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464672,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464770,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464774,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464776,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464881,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464884,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464886,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464986,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464990,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464993,
     "City": "Bhopal",
     "District": "Bhopal",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 464993,
     "City": "Raisen",
     "District": "Raisen",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465001,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465106,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465110,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465113,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465116,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465118,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465220,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465221,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465223,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465226,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465227,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465230,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465231,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465331,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465333,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465335,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465337,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465339,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465418,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465440,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465441,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465445,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465447,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465449,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465550,
     "City": "Shajapur",
     "District": "Shajapur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465661,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465667,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465669,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465672,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465674,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465677,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465679,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465680,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465683,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465685,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465687,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465689,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465691,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465693,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465693,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 465697,
     "City": "Rajgarh",
     "District": "Rajgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466001,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466111,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466113,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466114,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466115,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466116,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466117,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466118,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466119,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466120,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466125,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466221,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466331,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466445,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466446,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466448,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466554,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466651,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466661,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 466665,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 468001,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 469031,
     "City": "Sehore",
     "District": "Sehore",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470001,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470002,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470003,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470004,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470005,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470012,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470021,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470051,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470111,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470113,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470115,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470117,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470118,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470119,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470120,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470124,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470125,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470221,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470223,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470226,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470227,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470228,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470229,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470232,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470235,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470335,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470337,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470339,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470441,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470442,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470661,
     "City": "Damoh",
     "District": "Damoh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470663,
     "City": "Damoh",
     "District": "Damoh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470664,
     "City": "Damoh",
     "District": "Damoh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470666,
     "City": "Damoh",
     "District": "Damoh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470669,
     "City": "Sagar",
     "District": "Sagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470672,
     "City": "Damoh",
     "District": "Damoh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470673,
     "City": "Damoh",
     "District": "Damoh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470675,
     "City": "Damoh",
     "District": "Damoh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470771,
     "City": "Damoh",
     "District": "Damoh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470772,
     "City": "Damoh",
     "District": "Damoh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470775,
     "City": "Damoh",
     "District": "Damoh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470880,
     "City": "Damoh",
     "District": "Damoh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 470881,
     "City": "Damoh",
     "District": "Damoh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471001,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471101,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471105,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471111,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471201,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471202,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471301,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471311,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471313,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471315,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471318,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471401,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471405,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471408,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471411,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471501,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471505,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471510,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471515,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471516,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471525,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471606,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 471625,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472001,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472001,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472004,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472005,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472010,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472010,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472101,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472111,
     "City": "Chhatarpur",
     "District": "Chhatarpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472111,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472115,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472118,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472221,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472246,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472331,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472336,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472337,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472338,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472339,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472442,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472445,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472446,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 472447,
     "City": "Tikamgarh",
     "District": "Tikamgarh",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473001,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473101,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473105,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473110,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473111,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473112,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473113,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473115,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473118,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473222,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473226,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473249,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473287,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473330,
     "City": "Ashok Nagar",
     "District": "Ashok Nagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473331,
     "City": "Ashok Nagar",
     "District": "Ashok Nagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473331,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473332,
     "City": "Ashok Nagar",
     "District": "Ashok Nagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473333,
     "City": "Ashok Nagar",
     "District": "Ashok Nagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473335,
     "City": "Ashok Nagar",
     "District": "Ashok Nagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473440,
     "City": "Ashok Nagar",
     "District": "Ashok Nagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473443,
     "City": "Ashok Nagar",
     "District": "Ashok Nagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473443,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473444,
     "City": "Ashok Nagar",
     "District": "Ashok Nagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473446,
     "City": "Ashok Nagar",
     "District": "Ashok Nagar",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473551,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473585,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473638,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473660,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473662,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473665,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473670,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473770,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473774,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473775,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473781,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473793,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473865,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473880,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473885,
     "City": "Guna",
     "District": "Guna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473885,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473990,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 473995,
     "City": "Shivpuri",
     "District": "Shivpuri",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474001,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474002,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474003,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474004,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474005,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474006,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474007,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474008,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474009,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474010,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474011,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474012,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474015,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474020,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474022,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474040,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 474042,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475001,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475002,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475005,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475110,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475110,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475115,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475115,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475220,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475330,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475335,
     "City": "Datia",
     "District": "Datia",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475336,
     "City": "Datia",
     "District": "Datia",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475661,
     "City": "Datia",
     "District": "Datia",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475671,
     "City": "Datia",
     "District": "Datia",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475673,
     "City": "Datia",
     "District": "Datia",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475675,
     "City": "Datia",
     "District": "Datia",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475677,
     "City": "Datia",
     "District": "Datia",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475682,
     "City": "Datia",
     "District": "Datia",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475684,
     "City": "Datia",
     "District": "Datia",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475685,
     "City": "Datia",
     "District": "Datia",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475686,
     "City": "Datia",
     "District": "Datia",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 475686,
     "City": "Gwalior",
     "District": "Gwalior",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476001,
     "City": "Morena",
     "District": "Morena",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476111,
     "City": "Morena",
     "District": "Morena",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476115,
     "City": "Morena",
     "District": "Morena",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476134,
     "City": "Morena",
     "District": "Morena",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476219,
     "City": "Morena",
     "District": "Morena",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476221,
     "City": "Morena",
     "District": "Morena",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476224,
     "City": "Morena",
     "District": "Morena",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476228,
     "City": "Morena",
     "District": "Morena",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476229,
     "City": "Morena",
     "District": "Morena",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476332,
     "City": "Sheopur",
     "District": "Sheopur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476335,
     "City": "Sheopur",
     "District": "Sheopur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476337,
     "City": "Sheopur",
     "District": "Sheopur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476339,
     "City": "Sheopur",
     "District": "Sheopur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476345,
     "City": "Sheopur",
     "District": "Sheopur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476355,
     "City": "Sheopur",
     "District": "Sheopur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476444,
     "City": "Morena",
     "District": "Morena",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 476554,
     "City": "Morena",
     "District": "Morena",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477001,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477105,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477111,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477116,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477117,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477222,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477227,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477331,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477332,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477332,
     "City": "Sheopur",
     "District": "Sheopur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477333,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477335,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477441,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477445,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477446,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477447,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477449,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477555,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477557,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477566,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 477660,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 479006,
     "City": "Bhind",
     "District": "Bhind",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480001,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480002,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480105,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480106,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480107,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480108,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480109,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480110,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480111,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480115,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480221,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480223,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480224,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480225,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480228,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480331,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480334,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480337,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480338,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480441,
     "City": "Betul",
     "District": "Betul",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480441,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480442,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480443,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480447,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480449,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480551,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480553,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480554,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480555,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480557,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480559,
     "City": "Chhindwara",
     "District": "Chhindwara",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480661,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480667,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480771,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480880,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480881,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480882,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480884,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480886,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480887,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480888,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480990,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480991,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480994,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480996,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480997,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 480999,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481001,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481022,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481051,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481102,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481105,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481111,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481115,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481116,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481117,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481222,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481224,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481226,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481226,
     "City": "Seoni",
     "District": "Seoni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481331,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481332,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481335,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481337,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481441,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481445,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481449,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481551,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481554,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481556,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481661,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481661,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481662,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481663,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481664,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481665,
     "City": "Balaghat",
     "District": "Balaghat",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481665,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481666,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481668,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481672,
     "City": "Dindori",
     "District": "Dindori",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481672,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481768,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481771,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481776,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481778,
     "City": "Dindori",
     "District": "Dindori",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481879,
     "City": "Dindori",
     "District": "Dindori",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481880,
     "City": "Dindori",
     "District": "Dindori",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481882,
     "City": "Dindori",
     "District": "Dindori",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481884,
     "City": "Dindori",
     "District": "Dindori",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481885,
     "City": "Dindori",
     "District": "Dindori",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481885,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481980,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481990,
     "City": "Dindori",
     "District": "Dindori",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481993,
     "City": "Dindori",
     "District": "Dindori",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481995,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481996,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 481998,
     "City": "Mandla",
     "District": "Mandla",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482001,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482002,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482003,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482004,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482005,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482006,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482007,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482008,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482009,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482010,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482011,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482012,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482020,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482021,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482051,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482053,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 482056,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483001,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483002,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483053,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483105,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483110,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483113,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483119,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483220,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483220,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483222,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483222,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483225,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483225,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483330,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483331,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483332,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483334,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483334,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483336,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483440,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483440,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483442,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483445,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483501,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483504,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483770,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483773,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483775,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483880,
     "City": "Jabalpur",
     "District": "Jabalpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483880,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 483990,
     "City": "Katni",
     "District": "Katni",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484001,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484001,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484001,
     "City": "Umaria",
     "District": "Umaria",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484110,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484113,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484114,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484116,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484117,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484117,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484120,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484220,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484224,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484330,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484330,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484334,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484336,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484440,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484440,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484444,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484444,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484446,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484446,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484551,
     "City": "Umaria",
     "District": "Umaria",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484552,
     "City": "Umaria",
     "District": "Umaria",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484555,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484555,
     "City": "Umaria",
     "District": "Umaria",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484660,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484660,
     "City": "Umaria",
     "District": "Umaria",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484661,
     "City": "Umaria",
     "District": "Umaria",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484664,
     "City": "Umaria",
     "District": "Umaria",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484665,
     "City": "Umaria",
     "District": "Umaria",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484669,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484770,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484770,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484771,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484771,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484774,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484776,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484881,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484881,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 484886,
     "City": "Anuppur",
     "District": "Anuppur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485001,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485002,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485005,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485111,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485112,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485113,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485114,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485115,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485220,
     "City": "Rewa",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485221,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485226,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485331,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485331,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485334,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485344,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485441,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485446,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485447,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485551,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485661,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485666,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485771,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485772,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485773,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485774,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485775,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485778,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 485881,
     "City": "Satna",
     "District": "Satna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486001,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486002,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486003,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486004,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486005,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486006,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486111,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486112,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486113,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486114,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486115,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486117,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486123,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486220,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486223,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486226,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486331,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486333,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486335,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486338,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486340,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486341,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486440,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486441,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486442,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486445,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486446,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486447,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486448,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486450,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486451,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486550,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486551,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486552,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486553,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486556,
     "City": "Rewa",
     "District": "Rewa",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486661,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486661,
     "City": "Umaria",
     "District": "Umaria",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486666,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486669,
     "City": "Shahdol",
     "District": "Shahdol",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486669,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486670,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486675,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486771,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486775,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486776,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486881,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486882,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486884,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486885,
     "City": "Singrauli",
     "District": "Singrauli",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486886,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486886,
     "City": "Singrauli",
     "District": "Singrauli",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486887,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486887,
     "City": "Singrauli",
     "District": "Singrauli",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486888,
     "City": "Singrauli",
     "District": "Singrauli",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486889,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486889,
     "City": "Singrauli",
     "District": "Singrauli",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486890,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 486892,
     "City": "Sidhi",
     "District": "Sidhi",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487001,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487110,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487114,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487118,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487221,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487225,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487330,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487334,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487337,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487441,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487551,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487555,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487661,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487770,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487771,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 487881,
     "City": "Narsinghpur",
     "District": "Narsinghpur",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488001,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488050,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488051,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488058,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488059,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488101,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488220,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488222,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488333,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488441,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488442,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488443,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488446,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 488448,
     "City": "Panna",
     "District": "Panna",
     "State": "Madhya Pradesh"
    },
    {
     "Pin Code": 490001,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490006,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490008,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490009,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490011,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490012,
     "City": "Bhilai",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490020,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490021,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490022,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490023,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490024,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490025,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490026,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490036,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 490042,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491001,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491002,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491003,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491005,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491006,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491107,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491111,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491113,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491221,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491222,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491223,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491225,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491226,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491227,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491228,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491229,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491331,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491332,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491335,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491336,
     "City": "Kawardha",
     "District": "Kawardha",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491337,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491338,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491340,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491441,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491443,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491444,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491445,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491557,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491558,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491559,
     "City": "Kawardha",
     "District": "Kawardha",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491661,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491665,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491666,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491668,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491771,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491881,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491885,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491888,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491888,
     "City": "Rajnandgaon",
     "District": "Rajnandgaon",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491991,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491993,
     "City": "Durg",
     "District": "Durg",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 491995,
     "City": "Kawardha",
     "District": "Kawardha",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492001,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492002,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492003,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492004,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492005,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492006,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492007,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492008,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492009,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492010,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492011,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492012,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492013,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492014,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492015,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492099,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492101,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492109,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492112,
     "City": "Mahasamund",
     "District": "Mahasamund",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492112,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 492206,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493101,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493111,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493113,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493114,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493116,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493118,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493195,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493196,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493221,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493222,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493225,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493228,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493229,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493330,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493331,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493332,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493335,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493338,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493344,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493441,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493445,
     "City": "Mahasamund",
     "District": "Mahasamund",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493448,
     "City": "Mahasamund",
     "District": "Mahasamund",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493449,
     "City": "Mahasamund",
     "District": "Mahasamund",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493526,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493551,
     "City": "Mahasamund",
     "District": "Mahasamund",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493551,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493554,
     "City": "Mahasamund",
     "District": "Mahasamund",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493555,
     "City": "Mahasamund",
     "District": "Mahasamund",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493558,
     "City": "Mahasamund",
     "District": "Mahasamund",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493559,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493562,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493661,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493662,
     "City": "Dhamtari",
     "District": "Dhamtari",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493663,
     "City": "Dhamtari",
     "District": "Dhamtari",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493663,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493770,
     "City": "Dhamtari",
     "District": "Dhamtari",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493773,
     "City": "Dhamtari",
     "District": "Dhamtari",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493776,
     "City": "Dhamtari",
     "District": "Dhamtari",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493778,
     "City": "Dhamtari",
     "District": "Dhamtari",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493881,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493885,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493887,
     "City": "Gariaband",
     "District": "Gariaband",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493888,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493889,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493890,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493891,
     "City": "Gariaband",
     "District": "Gariaband",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493992,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 493996,
     "City": "Raipur",
     "District": "Raipur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494001,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494002,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494005,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494010,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494111,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494111,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494114,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494115,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494122,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494221,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494222,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494223,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494224,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494226,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494228,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494229,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494230,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494237,
     "City": "Kondagaon",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494331,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494332,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494333,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494334,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494335,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494336,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494337,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494347,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494441,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494442,
     "City": "Bastar",
     "District": "Bastar",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494444,
     "City": "Bijapur(CGH)",
     "District": "Bijapur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494446,
     "City": "Bijapur(CGH)",
     "District": "Bijapur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494447,
     "City": "Bijapur(CGH)",
     "District": "Bijapur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494448,
     "City": "Bijapur(CGH)",
     "District": "Bijapur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494448,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494449,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494450,
     "City": "Bijapur(CGH)",
     "District": "Bijapur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494450,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494551,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494552,
     "City": "Bijapur(CGH)",
     "District": "Bijapur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494552,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494553,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494554,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494556,
     "City": "Dantewada",
     "District": "Dantewada",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494635,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494661,
     "City": "Narayanpur",
     "District": "Narayanpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494665,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494669,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494670,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494771,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494775,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494776,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 494777,
     "City": "Kanker",
     "District": "Kanker",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495001,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495001,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495003,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495004,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495006,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495007,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495009,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495112,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495113,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495115,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495116,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495117,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495118,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495118,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495119,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495220,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495222,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495223,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495224,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495330,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495334,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495335,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495442,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495445,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495446,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495447,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495448,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495449,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495450,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495452,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495454,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495455,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495549,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495550,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495551,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495552,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495552,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495553,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495554,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495555,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495556,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495557,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495559,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495660,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495660,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495661,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495661,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495663,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495663,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495668,
     "City": "Bilaspur(CGH)",
     "District": "Bilaspur(CGH)",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495668,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495671,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495674,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495677,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495678,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495682,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495683,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495684,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495684,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495686,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495687,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495687,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495688,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495689,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495690,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495690,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495691,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495691,
     "City": "Korba",
     "District": "Korba",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495692,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 495695,
     "City": "Janjgir-champa",
     "District": "Janjgir-champa",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496001,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496004,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496005,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496100,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496107,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496108,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496109,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496111,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496113,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496115,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496116,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496118,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496220,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496223,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496224,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496225,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496227,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496242,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496242,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496245,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496330,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496331,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496334,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496336,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496338,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496440,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496445,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496450,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496460,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496551,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496554,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496661,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 496665,
     "City": "Raigarh",
     "District": "Raigarh",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497001,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497101,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497111,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497114,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497116,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497118,
     "City": "Jashpur",
     "District": "Jashpur",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497118,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497119,
     "City": "Balrampur",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497220,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497223,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497224,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497225,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497226,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497229,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497231,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497235,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497331,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497333,
     "City": "Surguja",
     "District": "Surguja",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497335,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497339,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497442,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497446,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497447,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497448,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497449,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497451,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497553,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497555,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497557,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497559,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497773,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 497778,
     "City": "Koriya",
     "District": "Koriya",
     "State": "Chhattisgarh"
    },
    {
     "Pin Code": 500001,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500002,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500003,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500004,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500005,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500005,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500006,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500007,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500008,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500008,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500009,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500010,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500011,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500012,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500013,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500014,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500015,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500015,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500016,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500017,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500018,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500018,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500019,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500020,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500021,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500022,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500023,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500024,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500025,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500026,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500027,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500028,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500029,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500030,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500030,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500031,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500032,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500033,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500034,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500035,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500035,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500036,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500037,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500037,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500038,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500039,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500039,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500040,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500041,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500042,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500043,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500043,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500044,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500045,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500046,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500047,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500047,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500048,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500049,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500049,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500050,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500051,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500052,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500053,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500054,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500055,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500056,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500057,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500058,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500059,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500060,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500061,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500062,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500063,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500064,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500065,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500066,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500067,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500068,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500069,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500070,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500072,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500073,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500074,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500074,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500075,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500076,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500077,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500078,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500078,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500079,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500080,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500081,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500082,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500083,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500084,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500085,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500086,
     "City": "Hyderabad",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500087,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500088,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500088,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500089,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500090,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500090,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500091,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500092,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500093,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500094,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500095,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500096,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500097,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500097,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500098,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500100,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500101,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500101,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 500108,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500180,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500213,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500285,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500300,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500333,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500361,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500423,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500602,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500701,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 500873,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 501101,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 501102,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501106,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501111,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501121,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501141,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501142,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501143,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501144,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501158,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501202,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501203,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501218,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501301,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 501301,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501359,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501401,
     "City": "Hyderabad",
     "District": "Hyderabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 501401,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501500,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501501,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501502,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501503,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501504,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501505,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501506,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501507,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501508,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501509,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501510,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501511,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 501512,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 502001,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502032,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502101,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502102,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502103,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502104,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502107,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502108,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502109,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502110,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502113,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502114,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502115,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502117,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502125,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502130,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502205,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502210,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502220,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502221,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502228,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502246,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502247,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502248,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502249,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502251,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502255,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502256,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502257,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502267,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502269,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502270,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502271,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502273,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502276,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502277,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502278,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502279,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502280,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502281,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502285,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502286,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502287,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502289,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502290,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502291,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502292,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502293,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502294,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502295,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502296,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502297,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502299,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502300,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502301,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502302,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502303,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502304,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502305,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502306,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502307,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502308,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502310,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502311,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502312,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502313,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502314,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502316,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502318,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502319,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502321,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502323,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502324,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502325,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502329,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502330,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502331,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502334,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502335,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502336,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502345,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502371,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502372,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502374,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502375,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 502381,
     "City": "Medak",
     "District": "Medak",
     "State": "Telangana"
    },
    {
     "Pin Code": 503001,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503002,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503003,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503101,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503102,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503108,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503110,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503111,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503114,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503120,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503122,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503123,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503124,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503125,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503144,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503145,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503164,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503165,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503174,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503175,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503180,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503185,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503186,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503187,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503188,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503201,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503202,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503206,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503207,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503212,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503213,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503214,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503217,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503218,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503219,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503224,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503225,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503230,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503235,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503245,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503246,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503301,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503302,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503305,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503306,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503307,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503308,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503309,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503310,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503311,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503321,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 503322,
     "City": "Nizamabad",
     "District": "Nizamabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504001,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504002,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504101,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504102,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504103,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504104,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504105,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504106,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504107,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504109,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504110,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504201,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504202,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504203,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504204,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504205,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504206,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504207,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504208,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504209,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504214,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504215,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504216,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504218,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504219,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504220,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504231,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504251,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504272,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504273,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504292,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504293,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504294,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504295,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504296,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504297,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504299,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504301,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504302,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504303,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504304,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504306,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504307,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504308,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504309,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504310,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504311,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504312,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504313,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504323,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 504346,
     "City": "Adilabad",
     "District": "Adilabad",
     "State": "Telangana"
    },
    {
     "Pin Code": 505001,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505002,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505005,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505101,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505102,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505122,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505129,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505152,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505153,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505162,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505172,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505174,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505184,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505185,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505186,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505187,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505188,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505208,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505209,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505210,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505211,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505212,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505214,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505215,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505301,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505302,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505303,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505304,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505305,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505306,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505307,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505325,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505326,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505327,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505330,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505331,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505401,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505402,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505403,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505404,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505405,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505415,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505416,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505425,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505445,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505450,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505451,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505452,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505453,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505454,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505455,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505460,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505462,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505466,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505467,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505468,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505469,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505470,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505471,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505472,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505473,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505474,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505475,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505476,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505480,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505481,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505490,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505497,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505498,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505501,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505502,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505503,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505504,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505505,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505514,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505524,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505525,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505526,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505527,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505528,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505529,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505530,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505531,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505532,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 505548,
     "City": "Karim Nagar",
     "District": "Karim Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 506001,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506002,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506003,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506004,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506005,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506006,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506007,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506008,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506009,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506011,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506012,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506013,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506015,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506101,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506102,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506104,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506105,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506112,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506122,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506132,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506134,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506135,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506142,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506143,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506144,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506145,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506151,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506163,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506164,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506165,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506166,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506167,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506168,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506169,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506170,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506172,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506175,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506201,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506221,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506222,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506223,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506224,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506244,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506252,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506301,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506302,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506303,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506310,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506313,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506314,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506315,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506316,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506317,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506318,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506319,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506324,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506329,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506330,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506331,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506332,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506342,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506343,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506344,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506345,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506347,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506348,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506349,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506352,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506355,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506356,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506365,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506366,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506367,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506368,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506369,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506370,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506371,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506381,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 506391,
     "City": "Warangal",
     "District": "Warangal",
     "State": "Telangana"
    },
    {
     "Pin Code": 507001,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507002,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507003,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507101,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507103,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507111,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507113,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507114,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507115,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507116,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507117,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507118,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507119,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507120,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507121,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507122,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507123,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507124,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507125,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507126,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507128,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507129,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507130,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 507133,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507134,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507135,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507136,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507137,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507138,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507140,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507154,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507157,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507158,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507159,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507160,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507161,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507163,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507164,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507165,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507166,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507167,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507168,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507169,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507170,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507182,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507183,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507201,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507202,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507203,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507204,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507208,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507209,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507210,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507211,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507216,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507301,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507302,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507303,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507304,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507305,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507306,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507307,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507316,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 507318,
     "City": "Khammam",
     "District": "Khammam",
     "State": "Telangana"
    },
    {
     "Pin Code": 508001,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508002,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508004,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508101,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508105,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508111,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508112,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508113,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508114,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508115,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508116,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508126,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508128,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508201,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508202,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508204,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508205,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508206,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508207,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508208,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508210,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508211,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508212,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508213,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508216,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508217,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508218,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508221,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508222,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508223,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508224,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508227,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508233,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508234,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508238,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508243,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508244,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508245,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508246,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508247,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508248,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508250,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508252,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508253,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508254,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508255,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508256,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508257,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508258,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508266,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508277,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508278,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508279,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508280,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508283,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508284,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508285,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508286,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508355,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508373,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508374,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508376,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 508377,
     "City": "Nalgonda",
     "District": "Nalgonda",
     "State": "Telangana"
    },
    {
     "Pin Code": 509001,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509102,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509103,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509104,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509105,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509110,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509120,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509125,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509126,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509127,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509128,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509129,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509130,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509131,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509132,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509133,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509135,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509152,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509153,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509201,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509202,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509203,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509204,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509205,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509206,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509207,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509208,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509209,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509210,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509215,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509216,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509217,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 509217,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509219,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509225,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509228,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509235,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509301,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509311,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509320,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509321,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509324,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509325,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 509325,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509326,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509327,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509334,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509335,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 509335,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509336,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509337,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509338,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509339,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 509339,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509340,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509349,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509350,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509351,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509352,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509353,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509357,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509358,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 509358,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509360,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509371,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 509371,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509375,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509376,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509380,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509381,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509382,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509385,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509401,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509406,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509407,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509408,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509409,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509410,
     "City": "Rangareddy",
     "District": "K.V.Rangareddy",
     "State": "Telangana"
    },
    {
     "Pin Code": 509410,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509411,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 509412,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 510002,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 510201,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 510700,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 510801,
     "City": "Mahabub Nagar",
     "District": "Mahabub Nagar",
     "State": "Telangana"
    },
    {
     "Pin Code": 515001,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515002,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515003,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515004,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515005,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515101,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515110,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515122,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515123,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515124,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515133,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515134,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515144,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515154,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515159,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515164,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515201,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515202,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515211,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515212,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515231,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515234,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515241,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515261,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515271,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515281,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515286,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515291,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515301,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515303,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515305,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515311,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515321,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515331,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515341,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515401,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515402,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515405,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515408,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515411,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515412,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515413,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515414,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515415,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515425,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515435,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515445,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515455,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515465,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515465,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515501,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515511,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515521,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515531,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515541,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515551,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515556,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515561,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515571,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515581,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515591,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515601,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515611,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515621,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515631,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515641,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515651,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515661,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515671,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515672,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515701,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515711,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515721,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515722,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515731,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515741,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515751,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515761,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515763,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515765,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515766,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515767,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515774,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515775,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515787,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515801,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515803,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515812,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515822,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515832,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515842,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515863,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515865,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515867,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515870,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515871,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 515872,
     "City": "Ananthapur",
     "District": "Ananthapur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516001,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516002,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516003,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516004,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516101,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516104,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516105,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516107,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516108,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516110,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516115,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516126,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516127,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516128,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516129,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516130,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516150,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516151,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516152,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516162,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516163,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516172,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516173,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516175,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516193,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516203,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516213,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516214,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516215,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516216,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516217,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516218,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516227,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516228,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516233,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516237,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516247,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516257,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516259,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516267,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516268,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516269,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516270,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516289,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516293,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516309,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516310,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516311,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516312,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516321,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516329,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516339,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516349,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516350,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516355,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516356,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516359,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516360,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516361,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516362,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516380,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516390,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516391,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516396,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516401,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516411,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516421,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516431,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516432,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516433,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516434,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516439,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516444,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516454,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516464,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516474,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516484,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516501,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516502,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516503,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516504,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516505,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 516507,
     "City": "Cuddapah",
     "District": "Cuddapah",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517001,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517002,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517004,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517101,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517102,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517103,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517111,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517112,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517113,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517123,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517124,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517125,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517126,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517127,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517128,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517129,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517130,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517131,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517132,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517152,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517167,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517172,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517192,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517193,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517194,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517213,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517214,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517234,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517235,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517236,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517237,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517247,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517257,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517277,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517280,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517291,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517292,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517297,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517299,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517305,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517319,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517325,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517326,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517350,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517351,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517352,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517370,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517390,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517391,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517401,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517403,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517408,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517414,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517415,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517416,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517417,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517418,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517419,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517421,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517422,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517423,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517424,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517425,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517426,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517429,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517432,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517501,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517502,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517503,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517504,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517505,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517506,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517507,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517520,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517526,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517536,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517541,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517551,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517561,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517569,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517571,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517581,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517582,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517583,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517584,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517586,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517587,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517588,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517589,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517590,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517591,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517592,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517599,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517619,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517620,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517636,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517640,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517641,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517642,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517643,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517644,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 517645,
     "City": "Chittoor",
     "District": "Chittoor",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518001,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518002,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518003,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518004,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518005,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518006,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518007,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518010,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518020,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518022,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518101,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518102,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518104,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518112,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518122,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518123,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518124,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518134,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518135,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518145,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518155,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518165,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518166,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518176,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518186,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518196,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518206,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518216,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518217,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518218,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518220,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518221,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518222,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518225,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518301,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518302,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518308,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518313,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518319,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518323,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518333,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518343,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518344,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518345,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518346,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518347,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518348,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518349,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518350,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518360,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518380,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518385,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518390,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518395,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518396,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518401,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518405,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518411,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518412,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518422,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518432,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518442,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518452,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518462,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518463,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518464,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518465,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518466,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518467,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518468,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518501,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518502,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518503,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518508,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518510,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518511,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518512,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518513,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518523,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518533,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518543,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518553,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518563,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518573,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518583,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518593,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518594,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518598,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518599,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 518674,
     "City": "Kurnool",
     "District": "Kurnool",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520001,
     "City": "Vijayawada",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520002,
     "City": "Vijayawada",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520003,
     "City": "Vijayawada",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520004,
     "City": "Vijayawada",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520007,
     "City": "Vijayawada",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520008,
     "City": "Vijayawada",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520009,
     "City": "Vijayawada",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520010,
     "City": "Vijayawada",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520011,
     "City": "Vijayawada",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520012,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520012,
     "City": "Vijayawada",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520013,
     "City": "Vijayawada",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 520015,
     "City": "Vijayawada",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521001,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521002,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521003,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521101,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521102,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521104,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521105,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521106,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521107,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521108,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521109,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521110,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521111,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521120,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521121,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521122,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521125,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521126,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521130,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521131,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521132,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521133,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521134,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521135,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521136,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521137,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521138,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521139,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521148,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521149,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521150,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521151,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521153,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521156,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521157,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521158,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521162,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521163,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521164,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521165,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521170,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521175,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521178,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521180,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521181,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521182,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521183,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521185,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521190,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521201,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521202,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521207,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521211,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521212,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521213,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521214,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521215,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521225,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521226,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521227,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521228,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521229,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521230,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521235,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521241,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521245,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521246,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521247,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521250,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521256,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521260,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521261,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521263,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521286,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521301,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521311,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521312,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521321,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521322,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521323,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521324,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521325,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521326,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521327,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521328,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521329,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521330,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521331,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521332,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521333,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521338,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521340,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521343,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521344,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521345,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521356,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521366,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521369,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521390,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521401,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521402,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521403,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521456,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 521457,
     "City": "Krishna",
     "District": "Krishna",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522001,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522002,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522003,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522004,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522005,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522006,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522007,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522009,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522015,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522016,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522017,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522018,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522019,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522020,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522034,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522101,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522102,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522111,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522112,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522113,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522124,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522201,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522202,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522211,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522212,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522213,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522233,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522234,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522235,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522236,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522237,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522256,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522257,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522258,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522259,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522261,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522262,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522264,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522265,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522268,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522301,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522302,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522303,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522304,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522305,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522306,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522307,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522308,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522309,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522310,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522311,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522312,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522313,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522314,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522315,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522316,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522317,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522318,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522324,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522325,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522329,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522330,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522341,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522401,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522402,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522403,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522408,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522409,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522410,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522411,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522412,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522413,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522414,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522415,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522421,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522426,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522435,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522436,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522437,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522438,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522439,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522501,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522502,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522503,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522508,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522509,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522510,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522529,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522549,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522601,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522603,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522611,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522612,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522613,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522614,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522615,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522616,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522617,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522619,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522626,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522646,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522647,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522649,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522657,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522658,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522659,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522660,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522661,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 522663,
     "City": "Guntur",
     "District": "Guntur",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523001,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523002,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523101,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523104,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523105,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523108,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523109,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523110,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523111,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523112,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523113,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523114,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523115,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523116,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523117,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523135,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523155,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523156,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523157,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523165,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523166,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523167,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523168,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523169,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523170,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523171,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523180,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523181,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523182,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523183,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523184,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523185,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523186,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523187,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523190,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523201,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523211,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523212,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523213,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523214,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523223,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523224,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523225,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523226,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523227,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523228,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523230,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523240,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523241,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523245,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523246,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523247,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523252,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523253,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523254,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523260,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523261,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523262,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523263,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523264,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523265,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523270,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523271,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523272,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523273,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523274,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523279,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523280,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523281,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523286,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523291,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523292,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523301,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523302,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523303,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523304,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523305,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523315,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523316,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523320,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523326,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523327,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523328,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523329,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523330,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523331,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523332,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523333,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523334,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523335,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523336,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523346,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523356,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523357,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523367,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523368,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523369,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523370,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523371,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523372,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 523373,
     "City": "Prakasam",
     "District": "Prakasam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524001,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524002,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524003,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524004,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524005,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524101,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524121,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524123,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524124,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524126,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524127,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524129,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524131,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524132,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524134,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524137,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524142,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524152,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524201,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524203,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524221,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524222,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524223,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524224,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524225,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524226,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524227,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524228,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524230,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524234,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524236,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524239,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524240,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524301,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524302,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524303,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524304,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524305,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524306,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524307,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524308,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524309,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524310,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524311,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524312,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524313,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524314,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524315,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524316,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524317,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524318,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524320,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524321,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524322,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524323,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524341,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524342,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524343,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524344,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524345,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524346,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524366,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524401,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524402,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524403,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524404,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524405,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524406,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524407,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524408,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524409,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524410,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524411,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524412,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524413,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524414,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524415,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 524421,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 529324,
     "City": "Nellore",
     "District": "Nellore",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530001,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530002,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530003,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530004,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530005,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530006,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530007,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530008,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530009,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530011,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530012,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530013,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530014,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530015,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530016,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530017,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530018,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530020,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530022,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530023,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530024,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530026,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530027,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530028,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530029,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530031,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530032,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530035,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530037,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530040,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530041,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530043,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530044,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530045,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530046,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530047,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530048,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530049,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530051,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530052,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530053,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 530101,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531001,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531002,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531011,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531019,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531020,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531021,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531022,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531023,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531024,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531025,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531026,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531027,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531028,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531029,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531030,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531031,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531032,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531033,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531034,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531035,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531036,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531040,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531055,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531060,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531061,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531075,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531077,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531081,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531082,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531083,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531084,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531085,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531087,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531105,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531111,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531113,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531114,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531115,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531116,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531117,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531118,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531126,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531127,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531133,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531149,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531151,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531162,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531162,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531163,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531173,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 531219,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532001,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532005,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532122,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532122,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532123,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532127,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532127,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532148,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532168,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532185,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532186,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532190,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532195,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532201,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532203,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532211,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532212,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532213,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532214,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532215,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532216,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532217,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532218,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532219,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532220,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532221,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532222,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532242,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532243,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532263,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532264,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532284,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532290,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532291,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532292,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532312,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532322,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532401,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532402,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532403,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532404,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532405,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532406,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532407,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532407,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532408,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532409,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532410,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532421,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532425,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532426,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532427,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532428,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532429,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532430,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532432,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532440,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532443,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532445,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532455,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532456,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532457,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532458,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532459,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532460,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532461,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532462,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532474,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 532484,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533001,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533001,
     "City": "Kakinada",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533002,
     "City": "Kakinada",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533003,
     "City": "Kakinada",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533004,
     "City": "Kakinada",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533005,
     "City": "Kakinada",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533006,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533016,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533101,
     "City": "Rajamundry",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533102,
     "City": "Rajamundry",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533103,
     "City": "Rajamundry",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533104,
     "City": "Rajamundry",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533105,
     "City": "Rajamundry",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533106,
     "City": "Rajamundry",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533107,
     "City": "Rajamundry",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533124,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533125,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533126,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533201,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533210,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533211,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533212,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533213,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533214,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533215,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533216,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533217,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533218,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533220,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533221,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533222,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533223,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533228,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533229,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533232,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533233,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533234,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533235,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533236,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533237,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533238,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533239,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533240,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533241,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533242,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533244,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533247,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533248,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533249,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533250,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533251,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533252,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533253,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533254,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533255,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533256,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533260,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533261,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533262,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533263,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533264,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533274,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533284,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533285,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533286,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533287,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533288,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533289,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533290,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533291,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533292,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533293,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533294,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533295,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533296,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533297,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533305,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533306,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533307,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533308,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533309,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533339,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533340,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533341,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533342,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533343,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533344,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533345,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533346,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533347,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533401,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533406,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533407,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533408,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533428,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533429,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533430,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533431,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533432,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533433,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533434,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533435,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533436,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533437,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533440,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533444,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533445,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533446,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533447,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533448,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533449,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533450,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533461,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533462,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533463,
     "City": "Thallarevu",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533464,
     "City": "Yanam",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533464,
     "City": "Yanam",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 533468,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533483,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 533577,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534001,
     "City": "Eluru",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534002,
     "City": "Eluru",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534003,
     "City": "Eluru",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534004,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534005,
     "City": "Eluru",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534006,
     "City": "Eluru",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534007,
     "City": "Eluru",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534101,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534102,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534111,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534112,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534122,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534123,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534124,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534126,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534134,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534145,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534146,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534156,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534165,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534166,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534176,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534186,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534195,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534196,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534197,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534198,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534199,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534201,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534202,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534204,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534206,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534207,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534208,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534209,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534210,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534211,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534215,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534216,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534217,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534218,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534222,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534225,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534227,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534230,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534235,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534236,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534237,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534238,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534239,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534240,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534243,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534244,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534245,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534247,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534250,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534260,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534265,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534266,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534267,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534268,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534269,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534275,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534280,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534281,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534301,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534302,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534305,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534311,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534312,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534313,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534315,
     "City": "East Godavari",
     "District": "East Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534315,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534316,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534318,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534320,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534324,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534326,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534327,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534328,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534329,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534330,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534331,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534338,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534340,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534341,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534342,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534350,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534401,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534406,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534411,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534416,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534425,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534426,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534427,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534432,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534437,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534442,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534444,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534447,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534448,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534449,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534450,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534451,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534452,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534456,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534460,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534461,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534462,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534467,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 534475,
     "City": "West Godavari",
     "District": "West Godavari",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535001,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535002,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535003,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535004,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535005,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535005,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535006,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535101,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535102,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535124,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535125,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535125,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535126,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535128,
     "City": "Srikakulam",
     "District": "Srikakulam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535128,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535145,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535145,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535148,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535160,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535161,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535183,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535183,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535204,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535213,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535214,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535215,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535216,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535217,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535218,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535220,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535221,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535230,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535240,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535250,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535250,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535260,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535270,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535273,
     "City": "Visakhapatnam",
     "District": "Visakhapatnam",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535273,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535280,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535281,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535463,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535501,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535502,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535521,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535522,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535523,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535524,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535525,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535526,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535527,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535534,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535546,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535547,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535557,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535558,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535559,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535568,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535573,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535578,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535579,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535580,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535581,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535591,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535592,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535593,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 535594,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 537004,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 558453,
     "City": "Vizianagaram",
     "District": "Vizianagaram",
     "State": "Andhra Pradesh"
    },
    {
     "Pin Code": 560000,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560001,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560002,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560003,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560004,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560005,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560006,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560007,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560008,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560009,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560010,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560011,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560012,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560013,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560014,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560015,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560016,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560017,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560018,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560019,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560020,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560021,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560022,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560023,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560024,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560025,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560026,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560027,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560028,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560029,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560030,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560032,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560033,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560034,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560035,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560036,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560037,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560038,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560039,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560040,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560041,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560042,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560043,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560044,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560045,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560046,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560047,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560048,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560049,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560050,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560051,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560052,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560053,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560054,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560055,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560056,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560057,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560058,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560059,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560060,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560060,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560061,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560062,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560063,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560064,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560065,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560066,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560067,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560067,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560068,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560068,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560069,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560070,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560071,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560072,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560073,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560074,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560075,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560076,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560077,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560078,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560079,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560080,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560081,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560082,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560082,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560083,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560083,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560084,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560085,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560086,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560087,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560088,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560089,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560090,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560090,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560091,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560091,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560092,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560093,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560094,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560095,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560096,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560097,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560098,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560099,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560099,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560100,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560102,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560103,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560104,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560105,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560107,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560108,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560109,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560110,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560111,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560112,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 560300,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561101,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561201,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561201,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561202,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561203,
     "City": "Bengaluru",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561203,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561204,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561205,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561206,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561206,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561207,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561207,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561208,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561208,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561209,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561209,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561210,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561210,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561211,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561211,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561212,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561212,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561213,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561213,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561215,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561228,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561228,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 561229,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562101,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562101,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562102,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562102,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562103,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562103,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562104,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562104,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562105,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562105,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562106,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562107,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562108,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562109,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562110,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562111,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562112,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562114,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562115,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562117,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562119,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562119,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562120,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562120,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562121,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562122,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562123,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562124,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562125,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562126,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562127,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562128,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562129,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562130,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562130,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562131,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562132,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562135,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562137,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562138,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562145,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562148,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562149,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562150,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562152,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562157,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562159,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562160,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562161,
     "City": "Ramanagar",
     "District": "Ramanagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562162,
     "City": "Bengaluru",
     "District": "Bengaluru",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562162,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 562163,
     "City": "Bengaluru Rural",
     "District": "Bengaluru Rural",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563101,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563102,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563103,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563113,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563114,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563115,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563116,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563117,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563118,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563119,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563120,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563121,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563121,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563122,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563123,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563123,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563124,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563124,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563125,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563125,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563126,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563127,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563128,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563129,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563130,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563131,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563132,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563133,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563134,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563135,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563136,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563137,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563138,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563138,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563139,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563146,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563146,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563151,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563157,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563159,
     "City": "Chikkaballapur",
     "District": "Chikkaballapur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563159,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563160,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563161,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 563162,
     "City": "Kolar",
     "District": "Kolar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570001,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570002,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570003,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570004,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570005,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570006,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570007,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570008,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570009,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570010,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570011,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570012,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570014,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570015,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570016,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570017,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570018,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570019,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570020,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570021,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570022,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570023,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570024,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570025,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570026,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570027,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570028,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570029,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570030,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 570053,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571101,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571102,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571103,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571104,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571105,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571107,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571108,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571109,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571110,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571110,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571111,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571114,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571115,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571116,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571117,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571118,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571119,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571120,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571121,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571122,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571123,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571124,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571125,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571126,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571127,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571128,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571128,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571129,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571130,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571131,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571134,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571136,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571140,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571145,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571187,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571188,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571189,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571201,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571211,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571212,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571213,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571214,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571215,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571216,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571217,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571218,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571219,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571231,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571232,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571234,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571235,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571236,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571237,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571239,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571247,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571248,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571249,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571250,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571251,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571252,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571253,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571254,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571255,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571301,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571302,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571311,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571312,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571313,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571314,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571315,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571316,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571317,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571320,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571329,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571330,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571331,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571342,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571401,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571402,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571403,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571404,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571405,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571415,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571416,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571417,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571418,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571419,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571421,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571422,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571423,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571424,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571425,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571426,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571427,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571428,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571429,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571430,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571431,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571432,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571433,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571434,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571435,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571436,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571438,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571439,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571440,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571441,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571442,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571443,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571444,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571445,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571446,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571448,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571450,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571455,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571457,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571463,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571468,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571474,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571475,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571476,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571477,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571478,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571479,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571490,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571493,
     "City": "Chamrajnagar",
     "District": "Chamrajnagar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571601,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571602,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571603,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571604,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571605,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571606,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571607,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571608,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571609,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571610,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571617,
     "City": "Mysore",
     "District": "Mysore",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571802,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571807,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571809,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571811,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 571812,
     "City": "Mandya",
     "District": "Mandya",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572101,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572102,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572103,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572104,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572105,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572106,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572107,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572111,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572112,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572113,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572114,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572115,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572116,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572117,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572118,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572119,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572120,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572121,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572122,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572123,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572124,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572125,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572126,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572127,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572128,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572129,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572130,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572132,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572133,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572134,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572135,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572136,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572137,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572138,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572139,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572140,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572141,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572142,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572143,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572144,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572145,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572160,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572165,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572168,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572175,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572201,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572202,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572211,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572212,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572213,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572214,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572215,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572216,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572217,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572218,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572219,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572220,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572221,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572222,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572223,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572224,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572225,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572226,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572227,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572228,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 572239,
     "City": "Tumkur",
     "District": "Tumkur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573101,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573102,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573103,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573111,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573112,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573113,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573114,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573115,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573116,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573117,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573118,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573119,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573120,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573121,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573122,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573123,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573124,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573125,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573126,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573127,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573128,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573129,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573130,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573131,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573132,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573133,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573134,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573135,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573136,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573137,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573139,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573141,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573142,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573144,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573147,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573150,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573162,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573164,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573165,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573201,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573202,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573210,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573211,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573212,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573213,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573214,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573215,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573216,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573217,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573218,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573219,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573220,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573222,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 573225,
     "City": "Hassan",
     "District": "Hassan",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574101,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574102,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574103,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574104,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574105,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574106,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574107,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574107,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574108,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574109,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574109,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574110,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574111,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574112,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574113,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574114,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574115,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574116,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574117,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574118,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574119,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574122,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574124,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574129,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574137,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574140,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574141,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574142,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574143,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574144,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574144,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574145,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574146,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574147,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574148,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574150,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574151,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574153,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574153,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574154,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574156,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574158,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574162,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574164,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574166,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574168,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574171,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574172,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574173,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574174,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574175,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574176,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574177,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574180,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574182,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574185,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574189,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574191,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574192,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574194,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574197,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574198,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574199,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574201,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574202,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574203,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574210,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574211,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574212,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574213,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574214,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574216,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574217,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574218,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574219,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574220,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574221,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574222,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574223,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574224,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574225,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574226,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574227,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574227,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574228,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574229,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574230,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574231,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574232,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574233,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574234,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574234,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574235,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574236,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574237,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574238,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574239,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574240,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574241,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574242,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574243,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574244,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574248,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574253,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574255,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574256,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574259,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574260,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574265,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574267,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574268,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574274,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574277,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574279,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574284,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574285,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574288,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574293,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574294,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574299,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574301,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574311,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574313,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574314,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574314,
     "City": "Kodagu",
     "District": "Kodagu",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574318,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574321,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574323,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574324,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574325,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574326,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574327,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574328,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574502,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574508,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 574509,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575001,
     "City": "Dakshina Kannada",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575001,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575002,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575003,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575004,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575005,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575006,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575007,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575008,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575009,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575010,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575011,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575013,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575014,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575015,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575016,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575017,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575018,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575019,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575020,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575021,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575022,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575023,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575025,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575026,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575027,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575028,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575029,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 575030,
     "City": "Mangalore",
     "District": "Dakshina Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576101,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576102,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576103,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576104,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576105,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576106,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576107,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576108,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576111,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576112,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576113,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576114,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576115,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576116,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576117,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576118,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576120,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576121,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576122,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576124,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576126,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576128,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576138,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576143,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576148,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576201,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576210,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576211,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576212,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576213,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576214,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576215,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576216,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576217,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576218,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576219,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576220,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576221,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576222,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576223,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576224,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576225,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576226,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576227,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576228,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576229,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576230,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576231,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576232,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576233,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576234,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576235,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576238,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576240,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576241,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576247,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576253,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576257,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576259,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576261,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576262,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576282,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 576283,
     "City": "Udupi",
     "District": "Udupi",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577001,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577002,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577003,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577004,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577005,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577006,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577101,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577102,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577111,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577112,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577113,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577114,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577115,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577116,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577117,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577118,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577119,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577120,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577121,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577122,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577123,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577124,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577125,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577126,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577127,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577128,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577129,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577130,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577131,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577132,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577133,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577134,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577135,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577136,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577137,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577138,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577139,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577140,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577142,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577144,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577145,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577146,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577149,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577157,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577160,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577168,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577175,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577179,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577180,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577181,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577182,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577201,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577202,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577203,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577204,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577205,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577211,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577213,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577213,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577214,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577215,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577216,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577216,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577217,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577218,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577219,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577220,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577221,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577222,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577223,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577224,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577225,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577225,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577226,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577227,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577228,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577229,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577230,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577231,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577232,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577233,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577235,
     "City": "Shimoga",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577236,
     "City": "Shimoga",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577238,
     "City": "Shimoga",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577242,
     "City": "Shimoga",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577243,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577243,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577245,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577247,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577301,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577302,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577401,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577411,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577412,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577413,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577414,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577415,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577416,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577417,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577418,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577419,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577421,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577422,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577423,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577424,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577425,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577426,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577427,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577427,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577428,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577429,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577430,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577431,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577432,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577433,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577434,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577435,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577436,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577443,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577447,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577448,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577450,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577451,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577452,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577453,
     "City": "Shimoga",
     "District": "Shimoga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577501,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577501,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577502,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577511,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577512,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577513,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577514,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577515,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577516,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577517,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577518,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577518,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577519,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577520,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577521,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577522,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577523,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577524,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577525,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577526,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577527,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577528,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577529,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577530,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577531,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577532,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577533,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577534,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577535,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577536,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577537,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577538,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577539,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577540,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577541,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577542,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577543,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577544,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577545,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577546,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577547,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577548,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577549,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577550,
     "City": "Chickmagalur",
     "District": "Chickmagalur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577551,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577552,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577553,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577554,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577555,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577556,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577557,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577557,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577558,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577564,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577565,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577566,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577575,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577577,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577586,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577589,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577597,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577598,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577599,
     "City": "Chitradurga",
     "District": "Chitradurga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 577601,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580001,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580002,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580003,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580004,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580005,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580006,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580007,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580008,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580009,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580010,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580011,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580020,
     "City": "Hubli",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580021,
     "City": "Hubli",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580023,
     "City": "Hubli",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580024,
     "City": "Hubli",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580025,
     "City": "Hubli",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580026,
     "City": "Hubli",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580028,
     "City": "Hubli",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580029,
     "City": "Hubli",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580030,
     "City": "Hubli",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580031,
     "City": "Hubli",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580032,
     "City": "Hubli",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580112,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580113,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580114,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580116,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580118,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580213,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 580214,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581101,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581102,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581103,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581104,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581105,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581106,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581107,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581108,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581109,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581110,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581111,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581112,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581113,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581115,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581116,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581117,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581118,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581119,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581119,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581120,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581121,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581123,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581124,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581125,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581126,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581127,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581128,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581129,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581132,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581133,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581135,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581136,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581139,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581140,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581141,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581145,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581146,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581148,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581149,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581152,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581158,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581159,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581165,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581170,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581182,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581186,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581187,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581193,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581195,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581196,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581197,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581201,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581202,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581203,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581204,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581205,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581206,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581207,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581208,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581209,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581210,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581211,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581212,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581213,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581221,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581223,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581224,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581225,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581230,
     "City": "Haveri",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581231,
     "City": "Dharwad",
     "District": "Haveri",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581301,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581302,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581303,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581304,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581305,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581306,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581307,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581308,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581314,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581315,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581316,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581317,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581318,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581319,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581320,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581321,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581322,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581323,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581324,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581325,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581326,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581327,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581328,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581329,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581330,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581331,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581332,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581333,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581334,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581335,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581336,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581337,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581338,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581339,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581340,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581341,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581342,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581343,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581344,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581345,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581346,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581347,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581348,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581349,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581350,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581351,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581352,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581353,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581354,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581355,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581356,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581357,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581358,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581359,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581360,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581361,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581362,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581363,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581365,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581384,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581395,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581396,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581400,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581401,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581402,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581403,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581411,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581412,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581420,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581421,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581423,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581440,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581443,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581450,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 581453,
     "City": "Uttara Kannada",
     "District": "Uttara Kannada",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582101,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582102,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582103,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582111,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582112,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582113,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582114,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582115,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582116,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582117,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582118,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582119,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582120,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582121,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582123,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582127,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582201,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582202,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582203,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582204,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582205,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582206,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582207,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582208,
     "City": "Dharwad",
     "District": "Dharwad",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582209,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582210,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582211,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582217,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582220,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582222,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582226,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582227,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 582237,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583101,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583102,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583103,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583104,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583105,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583111,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583112,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583113,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583114,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583115,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583116,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583117,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583118,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583119,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583120,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583121,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583122,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583123,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583124,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583125,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583126,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583127,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583128,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583129,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583130,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583131,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583132,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583133,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583134,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583135,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583136,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583137,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583139,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583152,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583201,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583202,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583203,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583211,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583212,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583213,
     "City": "Davangere",
     "District": "Davangere",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583214,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583215,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583216,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583217,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583218,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583219,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583220,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583221,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583222,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583223,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583224,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583225,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583226,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583226,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583227,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583227,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583228,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583229,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583229,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583230,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583231,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583232,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583233,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583234,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583235,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583235,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583236,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583237,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583238,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583238,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583239,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583240,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583251,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583259,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583266,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583268,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583268,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583270,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583271,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583273,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583275,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583276,
     "City": "Bellary",
     "District": "Bellary",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583277,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583277,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583278,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583279,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583279,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583280,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583281,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583282,
     "City": "Gadag",
     "District": "Gadag",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583282,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 583283,
     "City": "Koppal",
     "District": "Koppal",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584101,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584102,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584103,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584104,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584110,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584111,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584113,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584115,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584116,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584117,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584118,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584119,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584120,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584122,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584123,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584124,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584125,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584126,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584127,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584128,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584129,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584132,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584133,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584134,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584135,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584136,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584138,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584139,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584140,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584142,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584143,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584146,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584167,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584170,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584202,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 584203,
     "City": "Raichur",
     "District": "Raichur",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585101,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585102,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585103,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585104,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585105,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585106,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585154,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585201,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585202,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585210,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585211,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585212,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585213,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585214,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585215,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585216,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585217,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585218,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585219,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585220,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585221,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585222,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585223,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585224,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585225,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585226,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585227,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585228,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585229,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585236,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585237,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585238,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585244,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585255,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585258,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585261,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585265,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585268,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585274,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585279,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585284,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585285,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585287,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585290,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585291,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585292,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585301,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585302,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585303,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585304,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585305,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585306,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585307,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585308,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585309,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585309,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585310,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585311,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585312,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585313,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585314,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585315,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585316,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585317,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585318,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585319,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585319,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585320,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585321,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585321,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585322,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585323,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585324,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585325,
     "City": "Gulbarga",
     "District": "Gulbarga",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585326,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585327,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585328,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585329,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585330,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585331,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585333,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585337,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585340,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585342,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585353,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585355,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585359,
     "City": "Yadgir",
     "District": "Yadgir",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585401,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585402,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585403,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585411,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585412,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585413,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585414,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585415,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585416,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585417,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585418,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585419,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585421,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585424,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585426,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585428,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585431,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585436,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585437,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585439,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585440,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585443,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 585444,
     "City": "Bidar",
     "District": "Bidar",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586101,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586102,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586103,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586104,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586106,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586108,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586109,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586111,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586112,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586113,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586113,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586114,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586115,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586116,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586117,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586118,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586119,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586120,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586121,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586122,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586123,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586124,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586125,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586125,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586127,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586128,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586129,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586130,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586141,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586146,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586201,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586202,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586203,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586204,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586205,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586206,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586207,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586208,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586209,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586210,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586211,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586212,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586213,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586214,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586215,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586216,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586217,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586231,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 586232,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587101,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587102,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587103,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587111,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587112,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587113,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587114,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587115,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587116,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587117,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587117,
     "City": "Bijapur(KAR)",
     "District": "Bijapur(KAR)",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587118,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587119,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587120,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587121,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587122,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587124,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587125,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587127,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587134,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587138,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587154,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587155,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587201,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587202,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587203,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587204,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587205,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587206,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587207,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587208,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587301,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587311,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587312,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587313,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587314,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587315,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587316,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587317,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587319,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587326,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 587330,
     "City": "Bagalkot",
     "District": "Bagalkot",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590001,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590002,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590003,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590004,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590005,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590006,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590007,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590008,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590009,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590010,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590011,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590012,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590014,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590015,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590016,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590017,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590018,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590019,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 590020,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591101,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591102,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591103,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591104,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591106,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591107,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591108,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591109,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591110,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591111,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591112,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591113,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591114,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591115,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591116,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591117,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591118,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591119,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591120,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591121,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591122,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591123,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591124,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591125,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591126,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591127,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591128,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591129,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591130,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591131,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591136,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591142,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591143,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591147,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591148,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591152,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591153,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591154,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591155,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591156,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591157,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591160,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591167,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591173,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591180,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591189,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591190,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591197,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591201,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591211,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591212,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591213,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591214,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591215,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591216,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591217,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591218,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591219,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591220,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591221,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591222,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591223,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591224,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591225,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591226,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591227,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591228,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591229,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591230,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591231,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591232,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591233,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591234,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591235,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591236,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591237,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591238,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591239,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591240,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591241,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591242,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591243,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591244,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591246,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591247,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591248,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591253,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591254,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591255,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591263,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591264,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591265,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591280,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591287,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591301,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591302,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591303,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591304,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591305,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591306,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591307,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591308,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591309,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591310,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591311,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591312,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591313,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591314,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591315,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591316,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591317,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591319,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591322,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591327,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591332,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591340,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591344,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591345,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591501,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591504,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 591513,
     "City": "Belgaum",
     "District": "Belgaum",
     "State": "Karnataka"
    },
    {
     "Pin Code": 600001,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600002,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600003,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600004,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600005,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600006,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600007,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600008,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600009,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600010,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600011,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600012,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600013,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600014,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600015,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600016,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600017,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600018,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600019,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600020,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600021,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600022,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600023,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600024,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600025,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600026,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600027,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600028,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600029,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600030,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600031,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600032,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600033,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600034,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600035,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600036,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600037,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600038,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600039,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600040,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600041,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600041,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600042,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600043,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600044,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600045,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600046,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600047,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600048,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600049,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600050,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600051,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600052,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600053,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600054,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600055,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600056,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600056,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600057,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600058,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600059,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600060,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600061,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600062,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600063,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600064,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600065,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600066,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600067,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600068,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600069,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600070,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600071,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600072,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600073,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600074,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600075,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600076,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600077,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600078,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600079,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600080,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600081,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600082,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600083,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600084,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600085,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600086,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600087,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600088,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600089,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600090,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600091,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600092,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600093,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600094,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600095,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600096,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600097,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600098,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600099,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600100,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600101,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600102,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600103,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600104,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600105,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600106,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600107,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600107,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600108,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600109,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600110,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600111,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600112,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600113,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600114,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600115,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600116,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600117,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600118,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600119,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600120,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600121,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600122,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600123,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600124,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600125,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600126,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600127,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600128,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600128,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600129,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 600130,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 601101,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 601102,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 601102,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 601103,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 601201,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 601202,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 601203,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 601204,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 601205,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 601206,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 601301,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602001,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602002,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602003,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602021,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602023,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602024,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602025,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602026,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602101,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602102,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602103,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602105,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602105,
     "City": "Chennai",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602106,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602107,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602108,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602109,
     "City": "Chennai",
     "District": "Chennai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602117,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 602201,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603001,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603002,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603003,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603061,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603101,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603102,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603103,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603104,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603105,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603106,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603107,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603108,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603109,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603110,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603111,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603112,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603127,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603201,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603202,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603203,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603204,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603209,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603210,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603211,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603301,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603302,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603303,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603304,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603305,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603306,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603307,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603308,
     "City": "Chennai",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603309,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603310,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603311,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603312,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603313,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603314,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603319,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603401,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603402,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603403,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603405,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 603406,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604001,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604101,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604102,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604151,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604152,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604153,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604154,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604201,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604202,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604203,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604204,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604205,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604206,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604207,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604208,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604210,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604301,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604302,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604303,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604304,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604305,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604306,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604307,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604401,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604402,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604403,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604404,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604405,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604406,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604407,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604407,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604408,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604409,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604410,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604501,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604502,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604503,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604504,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604505,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604505,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 604601,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605001,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605002,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605003,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605004,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605005,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605006,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605007,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605007,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605008,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605009,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605010,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605011,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605013,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605014,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605014,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605101,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605101,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605102,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605102,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605103,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605104,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605105,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605105,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605106,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605106,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605106,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605107,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605107,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605108,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605109,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605110,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605110,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605111,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605111,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605201,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605202,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605203,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605301,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605302,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605401,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605402,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605501,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605501,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605502,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 605502,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605601,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605602,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605651,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605652,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605701,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605702,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605702,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605751,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605752,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605754,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605755,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605756,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605757,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605758,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605759,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605766,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605801,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605802,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 605803,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606001,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606003,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606102,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606103,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606104,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606104,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606105,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606106,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606107,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606108,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606110,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606111,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606115,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606201,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606202,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606203,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606204,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606205,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606206,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606207,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606208,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606213,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606301,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606302,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606303,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606304,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606305,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606401,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606402,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606601,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606603,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606604,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606611,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606701,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606702,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606703,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606704,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606705,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606706,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606707,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606708,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606709,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606710,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606751,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606752,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606753,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606754,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606755,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606801,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606802,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606803,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606804,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606805,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606806,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606807,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606808,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606811,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606901,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606902,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606903,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606904,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606905,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606906,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606907,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 606908,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607001,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607002,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607003,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607004,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607005,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607006,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607101,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607101,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607102,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607103,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607104,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607105,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607106,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607107,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607108,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607109,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607112,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607201,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607201,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607202,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607203,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607204,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607205,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607209,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607301,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607302,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607303,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607308,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607401,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607402,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 607402,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607801,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607802,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607803,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607804,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607805,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607805,
     "City": "Villupuram",
     "District": "Villupuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 607807,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608001,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608002,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608102,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608201,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608301,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608302,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608303,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608304,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608305,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608306,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608401,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608501,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608502,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608601,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608602,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608701,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608702,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608703,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608703,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608704,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608801,
     "City": "Cuddalore",
     "District": "Cuddalore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 608901,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609001,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609003,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609101,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609102,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609103,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609104,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609105,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609106,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609107,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609108,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609109,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609110,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609111,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609112,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609113,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609114,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609115,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609116,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609117,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609118,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609201,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609202,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609203,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609204,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609205,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609301,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609302,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609303,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609304,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609305,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609306,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609307,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609308,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609309,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609310,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609311,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609312,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609313,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609314,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609401,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609402,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609403,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609403,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609404,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609405,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609501,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609501,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609502,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609503,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609503,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609504,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609504,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609601,
     "City": "Karaikal",
     "District": "Karaikal",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 609602,
     "City": "Karaikal",
     "District": "Karaikal",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 609603,
     "City": "Karaikal",
     "District": "Karaikal",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 609603,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609604,
     "City": "Karaikal",
     "District": "Karaikal",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 609604,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609605,
     "City": "Karaikal",
     "District": "Karaikal",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 609606,
     "City": "Karaikal",
     "District": "Karaikal",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 609607,
     "City": "Karaikal",
     "District": "Karaikal",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 609608,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609609,
     "City": "Karaikal",
     "District": "Karaikal",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 609701,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609702,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609703,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609704,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609801,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609802,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609802,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609803,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609804,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609804,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609805,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609806,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609807,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609807,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609808,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609810,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 609811,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610001,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610003,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610004,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610101,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610101,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610102,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610103,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610104,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610105,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610106,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610106,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610107,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610109,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610201,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610201,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610202,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610203,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610203,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610204,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610205,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610206,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 610207,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611001,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611002,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611003,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611101,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611101,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611102,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611103,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611104,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611105,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611106,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611108,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611109,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611110,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611111,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 611112,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612001,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612002,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612101,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612102,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612102,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612103,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612104,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612105,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612106,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612201,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612201,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612201,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612202,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612203,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612204,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612301,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612302,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612303,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612401,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612402,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612501,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612502,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612503,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612504,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612601,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612602,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612603,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612603,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612604,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612605,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612605,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612610,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612701,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612702,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612703,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612801,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612802,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612802,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612803,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612804,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612804,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612901,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612902,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612903,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 612904,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613001,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613002,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613003,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613004,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613005,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613006,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613007,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613008,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613009,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613010,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613101,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613102,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613103,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613104,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613105,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613201,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613202,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613203,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613204,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613205,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613301,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613303,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613401,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613402,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613403,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613501,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613502,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613503,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613504,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613601,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613602,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613701,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613702,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613702,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613703,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613704,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 613705,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614001,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614013,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614014,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614015,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614015,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614016,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614017,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614017,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614018,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614019,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614019,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614020,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614101,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614102,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614103,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614201,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614202,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614203,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614204,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614205,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614206,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614207,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614208,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614208,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614210,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614211,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614301,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614302,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614302,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614303,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614401,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614402,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614403,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614404,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614601,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614602,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614612,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614613,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614614,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614615,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614616,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614617,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614618,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614619,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614620,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614621,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614622,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614623,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614624,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614625,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614626,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614628,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614629,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614630,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614701,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614702,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614703,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614704,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614704,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614705,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614706,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614707,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614708,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614710,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614711,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614711,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614712,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614713,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614714,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614715,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614716,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614716,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614717,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614723,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614738,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614801,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614802,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614803,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614804,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614805,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614806,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614807,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614808,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614809,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614810,
     "City": "Nagapattinam",
     "District": "Nagapattinam",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614901,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614902,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614902,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614903,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614903,
     "City": "Tiruvarur",
     "District": "Tiruvarur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614904,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614905,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614906,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 614908,
     "City": "Thanjavur",
     "District": "Thanjavur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620001,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620002,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620003,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620004,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620005,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620006,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620007,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620008,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620009,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620010,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620011,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620012,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620013,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620014,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620015,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620016,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620017,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620018,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620019,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620020,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620021,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620022,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620023,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620024,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620025,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620026,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620101,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 620102,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621001,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621002,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621003,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621004,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621005,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621006,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621007,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621008,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621009,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621010,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621011,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621012,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621014,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621101,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621101,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621102,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621103,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621104,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621104,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621105,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621106,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621107,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621108,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621109,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621110,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621110,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621110,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621111,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621112,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621113,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621114,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621115,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621116,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621117,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621118,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621133,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621202,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621203,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621204,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621205,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621206,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621207,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621208,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621209,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621210,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621211,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621212,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621213,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621214,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621215,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621215,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621216,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621217,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621218,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621218,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621219,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621220,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621301,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621302,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621305,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621305,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621306,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621306,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621307,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621308,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621308,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621308,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621310,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621311,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621311,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621312,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621312,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621313,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621314,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621315,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621315,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621316,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621601,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621651,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621651,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621652,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621653,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621653,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621701,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621702,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621703,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621704,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621705,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621706,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621707,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621708,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621709,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621710,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621711,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621712,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621713,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621713,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621714,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621715,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621715,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621716,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621717,
     "City": "Perambalur",
     "District": "Perambalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621718,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621719,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621722,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621729,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621730,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621801,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621802,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621803,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621804,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621805,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621806,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 621851,
     "City": "Ariyalur",
     "District": "Ariyalur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622001,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622002,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622003,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622004,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622005,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622101,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622102,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622103,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622104,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622201,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622202,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622203,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622204,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622209,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622301,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622302,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622303,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622304,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622401,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622402,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622403,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622404,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622407,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622408,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622409,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622411,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622412,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622422,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622501,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622502,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622503,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622504,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622505,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622506,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622507,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 622515,
     "City": "Pudukkottai",
     "District": "Pudukkottai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623006,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623105,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623115,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623120,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623135,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623308,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623315,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623401,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623401,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623402,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623402,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623403,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623404,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623406,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623407,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623409,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623501,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623502,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623503,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623504,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623512,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623513,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623514,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623515,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623516,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623517,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623518,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623519,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623520,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623521,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623522,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623523,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623524,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623525,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623526,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623527,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623528,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623529,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623530,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623531,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623532,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623533,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623534,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623536,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623537,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623538,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623566,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623601,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623603,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623604,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623605,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623608,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623701,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623701,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623703,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623704,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623705,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623706,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623707,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623708,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623711,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623712,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623801,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 623806,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624001,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624002,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624003,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624004,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624005,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624101,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624103,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624201,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624202,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624204,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624206,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624208,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624210,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624211,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624212,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624215,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624216,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624219,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624220,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624301,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624302,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624303,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624304,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624306,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624307,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624308,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624401,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624402,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624403,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624601,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624610,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624612,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624613,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624614,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624615,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624616,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624617,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624618,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624619,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624620,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624621,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624622,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624701,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624702,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624703,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624704,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624705,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624706,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624707,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624708,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624709,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624710,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624711,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624712,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624801,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 624802,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625001,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625002,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625003,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625004,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625005,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625006,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625007,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625008,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625009,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625010,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625011,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625012,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625014,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625015,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625016,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625017,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625018,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625019,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625020,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625021,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625022,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625023,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625101,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625102,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625103,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625104,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625105,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625106,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625107,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625108,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625109,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625110,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625122,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625201,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625203,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625205,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625207,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625214,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625218,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625221,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625234,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625301,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625402,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625501,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625503,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625512,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625513,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625514,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625515,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625516,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625517,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625518,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625519,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625520,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625521,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625522,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625523,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625524,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625525,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625526,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625527,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625527,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625528,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625529,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625530,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625531,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625532,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625533,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625534,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625535,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625536,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625537,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625538,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625540,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625547,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625556,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625562,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625579,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625582,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625601,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625602,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625603,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625604,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625605,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625701,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625702,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625703,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625704,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625705,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625705,
     "City": "Theni",
     "District": "Theni",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625706,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625707,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 625708,
     "City": "Madurai",
     "District": "Madurai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626001,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626002,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626003,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626004,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626005,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626101,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626102,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626103,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626104,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626105,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626106,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626107,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626108,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626109,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626110,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626111,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626112,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626113,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626114,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626115,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626116,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626117,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626118,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626119,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626121,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626122,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626123,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626124,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626125,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626126,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626127,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626128,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626129,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626130,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626131,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626132,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626133,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626134,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626135,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626136,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626137,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626138,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626139,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626140,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626141,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626142,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626149,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626188,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626189,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626201,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626202,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626202,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626203,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626204,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626205,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626205,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626607,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 626612,
     "City": "Virudhunagar",
     "District": "Virudhunagar",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627001,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627002,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627003,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627004,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627005,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627006,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627007,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627008,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627009,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627010,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627011,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627012,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627101,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627102,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627103,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627104,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627105,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627106,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627107,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627108,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627109,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627110,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627111,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627112,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627113,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627114,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627115,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627116,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627117,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627118,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627119,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627120,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627127,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627131,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627133,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627151,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627152,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627201,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627202,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627351,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627352,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627353,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627354,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627355,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627356,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627357,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627358,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627359,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627401,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627412,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627413,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627414,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627415,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627416,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627417,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627418,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627420,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627421,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627422,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627423,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627424,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627425,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627426,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627427,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627428,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627451,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627452,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627453,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627501,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627502,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627601,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627602,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627603,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627604,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627651,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627652,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627654,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627657,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627659,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627713,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627719,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627751,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627753,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627754,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627755,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627756,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627757,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627758,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627758,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627759,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627760,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627761,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627801,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627802,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627803,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627804,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627805,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627806,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627807,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627808,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627809,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627811,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627812,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627813,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627814,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627818,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627851,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627852,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627853,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627854,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627855,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627855,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627856,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627857,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627858,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627859,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627860,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627861,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627862,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627951,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 627953,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628001,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628002,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628003,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628004,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628005,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628006,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628007,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628008,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628101,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628102,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628103,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628104,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628105,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628151,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628152,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628201,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628202,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628203,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628204,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628205,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628206,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628207,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628208,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628209,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628210,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628211,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628212,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628213,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628215,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628216,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628217,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628218,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628219,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628229,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628251,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628252,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628301,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628302,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628303,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628304,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628401,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628402,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628501,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628502,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628502,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628503,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628552,
     "City": "Tirunelveli",
     "District": "Tirunelveli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628552,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628601,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628612,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628613,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628614,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628615,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628616,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628617,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628618,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628619,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628620,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628621,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628622,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628623,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628653,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628656,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628701,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628702,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628703,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628704,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628712,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628714,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628716,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628718,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628720,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628721,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628722,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628751,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628752,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628753,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628801,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628802,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628809,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628851,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628901,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628902,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628903,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628904,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628905,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628906,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628907,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628908,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 628952,
     "City": "Tuticorin",
     "District": "Tuticorin",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629001,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629002,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629003,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629004,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629101,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629102,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629151,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629152,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629153,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629154,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629155,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629156,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629157,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629158,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629159,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629160,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629161,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629162,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629163,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629164,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629165,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629166,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629167,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629168,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629169,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629170,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629171,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629172,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629173,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629174,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629175,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629176,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629177,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629178,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629179,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629180,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629193,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629201,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629202,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629203,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629204,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629251,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629252,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629301,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629302,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629401,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629402,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629403,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629501,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629502,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629601,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629602,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629701,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629702,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629703,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629704,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629801,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629802,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629803,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629804,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629809,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629810,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629851,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629852,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 629901,
     "City": "Kanyakumari",
     "District": "Kanyakumari",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630001,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630002,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630003,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630004,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630005,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630006,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630101,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630102,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630103,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630104,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630105,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630106,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630107,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630108,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630201,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630202,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630203,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630204,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630205,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630206,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630207,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630208,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630210,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630211,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630212,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630301,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630302,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630303,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630305,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630306,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630307,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630309,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630311,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630312,
     "City": "Ramanathapuram",
     "District": "Ramanathapuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630312,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630313,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630314,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630321,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630405,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630408,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630410,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630411,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630501,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630502,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630551,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630552,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630553,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630554,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630555,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630556,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630557,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630558,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630559,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630561,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630562,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630566,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630602,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630606,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630609,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630610,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630611,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630702,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630709,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630710,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 630713,
     "City": "Sivaganga",
     "District": "Sivaganga",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631001,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631002,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631003,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631004,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631005,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631006,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631051,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631052,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631101,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631102,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631151,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631152,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631201,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631202,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631202,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631203,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631204,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631205,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631206,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631207,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631208,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631209,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631210,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631211,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631212,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631213,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631301,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631302,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631303,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631304,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631402,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631402,
     "City": "Tiruvallur",
     "District": "Tiruvallur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631501,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631502,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631551,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631552,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631553,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631561,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631601,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631603,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631604,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631605,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631606,
     "City": "Kanchipuram",
     "District": "Kanchipuram",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631701,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 631702,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632001,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632002,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632004,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632005,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632006,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632007,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632008,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632009,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632010,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632011,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632012,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632013,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632014,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632055,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632057,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632058,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632059,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632101,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632102,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632103,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632104,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632105,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632106,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632107,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632113,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632114,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632115,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632201,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632202,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632203,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632204,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632209,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632301,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632311,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632311,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632312,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632312,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632313,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632314,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632315,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632316,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632317,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632318,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632319,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632319,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632326,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632401,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632403,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632404,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632405,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632406,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632501,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632502,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632503,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632504,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632505,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632506,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632507,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632507,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632508,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632509,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632510,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632511,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632511,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632512,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632512,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632513,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632514,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632515,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632516,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632517,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632518,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632519,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632520,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632521,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632531,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632601,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632602,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632603,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 632604,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635001,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635002,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635101,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635102,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635103,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635104,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635105,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635106,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635107,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635108,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635109,
     "City": "Hosur",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635109,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635110,
     "City": "Hosur",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635111,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635111,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635112,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635113,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635114,
     "City": "Hosur",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635115,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635116,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635117,
     "City": "Hosur",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635118,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635119,
     "City": "Hosur",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635120,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635121,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635122,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635123,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635124,
     "City": "Hosur",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635125,
     "City": "Hosur",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635126,
     "City": "Hosur",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635201,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635202,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635203,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635204,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635205,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635206,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635207,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635301,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635302,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635303,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635304,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635305,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635306,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635307,
     "City": "Krishnagiri",
     "District": "Krishnagiri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635601,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635602,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635651,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635652,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635653,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635654,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635655,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635701,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635702,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635703,
     "City": "Tiruvannamalai",
     "District": "Tiruvannamalai",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635703,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635710,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635751,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635752,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635754,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635801,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635802,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635803,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635804,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635805,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635806,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635807,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635808,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635809,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635810,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635811,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635812,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635813,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635814,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635815,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635851,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635852,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635853,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635854,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 635901,
     "City": "Vellore",
     "District": "Vellore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636001,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636001,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636002,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636003,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636004,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636005,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636006,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636007,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636008,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636009,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636010,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636011,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636012,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636013,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636014,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636015,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636016,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636030,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636101,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636102,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636103,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636104,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636105,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636106,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636107,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636108,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636109,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636110,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636111,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636112,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636113,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636113,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636114,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636115,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636116,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636117,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636118,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636118,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636119,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636121,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636122,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636138,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636139,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636140,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636141,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636142,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636142,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636201,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636202,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636202,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636203,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636204,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636301,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636302,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636303,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636304,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636305,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636306,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636307,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636308,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636309,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636351,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636352,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636354,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636401,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636402,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636403,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636404,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636406,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636451,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636452,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636453,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636454,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636455,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636456,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636457,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636458,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636501,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636502,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636503,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636601,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636602,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636701,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636704,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636705,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636803,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636804,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636805,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636806,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636807,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636808,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636809,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636810,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636811,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636812,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636813,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636902,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636903,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636904,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636905,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636906,
     "City": "Dharmapuri",
     "District": "Dharmapuri",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 636906,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637001,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637002,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637003,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637013,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637014,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637015,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637017,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637018,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637019,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637020,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637021,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637101,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637101,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637102,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637102,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637103,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637103,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637104,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637104,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637105,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637105,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637107,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637107,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637201,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637202,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637203,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637204,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637205,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637206,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637207,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637208,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637209,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637210,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637211,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637212,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637213,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637214,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637215,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637301,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637301,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637302,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637302,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637303,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637303,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637304,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637304,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637401,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637402,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637403,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637404,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637405,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637406,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637407,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637407,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637408,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637409,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637410,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637410,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637411,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637412,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637501,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637502,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637503,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637504,
     "City": "Salem",
     "District": "Salem",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 637505,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638001,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638002,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638003,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638004,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638005,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638006,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638007,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638007,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638008,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638009,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638010,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638011,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638012,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638051,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638052,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638053,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638054,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638055,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638056,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638057,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638058,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638060,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638101,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638102,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638103,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638104,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638105,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638106,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638107,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638108,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638109,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638110,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638111,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638112,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638115,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638116,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638151,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638151,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638152,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638153,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638154,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638181,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638182,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638183,
     "City": "Namakkal",
     "District": "Namakkal",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638301,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638311,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638312,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638313,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638314,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638315,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638316,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638401,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638402,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638451,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638452,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638453,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638454,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638455,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638456,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638457,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638458,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638459,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638460,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638461,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638462,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638476,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638501,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638502,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638503,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638504,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638505,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638506,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638656,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638657,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638660,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638661,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638672,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638673,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638701,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638702,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638703,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638706,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638751,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638752,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 638812,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639001,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639002,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639003,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639004,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639005,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639006,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639007,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639008,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639101,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639102,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639103,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639104,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639105,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639107,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639108,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639109,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639110,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639111,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639112,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639113,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639114,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639115,
     "City": "Tiruchirappalli",
     "District": "Tiruchirappalli",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639116,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639117,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639118,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639119,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639120,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639136,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639201,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639201,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639202,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639202,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639203,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639203,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639204,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639205,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639206,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 639207,
     "City": "Karur",
     "District": "Karur",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641001,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641002,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641003,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641004,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641005,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641006,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641007,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641008,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641009,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641010,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641011,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641012,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641013,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641014,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641015,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641016,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641017,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641018,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641019,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641020,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641021,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641022,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641023,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641024,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641025,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641026,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641027,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641028,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641029,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641030,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641031,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641032,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641033,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641034,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641035,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641036,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641037,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641038,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641039,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641041,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641042,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641043,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641044,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641045,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641046,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641047,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641048,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641049,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641050,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641062,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641101,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641103,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641104,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641105,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641107,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641108,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641109,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641110,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641111,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641112,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641113,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641114,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641201,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641202,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641301,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641302,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641305,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641401,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641402,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641407,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641601,
     "City": "Tiruppur",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641602,
     "City": "Tiruppur",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641603,
     "City": "Tiruppur",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641604,
     "City": "Tiruppur",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641605,
     "City": "Tiruppur",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641605,
     "City": "Tiruppur",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641606,
     "City": "Tiruppur",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641607,
     "City": "Tiruppur",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641607,
     "City": "Tiruppur",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641609,
     "City": "Tiruppur",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641652,
     "City": "Tiruppur",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641653,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641654,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641655,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641658,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641659,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641662,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641663,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641664,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641665,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641666,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641667,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641668,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641669,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641670,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641671,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641687,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 641697,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642001,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642002,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642003,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642004,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642005,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642006,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642007,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642045,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642101,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642102,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642103,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642104,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642105,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642106,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642107,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642108,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642109,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642110,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642111,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642112,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642113,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642113,
     "City": "Dindigul",
     "District": "Dindigul",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642114,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642117,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642120,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642122,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642123,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642125,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642126,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642127,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642128,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642129,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642130,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642132,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642133,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642134,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642154,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642201,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642202,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642203,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642204,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642205,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642206,
     "City": "Erode",
     "District": "Erode",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 642207,
     "City": "Coimbatore",
     "District": "Coimbatore",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643001,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643002,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643003,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643004,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643005,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643006,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643007,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643101,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643102,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643103,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643104,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643105,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643201,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643202,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643203,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643204,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643205,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643206,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643207,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643209,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643211,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643212,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643213,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643214,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643215,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643216,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643217,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643218,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643219,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643220,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643221,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643223,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643224,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643225,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643226,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643228,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643231,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643233,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643236,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643237,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643238,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643239,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643240,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643241,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643242,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643243,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 643253,
     "City": "Nilgiris",
     "District": "Nilgiris",
     "State": "Tamil Nadu"
    },
    {
     "Pin Code": 650591,
     "City": "THIRUVANANTHAPURAM",
     "District": "THIRUVANANTHAPURAM",
     "State": "Kerala"
    },
    {
     "Pin Code": 652301,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 670000,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670001,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670002,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670003,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670004,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670005,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670006,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670007,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670008,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670009,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670010,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670011,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670012,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670013,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670014,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670017,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670018,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670101,
     "City": "Thalassery",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670102,
     "City": "Thalassery",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670103,
     "City": "Thalassery",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670104,
     "City": "Thalassery",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670105,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670106,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670107,
     "City": "Thalassery",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670141,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670142,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670143,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670301,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670302,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670303,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670304,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670305,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670306,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670307,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670308,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670309,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670310,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670325,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670327,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670331,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670334,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670353,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670358,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670501,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670502,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670503,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670504,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670511,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670511,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 670521,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670540,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670561,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670562,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670563,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670567,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670571,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670581,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670582,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670591,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670592,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670593,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670594,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670595,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670601,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670602,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670604,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670611,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670612,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670613,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670614,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670621,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670622,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670631,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670632,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670633,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670641,
     "City": "Thalassery",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670642,
     "City": "Thalassery",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670643,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670644,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 670645,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 670646,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 670649,
     "City": "Thalassery",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670650,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670651,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670661,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670662,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670663,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670671,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670672,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670673,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670674,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670675,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670676,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670691,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670692,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670693,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670694,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670701,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670702,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670703,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670704,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670705,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670706,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 670721,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 670731,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 670732,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 670741,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 671121,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671122,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671123,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671124,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671310,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671311,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671312,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671313,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671314,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671315,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671316,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671317,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671318,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671319,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671321,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671322,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671323,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671324,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671326,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671348,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671351,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671531,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671532,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671533,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671541,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671542,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671543,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671551,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 671552,
     "City": "Kasargod",
     "District": "Kasargod",
     "State": "Kerala"
    },
    {
     "Pin Code": 673001,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673002,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673003,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673004,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673005,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673006,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673007,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673008,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673009,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673010,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673011,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673012,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673014,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673015,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673016,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673017,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673018,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673019,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673020,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673021,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673027,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673028,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673029,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673032,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673101,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673102,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673103,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673104,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673105,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673106,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673121,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673122,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673123,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673301,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673302,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673303,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673304,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673305,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673306,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673307,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673308,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673309,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673310,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673310,
     "City": "Mahe",
     "District": "Mahe",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 673310,
     "City": "Pondicherry",
     "District": "Pondicherry",
     "State": "Pondicherry"
    },
    {
     "Pin Code": 673311,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 673312,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 673313,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 673314,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673315,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673316,
     "City": "Kannur",
     "District": "Kannur",
     "State": "Kerala"
    },
    {
     "Pin Code": 673317,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673328,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673501,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673502,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673503,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673504,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673505,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673506,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673507,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673508,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673509,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673513,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673517,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673521,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673522,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673523,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673524,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673525,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673526,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673527,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673528,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673529,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673541,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673542,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673570,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673571,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673572,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673573,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673574,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673575,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673576,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673577,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673579,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673580,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673581,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673582,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673585,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673586,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673591,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673592,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673593,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673595,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673596,
     "City": "Wayanad",
     "District": "Wayanad",
     "State": "Kerala"
    },
    {
     "Pin Code": 673601,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673602,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673603,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673604,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673611,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673612,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673613,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673614,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673615,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673616,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673620,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673631,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673632,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673632,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673633,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673633,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673634,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673635,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673636,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673637,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673638,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673639,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673640,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673641,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673642,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673645,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673647,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673651,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673652,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 673655,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 673661,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 676101,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676102,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676103,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676104,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676105,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676106,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676107,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676108,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676109,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676121,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676122,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676123,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676127,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676301,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676302,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676303,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676304,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676305,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676306,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676307,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676309,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676311,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676312,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676317,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676319,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676320,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676501,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676502,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676503,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676504,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676505,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676506,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676507,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676508,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676509,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676510,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676517,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676519,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676521,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676522,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676523,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676525,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676528,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676541,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676542,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676551,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676552,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676553,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676561,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 676562,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 678001,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678002,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678003,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678004,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678005,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678006,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678007,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678008,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678009,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678010,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678011,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678012,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678013,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678014,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678101,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678102,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678103,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678104,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678452,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 678501,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678502,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678503,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678504,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678505,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678506,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678507,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678508,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678510,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678512,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678531,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678532,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678533,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678534,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678541,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678542,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678543,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678544,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678545,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678546,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678551,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678552,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678553,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678554,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678555,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678556,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678557,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678571,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678572,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678573,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678574,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678581,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678582,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678583,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678591,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678592,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678593,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678594,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678595,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678596,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678597,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678598,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678601,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678611,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678612,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678613,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678621,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678622,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678623,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678624,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678631,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678632,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678633,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678641,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678642,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678651,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678661,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678671,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678681,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678682,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678683,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678684,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678685,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678686,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678687,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678688,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678701,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678702,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678703,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678704,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678705,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678706,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678721,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678722,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678731,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678732,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 678762,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679101,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679102,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679103,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679104,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679105,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 679106,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 679121,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679122,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679123,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679301,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679302,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679303,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679304,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679305,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679306,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679307,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679308,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679309,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679313,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679321,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679322,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679323,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679324,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679325,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679326,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679327,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679328,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679329,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679330,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679331,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679332,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679333,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679334,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679335,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679336,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679337,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679338,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679339,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679340,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679351,
     "City": "PERINTHALMANNA",
     "District": "PERINTHALMANNA",
     "State": "Kerala"
    },
    {
     "Pin Code": 679352,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679357,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679501,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679502,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679503,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679504,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679505,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679506,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679511,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679512,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679513,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679514,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679515,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679516,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679521,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679522,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679523,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679531,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 679532,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 679533,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679534,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679535,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679536,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679551,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679552,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679553,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679554,
     "City": "Palakkad",
     "District": "Palakkad",
     "State": "Kerala"
    },
    {
     "Pin Code": 679561,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 679562,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 679563,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 679564,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 679571,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679572,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679573,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679574,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679575,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679576,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679577,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679578,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679579,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679580,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679581,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679582,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679583,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679584,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679585,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679586,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679587,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 679591,
     "City": "Malappuram",
     "District": "Malappuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 680001,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680002,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680003,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680004,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680005,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680006,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680007,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680008,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680009,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680010,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680011,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680012,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680013,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680014,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680015,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680020,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680021,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680022,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680026,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680027,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680028,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680101,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680102,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680103,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680104,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680121,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680122,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680123,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680125,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680301,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680302,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680303,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680304,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680305,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680306,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680307,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680308,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680309,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680310,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680311,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680312,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680314,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680317,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680325,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680501,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680502,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680503,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680504,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680505,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680506,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680507,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680508,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680509,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680510,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680511,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680512,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680513,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680514,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680515,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680516,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680517,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680518,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680519,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680520,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680521,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680522,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680523,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680524,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680541,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680542,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680543,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680544,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680545,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680546,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680551,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680552,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680553,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680555,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680561,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680562,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680563,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680564,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680565,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680566,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680567,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680568,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680569,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680570,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680571,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680581,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680582,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680583,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680584,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680585,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680586,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680587,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680588,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680589,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680590,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680591,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680593,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680594,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680596,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680601,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680602,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680604,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680608,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680611,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680612,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680613,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680614,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680615,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680616,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680617,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680618,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680619,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680620,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680623,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680631,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680641,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680642,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680651,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680652,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680653,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680654,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680655,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680656,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680661,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680662,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680663,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680664,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680665,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680666,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680667,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 680667,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680668,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680669,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680670,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680671,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680672,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680681,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680682,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680683,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680684,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680685,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680686,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680687,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680688,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680689,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680691,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680697,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680699,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680701,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680702,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680703,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680711,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680712,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680721,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680722,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680724,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680731,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680732,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680733,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680734,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680741,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680751,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 680752,
     "City": "Thrissur",
     "District": "Thrissur",
     "State": "Kerala"
    },
    {
     "Pin Code": 682001,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682002,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682003,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682004,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682005,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682006,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682007,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682008,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682009,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682010,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682011,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682012,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682013,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682015,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682016,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682017,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682018,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682019,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682020,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682021,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682022,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682023,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682024,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682025,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682026,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682027,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682028,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682029,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682030,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682031,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682032,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682033,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682034,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682035,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682036,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682037,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682038,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682039,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682040,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682041,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682042,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682301,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682302,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682303,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682304,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682305,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682306,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682307,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682308,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682309,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682310,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682311,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682312,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682313,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682314,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682315,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682316,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682319,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682324,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682501,
     "City": "Kochi",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682502,
     "City": "Kochi",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682503,
     "City": "Kochi",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682504,
     "City": "Kochi",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682505,
     "City": "Kochi",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682506,
     "City": "Kochi",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682507,
     "City": "Kochi",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682508,
     "City": "Kochi",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682509,
     "City": "Kochi",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682511,
     "City": "Kochi",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 682551,
     "City": "Lakshadweep",
     "District": "Lakshadweep",
     "State": "Lakshadweep"
    },
    {
     "Pin Code": 682552,
     "City": "Lakshadweep",
     "District": "Lakshadweep",
     "State": "Lakshadweep"
    },
    {
     "Pin Code": 682553,
     "City": "Lakshadweep",
     "District": "Lakshadweep",
     "State": "Lakshadweep"
    },
    {
     "Pin Code": 682554,
     "City": "Lakshadweep",
     "District": "Lakshadweep",
     "State": "Lakshadweep"
    },
    {
     "Pin Code": 682555,
     "City": "Lakshadweep",
     "District": "Lakshadweep",
     "State": "Lakshadweep"
    },
    {
     "Pin Code": 682556,
     "City": "Lakshadweep",
     "District": "Lakshadweep",
     "State": "Lakshadweep"
    },
    {
     "Pin Code": 682557,
     "City": "Lakshadweep",
     "District": "Lakshadweep",
     "State": "Lakshadweep"
    },
    {
     "Pin Code": 682558,
     "City": "Lakshadweep",
     "District": "Lakshadweep",
     "State": "Lakshadweep"
    },
    {
     "Pin Code": 682559,
     "City": "Lakshadweep",
     "District": "Lakshadweep",
     "State": "Lakshadweep"
    },
    {
     "Pin Code": 683016,
     "City": "Kozhikode",
     "District": "Kozhikode",
     "State": "Kerala"
    },
    {
     "Pin Code": 683101,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683102,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683104,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683105,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683106,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683107,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683108,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683110,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683111,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683112,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683501,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683502,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683503,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683511,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683512,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683513,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683514,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683515,
     "City": "Kochi",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683516,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683517,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683518,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683519,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683520,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683521,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683522,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683541,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683542,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683543,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683544,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683545,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683546,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683547,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683549,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683550,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683556,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683561,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683562,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683563,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683565,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683571,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683572,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683573,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683574,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683575,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683576,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683577,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683578,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683579,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683580,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683581,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683585,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683587,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683589,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 683594,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 685036,
     "City": "THIRUVANANTHAPURAM",
     "District": "THIRUVANANTHAPURAM",
     "State": "Kerala"
    },
    {
     "Pin Code": 685501,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685503,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685505,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685507,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685508,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685509,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685511,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685512,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685514,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685515,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685531,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685532,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685533,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685533,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 685535,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685551,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685552,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685553,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685554,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685561,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685562,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685563,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685565,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685566,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685571,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685581,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685582,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685583,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685584,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685585,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685586,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685587,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685588,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685589,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685590,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685591,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685595,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685601,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685602,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685602,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685603,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685604,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685605,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685606,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685607,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685608,
     "City": "Thodupuzha",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685609,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685612,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685613,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685614,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685615,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685616,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685618,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685619,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 685620,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 686001,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686002,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686003,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686004,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686005,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686006,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686007,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686008,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686009,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686010,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686011,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686012,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686013,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686014,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686015,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686016,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686017,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686018,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686019,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686020,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686021,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686022,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686041,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686101,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686102,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 686102,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686103,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 686103,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686104,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686105,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686106,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686121,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686122,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686123,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686141,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686143,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686144,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686146,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686501,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686502,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686503,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686504,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686505,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686506,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686507,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686508,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686509,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686510,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686510,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 686511,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 686512,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686513,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 686513,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686514,
     "City": "Idukki",
     "District": "Idukki",
     "State": "Kerala"
    },
    {
     "Pin Code": 686514,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686515,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686516,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686517,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686518,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686519,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686520,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686521,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686522,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686531,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686532,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686533,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686534,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 686535,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686536,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686537,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686538,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686539,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686540,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686541,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686542,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686543,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686544,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686545,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686546,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686547,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 686548,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686555,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686560,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686561,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686562,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686563,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686564,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686571,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686572,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686573,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686574,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686575,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686576,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686577,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686578,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686579,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686580,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686581,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686582,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686583,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686584,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686585,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686586,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686587,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686601,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686602,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686603,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686604,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686605,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686606,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686607,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686608,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686609,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686610,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686611,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686612,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686613,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686616,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686630,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686631,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686632,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686633,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686634,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686635,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686636,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686637,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686651,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686652,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686653,
     "City": "Kottayam",
     "District": "Kottayam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686661,
     "City": "Muvattupuzha",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686662,
     "City": "Muvattupuzha",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686663,
     "City": "Muvattupuzha",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686664,
     "City": "Muvattupuzha",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686665,
     "City": "Muvattupuzha",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686666,
     "City": "Muvattupuzha",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686667,
     "City": "Muvattupuzha",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686668,
     "City": "Muvattupuzha",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686669,
     "City": "Muvattupuzha",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686670,
     "City": "Muvattupuzha",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686671,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686672,
     "City": "Muvattupuzha",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686673,
     "City": "Muvattupuzha",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686681,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686691,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686692,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 686693,
     "City": "Ernakulam",
     "District": "Ernakulam",
     "State": "Kerala"
    },
    {
     "Pin Code": 688001,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688002,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688003,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688004,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688005,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688006,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688007,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688008,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688009,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688011,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688012,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688013,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688014,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688501,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688502,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688503,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688504,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688505,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688506,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688521,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688522,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688523,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688524,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688525,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688526,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688527,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688528,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688529,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688530,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688531,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688532,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688533,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688534,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688535,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688536,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688537,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688538,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688539,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688540,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688541,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688555,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688561,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688562,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688570,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 688582,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689101,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689102,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689103,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689104,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689105,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689106,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689107,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689108,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689109,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689110,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689111,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689112,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689113,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689115,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689121,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689122,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689122,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689123,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689123,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689124,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689126,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689501,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689502,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689503,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689504,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689505,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689506,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689507,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689508,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689509,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689510,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689511,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689512,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689513,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689514,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689520,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689521,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689531,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689532,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689533,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689541,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689542,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689543,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689544,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689545,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689546,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689547,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689548,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689549,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689550,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689551,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689571,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689572,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689573,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689574,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689581,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689582,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689583,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689584,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689585,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689586,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689587,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689588,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689589,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689590,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689591,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689592,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689594,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689595,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689597,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689602,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689611,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689612,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689613,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689614,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689615,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689621,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689622,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689623,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689624,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689625,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689626,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689627,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 689641,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689642,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689643,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689644,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689645,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689646,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689647,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689648,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689649,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689650,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689652,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689653,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689654,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689656,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689661,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689662,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689663,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689664,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689666,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689667,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689668,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689671,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689672,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689673,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689674,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689675,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689676,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689677,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689678,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689691,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689692,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689693,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689694,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689695,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 689695,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689696,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 689698,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689699,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 689711,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 690101,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690102,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690103,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690104,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690105,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690106,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690107,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690108,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690110,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690501,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690502,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690503,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690504,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690504,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 690505,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690506,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690507,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690508,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690509,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690510,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690511,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690512,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690513,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690514,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690515,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690516,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690517,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690518,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690519,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690520,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690521,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690522,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690522,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 690523,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690524,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690525,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690526,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690527,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690528,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690529,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690530,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690531,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690532,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690533,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690534,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690535,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690536,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690537,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690538,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690539,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690540,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690542,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690544,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690547,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690547,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690548,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690558,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690559,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690561,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690571,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690572,
     "City": "Alappuzha",
     "District": "Alappuzha",
     "State": "Kerala"
    },
    {
     "Pin Code": 690573,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 690574,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691001,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691002,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691003,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691004,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691005,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691006,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691007,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691008,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691009,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691010,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691011,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691012,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691013,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691014,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691015,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691016,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691019,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691020,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691021,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691301,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691302,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691303,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691304,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691305,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691306,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691307,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691308,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691309,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691310,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691311,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691312,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691319,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691322,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691331,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691332,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691333,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691334,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691500,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691501,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691502,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691503,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691504,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691505,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691506,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691507,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691508,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691509,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691510,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691511,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691512,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691515,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691516,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691520,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691521,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691522,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691523,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 691524,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 691525,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 691526,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691526,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 691530,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 691531,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691532,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691533,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691534,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691535,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691536,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691537,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691538,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691540,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691541,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691543,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691551,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 691552,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691552,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 691553,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691553,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 691554,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 691555,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 691556,
     "City": "Pathanamthitta",
     "District": "Pathanamthitta",
     "State": "Kerala"
    },
    {
     "Pin Code": 691557,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691559,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691560,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691566,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691571,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691572,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691573,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691574,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691576,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691577,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691578,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691579,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691581,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691582,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691583,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691584,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691585,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691589,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691590,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691601,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 691602,
     "City": "Kollam",
     "District": "Kollam",
     "State": "Kerala"
    },
    {
     "Pin Code": 695001,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695002,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695003,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695004,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695005,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695006,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695007,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695008,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695009,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695010,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695011,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695012,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695013,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695014,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695015,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695016,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695017,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695018,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695019,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695020,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695021,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695022,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695023,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695024,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695025,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695026,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695027,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695028,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695029,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695030,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695032,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695033,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695034,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695035,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695036,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695037,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695038,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695040,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695042,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695043,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695101,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695102,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695103,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695104,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695121,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695122,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695123,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695124,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695125,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695126,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695132,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695133,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695134,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695141,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695142,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695143,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695144,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695145,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695146,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695301,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695302,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695303,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695304,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695305,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695306,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695307,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695308,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695309,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695310,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695311,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695312,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695313,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695316,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695317,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695318,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695501,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695502,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695503,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695504,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695505,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695506,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695507,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695508,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695512,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695513,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695521,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695522,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695523,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695524,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695525,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695526,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695527,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695528,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695541,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695542,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695543,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695547,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695551,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695561,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695562,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695563,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695564,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695568,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695571,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695572,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695573,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695574,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695575,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695581,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695582,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695583,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695584,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695585,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695586,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695587,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695588,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695589,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695601,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695602,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695603,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695604,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695605,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695606,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695607,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695608,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695609,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695610,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695611,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695612,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695614,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 695615,
     "City": "Thiruvananthapuram",
     "District": "Thiruvananthapuram",
     "State": "Kerala"
    },
    {
     "Pin Code": 700001,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700002,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700003,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700004,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700005,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700006,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700007,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700008,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700009,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700010,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700011,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700012,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700013,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700014,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700015,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700016,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700017,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700018,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700019,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700020,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700021,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700022,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700023,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700024,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700025,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700026,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700027,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700028,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700029,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700030,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700031,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700032,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700033,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700034,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700035,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700036,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700037,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700038,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700039,
     "City": "Kolkata",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700040,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700041,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700042,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700043,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700044,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700045,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700046,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700047,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700048,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700049,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700050,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700051,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700052,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700053,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700054,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700055,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700056,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700057,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700058,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700059,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700060,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700061,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700062,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700063,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700064,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700065,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700066,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700067,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700068,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700069,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700070,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700071,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700072,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700073,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700074,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700075,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700076,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700077,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700078,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700079,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700080,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700081,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700082,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700083,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700084,
     "City": "Kolkata",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700085,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700086,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700087,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700088,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700089,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700089,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700090,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700091,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700092,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700093,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700094,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700095,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700096,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700097,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700098,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700099,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700100,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700101,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700102,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700103,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700104,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700105,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700105,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700106,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700107,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700108,
     "City": "Kolkata",
     "District": "Kolkata",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700109,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700110,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700111,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700112,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700113,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700114,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700115,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700116,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700117,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700118,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700119,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700120,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700121,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700122,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700123,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700124,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700125,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700126,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700127,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700128,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700129,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700130,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700131,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700132,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700133,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700134,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700135,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700135,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700136,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700137,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700138,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700139,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700140,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700141,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700142,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700143,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700144,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700145,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700146,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700147,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700148,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700149,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700150,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700151,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700152,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700153,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700154,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700155,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700156,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700157,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700158,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700159,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700160,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700194,
     "City": "South 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700201,
     "City": "South 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 700204,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711101,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711102,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711103,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711104,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711105,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711106,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711107,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711108,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711109,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711110,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711111,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711112,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711113,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711114,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711201,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711202,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711203,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711204,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711205,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711225,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711226,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711227,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711301,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711302,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711303,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711304,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711305,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711306,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711307,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711308,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711309,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711310,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711312,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711313,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711314,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711315,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711316,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711317,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711321,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711322,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711323,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711328,
     "City": "Jagadishpur",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711401,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711403,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711404,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711405,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711407,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711408,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711409,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711410,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711411,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711412,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711413,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 711414,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712101,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712102,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712103,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712104,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712105,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712121,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712122,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712123,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712124,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712125,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712134,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712135,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712136,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712137,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712138,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712139,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712146,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712147,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712148,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712149,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712152,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712201,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712202,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712203,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712204,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712205,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712221,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712222,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712223,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712224,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712232,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712233,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712234,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712235,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712245,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712246,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712247,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712248,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712249,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712250,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712258,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712301,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712302,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712303,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712304,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712305,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712306,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712308,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712310,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712311,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712316,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712331,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712401,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712402,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712403,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712404,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712405,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712406,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712407,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712408,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712408,
     "City": "Howrah",
     "District": "Howrah",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712409,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712410,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712410,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712411,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712412,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712413,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712414,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712415,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712416,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712417,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712418,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712419,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712424,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712426,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712428,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712429,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712501,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712502,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712503,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712504,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712512,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712513,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712514,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712515,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712601,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712602,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712611,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712612,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712613,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712614,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712615,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712616,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712617,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712701,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712702,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712704,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712705,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712706,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712707,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 712708,
     "City": "Hooghly",
     "District": "Hooghly",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713101,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713102,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713103,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713104,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713121,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713122,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713123,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713123,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713124,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713125,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713126,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713127,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713128,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713129,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713130,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713131,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713140,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713141,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713142,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713143,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713144,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713145,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713146,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713147,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713148,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713149,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713150,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713150,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713151,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713152,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713153,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713154,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713155,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713156,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713157,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713158,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713160,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713162,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713165,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713166,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713167,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713168,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713169,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713170,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713171,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713172,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713173,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713201,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713202,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713203,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713204,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713205,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713206,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713207,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713208,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713209,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713210,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713211,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713212,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713213,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713214,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713215,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713216,
     "City": "Durgapur",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713217,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713218,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713219,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713301,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713302,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713303,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713304,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713305,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713315,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713321,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713322,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713323,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713324,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713325,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713326,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713330,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713331,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713332,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713333,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713334,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713335,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713336,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713337,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713338,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713339,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713340,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713341,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713342,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713343,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713344,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713346,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713347,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713357,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713358,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713359,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713360,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713361,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713362,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713363,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713365,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713367,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713368,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713369,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713370,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713371,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713372,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713373,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713375,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713376,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713377,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713378,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713380,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713381,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713383,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713384,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713385,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713386,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713401,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713402,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713403,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713404,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713405,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713406,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713407,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713408,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713409,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713419,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713420,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713421,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713422,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713423,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713424,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713425,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713426,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713427,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713428,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713429,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713430,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713432,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713433,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713434,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713501,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713502,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713512,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713512,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713513,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713514,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713514,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713515,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713516,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713517,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713519,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713519,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 713520,
     "City": "Bardhaman",
     "District": "Bardhaman",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721101,
     "City": "Medinipur",
     "District": "Medinipur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721101,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721102,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721121,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721122,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721124,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721125,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721125,
     "City": "West Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721126,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721127,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721128,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721129,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721130,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721130,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721131,
     "City": "Kharagpur",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721131,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721132,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721133,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721134,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721135,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721135,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721136,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721137,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721138,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721139,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721140,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721142,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721143,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721144,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721144,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721145,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721146,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721147,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721148,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721149,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721150,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721151,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721152,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721153,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721153,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721154,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721155,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721156,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721157,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721158,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721159,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721160,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721161,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721166,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721167,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721168,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721171,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721172,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721201,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721211,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721212,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721222,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721232,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721242,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721253,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721253,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721254,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721260,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721301,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721301,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721302,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721303,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721304,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721305,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721306,
     "City": "Kharagpur",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721401,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721420,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721422,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721423,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721424,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721425,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721425,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721426,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721427,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721428,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721429,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721430,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721431,
     "City": "Kharagpur",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721431,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721432,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721433,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721434,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721435,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721435,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721436,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721437,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721437,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721438,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721439,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721440,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721441,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721442,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721443,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721443,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721444,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721445,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721446,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721447,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721448,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721449,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721450,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721451,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721452,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721453,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721454,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721455,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721456,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721457,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721458,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721461,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721463,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721464,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721467,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721501,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721503,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721504,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721505,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721506,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721507,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721513,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721514,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721515,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721516,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721517,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721518,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721601,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721602,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721603,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721604,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721605,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721606,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721607,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721625,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721626,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721627,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721627,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721628,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721629,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721630,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721631,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721632,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721633,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721634,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721635,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721636,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721641,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721641,
     "City": "Midnapore",
     "District": "West Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721642,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721643,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721644,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721645,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721646,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721647,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721648,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721649,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721650,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721651,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721652,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721653,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721654,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721655,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721656,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721657,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721658,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721659,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721666,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 721669,
     "City": "Midnapore",
     "District": "East Midnapore",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722101,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722102,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722107,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722110,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722111,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722112,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722121,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722122,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722132,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722133,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722134,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722135,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722136,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722136,
     "City": "Midnapore",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722137,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722138,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722139,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722140,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722141,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722142,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722143,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722144,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722145,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722146,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722147,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722148,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722149,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722150,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722151,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722152,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722153,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722154,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722155,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722156,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722157,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722158,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722160,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722161,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722162,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722163,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722164,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722165,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722166,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722168,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722169,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722170,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722171,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722172,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722173,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722174,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722175,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722176,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722177,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722178,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722179,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722180,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722181,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722182,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722183,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722201,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722202,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722203,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722204,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722205,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722206,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722207,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722208,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 722209,
     "City": "Bankura",
     "District": "Bankura",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723101,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723102,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723103,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723104,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723121,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723126,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723127,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723128,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723129,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723130,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723131,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723132,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723133,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723142,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723143,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723145,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723146,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723147,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723148,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723149,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723151,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723152,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723153,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723154,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723155,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723156,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723158,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723161,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723168,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723201,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723202,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723212,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723213,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723215,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 723216,
     "City": "Puruliya",
     "District": "Puruliya",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731101,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731102,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731103,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731104,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731121,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731123,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731124,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731125,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731126,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731127,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731129,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731130,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731132,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731133,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731201,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731202,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731204,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731213,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731214,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731215,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731216,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731219,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731220,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731221,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731222,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731223,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731224,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731233,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731234,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731235,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731236,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731237,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731238,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731239,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731240,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731241,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731242,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731243,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731244,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731245,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731301,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731302,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 731303,
     "City": "Birbhum",
     "District": "Birbhum",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732101,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732102,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732103,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732121,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732122,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732123,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732124,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732125,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732126,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732127,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732128,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732138,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732139,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732140,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732141,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732142,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732143,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732144,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732147,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732149,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732150,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732201,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732202,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732203,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732204,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732205,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732206,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732207,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732208,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732209,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732210,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732211,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732212,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732213,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732214,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732215,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 732216,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733101,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733102,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733103,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733121,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733121,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733123,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733124,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733124,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733125,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733125,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733126,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733127,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733128,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733128,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733129,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733130,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733132,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733132,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733133,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733134,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733140,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733141,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733142,
     "City": "Malda",
     "District": "Malda",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733142,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733143,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733145,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733146,
     "City": "North Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733156,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733158,
     "City": "South Dinajpur",
     "District": "South Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733201,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733202,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733207,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733208,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733209,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733210,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 733215,
     "City": "North Dinajpur",
     "District": "North Dinajpur",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734001,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734001,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734001,
     "City": "Siliguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734003,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734004,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734004,
     "City": "Siliguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734005,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734006,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734007,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734007,
     "City": "Siliguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734008,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734009,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734010,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734011,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734012,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734013,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734014,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734015,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734101,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734102,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734103,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734104,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734105,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734106,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734121,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734122,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734123,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734124,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734201,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734203,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734204,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734209,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734213,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734214,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734215,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734216,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734217,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734218,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734220,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734221,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734222,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734223,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734224,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734226,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734227,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734301,
     "City": "Kalimpong",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734311,
     "City": "Kalimpong",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734312,
     "City": "Kalimpong",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734313,
     "City": "Kalimpong",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734314,
     "City": "Kalimpong",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734315,
     "City": "Kalimpong",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734316,
     "City": "Kalimpong",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734317,
     "City": "Kalimpong",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734319,
     "City": "Kalimpong",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734320,
     "City": "Kalimpong",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734321,
     "City": "Kalimpong",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734401,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734403,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734421,
     "City": "Kalimpong",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734422,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734423,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734424,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734426,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734427,
     "City": "Siliguri",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734429,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734431,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734434,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734436,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734501,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 734503,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735101,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735102,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735121,
     "City": "Darjeeling",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735121,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735122,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735122,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735132,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735133,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735134,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735135,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735182,
     "City": "JAIGAON",
     "District": "JAIGAON",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735201,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735202,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735203,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735204,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735205,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735206,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735206,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735207,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735208,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735209,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735210,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735211,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735211,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735212,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735213,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735214,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735215,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735216,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735217,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735217,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735218,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735219,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735220,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735221,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735222,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735223,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735224,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735224,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735225,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735226,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735227,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735228,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735229,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735230,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735231,
     "City": "Darjeeling",
     "District": "Darjiling",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735232,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735233,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735234,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735235,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735301,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735302,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735303,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735303,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735304,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735305,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 735307,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736101,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736118,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736121,
     "City": "Alipurduar",
     "District": "Alipurduar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736122,
     "City": "Alipurduar",
     "District": "Alipurduar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736123,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736131,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736133,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736134,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736135,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736145,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736146,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736156,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736157,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736158,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736159,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736160,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736165,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736167,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736168,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736169,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736170,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736171,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736172,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736175,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736176,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736179,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736182,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736201,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736202,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736203,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736204,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736204,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736205,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736206,
     "City": "Cooch Behar",
     "District": "Cooch Behar",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736206,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736207,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 736208,
     "City": "Jalpaiguri",
     "District": "Jalpaiguri",
     "State": "West Bengal"
    },
    {
     "Pin Code": 737034,
     "City": "Gangtok",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737034,
     "City": "Gangtok",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737101,
     "City": "Gangtok",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737101,
     "City": "Gangtok",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737102,
     "City": "Gangtok",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737102,
     "City": "Gangtok",
     "District": "South Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737102,
     "City": "Gangtok",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737102,
     "City": "Gangtok",
     "District": "South Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737103,
     "City": "Gangtok",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737103,
     "City": "Gangtok",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737106,
     "City": "Pakyong",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737106,
     "City": "Pakyong",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737107,
     "City": "Dikchu",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737107,
     "City": "Dikchu",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737111,
     "City": "Gayzing",
     "District": "West Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737111,
     "City": "Gayzing",
     "District": "West Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737113,
     "City": "Pelling",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737113,
     "City": "Pelling",
     "District": "West Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737113,
     "City": "Pelling",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737113,
     "City": "Pelling",
     "District": "West Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737116,
     "City": "Mangan",
     "District": "North Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737116,
     "City": "Mangan",
     "District": "North Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737117,
     "City": "Mangan",
     "District": "North Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737117,
     "City": "Mangan",
     "District": "North Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737118,
     "City": "Mangan",
     "District": "North Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737118,
     "City": "Mangan",
     "District": "North Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737119,
     "City": "Mangan",
     "District": "North Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737119,
     "City": "Mangan",
     "District": "North Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737120,
     "City": "Chungthang",
     "District": "North Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737120,
     "City": "Chungthang",
     "District": "North Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737121,
     "City": "Jorethang",
     "District": "South Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737121,
     "City": "Jorethang",
     "District": "West Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737121,
     "City": "Jorethang",
     "District": "South Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737121,
     "City": "Jorethang",
     "District": "West Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737126,
     "City": "Namchi",
     "District": "South Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737126,
     "City": "Namchi",
     "District": "South Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737128,
     "City": "Melli",
     "District": "South Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737128,
     "City": "Melli",
     "District": "South Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737131,
     "City": "Rangli",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737131,
     "City": "Rangli",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737132,
     "City": "Rangpo",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737132,
     "City": "Rangpo",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737133,
     "City": "Rhenock",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737133,
     "City": "Rhenock",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737134,
     "City": "Singtam",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737134,
     "City": "Singtam",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737135,
     "City": "Gangtok",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737135,
     "City": "Ranipool",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737135,
     "City": "Gangtok",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737135,
     "City": "Ranipool",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737136,
     "City": "Rangpo",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737136,
     "City": "Rangpo",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737138,
     "City": "Singtam",
     "District": "East Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737138,
     "City": "Singtam",
     "District": "East Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 737139,
     "City": "Ravangla",
     "District": "South Sikkim",
     "State": "Sikkim"
    },
    {
     "Pin Code": 737139,
     "City": "Ravangla",
     "District": "South Sikkim",
     "State": "Unknown"
    },
    {
     "Pin Code": 741101,
     "City": "Krishnanagar",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741102,
     "City": "Krishnanagar",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741103,
     "City": "Krishnanagar",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741121,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741122,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741123,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741124,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741125,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741126,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741127,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741137,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741138,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741139,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741140,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741150,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741151,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741152,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741153,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741154,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741155,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741156,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741157,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741158,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741159,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741160,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741161,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741162,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741163,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741164,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741165,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741166,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741167,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741181,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741201,
     "City": "Ranaghat",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741202,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741221,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741222,
     "City": "Chakdah",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741223,
     "City": "Chakdah",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741232,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741233,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741234,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741235,
     "City": "Kalyani",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741238,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741245,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741246,
     "City": "Haringhata",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741247,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741248,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741249,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741250,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741251,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741252,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741253,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741254,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741255,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741256,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741257,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741301,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741302,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741313,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741315,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741316,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741317,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741319,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741401,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741402,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741404,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741405,
     "City": "Phulia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741501,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741502,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741503,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741504,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741505,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741506,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741507,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741508,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 741509,
     "City": "Nadia",
     "District": "Nadia",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742101,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742102,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742103,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742104,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742113,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742121,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742122,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742123,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742132,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742133,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742134,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742135,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742136,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742137,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742138,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742140,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742147,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742148,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742149,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742151,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742159,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742160,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742161,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742163,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742164,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742165,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742166,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742168,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742169,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742174,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742175,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742178,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742184,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742187,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742189,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742201,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742202,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742212,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742213,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742223,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742224,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742225,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742226,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742227,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742229,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742232,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742235,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742236,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742237,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742238,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742301,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742302,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742303,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742304,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742305,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742306,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742308,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742401,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742402,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742404,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742405,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742406,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742407,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 742408,
     "City": "Murshidabad",
     "District": "Murshidabad",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743101,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743120,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743122,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743123,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743124,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743125,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743126,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743127,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743128,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743129,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743130,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743133,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743134,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743135,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743136,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743144,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743145,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743165,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743166,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743188,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743193,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743194,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743201,
     "City": "Falta",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743221,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743222,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743223,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743232,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743233,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743234,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743235,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743244,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743245,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743247,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743248,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743249,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743251,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743252,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743262,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743263,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743268,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743269,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743270,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743271,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743272,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743273,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743274,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743276,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743286,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743287,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743289,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743290,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743291,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743292,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743293,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743294,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743297,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743302,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743312,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743313,
     "City": "North 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743318,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743329,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743329,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743330,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743331,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743332,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743336,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743337,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743338,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743339,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743343,
     "City": "North 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743345,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743347,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743348,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743349,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743351,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743352,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743353,
     "City": "Kolkata",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743354,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743355,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743356,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743357,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743363,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743368,
     "City": "Diamond Harbour",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743370,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743371,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743372,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743373,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743374,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743375,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743376,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743377,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743378,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743383,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743384,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743385,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743387,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743389,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743390,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743391,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743392,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743395,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743396,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743398,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743399,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743401,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743405,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743411,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743412,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743413,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743422,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743423,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743424,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743425,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743426,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743427,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743428,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743429,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743435,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743437,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743438,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743439,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743440,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743441,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743442,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743445,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743446,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743456,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743502,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743503,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743504,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743507,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743510,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743512,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743513,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743516,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743601,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743603,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743606,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743609,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743610,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743611,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743611,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743613,
     "City": "South 24 Parganas",
     "District": "South 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743701,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743702,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743704,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743710,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 743711,
     "City": "North 24 Parganas",
     "District": "North 24 Parganas",
     "State": "West Bengal"
    },
    {
     "Pin Code": 744101,
     "City": "Port Blair",
     "District": "South Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744102,
     "City": "Port Blair",
     "District": "South Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744103,
     "City": "Port Blair",
     "District": "South Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744104,
     "City": "Port Blair",
     "District": "South Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744105,
     "City": "Port Blair",
     "District": "South Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744106,
     "City": "Port Blair",
     "District": "South Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744107,
     "City": "Port Blair",
     "District": "South Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744112,
     "City": "Port Blair",
     "District": "South Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744201,
     "City": "Diglipur",
     "District": "North And Middle Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744202,
     "City": "Diglipur",
     "District": "North And Middle Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744203,
     "City": "North Andaman",
     "District": "North And Middle Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744204,
     "City": "North Andaman",
     "District": "North And Middle Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744205,
     "City": "North Andaman",
     "District": "North And Middle Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744206,
     "City": "South Andaman",
     "District": "South Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744207,
     "City": "South Andaman",
     "District": "South Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744209,
     "City": "North And Middle Andaman",
     "District": "North And Middle Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744210,
     "City": "Port Blair",
     "District": "North And Middle Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744211,
     "City": "Port Blair",
     "District": "South Andaman",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744301,
     "City": "Nicobar",
     "District": "Nicobar",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744302,
     "City": "Nicobar",
     "District": "Nicobar",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744303,
     "City": "Nicobar",
     "District": "Nicobar",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 744304,
     "City": "Nicobar",
     "District": "Nicobar",
     "State": "Andaman and Nicobar Islands"
    },
    {
     "Pin Code": 751001,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751002,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751003,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751004,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751005,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751006,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751007,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751008,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751009,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751010,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751011,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751012,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751013,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751014,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751015,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751016,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751017,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751018,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751019,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751020,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751021,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751022,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751023,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751024,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751025,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751030,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 751031,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752001,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752002,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752003,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752011,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752012,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752013,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752014,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752015,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752016,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752017,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752018,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752019,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752020,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752021,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752022,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752023,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752024,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752025,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752026,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752027,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752030,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752030,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752031,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752034,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752035,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752037,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752038,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752045,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752046,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752050,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752054,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752055,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752056,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752057,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752060,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752061,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752062,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752063,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752064,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752065,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752066,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752068,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752069,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752070,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752077,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752078,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752079,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752080,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752081,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752082,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752083,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752084,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752085,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752089,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752090,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752091,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752092,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752093,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752094,
     "City": "Nayagarh",
     "District": "Nayagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 752100,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752101,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752102,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752102,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752103,
     "City": "Bhubaneswar",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752104,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752105,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752106,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752107,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752108,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752109,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752110,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752111,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752113,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752114,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752115,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 752116,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752118,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752119,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752120,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 752120,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 752121,
     "City": "Puri",
     "District": "Puri",
     "State": "Odisha"
    },
    {
     "Pin Code": 753001,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753002,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753003,
     "City": "Bhubaneswar",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753003,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753004,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753006,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753007,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753008,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753009,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753010,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753011,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753012,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753013,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753014,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 753015,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754001,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754001,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 754002,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754003,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754004,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754004,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 754005,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754005,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 754006,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754007,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754008,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754009,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754010,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754011,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754012,
     "City": "Khorda",
     "District": "Khorda",
     "State": "Odisha"
    },
    {
     "Pin Code": 754018,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754020,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754021,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754022,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754023,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754023,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754024,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754025,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754026,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754027,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754028,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754029,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754030,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754031,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754032,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754035,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754037,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754071,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754082,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754100,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754102,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754103,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754104,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754105,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754106,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754107,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754108,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754109,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754110,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754111,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754112,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754113,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754113,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754114,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754119,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754120,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754130,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754130,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754131,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754132,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754133,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754134,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754134,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754134,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754135,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754136,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754137,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754138,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754139,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754140,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754141,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754141,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754142,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754143,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754145,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754153,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754159,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754160,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754162,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754162,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754200,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754201,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754202,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754203,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754203,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754204,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754205,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754205,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754206,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754207,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754208,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754209,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754210,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754211,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754212,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754213,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754214,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754214,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754215,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754216,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754217,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754218,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754219,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754220,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754221,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754222,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754223,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754224,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754225,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754227,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754228,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754231,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754239,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754240,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754244,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754245,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754246,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754248,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754250,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754253,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754289,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 754290,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754292,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754293,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754294,
     "City": "Jagatsinghapur",
     "District": "Jagatsinghapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 754295,
     "City": "Cuttack",
     "District": "Cuttack",
     "State": "Odisha"
    },
    {
     "Pin Code": 754296,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755001,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755003,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755004,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755005,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755006,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755007,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755008,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755009,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755010,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755011,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755012,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755013,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755014,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755015,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755016,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755017,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755018,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755019,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755019,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 755020,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755022,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755023,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755024,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755025,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755026,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755027,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755028,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755036,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755043,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755044,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755049,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755050,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 755061,
     "City": "Kendrapara",
     "District": "Kendrapara",
     "State": "Odisha"
    },
    {
     "Pin Code": 755062,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 756001,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756002,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756003,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756019,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756020,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756021,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756022,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756023,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756024,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756025,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756026,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756027,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756028,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756029,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756030,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756032,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756033,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756034,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756035,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756036,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756037,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756038,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756039,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756040,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756041,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756042,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756043,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756044,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756045,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756046,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756047,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756048,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756049,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756051,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756055,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756056,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756058,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756059,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756060,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756079,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756080,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756081,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756083,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756084,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756085,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756086,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756100,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756101,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756111,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756112,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756113,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756114,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756115,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756115,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756116,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756117,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756118,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756119,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756120,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756121,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756121,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756122,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756123,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756124,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756125,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756126,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756127,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756128,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756129,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756130,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756131,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756132,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756133,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756133,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756134,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756135,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756137,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756138,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756139,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756144,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756162,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756163,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756164,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756165,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756166,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 756167,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756168,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756171,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756181,
     "City": "Bhadrak",
     "District": "Bhadrak",
     "State": "Odisha"
    },
    {
     "Pin Code": 756182,
     "City": "Baleswar",
     "District": "Baleswar",
     "State": "Odisha"
    },
    {
     "Pin Code": 757001,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757002,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757003,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757014,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757016,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757017,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757018,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757019,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757020,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757021,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757022,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757023,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757024,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757025,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757026,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757027,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757028,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757029,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757030,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757031,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757032,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757033,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757034,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757035,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757036,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757037,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757038,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757039,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757040,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757041,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757042,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757043,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757045,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757046,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757047,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757048,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757049,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757050,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757051,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757052,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757053,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757054,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757055,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757073,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757074,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757075,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757077,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757079,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757081,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757082,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757083,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757084,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757085,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757086,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757087,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757091,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757092,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757093,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757100,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757101,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757102,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757103,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757104,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757105,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757106,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 757107,
     "City": "Mayurbhanj",
     "District": "Mayurbhanj",
     "State": "Odisha"
    },
    {
     "Pin Code": 758001,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758002,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758013,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758014,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758015,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758016,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758016,
     "City": "Patna",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758017,
     "City": "Patna",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758018,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758019,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758020,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758021,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758022,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758023,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758025,
     "City": "Jajapur",
     "District": "Jajapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 758025,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758026,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758027,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758028,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758029,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758030,
     "City": "Patna",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758031,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758032,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758034,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758035,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758036,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758037,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758038,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758040,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758041,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758043,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758044,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758045,
     "City": "Patna",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758046,
     "City": "Patna",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758047,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758076,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758078,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758079,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758080,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758081,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758082,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758083,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758084,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 758085,
     "City": "Kendujhar",
     "District": "Kendujhar",
     "State": "Odisha"
    },
    {
     "Pin Code": 759001,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759013,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759014,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759015,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759016,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759017,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759018,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759019,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759020,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759021,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759022,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759023,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759024,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759025,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759026,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759027,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759028,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759037,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759039,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759040,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759100,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759101,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759102,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759103,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759104,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759105,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759106,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759107,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759111,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759116,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759117,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759118,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759119,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759120,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759121,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759122,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759123,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759124,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759125,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759126,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759127,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759128,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759128,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759129,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759130,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759132,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759141,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759143,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759145,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759146,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759146,
     "City": "Dhenkanal",
     "District": "Dhenkanal",
     "State": "Odisha"
    },
    {
     "Pin Code": 759147,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 759148,
     "City": "Angul",
     "District": "Angul",
     "State": "Odisha"
    },
    {
     "Pin Code": 760001,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 760002,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 760003,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 760004,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 760005,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 760006,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 760007,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 760008,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 760009,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 760010,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761001,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761002,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761003,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761004,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761005,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761006,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761007,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761008,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761009,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761010,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761011,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761012,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761013,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761014,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761014,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761015,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761016,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761017,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761017,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761018,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761018,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761019,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761020,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761025,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761026,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761027,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761028,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761029,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761030,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761031,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761032,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761035,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761037,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761041,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761042,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761043,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761045,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761052,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761053,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761054,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761055,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761100,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761101,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761102,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761103,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761104,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761105,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761106,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761107,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761108,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761109,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761110,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761111,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761114,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761115,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761116,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761117,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761118,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761119,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761120,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761121,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761122,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761123,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761124,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761125,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761126,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761131,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761132,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761133,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761140,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761141,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761143,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761144,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761146,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761151,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761200,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761201,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761206,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761207,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761208,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761209,
     "City": "Ganjam",
     "District": "Ganjam",
     "State": "Odisha"
    },
    {
     "Pin Code": 761210,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761211,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761212,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761213,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761214,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761215,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 761217,
     "City": "Gajapati",
     "District": "Gajapati",
     "State": "Odisha"
    },
    {
     "Pin Code": 762001,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762002,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762010,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762011,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762012,
     "City": "Boudh",
     "District": "Boudh",
     "State": "Odisha"
    },
    {
     "Pin Code": 762012,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762013,
     "City": "Boudh",
     "District": "Boudh",
     "State": "Odisha"
    },
    {
     "Pin Code": 762014,
     "City": "Boudh",
     "District": "Boudh",
     "State": "Odisha"
    },
    {
     "Pin Code": 762015,
     "City": "Boudh",
     "District": "Boudh",
     "State": "Odisha"
    },
    {
     "Pin Code": 762016,
     "City": "Boudh",
     "District": "Boudh",
     "State": "Odisha"
    },
    {
     "Pin Code": 762017,
     "City": "Boudh",
     "District": "Boudh",
     "State": "Odisha"
    },
    {
     "Pin Code": 762018,
     "City": "Boudh",
     "District": "Boudh",
     "State": "Odisha"
    },
    {
     "Pin Code": 762018,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762019,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762020,
     "City": "Boudh",
     "District": "Boudh",
     "State": "Odisha"
    },
    {
     "Pin Code": 762020,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762021,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762022,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762023,
     "City": "Boudh",
     "District": "Boudh",
     "State": "Odisha"
    },
    {
     "Pin Code": 762024,
     "City": "Boudh",
     "District": "Boudh",
     "State": "Odisha"
    },
    {
     "Pin Code": 762026,
     "City": "Boudh",
     "District": "Boudh",
     "State": "Odisha"
    },
    {
     "Pin Code": 762027,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762028,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762029,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762030,
     "City": "Boudh",
     "District": "Boudh",
     "State": "Odisha"
    },
    {
     "Pin Code": 762100,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762101,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762102,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762103,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762104,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762105,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762106,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762107,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762109,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762110,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 762112,
     "City": "Kandhamal",
     "District": "Kandhamal",
     "State": "Odisha"
    },
    {
     "Pin Code": 763001,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 763002,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 763003,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 763004,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 763008,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764001,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764002,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764003,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764004,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764005,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764006,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764011,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764014,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764020,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764021,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764027,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764028,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764036,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764037,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764038,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764039,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764040,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764041,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764041,
     "City": "Malkangiri",
     "District": "Malkangiri",
     "State": "Odisha"
    },
    {
     "Pin Code": 764042,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764043,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764043,
     "City": "Malkangiri",
     "District": "Malkangiri",
     "State": "Odisha"
    },
    {
     "Pin Code": 764044,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764044,
     "City": "Malkangiri",
     "District": "Malkangiri",
     "State": "Odisha"
    },
    {
     "Pin Code": 764045,
     "City": "Malkangiri",
     "District": "Malkangiri",
     "State": "Odisha"
    },
    {
     "Pin Code": 764046,
     "City": "Malkangiri",
     "District": "Malkangiri",
     "State": "Odisha"
    },
    {
     "Pin Code": 764047,
     "City": "Malkangiri",
     "District": "Malkangiri",
     "State": "Odisha"
    },
    {
     "Pin Code": 764048,
     "City": "Malkangiri",
     "District": "Malkangiri",
     "State": "Odisha"
    },
    {
     "Pin Code": 764049,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764051,
     "City": "Malkangiri",
     "District": "Malkangiri",
     "State": "Odisha"
    },
    {
     "Pin Code": 764052,
     "City": "Malkangiri",
     "District": "Malkangiri",
     "State": "Odisha"
    },
    {
     "Pin Code": 764053,
     "City": "Malkangiri",
     "District": "Malkangiri",
     "State": "Odisha"
    },
    {
     "Pin Code": 764055,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764055,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764056,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764057,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764058,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764059,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764059,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 764061,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764062,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 764063,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764070,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764071,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764072,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764073,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764074,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764075,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764076,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764077,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764078,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764081,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 764085,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 764086,
     "City": "Malkangiri",
     "District": "Malkangiri",
     "State": "Odisha"
    },
    {
     "Pin Code": 764087,
     "City": "Malkangiri",
     "District": "Malkangiri",
     "State": "Odisha"
    },
    {
     "Pin Code": 764088,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 765001,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765002,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765013,
     "City": "Koraput",
     "District": "Koraput",
     "State": "Odisha"
    },
    {
     "Pin Code": 765013,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765015,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765016,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765017,
     "City": "Nabarangapur",
     "District": "Nabarangapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 765017,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765018,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765019,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765020,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765021,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765022,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765023,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765024,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765025,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765026,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765029,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765033,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 765034,
     "City": "Rayagada",
     "District": "Rayagada",
     "State": "Odisha"
    },
    {
     "Pin Code": 766001,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766002,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766011,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766012,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766013,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766014,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766015,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766016,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766017,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766018,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766019,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766020,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766023,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766026,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766027,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766028,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766029,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766031,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766032,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766036,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766037,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766100,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766101,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766102,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766103,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766104,
     "City": "Nuapada",
     "District": "Nuapada",
     "State": "Odisha"
    },
    {
     "Pin Code": 766105,
     "City": "Nuapada",
     "District": "Nuapada",
     "State": "Odisha"
    },
    {
     "Pin Code": 766106,
     "City": "Nuapada",
     "District": "Nuapada",
     "State": "Odisha"
    },
    {
     "Pin Code": 766107,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766107,
     "City": "Nuapada",
     "District": "Nuapada",
     "State": "Odisha"
    },
    {
     "Pin Code": 766108,
     "City": "Nuapada",
     "District": "Nuapada",
     "State": "Odisha"
    },
    {
     "Pin Code": 766110,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766111,
     "City": "Nuapada",
     "District": "Nuapada",
     "State": "Odisha"
    },
    {
     "Pin Code": 766118,
     "City": "Kalahandi",
     "District": "Kalahandi",
     "State": "Odisha"
    },
    {
     "Pin Code": 766118,
     "City": "Nuapada",
     "District": "Nuapada",
     "State": "Odisha"
    },
    {
     "Pin Code": 767001,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767002,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767016,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767016,
     "City": "Sonapur",
     "District": "Sonapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 767017,
     "City": "Sonapur",
     "District": "Sonapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 767018,
     "City": "Sonapur",
     "District": "Sonapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 767019,
     "City": "Sonapur",
     "District": "Sonapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 767020,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767020,
     "City": "Sonapur",
     "District": "Sonapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 767021,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767022,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767023,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767023,
     "City": "Sonapur",
     "District": "Sonapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 767024,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767025,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767026,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767027,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767028,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767029,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767030,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767032,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767033,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767035,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767037,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767038,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767039,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767040,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767041,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767042,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767045,
     "City": "Sonapur",
     "District": "Sonapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 767046,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767048,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767060,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767061,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767062,
     "City": "Sonapur",
     "District": "Sonapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 767065,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767066,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767067,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767068,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 767068,
     "City": "Sonapur",
     "District": "Sonapur",
     "State": "Odisha"
    },
    {
     "Pin Code": 767070,
     "City": "Balangir",
     "District": "Balangir",
     "State": "Odisha"
    },
    {
     "Pin Code": 768001,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768002,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768003,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768004,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768005,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768006,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768016,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768017,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768018,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768019,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768020,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768025,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768026,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768027,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768028,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768029,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768030,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768031,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768032,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768033,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768034,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768035,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768036,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768037,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768038,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768039,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768040,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768042,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768045,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768045,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768048,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768049,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768050,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768052,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768102,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768103,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768104,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768104,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768105,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768106,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768107,
     "City": "Debagarh",
     "District": "Debagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768107,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768108,
     "City": "Debagarh",
     "District": "Debagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768109,
     "City": "Debagarh",
     "District": "Debagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768110,
     "City": "Debagarh",
     "District": "Debagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768111,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768112,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768113,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768115,
     "City": "Bargarh",
     "District": "Bargarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768118,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768119,
     "City": "Debagarh",
     "District": "Debagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768121,
     "City": "Debagarh",
     "District": "Debagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768200,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768201,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768202,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768203,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768204,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768211,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768212,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768213,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768214,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768215,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768216,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768217,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768218,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768219,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768220,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768221,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768222,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768224,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768225,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768226,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768227,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768228,
     "City": "Debagarh",
     "District": "Debagarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 768228,
     "City": "Sambalpur",
     "District": "Sambalpur",
     "State": "Odisha"
    },
    {
     "Pin Code": 768233,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 768234,
     "City": "Jharsuguda",
     "District": "Jharsuguda",
     "State": "Odisha"
    },
    {
     "Pin Code": 769001,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769001,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769002,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769002,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769003,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769003,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769004,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769004,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769005,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769005,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769006,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769006,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769007,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769007,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769008,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769008,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769009,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769009,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769010,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769010,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769011,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769011,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769012,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769013,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769014,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769015,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769016,
     "City": "Rourkela",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769042,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 769043,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770001,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770002,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770011,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770012,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770013,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770014,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770015,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770016,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770017,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770018,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770019,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770020,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770021,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770022,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770023,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770024,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770031,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770032,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770033,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770034,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770035,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770036,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770037,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770038,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770039,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770040,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770041,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770042,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770043,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770044,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770046,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770048,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770051,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770052,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770070,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770072,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770073,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770074,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770075,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 770076,
     "City": "Sundergarh",
     "District": "Sundergarh",
     "State": "Odisha"
    },
    {
     "Pin Code": 781001,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781002,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781003,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781004,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781005,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781006,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781007,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781008,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781009,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781010,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781011,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781012,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781013,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781014,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781015,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781016,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781017,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781018,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781019,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781020,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781021,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781022,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781023,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781024,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781025,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781026,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781027,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781028,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781029,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781030,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781031,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781032,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781034,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781035,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781036,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781037,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781038,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781039,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781040,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781101,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781102,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781103,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781104,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781120,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781121,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781122,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781123,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781124,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781125,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781126,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781127,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781128,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781129,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781130,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781131,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781132,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781133,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 781134,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781135,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781136,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781137,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781138,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781141,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781150,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781171,
     "City": "Guwahati",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781301,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781302,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781303,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781304,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781305,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781305,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781306,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781307,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781308,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781309,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781309,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781310,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781311,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781312,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781313,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781314,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781315,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781316,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781317,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781317,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781318,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781319,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781321,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781325,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781326,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781327,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781328,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781329,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781329,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781330,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781333,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781334,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781335,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781337,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781338,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781339,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781340,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781341,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781343,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781344,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781346,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781347,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781348,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781349,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781350,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781350,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781350,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781351,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781352,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781353,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781354,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781355,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781360,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781364,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781365,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781366,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781367,
     "City": "Barpeta",
     "District": "Barpeta",
     "State": "Assam"
    },
    {
     "Pin Code": 781367,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781368,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781369,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781370,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781371,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781372,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781373,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781374,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781375,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781376,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781377,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781378,
     "City": "Nalbari",
     "District": "Nalbari",
     "State": "Assam"
    },
    {
     "Pin Code": 781380,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781381,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 781382,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 782001,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782001,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782002,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782003,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782101,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782102,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782103,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782103,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782104,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782105,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782106,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782112,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782120,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782121,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782121,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782122,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782123,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782124,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782125,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782126,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782127,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782135,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782136,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782137,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782138,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782139,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782139,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782140,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782141,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782142,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782143,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782144,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782401,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 782402,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 782403,
     "City": "Kamrup",
     "District": "Kamrup",
     "State": "Assam"
    },
    {
     "Pin Code": 782410,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782410,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782410,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782411,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782412,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782413,
     "City": "Marigaon",
     "District": "Marigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782425,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782425,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782426,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782427,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782428,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782429,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782435,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782435,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782439,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782440,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782441,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782442,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782442,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782445,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782446,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782446,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782447,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782447,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782448,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782450,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782460,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782460,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782461,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782462,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782470,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782480,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782481,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782481,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 782482,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782485,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782486,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 782490,
     "City": "Karbi Anglong",
     "District": "Karbi Anglong",
     "State": "Assam"
    },
    {
     "Pin Code": 783101,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783120,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783121,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783122,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783123,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783123,
     "City": "East Garo Hills",
     "District": "East Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 783124,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783125,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783126,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783127,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783127,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783128,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783129,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783130,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783131,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783132,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783133,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783134,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783134,
     "City": "Goalpara",
     "District": "Goalpara",
     "State": "Assam"
    },
    {
     "Pin Code": 783134,
     "City": "East Garo Hills",
     "District": "East Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 783135,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783301,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783323,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783324,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783325,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783330,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783331,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783332,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783333,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783334,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783335,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783336,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783337,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783337,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783339,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783345,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783345,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783346,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783347,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783348,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783349,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783350,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783354,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783360,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783360,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783361,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783369,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783370,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783371,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783372,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783373,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783375,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783376,
     "City": "Kokrajhar",
     "District": "Kokrajhar",
     "State": "Assam"
    },
    {
     "Pin Code": 783380,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783381,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783382,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783383,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783383,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783384,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783385,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783386,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783388,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783388,
     "City": "Dhubri",
     "District": "Dhubri",
     "State": "Assam"
    },
    {
     "Pin Code": 783389,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783390,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783391,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783392,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783393,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 783394,
     "City": "Bongaigaon",
     "District": "Bongaigaon",
     "State": "Assam"
    },
    {
     "Pin Code": 784001,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784010,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784025,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784026,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784027,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784028,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784101,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784102,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784103,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784104,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784105,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784110,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784111,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784112,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784113,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784114,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784115,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784116,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784117,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784125,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784144,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784145,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784146,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784147,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784148,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784149,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784150,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784153,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784154,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784160,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784161,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784163,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784164,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784165,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784166,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784167,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784168,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784169,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784170,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784171,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784172,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784173,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784174,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784175,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784176,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784177,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784178,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784179,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784180,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784181,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784182,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784184,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784185,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784189,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784190,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784501,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784502,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784504,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784505,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784506,
     "City": "Sonitpur",
     "District": "Sonitpur",
     "State": "Assam"
    },
    {
     "Pin Code": 784507,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784508,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784509,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784510,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784513,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784514,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784521,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784522,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784523,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784524,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784525,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784526,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784527,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784528,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 784529,
     "City": "Darrang",
     "District": "Darrang",
     "State": "Assam"
    },
    {
     "Pin Code": 785000,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785001,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785004,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785005,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785006,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785006,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785007,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785008,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785009,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785010,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785013,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785014,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785015,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785101,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785102,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785104,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785104,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785105,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785106,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785107,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785107,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785108,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785110,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785111,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785112,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785601,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785601,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785602,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785602,
     "City": "Nagaon",
     "District": "Nagaon",
     "State": "Assam"
    },
    {
     "Pin Code": 785602,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785603,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785609,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785610,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785611,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785612,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785613,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785614,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785614,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785615,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785616,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785616,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785618,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785619,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785621,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785622,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785625,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785626,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785630,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785631,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785632,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785633,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785633,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785634,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785635,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785636,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785640,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785661,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785662,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785663,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785664,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785665,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785666,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785666,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785667,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785669,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785670,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785671,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785671,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785672,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785673,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785674,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785675,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 785676,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 785680,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785681,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785682,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785683,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785684,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785685,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785686,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785687,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785688,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785689,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785690,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785691,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785691,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785692,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785693,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785694,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785696,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785697,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785698,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785699,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785700,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785701,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 785702,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785703,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785703,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785704,
     "City": "Jorhat",
     "District": "Jorhat",
     "State": "Assam"
    },
    {
     "Pin Code": 785705,
     "City": "Golaghat",
     "District": "Golaghat",
     "State": "Assam"
    },
    {
     "Pin Code": 785705,
     "City": "Sibsagar",
     "District": "Sibsagar",
     "State": "Assam"
    },
    {
     "Pin Code": 786001,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786002,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786003,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786004,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786005,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786006,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786007,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786008,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786010,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786012,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786101,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786102,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786103,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786125,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786126,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786145,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786146,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786147,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786150,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786151,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786152,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786153,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786154,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786155,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786156,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786157,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786158,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786159,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786160,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786170,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786171,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786173,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786174,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786179,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786181,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786182,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786183,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786183,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786184,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786187,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786188,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786189,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786189,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786190,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786191,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786192,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786601,
     "City": "Tinsukia",
     "District": "Tinsukia",
     "State": "Assam"
    },
    {
     "Pin Code": 786602,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786610,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786611,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786612,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786613,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786614,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786621,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786622,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786623,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 786692,
     "City": "Dibrugarh",
     "District": "Dibrugarh",
     "State": "Assam"
    },
    {
     "Pin Code": 787001,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787023,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787026,
     "City": "Dhemaji",
     "District": "Dhemaji",
     "State": "Assam"
    },
    {
     "Pin Code": 787031,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787032,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787033,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787034,
     "City": "Dhemaji",
     "District": "Dhemaji",
     "State": "Assam"
    },
    {
     "Pin Code": 787034,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787035,
     "City": "Dhemaji",
     "District": "Dhemaji",
     "State": "Assam"
    },
    {
     "Pin Code": 787035,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787051,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787052,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787053,
     "City": "Dhemaji",
     "District": "Dhemaji",
     "State": "Assam"
    },
    {
     "Pin Code": 787053,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787054,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787055,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787056,
     "City": "Lakhimpur",
     "District": "Lakhimpur",
     "State": "Assam"
    },
    {
     "Pin Code": 787057,
     "City": "Dhemaji",
     "District": "Dhemaji",
     "State": "Assam"
    },
    {
     "Pin Code": 787058,
     "City": "Dhemaji",
     "District": "Dhemaji",
     "State": "Assam"
    },
    {
     "Pin Code": 787059,
     "City": "Dhemaji",
     "District": "Dhemaji",
     "State": "Assam"
    },
    {
     "Pin Code": 787060,
     "City": "Dhemaji",
     "District": "Dhemaji",
     "State": "Assam"
    },
    {
     "Pin Code": 787061,
     "City": "Dhemaji",
     "District": "Dhemaji",
     "State": "Assam"
    },
    {
     "Pin Code": 787110,
     "City": "Dhemaji",
     "District": "Dhemaji",
     "State": "Assam"
    },
    {
     "Pin Code": 788001,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788002,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788003,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788004,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788005,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788006,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788006,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788007,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788009,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788010,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788011,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788013,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788014,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788015,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788025,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788026,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788030,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788031,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788098,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788099,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788101,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788102,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788103,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788104,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788106,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788107,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788108,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788108,
     "City": "North Cachar Hills",
     "District": "North Cachar Hills",
     "State": "Assam"
    },
    {
     "Pin Code": 788109,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788110,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788111,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788112,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788113,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788113,
     "City": "North Cachar Hills",
     "District": "North Cachar Hills",
     "State": "Assam"
    },
    {
     "Pin Code": 788114,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788115,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788116,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788117,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788118,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788119,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788120,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788120,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788121,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788123,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788126,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788127,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788150,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788151,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788152,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788155,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788156,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788160,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788161,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788162,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788163,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788164,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788165,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788166,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788168,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788701,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788709,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788710,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788711,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788712,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788713,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788719,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788720,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788722,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788723,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788724,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788725,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788726,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788727,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788728,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788733,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788734,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788735,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788736,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788737,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788781,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788801,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788802,
     "City": "Hailakandi",
     "District": "Hailakandi",
     "State": "Assam"
    },
    {
     "Pin Code": 788803,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788804,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788805,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788806,
     "City": "Karimganj",
     "District": "Karimganj",
     "State": "Assam"
    },
    {
     "Pin Code": 788815,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788816,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788817,
     "City": "Cachar",
     "District": "Cachar",
     "State": "Assam"
    },
    {
     "Pin Code": 788818,
     "City": "North Cachar Hills",
     "District": "North Cachar Hills",
     "State": "Assam"
    },
    {
     "Pin Code": 788819,
     "City": "North Cachar Hills",
     "District": "North Cachar Hills",
     "State": "Assam"
    },
    {
     "Pin Code": 788820,
     "City": "North Cachar Hills",
     "District": "North Cachar Hills",
     "State": "Assam"
    },
    {
     "Pin Code": 788830,
     "City": "North Cachar Hills",
     "District": "North Cachar Hills",
     "State": "Assam"
    },
    {
     "Pin Code": 788831,
     "City": "North Cachar Hills",
     "District": "North Cachar Hills",
     "State": "Assam"
    },
    {
     "Pin Code": 788832,
     "City": "North Cachar Hills",
     "District": "North Cachar Hills",
     "State": "Assam"
    },
    {
     "Pin Code": 788931,
     "City": "North Cachar Hills",
     "District": "North Cachar Hills",
     "State": "Assam"
    },
    {
     "Pin Code": 790001,
     "City": "Papum Pare",
     "District": "Papum Pare",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 790001,
     "City": "West Kameng",
     "District": "West Kameng",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 790002,
     "City": "West Kameng",
     "District": "West Kameng",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 790003,
     "City": "West Kameng",
     "District": "West Kameng",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 790101,
     "City": "West Kameng",
     "District": "West Kameng",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 790102,
     "City": "East Kameng",
     "District": "East Kameng",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 790102,
     "City": "West Kameng",
     "District": "West Kameng",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 790103,
     "City": "East Kameng",
     "District": "East Kameng",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 790104,
     "City": "Tawang",
     "District": "Tawang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 790105,
     "City": "Tawang",
     "District": "Tawang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 790106,
     "City": "Tawang",
     "District": "Tawang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 790114,
     "City": "West Kameng",
     "District": "West Kameng",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 790116,
     "City": "West Kameng",
     "District": "West Kameng",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791001,
     "City": "Papum Pare",
     "District": "Papum Pare",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791001,
     "City": "West Siang",
     "District": "West Siang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791002,
     "City": "Upper Siang",
     "District": "Upper Siang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791003,
     "City": "West Siang",
     "District": "West Siang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791101,
     "City": "West Siang",
     "District": "West Siang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791102,
     "City": "East Siang",
     "District": "East Siang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791102,
     "City": "Upper Siang",
     "District": "Upper Siang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791103,
     "City": "East Siang",
     "District": "East Siang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791104,
     "City": "East Siang",
     "District": "East Siang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791105,
     "City": "Upper Siang",
     "District": "Upper Siang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791109,
     "City": "Papum Pare",
     "District": "Papum Pare",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791110,
     "City": "Papum Pare",
     "District": "Papum Pare",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791111,
     "City": "Papum Pare",
     "District": "Papum Pare",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791112,
     "City": "Papum Pare",
     "District": "Papum Pare",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791113,
     "City": "Lower Subansiri",
     "District": "Lower Subansiri",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791113,
     "City": "Papum Pare",
     "District": "Papum Pare",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791118,
     "City": "Kurung Kumey",
     "District": "Kurung Kumey",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791119,
     "City": "Lower Subansiri",
     "District": "Lower Subansiri",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791119,
     "City": "Papum Pare",
     "District": "Papum Pare",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791120,
     "City": "Lower Subansiri",
     "District": "Lower Subansiri",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791121,
     "City": "Papum Pare",
     "District": "Papum Pare",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791122,
     "City": "Upper Subansiri",
     "District": "Upper Subansiri",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791123,
     "City": "Papum Pare",
     "District": "Papum Pare",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 791125,
     "City": "West Siang",
     "District": "West Siang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792001,
     "City": "Lohit",
     "District": "Lohit",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792055,
     "City": "Changlang",
     "District": "Changlang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792056,
     "City": "Changlang",
     "District": "Changlang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792101,
     "City": "Dibang Valley",
     "District": "Dibang Valley",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792102,
     "City": "Lohit",
     "District": "Lohit",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792103,
     "City": "Changlang",
     "District": "Changlang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792103,
     "City": "Lohit",
     "District": "Lohit",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792104,
     "City": "Lohit",
     "District": "Lohit",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792104,
     "City": "Papum Pare",
     "District": "Papum Pare",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792105,
     "City": "Lohit",
     "District": "Lohit",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792110,
     "City": "Dibang Valley",
     "District": "Dibang Valley",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792110,
     "City": "Lower Dibang Valley",
     "District": "Lower Dibang Valley",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792111,
     "City": "Lohit",
     "District": "Lohit",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792120,
     "City": "Changlang",
     "District": "Changlang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792121,
     "City": "Changlang",
     "District": "Changlang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792122,
     "City": "Changlang",
     "District": "Changlang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792123,
     "City": "Changlang",
     "District": "Changlang",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792129,
     "City": "Tirap",
     "District": "Tirap",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792130,
     "City": "Tirap",
     "District": "Tirap",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 792131,
     "City": "Tirap",
     "District": "Tirap",
     "State": "Arunachal Pradesh"
    },
    {
     "Pin Code": 793001,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793002,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793003,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793004,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793005,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793006,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793007,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793008,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793009,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793010,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793011,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793012,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793013,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793014,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793015,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793016,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793017,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793018,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793019,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793021,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793022,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793101,
     "City": "Ri Bhoi",
     "District": "Ri Bhoi",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793102,
     "City": "Ri Bhoi",
     "District": "Ri Bhoi",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793103,
     "City": "Ri Bhoi",
     "District": "Ri Bhoi",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793104,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793104,
     "City": "Ri Bhoi",
     "District": "Ri Bhoi",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793105,
     "City": "Ri Bhoi",
     "District": "Ri Bhoi",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793106,
     "City": "East Garo Hills",
     "District": "East Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793106,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793106,
     "City": "West Khasi Hills",
     "District": "West Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793107,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793108,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793109,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793109,
     "City": "Jaintia Hills",
     "District": "Jaintia Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793110,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793111,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793112,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793113,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793114,
     "City": "South West Khasi Hills",
     "District": "South West Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793115,
     "City": "West Khasi Hills",
     "District": "West Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793119,
     "City": "West Khasi Hills",
     "District": "West Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793120,
     "City": "West Khasi Hills",
     "District": "West Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793121,
     "City": "East Khasi Hills",
     "District": "East Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793121,
     "City": "West Khasi Hills",
     "District": "West Khasi Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793122,
     "City": "Ri Bhoi",
     "District": "Ri Bhoi",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793123,
     "City": "Ri Bhoi",
     "District": "Ri Bhoi",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793150,
     "City": "Jaintia Hills",
     "District": "Jaintia Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793151,
     "City": "Jaintia Hills",
     "District": "Jaintia Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793160,
     "City": "Jaintia Hills",
     "District": "Jaintia Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793200,
     "City": "Jaintia Hills",
     "District": "Jaintia Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 793210,
     "City": "Jaintia Hills",
     "District": "Jaintia Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794001,
     "City": "West Garo Hills",
     "District": "West Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794002,
     "City": "North Garo Hills",
     "District": "North Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794005,
     "City": "West Garo Hills",
     "District": "West Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794101,
     "City": "West Garo Hills",
     "District": "West Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794102,
     "City": "South Garo Hills",
     "District": "South Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794103,
     "City": "West Garo Hills",
     "District": "West Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794104,
     "City": "West Garo Hills",
     "District": "West Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794105,
     "City": "West Garo Hills",
     "District": "West Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794106,
     "City": "West Garo Hills",
     "District": "West Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794107,
     "City": "East Garo Hills",
     "District": "East Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794107,
     "City": "West Garo Hills",
     "District": "West Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794108,
     "City": "East Garo Hills",
     "District": "East Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794108,
     "City": "West Garo Hills",
     "District": "West Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794109,
     "City": "West Garo Hills",
     "District": "West Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794110,
     "City": "East Garo Hills",
     "District": "East Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794111,
     "City": "East Garo Hills",
     "District": "East Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794112,
     "City": "West Garo Hills",
     "District": "West Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794114,
     "City": "South Garo Hills",
     "District": "South Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 794115,
     "City": "South West Garo Hills",
     "District": "South West Garo Hills",
     "State": "Meghalaya"
    },
    {
     "Pin Code": 795001,
     "City": "Imphal",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795002,
     "City": "Imphal",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795002,
     "City": "Imphal West",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795003,
     "City": "Imphal",
     "District": "Imphal East",
     "State": "Manipur"
    },
    {
     "Pin Code": 795003,
     "City": "Imphal",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795004,
     "City": "Imphal",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795005,
     "City": "Imphal",
     "District": "Imphal East",
     "State": "Manipur"
    },
    {
     "Pin Code": 795006,
     "City": "Churachandpur",
     "District": "Churachandpur",
     "State": "Manipur"
    },
    {
     "Pin Code": 795007,
     "City": "Senapati",
     "District": "Senapati",
     "State": "Manipur"
    },
    {
     "Pin Code": 795008,
     "City": "Imphal",
     "District": "Imphal East",
     "State": "Manipur"
    },
    {
     "Pin Code": 795009,
     "City": "Imphal",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795010,
     "City": "Imphal",
     "District": "Imphal East",
     "State": "Manipur"
    },
    {
     "Pin Code": 795015,
     "City": "Senapati",
     "District": "Senapati",
     "State": "Manipur"
    },
    {
     "Pin Code": 795101,
     "City": "Chandel",
     "District": "Chandel",
     "State": "Manipur"
    },
    {
     "Pin Code": 795101,
     "City": "Thoubal",
     "District": "Thoubal",
     "State": "Manipur"
    },
    {
     "Pin Code": 795102,
     "City": "Chandel",
     "District": "Chandel",
     "State": "Manipur"
    },
    {
     "Pin Code": 795103,
     "City": "Thoubal",
     "District": "Thoubal",
     "State": "Manipur"
    },
    {
     "Pin Code": 795104,
     "City": "Senapati",
     "District": "Senapati",
     "State": "Manipur"
    },
    {
     "Pin Code": 795106,
     "City": "Senapati",
     "District": "Senapati",
     "State": "Manipur"
    },
    {
     "Pin Code": 795107,
     "City": "Senapati",
     "District": "Senapati",
     "State": "Manipur"
    },
    {
     "Pin Code": 795112,
     "City": "Senapati",
     "District": "Senapati",
     "State": "Manipur"
    },
    {
     "Pin Code": 795113,
     "City": "Imphal",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795114,
     "City": "Imphal",
     "District": "Imphal East",
     "State": "Manipur"
    },
    {
     "Pin Code": 795115,
     "City": "Imphal",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795116,
     "City": "Imphal",
     "District": "Imphal East",
     "State": "Manipur"
    },
    {
     "Pin Code": 795116,
     "City": "Imphal",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795117,
     "City": "Churachandpur",
     "District": "Churachandpur",
     "State": "Manipur"
    },
    {
     "Pin Code": 795118,
     "City": "Bishnupur",
     "District": "Bishnupur",
     "State": "Manipur"
    },
    {
     "Pin Code": 795118,
     "City": "Churachandpur",
     "District": "Churachandpur",
     "State": "Manipur"
    },
    {
     "Pin Code": 795118,
     "City": "Senapati",
     "District": "Senapati",
     "State": "Manipur"
    },
    {
     "Pin Code": 795122,
     "City": "Senapati",
     "District": "Senapati",
     "State": "Manipur"
    },
    {
     "Pin Code": 795124,
     "City": "Bishnupur",
     "District": "Bishnupur",
     "State": "Manipur"
    },
    {
     "Pin Code": 795125,
     "City": "Senapati",
     "District": "Senapati",
     "State": "Manipur"
    },
    {
     "Pin Code": 795125,
     "City": "Tamenglong",
     "District": "Tamenglong",
     "State": "Manipur"
    },
    {
     "Pin Code": 795126,
     "City": "Bishnupur",
     "District": "Bishnupur",
     "State": "Manipur"
    },
    {
     "Pin Code": 795127,
     "City": "Chandel",
     "District": "Chandel",
     "State": "Manipur"
    },
    {
     "Pin Code": 795128,
     "City": "Churachandpur",
     "District": "Churachandpur",
     "State": "Manipur"
    },
    {
     "Pin Code": 795129,
     "City": "Senapati",
     "District": "Senapati",
     "State": "Manipur"
    },
    {
     "Pin Code": 795130,
     "City": "Thoubal",
     "District": "Thoubal",
     "State": "Manipur"
    },
    {
     "Pin Code": 795131,
     "City": "Chandel",
     "District": "Chandel",
     "State": "Manipur"
    },
    {
     "Pin Code": 795132,
     "City": "Imphal",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795132,
     "City": "Imphal",
     "District": "Thoubal",
     "State": "Manipur"
    },
    {
     "Pin Code": 795133,
     "City": "Bishnupur",
     "District": "Bishnupur",
     "State": "Manipur"
    },
    {
     "Pin Code": 795134,
     "City": "Bishnupur",
     "District": "Bishnupur",
     "State": "Manipur"
    },
    {
     "Pin Code": 795135,
     "City": "Chandel",
     "District": "Chandel",
     "State": "Manipur"
    },
    {
     "Pin Code": 795135,
     "City": "Thoubal",
     "District": "Thoubal",
     "State": "Manipur"
    },
    {
     "Pin Code": 795136,
     "City": "Imphal",
     "District": "Imphal East",
     "State": "Manipur"
    },
    {
     "Pin Code": 795136,
     "City": "Imphal",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795138,
     "City": "Thoubal",
     "District": "Thoubal",
     "State": "Manipur"
    },
    {
     "Pin Code": 795139,
     "City": "Churachandpur",
     "District": "Churachandpur",
     "State": "Manipur"
    },
    {
     "Pin Code": 795140,
     "City": "Imphal",
     "District": "Imphal East",
     "State": "Manipur"
    },
    {
     "Pin Code": 795140,
     "City": "Imphal",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795141,
     "City": "Tamenglong",
     "District": "Tamenglong",
     "State": "Manipur"
    },
    {
     "Pin Code": 795142,
     "City": "Ukhrul",
     "District": "Ukhrul",
     "State": "Manipur"
    },
    {
     "Pin Code": 795143,
     "City": "Churachandpur",
     "District": "Churachandpur",
     "State": "Manipur"
    },
    {
     "Pin Code": 795144,
     "City": "Ukhrul",
     "District": "Ukhrul",
     "State": "Manipur"
    },
    {
     "Pin Code": 795145,
     "City": "Ukhrul",
     "District": "Ukhrul",
     "State": "Manipur"
    },
    {
     "Pin Code": 795146,
     "City": "Imphal",
     "District": "Imphal West",
     "State": "Manipur"
    },
    {
     "Pin Code": 795147,
     "City": "Tamenglong",
     "District": "Tamenglong",
     "State": "Manipur"
    },
    {
     "Pin Code": 795148,
     "City": "Thoubal",
     "District": "Thoubal",
     "State": "Manipur"
    },
    {
     "Pin Code": 795149,
     "City": "Thoubal",
     "District": "Thoubal",
     "State": "Manipur"
    },
    {
     "Pin Code": 795150,
     "City": "Senapati",
     "District": "Senapati",
     "State": "Manipur"
    },
    {
     "Pin Code": 795159,
     "City": "Tamenglong",
     "District": "Tamenglong",
     "State": "Manipur"
    },
    {
     "Pin Code": 796001,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796004,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796005,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796007,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796008,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796009,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796012,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796014,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796017,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796070,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796070,
     "City": "Kolasib",
     "District": "Kolasib",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796081,
     "City": "Kolasib",
     "District": "Kolasib",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796082,
     "City": "Kolasib",
     "District": "Kolasib",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796091,
     "City": "Kolasib",
     "District": "Kolasib",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796101,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796101,
     "City": "Kolasib",
     "District": "Kolasib",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796111,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796161,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796181,
     "City": "Serchhip",
     "District": "Serchhip",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796184,
     "City": "Serchhip",
     "District": "Serchhip",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796186,
     "City": "Serchhip",
     "District": "Serchhip",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796190,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796261,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796290,
     "City": "Champhai",
     "District": "Champhai",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796310,
     "City": "Champhai",
     "District": "Champhai",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796320,
     "City": "Champhai",
     "District": "Champhai",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796321,
     "City": "Champhai",
     "District": "Champhai",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796370,
     "City": "Champhai",
     "District": "Champhai",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796370,
     "City": "Serchhip",
     "District": "Serchhip",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796410,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796410,
     "City": "Mammit",
     "District": "Mammit",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796421,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796421,
     "City": "Mammit",
     "District": "Mammit",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796431,
     "City": "Mammit",
     "District": "Mammit",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796441,
     "City": "Mammit",
     "District": "Mammit",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796471,
     "City": "Aizawl",
     "District": "Aizawl",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796471,
     "City": "Mammit",
     "District": "Mammit",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796501,
     "City": "Mammit",
     "District": "Mammit",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796571,
     "City": "Lunglei",
     "District": "Lunglei",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796581,
     "City": "Lunglei",
     "District": "Lunglei",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796691,
     "City": "Lunglei",
     "District": "Lunglei",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796701,
     "City": "Lunglei",
     "District": "Lunglei",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796710,
     "City": "Lunglei",
     "District": "Lunglei",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796730,
     "City": "Lunglei",
     "District": "Lunglei",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796751,
     "City": "Lunglei",
     "District": "Lunglei",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796770,
     "City": "Lawngtlai",
     "District": "Lawngtlai",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796770,
     "City": "Lunglei",
     "District": "Lunglei",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796891,
     "City": "Lawngtlai",
     "District": "Lawngtlai",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796901,
     "City": "Lawngtlai",
     "District": "Lawngtlai",
     "State": "Mizoram"
    },
    {
     "Pin Code": 796901,
     "City": "Saiha",
     "District": "Saiha",
     "State": "Mizoram"
    },
    {
     "Pin Code": 797001,
     "City": "Kohima",
     "District": "Kohima",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797002,
     "City": "Kohima",
     "District": "Kohima",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797003,
     "City": "Kohima",
     "District": "Kohima",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797004,
     "City": "Kohima",
     "District": "Kohima",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797005,
     "City": "Kohima",
     "District": "Kohima",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797006,
     "City": "Kohima",
     "District": "Kohima",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797099,
     "City": "Wokha",
     "District": "Wokha",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797100,
     "City": "Wokha",
     "District": "Wokha",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797101,
     "City": "Kohima",
     "District": "Kohima",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797101,
     "City": "Peren",
     "District": "Peren",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797102,
     "City": "Phek",
     "District": "Phek",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797103,
     "City": "Dimapur",
     "District": "Dimapur",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797104,
     "City": "Phek",
     "District": "Phek",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797105,
     "City": "Kohima",
     "District": "Kohima",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797106,
     "City": "Dimapur",
     "District": "Dimapur",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797107,
     "City": "Phek",
     "District": "Phek",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797108,
     "City": "Phek",
     "District": "Phek",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797109,
     "City": "Kohima",
     "District": "Kohima",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797109,
     "City": "Zunhebotto",
     "District": "Zunhebotto",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797110,
     "City": "Peren",
     "District": "Peren",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797111,
     "City": "Wokha",
     "District": "Wokha",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797112,
     "City": "Dimapur",
     "District": "Dimapur",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797113,
     "City": "Dimapur",
     "District": "Dimapur",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797114,
     "City": "Phek",
     "District": "Phek",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797115,
     "City": "Dimapur",
     "District": "Dimapur",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797116,
     "City": "Dimapur",
     "District": "Dimapur",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797117,
     "City": "Dimapur",
     "District": "Dimapur",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797120,
     "City": "Kohima",
     "District": "Kohima",
     "State": "Nagaland"
    },
    {
     "Pin Code": 797121,
     "City": "Kohima",
     "District": "Kohima",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798601,
     "City": "Mokokchung",
     "District": "Mokokchung",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798602,
     "City": "Mon",
     "District": "Mon",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798603,
     "City": "Mon",
     "District": "Mon",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798604,
     "City": "Mokokchung",
     "District": "Mokokchung",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798611,
     "City": "Kiphire",
     "District": "Kiphire",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798612,
     "City": "Tuensang",
     "District": "Tuensang",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798613,
     "City": "Mokokchung",
     "District": "Mokokchung",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798614,
     "City": "Mokokchung",
     "District": "Mokokchung",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798615,
     "City": "Mokokchung",
     "District": "Mokokchung",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798616,
     "City": "Tuensang",
     "District": "Tuensang",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798617,
     "City": "Mokokchung",
     "District": "Mokokchung",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798618,
     "City": "Mokokchung",
     "District": "Mokokchung",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798619,
     "City": "Zunhebotto",
     "District": "Zunhebotto",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798620,
     "City": "Zunhebotto",
     "District": "Zunhebotto",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798621,
     "City": "Mon",
     "District": "Mon",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798622,
     "City": "Mon",
     "District": "Mon",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798623,
     "City": "Mokokchung",
     "District": "Mokokchung",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798625,
     "City": "Longleng",
     "District": "Longleng",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798626,
     "City": "Tuensang",
     "District": "Tuensang",
     "State": "Nagaland"
    },
    {
     "Pin Code": 798627,
     "City": "Zunhebotto",
     "District": "Zunhebotto",
     "State": "Nagaland"
    },
    {
     "Pin Code": 799001,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799002,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799003,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799004,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799005,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799006,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799007,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799008,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799009,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799010,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799012,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799013,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799014,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799015,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799015,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799022,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799035,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799045,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799045,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799046,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799101,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799102,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799103,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799103,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799104,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799105,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799105,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799114,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799115,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799115,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799116,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799120,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799125,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799130,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799131,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799141,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799142,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799143,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799144,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799145,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799150,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799153,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799153,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799155,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799156,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799157,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799181,
     "City": "South Tripura",
     "District": "South Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799201,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799202,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799203,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799204,
     "City": "Dhalai",
     "District": "Dhalai",
     "State": "Tripura"
    },
    {
     "Pin Code": 799205,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799207,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799210,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799211,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799212,
     "City": "West Tripura",
     "District": "West Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799250,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799251,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799253,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799254,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799256,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799260,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799261,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799262,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799263,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799264,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799266,
     "City": "Dhalai",
     "District": "Dhalai",
     "State": "Tripura"
    },
    {
     "Pin Code": 799266,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799269,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799270,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799271,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799273,
     "City": "Dhalai",
     "District": "Dhalai",
     "State": "Tripura"
    },
    {
     "Pin Code": 799273,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799275,
     "City": "Dhalai",
     "District": "Dhalai",
     "State": "Tripura"
    },
    {
     "Pin Code": 799277,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799278,
     "City": "Dhalai",
     "District": "Dhalai",
     "State": "Tripura"
    },
    {
     "Pin Code": 799279,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799280,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799281,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799284,
     "City": "Dhalai",
     "District": "Dhalai",
     "State": "Tripura"
    },
    {
     "Pin Code": 799285,
     "City": "Dhalai",
     "District": "Dhalai",
     "State": "Tripura"
    },
    {
     "Pin Code": 799286,
     "City": "Dhalai",
     "District": "Dhalai",
     "State": "Tripura"
    },
    {
     "Pin Code": 799287,
     "City": "Dhalai",
     "District": "Dhalai",
     "State": "Tripura"
    },
    {
     "Pin Code": 799288,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 799289,
     "City": "Dhalai",
     "District": "Dhalai",
     "State": "Tripura"
    },
    {
     "Pin Code": 799290,
     "City": "North Tripura",
     "District": "North Tripura",
     "State": "Tripura"
    },
    {
     "Pin Code": 800001,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800001,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800002,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800002,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800003,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800003,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800004,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800004,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800005,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800005,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800006,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800006,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800007,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800007,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800008,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800008,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800009,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800009,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800010,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800010,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800011,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800011,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800012,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800012,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800013,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800013,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800014,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800014,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800015,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800015,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800016,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800016,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800017,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800017,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800018,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800018,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800019,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800019,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800020,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800020,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800021,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800021,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800022,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800022,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800023,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800023,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800024,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800024,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800025,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800025,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800026,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800026,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800027,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800027,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800028,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800028,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800029,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800029,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 800111,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 800111,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801101,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801101,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801102,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801102,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801103,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801103,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801104,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801104,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801105,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801105,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801108,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801108,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801109,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801109,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801110,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801110,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801111,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801111,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801112,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801112,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801113,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801113,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801117,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801117,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801301,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 801301,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801302,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 801302,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801303,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 801303,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801304,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 801304,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801304,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801304,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801305,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 801305,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801306,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 801306,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801307,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 801307,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801501,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801501,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801502,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801502,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801503,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801503,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801505,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801505,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801506,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801506,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 801507,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 801507,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802101,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802101,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802102,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802102,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802103,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802103,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802111,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802111,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802112,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802112,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802113,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802113,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802114,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802114,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802115,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802115,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802116,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802116,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802117,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802117,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802118,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802118,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802119,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802119,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802120,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802120,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802122,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802122,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802123,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802123,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802125,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802125,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802126,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802126,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802127,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802127,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802128,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802128,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802129,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802129,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802130,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802130,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802131,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802131,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802132,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Bihar"
    },
    {
     "Pin Code": 802132,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802133,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802133,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802134,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802134,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802135,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802135,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802136,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802136,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802151,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802151,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802152,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802152,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802154,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802154,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802155,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802155,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802156,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802156,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802157,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802157,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802158,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802158,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802159,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802159,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802160,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802160,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802161,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802161,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802162,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802162,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802163,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802163,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802164,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802164,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802165,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802165,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802166,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802166,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802183,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802183,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802201,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802201,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802202,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802202,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802203,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802203,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802204,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802204,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802205,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802205,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802206,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802206,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802207,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802207,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802208,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802208,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802209,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802209,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802210,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802210,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802211,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 802211,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802212,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 802212,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802213,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Bihar"
    },
    {
     "Pin Code": 802213,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 802213,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802213,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802214,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 802214,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802215,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 802215,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802216,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 802216,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802217,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 802217,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802218,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Bihar"
    },
    {
     "Pin Code": 802218,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 802218,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802218,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802219,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 802219,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802220,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 802220,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802221,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 802221,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802222,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802222,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802223,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802223,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802226,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 802226,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802301,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802301,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802302,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802302,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802311,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802311,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802312,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802312,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802313,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802313,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Bihar"
    },
    {
     "Pin Code": 802313,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802313,
     "City": "Buxar",
     "District": "Buxar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802314,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802314,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802316,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802316,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802318,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802318,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802351,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802351,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 802352,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 802352,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803101,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803101,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803107,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803107,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803108,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803108,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803109,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803109,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 803109,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803109,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803110,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803110,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803110,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803110,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803111,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803111,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803113,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803113,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803114,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803114,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803115,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803115,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803116,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803116,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803117,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803117,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803118,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803118,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803119,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803119,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803120,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803120,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803121,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803121,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803130,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803130,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803201,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803201,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803202,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803202,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803203,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803203,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803211,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803211,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803212,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803212,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803213,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803213,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803213,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803213,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803214,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803214,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803215,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803215,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803216,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 803216,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803221,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803221,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803301,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803301,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803302,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803302,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803303,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803303,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803306,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803306,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 803307,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 803307,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804401,
     "City": "Arwal",
     "District": "Arwal",
     "State": "Bihar"
    },
    {
     "Pin Code": 804401,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 804401,
     "City": "Arwal",
     "District": "Arwal",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804401,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804402,
     "City": "Arwal",
     "District": "Arwal",
     "State": "Bihar"
    },
    {
     "Pin Code": 804402,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 804402,
     "City": "Arwal",
     "District": "Arwal",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804402,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804403,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 804403,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804404,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 804404,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804405,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804405,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804406,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804406,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 804406,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804406,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804407,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804407,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804408,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804408,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804417,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804417,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804418,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804418,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804419,
     "City": "Arwal",
     "District": "Arwal",
     "State": "Bihar"
    },
    {
     "Pin Code": 804419,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 804419,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804419,
     "City": "Arwal",
     "District": "Arwal",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804419,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804419,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804420,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804420,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804421,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 804421,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804421,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804421,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804422,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804422,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804423,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 804423,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804423,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804423,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804424,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 804424,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804424,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804424,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804425,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804425,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804426,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 804426,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804426,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 804426,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804426,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804426,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804427,
     "City": "Arwal",
     "District": "Arwal",
     "State": "Bihar"
    },
    {
     "Pin Code": 804427,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804427,
     "City": "Arwal",
     "District": "Arwal",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804427,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804428,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 804428,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804428,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804428,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804429,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804429,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804430,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 804430,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804432,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804432,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804434,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 804434,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804435,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 804435,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804451,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 804451,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804452,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 804452,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804453,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 804453,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 804454,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 804454,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805101,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805101,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805102,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805102,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805103,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805103,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805104,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805104,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805105,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 805105,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805106,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805106,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805107,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805107,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805108,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805108,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805109,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 805109,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805109,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805109,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805110,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805110,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805111,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805111,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805112,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805112,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805121,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805121,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805122,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805122,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805123,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805123,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805124,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805124,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805125,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805125,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805126,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805126,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805127,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805127,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805128,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 805128,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805128,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805128,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805129,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 805129,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805129,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805129,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805130,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 805130,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805130,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805130,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805131,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 805131,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805132,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805132,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805133,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805133,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805135,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 805135,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805141,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Bihar"
    },
    {
     "Pin Code": 805141,
     "City": "Nawada",
     "District": "Nawada",
     "State": "Foreign country"
    },
    {
     "Pin Code": 805236,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 805236,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811101,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 811101,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Bihar"
    },
    {
     "Pin Code": 811101,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811101,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811102,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 811102,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Bihar"
    },
    {
     "Pin Code": 811102,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811102,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811103,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 811103,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Bihar"
    },
    {
     "Pin Code": 811103,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811103,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811104,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Bihar"
    },
    {
     "Pin Code": 811104,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 811104,
     "City": "Nalanda",
     "District": "Nalanda",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811104,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811105,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Bihar"
    },
    {
     "Pin Code": 811105,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811106,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 811106,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 811106,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811106,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811107,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 811107,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Bihar"
    },
    {
     "Pin Code": 811107,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811107,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811112,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 811112,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811201,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 811201,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 811201,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Bihar"
    },
    {
     "Pin Code": 811201,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811201,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811201,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811202,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 811202,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 811202,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811202,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811211,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 811211,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 811211,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811211,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811212,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 811212,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811213,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 811213,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811214,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 811214,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811301,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Bihar"
    },
    {
     "Pin Code": 811301,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811302,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 811302,
     "City": "Patna",
     "District": "Patna",
     "State": "Bihar"
    },
    {
     "Pin Code": 811302,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811302,
     "City": "Patna",
     "District": "Patna",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811303,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Bihar"
    },
    {
     "Pin Code": 811303,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811304,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 811304,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Bihar"
    },
    {
     "Pin Code": 811304,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811304,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811305,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Bihar"
    },
    {
     "Pin Code": 811305,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811307,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Bihar"
    },
    {
     "Pin Code": 811307,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811308,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 811308,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Bihar"
    },
    {
     "Pin Code": 811308,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811308,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811309,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 811309,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811310,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 811310,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811311,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Bihar"
    },
    {
     "Pin Code": 811311,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 811311,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 811311,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811311,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811311,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811312,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Bihar"
    },
    {
     "Pin Code": 811312,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811313,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Bihar"
    },
    {
     "Pin Code": 811313,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811314,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Bihar"
    },
    {
     "Pin Code": 811314,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811315,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Bihar"
    },
    {
     "Pin Code": 811315,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 811315,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Bihar"
    },
    {
     "Pin Code": 811315,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811315,
     "City": "Lakhisarai",
     "District": "Lakhisarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811315,
     "City": "Sheikhpura",
     "District": "Sheikhpura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811316,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Bihar"
    },
    {
     "Pin Code": 811316,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Foreign country"
    },
    {
     "Pin Code": 811317,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Bihar"
    },
    {
     "Pin Code": 811317,
     "City": "Jamui",
     "District": "Jamui",
     "State": "Foreign country"
    },
    {
     "Pin Code": 812001,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 812001,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 812002,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 812002,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 812003,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 812003,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 812004,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 812004,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 812005,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 812005,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 812006,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 812006,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 812007,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 812007,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813101,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813101,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813101,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813101,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813102,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813102,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813103,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813103,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 813103,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813103,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813104,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813104,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813105,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813105,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813105,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813105,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813106,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813106,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813107,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813107,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813108,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813108,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813108,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813108,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813109,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813109,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813110,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813110,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 813110,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813110,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813113,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813113,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813118,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813118,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813201,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813201,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 813201,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813201,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813202,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813202,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813203,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813203,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813204,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813204,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 813204,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813204,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813205,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813205,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813205,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813205,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813206,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813206,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813207,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813207,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 813207,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813207,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813208,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 813208,
     "City": "Sahibganj",
     "District": "Sahibganj",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 813209,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813209,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813210,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813210,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813211,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813211,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 813211,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813211,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813212,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813212,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813212,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 813212,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813212,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813212,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813213,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813213,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813214,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813214,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813221,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 813221,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813221,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 813221,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813221,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813221,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813222,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813222,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 813225,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 813225,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 814101,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814102,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814102,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814103,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814110,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814111,
     "City": "Pakur",
     "District": "Pakur",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814112,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814113,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814114,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814115,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814116,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814118,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814119,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814120,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814120,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814122,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814131,
     "City": "Banka",
     "District": "Banka",
     "State": "Bihar"
    },
    {
     "Pin Code": 814131,
     "City": "Banka",
     "District": "Banka",
     "State": "Foreign country"
    },
    {
     "Pin Code": 814133,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814133,
     "City": "Pakur",
     "District": "Pakur",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814141,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814142,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814143,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814144,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814145,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814146,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814146,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814147,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814148,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814149,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814150,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814150,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814151,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814151,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814152,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814153,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814153,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814154,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814155,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814155,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814156,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814157,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814158,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814160,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814162,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814165,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814166,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814166,
     "City": "Jamtara",
     "District": "Jamtara",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 814167,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815301,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815302,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815311,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815312,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815313,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815314,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815315,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815316,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815317,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815318,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815351,
     "City": "Jamtara",
     "District": "Jamtara",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815352,
     "City": "Jamtara",
     "District": "Jamtara",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815353,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815354,
     "City": "Jamtara",
     "District": "Jamtara",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815355,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815355,
     "City": "Jamtara",
     "District": "Jamtara",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815357,
     "City": "Deoghar",
     "District": "Deoghar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 815359,
     "City": "Jamtara",
     "District": "Jamtara",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816101,
     "City": "Sahibganj",
     "District": "Sahibganj",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816102,
     "City": "Godda",
     "District": "Godda",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816102,
     "City": "Pakur",
     "District": "Pakur",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816102,
     "City": "Sahibganj",
     "District": "Sahibganj",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816103,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816103,
     "City": "Pakur",
     "District": "Pakur",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816104,
     "City": "Pakur",
     "District": "Pakur",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816105,
     "City": "Pakur",
     "District": "Pakur",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816105,
     "City": "Sahibganj",
     "District": "Sahibganj",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816106,
     "City": "Pakur",
     "District": "Pakur",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816107,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816107,
     "City": "Pakur",
     "District": "Pakur",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816108,
     "City": "Sahibganj",
     "District": "Sahibganj",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816109,
     "City": "Sahibganj",
     "District": "Sahibganj",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816110,
     "City": "Sahibganj",
     "District": "Sahibganj",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816115,
     "City": "Sahibganj",
     "District": "Sahibganj",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816116,
     "City": "Sahibganj",
     "District": "Sahibganj",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816117,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816117,
     "City": "Pakur",
     "District": "Pakur",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816118,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816119,
     "City": "Dumka",
     "District": "Dumka",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816120,
     "City": "Sahibganj",
     "District": "Sahibganj",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 816129,
     "City": "Sahibganj",
     "District": "Sahibganj",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 821101,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Bihar"
    },
    {
     "Pin Code": 821101,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821102,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Bihar"
    },
    {
     "Pin Code": 821102,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821103,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Bihar"
    },
    {
     "Pin Code": 821103,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821104,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Bihar"
    },
    {
     "Pin Code": 821104,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821104,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821104,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821105,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Bihar"
    },
    {
     "Pin Code": 821105,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821106,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Bihar"
    },
    {
     "Pin Code": 821106,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821107,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821107,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821108,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Bihar"
    },
    {
     "Pin Code": 821108,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821108,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821108,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821109,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Bihar"
    },
    {
     "Pin Code": 821109,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821110,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Bihar"
    },
    {
     "Pin Code": 821110,
     "City": "Kaimur (Bhabua)",
     "District": "Kaimur (Bhabua)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821111,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821111,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821112,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821112,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821113,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821113,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821115,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821115,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821116,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821116,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821301,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821301,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821302,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821302,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821303,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821303,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821304,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821304,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821305,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821305,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821306,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821306,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821307,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821307,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821308,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821308,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821309,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821309,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821310,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821310,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821311,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821311,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 821312,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Bihar"
    },
    {
     "Pin Code": 821312,
     "City": "Rohtas",
     "District": "Rohtas",
     "State": "Foreign country"
    },
    {
     "Pin Code": 822101,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822102,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822110,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822111,
     "City": "Latehar",
     "District": "Latehar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822112,
     "City": "Garhwa",
     "District": "Garhwa",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822112,
     "City": "Latehar",
     "District": "Latehar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822113,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822114,
     "City": "Garhwa",
     "District": "Garhwa",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822115,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822116,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822117,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822118,
     "City": "Garhwa",
     "District": "Garhwa",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822118,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822119,
     "City": "Latehar",
     "District": "Latehar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822120,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822121,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822122,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822123,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822124,
     "City": "Garhwa",
     "District": "Garhwa",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822125,
     "City": "Garhwa",
     "District": "Garhwa",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822126,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822128,
     "City": "Garhwa",
     "District": "Garhwa",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822128,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822129,
     "City": "Garhwa",
     "District": "Garhwa",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822131,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822132,
     "City": "Garhwa",
     "District": "Garhwa",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822132,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822133,
     "City": "Garhwa",
     "District": "Garhwa",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 822134,
     "City": "Garhwa",
     "District": "Garhwa",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 823001,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 823001,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 823002,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 823002,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 823003,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 823003,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 823004,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 823004,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 823005,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 823005,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 823006,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 823006,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 823007,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 823007,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 823009,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 823009,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 823010,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 823010,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 823011,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 823011,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 823013,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 823013,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 823311,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 823311,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824101,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824101,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824102,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824102,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824103,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824103,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824111,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824111,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824112,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824112,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824113,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824113,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824113,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824113,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824114,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824114,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824115,
     "City": "Arwal",
     "District": "Arwal",
     "State": "Bihar"
    },
    {
     "Pin Code": 824115,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824115,
     "City": "Arwal",
     "District": "Arwal",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824115,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824116,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824116,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824116,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824116,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824118,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824118,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 824118,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824118,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824120,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824120,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824121,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824121,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824122,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824122,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824123,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824123,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824124,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824124,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824125,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824125,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824127,
     "City": "Arwal",
     "District": "Arwal",
     "State": "Bihar"
    },
    {
     "Pin Code": 824127,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824127,
     "City": "Arwal",
     "District": "Arwal",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824127,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824129,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824129,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824130,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824130,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824143,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824143,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824201,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824201,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824202,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824202,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824203,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824203,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824205,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824205,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824206,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824206,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824207,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824207,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824207,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824207,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824208,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824208,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824209,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824209,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824210,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824210,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824211,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824211,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824216,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824216,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824217,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824217,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824219,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824219,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824219,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824219,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824220,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824220,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824221,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824221,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824231,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824231,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824232,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824232,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 824232,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824232,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824233,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824233,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824234,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824234,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824235,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824235,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Bihar"
    },
    {
     "Pin Code": 824235,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824235,
     "City": "Jehanabad",
     "District": "Jehanabad",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824236,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824236,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824237,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Bihar"
    },
    {
     "Pin Code": 824237,
     "City": "Gaya",
     "District": "Gaya",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824301,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824301,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824302,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824302,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824303,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824303,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 824331,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Bihar"
    },
    {
     "Pin Code": 824331,
     "City": "Aurangabad(BH)",
     "District": "Aurangabad(BH)",
     "State": "Foreign country"
    },
    {
     "Pin Code": 825101,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825102,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825103,
     "City": "Chatra",
     "District": "Chatra",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825106,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825108,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825109,
     "City": "Koderma",
     "District": "Koderma",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825132,
     "City": "Koderma",
     "District": "Koderma",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825167,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825301,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825302,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825303,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825311,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825312,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825313,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825314,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825315,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825315,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825316,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825317,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825318,
     "City": "Koderma",
     "District": "Koderma",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825319,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825320,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825321,
     "City": "Chatra",
     "District": "Chatra",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825322,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825322,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825323,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825323,
     "City": "Koderma",
     "District": "Koderma",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825324,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825325,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825326,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825329,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825330,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825336,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825401,
     "City": "Chatra",
     "District": "Chatra",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825401,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825402,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825403,
     "City": "Chatra",
     "District": "Chatra",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825404,
     "City": "Chatra",
     "District": "Chatra",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825405,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825406,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825407,
     "City": "Koderma",
     "District": "Koderma",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825408,
     "City": "Chatra",
     "District": "Chatra",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825409,
     "City": "Koderma",
     "District": "Koderma",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825410,
     "City": "Koderma",
     "District": "Koderma",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825411,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825412,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825413,
     "City": "Koderma",
     "District": "Koderma",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825418,
     "City": "Giridh",
     "District": "Giridh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825418,
     "City": "Koderma",
     "District": "Koderma",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 825421,
     "City": "Koderma",
     "District": "Koderma",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 826001,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 826003,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 826004,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 826005,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 827001,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 827003,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 827004,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 827006,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 827009,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 827010,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 827011,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 827012,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 827013,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 827014,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 827302,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 827302,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828101,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828103,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828104,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828105,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828106,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828107,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828108,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828109,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828110,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828111,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828112,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828113,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828114,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828115,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828116,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828117,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828119,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828120,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828121,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828122,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828123,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828124,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828125,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828125,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828126,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828127,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828128,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828129,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828130,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828131,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828132,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828133,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828134,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828135,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828142,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828201,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828202,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828203,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828204,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828205,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828206,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828207,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828301,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828302,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828302,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828303,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828303,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828304,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828305,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828306,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828307,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828307,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828308,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828309,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828401,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828402,
     "City": "Dhanbad",
     "District": "Dhanbad",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828403,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 828404,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829101,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829101,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829102,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829103,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829104,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829105,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829106,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829107,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829108,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829109,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829110,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829111,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829112,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829113,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829114,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829116,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829117,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829118,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829119,
     "City": "Hazaribag",
     "District": "Hazaribag",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829119,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829121,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829122,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829123,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829125,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829126,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829127,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829128,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829129,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829130,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829131,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829132,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829133,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829134,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829135,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829143,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829144,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829149,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829150,
     "City": "Ramgarh",
     "District": "Ramgarh",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829201,
     "City": "Chatra",
     "District": "Chatra",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829201,
     "City": "Garhwa",
     "District": "Garhwa",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829202,
     "City": "Latehar",
     "District": "Latehar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829202,
     "City": "Palamau",
     "District": "Palamau",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829203,
     "City": "Latehar",
     "District": "Latehar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829204,
     "City": "Latehar",
     "District": "Latehar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829205,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829206,
     "City": "Latehar",
     "District": "Latehar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829207,
     "City": "Latehar",
     "District": "Latehar",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829208,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829209,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829210,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 829301,
     "City": "Bokaro",
     "District": "Bokaro",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831001,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831002,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831002,
     "City": "Jamshedpur",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831003,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831004,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831005,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831006,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831007,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831009,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831010,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831011,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831012,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831012,
     "City": "Jamshedpur",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831013,
     "City": "Jamshedpur",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831014,
     "City": "Jamshedpur",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831015,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831016,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831017,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831018,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831019,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 831020,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832101,
     "City": "East Singhbhum",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832101,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832102,
     "City": "East Singhbhum",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832103,
     "City": "East Singhbhum",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832104,
     "City": "East Singhbhum",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832105,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832106,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832107,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832107,
     "City": "Jamshedpur",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832108,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832109,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832110,
     "City": "East Singhbhum",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832111,
     "City": "East Singhbhum",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832119,
     "City": "Seraikela",
     "District": "Seraikela",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832301,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832302,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832303,
     "City": "East Singhbhum",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832304,
     "City": "Jamshedpur",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832401,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832402,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832403,
     "City": "Jamshedpur",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832403,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 832404,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833101,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833101,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833102,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833102,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833103,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833104,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833105,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833105,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833106,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833201,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833202,
     "City": "East Singhbhum",
     "District": "East Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833202,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833203,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833204,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833204,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833212,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833213,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833214,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833215,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833216,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833217,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833218,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833219,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833220,
     "City": "Seraikela-kharsawan",
     "District": "Seraikela-kharsawan",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833221,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833222,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 833223,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834001,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834002,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834003,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834004,
     "City": "Ranchi",
     "District": "Lohardaga",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834004,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834005,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834006,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834008,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834009,
     "City": "Ranchi",
     "District": "Lohardaga",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834009,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834010,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834011,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 834012,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835101,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835102,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835103,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835201,
     "City": "Simdega",
     "District": "Simdega",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835202,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835203,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835204,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835205,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835206,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835207,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835208,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835209,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835210,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835211,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835211,
     "City": "Simdega",
     "District": "Simdega",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835212,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835212,
     "City": "Simdega",
     "District": "Simdega",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835213,
     "City": "Lohardaga",
     "District": "Lohardaga",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835214,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835214,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835215,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835216,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835216,
     "City": "West Singhbhum",
     "District": "West Singhbhum",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835217,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835218,
     "City": "Lohardaga",
     "District": "Lohardaga",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835219,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835220,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835221,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835222,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835223,
     "City": "Simdega",
     "District": "Simdega",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835224,
     "City": "Simdega",
     "District": "Simdega",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835225,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835226,
     "City": "Simdega",
     "District": "Simdega",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835227,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835227,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835228,
     "City": "Simdega",
     "District": "Simdega",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835229,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835230,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835231,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835232,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835233,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835234,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835235,
     "City": "Khunti",
     "District": "Khunti",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835235,
     "City": "Simdega",
     "District": "Simdega",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835301,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835302,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835302,
     "City": "Khunti",
     "District": "Khunti",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835302,
     "City": "Lohardaga",
     "District": "Lohardaga",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835303,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835324,
     "City": "Gumla",
     "District": "Gumla",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835325,
     "City": "Lohardaga",
     "District": "Lohardaga",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835325,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 835941,
     "City": "Ranchi",
     "District": "Ranchi",
     "State": "Jharkhand"
    },
    {
     "Pin Code": 841101,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841101,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841201,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841201,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841202,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841202,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841203,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841203,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841204,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841204,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841205,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841205,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841206,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841206,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841207,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841207,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841208,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841208,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841209,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841209,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841210,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841210,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841211,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841211,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841212,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841212,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841213,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841213,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841214,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841214,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841215,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841215,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841216,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841216,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841217,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841217,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841218,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841218,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841219,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841219,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841220,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841220,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841221,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841221,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841222,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841222,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841223,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841223,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841223,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841223,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841224,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841224,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841225,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841225,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841226,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841226,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841227,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841227,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841231,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841231,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841232,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841232,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841233,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841233,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841234,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841234,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841235,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841235,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841236,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841236,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841237,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841237,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841238,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841238,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841239,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841239,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841240,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841240,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841241,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841241,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841242,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841242,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841243,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841243,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841244,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841244,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841245,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841245,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841286,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841286,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841287,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841287,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841301,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841301,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841302,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841302,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841305,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841305,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841311,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841311,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841312,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 841312,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841312,
     "City": "Bhojpur",
     "District": "Bhojpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841312,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841313,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841313,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841316,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841316,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841401,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841401,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841402,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841402,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841403,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841403,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841404,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841404,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841405,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841405,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841406,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841406,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841407,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841407,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841408,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841408,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841409,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841409,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841410,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841410,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841411,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841411,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841412,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841412,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841413,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841413,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841414,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841414,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841415,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841415,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841416,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841416,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841417,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841417,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841417,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841417,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841418,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841418,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841419,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841419,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841420,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841420,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841421,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841421,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841422,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841422,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841422,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841422,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841423,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841423,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841424,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841424,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841425,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841425,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841426,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841426,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841427,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841427,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841428,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841428,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841433,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841433,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841434,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841434,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841435,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841435,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841436,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841436,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841437,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841437,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841438,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841438,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841439,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841439,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841440,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841440,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841441,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841441,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841442,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841442,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841443,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841443,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841446,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841446,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841460,
     "City": "Saran",
     "District": "Saran",
     "State": "Bihar"
    },
    {
     "Pin Code": 841460,
     "City": "Saran",
     "District": "Saran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841501,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841501,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841502,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841502,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841503,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841503,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841504,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841504,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841505,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841505,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841506,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841506,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841507,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841507,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841508,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 841508,
     "City": "Gopalganj",
     "District": "Gopalganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 841509,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Bihar"
    },
    {
     "Pin Code": 841509,
     "City": "Siwan",
     "District": "Siwan",
     "State": "Foreign country"
    },
    {
     "Pin Code": 842001,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 842001,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 842002,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 842002,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 842003,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 842003,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 842004,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 842004,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 842005,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 842005,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843007,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843007,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843101,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843101,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843102,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843102,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 843102,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843102,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843103,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843103,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843104,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843104,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843105,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843105,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843106,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843106,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843107,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843107,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843108,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843108,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843109,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843109,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843110,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 843110,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843111,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843111,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843112,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843112,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843113,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843113,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843114,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 843114,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843115,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843115,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843117,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843117,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843118,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843118,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843119,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843119,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843120,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843120,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843121,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843121,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843122,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843122,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843123,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843123,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843124,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843124,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843125,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843125,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843126,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843126,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843127,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843127,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843128,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843128,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843129,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843129,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843130,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843130,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843132,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843132,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843141,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843141,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843143,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843143,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843146,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843146,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843147,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843147,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843153,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843153,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843161,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843161,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843165,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843165,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843215,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843215,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843301,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843301,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843302,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843302,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843311,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843311,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843312,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843312,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843313,
     "City": "Sheohar",
     "District": "Sheohar",
     "State": "Bihar"
    },
    {
     "Pin Code": 843313,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843313,
     "City": "Sheohar",
     "District": "Sheohar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843313,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843314,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843314,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843315,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843315,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843316,
     "City": "Sheohar",
     "District": "Sheohar",
     "State": "Bihar"
    },
    {
     "Pin Code": 843316,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843316,
     "City": "Sheohar",
     "District": "Sheohar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843316,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843317,
     "City": "Sheohar",
     "District": "Sheohar",
     "State": "Bihar"
    },
    {
     "Pin Code": 843317,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843317,
     "City": "Sheohar",
     "District": "Sheohar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843317,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843318,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843318,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843319,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843319,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843320,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843320,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843321,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 843321,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843322,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843322,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843323,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843323,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843324,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843324,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843325,
     "City": "Sheohar",
     "District": "Sheohar",
     "State": "Bihar"
    },
    {
     "Pin Code": 843325,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843325,
     "City": "Sheohar",
     "District": "Sheohar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843325,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843326,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843326,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843327,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843327,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843328,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843328,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843329,
     "City": "Sheohar",
     "District": "Sheohar",
     "State": "Bihar"
    },
    {
     "Pin Code": 843329,
     "City": "Sheohar",
     "District": "Sheohar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843330,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843330,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843331,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843331,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843332,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843332,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843333,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843333,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843334,
     "City": "Sheohar",
     "District": "Sheohar",
     "State": "Bihar"
    },
    {
     "Pin Code": 843334,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843334,
     "City": "Sheohar",
     "District": "Sheohar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843334,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843351,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843351,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843360,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843360,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 843382,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 843382,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844101,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844101,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844102,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844102,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844103,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844103,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844111,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 844111,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844111,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844111,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844112,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 844112,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844112,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844112,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844113,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844113,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844114,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844114,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844115,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844115,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844116,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844116,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844117,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844117,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844118,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 844118,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844118,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844118,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844119,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844119,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844120,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 844120,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844120,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844120,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844121,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844121,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844122,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844122,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844123,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844123,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844124,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844124,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844125,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844125,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844126,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844126,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844127,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 844127,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844128,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844128,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844501,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 844501,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844501,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844501,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844502,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844502,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844503,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844503,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844504,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844504,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844505,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844505,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844506,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 844506,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844506,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844506,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844507,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844507,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844508,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844508,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 844509,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Bihar"
    },
    {
     "Pin Code": 844509,
     "City": "Vaishali",
     "District": "Vaishali",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845101,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845101,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845102,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845102,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845103,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845103,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845104,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845104,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845105,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845105,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845106,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845106,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845107,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845107,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845301,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845301,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845302,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845302,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845303,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845303,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845304,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845304,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845305,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845305,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845306,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845306,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845307,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845307,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845315,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845315,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845401,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845401,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845404,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845404,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845406,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845406,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845411,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845411,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845412,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845412,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845413,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845413,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845414,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845414,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845415,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845415,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845416,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845416,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845417,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845417,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845418,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845418,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845419,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845419,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845420,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845420,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845422,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845422,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845423,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845423,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845424,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845424,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845425,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845425,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845426,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845426,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845427,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845427,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845428,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845428,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845429,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845429,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845430,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845430,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845431,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845431,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845432,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845432,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845433,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845433,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845434,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845434,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845435,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845435,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845436,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845436,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845437,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845437,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845438,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845438,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845440,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845440,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845449,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845449,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845450,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845450,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845451,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845451,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845452,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845452,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845453,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845453,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845454,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845454,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845455,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845455,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845456,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845456,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845457,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845457,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845458,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845458,
     "City": "East Champaran",
     "District": "East Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 845459,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Bihar"
    },
    {
     "Pin Code": 845459,
     "City": "West Champaran",
     "District": "West Champaran",
     "State": "Foreign country"
    },
    {
     "Pin Code": 846001,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 846001,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 846002,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 846002,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 846003,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 846003,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 846004,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 846004,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 846005,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 846005,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 846006,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 846006,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 846007,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 846007,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 846008,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 846008,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 846009,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 846009,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847101,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847101,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847102,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847102,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847102,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847102,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847103,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847103,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847104,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847104,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847105,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847105,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 847105,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847105,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847106,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847106,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847107,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847107,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 847107,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847107,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847108,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847108,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847109,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847109,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847115,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847115,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847121,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847121,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847122,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847122,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847122,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847122,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847123,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847123,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847201,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847201,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847203,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847203,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847204,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847204,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847211,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847211,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847212,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847212,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847213,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847213,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847214,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847214,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847215,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847215,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847222,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847222,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847223,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847223,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847224,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847224,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847225,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847225,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847226,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847226,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847227,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847227,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847228,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847228,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847229,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847229,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847230,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847230,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847231,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847231,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847232,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847232,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847233,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847233,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847234,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847234,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847235,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847235,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847236,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847236,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847238,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847238,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847239,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847239,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847239,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847239,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847240,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847240,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847301,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847301,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 847301,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847301,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847302,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847302,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 847302,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847302,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847303,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847303,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847303,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847303,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847304,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847304,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847305,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847305,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847306,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847306,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847307,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847307,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 847307,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Bihar"
    },
    {
     "Pin Code": 847307,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847307,
     "City": "Muzaffarpur",
     "District": "Muzaffarpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847307,
     "City": "Sitamarhi",
     "District": "Sitamarhi",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847308,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847308,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847337,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847337,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847401,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847401,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847402,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847402,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847403,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847403,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847404,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847404,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847405,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847405,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847407,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847407,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847408,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847408,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 847408,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847408,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847409,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847409,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847410,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847410,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847411,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847411,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847421,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847421,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847422,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847422,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847423,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847423,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847424,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847424,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847424,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847424,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847427,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847427,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847428,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847428,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847429,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 847429,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847451,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 847451,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847452,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Bihar"
    },
    {
     "Pin Code": 847452,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 847452,
     "City": "Madhubani",
     "District": "Madhubani",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847452,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 847725,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 847725,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848101,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848101,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848102,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848102,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848111,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848111,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848113,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848113,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848114,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848114,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848115,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848115,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848117,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848117,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848121,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848121,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848122,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848122,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848125,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848125,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848127,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848127,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848129,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848129,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848130,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848130,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848131,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848131,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848132,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848132,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848133,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848133,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848134,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848134,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848160,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848160,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848201,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 848201,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848202,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 848202,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848203,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 848203,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848204,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 848204,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848205,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 848205,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848205,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848205,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848206,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848206,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848207,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 848207,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848207,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848207,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848208,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848208,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848209,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 848209,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848209,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848209,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848210,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848210,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848211,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848211,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848213,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 848213,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848213,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848213,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848216,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848216,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848236,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848236,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848301,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848301,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848302,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848302,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848501,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848501,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848502,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848502,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848503,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848503,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848504,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848504,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848505,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848505,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848506,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 848506,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Bihar"
    },
    {
     "Pin Code": 848506,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 848506,
     "City": "Samastipur",
     "District": "Samastipur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851101,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851101,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851103,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851103,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851111,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851111,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851112,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851112,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851113,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851113,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851114,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851114,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851115,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851115,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851116,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851116,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851117,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851117,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851118,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851118,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851120,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851120,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851122,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851122,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851126,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851126,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851127,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851127,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851128,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851128,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851129,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851129,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851130,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851130,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851131,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851131,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851132,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851132,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851133,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851133,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851134,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851134,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851135,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851135,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851201,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 851201,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851202,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 851202,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851203,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 851203,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851204,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 851204,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851205,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 851205,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851210,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851210,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851211,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851211,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851212,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 851212,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851213,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 851213,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851214,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 851214,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851215,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851215,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 851215,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851215,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851216,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 851216,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851217,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851217,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 851218,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Bihar"
    },
    {
     "Pin Code": 851218,
     "City": "Begusarai",
     "District": "Begusarai",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852101,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852101,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852105,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852105,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852106,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852106,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852107,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852107,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852108,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852108,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852108,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852108,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852109,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852109,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852110,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852110,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852111,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852111,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852112,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852112,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852113,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852113,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852114,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852114,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852115,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852115,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852116,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852116,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852116,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852116,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852121,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852121,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852121,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852121,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852122,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852122,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852123,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852123,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852123,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852123,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852124,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852124,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852124,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852124,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852125,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852125,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852126,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852126,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852127,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852127,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852127,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852127,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852128,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852128,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852129,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852129,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852130,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852130,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852131,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852131,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852132,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852132,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852137,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852137,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852138,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852138,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852139,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852139,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852161,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 852161,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852201,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852201,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852202,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852202,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852202,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852202,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852210,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852210,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852212,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852212,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852213,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852213,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852214,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852214,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852215,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852215,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852216,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Bihar"
    },
    {
     "Pin Code": 852216,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852216,
     "City": "Darbhanga",
     "District": "Darbhanga",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852216,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852217,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852217,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852218,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852218,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 852218,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852218,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852219,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852219,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852220,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 852220,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 852221,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Bihar"
    },
    {
     "Pin Code": 852221,
     "City": "Saharsa",
     "District": "Saharsa",
     "State": "Foreign country"
    },
    {
     "Pin Code": 853201,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 853201,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 853202,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 853202,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 853203,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 853203,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Bihar"
    },
    {
     "Pin Code": 853203,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 853203,
     "City": "Khagaria",
     "District": "Khagaria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 853204,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 853204,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 853204,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Bihar"
    },
    {
     "Pin Code": 853204,
     "City": "Munger",
     "District": "Munger",
     "State": "Bihar"
    },
    {
     "Pin Code": 853204,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 853204,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 853204,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 853204,
     "City": "Madhepura",
     "District": "Madhepura",
     "State": "Foreign country"
    },
    {
     "Pin Code": 853204,
     "City": "Munger",
     "District": "Munger",
     "State": "Foreign country"
    },
    {
     "Pin Code": 853204,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 853205,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Bihar"
    },
    {
     "Pin Code": 853205,
     "City": "Bhagalpur",
     "District": "Bhagalpur",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854101,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854101,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854102,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854102,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854102,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854102,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854103,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854103,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854104,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854104,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854105,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854105,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854106,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854106,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854107,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854107,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854108,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854108,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854109,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854109,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854112,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854112,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854113,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854113,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854113,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854113,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854114,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854114,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854115,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854115,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854116,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854116,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854117,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854117,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854201,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854201,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854202,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854202,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854203,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854203,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854204,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854204,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854205,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854205,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854206,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854206,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854301,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854301,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854302,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854302,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854303,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854303,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854304,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854304,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854305,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854305,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854306,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854306,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854311,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854311,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854312,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854312,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854315,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854315,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854316,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854316,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854316,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854316,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854317,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854317,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854317,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854317,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854318,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854318,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854325,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854325,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854325,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854325,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854326,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854326,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854327,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854327,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854328,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854328,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854329,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854329,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854330,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854330,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854331,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854331,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854332,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854332,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854333,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854333,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854333,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854333,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854334,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854334,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854335,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854335,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 854335,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854335,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854335,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854335,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854336,
     "City": "Araria",
     "District": "Araria",
     "State": "Bihar"
    },
    {
     "Pin Code": 854336,
     "City": "Araria",
     "District": "Araria",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854337,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Bihar"
    },
    {
     "Pin Code": 854337,
     "City": "Purnia",
     "District": "Purnia",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854338,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 854338,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854339,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 854339,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 854340,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Bihar"
    },
    {
     "Pin Code": 854340,
     "City": "Supaul",
     "District": "Supaul",
     "State": "Foreign country"
    },
    {
     "Pin Code": 855101,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 855101,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 855102,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 855102,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 855105,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 855105,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 855106,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 855106,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 855107,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 855107,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 855108,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 855108,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 855113,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 855113,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 855114,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Bihar"
    },
    {
     "Pin Code": 855114,
     "City": "Katihar",
     "District": "Katihar",
     "State": "Foreign country"
    },
    {
     "Pin Code": 855115,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 855115,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 855116,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 855116,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Foreign country"
    },
    {
     "Pin Code": 855117,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Bihar"
    },
    {
     "Pin Code": 855117,
     "City": "Kishanganj",
     "District": "Kishanganj",
     "State": "Foreign country"
    }
   ]
 export default pincodeData;