import React, { useState, useEffect } from 'react';
import bump from '../../Images/Car-Page/bump.svg';
import fenderbender from '../../Images/Car-Page/folder-bender.svg';
import carwash from '../../Images/Car-Page/car-wash.svg';
import RegistrationNumberFetching from '../../Components/RegistrationNumberFetching/RegistrationNumberFetching';
import { GetDataLocalStorage } from '../../../Framework/GlobalObj';
import '../../Pages/Car Pages/CarRegNumberFetching.css';
import roadCar from '../../Images/Car-Page/bluecar.gif';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { useNavigate } from 'react-router';

const CarRegNumberFetching = () => {
  const [req, setreq] = useState({});
  const naviagte = useNavigate();

  useEffect(() => {
    let obj = GetDataLocalStorage('globalObj');
    setreq(obj);
  }, []);

  const handlebackBtn = () => {
    naviagte('/');
  };

  return (
    <div className="">
      <div className="registration-number-top-section">
        <div className="back_icon">
          <KeyboardBackspaceIcon onClick={handlebackBtn} />
        </div>
        {/* <div className="bg-road-car">

        </div> */}
        <img src={roadCar} alt="" className="roadimg" />
        <RegistrationNumberFetching req={req} setreq={setreq} />
      </div>

      <div className="mt-5 text-center justify-content-center container-fluid">
        {/* Car Insurance & Its Types (Description) */}
        <h1 className="fw-bold">Car Insurance & Its Types</h1>
        <p className="mt-5">Car insurance is a type of insurance coverage that provides financial protection against losses incurred due to accidents, theft, or other damage involving a vehicle. It is a contract between the policyholder &#x2772; the vehicle owner &#10099; and the insurance company, where the policyholder pays a premium in exchange for coverage as outlined in the policy.</p>
        <div className="row  mt-5">
          <div className="col-lg-4 col-md-12">
            <img src={carwash} alt="" style={{ width: '156.32px', height: '91.04px' }} />
            <p style={{ fontSize: '22px', fontWeight: '500' }}>Liability Coverage</p>
            <p className="">This is the most basic and mandatory form of car insurance in most places. It covers the costs associated with injuries or property damage you cause to others in an accident.</p>
          </div>

          <div className="col-lg-4 col-md-12">
            <img src={fenderbender} alt="" style={{ width: '156.32px', height: '91.04px' }} />
            <p style={{ fontSize: '22px', fontWeight: '500' }}>Collision Coverage</p>
            <p className="">This coverage pays for the repairs or replacement of your vehicle in the event of a collision, regardless of who is at fault.</p>
          </div>

          <div className="col-lg-4 col-md-12">
            <img src={bump} alt="" className="" style={{ width: '156.32px', height: '91.04px' }} />
            <p style={{ fontSize: '22px', fontWeight: '500' }}>Comprehensive Coverage</p>
            <p className="">This coverage protects against non-collision events such as theft, vandalism, fire, or damage caused by natural disasters.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarRegNumberFetching;
