import axios from "axios";
import { travel_api } from "../../Config";
const quoteEndPoint = "/insurance/v1/travel/policy/quote";
const proposalEndPoint = "/insurance/v1/travel/policy/proposal";
let premiumCalculation = async (postdata) => {  try { 
  console.log(postdata,"frontEnd Quotation body----------------")
    let res = await axios.post(`${travel_api + quoteEndPoint}`, postdata);
    console.log(res?.data?.body?.data, "backend response body-----------");
    return res?.data?.body?.data;
  } catch (error) {
    console.log({ error });
  }
};

let getProposalNumber = async (postdata) => {
  try {
    console.log(postdata, "front end proposal body-----------");
    let res = await axios.post(`${travel_api + proposalEndPoint}`, postdata);
    console.log(res?.data?.body, "backend proposal response---------------");
    return res?.data?.body;
  } catch (error) {
    console.log({ error });
  }
};

const res = await axios.get("https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/Travel/config.json");
const Images =  (res.data.imageUrl);



const travel = await axios.get('https://lmv-web-staging.s3.ap-south-1.amazonaws.com/JSON/Travel/masterData.json');
const data =travel.data;

const ageOptions = () => {
  return data.ageOptions;
};

const Countries = () => {
  return data.continents;
};


const sumInsured = () => {
  return data.sumInsured;
};

const mostVisitedCountries = ()=>{
  return data.mostVisitedCountries;
}

function fieldObject(data, fieldName) {
  let inputFields = data.inputFields;
  let input = inputFields.find((e) => e.fieldName === fieldName);
  return input || null;
}

export {
  premiumCalculation,
  getProposalNumber,
  sumInsured,
  Countries,
  fieldObject,
  ageOptions,
  mostVisitedCountries,
  Images
};
