import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ArrowForwardSharp, ArrowBack } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { handleNewEmployee } from "../../../../Redux/admin/action";
import axios from "axios";
import { setError as setErrorMessage } from "../../../../Redux/action-creators/errorAction";

const getEighteenYearsAgoDate = () => {
  const today = new Date();
  today.setFullYear(today.getFullYear() - 18);
  return today.toISOString().split("T")[0];
};

const getTodayDate = () => {
  return new Date().toISOString().split("T")[0];
};

const EmployeePersonalDetails = ({ setStepCount }) => {
  const navigate = useNavigate();
  const eighteenYearsAgoDate = getEighteenYearsAgoDate();
  const todayDate = getTodayDate();
  const [loadingMobile, setLoadingMobile] = useState(false);
  const [loadingEmployeeId, setLoadingEmployeeId] = useState(false);
  const [errorMobile, setErrorMobile] = useState("");
  const [errorEmployeeId, setErrorEmployeeId] = useState("");
  const [employeeExists, setEmployeeExists] = useState(false);

  // Function to call the API and check if either Employee ID or Mobile Number already exists
  const checkIfExists = async (value, type) => {
    const url = `https://5il6my5wxc.execute-api.ap-south-1.amazonaws.com/Prod/admin/userdata/${value}`;
    try {
      const response = await axios.get(url);
      if (response.data === "user already exists") {
        if (type === "mobile") {
          setErrorMobile("Mobile number already exists");
        } else if (type === "employeeId") {
          setErrorEmployeeId("Employee ID already exists");
        }
      } else {
        if (type === "mobile") {
          setErrorMobile(""); // Clear any previous error for mobile
        } else if (type === "employeeId") {
          setErrorEmployeeId(""); // Clear any previous error for employee ID
        }
      }
    } catch (error) {
      if (type === "mobile") {
        setErrorMobile("An error occurred while checking Mobile number");
      } else if (type === "employeeId") {
        setErrorEmployeeId("An error occurred while checking Employee ID");
      }
    }
  };

  // Mobile number validation and API check
  const handleMobileChange = async (e) => {
    const mobile = e.target.value;
    if (mobile.length < 10) {
      setErrorMobile(""); // Reset error if mobile number is too short
      return;
    }
    setLoadingMobile(true);
    setErrorMobile(""); // Reset previous errors when typing

    await checkIfExists(mobile, "mobile"); // Check if mobile exists

    setLoadingMobile(false);
  };

  // Employee ID validation and API check
  const handleEmployeeIdChange = async (e) => {
    const employeeId = e.target.value;
    if (employeeId.length < 5) {
      setEmployeeExists(false);
      setLoadingEmployeeId(false);
      setErrorEmployeeId(""); // Reset error if employee ID is too short
      return;
    }
    setLoadingEmployeeId(true);
    setErrorEmployeeId(""); // Reset previous errors when typing

    await checkIfExists(employeeId, "employeeId"); // Check if employee ID exists

    setLoadingEmployeeId(false);
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      name: "",
      dob: "",
      gender: "",
      mobile: "",
      maritalStatus: "",
      doj: "",
      employeeId: "",
    },
  });

  const validateDate = (dateString, type) => {
    const date = new Date(dateString);
    const today = new Date();
    let error = "";
    if (type === "dob") {
      if (date > new Date(eighteenYearsAgoDate)) {
        error = "You must be at least 18 years old";
      }
    } else if (type === "doj") {
      if (date > today) {
        error = "Date of Joining cannot be a future date";
      }
    }
    return error;
  };

  const handleDateChange = (e, type) => {
    const value = e.target.value;
    validateDate(value, type);
    if (type === "dob") {
      setValue("dob", value, { shouldValidate: true });
    } else if (type === "doj") {
      setValue("doj", value, { shouldValidate: true });
    }
  };

  const dispatch = useDispatch();
  const onSubmit = (data) => {
    const dobError = validateDate(data.dob, "dob");
    const dojError = validateDate(data.doj, "doj");

    if (dobError || dojError) {
      setValue("dob", data.dob, { shouldValidate: true });
      setValue("doj", data.doj, { shouldValidate: true });
      return;
    }

    dispatch(handleNewEmployee({ form: "PersonalDetails", data }));
    setStepCount(2);
  };

  return (
    <Grid item xs={12}>
      <Paper
        elevation={5}
        sx={{ m: 3, border: "1px solid #ccc", borderRadius: 2 }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Typography variant="h4" p={2} sx={{ textAlign: "center" }}>
            Employee Personal Details
          </Typography>

          <Grid container p={2} columnSpacing={10} rowSpacing={5}>
            <Grid item xs={4}>
              <TextField
                type="text"
                label="Full Name"
                fullWidth
                {...register("name", {
                  required: "Full Name is required",
                  pattern: {
                    value: /^[a-zA-Z\s]*$/,
                    message: "Only letters and spaces are allowed",
                  },
                })}
                error={!!errors.name}
                helperText={errors.name?.message || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="date"
                label="Date Of Birth"
                fullWidth
                {...register("dob", {
                  required: "Date of Birth is required",
                  validate: (value) => {
                    const error = validateDate(value, "dob");
                    return error || true;
                  },
                })}
                error={!!errors.dob}
                helperText={errors.dob?.message || ""}
                InputLabelProps={{ shrink: true }}
                inputProps={{ max: eighteenYearsAgoDate }}
                onChange={(e) => handleDateChange(e, "dob")}
              />
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth error={!!errors.gender}>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Controller
                  name="gender"
                  control={control}
                  rules={{ required: "Gender is required" }}
                  render={({ field }) => (
                    <Select {...field} labelId="gender-label" id="gender">
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </Select>
                  )}
                />
                {errors.gender && (
                  <FormHelperText>{errors.gender.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="text"
                label="Mobile Number"
                fullWidth
                {...register("mobile", {
                  required: "Mobile Number is required",
                  pattern: {
                    value: /^[6-9][0-9]{9}$/,
                    message: "Enter a valid 10-digit mobile number starting with 6, 7, 8, or 9",
                  },
                })}
                onChange={handleMobileChange}
                error={!!errors.mobile || errorMobile}
                helperText={errorMobile || errors.mobile?.message || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {loadingMobile ? <CircularProgress size={24} /> : null}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 10,
                }}
                onInput={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  if (value.length > 0 && !/^[6-9]/.test(value)) {
                    e.target.value = "";
                  } else {
                    e.target.value = value;
                  }
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth error={!!errors.maritalStatus}>
                <InputLabel id="marital-status-label">Marital Status</InputLabel>
                <Controller
                  name="maritalStatus"
                  control={control}
                  rules={{ required: "Marital Status is required" }}
                  render={({ field }) => (
                    <Select {...field} labelId="marital-status-label" id="marital-status">
                      <MenuItem value="single">UnMarried</MenuItem>
                      <MenuItem value="married">Married</MenuItem>
                    </Select>
                  )}
                />
                {errors.maritalStatus && (
                  <FormHelperText>{errors.maritalStatus.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <TextField
                type="date"
                label="Date Of Joining"
                fullWidth
                {...register("doj", {
                  required: "Date of Joining is required",
                  validate: (value) => {
                    const error = validateDate(value, "doj");
                    return error || true;
                  },
                })}
                error={!!errors.doj}
                helperText={errors.doj?.message || ""}
                InputLabelProps={{ shrink: true }}
                inputProps={{ max: todayDate }}
                onChange={(e) => handleDateChange(e, "doj")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="text"
                label="Employee ID"
                fullWidth
                {...register("employeeId", {
                  required: "Employee ID is required",
                })}
                onChange={handleEmployeeIdChange}
                error={!!errors.employeeId || errorEmployeeId}
                helperText={errorEmployeeId || errors.employeeId?.message || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {loadingEmployeeId ? <CircularProgress size={24} /> : null}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 5,
                }}
                onInput={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  if (value.length > 5) {
                    e.target.value = value.slice(0, 5);
                  }
                  if (value.length > 0 && !/^[1-9]/.test(value)) {
                    e.target.value = "";
                  } else {
                    e.target.value = value;
                  }
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                type="number"
                label="Employee Salary"
                fullWidth
                {...register("employeeSalary", {
                  required: "Employee Salary is required",
                })}
                error={!!errors.employeeSalary}
                helperText={errors.employeeSalary?.message || ""}
              />
            </Grid>
          </Grid>

          <Stack
            direction="row"
            justifyContent="space-between"
            p={2}
            mt={2}
            alignItems="center"
          >
            <Button
              startIcon={<ArrowBack />}
              variant="contained"
              type="button"
              onClick={() => navigate("/employee/useridmanagement")}
              disabled={loadingMobile || loadingEmployeeId}
            >
              Back
            </Button>
            <Button
              variant="contained"
              endIcon={<ArrowForwardSharp />}
              type="submit"
              disabled={loadingMobile || loadingEmployeeId}
            >
              Next
            </Button>
          </Stack>
        </form>
      </Paper>
    </Grid>
  );
};

export default EmployeePersonalDetails;
