const vehicalData = require("../../Vehicle/data/vehicleMaster.json");
 
// const maker_model="CRETA 1.5 MPI IVT SX"
// const maker_descreption="HYUNDAI MOTOR INDIA LTD"
 
// const maker_model="ETIOS LIVA GD"
// const maker_descreption="TOYOTA KIRLOSKAR MOTOR PVT LTD"
 
export const VehicleMainCode=(maker_model,maker_descreption,fuel)=>{
 
    let arr=[];
    let str="";
    if(maker_descreption=="MAHINDRA & MAHINDRA LTD*"){
        str="MAHINDRA AND MAHINDRA"
    }else{
        str=maker_descreption
    }
    for (let i = 0; i < vehicalData.length; i++) {
        let vehiclemake = vehicalData[i]['Make'].toString().trim();
        let fuelType = vehicalData[i]['Fuel Type']; // Missing trimming here
        // Ensure str, fuel, and arr are defined and have the expected values
        if (str && str.trim().indexOf(vehiclemake) >= 0 && fuelType && fuelType.trim().toLowerCase() === fuel.trim().toLowerCase()) {
          arr.push(vehicalData[i]);
        }
      }
    // for(let i=0;i<vehicalData.length;i++){
    //     let vehiclemake=vehicalData[i]['Make'].toString().trim();
    //     let fuelType=vehicalData[i]['Fuel Type']
    //     if(str.trim().indexOf(vehiclemake)>=0 && fuelType.trim().toLowerCase() == fuel.trim().toLowerCase()){
    //         arr.push(vehicalData[i])
    //     }
    // }
   
    let arrModel=[];
   
    for(let i=0;i<arr.length;i++){
        let vehiclemake=arr[i]['Model'].toString().trim();
            if(vehiclemake=="XUV 700"){
                vehiclemake=vehiclemake.split(" ").join("")
            }
 
            if(maker_model.length>vehiclemake.length){
                if(maker_model.indexOf(vehiclemake)>=0){
                    arrModel.push(arr[i])
                }
            }else if(vehiclemake.length>maker_model.length){
                if(vehiclemake.indexOf(maker_model)>=0){
                    arrModel.push(arr[i])
                }
            }
    }
 
   
    let varient=maker_model.split(" ");
   
    let varientMap=[];
   
   
    for(let i=0;i<arrModel.length;i++){
        let vehiclevarient=arrModel[i]['Variant'].toString().trim();
        let count=0;
        for(let j=0;j<varient.length;j++){
            if(varient[j]?.includes('-')){
                let splitVarient=varient[j].split('-');
               for(let k=0;k<splitVarient.length;k++){
                if(vehiclevarient.indexOf(splitVarient[k].toString().trim())>-1){
                    count++;
                }
            }
           }else if(vehiclevarient.indexOf(varient[j].toString().trim())>-1){
            count++;
        }
    }
    if(count>0){
        varientMap.push({...arrModel[i],count});
    }
}
 
 
varientMap.sort((a,b)=> b.count-a.count);
 
let max=0;
let matchingVehicleListArr=[];
 
for(let i=0;i<varientMap.length;i++){
if(varientMap[i].count>=max){
 max=varientMap[i].count;
matchingVehicleListArr.push(varientMap[i]);
}else{
    break;
}
}
 
 
if(matchingVehicleListArr.length==0){
    matchingVehicleListArr=[ ...arrModel ]
}
console.log("in vehicle123", arrModel)
  return matchingVehicleListArr;
}
 