export const signupContainer ={
    display: 'flex',
    justifyContent: 'center',
    alignItems:"center",
    height: '90vh',
    overflow: 'hidden', // Prevents scrolling
    position: 'relative',
}

export const signupMain = {
    position: 'relative', // Bring the form to the front
    borderRadius: '25px',
    boxShadow: 4,
    width: '58%', // Adjust the width as needed
}