import React, { useRef, useState } from 'react';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './Otpmodal.css';
import { useNavigate } from 'react-router-dom';
import { getSmsResponse, getSmsVerificationResponse } from '../../Redux/Action';
import { useDispatch, useSelector } from 'react-redux';
import { AlertError } from '../Toaster/AlertError';
import axios from 'axios';
import { common_api } from '../../Config';
import { sendOtp, sendOtpToNumber } from '../Add&SendOTP';

const Otpmodal = ({ open, handleClose, number, typed, setStepCount }) => {
  console.log(typed, 'typeddd');
  console.log(setStepCount, "step")
  const [otp, setOTP] = useState(['', '', '', '']);
  const [otpSentSuccess, setOtpSentSuccess] = useState(false);
  const [loading, setloading] = useState(false);
  const [reqBody, setReqBody] = useState(null)
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('');
  const [type, setType] = useState('');
  const [method, setMethod] = useState('');
  // Initialize an array with 4 empty strings`
  const otpInputs = useRef([]);
  const navigate = useNavigate();
  const smsResponse = useSelector((store) => store.motorReducer.smsResponse);

  const dispatch = useDispatch();
  const handleInputChange = (index, event) => {
    const newOtp = [...otp];
    newOtp[index] = event.target.value;
    setOTP(newOtp);
    if (event.target.value && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };
  const handleBackspace = (index, event) => {
    if (index > 0 && !otp[index]) {
      const newOtp = [...otp];
      newOtp[index - 1] = '';
      setOTP(newOtp);
      otpInputs.current[index - 1].focus();
    }
  };

  const handleResendOtp = () => {
    const method = 'RESEND';
    const org_id = '100';
    const agent_id = '1000';
    const phone = number;
    const type = 'C';
    dispatch(getSmsResponse(phone, method, org_id, agent_id, type));
    setOtpSentSuccess(true);
  };

  const handleVerifyOTP = async () => {
    setloading(true);
    const method = "VERIFY";
    const type = "C";
    const code = Number(otp.join(""));
    const phone = number;
    setReqBody({ code, phone, type, method })
    console.log(reqBody);
    let verifyOtpRes
    try {
      verifyOtpRes = await sendOtpToNumber({ method, type, code, phone })
      console.log(verifyOtpRes, 'res in otp modal')
      if (verifyOtpRes.data?.success && typed === "health") {
        setloading(false);
        navigate("/health/quotations");
      } else if (verifyOtpRes.data?.success && typed === "travel"
      ) {
        setloading(false);
        navigate("/travel/quotes");
      } else if (verifyOtpRes.data?.success && typed === "loan") {
        alert('going')
        setloading(false);
        setStepCount(2);
      } else {
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      if (verifyOtpRes.data?.success === false) {
        alert(verifyOtpRes.data?.message === true)
      }
    }
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div className="car-details-otp-modal">
          <span className="mainHeading">Enter OTP</span>
          <p className="otpSubheading">We have sent a verification code to your mobile number</p>
          <div className="car-details-otp-box-container">
            {otp.map((value, index) => (
              <input
                className="car-details-otp-box"
                type="text"
                id={`otp-input-${index}`}
                key={index}
                value={value}
                maxLength="1"
                onChange={(e) => handleInputChange(index, e)}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    handleBackspace(index, e);
                  }
                }}
                ref={(input) => (otpInputs.current[index] = input)}
              />
            ))}
          </div>
          <button className="car-details-otp-submit-button" type="submit" onClick={handleVerifyOTP}>
            Verify & Submit
          </button>
          <p className="resendNote">
            Didn't receive the code?{' '}
            <button className="resendBtn" onClick={() => handleResendOtp()}>
              Resend Code
            </button>
          </p>
          <CloseIcon sx={{ cursor: 'pointer', position: 'absolute', right: '20px', top: '20px' }} onClick={handleClose} />
        </div>
      </Modal>
    </>
  );
};
export default Otpmodal;
