import { crm_api, vehicle_api } from "../../../Config";
import axios from "axios";
// import logos from "../../../../public/crm/customer/logos.json"
 
export const getLogos = () => {
    return axios.get(`https://lmv-web-staging.s3.ap-south-1.amazonaws.com/CRM/CustomerDocs/crm/customer/logos.json`);
};  

export const getMenuDetails = () => {
    return axios.get(`https://lmv-web-staging.s3.ap-south-1.amazonaws.com/CRM/CustomerDocs/crm/customer/customer-menu.json`);
};

export const getSupportInfo = () => {
    return axios.get('https://lmv-web-staging.s3.ap-south-1.amazonaws.com/CRM/CustomerDocs/crm/customer/support.json');
};

export const getUserJson = () => {
    return axios.get('https://lmv-web-staging.s3.ap-south-1.amazonaws.com/CRM/CustomerDocs/crm/customer/user.json');
};

export const getMasterData = () => {
    return axios.get(`https://lmv-web-staging.s3.ap-south-1.amazonaws.com/CRM/CustomerDocs/crm/customer/master-data.json`);
};

export const getProviderLogo = () => {
    return axios.get('https://lmv-web-staging.s3.ap-south-1.amazonaws.com/CRM/CustomerDocs/crm/customer/provider-logo.json');
};

export const getCustomerBankAccount = (customer_id) => {
    return axios.get(crm_api+'/customer/v1/bankAccountInfo/'+customer_id);
};

export const postCustomerBankDetails = (customer_id,payload) => {
    return axios.put(crm_api+'/customer/v1/bankAccountInfo/'+customer_id, payload);
};

export const postClaimDetail = (payload) => {
    return axios.post(crm_api+'/customer/v1/claim', payload);
};

export const getAllPolicies = () => {
    return axios.get(crm_api+'/customer/v1/policy/');
};

export const getPoliciesDataById = (customer_id) => {
    return axios.get(crm_api+'/customer/v1/policy/'+ customer_id);
    // return axios.get('http://localhost:5000/customer/v1/policy/'+ customer_id);
};

export const getClaimsByCustomerId = (customer_id) => {
    return axios.get(crm_api+'/customer/v1/claim/'+ customer_id);
};

export const updateProfileSetting = (customer_id, payload) => {
    return axios.put(crm_api+'/customer/v1/profileSetting/' + customer_id, payload);
    // return axios.put('http://localhost:5000/customer/v1/profileSetting/' + customer_id, payload); // local
};

export const updateUserProfileData = (phone, payload) => {
    return axios.put(crm_api+'/customer/v1/profileDetails/' + phone, payload);
    // return axios.put('http://localhost:5000/customer/v1/profileDetails/' + phone, payload); // local
};

export const fetchCustomerData = (phone) => {
    return axios.get(crm_api + '/customer/v1/profileDetails/' + phone) //prod
    // return axios.get('http://localhost:5000/customer/v1/profileDetails/' + phone) // local
}

export const validateUserByPhone = (payload) => {
    return axios.post(crm_api+'/customer/v1/user/validateUser', payload); // prod
    // return axios.post('http://localhost:5000/customer/v1/user/validateUser', payload);
};  

export const generateOtp = (payload) => {
    return axios.post(crm_api+'/Posp/v1/user/generateOTP', payload); // prod
    // return axios.post('http://localhost:5000/customer/v1/user/generateOTP', payload);
};

export const otpValidation = (payload) => {
    return axios.post(crm_api+'/customer/v1/user/validateOTP', payload); // prod
    // return axios.post('http://localhost:5000/customer/v1/user/validateOTP', payload);
};


// export const validateUserByPhone = (payload) => {
//     return axios.post('https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/customer/v1/user/validateUser', payload); // stg
//     // return axios.post('http://localhost:5000/customer/v1/user/validateUser', payload); // local
// };

// export const generateOtp = (payload) => {
//     return axios.post('https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/customer/v1/user/genrateOTP', payload); // stg
//     // return axios.post('http://localhost:5000/customer/v1/user/generateOTP', payload); // local
// };

// export const otpValidation = (payload) => {
//     return axios.post('https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/customer/v1/user/validateOTP', payload); // stg
//     // return axios.post('http://localhost:5000/customer/v1/user/validateOTP', payload); // local
// };



// export const updateUserProfileData = (phone, payload) => {
//     // return axios.put('https://p23oxyscde.execute-api.ap-south-1.amazonaws.com/Stage/customer/v1/profileDetails/' + phone, payload); //stg
//     return axios.put('http://localhost:5000/customer/v1/profileDetails/' + phone, payload); // local
// };

export const fetchVehicleDetials = (playload) => {
    return axios.post(vehicle_api + '/rto/v1/rc', playload) // stg
}